import makeStyles from '@mui/styles/makeStyles';
import createStyles from '@mui/styles/createStyles';

const useStyles = makeStyles(() =>
  createStyles({
    titleElement: {
      marginBottom: '16px',
      fontSize: '20px',
      fontWeight: 600,
    },
    userVerification: {
      position: 'absolute',
      right: '16px',
      top: '16px',
    },
    contentWrapper: {
      position: 'relative',
    },
    detailWrapper: {
      padding: '0 16px 16px',
    },
    tooltipWrapper: {
      position: 'absolute',
      right: 0,
      top: 0,
      transform: 'translateX(100%)',
    },
    ecosystemElement: {
      display: 'flex',
      flexDirection: 'row',
      alignItems: 'center',
      marginTop: '10px',
    },
    ecosystemView: {
      marginLeft: '8px',
    },
    emailTrim: {
      textOverflow: 'ellipsis',
      whiteSpace: 'nowrap',
      overflow: 'hidden',
    },
    headingWithIcon: {
      display: 'flex',
      alignItems: 'center',
      lineHeight: 1, // !important,
    },
    infoIcon: {
      marginLeft: '2px !important',
      cursor: 'pointer',
      fontSize: '12px !important',
    },
    outerWrapper: {
      display: 'flex',
      flexDirection: 'column',
      margin: -16,
      '&> *': {
        margin: 16,
      },
    },
    form: {
      width: 440,
      display: 'flex',
      flexDirection: 'column',
    },
    submitWrapper: {
      display: 'flex',
      justifyContent: 'center',
      position: 'relative',
    },
    errorMessage: {
      position: 'absolute',
      left: '50%',
      bottom: 0,
      transform: 'translate(-50%, 100%)',
    },
  })
);

export default useStyles;
