import React from 'react';

import CopyTextFieldList from '../../ui/CopyTextField/CopyTextFieldList';

export interface IOwnProps {
  orgId: string;
}

export const OrgDialog: React.FC<IOwnProps> = ({ orgId }) => {
  const textList = [
    {
      title: 'Organization Id (IAM Id)',
      dataTestId: 'organization-id',
      text: orgId,
    },
  ];

  return <CopyTextFieldList textList={textList} />;
};
