import React, { useEffect, useState } from 'react';

import useSharedStyles from '../../../shared/useSharedStyles';
import Tooltip from '../../../ui/Tooltip';
import CopyTextFieldList from '../../../ui/CopyTextField/CopyTextFieldList';
import { IOwnProps as ICopyTextField } from '../../../ui/CopyTextField';
import { ITariffModel } from '../../../../types/tariff';
import useStyles from './useStyles';
import { Typography } from '../../../shared/Typography/Typography';

export interface IOwnProps {
  tariff: ITariffModel | null;
}

const Tariff: React.FC<IOwnProps> = ({ tariff, children }) => {
  const sharedClasses = useSharedStyles();
  const classes = useStyles();
  const [tooltipData, setTooltipData] = useState<ICopyTextField[] | null>(null);

  useEffect(() => {
    if (!tariff) {
      return;
    }

    setTooltipData([
      {
        title: 'Tariff Code',
        text: tariff.code,
      },
    ]);
  }, [tariff]);

  return (
    <div className={sharedClasses.cardDetail}>
      <Typography variant='h6' className={classes.headingWithIcon}>
        Tariff Name
        {tooltipData && (
          <Tooltip tooltipName='tariff-info'>
            <CopyTextFieldList textList={tooltipData} />
          </Tooltip>
        )}
      </Typography>
      <div className={sharedClasses.cardDetailData}>{children}</div>
    </div>
  );
};

export default Tariff;
