import makeStyles from '@mui/styles/makeStyles';
import createStyles from '@mui/styles/createStyles';

const useSharedStyles = makeStyles(() =>
  createStyles({
    cardButtonWrapper: {
      display: 'flex',
      flexDirection: 'row',
      alignItems: 'center',
      justifyContent: 'space-between',
      padding: '4px 8px',
      borderRadius: '4px',
      boxShadow: '0 4px 8px 0 rgba(0, 0, 0, 0.1)',
      backgroundColor: '#f5f6f8',
      marginRight: 12,
      marginTop: 16,
      '&:hover': {
        boxShadow: '0 4px 8px 0 rgba(0, 0, 0, 0.2)',
        backgroundColor: '#cad3e0',
        cursor: 'pointer',
      },
    },
    cardButtonTitle: {
      fontSize: '12px',
      fontWeight: 600,
      lineHeight: '16px',
      color: '#878c96',
      whiteSpace: 'nowrap',
    },
    roundCircle: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      height: '15px',
      borderRadius: '20px',
      border: '1.2px solid #878c96',
      minWidth: '15px',
      '&:not(:first-child)': { marginLeft: '5px' },
    },
    cardButtonCount: {
      fontSize: '10px',
      fontWeight: 600,
      lineHeight: 1,
      letterSpacing: '0',
      color: '#878c96',
      padding: '0 3px',
    },
    container: {
      marginTop: 'calc(50vh - 50px)',
      textAlign: 'center',
    },
    sectionWrap: {
      display: 'flex',
      width: '100%',
      position: 'relative',
      flexDirection: 'row',
      alignItems: 'center',
      justifyContent: 'flex-start',
      paddingBottom: '10px',
      // border-bottom: 1px solid #ddd,
      // margin-bottom: 20px,
    },

    sectionTitle: {
      fontSize: '20px', // !important,
      fontWeight: 600, // !important,
      lineHeight: 1.2, // !important,
    },

    cardElement: {
      backgroundColor: '#ffffff',
      boxShadow: '0 2px 8px 0 rgba(0, 0, 0, 0.08)',
      borderRadius: '4px',
      padding: '16px',
      marginBottom: '12px',
    },
    cardTitle: {
      fontSize: '20px',
      lineHeight: 'normal',
    },
    cardDetail: {
      marginBottom: '24px',
    },

    cardDetailTitle: {},

    cardDetailData: {
      marginTop: '10px',
      padding: '0 20px 0 0',
      display: 'flex',
      alignItems: 'center',
    },

    expand: {
      marginBottom: 0,
      overflow: 'hidden',
    },

    cardElementExpanded: {
      height: 'auto',
    },

    cardElementCondensed: {
      height: 0,
    },

    titleWithExpand: {
      display: 'flex',
      flexFlow: 'row nowrap',
    },

    scrollingDiv: {
      minHeight: '200px',
      maxHeight: '350px',
      margin: 0,
      padding: 0,
      overflowX: 'hidden',
      overflowY: 'auto',
    },
    titleIcon: {
      color: '#6633cc',
      fontSize: 24,
      marginRight: 16,
    },
    nextIcon: {
      fontSize: '24px',
      color: '#6633cc',
      cursor: 'pointer',
    },
    expandIcon: {
      marginRight: '10px,',
      fontSize: '24px',
      color: '#6633cc',
      cursor: 'pointer',
    },
    overview: {
      gridTemplateColumns: 'repeat(12, 50px) 0',
      display: 'grid',
      gridColumnGap: '12px',
      gridRowGap: '0px',
      margin: '12px 0 12px 0',
      zIndex: 5,
    },
    largeOverview: {
      gridTemplateColumns: 'repeat(18, 50px) 0',
      display: 'grid',
      gridColumnGap: '12px',
      gridRowGap: '0px',
      margin: '12px 0 12px 0',
      zIndex: 5,
    },
    overviewWithSidebar: {
      gridTemplateColumns: 'repeat(20, 50px) 0',
      display: 'grid',
      gridColumnGap: '12px',
      gridRowGap: '0px',
      margin: '12px 0 12px 0',
      zIndex: 5,
    },
    overviewNoActionPanel: {
      gridTemplateColumns: 'repeat(8, 50px) 0',
      display: 'grid',
      gridColumnGap: '12px',
      gridRowGap: '0px',
      margin: '12px 0 12px 0',
      zIndex: 5,
    },
    staticWrapper: {
      gridColumnStart: 1,
      gridColumnEnd: 9,
      height: '100%',
    },
    box: {
      height: '400px',
      background: 'grey',
      marginBottom: '10px',
    },
    actionWrapper: {
      gridColumnStart: 9,
      gridColumnEnd: 13,
      height: '100%',
    },
    hideActionWrapper: {
      display: 'none',
    },
    smallBox: {
      background: 'green',
      height: '200px',
      padding: '20px',
      boxSizing: 'border-box',
    },
    detailsWrapper: {
      gridColumnStart: 1,
      gridColumnEnd: 13,
    },

    largeDetailsWrapper: {
      gridColumnStart: 1,
      gridColumnEnd: 21,
    },
    withSidebarDetailsWrapper: {
      gridColumnStart: 1,
      gridColumnEnd: 21,
    },
    textOverflow: {
      display: 'block',
      whiteSpace: 'nowrap',
      overflow: 'hidden',
      textOverflow: 'ellipsis',
    },
    headingWithIcon: {
      display: 'flex',
      alignItems: 'center',
      lineHeight: 1, // !important,
      marginBottom: 16,
    },
    infoIcon: {
      marginLeft: '2px', // !important,
      cursor: 'pointer',
      fontSize: '12px', // !important,
    },
    labelWrapper: {
      display: 'flex',
      alignItems: 'center',
    },
  })
);

export default useSharedStyles;
