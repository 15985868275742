import { Theme } from '@mui/material/styles';
import createStyles from '@mui/styles/createStyles';
import makeStyles from '@mui/styles/makeStyles';

export const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    textWithLink: {
      textDecoration: 'underline',
      cursor: 'pointer',
      '&:hover': {
        color: theme.colorPrimary,
      },
    },
    successMessageWrapper: {
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',

      '& > img': {
        margin: 32,
        marginTop: 16,
      },
    },
    detailWrapper: {
      padding: '0 16px 16px',
    },
    chargingCardLink: {
      cursor: 'pointer',
      textDecoration: 'underline',

      '&:hover': {
        color: '#6633cc',
      },
    },
    divider: {
      width: '100%',
      backgroundColor: '#f5f6f8 !important',
    },
    informationRow: {
      padding: '16px',
      display: 'flex',
      flexDirection: 'row',
      alignItems: 'center',
      paddingTop: 0,
      flexFlow: 'row wrap',
    },
    headingWithIcon: {
      display: 'flex',
      alignItems: 'center',
      lineHeight: 1, // !important,
    },
    infoIcon: {
      marginLeft: '2px !important',
      cursor: 'pointer',
      fontSize: '12px !important',
    },
    tooltipWrapper: {
      position: 'absolute',
      right: 0,
      top: 0,
      transform: 'translateX(100%)',
    },
  })
);
