import createStyles from '@mui/styles/createStyles';
import makeStyles from '@mui/styles/makeStyles';
import CreditCardImg from '../../../../assets/image/credit_card_image.png';

const useStyles = makeStyles(() =>
  createStyles({
    cardContainer: {
      padding: '0px 36px 36px 20px',
    },
    cardDetails: {
      display: 'flex',
    },
    cardImage: {
      width: '100%',
    },
    cardInformation: {
      display: 'flex',
      alignItems: 'center',
    },
    cardLayout: {
      position: 'relative',
      backgroundImage: `url(${CreditCardImg})`,
      width: '325px',
      height: '206px',
      backgroundSize: 'contain',
      backgroundRepeat: 'no-repeat',
    },
    cardOverlay: {
      position: 'absolute',
      width: '100%',
      bottom: 15,
      padding: '0px 30px',
    },
    cardOverlayTextContainer: {
      display: 'flex',
      justifyContent: 'space-between',
    },
    textOnCard: {
      fontSize: 14,
      fontFamily: 'monospace',
    },
    numberOnCard: {
      fontSize: 24,
      fontFamily: 'monospace',
    },
    cardIconOverlay: {
      position: 'absolute',
      top: 10,
      right: 30,
      width: '44px',
      height: '44px',
      backgroundImage: 'none',
      backgroundSize: 'contain',
      backgroundRepeat: 'no-repeat',
      backgroundPosition: 'center',
    },
  })
);

export default useStyles;
