import { ActionInterface } from '../actions';
import { State } from '../';
import { UserStateModel } from '../';
import update from 'immutability-helper';

export default (state: State, action: ActionInterface.VerifyUser): State => {
  const user = state || {
    loading: false,
    data: null,
    error: null,
    request: null,
    verified: false,
  };

  const updatedUser: UserStateModel = update(user, {
    verified: {
      $set: action.verified,
    },
  });

  return {
    ...state,
    ...updatedUser,
  };
};
