import { ActionInterface } from '../actions';
import { State } from '..';
import update from 'immutability-helper';

export default (
  state: State,
  action: ActionInterface.GetNearbyLocationsSuccess
): State => {
  const { nearbyLocations, evse_id } = action;
  const currentChargingStationState = state;

  const updatedData = Object.assign(
    {},
    currentChargingStationState.nearbyLocations,
    {
      [evse_id]: nearbyLocations,
    }
  );

  const updatedChargingStationState = update(currentChargingStationState, {
    nearbyLocations: {
      $set: updatedData,
    },
    nearbyLocationsLoading: {
      $set: false,
    },
  });

  return {
    ...state,
    ...updatedChargingStationState,
  };
};
