import { ICircleState } from '../index';

export const defaultSignedUpCircleState: ICircleState = {
  circleState: 'sad',
  happyText: 'Signed up for subscription',
  sadText: 'No active subscription',
  testId: 'is-active-subscription-circle',
};
export const defaultCardOrderedCircleState: ICircleState = {
  circleState: 'sad',
  happyText: 'Card ordered',
  sadText: 'No card ordered',
  testId: 'is-ordered-card-circle',
};
export const defaultCardAddedToWalletCircleState: ICircleState = {
  circleState: 'sad',
  happyText: 'Card added to wallet',
  sadText: 'No card added to wallet',
  testId: 'is-card-in-wallet-circle',
};
export const defaultCardPairedCircleState: ICircleState = {
  circleState: 'sad',
  happyText: 'Card paired to active subscription',
  sadText: 'No card paired with active subscription',
  testId: 'is-card-paired-to-active-subscription-circle',
};
export const defaultTariffEligibleForPncCircleState: ICircleState = {
  circleState: 'sad',
  happyText: 'Tariff eligible for P&C',
  sadText: 'Tariff not eligible for P&C',
  testId: 'is-eligible-tariff-circle',
};
export const defaultPncEnabledCircleState: ICircleState = {
  circleState: 'sad',
  happyText: 'P&C enabled',
  sadText: 'P&C not enabled',
  testId: 'is-pnc-enabled-circle',
};
