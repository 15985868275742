import React from 'react';
import useStyles from './useStyles';

interface IBasicStatusIndicatorProps<S> {
  status: S;
  colorMapping: (status: S) => string;
}

export function BasicStatusIndicator<S>({
  status,
  colorMapping,
}: IBasicStatusIndicatorProps<S>): ReturnType<
  React.FC<IBasicStatusIndicatorProps<S>>
> {
  const classes = useStyles();
  const backgroundColor = colorMapping(status);
  return (
    <div
      data-testid='status-indicator'
      className={`${classes.indicator} ${backgroundColor === 'radiating' &&
        classes.statusPulse} ${classes.activationStatus}`}
      style={{ backgroundColor }}
    />
  );
}
