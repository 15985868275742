import { get } from 'lodash';

type UrlParams = Record<string, string | undefined>;

export const parseURLparameters = (url: string): UrlParams => {
  const urlParams: UrlParams = {};
  url
    .slice(1)
    .replace(
      /([^=&]+)=([^&]*)/g,
      (_m, key: string, value: string): string =>
        (urlParams[decodeURIComponent(key)] = decodeURIComponent(value))
    );

  return urlParams;
};

export const checkVinTool = (urlParams: UrlParams): boolean => {
  return get(urlParams, 'tool') === 'vin-eligibility';
};

export const checkWallboxTool = (urlParams: UrlParams): boolean => {
  return get(urlParams, 'tool') === 'wallbox-sn-check';
};

export const checkVerifiedCustomer = (urlParams: UrlParams): boolean => {
  return Boolean(
    urlParams.customer_verified &&
      urlParams.customer_verified === 'true' &&
      urlParams.email
  );
};
