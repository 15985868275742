import React from 'react';
import omit from 'lodash/omit';
import { SubmitHandler } from 'react-hook-form';
import {
  EInvoiceRefundType,
  EInvoiceReimbursementAmount,
  IInvoiceRefundLineItem,
  IInvoiceLineItem,
} from '../../../../../../types/invoice';
import { useInvoiceRefund } from '../../../../../../state/mutations/useInvoiceRefund';
import connector, { IPropsFromState } from '../../../../../Connector/Connector';
import { Form } from '../../../../../shared/form-elements';
import SubscriptionRefundFields from './SubscriptionRefundFormFields';
import { subscriptionRefundFormSchema } from './SubscriptionRefundForm.schema';
import InvoiceRefundResponse from '../../shared/response/InvoiceRefundResponse';

export type TSubscriptionRefundFormProps = IPropsFromState & {
  invoiceId: string;
  baseFee: IInvoiceLineItem;
};
const SubscriptionRefundForm: React.FC<TSubscriptionRefundFormProps> = ({
  invoiceId,
  baseFee,
  removeBreadcrumb,
  subscriberState,
  getInvoices,
}) => {
  const { error, mutate, isError, isSuccess } = useInvoiceRefund();
  const DEFAULT_VALUES: IInvoiceRefundLineItem = {
    invoice_line_item_id: baseFee.id,
    process_id: baseFee.process_id,
    type: EInvoiceRefundType.SUBSCRIPTION_FEE,
    gross_amount_to_refund: Number(baseFee.gross_amount),
    reimbursement_amount: EInvoiceReimbursementAmount.FULL,
  };

  const onSubmit: SubmitHandler<IInvoiceRefundLineItem> = (data) => {
    mutate(
      {
        subscriberId: subscriberState.data!.id,
        invoiceId,
        data: {
          line_items: [
            {
              ...omit(data, 'reimbursement_amount'),
              gross_amount_to_refund: data.gross_amount_to_refund,
            },
          ],
        },
      },
      {
        onSuccess: () => {
          if (subscriberState.data) {
            getInvoices({
              subscriberId: subscriberState.data.id,
            });
          }
        },
      }
    );
  };

  return (
    <>
      <Form<IInvoiceRefundLineItem>
        schema={subscriptionRefundFormSchema(
          Number(baseFee.gross_amount),
          baseFee.currency
        )}
        defaultValues={DEFAULT_VALUES}
        onSubmit={onSubmit}
      >
        <SubscriptionRefundFields baseFee={baseFee} />
      </Form>
      <InvoiceRefundResponse
        error={error}
        isError={isError}
        type='subscription'
        isSuccess={isSuccess}
        removeBreadcrumb={removeBreadcrumb}
      />
    </>
  );
};

export default connector(SubscriptionRefundForm);
