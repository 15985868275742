import { ModelVariant } from '../types/user';

export const mapMGWBModelVariantToString = (
  modelVariant?: ModelVariant
): string => {
  switch (modelVariant) {
    case 'PRO':
      return 'EVBox MGWB Pro';
    case 'CONNECT':
      return 'EVBox MGWB Connect';
    default:
      return 'EVBox MGWB';
  }
};

export const getStationModel = (
  name?: string,
  modelVariant?: ModelVariant
): string => {
  switch (name) {
    case 'COMPLEO_CITO':
      return 'Compleo Cito 240';
    case 'INNOGY_EBOX':
      return 'innogy eBox';
    case 'ECOTAP_DC22':
      return 'Ecotap DC 22';
    case 'EBEE_MODULAR':
      return 'Ebee Modular Kit';
    case 'EVBOX_ELVI':
      return 'EVBox Elvi';
    case 'EVBOX_MGWB':
      return mapMGWBModelVariantToString(modelVariant);
    default:
      return '-';
  }
};

export const getAuthorisationMode = (name?: string): string => {
  switch (name) {
    case 'no_authorization_cs':
      return 'Instant Charging';
    case 'no_authorization_csms':
      return 'Free Charging';
    case 'authorization_csms':
      return 'Private Charging';
    case 'authorization_e_roaming':
      return 'Public Charging';
    default:
      return '-';
  }
};

interface IStationOwner {
  firstName?: string;
  lastName?: string;
  email?: string;
  orgName?: string;
  type: string;
}

export const parseStationOwner = ({
  firstName,
  lastName,
  email,
  type,
  orgName,
}: IStationOwner): string => {
  if (type === 'organization') {
    return `${orgName}`;
  } else if (type === 'user') {
    if (!firstName) {
      return `${email}`;
    } else {
      return `${firstName} ${lastName}`;
    }
  }

  return '-';
};
