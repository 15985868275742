import React, { useEffect, useRef, useState } from 'react';
import Divider from '@mui/material/Divider';
import { Grid, IconButton, Alert } from '@mui/material';
import CreditCardIcon from '@mui/icons-material/CreditCard';
import PowerIcon from '@mui/icons-material/Power';
import toast from 'react-hot-toast';

import {
  addBreadcrumbHandler,
  IBreadcrumbHandlerProps,
} from '../../../../utils/addBreadcrumbHandler';
import {
  IRFIDCardModel,
  ISubscriptionModel,
} from '../../../../types/subscriber';
import connector, { IPropsFromState } from '../../../Connector/Connector';
import { DialogChargingCard } from '../../../ChargingCards/DialogChargingCard';
import { IChargingCardDialog } from '../../../OverviewCards/PublicCharging';
import { ITariffConditionModel, ITariffModel } from '../../../../types/tariff';
import { TariffService } from '../../../../services/tariffs';
import { formatIsoDates } from '../../../../utils/dates';
import { parseAddress } from '../../../../utils/parseAddress';
import useSharedStyles from '../../../shared/useSharedStyles';
import SubscriptionStatus from '../Status/SubscriptionStatus';
import SignupDate from '../SignupDate/SignupDate';
import Tooltip from '../../../ui/Tooltip';
import Tariff from '../Tariff/Tariff';
import { IActionItem } from '../../../shared/ActionDropdownContainer';
import PairRfidAndSubscription from '../../../shared/Dialogs/PairRfidAndSubscription';
import { TerminateSubscriptionWarningDialog } from '../TerminateSubscriptionWarningDialog/TerminateSubscriptionWarningDialog';
import { TerminateSubscriptionSummaryDialog } from '../TerminateSubscriptionSummaryDialog/TerminateSubscriptionSummaryDialog';
import { TerminateAtFutureDateDialog } from '../TerminateAtFutureDateDialog/TerminateAtFutureDateDialog';
import { getSubscriptionTerminationResult } from '../subscriptionTerminationResultsStorage';
import { StatusInformationDialog } from '../StatusInformationDialog/StatusInformationDialog';
import {
  TerminateSubscriptionButton,
  TerminateSubscriptionType,
} from '../TerminateSubscriptionButton/TerminateSubscriptionButton';
import useStyles from './useStyles';
import { TerminateUpcomingSubscriptionDialog } from '../TerminateUpcomingSubscriptionDialog';
import { SubscriptionDetailsHeader } from '../SubscriptionDetailsHeader/SubscriptionDetailsHeader';
import { BasicInformationItem } from '../../../shared/InformationItems/BasicInformationItem';
import { TextLink } from '../../../shared/InformationItems/TextLink';
import { CardSubheadline } from '../../../shared/InformationItems/CardSubheadline';
import { getDealershipName } from '../../../../utils/TenantData';
import { PlugAndChargeService } from '../../../../services/plugAndCharge';
import { CarService } from '../../../../services/car';
import { getDefined } from '../../../../utils/getDefined';
import InformationItem from '../../../shared/InformationItems/InformationItem';
import EnableDisablePlugAndChargeModal from '../EnableDisablePlugAndChargeModal/EnableDisablePlugAndChargeModal';
import RefreshIcon from '@mui/icons-material/Refresh';
import { useTariffsQuery } from '../../../../state/queries/tariffs';
import { StatefulInformationItem } from '../../../shared/InformationItems/InformationItemWithLoadingState';
import { PlugAndChargeStatus } from '../PlugAndChargeStatus/PlugAndChargeStatus';
import { TariffEligibilityStatus } from '../PlugAndChargeStatus/TariffEligibilityStatus';
import { VinCapabilityStatus } from '../PlugAndChargeStatus/VinCapabilityStatus';
import { Typography } from '../../../shared/Typography/Typography';
import { useTypedTranslation } from '../../../../custom-hooks/useTypedTranslation';

const checkIfSingle = (str: number): string => (str === 1 ? '' : 's');

const hasUpcomingSubscription = (
  subscriptions: ISubscriptionModel[] | null
) => {
  if (subscriptions === null) {
    return false;
  }
  return subscriptions.some((sub) => sub.status === 'upcoming');
};

export type TOwnProps = IPropsFromState & {
  resourceId?: string;
  crumbId?: string;
};

export interface IOnTerminateSubscriptionParams {
  type: TerminateSubscriptionType;
  revocationReason?: string;
}

export const SubscriptionDetails: React.FC<TOwnProps> = ({
  subscriberState,
  subscriptionState,
  resourceId,
  chargingCardState,
  addBreadcrumb,
  breadcrumbState,
  crumbId,
  getSubscriptions,
  terminateSubscription,
  removeBreadcrumb,
  getSubscriberOnlyRequest,
}) => {
  const classes = useStyles();
  const sharedClasses = useSharedStyles();
  const taskBreadcrumbState = breadcrumbState.breadcrumbs;
  const chargingCards = chargingCardState;
  const subscriber = subscriberState;
  const terminationError = useRef(false);
  const tariffs = useTariffsQuery();
  const { t, terms } = useTypedTranslation();

  const [
    tariffBaseConditions,
    setTariffBaseConditions,
  ] = useState<ITariffConditionModel | null>(null);

  const [tariff, setTariff] = useState<ITariffModel | null>(null);
  const [actionItems, setActionItems] = useState<IActionItem[] | null>(null);

  const [isTariffEligibleForPnc, setIsTariffEligibleForPnc] = useState<boolean>(
    false
  );

  const [subscription, setSubscription] = useState<ISubscriptionModel | null>(
    null
  );

  const [
    subscriptionChargingCard,
    setSubscriptionChargingCard,
  ] = useState<IRFIDCardModel | null>(null);

  const [dialogData, setDialogData] = useState<IChargingCardDialog | null>(
    null
  );

  const [
    terminateSubscriptionDialogIsOpen,
    setTerminateSubscriptionDialogIsOpen,
  ] = useState<TerminateSubscriptionType | null>(null);

  const [
    terminateAtFutureDateDialogIsOpen,
    setTerminateAtFutureDateDialogIsOpen,
  ] = useState<boolean>(false);

  const [
    subscriptionTerminationDate,
    setSubscriptionTerminationDate,
  ] = useState<Date | null>(null);

  const [
    terminateSubscriptionWarningIsOpen,
    setTerminateSubscriptionWarningIsOpen,
  ] = useState<boolean>(false);

  const [statusInfoIsOpen, setStatusInfoIsOpen] = useState<boolean>(false);

  const [
    blockTerminationWithUpcomingSubscription,
    setBlockTerminationWithUpcomingSubscription,
  ] = useState(false);

  const [vin, setVin] = useState<string | undefined>(undefined);
  const [isVinCapableOfPnc, setIsVinCapableOfPnc] = useState<boolean>(false);

  const [showRefreshErrorAlert, setShowRefreshErrorAlert] = useState<boolean>(
    false
  );

  const getTenant = () => {
    const subscriberData = subscriber.data;
    return getDealershipName(subscriberData?.tenant_id) ?? null;
  };
  const tenant = getTenant();

  const updateActionItems = () => {
    setActionItems([
      {
        icon: <CreditCardIcon className={classes.actionItemIcon} />,
        friendlyText: 'Pair with RFID Card',
        component: <PairRfidAndSubscription />,
      },
      {
        icon: <PowerIcon className={classes.actionItemIcon} />,
        friendlyText: 'Enable/Disable Plug & Charge',
        component: (
          <EnableDisablePlugAndChargeModal subscriptionId={resourceId} />
        ),
      },
    ]);
  };

  const onClickRefreshPncStatusButton = () => {
    try {
      getSubscriberOnlyRequest({
        userId: getDefined(subscriber?.data?.iam_id),
      });
      setShowRefreshErrorAlert(false);
    } catch (e) {
      setShowRefreshErrorAlert(true);
    }
  };

  useEffect(() => {
    if (subscription && chargingCards && chargingCards.data) {
      const currentCard = chargingCards.data.find(
        (cc) => cc.subscription_id === subscription.id
      );
      setSubscriptionChargingCard(currentCard || null);

      if (currentCard) {
        setDialogData({
          rfidUid: currentCard.id,
          number: currentCard.number,
          tag: currentCard.tag,
        });
      }
    }
  }, [subscription, chargingCards]);

  const updateTariff = (tariffId: string) => {
    const tariffsList = tariffs.data ?? [];
    const updatedTariff = tariffsList.find(({ id }) => id === tariffId) || null;
    setTariff(updatedTariff);
    setIsTariffEligibleForPnc(updatedTariff?.plug_and_charge_capable ?? false);
  };

  useEffect(() => {
    if (subscription) {
      const getTariffBaseConditions = async () => {
        try {
          const trf = await TariffService.getByConditionId(
            subscription.tariff_id,
            subscription.base_condition_id
          );
          setTariffBaseConditions(trf);
        } catch (err) {
          setTariffBaseConditions(null);
        }
      };

      getTariffBaseConditions();
      updateTariff(subscription.tariff_id);
      updateActionItems();
    }
  }, [subscription, tariffs.data]);

  useEffect(() => {
    if (subscriptionState && resourceId) {
      if (subscriptionState.data) {
        const currentSubscription = subscriptionState.data.find(
          (cc) => cc.id === resourceId
        );

        if (currentSubscription) {
          setSubscription(currentSubscription);
        }
      }
    }
  }, [resourceId, subscriptionState]);

  useEffect(() => {
    const hasUpcomingSub = hasUpcomingSubscription(subscriptionState.data);
    if (subscription && subscription.status === 'active' && hasUpcomingSub) {
      setBlockTerminationWithUpcomingSubscription(true);
    }
  });

  useEffect(() => {
    if (
      (terminateSubscriptionWarningIsOpen ||
        terminateAtFutureDateDialogIsOpen) &&
      !subscriptionState.loading
    ) {
      setTerminateSubscriptionWarningIsOpen(false);
      setTerminateAtFutureDateDialogIsOpen(false);
      terminationError.current = subscriptionState.error !== null;
    }
  }, [subscriptionState.loading]);

  useEffect(() => {
    const getVin = async () => {
      try {
        if (subscriber && !subscriber.loading && subscriber.data !== null) {
          if (subscriber.data.plug_and_charge.pcid) {
            setVin(subscriber.data.plug_and_charge.pcid);
          } else {
            const carVin = await CarService.getVinByIamId(
              getDefined(subscriber.data).iam_id
            );
            setVin(carVin);
          }
        } else {
          setVin(undefined);
        }
      } catch (e) {
        setVin(undefined);
      }
    };
    getVin();
  }, [subscriber]);

  useEffect(() => {
    if (vin) {
      const getVinCapableOfPnc = async () => {
        try {
          const isCapable = await PlugAndChargeService.getPncCapabilityByVIN(
            vin
          );
          setIsVinCapableOfPnc(isCapable);
        } catch (e) {
          setIsVinCapableOfPnc(false);
        }
      };
      getVinCapableOfPnc();
    } else {
      setIsVinCapableOfPnc(false);
    }
  }, [vin, subscriber]);

  const breadcrumbHandler = (props: IBreadcrumbHandlerProps): void =>
    addBreadcrumbHandler({
      ...props,
      addBreadcrumb,
      crumbId,
      taskBreadcrumbState,
    });

  if (!subscription || !tenant) {
    return (
      <div className={sharedClasses.cardElement}>
        <Typography variant='body2'>
          {`Unable to display subscription details: Subscription ${resourceId} or tenant ${subscriber.data?.tenant_id} can not be found.`}
        </Typography>
      </div>
    );
  }

  const linkToChargingCardDetails = () => {
    breadcrumbHandler({
      component: 'ChargingCardDetails',
      friendlyText: 'Charging Card Details',
      id: subscriptionChargingCard?.id,
    });
  };

  const storedSubscriptionTerminationInfo = getSubscriptionTerminationResult(
    subscription.id
  );

  const reloadSubscriberAndSubscriptions = () => {
    try {
      getSubscriberOnlyRequest({
        userId: getDefined(subscriber.data?.iam_id),
      });
      getSubscriptions({
        subscriberId: getDefined(subscriber.data?.id),
      });
    } catch (err) {
      toast.error(t(terms.general_errors.generic));
    }
  };

  const handleCloseSubscriptionDialog = () => {
    if (terminateSubscriptionDialogIsOpen === 'active') {
      reloadSubscriberAndSubscriptions();
    }
    setTerminateSubscriptionDialogIsOpen(null);
    removeBreadcrumb({ position: 0 });
  };

  const onTerminateSubscription = ({
    type,
    revocationReason,
  }: IOnTerminateSubscriptionParams) => {
    if (!subscriber.data) {
      return;
    }
    setSubscriptionTerminationDate(null);
    terminateSubscription({
      subscriptionId: subscription.id,
      subscriberId: subscriber.data.id,
      status: type,
      revocationReason,
    });
    setTerminateSubscriptionDialogIsOpen(type);
  };

  const onOpenTerminateAtFutureDate = () => {
    if (!subscriber.data) {
      return;
    }
    setTerminateSubscriptionWarningIsOpen(false);
    setTerminateAtFutureDateDialogIsOpen(true);
  };

  const onSetTerminationDate = () => {
    if (!subscriber.data || !subscriptionTerminationDate) {
      return;
    }
    terminateSubscription({
      subscriptionId: subscription.id,
      subscriberId: subscriber.data.id,
      status: 'active',
      terminationDate: subscriptionTerminationDate,
    });
    setTerminateSubscriptionDialogIsOpen('active');
  };

  const renderTerminationButton = () => {
    return {
      active: (
        <>
          <Divider />
          <TerminateSubscriptionButton
            type='active'
            onClick={() => {
              setTerminateSubscriptionWarningIsOpen(true);
            }}
          />
        </>
      ),
      upcoming: (
        <>
          <Divider />
          <TerminateSubscriptionButton
            type='upcoming'
            onClick={() => {
              onTerminateSubscription({ type: 'upcoming' });
            }}
          />
        </>
      ),
    }[subscription.status as TerminateSubscriptionType];
  };

  const tariffBaseConditionContent = (
    key:
      | 'subscription_monthly_fee'
      | 'sign_up_fee'
      | 'duration_months'
      | 'notice_period_days'
  ): string => {
    if (
      tariffBaseConditions === null ||
      tariffBaseConditions[key] === undefined
    ) {
      return '-';
    } else if (['subscription_monthly_fee', 'sign_up_fee'].includes(key)) {
      return `${tariffBaseConditions[key]} ${tariffBaseConditions.currency}`;
    } else if (['duration_months', 'notice_period_days'].includes(key)) {
      return `${tariffBaseConditions[key]} ${
        key === 'duration_months' ? 'month' : 'day'
      }${checkIfSingle(tariffBaseConditions[key])}`;
    } else {
      return '-';
    }
  };

  const getTariffInformationItems = () => [
    {
      headline: 'Charging Prices',
      content: (
        <Typography
          classes={{
            root: classes.textWithLink,
          }}
          data-testid='tariff-card'
          variant='body2'
          onClick={() =>
            breadcrumbHandler({
              component: 'SubscriptionTariffPDFViewer',
              friendlyText: 'Subscription Tariff PDF',
              id: subscription.base_condition_id,
            })
          }
        >
          Charging Prices PDF
        </Typography>
      ),
      id: 'charging_prices',
    },
    {
      headline: 'Monthly Fee',
      content: tariffBaseConditionContent('subscription_monthly_fee'),
      id: 'monthly_fee',
    },
    {
      headline: 'Duration',
      content: tariffBaseConditionContent('duration_months'),
      id: 'duration',
    },
    {
      headline: 'Notice Period',
      content: tariffBaseConditionContent('notice_period_days'),
      id: 'notice_period',
    },
    {
      headline: 'Signup Fee',
      content: tariffBaseConditionContent('sign_up_fee'),
      id: 'signup_fee',
    },
  ];

  const getVoucherDetailsItems = () => [
    {
      title: 'Total Free Charge Credit',
      content: `${subscription.voucher_details?.total_credit.toFixed(2)} ${
        subscription.voucher_details?.currency
      }`,
    },
    {
      title: 'Remaining Free Charge Credit',
      content: `${subscription.voucher_details?.remaining_credit.toFixed(2)} ${
        subscription.voucher_details?.currency
      }`,
    },
    {
      title: 'Free Charge Credit Last Used',
      content: subscription.voucher_details?.last_used_at
        ? formatIsoDates(subscription.voucher_details.last_used_at)
        : '-',
    },
  ];

  return (
    <div
      className={sharedClasses.cardElement}
      data-testid='subscription-details-component'
    >
      <SubscriptionDetailsHeader
        tariffBaseCondtionName={tariffBaseConditions?.name}
        actionItems={actionItems}
      />
      <Grid container alignItems='flex-start' direction='row'>
        <Grid item xs={12}>
          <CardSubheadline title='Details' />
        </Grid>
        <Grid item xs={4}>
          <SignupDate
            startDate={subscription.start_date}
            subscriptionId={subscription.id}
          />
        </Grid>
        <Grid item xs={4}>
          <BasicInformationItem
            title='Preliminary End'
            content={formatIsoDates(subscription.end_date)}
          />
        </Grid>
        <Grid item xs={4}>
          <SubscriptionStatus
            subscription={subscription}
            showInfoButton={storedSubscriptionTerminationInfo !== null}
            onInfoButtonClick={() => setStatusInfoIsOpen(true)}
          />
        </Grid>
        <Grid item xs={4}>
          <div className={sharedClasses.cardDetail}>
            <Typography
              className={classes.headingWithIcon}
              data-testid='subscription-linked-charging-cards-title'
              variant='h6'
            >
              Linked Charging Card
              {dialogData && (
                <Tooltip tooltipName='more-info'>
                  <DialogChargingCard
                    rfidUid={dialogData.rfidUid}
                    cardNumber={dialogData.number}
                    tag={dialogData.tag}
                  />
                </Tooltip>
              )}
            </Typography>
            <div className={sharedClasses.cardDetailData}>
              {subscriptionChargingCard ? (
                <TextLink
                  testId='linked-charging-card'
                  linkText={subscriptionChargingCard.number || '-'}
                  linkTo={linkToChargingCardDetails}
                />
              ) : (
                '-'
              )}
            </div>
          </div>
        </Grid>
        <Grid item xs={4}>
          <BasicInformationItem
            title='Automatic Renewal'
            content={
              subscription.automatic_renewal ? 'Activated' : 'Deactivated'
            }
          />
        </Grid>
        <Grid item xs={4} />
        <Grid item xs={12}>
          <BasicInformationItem
            title='Billing Address'
            content={
              (subscriber?.data && parseAddress(subscriber.data.address)) || '-'
            }
          />
        </Grid>
        <Grid item xs={12}>
          <CardSubheadline title='Tariff Information' />
        </Grid>
        <Grid item xs={4}>
          <Tariff tariff={tariff}>
            <Typography variant='body2'>
              {tariffBaseConditions?.name || '-'}
            </Typography>
          </Tariff>
        </Grid>
        {getTariffInformationItems().map((item, index) => (
          <Grid item xs={4} key={index}>
            <InformationItem item={{ ...item, id: item.headline }} />
          </Grid>
        ))}
        <Grid item xs={4}>
          <StatefulInformationItem
            item={{
              id: 'tariff-eligibility-for-pnc',
              headline: 'Tariff eligibility for Plug & Charge',
              content: (
                <TariffEligibilityStatus status={isTariffEligibleForPnc} />
              ),
            }}
            queryState={tariffs}
          />
        </Grid>
        {subscription.voucher_details &&
          getVoucherDetailsItems().map((item, index) => {
            return (
              <Grid item xs={4} key={index}>
                <BasicInformationItem
                  title={item.title}
                  content={item.content}
                />
              </Grid>
            );
          })}
        <Grid item xs={12}>
          <CardSubheadline title='Plug & Charge' />
        </Grid>
        {subscriber.data && subscription && (
          <>
            <Grid item xs={5}>
              <BasicInformationItem
                title={'VIN associated with Plug & Charge'}
                content={vin ?? '-'}
              />
            </Grid>
            <Grid item xs={7}>
              <InformationItem
                item={{
                  id: 'vin-capability',
                  headline: 'VIN capable of Plug & Charge',
                  content: vin ? (
                    <VinCapabilityStatus status={isVinCapableOfPnc} />
                  ) : (
                    '-'
                  ),
                }}
              />
            </Grid>
            <Grid item xs={5}>
              <InformationItem
                item={{
                  id: 'plug-and-charge-status',
                  headline: 'Status',
                  content: subscriber.data.plug_and_charge.state ? (
                    <Grid container item alignItems='center'>
                      <Grid item style={{ alignSelf: 'center' }}>
                        <PlugAndChargeStatus
                          status={subscriber.data.plug_and_charge.state}
                        />
                      </Grid>
                      <Grid item>
                        <IconButton
                          color='primary'
                          className={classes.refreshIcon}
                          onClick={onClickRefreshPncStatusButton}
                          size='large'
                        >
                          <RefreshIcon fontSize='small' />
                        </IconButton>
                      </Grid>
                    </Grid>
                  ) : (
                    '-'
                  ),
                }}
              />
            </Grid>
            <Grid item xs={7}>
              <InformationItem
                item={{
                  headline: 'Plug & Charge Event History',
                  content: (
                    <Typography
                      classes={{
                        root: classes.textWithLink,
                      }}
                      data-testid='tariff-card'
                      variant='body2'
                      onClick={() =>
                        breadcrumbHandler({
                          component: 'PlugAndChargeEventHistory',
                          friendlyText: 'Plug & Charge Event History',
                          id: subscription.id,
                        })
                      }
                    >
                      History of changes
                    </Typography>
                  ),
                  id: 'plug_and_charge_event_history',
                }}
              />
            </Grid>
            {showRefreshErrorAlert && (
              <Grid item xs={12} className={classes.buttonErrorAlert}>
                <Alert severity='error' data-testid='refreshPncErrorAlert'>
                  Something went wrong, please try again
                </Alert>
              </Grid>
            )}
          </>
        )}
      </Grid>
      <div className={classes.terminateSubscription}>
        {renderTerminationButton()}
      </div>
      <TerminateUpcomingSubscriptionDialog
        hasError={!!subscriptionState.error}
        isOpen={terminateSubscriptionDialogIsOpen === 'upcoming'}
        onClose={handleCloseSubscriptionDialog}
      />
      <TerminateSubscriptionWarningDialog
        open={terminateSubscriptionWarningIsOpen}
        onClose={() => {
          setTerminateSubscriptionWarningIsOpen(false);
        }}
        hasUpcomingSubscription={blockTerminationWithUpcomingSubscription}
        doTerminate={onTerminateSubscription}
        doSetTerminationDate={onOpenTerminateAtFutureDate}
        isLoading={subscriptionState.loading}
        tenant={tenant}
      />
      <TerminateSubscriptionSummaryDialog
        open={
          terminateSubscriptionDialogIsOpen === 'active' &&
          subscriptionState.loading === false
        }
        onClose={handleCloseSubscriptionDialog}
        hasError={terminationError.current}
        tenant={tenant}
        terminationDate={subscriptionTerminationDate}
      />
      <TerminateAtFutureDateDialog
        open={terminateAtFutureDateDialogIsOpen}
        onClose={() => {
          setTerminateAtFutureDateDialogIsOpen(false);
        }}
        onDateChange={setSubscriptionTerminationDate}
        doSetTerminationDate={onSetTerminationDate}
        isLoading={subscriptionState.loading}
        currentEndDate={new Date(subscription.end_date)}
      />
      <StatusInformationDialog
        open={statusInfoIsOpen}
        onClose={() => {
          setStatusInfoIsOpen(false);
        }}
        terminateSubscriptionResult={storedSubscriptionTerminationInfo}
      />
    </div>
  );
};

export default connector(SubscriptionDetails);
