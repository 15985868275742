import React, { useEffect, useState } from 'react';
import { TariffService } from '../../../../services/tariffs';
import { Header } from '../../../CollectionCards/Header';
import connector, { IPropsFromState } from '../../../Connector/Connector';
import subscriptionLogoImage from '../../../../assets/image/subscription.svg';
import PdfViewer from '../../../shared/PdfViewer';
import useSharedStyles from '../../../shared/useSharedStyles';

const SubscriptionLogo = (
  <img src={subscriptionLogoImage} alt='Subscription Tariff Icon' />
);

export interface ISubscriptionTariffPDFViewer extends IPropsFromState {
  resourceId?: string;
}

const SubscriptionTariffPDFViewer: React.FC<ISubscriptionTariffPDFViewer> = ({
  resourceId,
}) => {
  const [tariffPdf, setTariffPdf] = useState<Blob | null>(null);
  const [tariffBaseConditionId, setTariffBaseConditionId] = useState<
    string | null
  >(null);
  const sharedClasses = useSharedStyles();

  useEffect(() => {
    if (resourceId && tariffBaseConditionId !== resourceId) {
      setTariffBaseConditionId(resourceId);
    }
  }, [resourceId]);

  useEffect(() => {
    const getPDF = async (tariffId: string) => {
      const { data } = await TariffService.getTariffPdf(tariffId);

      setTariffPdf(data);
    };

    if (tariffBaseConditionId) {
      getPDF(tariffBaseConditionId).catch(() => {
        setTariffPdf(null);
      });
    }
  }, [tariffBaseConditionId]);

  return (
    <div
      className={sharedClasses.cardElement}
      data-testid='tariff-pdf-detail-component'
    >
      <Header title='Subscription Tariff' icon={SubscriptionLogo} />
      {tariffPdf && <PdfViewer file={tariffPdf} />}
    </div>
  );
};

export default connector(SubscriptionTariffPDFViewer);
