import makeStyles from '@mui/styles/makeStyles';
import createStyles from '@mui/styles/createStyles';

const useStyles = makeStyles(() =>
  createStyles({
    actionItemIcon: {
      height: '100%',
      width: 'auto',
    },
    headingWithIcon: {
      display: 'flex',
      alignItems: 'center',
      lineHeight: 1, // !important,
    },
    terminateSubscription: {
      marginTop: '24px',
    },
    textWithLink: {
      textDecoration: 'underline',
      cursor: 'pointer',
    },
    refreshIcon: {
      padding: '5px',
      color: 'rgba(0, 0, 0, 0.54)',
    },
    buttonErrorAlert: {
      marginTop: '0.5em',
    },
  })
);

export default useStyles;
