import update from 'immutability-helper';
import { State } from '..';
import { ActionInterface } from '../actions';

export default (
  state: State,
  action: ActionInterface.SetSubscriberTemp
): State => {
  const { subscriber } = action;
  const currentSubscriber = state;

  const updatedSubscriber = update(currentSubscriber, {
    searchData: {
      $set: subscriber,
    },
  });

  return {
    ...state,
    ...updatedSubscriber,
  };
};
