import { AppDispatch } from '../../../../store';
import { ActionCreator } from '../../../../store/state/actions';
import { ActionInterface } from '../../../../store/state/breadcrumbs/actions';
import { Dispatcher } from '../index';

interface IDispatchers {
  addBreadcrumb: Dispatcher<ActionInterface.AddBreadcrumb>;
  updateBreadcrumb: Dispatcher<ActionInterface.UpdateBreadcrumb>;
  removeBreadcrumb: Dispatcher<ActionInterface.RemoveBreadcrumb>;
  setCurrentBreadcrumb: Dispatcher<ActionInterface.SetCurrentBreadcrumb>;
}

export const createDispatchers = (dispatch: AppDispatch): IDispatchers => ({
  addBreadcrumb: (payload) =>
    dispatch(ActionCreator.breadcrumbs.AddBreadcrumb(payload)),

  updateBreadcrumb: (payload) =>
    dispatch(ActionCreator.breadcrumbs.UpdateBreadcrumb(payload)),

  removeBreadcrumb: (payload) =>
    dispatch(ActionCreator.breadcrumbs.RemoveBreadcrumb(payload)),

  setCurrentBreadcrumb: (payload) =>
    dispatch(ActionCreator.breadcrumbs.SetCurrentBreadcrumb(payload)),
});
