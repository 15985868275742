import React from 'react';
import * as yup from 'yup';

import { Form, Select } from '../../../../shared/form-elements';
import { useTypedTranslation } from '../../../../../custom-hooks/useTypedTranslation';
import { useStyles } from './useStyles';
import { SubmitButton } from '../../../../shared/form-elements/SubmitButton/SubmitButton';
import {
  mapRevocationReasonsToSelectOptions,
  useGetRevocationReasons,
} from '../../../../../state/queries/useGetRevocationReasons';
import { IOnTerminateSubscriptionParams } from '../../SubscriptionDetails/SubscriptionDetails';

interface IProps {
  callback: (params: IOnTerminateSubscriptionParams) => void;
  isLoading: boolean;
  disabled: boolean;
}

interface IFormFields {
  revocationReason: string;
}

export const TerminateSubscriptionForm: React.FC<IProps> = ({
  callback,
  isLoading,
  disabled,
}) => {
  const { t, terms } = useTypedTranslation();
  const classes = useStyles();
  const { data: revocationReasons } = useGetRevocationReasons();

  const onSubmit = ({ revocationReason }: IFormFields) => {
    callback({ type: 'active', revocationReason });
  };

  const schema = yup.object().shape({
    revocationReason: yup.string().required(),
  });

  return (
    <Form
      className={classes.form}
      onSubmit={onSubmit}
      schema={schema}
      defaultValues={{ revocationReason: '' }}
      mode='onChange'
    >
      <Select
        name='revocationReason'
        label={t(terms.subscription_termination.revocation_reason_label)}
        options={mapRevocationReasonsToSelectOptions(revocationReasons ?? [])}
        disabled={!revocationReasons?.length}
        isRequired
      />
      <SubmitButton
        text={t(terms.subscription_termination.terminate_subscription_now)}
        disabled={disabled}
        isLoading={isLoading}
        variant='alert'
      />
    </Form>
  );
};
