import {
  IChargingRecord,
  IChargingRecordViewModel,
} from '../types/chargingRecords';

import { IActiveSession } from '../types/activeSessions';
import { IChargingSessionModel } from '../types/chargingSession';
import differenceInSeconds from 'date-fns/differenceInSeconds';
import { getAuthenticationMethod } from '../utils/getAuthenticationMethod';
import { getAuthorisationMode } from '../utils/stationUtils';
import isBefore from 'date-fns/isBefore';

export class ChargeRecord implements IChargingRecordViewModel {
  id!: string;
  blocking_fee?: number;
  blocking_fee_duration_in_minutes?: number;
  creation_date!: string;
  duration!: number;
  consumption!: number;
  price?: number;
  station_id!: string;
  card_id!: string;
  start_date_time!: string;
  stop_date_time?: string;
  type!: string;
  currency!: string;
  city!: string;
  vat_percentage!: number;
  location_operator_name!: string;
  location_address!: string;
  authentication_method!: string;
  rfid_card_serial_number!: string;
  discount!: number;
  vat_amount!: number;
  cpo_country_code!: string;
  cpo_party_id!: string;
  cpo_price!: number;
  cpo_price_exchanged!: number;
  cpo_price_currency!: string;
  cpo_pricing_source?: string;
  guaranteed_cpo_price?: number;
  guaranteed_cpo_price_currency?: string;
  location_id!: string;
  station_serial_number!: string;
  station_name!: string;
  location_evse_id!: string;
  billing_status?: string;
  session_faulted?: boolean;
  authorization_mode?: string;
  connector_id!: number;
  invalid_duration!: boolean;
  coupon_reduction!: number;
  external_cdr_id: string;
  charging_condition_id!: string;
  location_connector_power_type!: string;
  subscription_id: string;

  constructor(data: IChargingRecord | IChargingSessionModel | IActiveSession) {
    this.id = '';
    this.creation_date = '';
    this.duration = 0;
    this.consumption = 0;
    this.price = undefined;
    this.station_id = '';
    this.card_id = '';
    this.start_date_time = '';
    this.stop_date_time = '';
    this.type = '';
    this.currency = '';
    this.city = '';
    this.vat_percentage = 0;
    this.location_operator_name = '';
    this.location_address = '';
    this.authentication_method = '';
    this.rfid_card_serial_number = '';
    this.discount = 0;
    this.vat_amount = 0;
    this.cpo_country_code = '';
    this.cpo_party_id = '';
    this.cpo_price = 0;
    this.cpo_price_exchanged = 0;
    this.cpo_price_currency = '';
    this.cpo_pricing_source = '';
    this.guaranteed_cpo_price = 0;
    this.guaranteed_cpo_price_currency = '';
    this.location_id = '';
    this.station_serial_number = '';
    this.station_name = '';
    this.location_evse_id = '';
    this.billing_status = '';
    this.session_faulted = false;
    this.authorization_mode = '';
    this.connector_id = 0;
    this.invalid_duration = false;
    this.coupon_reduction = 0;
    this.external_cdr_id = '';
    this.charging_condition_id = '';
    this.location_connector_power_type = '';
    this.subscription_id = '';

    if (data.type === 'public') {
      this.setPublicChargingRecord(data as IChargingSessionModel);
    }

    if (data.type === 'private' || data.type === 'business') {
      this.setPrivateBusinessChargingRecord(data as IChargingRecord);
    }

    if (data.type === 'active') {
      this.setActiveSessionRecord(data as IActiveSession);
    }
  }

  setPublicChargingRecord(data: IChargingSessionModel): void {
    this.type = data.type || '';
    this.id = data.id;
    this.blocking_fee = data.blocking_fee;
    this.blocking_fee_duration_in_minutes =
      data.blocking_fee_duration_in_minutes;
    this.creation_date = data.created_at;
    this.duration = data.total_time;
    this.consumption = data.total_energy;
    this.price = data.total_price;
    this.station_id = data.location_evse_id;
    this.location_evse_id = data.location_evse_id || '';
    this.card_id = data.rfid_card_id || '';
    this.start_date_time = data.start_date_time;
    this.stop_date_time = data.end_date_time;
    this.billing_status = data.billing_status;
    this.currency = data.local_currency || '';
    this.city = data.location_city || '';
    this.vat_percentage = data.vat_percentage || 0;
    this.location_operator_name = data.location_operator_name || '';
    this.location_address = data.location_address || '';
    this.authentication_method = data.token_type || '';
    this.discount = data.discount || 0;
    this.vat_amount = data.vat_amount || 0;
    this.cpo_country_code = data.cpo_country_code || '';
    this.cpo_party_id = data.cpo_party_id || '';
    this.cpo_price = data.cpo_price || 0;
    this.cpo_price_exchanged = data.cpo_price_exchanged || 0;
    this.cpo_price_currency = data.cpo_price_currency || '';
    this.cpo_pricing_source = data.cpo_pricing_source || '';
    this.guaranteed_cpo_price = data.guaranteed_cpo_price || 0;
    this.guaranteed_cpo_price_currency =
      data.guaranteed_cpo_price_currency || '';
    this.location_id = data.elli_location_id || '';
    this.coupon_reduction = data.coupon_reduction || 0;
    this.external_cdr_id = data.external_cdr_id || '';
    this.charging_condition_id = data.charging_condition_id || '';
    this.location_connector_power_type =
      data.location_connector_power_type || '';
    this.subscription_id = data.subscription_id || '';
  }

  setPrivateBusinessChargingRecord(data: IChargingRecord): void {
    this.type = data.type || '';
    this.id = data.transaction_id;
    this.creation_date = data.start_date_time;
    this.session_faulted = data.session_faulted;
    this.duration = this.getTotalTime(
      data.start_date_time,
      data.stop_date_time || ''
    );
    this.consumption = data.total_energy_wh ? data.total_energy_wh / 1000 : 0;
    this.station_id = data.station_id;
    this.card_id = data.rfid_card_id || '';
    this.start_date_time = data.start_date_time;
    this.stop_date_time = data.stop_date_time;
    this.rfid_card_serial_number = data.rfid_card_serial_number || '';
    this.location_id = data.station_id || '';
    this.station_serial_number = data.station_serial_number || '';
    this.station_name = data.station_name || '';
    this.connector_id = data.connector_id || 0;
    this.authorization_mode = data.authorization_mode
      ? getAuthorisationMode(data.authorization_mode)
      : undefined;
    this.authentication_method = getAuthenticationMethod(
      data.authentication_method
    );
  }

  setActiveSessionRecord(data: IActiveSession): void {
    this.id = data.id;
    this.type = data.type as string;
    this.station_id = data.station_id;
    this.card_id = data.rfid_card_id || '';
    this.creation_date = data.start_date_time;
    this.duration = this.getTotalTime(data.start_date_time, '');
    this.consumption = data.energy_consumption_wh
      ? data.energy_consumption_wh / 1000
      : 0;
    this.rfid_card_serial_number = data.rfid_card_serial_number || '';
  }

  getTotalTime(start: string, end: string): number {
    const dateStart = start ? new Date(start) : new Date();
    const dateEnd = end ? new Date(end) : new Date();

    // end can be an empty string in case of a faulty C@H charge record.
    // This can happen when an agent manually terminates a charging session.
    // In that case we don't want to display a duration in the UI.
    if (!isBefore(dateStart, dateEnd) || end === '') {
      this.invalid_duration = true;
    }
    return differenceInSeconds(dateEnd, dateStart);
  }
}
