import makeStyles from '@mui/styles/makeStyles';
import createStyles from '@mui/styles/createStyles';
import { Theme } from '@mui/material/styles';

interface IProps {
  showBanner?: boolean;
}

const useStyles = makeStyles<Theme, IProps>((theme) =>
  createStyles({
    debug: {
      marginTop: '50px',
      color: '#ddd',
    },
    crmWrapper: {
      height: '100%',
      boxSizing: 'border-box',
      position: 'relative',
      background: '#f5f6f8',
      overflowX: 'scroll',
      padding: `0 ${theme.portalPadding} ${theme.portalPadding} ${theme.portalPadding}`,
      outline: 'none',
      fontFamily: 'Inter, sans-serif',
      width: '100vw',
    },
    contentWrapper: {
      display: 'flex',
      width: '100%',
      marginTop: (props: IProps) => (props.showBanner ? 96 : 44),
    },
    topBar: {
      display: 'grid',
      rowGap: 12,
      padding: `12px ${theme.portalPadding}`,
      background: theme.colorBodyBackground,
    },
    isFixed: {
      zIndex: 10,
      position: 'fixed',
      top: 0,
      left: 0,
      width: '100%',
    },
    navigation: {
      display: 'grid',
      alignItems: 'center',
      gridTemplateColumns: 'auto 1fr auto',
    },
    loading: {
      display: 'flex',
      width: '100%',
      height: '100%',
      alignItems: 'center',
      justifyContent: 'center',
    },
    root: {
      height: '100%',
    },
    actionButton: {
      marginBottom: '10px !important',
      '&:lastChild': {
        marginBottom: '0', // !important,
      },
    },
    welcomeWrapper: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      height: '100%',
    },
    logoContainer: {
      height: '125px',
      img: {
        height: '100%',
      },
    },
    welcomeImageContainer: {
      width: '320px',
      margin: '10px auto 30px',
      img: {
        width: '100%',
      },
    },
    closeIconWrapper: {
      background: '#ff8c00',
      borderRadius: '4px',
      width: '20px',
      height: '20px',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      cursor: 'pointer',
      boxShadow: '0px 3px 10px 1px #ff8c0042',
    },
    closeIconCRM: {
      top: '20px',
      position: 'fixed',
      right: '0',
      background: '#ff8c00',
      borderRadius: '4px',
      width: '20px',
      height: '20px',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      cursor: 'pointer',
      marginRight: '16px',
      boxShadow: '0px 3px 10px 1px #ff8c0042',
      zIndex: 11,
    },
    invalidRoleBox: {
      border: '2px solid #f00066',
      padding: '12px 36px',
    },

    invalidTitle: {
      textAlign: 'center',
      fontSize: '16px',
      fontWeight: 600,
      lineHeight: 15,
      color: '#f00066',
    },
  })
);

export default useStyles;
