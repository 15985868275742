import React from 'react';
import useStyles from './useStyles';
import { Typography } from '../../shared/Typography/Typography';

export interface IOwnProps {
  title: string;
  listElements?: JSX.Element[];
  bodyText?: string;
  isLastItem?: boolean;
}

const VinResultElement: React.FC<IOwnProps> = ({
  listElements,
  title,
  bodyText,
  isLastItem = false,
}) => {
  const classes = useStyles();

  return (
    <div className={!isLastItem ? classes.section : ''}>
      <Typography variant='h2' className={classes.sectionHeadline}>
        {title}
      </Typography>
      {bodyText && (
        <Typography variant='body2' className={classes.sectionDescription}>
          {bodyText}
        </Typography>
      )}
      {listElements && <ul className={classes.list}>{listElements}</ul>}
    </div>
  );
};

export default VinResultElement;
