import React, {
  ChangeEvent,
  useContext,
  useEffect,
  useReducer,
  useState,
} from 'react';
import { Grid } from '@mui/material';

import connector, { IPropsFromState } from '../../../Connector/Connector';
import useStyles from './useStyles';
import InputFieldWithStatus from '../InputFieldWithStatus';
import { ISubscriptionModel } from '../../../../types/subscriber';
import { SubscriptionStateModel } from '../../../../store/state/subscription';
import {
  IPairingState,
  PAIRING_ACTIONS,
  pairingReducer,
} from './pairingReducer';
import MultiLineButton from '../../Buttons/MultilineButton';
import { DialogContextState } from '../context/ActionDialogContext';
import { preparePairing } from './utils';
import { Status } from '../../Icons/StatusIcon';
import DialogHeadline from '../DialogHeadline';
import { useTypedTranslation } from '../../../../custom-hooks/useTypedTranslation';

export type IOwnProps = IPropsFromState & {
  rfidCardId?: string;
};

interface IInitialStateData {
  rfidCardId?: string;
  subscription?: ISubscriptionModel;
}

const getActiveSubscription = (subscriptions: SubscriptionStateModel) => {
  if (!subscriptions) {
    return undefined;
  }

  // Currently there is only one active subscription possible. This can be changed
  // to return all subscriptions later on to provide a selection to the user
  return subscriptions.data?.find(({ status }) => status === 'active');
};

const getInitialState: (params: IInitialStateData) => IPairingState = ({
  rfidCardId,
  subscription,
}) => ({
  isDisabledSubmit: !subscription || !rfidCardId,
  pairingState: rfidCardId ? Status.OPEN : Status.EMPTY_INPUT,
  isDisabledInput: !!rfidCardId || !subscription,
  message: subscription ? '' : 'User has no active subscription',
});

/**
 * Handles pairing of rfid card and subscription
 * @param rfidCardId predefined rfid card id to be paired
 * @param subscriptionState user's subscriptions
 */
export const PairRfidAndSubscription: React.FC<IOwnProps> = ({
  rfidCardId,
  subscriptionState,
  getChargingCardRequest,
}) => {
  const classes = useStyles();
  const { handleCloseDialog } = useContext(DialogContextState);
  const { t, terms } = useTypedTranslation();
  const [subscription] = useState<ISubscriptionModel | undefined>(() =>
    getActiveSubscription(subscriptionState)
  );
  const [inputRfidCardId, setInputRfidCardId] = useState<string>(
    rfidCardId || ''
  );
  const [state, dispatch] = useReducer(
    pairingReducer,
    { rfidCardId, subscription },
    getInitialState
  );
  const isPending = state.pairingState === Status.PENDING;

  useEffect(() => {
    if (rfidCardId) {
      dispatch({
        type: PAIRING_ACTIONS.STATIC_RFID,
      });
    }
  }, [rfidCardId]);

  const handlePairing = async () => {
    await preparePairing(dispatch, subscription?.id, inputRfidCardId);
  };

  useEffect(() => {
    if (state.pairingState === Status.SUCCESS && subscription) {
      getChargingCardRequest({
        subscriberId: subscription.subscriber_id,
      });
    }
  }, [state.pairingState, subscription]);

  useEffect(() => {
    if (
      inputRfidCardId?.length > 0 &&
      state.pairingState === Status.EMPTY_INPUT
    ) {
      dispatch({ type: PAIRING_ACTIONS.OPEN });
    } else if (
      inputRfidCardId?.length === 0 &&
      state.pairingState !== Status.EMPTY_INPUT
    ) {
      dispatch({ type: PAIRING_ACTIONS.EMPTY_INPUT });
    }
  }, [inputRfidCardId?.length, state.isDisabledInput]);

  useEffect(() => {
    if (!subscription) {
      dispatch({
        type: PAIRING_ACTIONS.ERROR,
        payload: t(
          terms.pair_rfid_card_and_subscription.warnings.no_active_subscription
        ),
      });
    }
  }, [subscription]);

  const handleRfidCardChange = (e: ChangeEvent<HTMLInputElement>): void => {
    setInputRfidCardId(e.target.value);
  };

  return (
    <>
      <Grid item>
        <DialogHeadline
          headline={t(terms.pair_rfid_card_and_subscription.headline)}
          subHeadline={
            !rfidCardId
              ? t(terms.pair_rfid_card_and_subscription.sub_headline)
              : undefined
          }
        />
      </Grid>

      <Grid item className={classes.centeredContainer}>
        <InputFieldWithStatus
          title={t(terms.pair_rfid_card_and_subscription.rfid_card_input)}
          value={inputRfidCardId}
          onChange={handleRfidCardChange}
          isDisabled={isPending || state.isDisabledInput}
          status={state.pairingState}
          message={state.message}
        />
      </Grid>

      <Grid item className={classes.centeredContainer}>
        <div className={classes.buttonContainer}>
          {state.pairingState === Status.SUCCESS ? (
            <MultiLineButton
              handleClick={handleCloseDialog}
              mainText={t(terms.general.close)}
            />
          ) : (
            <MultiLineButton
              handleClick={handlePairing}
              isDisabled={isPending || state.isDisabledSubmit}
              mainText={t(
                terms.pair_rfid_card_and_subscription.submit.main_text
              )}
              smallText={t(
                terms.pair_rfid_card_and_subscription.submit.small_text
              )}
            />
          )}
        </div>
      </Grid>
    </>
  );
};

export default connector(PairRfidAndSubscription);
