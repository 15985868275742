import React from 'react';
import { IBreadcrumbHandlerProps } from '../../../utils/addBreadcrumbHandler';
import { AccountsActionButton } from './ActionButtons/AccountsActionButton';
import { ChargingCardsActionButton } from './ActionButtons/ChargingCardsActionButton';
import { ChargingSessionsActionButton } from './ActionButtons/ChargingSessionsActionButton';
import { InvoicesActionButton } from './ActionButtons/InvoicesActionButton';
import { OrdersActionButton } from './ActionButtons/OrdersActionButton';
import { StationsActionButton } from './ActionButtons/StationsActionButton';
import { SubscriptionsActionButton } from './ActionButtons/SubscriptionsActionButton';

export type ActionButton =
  | 'Orders'
  | 'Account'
  | 'ChargingCards'
  | 'Invoices'
  | 'Stations'
  | 'Subscriptions'
  | 'PrivateChargingSessions'
  | 'PublicChargingSessions'
  | 'BusinessChargingSessions';

export interface IActionButtonConfig {
  breadcrumbHandler: (props: IBreadcrumbHandlerProps) => void;
  number: number;
  type: ActionButton;
}

const createActionButtonFactory = (
  buttonConfig: IActionButtonConfig,
  index: number
): React.ReactNode => {
  const { breadcrumbHandler, number, type } = buttonConfig;

  return {
    ChargingCards: (
      <ChargingCardsActionButton
        key={index}
        breadcrumbHandler={breadcrumbHandler}
        chargingCardsNumber={number}
      />
    ),
    Account: (
      <AccountsActionButton
        key={index}
        breadcrumbHandler={breadcrumbHandler}
        accountsNumber={number}
      />
    ),
    PrivateChargingSessions: (
      <ChargingSessionsActionButton
        key={index}
        breadcrumbHandler={breadcrumbHandler}
        chargingSessionsNumber={number}
        defaultFilter='Private'
      />
    ),
    PublicChargingSessions: (
      <ChargingSessionsActionButton
        key={index}
        breadcrumbHandler={breadcrumbHandler}
        chargingSessionsNumber={number}
        defaultFilter='Public'
      />
    ),
    BusinessChargingSessions: (
      <ChargingSessionsActionButton
        key={index}
        breadcrumbHandler={breadcrumbHandler}
        chargingSessionsNumber={number}
        defaultFilter='Business'
      />
    ),
    Orders: (
      <OrdersActionButton
        key={index}
        breadcrumbHandler={breadcrumbHandler}
        cardOrdersNumber={number}
      />
    ),
    Invoices: (
      <InvoicesActionButton
        key={index}
        breadcrumbHandler={breadcrumbHandler}
        invoicesNumber={number}
      />
    ),
    Subscriptions: (
      <SubscriptionsActionButton
        key={index}
        breadcrumbHandler={breadcrumbHandler}
        subscriptionsNumber={number}
      />
    ),
    Stations: (
      <StationsActionButton
        key={index}
        breadcrumbHandler={breadcrumbHandler}
        organizationStationsNumber={number}
      />
    ),
  }[type];
};

export const createActionButtons = (
  buttonConfigs: (IActionButtonConfig | undefined)[]
): React.ReactNode[] => {
  return buttonConfigs.map((buttonConfig, index) => {
    if (buttonConfig === undefined) {
      return;
    }

    return createActionButtonFactory(buttonConfig, index);
  });
};
