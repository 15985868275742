import React from 'react';
import find from 'lodash/find';
import isEqual from 'lodash/isEqual';
import { format } from 'date-fns';
import {
  IColumn,
  IRow,
} from '../../../shared/AssetTable/AssetTable/AssetTable';
import {
  IFieldNames,
  mapFieldsForTable,
} from '../../../../utils/mapFieldsForTable';
import {
  IInvoiceRefundLineItem,
  IInvoiceLineItem,
} from '../../../../types/invoice';

interface IRefundContent {
  onRefund: (cdrLineItemId: string) => void;
  processId: string;
}

const RefundContent: React.FC<IRefundContent> = ({ onRefund, processId }) => (
  <span
    role='button'
    style={{
      textDecoration: 'underline',
      cursor: 'pointer',
      fontWeight: 700,
    }}
    data-testid='prefill-cdr-refund-cta'
    onClick={() => onRefund(processId)}
  >
    Add Refund
  </span>
);

const fieldNames: IFieldNames = {
  external_cdr_id: {
    name: 'CDR ID Number',
  },
  cdr_start_date: {
    name: 'Start Date',
  },
  total_price_incl_tax: {
    name: 'Amount',
  },
  consumption: {
    name: 'Consumption',
  },
};

export const getColumns = (withRefundAction: boolean): IColumn[] => {
  if (withRefundAction) {
    const refundFieldName: IFieldNames = {
      action: {
        name: 'Add Refund',
      },
    };
    const fieldsWithRefundActions = { ...fieldNames, ...refundFieldName };
    return mapFieldsForTable(fieldsWithRefundActions, []);
  }
  return mapFieldsForTable(fieldNames, []);
};

export const getRows = (
  invoiceLineItems: IInvoiceLineItem[],
  refundEntries: IInvoiceRefundLineItem[],
  onRefund?: (cdrLineItemId: string) => void,
  selectedCdrId?: string
): IRow[] => {
  return invoiceLineItems.map((invoiceLineItem) => {
    const row = {
      id: invoiceLineItem.process_id,
      data: {
        external_cdr_id: {
          content: invoiceLineItem.process_id || '-',
        },
        cdr_start_date: {
          content: format(
            new Date(invoiceLineItem.start_date),
            'dd.MM.YYY HH:mm'
          ),
        },
        total_price_incl_tax: {
          content: `${invoiceLineItem.gross_amount} ${invoiceLineItem.currency}`,
        },
        consumption: {
          content: `${invoiceLineItem.quantity} kWh`,
        },
      },
    };

    const isSelectedForRefund = isEqual(
      invoiceLineItem.process_id,
      selectedCdrId
    );
    const hasRefundEntry = find(refundEntries, {
      invoice_line_item_id: invoiceLineItem.id,
    });

    if (onRefund) {
      return {
        ...row,
        data: {
          ...row.data,
          action: {
            content:
              isSelectedForRefund || hasRefundEntry ? (
                <div />
              ) : (
                <RefundContent
                  onRefund={onRefund}
                  processId={invoiceLineItem.process_id}
                />
              ),
          },
        },
      };
    }
    return row;
  });
};
