import makeStyles from '@mui/styles/makeStyles';
import createStyles from '@mui/styles/createStyles';

export const useStyles = makeStyles(() =>
  createStyles({
    form: {
      display: 'grid',
      gap: 20,
    },
  })
);
