import makeStyles from '@mui/styles/makeStyles';
import createStyles from '@mui/styles/createStyles';

const useStyles = makeStyles(() =>
  createStyles({
    addressElement: {
      marginTop: '10px',
    },
    infoIcon: {
      marginLeft: '2px !important',
      cursor: 'pointer',
      fontSize: '12px !important',
      top: '-1px !important',
    },
    billingStatus: {
      textTransform: 'capitalize', // !important',
    },
    headingWithIcon: {
      display: 'flex',
      alignItems: 'center',
    },
    dialog: {
      padding: 32,
      minWidth: 346,
    },
    customerPriceWrapper: {
      display: 'flex',
      flexFlow: 'column nowrap',
    },
    priceIncludesWrapper: {
      display: 'flex',
      flexFlow: 'column nowrap',
      marginTop: 16,
    },
    buyingPriceWrapper: {
      display: 'flex',
      flexFlow: 'column nowrap',
      marginTop: 16,
    },
    title: {
      fontSize: 16,
      fontWeight: 600,
      marginBottom: 16,
    },
    itemWrapper: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'space-between',
      marginBottom: 4,
    },
    discountWrapper: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'space-between',
      color: '#6633cc',
      marginBottom: 4,
    },
    greyedOut: {
      color: '#878c96',
    },
    itemProperty: {
      fontSize: 14,
    },
    itemValue: {
      fontSize: 14,
    },
    totalWrapper: {
      fontWeight: 600,
      borderTop: '1px solid #000',
      alignSelf: 'flex-end',
      paddingTop: 4,
      paddingLeft: 10,
    },
  })
);

export default useStyles;
