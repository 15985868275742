import { useTypedTranslation } from '../../../../custom-hooks/useTypedTranslation';
import { IUpdateUserProfile } from '../../../../services/users';
import { TInputProps } from '../../../shared/form-elements/input/Input.types';

export interface IChangeUserProfile {
  first_name: string;
  last_name: string;
}

interface ITextFields {
  [key: string]: TInputProps<IChangeUserProfile>;
}

export const useTextFields = (): ITextFields => {
  const { t, terms } = useTypedTranslation();
  const labels = terms.update_user_profile.fields;

  return {
    firstName: {
      id: 'first_name',
      name: 'first_name',
      label: t(labels.first_name),
      isRequired: true,
    },
    lastName: {
      id: 'last_name',
      name: 'last_name',
      label: t(labels.last_name),
      isRequired: true,
    },
  };
};

const DEFAULT_VALUES = {
  first_name: '',
  last_name: '',
};

export const getDefaultValues = (
  firstName?: string,
  lastName?: string
): IUpdateUserProfile => {
  return {
    first_name: firstName || DEFAULT_VALUES.first_name,
    last_name: lastName || DEFAULT_VALUES.last_name,
  };
};
