import { AppDispatch } from '../../../../store';
import { ActionCreator } from '../../../../store/state/actions';
import { ActionInterface } from '../../../../store/state/ui/actions';
import { Dispatcher } from '../index';

export interface IDispatchers {
  setVerificationStep: Dispatcher<ActionInterface.SetVerificationStep>;
  openVerificationFlow: Dispatcher<ActionInterface.OpenVerificationFlow>;
}

export const createDispatchers = (dispatch: AppDispatch): IDispatchers => ({
  setVerificationStep: (payload) =>
    dispatch(ActionCreator.ui.SetVerificationStep(payload)),

  openVerificationFlow: (payload) =>
    dispatch(ActionCreator.ui.OpenVerificationFlow(payload)),
});
