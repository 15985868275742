import update from 'immutability-helper';
import { State } from '../';
import { ActionInterface } from '../actions';
import serializeError from 'serialize-error';

export default (
  state: State,
  action: ActionInterface.GetSubscriberError
): State => {
  const { error } = action;

  const currentSubscriber = state || {
    loading: true,
    data: null,
    searchData: null,
    error: null,
    request: null,
  };

  const updatedSubscriber = update(currentSubscriber, {
    error: {
      $set: serializeError(error),
    },
    loading: {
      $set: false,
    },
    data: {
      $set: null,
    },
    searchData: {
      $set: null,
    },
  });

  return {
    ...state,
    ...updatedSubscriber,
  };
};
