import React from 'react';
import { Button } from '@elli-eco/component-library';
import { useFormContext } from 'react-hook-form';

interface IProps {
  text: string;
  disabled?: boolean;
  isLoading?: boolean;
  variant?: 'filled' | 'alert';
}

export const SubmitButton: React.FC<IProps> = ({
  text,
  isLoading,
  disabled,
  variant = 'filled',
}) => {
  const { formState } = useFormContext();
  const { isValid } = formState;

  return (
    <Button
      type='submit'
      variant={variant}
      disabled={disabled || !isValid}
      isLoading={isLoading}
    >
      {text}
    </Button>
  );
};
