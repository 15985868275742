import React from 'react';
import { useTheme } from '@mui/material/styles';
import useStyles from './useStyles';
import { BasicStatusIndicator } from './BasicStatusIndicator';
import { Typography } from '../Typography/Typography';

export interface IMappedStatus {
  color: string;
  label: string;
}

interface IBasicStatusProps<S> {
  status: S;
  valueMapper: (status: S) => IMappedStatus;
  statusTypeForTestId?: string;
  CustomLabelComponent?: React.FC<{ label: string }>;
}

export function BasicStatus<S>({
  status,
  valueMapper,
  statusTypeForTestId,
  CustomLabelComponent,
}: IBasicStatusProps<S>): ReturnType<React.FC<IBasicStatusProps<S>>> {
  const classes = useStyles(useTheme());

  return (
    <div className={classes.statusWrapper}>
      <BasicStatusIndicator
        status={status}
        colorMapping={(status) => valueMapper(status).color} //NOSONAR
      />
      {CustomLabelComponent ? (
        <CustomLabelComponent label={valueMapper(status).label} />
      ) : (
        <Typography
          className={classes.statusText}
          data-testid={
            statusTypeForTestId ? `${statusTypeForTestId}-status` : undefined
          }
          variant='body2'
        >
          {valueMapper(status).label}
        </Typography>
      )}
    </div>
  );
}
