import * as yup from 'yup';
import {
  EInvoiceRefundType,
  EInvoiceReimbursementAmount,
  IInvoiceRefundLineItem,
} from '../../../../../../types/invoice';

export const chargeRecordRefundFormSchema: yup.SchemaOf<IInvoiceRefundLineItem> = yup
  .object()
  .shape({
    invoice_line_item_id: yup.string().required(),
    process_id: yup.string().required(),
    type: yup
      .mixed<EInvoiceRefundType>()
      .is([EInvoiceRefundType.CHARGING_SESSION])
      .required(),
    reimbursement_amount: yup
      .mixed<EInvoiceReimbursementAmount>()
      .oneOf(Object.values(EInvoiceReimbursementAmount))
      .required(),
    gross_amount_to_refund: yup
      .number()
      .nullable()
      .positive()
      .required(),
  });
