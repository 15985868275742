import React, { useEffect, useState } from 'react';
import connector, { IPropsFromState } from '../../Connector/Connector';

import AccountsTable from './AccountsTable';
import { IMember } from '../../../types/organization';
import { ISortState } from '../../shared/AssetTable/AssetTable/AssetTable';
import SupervisorAccountOutlinedIcon from '@mui/icons-material/SupervisorAccountOutlined';
import useSharedStyles from '../../shared/useSharedStyles';
import { sortRequestHandler as sortStateHandler } from '../../../utils/sortRequestHandler';
import useStyles from './useStyles';

type TOwnProps = IPropsFromState & {
  crumbId?: string;
};

export const AccountsCollection: React.FC<TOwnProps> = ({
  organizationState,
  sortAccounts,
}) => {
  const classes = useStyles();
  const sharedClasses = useSharedStyles();

  const [sortState, setSortState] = useState<ISortState>({
    sortByColumnKey: 'email',
    sortDesc: true,
  });

  const [accounts, setAccounts] = useState<IMember[]>([]);

  const taskOrganizationState = organizationState;

  const sortRequestHandler = (columnKey: string | null): void => {
    sortStateHandler(columnKey, sortState, setSortState);
  };

  useEffect(() => {
    if (taskOrganizationState.accounts) {
      setAccounts(taskOrganizationState.accounts.members);
    }
  }, [taskOrganizationState]);

  useEffect(() => {
    sortAccounts({ sortState });
  }, [sortState, sortAccounts]);

  return (
    <div
      className={sharedClasses.cardElement}
      data-testid='accounts-collection'
    >
      <div className={classes.accountsCardHeader}>
        <div className={classes.titleWrapper}>
          <SupervisorAccountOutlinedIcon className={classes.titleIcon} />
          <h1 className={classes.panelTitle}>Accounts</h1>
        </div>
      </div>
      <div>
        <AccountsTable
          accounts={accounts}
          sortState={sortState}
          sortRequestHandler={sortRequestHandler}
        />
      </div>
    </div>
  );
};

export default connector(AccountsCollection);
