import { ActionInterface, ActionType } from '../actions';
import initialState, { State } from '../';

import clearOrganization from './clearOrganization';
import getOrganizationError from './getOrganizationError';
import getOrganizationRequest from './getOrganizationRequest';
import getOrganizationSuccess from './getOrganizationSuccess';
import sortAccounts from './sortAccounts';
import sortOrganizationStations from './sortOrganizationStations';

const reducer = (state: State = initialState, action: any) => {
  switch (action.type) {
    case ActionType.GetOrganizationRequest:
      return getOrganizationRequest(
        state,
        action as ActionInterface.GetOrganizationRequest
      );
    case ActionType.GetOrganizationSuccess:
      return getOrganizationSuccess(
        state,
        action as ActionInterface.GetOrganizationSuccess
      );
    case ActionType.GetOrganizationError:
      return getOrganizationError(
        state,
        action as ActionInterface.GetOrganizationError
      );
    case ActionType.ClearOrganization:
      return clearOrganization(state);
    case ActionType.SortOrganizationStations:
      return sortOrganizationStations(
        state,
        action as ActionInterface.SortOrganizationStations
      );
    case ActionType.SortAccounts:
      return sortAccounts(state, action as ActionInterface.SortAccounts);
    default:
      return state;
  }
};

export default reducer;
