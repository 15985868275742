import { ICreditNote } from '../../../types/creditNote';
import { ISortState } from '../../shared/AssetTable/AssetTable/AssetTable';
import { lexicographical } from '../../../utils/comparisonFunctions';

type TSortFunction = (
  creditNoteA: ICreditNote,
  creditNoteB: ICreditNote,
  sortDesc: boolean
) => number;

interface ISortFunctions {
  [key: string]: TSortFunction;
}

/**
 * Generic sorting function for numeric keys of credit notes
 * @param key credit note key with numeric value
 */
const sortCreditNoteLexicographical = (key: keyof ICreditNote) => (
  creditNoteA: ICreditNote,
  creditNoteB: ICreditNote
) => lexicographical(creditNoteA[key], creditNoteB[key]);

const sortByDate = (creditNoteA: ICreditNote, creditNoteB: ICreditNote) =>
  new Date(creditNoteA.credit_memo_date).getTime() -
  new Date(creditNoteB.credit_memo_date).getTime();

/**
 * Holds all sorting functions for credit note fields
 */
const sortFunctions: ISortFunctions = {
  credit_memo_number: sortCreditNoteLexicographical('credit_memo_number'),
  gross_amount: sortCreditNoteLexicographical('gross_amount'),
  state: sortCreditNoteLexicographical('state'),
  credit_memo_date: sortByDate,
};

/**
 * Takes credit notes and decides how to sort them
 * @param creditNotes list of credit notes
 * @param sortState requested sorting
 */
const sortCreditNotes = (
  creditNotes: ICreditNote[],
  sortState: ISortState
): ICreditNote[] => {
  if (!sortState.sortByColumnKey) {
    return creditNotes;
  }

  const key = sortState.sortByColumnKey as keyof ICreditNote;
  const currentSortFunction = sortFunctions[key];
  const direction = sortState.sortDesc ? -1 : 1;

  const sortedCreditNotes = creditNotes
    .filter((creditNote) => !!creditNote[key])
    .sort((a, b) => currentSortFunction(a, b, sortState.sortDesc) * direction);

  const missingSortKeyEntities = creditNotes.filter(
    (creditNote) => !creditNote[key]
  );

  if (sortState.sortDesc) {
    return [...sortedCreditNotes, ...missingSortKeyEntities];
  } else {
    return [...missingSortKeyEntities, ...sortedCreditNotes];
  }
};

export default sortCreditNotes;
