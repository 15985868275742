import { ErrorObject } from 'serialize-error';
import { IPaymentMethod } from '../../../types/paymentMethods';

export interface IPaymentDetailsModel {
  loading: boolean;
  data: IPaymentMethod[] | null;
  error: ErrorObject | null;
  request: { userId: string } | null;
}

export const initialState: IPaymentDetailsModel = {
  loading: false,
  data: [],
  error: null,
  request: null,
};

const state: IPaymentDetailsModel = {
  ...initialState,
};

export type State = typeof state;
export default state;
