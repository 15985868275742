import { AxiosError } from 'axios';
import { ActionCreator, ActionInterface, ActionType } from './actions';
import { all, call, put, takeEvery } from 'redux-saga/effects';
import { IPaymentMethod } from '../../../types/paymentMethods';
import { PaymentDetailsService } from '../../../services/paymentDetails';

export function* paymentDetailsSaga() {
  function* getPaymentDetails(
    action: ActionInterface.GetPaymentDetailsRequest
  ) {
    try {
      const paymentMethods: IPaymentMethod[] = yield call(
        PaymentDetailsService.getPaymentDetails,
        action.userId
      );

      yield put<ActionInterface.GetPaymentDetailsSuccess>(
        ActionCreator.GetPaymentDetailsSuccess({
          data: paymentMethods,
        })
      );
    } catch (error) {
      yield put<ActionInterface.GetPaymentDetailsError>(
        ActionCreator.GetPaymentDetailsError({
          error: error as AxiosError,
        })
      );
    }
  }

  yield all([
    takeEvery(ActionType.GetPaymentDetailsRequest, getPaymentDetails),
  ]);
}

export default paymentDetailsSaga;
