import React from 'react';
import useStyles from './useStyles';

interface IOwnProps {
  name?: string;
}

export const SubscriberLabel: React.FC<IOwnProps> = ({ name }) => {
  const classes = useStyles();
  if (!name) {
    return <span className={classes.unknownSubscriber}>Unknown name</span>;
  }

  return <span data-testid='subscriber-label'>{name}</span>;
};
