import React, { useEffect, useState } from 'react';

import { IActiveSession } from '../../../../types/activeSessions';
import { IBreadcrumbHandlerProps } from '../../../../utils/addBreadcrumbHandler';
import { IChargingRecordViewModel } from '../../../../types/chargingRecords';
import { IRFIDCardModel } from '../../../../types/subscriber';
import { IStationViewModel } from '../../../../view-models/StationModel';
import { RecentSessions as PrivateBusiness } from './PrivateBusiness/PrivateBusiness';
import { RecentSessions as Public } from './Public/Public';

interface IOwnProps {
  chargingRecords: IChargingRecordViewModel[];
  breadcrumbHandler: (props: IBreadcrumbHandlerProps) => void;
  chargingStation: IStationViewModel;
  activeSessions: IActiveSession[];
  stationRefreshing: boolean;
  chargingCards: IRFIDCardModel[];
}

export const RecentSessions: React.FC<IOwnProps> = ({
  chargingRecords,
  chargingStation,
  activeSessions,
  chargingCards,
  stationRefreshing,
  breadcrumbHandler,
}) => {
  const [stationType, setStationType] = useState<string>('');

  useEffect(() => {
    if (chargingStation.type === 'public') {
      setStationType('public');
    } else if (chargingStation.type === 'private_business') {
      setStationType('private_business');
    }
  }, [chargingStation]);

  return (
    <>
      {stationType === 'public' ? (
        <Public
          stationRefreshing={stationRefreshing}
          chargingRecords={chargingRecords}
          breadcrumbHandler={breadcrumbHandler}
        />
      ) : (
        <PrivateBusiness
          chargingCards={chargingCards}
          stationRefreshing={stationRefreshing}
          activeSessions={activeSessions}
          chargingRecords={chargingRecords}
          breadcrumbHandler={breadcrumbHandler}
        />
      )}
    </>
  );
};

export default RecentSessions;
