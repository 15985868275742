import { AppDispatch } from '../../../../store';
import { ActionCreator } from '../../../../store/state/actions';
import { ActionInterface } from '../../../../store/state/vehicle/actions';
import { Dispatcher } from '../index';

interface IDispatchers {
  getVehicleRequest: Dispatcher<ActionInterface.GetVehicleRequest>;
  clearVehicle: Dispatcher<ActionInterface.ClearVehicle>;
}

export const createDispatchers = (dispatch: AppDispatch): IDispatchers => ({
  getVehicleRequest: (payload) =>
    dispatch(ActionCreator.vehicle.GetVehicleRequest(payload)),

  clearVehicle: (payload) =>
    dispatch(ActionCreator.vehicle.ClearVehicle(payload)),
});
