import { ICsmsChargingStation, IHomeStation } from '../../../types/user';

/* eslint-disable @typescript-eslint/naming-convention */
import { ErrorObject } from 'serialize-error';

export interface GetHomeStationsRequest {
  user_id: string;
}

export interface HomeStationsStateModel {
  loading: boolean;
  data: ICsmsChargingStation | null;
  stations: IHomeStation[] | [];
  error: ErrorObject | null;
  request: { user_id: string } | null;
}

export const initialState: HomeStationsStateModel = {
  loading: false,
  data: null,
  stations: [],
  error: null,
  request: null,
};

const state: HomeStationsStateModel = {
  ...initialState,
};

export type State = typeof state;
export default state;
