import React, { useEffect, useState } from 'react';
import {
  Table,
  TableBody,
  TableCell,
  TableCellProps,
  TableRow,
} from '@mui/material';
import { useTheme } from '@mui/material/styles';

import { AssetTableHead } from '../AssetTableHead/AssetTableHead';
import Magnifier from '../../../../assets/image/magnifyingGlass.svg';
import useStyles from './useStyles';
import { CollapsibleRowItem } from './CollapsibleRowItem';
import { AssetTableRow } from './AssetTableRow';

export interface ISortState {
  sortByColumnKey: string | null;
  sortDesc: boolean;
}
export interface IColumn {
  header: string | JSX.Element;
  key: string;
  isSortable: boolean;
  isStatus?: boolean;
  width?: string;
  noPadding?: boolean;
  noBorder?: boolean;
  align?: TableCellProps['align'];
}

export interface IBulkActionWithCallback {
  icon: JSX.Element;
  title: string;
  callback: (ids: string[]) => void;
}
export interface IBulkActionWithMenu {
  icon: JSX.Element;
  title: string;
  menu: (ids: string[], closeCallback: () => void) => JSX.Element;
  key: string;
}
export type BulkAction = IBulkActionWithCallback | IBulkActionWithMenu;

export interface ICell {
  content: JSX.Element | string | number;
  isToggle?: boolean;
}

export interface IRow {
  id: string;
  data: {
    [key: string]: ICell;
  };
  history?: { [key: string]: ICell }[];
  expandState?: boolean;
}

interface IOwnProps {
  bulkActions: BulkAction[];
  columns: IColumn[];
  filterState?: any; // FilterState
  onRowClick?: (row: IRow) => void;
  rows: IRow[];
  sortRequestHandler?: (property: string) => void;
  sortingState?: ISortState;
  noDataTitle?: string;
  noDataSubTitle?: string;
  condensed?: boolean;
  isCollapsible?: boolean;
}

const addBulkEditingInfo = (cell: ICell): ICell['content'] => {
  if (typeof cell.content === 'string' || typeof cell.content === 'number') {
    return cell.content;
  } else {
    return React.cloneElement(cell.content);
  }
};

export const AssetTable: React.FC<IOwnProps> = ({
  bulkActions,
  columns,
  filterState,
  onRowClick,
  rows,
  sortRequestHandler = (x) => x,
  sortingState = { sortByColumnKey: null, sortDesc: false },
  noDataTitle,
  noDataSubTitle,
  condensed = false,
  isCollapsible = false,
}) => {
  const theme = useTheme();
  const classes = useStyles(theme);
  const [selected, setSelected] = useState<string[]>([]);

  const toggleSelection = (id: string) => {
    if (bulkActions.length === 0) {
      return;
    }
    if (selected.includes(id)) {
      setSelected(selected.filter((value) => value !== id));
    } else {
      setSelected([...selected, id]);
    }
  };

  const toggleSelectAll = () => {
    if (bulkActions.length === 0) {
      return;
    }
    if (selected.length === 0) {
      setSelected(rows.map((row) => row.id));
    } else {
      setSelected([]);
    }
  };

  useEffect(() => {
    setSelected([]);
  }, [filterState]);

  const getTableCellClasses = (column: IColumn): string => {
    const classList = [];

    if (column.noPadding) {
      classList.push(classes.noPadding);
    } else {
      classList.push(classes.tableCell);
    }

    if (column.noBorder) {
      classList.push(classes.noBorder);
    }

    return classList.join(' ');
  };

  function renderCollapsibleRowItems() {
    return rows.map((row, index) => (
      <CollapsibleRowItem
        key={index}
        selected={selected}
        row={row}
        columns={columns}
        onRowClick={onRowClick}
        toggleSelection={toggleSelection}
        getTableCellClasses={getTableCellClasses}
      />
    ));
  }

  function renderRowItems() {
    return rows.map((row, index) => {
      const rowSelected = selected.includes(row.id);
      const checkboxCallback = () => {
        toggleSelection(row.id);

        // Unfocus the checkbox so that the row does not remain grey
        const focusedElement = document.activeElement as HTMLElement;
        focusedElement.blur();
      };

      const onClickRowEvent = () => {
        if (onRowClick) {
          onRowClick(row);
        }
        return selected.length > 0 ? checkboxCallback : undefined;
      };

      return (
        <AssetTableRow
          key={index}
          selected={selected}
          rowSelected={rowSelected}
          onRowClick={onRowClick}
          onClickRowEvent={onClickRowEvent}
          columns={columns}
          getTableCellClasses={getTableCellClasses}
          row={row}
          addBulkEditingInfo={addBulkEditingInfo}
          condensed={condensed}
        />
      );
    });
  }

  function renderRows() {
    return isCollapsible ? renderCollapsibleRowItems() : renderRowItems();
  }

  return (
    <React.Fragment>
      <Table className={classes.table}>
        <AssetTableHead
          condensed={condensed}
          bulkActions={bulkActions}
          columns={columns}
          isStuck={false}
          nRows={rows.length}
          selected={selected}
          sortingState={sortingState}
          sortRequestHandler={sortRequestHandler}
          toggleSelectAll={toggleSelectAll}
        />

        <TableBody>
          {rows.length > 0 ? (
            renderRows()
          ) : (
            <TableRow>
              <TableCell
                className={classes.noContentRow}
                colSpan={columns.length}
                align='center'
              >
                <div className={classes.noDataWrapper}>
                  <img src={Magnifier} alt='magnifier' />
                  <p className={classes.noDataTitle}>{noDataTitle}</p>
                  <p className={classes.noDataSubTitle}>{noDataSubTitle}</p>
                </div>
              </TableCell>
            </TableRow>
          )}
        </TableBody>
      </Table>
    </React.Fragment>
  );
};
