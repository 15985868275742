import makeStyles from '@mui/styles/makeStyles';
import createStyles from '@mui/styles/createStyles';

const useStyles = makeStyles(() =>
  createStyles({
    chargingReadinessWrapper: {
      width: 640,
    },
    closeIcon: {},
    headerWrapper: {
      marginBottom: 48,
      textAlign: 'center',
    },
    dialogTitle: {
      fontSize: 28,
      marginBottom: 16,
      fontWeight: 600,
    },
    dialogSubtitle: {
      fontSize: 16,
      fontWeight: 'normal',
    },
  })
);

export default useStyles;
