import useStyles from './useStyles';
import { useTheme } from '@mui/material/styles';
import React, { useEffect, useState } from 'react';
import { PlugAndChargeService } from '../../../../services/plugAndCharge';
import CheckIcon from '@mui/icons-material/Check';
import CloseIcon from '@mui/icons-material/Close';
import { Typography } from '../../../shared/Typography/Typography';

type TOwnProps = {
  vin?: string;
};

export const PlugAndChargeCapability: React.FC<TOwnProps> = ({ vin }) => {
  const classes = useStyles();
  const theme = useTheme();

  const [isPncCapable, setIsPncCapable] = useState(false);

  useEffect(() => {
    (async () => {
      try {
        const isCapable = vin
          ? await PlugAndChargeService.getPncCapabilityByVIN(vin)
          : false;
        setIsPncCapable(isCapable);
      } catch {
        setIsPncCapable(false);
      }
    })();
  });

  return (
    <div data-testid='vinCapabilityForPncContainer'>
      <div className={classes.capability}>
        {isPncCapable ? (
          <CheckIcon
            data-testid='vinPncCapableIcon'
            style={{
              fontSize: 32,
              color: theme.colorPrimary,
            }}
          />
        ) : (
          <CloseIcon
            data-testid='vinPncNotCapableIcon'
            style={{
              fontSize: 32,
              color: '#f00066',
            }}
          />
        )}
        <Typography variant='body1' className={classes.capabilityLabel}>
          capable
        </Typography>
      </div>
    </div>
  );
};
