import makeStyles from '@mui/styles/makeStyles';
import createStyles from '@mui/styles/createStyles';

const useStyles = makeStyles(() =>
  createStyles({
    rootContainer: {
      width: '473px',
      maxWidth: 473,
    },
  })
);

export default useStyles;
