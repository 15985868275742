import { Theme } from '@mui/material/styles';

import createStyles from '@mui/styles/createStyles';
import makeStyles from '@mui/styles/makeStyles';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    activeSessionWrapper: {
      position: 'relative',
      '&:hover': {
        '& $cardOverlay': {
          transform: 'scale(1)',
        },
      },
    },
    cardOverlay: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      position: 'absolute',
      top: '0',
      left: '0',
      height: '100%',
      width: '100%',
      background: 'rgb(255 255 255 / 77%)',
      paddingBottom: 20,
      transform: 'scale(0)',
    },
    detailButton: {
      border: `2px solid ${theme.colorPrimary}`,
      background: 'white',
      fontSize: 16,
      fontWeight: 600,
      color: theme.colorPrimary,
      lineHeight: 1.5,
      height: 40,
      cursor: 'pointer',
      outline: 'none',
    },
  })
);

export default useStyles;
