import React from 'react';
import Check from '@mui/icons-material/Check';
import { CircularProgress } from '@mui/material';
import PanoramaFishEyeIcon from '@mui/icons-material/PanoramaFishEye';
import { CheckCircleOutline } from '@mui/icons-material';

import { useStyles } from './useStyles';

export enum Status {
  OPEN = 'OPEN',
  PENDING = 'PENDING',
  SUCCESS = 'SUCCESS',
  ERROR = 'ERROR',
  EMPTY_INPUT = 'EMPTY_INPUT',
}

interface IOwnProps {
  status: Status;
}

/**
 * Holds all states and icons for a status-icon life-cycle
 */
const StatusIcon: React.FC<IOwnProps> = ({ status }) => {
  const classes = useStyles();
  const aggregateClasses = (...customClasses: string[]) =>
    [...customClasses, classes.root].join(' ');

  switch (status) {
    case Status.PENDING:
      return <CircularProgress size={18} />;
    case Status.SUCCESS:
      return <Check className={classes.root} />;
    case Status.ERROR:
      return (
        <PanoramaFishEyeIcon className={aggregateClasses(classes.error)} />
      );
    case Status.OPEN:
      return <CheckCircleOutline className={classes.root} />;

    default:
      return null;
  }
};

export default React.memo(StatusIcon);
