import { ChargingSessionsStateModel, State } from '..';

import { ActionInterface } from '../actions';
import { omit } from 'lodash';
import update from 'immutability-helper';

export default (
  state: State,
  action: ActionInterface.GetChargingSessionsRequest
): State => {
  const chargingsessions = state || {
    loading: true,
    data: null,
    error: null,
    request: null,
    public: null,
    private: null,
    organization: null,
  };

  const updatedChargingSessions: ChargingSessionsStateModel = update(
    chargingsessions,
    {
      error: {
        $set: null,
      },
      loading: {
        $set: true,
      },
      request: {
        $set: omit(action, ['type']),
      },
    }
  );

  return {
    ...state,
    ...updatedChargingSessions,
  };
};
