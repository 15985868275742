import { Theme } from '@mui/material';

import makeStyles from '@mui/styles/makeStyles';
import createStyles from '@mui/styles/createStyles';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    card: {
      backgroundColor: '#ffffff',
      boxShadow: '0 2px 8px 0 rgba(0, 0, 0, 0.08)',
      borderRadius: '4px',
      padding: '16px 16px 0',
      marginBottom: '12px',
    },
    cardHeaderWrapper: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'flex-start',
      marginBottom: 32,
    },
    headerLogo: {
      fontSize: 24,
      marginRight: 16,
      color: theme.colorPrimary,
    },
    headerTitle: {
      fontSize: 20,
      fontWeight: 600,
    },
    detailsWrapper: {},
    sectionTitle: {
      marginBottom: 16,
      fontSize: 16,
      fontWeight: 600,
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'flex-start',
    },
    refreshIcon: {
      color: theme.colorPrimary,
      cursor: 'pointer',
      fontSize: 20,
      marginLeft: 4,
    },
    outerItemWrapper: {
      display: 'flex',
      alignItems: 'flex-start',
      justifyContent: 'space-between',
      flexFlow: 'row wrap',
    },
    itemWrapper: {
      display: 'flex',
      flexFlow: 'column nowrap',
      alignItems: 'flex-start',
      justifyContent: 'space-between',
      marginBottom: 24,
      width: 200,
    },
    itemWrapperAddress: {
      display: 'flex',
      flexFlow: 'column nowrap',
      alignItems: 'flex-start',
      justifyContent: 'space-between',
      marginBottom: 24,
      width: 450,
    },
    itemTitle: {
      fontSize: 12,
      color: '#878c96',
      marginBottom: 10,
      lineHeight: 1.33,
    },
    itemTitleWithIcon: {
      fontSize: 12,
      color: '#878c96',
      marginBottom: 10,
      lineHeight: 1.33,
      display: 'flex',
      alignItems: 'center',
      '&:first-child': {
        marginRight: 3,
      },
    },
    itemValue: {
      fontSize: 14,
      display: 'flex',
      alignItems: 'center',
    },
    itemValueLink: {
      fontSize: 14,
      display: 'flex',
      alignItems: 'center',
      textDecoration: 'underline',
      cursor: 'pointer',
    },
    valueWithIcon: {
      fontSize: 14,
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'space-between',
    },
    infoIcon: {
      marginLeft: 2,
      cursor: 'pointer',
      fontSize: 12,
    },
    circleProgress: {
      color: theme.colorAccent,
      marginLeft: 6,
    },
    loading: {
      opacity: 0.5,
    },
    locationWrapper: {},
    tooltipOverflow: {
      display: 'block',
      whiteSpace: 'nowrap',
      overflow: 'hidden',
      textOverflow: 'ellipsis',
      width: 200,
    },
  })
);

export default useStyles;
