import { Config } from './environment';
import { UnreachableCaseError } from './errors';

export type ElliProductName = 'CHARGING_SITE' | 'CHARGE_AT_HOME';

export interface IElliProduct {
  id: string;
  name: ElliProductName;
  displayName: string;
}

const getChargingSiteElliProductData = (): IElliProduct => {
  const productName: ElliProductName = 'CHARGING_SITE';
  const displayName = 'B2B';

  switch (window.CONFIG) {
    case Config.LOCAL:
    case Config.DEV:
    case Config.STAGE:
    case Config.TEST:
      return {
        id: '5bc086d4-47d4-4005-b96d-96b09ff54852',
        name: productName,
        displayName,
      };
    case Config.PROD:
      return {
        id: 'b5a183fc-d529-4712-b806-aeefdad7fd79',
        name: productName,
        displayName,
      };
    default:
      throw new UnreachableCaseError(window.CONFIG);
  }
};

const getChargeAtHomeElliProductData = (): IElliProduct => {
  const productName: ElliProductName = 'CHARGE_AT_HOME';
  const displayName = 'B2C';

  switch (window.CONFIG) {
    case Config.LOCAL:
    case Config.DEV:
    case Config.STAGE:
    case Config.TEST:
      return {
        id: '3a15a5d1-4000-4411-babf-4e03909d15be',
        name: productName,
        displayName,
      };
    case Config.PROD:
      return {
        id: '65f489c6-7732-4968-bfd5-78f042d79dcb',
        name: productName,
        displayName,
      };
    default:
      throw new UnreachableCaseError(window.CONFIG);
  }
};

export const getElliProductData = (
  elliProductName: ElliProductName
): IElliProduct => {
  switch (elliProductName) {
    case 'CHARGING_SITE':
      return getChargingSiteElliProductData();
    case 'CHARGE_AT_HOME':
      return getChargeAtHomeElliProductData();
    default:
      throw new UnreachableCaseError(elliProductName);
  }
};
