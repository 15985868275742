import makeStyles from '@mui/styles/makeStyles';

const useStyles = makeStyles({
  circularProgress: {
    position: 'absolute',
  },
  buttonStyling: {
    padding: '5px 20px',
    minWidth: 170,
  },
  mainButtonText: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  withSmallButtonText: {
    paddingBottom: '6px',
  },
  smallButtonText: {
    position: 'absolute',
    right: 0,
    left: 0,
    bottom: 5,
    textAlign: 'center',
    fontSize: 10,
    lineHeight: '1em',
  },
  textContainerHidden: {
    visibility: 'hidden',
  },
});

export default useStyles;
