import React from 'react';
import { Status } from '../../Icons/StatusIcon';

export interface IPairingState {
  pairingState: Status;
  isDisabledInput: boolean;
  isDisabledSubmit: boolean;
  message: string;
}

export interface IPairingStateAction {
  type: string;
  payload: any;
}

export const PAIRING_ACTIONS = {
  OPEN: 'OPEN',
  PENDING: 'PENDING',
  SUCCESS: 'SUCCESS',
  WARNING: 'WARNING',
  ERROR: 'ERROR',
  STATIC_RFID: 'STATIC_RFID',
  EMPTY_INPUT: 'EMPTY_INPUT',
};

export const pairingReducer: React.Reducer<IPairingState, any> = (
  state: IPairingState,
  action: IPairingStateAction
) => {
  switch (action.type) {
    case PAIRING_ACTIONS.OPEN:
      return {
        ...state,
        pairingState: Status.OPEN,
        isDisabledInput: false,
        isDisabledSubmit: false,
      };

    case PAIRING_ACTIONS.STATIC_RFID:
      return {
        ...state,
        isDisabledInput: true,
        isDisabledSubmit: false,
      };

    case PAIRING_ACTIONS.PENDING:
      return {
        ...state,
        pairingState: Status.PENDING,
        message: '',
      };

    case PAIRING_ACTIONS.SUCCESS:
      return {
        ...state,
        pairingState: Status.SUCCESS,
        isDisabledSubmit: false,
        isDisabledInput: true,
        message: '',
      };

    case PAIRING_ACTIONS.WARNING:
      return {
        ...state,
        pairingState: Status.ERROR,
        message: action.payload,
      };

    case PAIRING_ACTIONS.ERROR:
      return {
        ...state,
        pairingState: Status.ERROR,
        message: action.payload,
        isDisabledSubmit: true,
        isDisabledInput: true,
      };

    case PAIRING_ACTIONS.EMPTY_INPUT:
      return {
        ...state,
        pairingState: Status.EMPTY_INPUT,
        isDisabledSubmit: true,
      };

    default:
      return state;
  }
};
