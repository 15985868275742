import connector, { IPropsFromState } from '../../../Connector/Connector';
import useStyles from './useStyles';
import React, { ChangeEvent, useEffect, useState } from 'react';
import { Button, Grid, IconButton, TextField, Alert } from '@mui/material';
import DialogHeadline from '../../../shared/Dialogs/DialogHeadline';
import { useTheme } from '@mui/material/styles';
import { BasicInformationItem } from '../../../shared/InformationItems/BasicInformationItem';
import { CardSubheadline } from '../../../shared/InformationItems/CardSubheadline';
import { PlugAndChargeService } from '../../../../services/plugAndCharge';
import { CarService } from '../../../../services/car';
import { getDefined } from '../../../../utils/getDefined';
import { ISubscriptionModel } from '../../../../types/subscriber';
import { ITariffConditionModel } from '../../../../types/tariff';
import { TariffService } from '../../../../services/tariffs';
import RefreshIcon from '@mui/icons-material/Refresh';
import InformationItem from '../../../shared/InformationItems/InformationItem';
import { useTariffsQuery } from '../../../../state/queries/tariffs';
import { StatefulInformationItem } from '../../../shared/InformationItems/InformationItemWithLoadingState';
import { PlugAndChargeStatus } from '../PlugAndChargeStatus/PlugAndChargeStatus';
import { TariffEligibilityStatus } from '../PlugAndChargeStatus/TariffEligibilityStatus';
import { VinCapabilityStatus } from '../PlugAndChargeStatus/VinCapabilityStatus';
import { Typography } from '../../../shared/Typography/Typography';

export type IOwnProps = IPropsFromState & {
  subscriptionId?: string;
};

const checkIfValidVin = (vin: string) => {
  // Officially, the correct regex for valid VINs is '[A-HJ-NPR-Z0-9]{17}'
  // however, a more relaxed regex is used here because not all testing VINs are compliant
  const isValidVin = new RegExp('[A-Z0-9]{17}');
  return isValidVin.test(vin);
};

const EnableDisablePlugAndChargeModal: React.FC<IOwnProps> = ({
  subscriptionId,
  subscriberState,
  subscriptionState,
  getSubscriberOnlyRequest,
}) => {
  const theme = useTheme();
  const classes = useStyles(theme);

  const getTariffsQuery = useTariffsQuery();

  const subscriber = subscriberState;

  const [subscription, setSubscription] = useState<ISubscriptionModel | null>(
    null
  );

  const [
    tariffBaseConditions,
    setTariffBaseConditions,
  ] = useState<ITariffConditionModel | null>(null);
  const [isTariffEligibleForPnc, setIsTariffEligibleForPnc] = useState<boolean>(
    false
  );

  const [vin, setVin] = useState<string | undefined>(undefined);
  const [isVinCapableOfPnc, setIsVinCapableOfPnc] = useState<boolean>(false);
  const [isVinEditable, setIsVinEditable] = useState<boolean>(true);
  const [isValidVinInput, setIsValidVinInput] = useState<boolean>(true);

  const [allowEnablePnc, setAllowEnablePnc] = useState<boolean>(false);

  const [displayButtonErrorAlert, setDisplayButtonErrorAlert] = useState<
    boolean
  >(false);

  const onClickDisableButton = async () => {
    try {
      await PlugAndChargeService.disablePlugAndCharge(
        getDefined(subscriber?.data?.id)
      );
      getSubscriberOnlyRequest({
        userId: getDefined(subscriber?.data?.iam_id),
      });
      setDisplayButtonErrorAlert(false);
    } catch {
      setDisplayButtonErrorAlert(true);
    }
  };
  const onClickEnableButton = async () => {
    if (allowEnablePnc) {
      try {
        await PlugAndChargeService.enablePlugAndCharge(
          getDefined(subscriber?.data?.id),
          vin
        );
        getSubscriberOnlyRequest({
          userId: getDefined(subscriber?.data?.iam_id),
        });
        setDisplayButtonErrorAlert(false);
      } catch {
        setDisplayButtonErrorAlert(true);
      }
    }
  };
  const onChangeVinInput = async (
    event: ChangeEvent<HTMLTextAreaElement | HTMLInputElement>
  ) => {
    if (event.target.value === '') {
      setIsValidVinInput(true);
    } else {
      const isValid = checkIfValidVin(event.target.value);
      setIsValidVinInput(isValid);
      if (isValid) {
        setVin(event.target.value);
      } else {
        setVin(undefined);
      }
    }
  };
  const onClickRefreshPncStatusButton = () => {
    try {
      getSubscriberOnlyRequest({
        userId: getDefined(subscriber?.data?.iam_id),
      });
      setDisplayButtonErrorAlert(false);
    } catch {
      setDisplayButtonErrorAlert(true);
    }
  };

  useEffect(() => {
    const getVin = async () => {
      try {
        if (subscriber && !subscriber.loading && subscriber.data !== null) {
          if (subscriber.data.plug_and_charge.pcid) {
            setVin(subscriber.data.plug_and_charge.pcid);
            setIsVinEditable(false);
          } else {
            const carVin = await CarService.getVinByIamId(
              getDefined(subscriber.data).iam_id
            );
            setVin(carVin);
            setIsVinEditable(carVin === undefined);
          }
        } else {
          setVin(undefined);
          setIsVinEditable(true);
        }
      } catch (e) {
        setVin(undefined);
        setIsVinEditable(true);
      }
    };
    getVin();
  }, [subscriberState]);

  useEffect(() => {
    if (subscriptionState && subscriptionId) {
      if (subscriptionState.data) {
        const currentSubscription = subscriptionState.data.find(
          (cc) => cc.id === subscriptionId
        );

        if (currentSubscription) {
          setSubscription(currentSubscription);
        }
      }
    }
  }, [subscriptionId, subscriptionState]);

  useEffect(() => {
    if (subscription) {
      const updateTariffBaseConditions = async () => {
        try {
          const trf = await TariffService.getByConditionId(
            subscription.tariff_id,
            subscription.base_condition_id
          );
          setTariffBaseConditions(trf);
        } catch (err) {
          setTariffBaseConditions(null);
        }
      };
      const updateTariff = (tariffId: string) => {
        const tariffs = getTariffsQuery.data || [];
        const updatedTariff = tariffs.find(({ id }) => id === tariffId) || null;
        setIsTariffEligibleForPnc(
          updatedTariff?.plug_and_charge_capable ?? false
        );
      };
      updateTariffBaseConditions();
      updateTariff(subscription.tariff_id);
    }
  }, [subscription, getTariffsQuery.data]);

  useEffect(() => {
    if (vin) {
      const getVinCapableOfPnc = async (vin: string) => {
        try {
          const isCapable = await PlugAndChargeService.getPncCapabilityByVIN(
            vin
          );
          setIsVinCapableOfPnc(isCapable);
        } catch (e) {
          setIsVinCapableOfPnc(false);
        }
      };
      getVinCapableOfPnc(vin);
    } else {
      setIsVinCapableOfPnc(false);
    }
  }, [vin]);

  useEffect(() => {
    if (isVinCapableOfPnc && isTariffEligibleForPnc) {
      setAllowEnablePnc(true);
    } else {
      setAllowEnablePnc(false);
    }
  }, [isVinCapableOfPnc, isTariffEligibleForPnc]);

  return (
    <div
      className={classes.outerWrapper}
      data-testid={'enable-disable-plug-and-charge-modal'}
    >
      <Grid item>
        <DialogHeadline
          headline='Enable/Disable Plug & Charge'
          subHeadline='Both the VIN and tariff must be compatible enable Plug & Charge'
        />
      </Grid>
      {subscriber && subscriber.data ? (
        <Grid item container xs={12} className={classes.form} spacing={1}>
          <Grid item xs={12}>
            <CardSubheadline title='Details' />
          </Grid>
          <Grid item container xs={12} spacing={1}>
            <Grid item xs={12} container spacing={1}>
              <Grid item xs={6}>
                {isVinEditable ? (
                  <TextField
                    label='VIN'
                    defaultValue=''
                    placeholder='-'
                    error={!isValidVinInput}
                    variant='outlined'
                    helperText={isValidVinInput ? '' : 'Must be valid VIN'}
                    size='small'
                    className={classes.vinInput}
                    data-testid='vinModalInputField'
                    InputLabelProps={{ shrink: true }}
                    InputProps={{
                      'aria-label': 'VIN input field',
                    }}
                    onChange={onChangeVinInput}
                  />
                ) : (
                  <BasicInformationItem
                    title={'Vehicle Identification Number'}
                    content={vin ?? '-'}
                  />
                )}
              </Grid>
              <Grid item xs={6}>
                <InformationItem
                  item={{
                    id: 'vin-capability',
                    headline: 'VIN capable of Plug & Charge',
                    content: (
                      <VinCapabilityStatus
                        status={isVinCapableOfPnc}
                        statusTypeForTestId={'vin-capability'}
                      />
                    ),
                  }}
                />
              </Grid>
            </Grid>
            <Grid item xs={12} container spacing={1}>
              <Grid item xs={6}>
                <StatefulInformationItem
                  item={{
                    id: 'tariff-base-condition-name',
                    headline: 'Tariff',
                    content: (
                      <Typography variant='body2'>
                        {tariffBaseConditions?.name ?? '-'}
                      </Typography>
                    ),
                  }}
                  queryState={getTariffsQuery}
                />
              </Grid>
              <Grid item xs={6}>
                <StatefulInformationItem
                  item={{
                    id: 'tariff-eligibility-for-pnc',
                    headline: 'Tariff eligibility for Plug & Charge',
                    content: (
                      <TariffEligibilityStatus
                        status={isTariffEligibleForPnc}
                      />
                    ),
                  }}
                  queryState={getTariffsQuery}
                />
              </Grid>
            </Grid>
          </Grid>
          <Grid container item xs={12} alignContent='center'>
            <Grid item xs={12}>
              <CardSubheadline title='Plug & Charge Status' />
            </Grid>
            <Grid container item xs={12} alignItems='center'>
              <Grid
                item
                className={classes.pncStatus}
                style={{ alignSelf: 'center' }}
              >
                <PlugAndChargeStatus
                  status={subscriber.data.plug_and_charge.state}
                />
              </Grid>
              <Grid item>
                <IconButton
                  color='primary'
                  className={classes.refreshIcon}
                  onClick={onClickRefreshPncStatusButton}
                  size='large'
                >
                  <RefreshIcon fontSize='small' />
                </IconButton>
              </Grid>
            </Grid>
          </Grid>
          <Grid
            item
            xs={12}
            container
            className={classes.buttonWrapper}
            justifyContent='center'
          >
            <Grid item xs={4}>
              <Button
                color='primary'
                variant='outlined'
                fullWidth
                onClick={onClickEnableButton}
                disabled={!allowEnablePnc}
              >
                Enable
              </Button>
            </Grid>
            <Grid item xs={1} />
            <Grid item xs={4}>
              <Button
                variant='outlined'
                fullWidth
                className={classes.alertOutlined}
                onClick={onClickDisableButton}
              >
                Disable
              </Button>
            </Grid>
            {displayButtonErrorAlert && (
              <Grid item xs={12} className={classes.buttonErrorAlert}>
                <Alert
                  severity='error'
                  data-testid='enableDisableButtonErrorAlert'
                >
                  Something went wrong, please try again
                </Alert>
              </Grid>
            )}
          </Grid>
        </Grid>
      ) : (
        <Alert severity='error'>
          Unable to load subscriber data, please refresh the page and try again
        </Alert>
      )}
    </div>
  );
};

export default connector(EnableDisablePlugAndChargeModal);
