import update from 'immutability-helper';
import { State, CardOrderStateModel } from '..';

export default (state: State): State => {
  const updatedState: CardOrderStateModel = update(state, {
    $unset: ['data', 'error', 'loading', 'request'],
  });

  return {
    ...updatedState,
  };
};
