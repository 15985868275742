import toast from 'react-hot-toast';
import { UseQueryResult, useQuery } from 'react-query';

import { IRevocationReason } from '../../types/subscription';
import { TerminateSubscriptionService } from '../../services/subscriptions';
import { ISelectOption } from '../../components/shared/form-elements/select/Select.types';
import { useTypedTranslation } from '../../custom-hooks/useTypedTranslation';

export const useGetRevocationReasons = (): UseQueryResult<IRevocationReason[]> => {
  const { t, terms } = useTypedTranslation();

  return useQuery(
    'revocationReasons',
    TerminateSubscriptionService.getRevocationReasons,
    {
      staleTime: Infinity,
      cacheTime: Infinity,
      onError: () => {
        toast.error(
          t(terms.general_errors.could_not_be_loaded, {
            resource: 'Revocation reasons',
          })
        );
      },
    }
  );
};

export const mapRevocationReasonsToSelectOptions = (
  revocationReasons: IRevocationReason[]
): ISelectOption[] => {
  return revocationReasons.map((reason) => ({
    key: reason.revocation_reason,
    value: reason.revocation_reason,
    content: reason.description,
  }));
};
