import React from 'react';
import { Controller, FieldValues } from 'react-hook-form';
import TextField from '@mui/material/TextField';
import { TInputProps } from './Input.types';

const Input = <FormValues extends FieldValues>({
  name,
  control,
  error,
  ref,
  label,
  isRequired,
  ...rest
}: TInputProps<FormValues>): React.ReactElement => {
  const inputLabel = isRequired ? [label, '*'].join(' ') : label;

  return (
    <Controller<FormValues>
      name={name}
      control={control}
      render={({ field }) => (
        <TextField
          {...rest}
          {...field}
          fullWidth
          variant='outlined'
          error={!!error}
          inputRef={field.ref || ref}
          label={inputLabel}
          inputProps={{
            'data-testid': `${name}-input-field`,
          }}
          helperText={error?.message || rest.helperText}
        />
      )}
    />
  );
};

export default Input;
