import { AppDispatch } from '../../../../store';
import { ActionCreator } from '../../../../store/state/actions';
import { ActionInterface } from '../../../../store/state/chargingCards/actions';
import { Dispatcher } from '../index';

interface IDispatchers {
  getChargingCardRequest: Dispatcher<ActionInterface.GetChargingCardRequest>;
  setCurrentCard: Dispatcher<ActionInterface.SetCurrentCard>;
  sortChargingCards: Dispatcher<ActionInterface.SortChargingCards>;
}

export const createDispatchers = (dispatch: AppDispatch): IDispatchers => ({
  getChargingCardRequest: (payload) =>
    dispatch(ActionCreator.chargingCards.GetChargingCardRequest(payload)),

  setCurrentCard: (payload) =>
    dispatch(ActionCreator.chargingCards.SetCurrentCard(payload)),

  sortChargingCards: (payload) =>
    dispatch(ActionCreator.chargingCards.SortChargingCards(payload)),
});
