import React, { useEffect } from 'react';
import { useFormContext, useWatch } from 'react-hook-form';
import {
  EInvoiceReimbursementAmount,
  IInvoiceRefundLineItem,
  IInvoiceLineItem,
} from '../../../../../../types/invoice';
import {
  ConnectForm,
  Input,
  Select,
} from '../../../../../shared/form-elements';
import {
  REFUND_TYPE_SELECT_OPTIONS,
  REIMBURSEMENT_AMOUNT_SELECT_OPTIONS,
} from '../../shared/options/InvoiceRefundOptions';
import MultilineButton from '../../../../../shared/Buttons/MultilineButton';
import useStyles from '../../charge-record/form/useStyles';
import useFormStyles from '../../../../../shared/form-elements/useStyles';
import { useTypedTranslation } from '../../../../../../custom-hooks/useTypedTranslation';
import { useInvoiceRefund } from '../../../../../../state/mutations/useInvoiceRefund';

export type TSubscriptionRefundFormFieldsProps = {
  baseFee: IInvoiceLineItem;
};

const SubscriptionRefundFormFields: React.FC<TSubscriptionRefundFormFieldsProps> = ({
  baseFee,
}) => {
  const { isLoading: isSubmitting } = useInvoiceRefund();
  const { t, terms } = useTypedTranslation();
  const { setValue, control } = useFormContext<IInvoiceRefundLineItem>();
  const reimbursementAmountOption = useWatch({
    name: 'reimbursement_amount',
    control,
  });
  const classes = useStyles();
  const formClasses = useFormStyles();
  const isFullRefund =
    reimbursementAmountOption === EInvoiceReimbursementAmount.FULL;

  const setFullRefundAmount = () => {
    setValue('gross_amount_to_refund', Number(baseFee.gross_amount), {
      shouldValidate: true,
    });
  };

  useEffect(() => {
    if (isFullRefund) {
      setFullRefundAmount();
    }
  }, [reimbursementAmountOption]);

  return (
    <ConnectForm>
      <Select
        name='type'
        className={formClasses.formField}
        options={REFUND_TYPE_SELECT_OPTIONS}
        disabled
      />
      <Input
        name='process_id'
        className={formClasses.formField}
        label='Process ID'
        disabled
      />
      <Select
        name='reimbursement_amount'
        className={formClasses.formField}
        label={t(terms.refunds.form.fields.reimbursement_amount)}
        options={REIMBURSEMENT_AMOUNT_SELECT_OPTIONS}
        isRequired
      />
      <Input
        name='gross_amount_to_refund'
        type='number'
        label={`${t(terms.refunds.form.fields.amount)} in ${baseFee.currency}`}
        className={formClasses.formField}
        isRequired
        disabled={!reimbursementAmountOption || isFullRefund}
      />
      <MultilineButton
        mainText={t(terms.refunds.form.submit)}
        className={classes.button}
        inProgress={isSubmitting}
      />
    </ConnectForm>
  );
};

export default SubscriptionRefundFormFields;
