import { Action } from 'redux';
import initialState, { State } from '..';
import { ActionInterface, ActionType } from '../actions';
import { VehicleStateModel } from './../index';
import clearVehicle from './clearVehicle';
import getVehicleError from './getVehicleError';
import getVehicleRequest from './getVehicleRequest';
import getVehicleSuccess from './getVehicleSuccess';

const reducer = (
  state: State = initialState,
  action: Action
): VehicleStateModel => {
  switch (action.type) {
    case ActionType.GetVehicleRequest:
      return getVehicleRequest(
        state,
        action as ActionInterface.GetVehicleRequest
      );
    case ActionType.GetVehicleSuccess:
      return getVehicleSuccess(
        state,
        action as ActionInterface.GetVehicleSuccess
      );
    case ActionType.GetVehicleError:
      return getVehicleError(state, action as ActionInterface.GetVehicleError);
    case ActionType.ClearVehicle:
      return clearVehicle(state);
    default:
      return state;
  }
};

export default reducer;
