import { IFieldNames } from '../../../../utils/mapFieldsForTable';

export const fieldNames: IFieldNames = {
  status: {
    name: 'Status',
  },
  voucher_code: {
    name: 'Code',
  },
  initial_value: {
    name: 'Initial Value',
  },
  remaining_value: {
    name: 'Remaining Value',
  },
  reservation_date: {
    name: 'Reservation Date',
  },
  expiry_date: {
    name: 'Expiry Date',
  },
};
