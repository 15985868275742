import { AvailableBrands } from '../components/VinEligibility/VinEligibility';
import ApiService from './api';
import { IVehicleModel } from '../types/vehicle';
import { getTenantIdForBrand } from '../utils/TenantData';
import { config as appConfig } from '../config';

interface IRequestParams {
  vin: string;
  brand: AvailableBrands;
}

export enum RestrictionEligibleTariffs {
  'none' = 'none',
  'vehicle_based' = 'vehicle_based',
}

const getById = async ({
  vin,
  brand,
}: IRequestParams): Promise<IVehicleModel> => {
  const apiService = new ApiService(
    `/mgmt/customer/v1/tariffs/access/vehiclebased`,
    {
      data: {
        vehicle_identification_number: vin,
        tenant_id: getTenantIdForBrand(brand, appConfig.env === 'prod'),
      },
    }
  );
  const response = await apiService.query();
  return {
    vin,
    brand,
    associated_subscriptions: response.data.associated_subscriptions,
    associated_users: response.data.associated_users,
    eligible_tariffs: response.data.tariffs
      ? response.data.tariffs.map(
          (tariff: {
            name: string;
            code: string;
            restriction:
              | RestrictionEligibleTariffs.none
              | RestrictionEligibleTariffs.vehicle_based;
            accessible: boolean;
          }) => ({
            tariff_name: tariff.name,
            tariff_code: tariff.code,
            restriction: tariff.restriction,
            eligible: tariff.accessible,
          })
        )
      : [],
  };
};

export const VehiclesService = { getById };
