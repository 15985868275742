import { Grid } from '@mui/material';
import { SessionDisplayGrid } from './SessionDisplayGrid';
import React from 'react';
import useSharedStyles from '../../shared/useSharedStyles';
import { formatIsoDates } from '../../../utils/dates';
import { ChargingSessionDuration } from '../../ChargingSessionDuration/ChargingSessionDuration';
import { parseConsumption } from '../../CollectionCards/ChargingSessions/helpers';
import { ChargingSessionStatusComponent } from '../../ChargingSessionStatus/ChargingSessionStatusComponent';
import { IPublicChargingSession } from '../../../types/publicCharging';
import { Typography } from '../../shared/Typography/Typography';

interface IOwnProps {
  session: IPublicChargingSession;
}

export const SessionChargingDetails: React.FC<IOwnProps> = ({
  session,
}): JSX.Element => {
  const sharedClasses = useSharedStyles();

  const detailsGridItems = [
    { title: 'Session Start', text: formatIsoDates(session.start_datetime) },
    {
      title: 'Last updated by CPO',
      text: formatIsoDates(session.last_updated_cpo),
    },
    {
      title: 'Duration',
      content: (
        <ChargingSessionDuration
          start_datetime={session.start_datetime}
          end_datetime={session.end_datetime}
          last_updated_cpo={session.last_updated_cpo}
          session_status={session.status}
        />
      ),
    },
    {
      title: 'Session End',
      text: formatIsoDates(session.end_datetime ?? '-'),
    },
    {
      title: 'Consumption',
      text: parseConsumption(session.energy_consumption_in_kwh ?? 0),
    },
    {
      title: 'Status',
      content: <ChargingSessionStatusComponent status={session.status} />,
    },
  ];

  return (
    <Grid container data-testid='session-details'>
      <Grid item xs={12} className={sharedClasses.cardDetail}>
        <Typography variant='h4'>Details</Typography>
      </Grid>
      {SessionDisplayGrid(detailsGridItems)}
    </Grid>
  );
};
