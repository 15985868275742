import React from 'react';
import { IBreadcrumbHandlerProps } from '../../../../utils/addBreadcrumbHandler';
import { CardButton } from '../../CardButton';

type DefaultFilter = 'Business' | 'Private' | 'Public';

interface IOwnProps {
  breadcrumbHandler: (props: IBreadcrumbHandlerProps) => void;
  chargingSessionsNumber: number;
  defaultFilter: DefaultFilter;
}

export const ChargingSessionsActionButton: React.FC<IOwnProps> = ({
  chargingSessionsNumber,
  defaultFilter,
  breadcrumbHandler,
}) => (
  <CardButton
    onClick={() =>
      breadcrumbHandler({
        component: 'ChargingSessionsCollection',
        friendlyText: 'Charging Sessions',
        large: true,
        defaultFilters: [defaultFilter],
      })
    }
    length={chargingSessionsNumber}
    title='Sessions'
  />
);
