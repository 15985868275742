import React, { useState } from 'react';
import connector, { IPropsFromState } from '../../../Connector/Connector';
import useSharedStyles from '../../../shared/useSharedStyles';
import { PlugAndChargeEventHistoryHeader } from './PlugAndChargeEventHistoryHeader';
import { lexicographical } from '../../../../utils/comparisonFunctions';
import { IPlugAndChargeEvent } from '../../../../types/plugAndCharge';
import {
  resetPlugAndChargeEventHistoryQuery,
  usePlugAndChargeEventHistoryQuery,
} from '../../../../state/queries/plugAndChargeEventHistory';
import { PlugAndChargeEventHistoryTable } from './PlugAndChargeEventHistoryTable';
import { Skeleton } from '@mui/material';

type TOwnProps = IPropsFromState & {
  resourceId?: string;
  crumbId?: string;
};

const sortEventsByTimestamp = (
  sortDesc: boolean,
  events: IPlugAndChargeEvent[]
): IPlugAndChargeEvent[] => {
  // Note that { ...events } does *not* work!
  const sortedEvents = events.slice();

  return sortedEvents.sort((a, b) => {
    const ascendingSortResult = lexicographical(a.timestamp, b.timestamp);
    return sortDesc ? ascendingSortResult * -1 : ascendingSortResult;
  });
};

export const PlugAndChargeEventHistory: React.FC<TOwnProps> = ({
  subscriberState,
}) => {
  const sharedClasses = useSharedStyles();

  const emaid = subscriberState.data?.plug_and_charge.emaid ?? '';

  const {
    isLoading,
    isError,
    data: events,
  } = usePlugAndChargeEventHistoryQuery(emaid);

  const [sortDesc, setSortDesc] = useState(true);

  return (
    <div className={sharedClasses.cardElement}>
      <PlugAndChargeEventHistoryHeader
        emaid={emaid}
        refreshEventsHandler={() => resetPlugAndChargeEventHistoryQuery(emaid)}
      />
      {isLoading || isError ? (
        <div style={{ height: '400px' }}>
          <Skeleton width={'100%'} height={'100%'} />
        </div>
      ) : (
        <PlugAndChargeEventHistoryTable
          sortedEvents={events ? sortEventsByTimestamp(sortDesc, events) : []}
          sortDesc={sortDesc}
          setSortDesc={setSortDesc}
        />
      )}
    </div>
  );
};

export default connector(PlugAndChargeEventHistory);
