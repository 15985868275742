import { ChargingState, TChargingState } from './ChargeState/ChargingState';
import {
  IBreadcrumbHandlerProps,
  addBreadcrumbHandler,
} from '../../../utils/addBreadcrumbHandler';
import React, { useEffect, useState } from 'react';
import connector, { IPropsFromState } from '../../Connector/Connector';

import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import { IChargingRecordViewModel } from '../../../types/chargingRecords';
import { IHomeStation } from '../../../types/user';
import IconButton from '@mui/material/IconButton';
import decimalToComma from '../../../utils/decimalToComma';
import elviCharger from '../../../assets/image/charger_Elvi_base.svg';
import mgwb from '../../../assets/image/charger_ID_base.svg';
import useStyles from './useStyles';

const headerTileString = 'Wallbox';

export const CHARGER_MAP = {
  EVBOX_MGWB: headerTileString,
  EVBOX_ELVI: headerTileString,
};

export type TValidChargers = 'EVBOX_MGWB' | 'EVBOX_ELVI';

const getChargerImage = (model: string): string => {
  switch (model) {
    case 'EVBOX_MGWB':
      return mgwb;
    case 'EVBOX_ELVI':
      return elviCharger;
    default:
      return '';
  }
};

type TOwnProps = IPropsFromState & {
  homeStations: IHomeStation[];
  setHomeStationTitle: React.Dispatch<React.SetStateAction<string>>;
};

export const HomeCharger: React.FC<TOwnProps> = ({
  homeStations,
  chargingSessionsState,
  breadcrumbState,
  setHomeStationTitle,
  addBreadcrumb,
}) => {
  const classes = useStyles();
  const taskBreadcrumbState = breadcrumbState.breadcrumbs;

  const taskChargingSessions = chargingSessionsState;

  const [currentStation, setCurrentStation] = useState<IHomeStation>(
    homeStations[0]
  );

  const getChargingStatus = (homeStation: IHomeStation) => {
    if (homeStation.connection_state === 'disconnected') {
      return 'disconnected';
    }
    if (!homeStation.evses || homeStation.evses.length === 0) {
      return 'unreported';
    }
    return homeStation.evses[0].status as TChargingState;
  };

  const [totalEnergy, setTotalEnergy] = useState<string>('0');

  useEffect(() => {
    if (taskChargingSessions && taskChargingSessions.data && currentStation) {
      let total = taskChargingSessions.data.reduce(
        (accum: number, current: IChargingRecordViewModel) => {
          if (current.station_id !== currentStation.id) {
            return accum;
          }

          return accum + current.consumption;
        },
        0
      );

      const pieces = total.toString().split('.');
      if (pieces[1] && pieces[1].length > 3) {
        total = +total.toFixed(3);
      }

      setTotalEnergy(decimalToComma(total));
    }
  }, [taskChargingSessions, currentStation]);

  const breadcrumbHandler = (props: IBreadcrumbHandlerProps): void =>
    addBreadcrumbHandler({
      ...props,
      addBreadcrumb,
      taskBreadcrumbState,
    });

  const traverseChargers = () => {
    const currentIndex = homeStations.findIndex(
      (hs) => hs.id === currentStation.id
    );
    if (homeStations[currentIndex + 1]) {
      setCurrentStation(homeStations[currentIndex + 1]);
      setHomeStationTitle(
        CHARGER_MAP[homeStations[currentIndex + 1].model as TValidChargers]
      );
    } else {
      setCurrentStation(homeStations[0]);
      setHomeStationTitle(CHARGER_MAP[homeStations[0].model as TValidChargers]);
    }
  };

  const traverseChargersBack = () => {
    const currentIndex = homeStations.findIndex(
      (hs) => hs.id === currentStation.id
    );
    if (homeStations[currentIndex - 1]) {
      setCurrentStation(homeStations[currentIndex - 1]);
      setHomeStationTitle(
        CHARGER_MAP[homeStations[currentIndex - 1].model as TValidChargers]
      );
    } else {
      setCurrentStation(homeStations[homeStations.length - 1]);
      setHomeStationTitle(
        CHARGER_MAP[
          homeStations[homeStations.length - 1].model as TValidChargers
        ]
      );
    }
  };

  if (!currentStation) {
    return null;
  }

  return (
    <div
      className={`${classes.componentWrapper} ${
        homeStations.length === 1 ? classes.componentWrapperSingle : ''
      }`}
    >
      <div className={classes.chargerWrapper}>
        <div className={classes.chargerIcon}>
          <img
            src={getChargerImage(currentStation.model as string)}
            alt='Charger type icon'
          />
          <span
            className={`${classes.chargerStateIcon} ${
              currentStation.model === 'EVBOX_MGWB'
                ? classes.evBoxCharger
                : classes.elviCharger
            }`}
          >
            <ChargingState status={getChargingStatus(currentStation)} />
          </span>
        </div>
        <div className={classes.chargerDetails}>
          <span className={classes.totalEnergy}>{totalEnergy} kWh</span>
          <span className={classes.energyText}>Total energy provided</span>
          <span className={classes.informationText}>
            Name: {currentStation.name || '-'}
          </span>
          <span className={classes.informationText}>
            Serial number: {currentStation.station_serial_number || '-'}
          </span>
          {homeStations.length > 1 && (
            <div className={classes.stationDotsWrapper}>
              {homeStations.map((station) =>
                station.id === currentStation.id ? (
                  <span key={station.id} className={classes.dotCurrent}></span>
                ) : (
                  <span key={station.id} className={classes.dot}></span>
                )
              )}
            </div>
          )}
        </div>
      </div>
      <div
        className={`${classes.cardOverlay} ${
          homeStations.length === 1 ? classes.cardOverlaySingle : ''
        }`}
      >
        {homeStations.length > 1 && (
          <IconButton
            onClick={() => traverseChargersBack()}
            color='primary'
            data-testid='back-button'
            disableRipple
            disableFocusRipple
            classes={{
              root: classes.iconButton,
            }}
            size='large'
          >
            <ArrowForwardIosIcon
              style={{
                transform: 'rotate(180deg)',
              }}
            />
          </IconButton>
        )}
        <button
          onClick={() =>
            breadcrumbHandler({
              component: 'ChargingStationDetails',
              friendlyText: 'Charging Station',
              id: currentStation.id,
              config: {
                station_id: currentStation.id,
              },
            })
          }
          className={classes.detailButton}
        >
          Details
        </button>
        {homeStations.length > 1 && (
          <IconButton
            onClick={() => traverseChargers()}
            data-testid='forward-button'
            color='primary'
            disableRipple
            disableFocusRipple
            classes={{
              root: classes.iconButton,
            }}
            size='large'
          >
            <ArrowForwardIosIcon />
          </IconButton>
        )}
      </div>
    </div>
  );
};

export default connector(HomeCharger);
