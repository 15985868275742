import { ITerminateSubscriptionResponse } from '../../../../services/subscriptions';
import React from 'react';
import { Typography } from '../../../shared/Typography/Typography';

export type OwnProps = {
  terminationResponse: ITerminateSubscriptionResponse;
};

export const TechnicalIds: React.FC<OwnProps> = ({ terminationResponse }) => {
  return (
    <div>
      <Typography variant='inherit'>
        IAM ID: {terminationResponse.iam_id}
      </Typography>
      <br />
      <Typography variant='inherit'>
        Tariff Code: {terminationResponse.tariff_code}
      </Typography>
      <br />
      <Typography variant='inherit'>
        VIN: {terminationResponse.vehicle_identification_number}
      </Typography>
      <br />
    </div>
  );
};
