import makeStyles from '@mui/styles/makeStyles';
import createStyles from '@mui/styles/createStyles';

const useStyles = makeStyles(() =>
  createStyles({
    indicator: {
      borderRadius: '5px',
      height: 8,
      width: 8,
      marginRight: 4,
    },
    statusPulse: {
      boxShadow: '0 0 0 0 rgba(255, 140, 0, 1)',
      animation: '$pulse-orange 2s infinite',
    },
    '@keyframes pulse-orange': {
      '0%': {
        transform: 'scale(0.95)',
        boxShadow: '0 0 0 0 rgba(255, 140, 0, 0.7)',
      },
      '70%': {
        transform: 'scale(1)',
        boxShadow: '0 0 0 10px rgba(255, 140, 0, 0)',
      },
      '100%': {
        transform: 'scale(0.95)',
        boxShadow: '0 0 0 0 rgba(255, 140, 0, 0)',
      },
    },
  })
);

export default useStyles;
