import * as yup from 'yup';
import {
  EInvoiceRefundType,
  EInvoiceReimbursementAmount,
  IInvoiceRefundLineItem,
} from '../../../../../../types/invoice';
import { countryInformationMapper } from '../../../../../../utils/currencies';

export const subscriptionRefundFormSchema = (
  subscriptionFeeAmount: number,
  currency: string
): yup.SchemaOf<IInvoiceRefundLineItem> =>
  yup.object().shape({
    invoice_line_item_id: yup.string().required(),
    process_id: yup.string().required(),
    type: yup
      .mixed<EInvoiceRefundType>()
      .is([EInvoiceRefundType.SUBSCRIPTION_FEE])
      .required(),
    reimbursement_amount: yup
      .mixed<EInvoiceReimbursementAmount>()
      .oneOf(Object.values(EInvoiceReimbursementAmount))
      .required(),
    gross_amount_to_refund: yup
      .number()
      .max(subscriptionFeeAmount)
      .positive()
      .required()
      .when([], {
        is: () => currency === countryInformationMapper.HU.currency,
        then: yup
          .number()
          .integer(
            `for ${countryInformationMapper.HU.currency} currency, amount should be a whole number`
          )
          .max(subscriptionFeeAmount)
          .positive()
          .required(),
        otherwise: yup
          .number()
          .max(subscriptionFeeAmount)
          .positive()
          .required(),
      }),
  });
