import React from 'react';
import Dialog from '@mui/material/Dialog';
import useStyles from './useStyles';
import { Typography } from '../../../shared/Typography/Typography';

export interface IOwnProps {
  open: boolean;
  onClose: (value: string) => void;
}

export const AccessModeDialog: React.FC<IOwnProps> = ({ open, onClose }) => {
  const classes = useStyles();

  return (
    <Dialog
      onClose={onClose}
      open={open}
      data-testid='access-mode-dialog'
      PaperProps={{
        classes: {
          root: classes.dialog,
        },
      }}
    >
      <div className={classes.textWrapper}>
        <div className={classes.section}>
          <span className={classes.title}>Instant Charging</span>
          <Typography variant='body2'>
            Anyone can use the station. Plugging in an electric vehicle will
            immediately start a charging session. No RFID card needed.
          </Typography>
        </div>
        <div className={classes.section}>
          <span className={classes.title}>Free Charging</span>
          <Typography variant='body2'>
            Charging requires authentication on the station with an RFID card.
            Any RFID card can be used for authorization.
          </Typography>
        </div>
        <div className={classes.section}>
          <span className={classes.title}>Private Charging</span>
          <Typography variant='body2'>
            Charging requires authentication on the station with an RFID card.
            Only cards that the station’s owner authorized can be used to start
            charging sessions.
          </Typography>
        </div>
        <div className={classes.section}>
          <span className={classes.title}>Public Charging</span>
          <Typography variant='body2'>
            The station becomes part of the Volkswagen Group Charging Network
            and drivers using 3rd party services can charge at that station.
            Charging requires authentication on the station with an RFID card.
          </Typography>
        </div>
      </div>
    </Dialog>
  );
};
