import { Theme } from '@mui/material';

import makeStyles from '@mui/styles/makeStyles';
import createStyles from '@mui/styles/createStyles';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    unknownSubscriber: {
      color: theme.textSecondary,
    },
  })
);

export default useStyles;
