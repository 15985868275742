import React from 'react';

import CopyTextField, { IOwnProps as ICopyTextField } from './index';

export interface IOwnProps {
  textList: ICopyTextField[];
}

/**
 * Container component to create CopyTextField elements by a simple config
 * @param textList given data for list
 */
const CopyTextFieldList: React.FC<IOwnProps> = ({ textList }) => (
  <>
    {textList.map((props) => (
      <CopyTextField key={props.title} {...props} />
    ))}
  </>
);

export default CopyTextFieldList;
