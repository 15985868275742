import React from 'react';
import {
  AssetTable,
  IColumn,
  IRow,
} from '../../shared/AssetTable/AssetTable/AssetTable';
import { ElliTooltip } from '../../shared/ElliTooltip/ElliTooltip';
import { IConnectorViewModel } from '../../../view-models/StationModel';
import { Status } from './Status/Status';
import { TypeIndicator } from './TypeIndicator/TypeIndicator';
import decimalToComma from '../../../utils/decimalToComma';
import useSharedStyles from '../../shared/useSharedStyles';
import {
  IFieldNames,
  mapFieldsForTable,
} from '../../../utils/mapFieldsForTable';

const getColumns = (type: string): IColumn[] => {
  const fieldNames: IFieldNames = {
    evse_id: {
      name: 'EVSE ID',
    },
    type: {
      name: 'Type',
    },
    max_power: {
      name: 'Max. Power',
    },
    status: {
      name: 'Status',
    },
  };

  if (type === 'public') {
    fieldNames.pricing = {
      name: 'Pricing',
    };
  }

  return mapFieldsForTable(fieldNames, []);
};

const getRows = (chargingSpots: IConnectorViewModel[]): IRow[] => {
  const sharedClasses = useSharedStyles();

  if (!chargingSpots.length) {
    return [];
  }

  return chargingSpots.map((cs, index) => {
    return {
      data: {
        evse_id: {
          content: cs.evse_id ? (
            <ElliTooltip title={cs.evse_id}>
              <span className={sharedClasses.textOverflow}>{cs.evse_id}</span>
            </ElliTooltip>
          ) : (
            '-'
          ),
        },
        type: {
          content: cs.connector_type ? (
            <TypeIndicator
              plugType={cs.connector_type}
              powerType={cs.power_type}
            />
          ) : (
            '-'
          ),
        },
        max_power: {
          content: cs.max_power
            ? cs.max_power_type === 'w'
              ? `${decimalToComma(cs.max_power / 1000)} kW`
              : `${decimalToComma(cs.max_power)} kW`
            : '-',
        },
        status: {
          content: <Status status={cs.status || 'UNKNOWN'} />,
        },
        pricing: {
          content: cs.price || '',
        },
        // actions: {
        //   content: (
        //     <IconButton color='primary'>
        //       <MoreVertIcon
        //         style={{
        //           fontSize: 20,
        //         }}
        //       />
        //     </IconButton>
        //   ),
        // },
      },
      id: index.toString(),
    };
  });
};

interface IOwnProps {
  chargingSpots: IConnectorViewModel[];
  type: string;
}

export const Connectors: React.FC<IOwnProps> = ({ chargingSpots, type }) => {
  return (
    <div>
      <AssetTable
        data-testid='asset-table'
        bulkActions={[]}
        columns={getColumns(type)}
        rows={getRows(chargingSpots)}
        sortRequestHandler={() => true}
        noDataTitle='No connector information'
        noDataSubTitle='This station is not updating its connector information.'
      />
    </div>
  );
};

export default Connectors;
