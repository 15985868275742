import React from 'react';
import { useTheme } from '@mui/material/styles';
import {
  BasicStatus,
  IMappedStatus,
} from '../../../shared/StatusIndicator/BasicStatus';

export const TariffEligibilityStatus: React.FC<{
  status: boolean;
}> = ({ status }) => {
  const theme = useTheme();

  function valueMapper(value: boolean): IMappedStatus {
    return value
      ? { color: theme.colorAccent, label: 'Eligible' }
      : { color: theme.surfaceTertiary, label: 'Not eligible' };
  }

  return <BasicStatus status={status} valueMapper={valueMapper} />;
};
