import makeStyles from '@mui/styles/makeStyles';
import createStyles from '@mui/styles/createStyles';

const useStyles = makeStyles((theme) =>
  createStyles({
    divContainer: {
      maxWidth: '600px',
      alignItems: 'center',
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'center',
    },

    errorBoundary: {
      alignItems: 'center',
      display: 'flex',
      justifyContent: 'center',
      height: '100vh',
      width: '100vw',
    },
    errorContainer: {
      borderRadius: 8,
      backgroundColor: '#1e293b',
      color: 'white',
      padding: 10,
    },
    errorHeadline: {
      margin: '16px 0px 16px 0px',
      textAlign: 'center',
    },
    link: {
      color: theme.colorPrimary,
      marginTop: '10px',
    },
    subHeading: {
      fontWeight: 300,
      textAlign: 'center' as const,
    },
  })
);

export default useStyles;
