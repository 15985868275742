interface IAddress {
  address_line_2: string;
  city: string;
  country_code: string;
  house_number: string;
  street: string;
  zip: string;
}

export default (address: IAddress): string => {
  let formattedAddress = ``;

  if (address.house_number !== ' ') {
    formattedAddress += `${address.house_number} `;
  }

  if (address.street !== ' ') {
    formattedAddress += `${address.street}, `;
  }
  if (address.address_line_2) {
    formattedAddress += `${address.address_line_2}, `;
  }

  formattedAddress += `${address.zip} ${address.city}, ${address.country_code}`;

  return formattedAddress;
};
