import { Theme } from '@mui/material';

import makeStyles from '@mui/styles/makeStyles';
import createStyles from '@mui/styles/createStyles';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    dataBoxGrey: { backgroundColor: theme.palette.grey['200'], padding: '1em' },
  })
);

export default useStyles;
