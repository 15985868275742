import createStyles from '@mui/styles/createStyles';
import makeStyles from '@mui/styles/makeStyles';

const useStyles = makeStyles(() =>
  createStyles({
    itemTitle: {
      fontSize: 12,
      color: '#878c96',
      marginBottom: 10,
      lineHeight: 1.33,
    },
    itemValue: {
      fontSize: 14,
      display: 'flex',
      alignItems: 'center',
      maxWidth: '100%',
    },
    itemWrapper: {
      display: 'flex',
      flexFlow: 'column nowrap',
      alignItems: 'flex-start',
      justifyContent: 'space-between',
      marginBottom: 24,
      width: 200,
      flex: '0 0 33%',
    },
    stationRefreshing: {
      opacity: 0.5,
    },
    outerItemWrapper: {
      display: 'flex',
      alignItems: 'flex-start',
      justifyContent: 'flex-start',
      flexFlow: 'row wrap',
    },
  })
);

export default useStyles;
