import React from 'react';
import { PaymentCard } from './PaymentCard';
import { PaymentCardInformation } from './PaymentCardInformation';
import useStyles from './useStyles';
import { IPaymentMethod } from '../../../../types/paymentMethods';
import { PaymentEWalletInformation } from './PaymentEWalletInformation';
import { CardSubheadline } from '../../../shared/InformationItems/CardSubheadline';

interface IPaymentCardSection {
  paymentMethod: IPaymentMethod;
}

export const PaymentCardSection: React.FC<IPaymentCardSection> = ({
  paymentMethod,
}) => {
  const classes = useStyles({});

  if (paymentMethod.paymentType === 'E-Wallets') {
    return (
      <div data-testid='payment-ewallet-section'>
        <CardSubheadline title={paymentMethod.name} />
        <PaymentEWalletInformation
          email={paymentMethod.carrierNumber}
          status={paymentMethod.statusCode}
        ></PaymentEWalletInformation>
      </div>
    );
  }
  return (
    <div data-testid='payment-card-section'>
      <CardSubheadline title='Credit Card' />
      <div className={classes.cardDetails} data-testid='payment-card-details'>
        <PaymentCard
          cardNumber={paymentMethod.carrierNumber}
          expiryMonth={paymentMethod.expiryMonth}
          expiryYear={paymentMethod.expiryYear}
          name={paymentMethod.ownerName}
          iconUrl={paymentMethod.iconUrl}
        />
        <PaymentCardInformation
          cardIssuer={paymentMethod.name}
          bankName={paymentMethod.bankName}
          expiryMonth={paymentMethod.expiryMonth}
          expiryYear={paymentMethod.expiryYear}
          status={paymentMethod.statusCode}
        />
      </div>
    </div>
  );
};
