import {
  alpha,
  createTheme,
  StyledEngineProvider,
  Theme,
  ThemeProvider,
} from '@mui/material/styles';
import { merge } from 'lodash';
import { ExpandMore } from '@mui/icons-material';
import React from 'react';
import { ElliTheme } from '@elli-eco/component-library';

import InterRegularLatinExt from './assets/fonts/Inter-Regular-Latin-Ext.woff2';
import InterRegularLatin from './assets/fonts/Inter-Regular-Latin.woff2';

const elliTheme = ElliTheme.mainTheme;

enum Colors {
  BodyBackground = '#f5f6f8',
  DarkPopularPink = '#DF0061',
  iconHoverColor = 'rgba(0, 0, 0, 0.08)',
}

interface ICustomThemeProperties {
  colorAlertPrimaryDark: string;
  colorBodyBackground: string;
  portalPadding: string;
}

declare module '@mui/material/styles/createTheme' {
  // eslint-disable-next-line @typescript-eslint/naming-convention, @typescript-eslint/no-empty-interface
  interface Theme extends ICustomThemeProperties {}

  // eslint-disable-next-line @typescript-eslint/naming-convention, @typescript-eslint/no-empty-interface
  interface ThemeOptions extends ICustomThemeProperties {}
}

declare module '@mui/material/styles' {
  // eslint-disable-next-line @typescript-eslint/naming-convention, @typescript-eslint/no-empty-interface
  interface Theme extends ICustomThemeProperties {}

  // eslint-disable-next-line @typescript-eslint/naming-convention, @typescript-eslint/no-empty-interface
  interface ThemeOptions extends ICustomThemeProperties {}
}

export const mainTheme = createTheme({
  colorAlertPrimaryDark: Colors.DarkPopularPink,
  colorBodyBackground: Colors.BodyBackground,
  portalPadding: '36px',

  components: {
    MuiCssBaseline: {
      styleOverrides: `
        @font-face {
          font-family: 'Inter';
          font-style: normal;
          font-display: swap;
          font-weight: 300;
          src: local('Inter'), url(${InterRegularLatinExt}) format('woff2');
          unicode-range: U+0100-024F, U+0259, U+1E00-1EFF, U+2020, U+20A0-20AB, U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF;
        }
        @font-face {
          font-family: 'Inter';
          font-style: normal;
          font-display: swap;
          font-weight: 300;
          src: local('Inter'), url(${InterRegularLatin}) format('woff2');
          unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
        }        
        @font-face {
          font-family: 'Inter';
          font-style: normal;
          font-display: swap;
          font-weight: 400;
          src: local('Inter'), url(${InterRegularLatinExt}) format('woff2');
          unicode-range: U+0100-024F, U+0259, U+1E00-1EFF, U+2020, U+20A0-20AB, U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF;
        }
        @font-face {
          font-family: 'Inter';
          font-style: normal;
          font-display: swap;
          font-weight: 400;
          src: local('Inter'), url(${InterRegularLatin}) format('woff2');
          unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
        }
        @font-face {
          font-family: 'Inter';
          font-style: normal;
          font-display: swap;
          font-weight: 500;
          src: local('Inter'), url(${InterRegularLatinExt}) format('woff2');
          unicode-range: U+0100-024F, U+0259, U+1E00-1EFF, U+2020, U+20A0-20AB, U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF;
        }
        @font-face {
          font-family: 'Inter';
          font-style: normal;
          font-display: swap;
          font-weight: 500;
          src: local('Inter'), url(${InterRegularLatin}) format('woff2');
          unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
        }
        @font-face {
          font-family: 'Inter';
          font-style: normal;
          font-display: swap;
          font-weight: 600;
          src: local('Inter'), url(${InterRegularLatinExt}) format('woff2');
          unicode-range: U+0100-024F, U+0259, U+1E00-1EFF, U+2020, U+20A0-20AB, U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF;
        }
        @font-face {
          font-family: 'Inter';
          font-style: normal;
          font-display: swap;
          font-weight: 600;
          src: local('Inter'), url(${InterRegularLatin}) format('woff2');
          unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
        }
        @font-face {
          font-family: 'Inter';
          font-style: normal;
          font-display: swap;
          font-weight: 700;
          src: local('Inter'), url(${InterRegularLatinExt}) format('woff2');
          unicode-range: U+0100-024F, U+0259, U+1E00-1EFF, U+2020, U+20A0-20AB, U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF;
        }
        @font-face {
          font-family: 'Inter';
          font-style: normal;
          font-display: swap;
          font-weight: 700;
          src: local('Inter'), url(${InterRegularLatin}) format('woff2');
          unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
        }
      `,
    },
    MuiButton: {
      styleOverrides: {
        containedSecondary: {
          '&:hover': {
            backgroundColor: elliTheme.colorAccent,
            boxShadow: `0 8px 32px 0 ${alpha(elliTheme.colorAccent, 0.6)}`,
          },
          borderRadius: 0,
          boxShadow: `0 4px 12px 0 ${alpha(elliTheme.colorAccent, 0.3)}`,
          letterSpacing: 'normal',
        },
        outlinedPrimary: {
          '&&': {
            borderWidth: '2px',
          },
          borderColor: elliTheme.colorPrimary,
        },
        textPrimary: {
          '&&': { background: 'transparent' },
          '&:focus': { textDecoration: 'underline' },
          '&:hover': { textDecoration: 'underline' },
          color: elliTheme.colorPrimary,
          minWidth: '0',
          padding: '0',
          textDecoration: 'none',
          whiteSpace: 'nowrap',
        },
      },
    },
    MuiFormLabel: {
      styleOverrides: {
        root: {
          fontSize: '16px',
        },
      },
    },
    MuiIconButton: {
      styleOverrides: {
        root: {
          padding: '0',
          '&:hover': {
            backgroundColor: Colors.iconHoverColor,
          },
        },
        colorPrimary: {
          '&:hover': {
            backgroundColor: Colors.iconHoverColor,
          },
        },
      },
    },
    MuiSvgIcon: {
      styleOverrides: {
        root: {
          fontSize: undefined,
        },
      },
    },
    MuiTableRow: {
      styleOverrides: {
        hover: {
          '&$hover:hover': {
            backgroundColor: elliTheme.surfaceSecondary,
          },
        },
      },
    },
    MuiInputLabel: {
      styleOverrides: {
        root: {
          '&.MuiInputLabel-outlined': {
            background: '#fff',
            paddingRight: 4,
          },
        },
      },
    },
    MuiSelect: {
      defaultProps: {
        IconComponent: ExpandMore,
      },
    },
  },
  palette: {
    error: {
      contrastText: elliTheme.textPrimary,
      main: elliTheme.colorAlertPrimary,
    },
    primary: {
      contrastText: elliTheme.surfacePrimary,
      main: elliTheme.colorPrimary,
    },
    secondary: {
      contrastText: elliTheme.textPrimary,
      main: elliTheme.colorAccent,
    },
    text: {
      primary: elliTheme.textPrimary,
      secondary: elliTheme.textSecondary,
    },
    colors: elliTheme.palette.colors,
  },
  shape: {
    borderRadius: 0,
  },
  typography: {
    // useNextVariants: true,
    body1: {
      // Using this for menu / list items
      fontSize: '14px',
      fontWeight: 600,
      letterSpacing: '0.14px',
    },
    body2: {
      // Note that Material UI uses body2 as the default
      color: elliTheme.textPrimary,
      fontSize: '14px',
      lineHeight: '1.43',
      fontWeight: 400,
      letterSpacing: '0.14px',
    },
    button: {
      fontSize: '16px',
      fontWeight: 700,
      textTransform: 'none',
    },
    caption: {
      fontSize: '12px',
      letterSpacing: '0px',
      lineHeight: '1.33',
    },
    fontFamily: 'Inter, sans-serif',
    fontWeightBold: 800,
    fontWeightLight: 300,
    fontWeightMedium: 700,
    fontWeightRegular: 400,
    h1: {
      // Title1 (Zeplin)
      fontSize: '28px',
      fontWeight: 600,
      lineHeight: '1.21',
    },
    h2: {
      // Title2
      fontSize: '24px',
      fontWeight: 600,
      lineHeight: '1.33',
    },
    h3: {
      // Title 3
      fontSize: '18px',
      fontWeight: 400,
      lineHeight: '1.78',
    },
    h4: {
      // Headline
      fontSize: '16px',
      fontWeight: 600,
      lineHeight: '1.38',
    },
    h5: {
      color: elliTheme.colorPrimary,
      fontSize: '12px',
      fontWeight: 400,
      lineHeight: '1.33',
      letterSpacing: 0,
    },
    h6: {
      color: elliTheme.textSecondary,
      fontSize: '12px',
      fontWeight: 400,
      lineHeight: '1.33',
      letterSpacing: '0.4px',
    },
  },
});

export type MainTheme = typeof mainTheme;

const mergeWithElliTheme = (elliTheme: Theme) =>
  merge({}, elliTheme, mainTheme);

export const Provider: React.FC = ({ children }) => (
  <ElliTheme.Provider>
    <StyledEngineProvider injectFirst>
      <ThemeProvider theme={mergeWithElliTheme}>{children}</ThemeProvider>
    </StyledEngineProvider>
  </ElliTheme.Provider>
);
