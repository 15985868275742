import React, { useEffect, useRef, useState } from 'react';
import connector, { IPropsFromState } from '../Connector/Connector';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import { BreadcrumbModel } from '../../store/state/breadcrumbs';
import Button from '@mui/material/Button';
import ClickAwayListener from '@mui/material/ClickAwayListener';
import { ElliTooltip } from '../shared/ElliTooltip/ElliTooltip';
import Grow from '@mui/material/Grow';
import MenuItem from '@mui/material/MenuItem';
import MenuList from '@mui/material/MenuList';
import Paper from '@mui/material/Paper';
import Popper from '@mui/material/Popper';
import useStyles from './useStyles';

type IOwnProps = IPropsFromState & {
  containerRef?: React.RefObject<HTMLDivElement>;
  setProgramScroll: React.Dispatch<React.SetStateAction<boolean>>;
};

export const Breadcrumbs: React.FC<IOwnProps> = ({
  userState,
  breadcrumbState,
  containerRef,
  addBreadcrumb,
  removeBreadcrumb,
  setProgramScroll,
}) => {
  const classes = useStyles();
  const dropdownRef = useRef<HTMLButtonElement>(null);
  const breadcrumbRef = useRef<HTMLDivElement>(null);

  const [fullNameOrEmail, setFullNameOrEmail] = useState<string>('');
  const [breadcrumbs, setBreadcrumbs] = useState<BreadcrumbModel[]>([]);
  const [dropdownCrumbs, setDropdownCrumbs] = useState<BreadcrumbModel[]>([]);
  const [open, setOpen] = useState(false);
  const [dropdownCurrent, setDropdownCurrent] = useState<boolean>(false);

  const user = userState;

  useEffect(() => {
    if (user.data) {
      if (user.data.first_name) {
        setFullNameOrEmail(`${user.data.first_name} ${user.data.last_name}`);
      } else {
        setFullNameOrEmail(user.data.email);
      }
    }
  }, [user]);

  const getBreadcrumbWrapperWidth = (): number => {
    if (breadcrumbRef && breadcrumbRef.current) {
      return breadcrumbRef.current.offsetWidth;
    }

    return 0;
  };

  const getContainerWidth = (): number => {
    if (containerRef && containerRef.current) {
      return containerRef.current.clientWidth;
    }

    return 250;
  };

  useEffect(() => {
    if (breadcrumbState && breadcrumbState.breadcrumbs) {
      setBreadcrumbs([]);
      setDropdownCrumbs([]);
      const allCrumbs = breadcrumbState.breadcrumbs;
      const breadcrumbWrapperWidth = getBreadcrumbWrapperWidth();
      const containerWidth = getContainerWidth();
      const collapseOnWidth = breadcrumbWrapperWidth + 250 > containerWidth;
      const collapseOnAmount = allCrumbs.length > 4;

      if (collapseOnWidth || collapseOnAmount) {
        const num = collapseOnWidth
          ? Math.trunc((containerWidth - 250) / 190)
          : 4;
        const bc = allCrumbs.slice(num * -1);
        const dropCrumbs = allCrumbs.slice(0, num * -1);
        setBreadcrumbs(bc);
        setDropdownCrumbs(dropCrumbs);
        return;
      }
      setDropdownCurrent(false);
      setBreadcrumbs(allCrumbs);
    }
  }, [breadcrumbState]);

  useEffect(() => {
    if (!breadcrumbState) {
      const data = {
        component: 'Overview',
        friendlyText: 'Overview',
        level: 0,
        midPoint: 200,
        startPoint: 0,
      };

      addBreadcrumb({ data });
    }
  }, []);

  useEffect(() => {
    if (!open && containerRef && containerRef.current) {
      containerRef.current.focus();
    }
  }, [open]);

  useEffect(() => {
    if (dropdownCrumbs.length) {
      const hasCurrent = dropdownCrumbs.find((crumb) => crumb.current);
      if (hasCurrent) {
        setDropdownCurrent(true);
      } else {
        setDropdownCurrent(false);
      }
    }
  }, [dropdownCrumbs]);

  const handleClose = (
    event: Pick<Partial<Event>, 'target'>,
    position?: number
  ): void => {
    if (
      dropdownRef.current &&
      dropdownRef.current.contains(event.target as HTMLElement)
    ) {
      return;
    }
    if (position !== undefined) {
      setProgramScroll(true);
      removeBreadcrumb({ position });
    }
    setOpen(false);
  };

  const handleToggle = (): void => {
    setOpen((prevOpen) => !prevOpen);
  };

  const handleBreadcrumbClick = (
    _e: React.MouseEvent<HTMLSpanElement>,
    breadcrumb: BreadcrumbModel
  ): void => {
    setProgramScroll(true);
    removeBreadcrumb({ position: breadcrumb.level });
  };

  const renderBreadcrumbs = (
    breadcrumb: BreadcrumbModel,
    index: number
  ): JSX.Element => {
    const isCurrent = breadcrumb.current;

    if (breadcrumbs.length === 1) {
      return (
        <span
          className={`${classes.breadcrumb} ${classes.lastBreadcrumb}`}
          data-testid='default-breadcrumb'
          key={index}
        >
          {breadcrumb.friendlyText}
        </span>
      );
    }

    switch (index) {
      case 0:
        return (
          <span
            data-testid='clickable-breadcrumb'
            className={`${classes.actionItem}`}
            key={index}
          >
            <span
              data-testid='breadcrumb-click-span'
              className={`${classes.breadcrumLink} ${
                classes.breadcrumb
              } ${isCurrent && classes.currentBreadcrumb}`}
              onClick={(event: React.MouseEvent<HTMLSpanElement>): void =>
                handleBreadcrumbClick(event, breadcrumb)
              }
            >
              {breadcrumb.friendlyText}
            </span>
          </span>
        );
      case breadcrumbs.length - 1:
        return (
          <span key={index} className={classes.arrowSpan}>
            <ArrowForwardIosIcon className={classes.arrow} />{' '}
            <span
              className={`${classes.breadcrumb} ${isCurrent &&
                classes.currentBreadcrumb} ${classes.lastBreadcrumb}`}
            >
              {breadcrumb.friendlyText}
            </span>
          </span>
        );
      default:
        return (
          <span
            data-testid='clickable-breadcrumb'
            className={`${classes.actionItem} ${classes.arrowSpan}`}
            key={index}
          >
            <ArrowForwardIosIcon className={classes.arrow} />{' '}
            <span
              data-testid='breadcrumb-click-span'
              className={`${classes.breadcrumb} ${
                classes.breadcrumLink
              } ${isCurrent && classes.currentBreadcrumb}`}
              onClick={(event: React.MouseEvent<HTMLSpanElement>): void =>
                handleBreadcrumbClick(event, breadcrumb)
              }
            >
              {breadcrumb.friendlyText}
            </span>
          </span>
        );
    }
  };

  return (
    <div>
      <div className={classes.breadcrumbWrapper} ref={breadcrumbRef}>
        <span className={classes.name} data-testid='user-or-email'>
          {fullNameOrEmail}:{' '}
        </span>
        {Boolean(dropdownCrumbs.length) && (
          <span data-testid='breadcrumb-dropdown'>
            <Button
              ref={dropdownRef}
              onClick={handleToggle}
              data-testid='breadcrumb-button'
              classes={{
                root: dropdownCurrent
                  ? classes.dropdownButtonUnderline
                  : classes.dropdownButton,
              }}
            >
              ...
            </Button>
            <Popper
              open={open}
              anchorEl={dropdownRef.current}
              role={undefined}
              transition
              disablePortal
              placement='bottom-start'
            >
              {({ TransitionProps }): JSX.Element => (
                <Grow
                  {...TransitionProps}
                  style={{
                    transformOrigin: 'left top',
                  }}
                >
                  <Paper
                    classes={{
                      root: classes.dropdownPaper,
                    }}
                  >
                    <ClickAwayListener
                      onClickAway={(event) => handleClose(event)}
                    >
                      <MenuList
                        classes={{
                          root: classes.menuList,
                        }}
                        autoFocusItem={open}
                        id='menu-list-grow'
                      >
                        {dropdownCrumbs.map((crumb) => (
                          <MenuItem
                            classes={{
                              root: classes.menuItem,
                            }}
                            data-testid='breadcrumb-dropdown-item'
                            key={crumb.id}
                            onClick={(event) => handleClose(event, crumb.level)}
                          >
                            <ElliTooltip title={crumb.friendlyText}>
                              <span className={classes.menuItemText}>
                                {crumb.friendlyText}
                              </span>
                            </ElliTooltip>
                          </MenuItem>
                        ))}
                      </MenuList>
                    </ClickAwayListener>
                  </Paper>
                </Grow>
              )}
            </Popper>
          </span>
        )}
        {breadcrumbs.map((breadcrumb, index) =>
          renderBreadcrumbs(breadcrumb, index)
        )}
      </div>
    </div>
  );
};

export default connector(Breadcrumbs);
