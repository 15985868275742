import connector, { IPropsFromState } from '../../Connector/Connector';

import Card from '../Card/Card';
import { IRFIDCardModel } from '../../../types/subscriber';
import React from 'react';
import { Transition } from 'react-transition-group';
import useStyles from './useStyles';

type ITransitionStyles = 'entering' | 'entered' | 'exiting' | 'exited';

const defaultStyle = {
  transition: `max-height .3s ease-in-out`,
  height: 'auto',
  maxHeight: '520px',
  overflow: 'hidden',
  paddingTop: 0,
};

const transitionStyles = {
  entering: {
    maxHeight: '520px',
    paddingTop: 0,
  },
  entered: {
    maxHeight: '520px',
    paddingTop: 16,
  },
  exiting: {
    maxHeight: '0',
    paddingTop: 16,
    transition: `all .3s ease-in-out`,
  },
  exited: { maxHeight: '0', paddingTop: 0, transition: `all .3s ease-in-out` },
};

type TProps = IPropsFromState & {
  expand: boolean;
};

export const CardList: React.FC<TProps> = ({ expand, chargingCardState }) => {
  const classes = useStyles();
  const chargingCards = chargingCardState;

  if (chargingCards && chargingCards.data?.length) {
    const firstCard = chargingCards.data[0];
    return (
      <>
        <div className={classes.outerCardWrapper}>
          <Card chargingCard={firstCard} defaultCard={true} />
        </div>
        <Transition in={expand} timeout={0}>
          {(state: ITransitionStyles) => (
            <div
              style={{
                ...defaultStyle,
                ...transitionStyles[state],
              }}
              className={classes.innerCardsWrapper}
            >
              {(chargingCards.data as IRFIDCardModel[]).map(
                (chargingCard, index) => {
                  if (index === 0) {
                    return null;
                  }

                  return (
                    <Card key={chargingCard.id} chargingCard={chargingCard} />
                  );
                }
              )}
            </div>
          )}
        </Transition>
      </>
    );
  }

  return null;
};

export default connector(CardList);
