import { IConfig } from '../config';

export const getStageConfig = (): IConfig => ({
  useDevtools: true,
  elliBaseUrl: 'https://api.elli.eco/sandbox',
  aadRedirectUrl: 'https://stage.contact-center.elli.eco/',
  aadClientAuthority:
    'https://login.microsoftonline.com/fbb6e5ca-b9eb-4bc5-b01e-50e49ed6ea42',
  aadClientId: '901b5182-e975-44af-b230-9ef4445d8a16',
  XApiKey: 'RrSOgycrL0nqk81NMalNxzaM7LOyUqRX',
  // TODO: #121436 Revoke and remove API keys from code
  googleMapApiKey: 'AIzaSyC0syM2bvnt0PImiVc7DXj-quYnngC80gQ',
  env: 'stage',
  launchDarklyClientId: '5f6af890732fa50ba506e973',
  auth0Audience: 'https://api.elli.eco/mgmt',
  auth0ClientId: 'DQnDWfshuwJlViIbLLkK8BjyGI9ZWLxm',
  auth0Domain: 'https://sandbox.login.elli.eco',
  auth0RedirectUri: 'https://stage.contact-center.elli.eco/',
  auth0Scopes: 'openid', //should be enough if not use 'openid profile'
});
