import { Theme } from '@mui/material/styles';

import createStyles from '@mui/styles/createStyles';
import makeStyles from '@mui/styles/makeStyles';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    activeSessionWrapper: {},
    sessionDetailsWrapper: {},
    connectorDetailsWrapper: {
      marginTop: 16,
    },
    actionButtonsWrapper: {
      margin: '0 -16px -16px',
      height: 48,
      display: 'flex',
      padding: 16,
      borderTop: '1px solid #f5f6f8',
    },
    sectionTitle: {
      fontSize: 14,
      fontWeight: 600,
      marginBottom: 8,
    },
    itemWrapper: {
      display: 'flex',
      flexFlow: 'column nowrap',
      alignItems: 'flex-start',
      justifyContent: 'space-between',
      marginBottom: 12,
    },
    itemTitle: {
      fontSize: 10,
      color: '#878c96',
      marginBottom: 4,
      lineHeight: 1.6,
    },
    itemTitleWithIcon: {
      fontSize: 10,
      color: '#878c96',
      marginBottom: 4,
      lineHeight: 1.6,
      display: 'flex',
      alignItems: 'center',
      '&:first-child': {
        marginRight: 2,
      },
    },
    itemValue: {
      fontSize: 12,
      display: 'flex',
      alignItems: 'center',
    },
    startDurationWrapper: {
      display: 'flex',
      flexFlow: 'row wrap',
      '& $itemWrapper': {
        '&:first-child': {
          marginRight: 16,
        },
      },
    },
    actionButton: {
      fontSize: 10,
      fontWeight: 600,
      fontStretch: 'normal',
      fontStyle: 'normal',
      lineHeight: 1.6,
      color: '#878c96',
      textTransform: 'uppercase',
      cursor: 'pointer',
      '&:first-child': {
        marginRight: 16,
      },
    },
    infoIcon: {
      marginLeft: 2,
      cursor: 'pointer',
      fontSize: 12,
    },
    itemLink: {
      fontSize: 12,
      lineHeight: 1.33,
      textDecoration: 'underline',
      cursor: 'pointer',
    },
    valueWithIcon: {
      fontSize: 12,
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'space-between',
    },
    circleProgress: {
      color: theme.colorAccent,
      marginLeft: 4,
    },
    loading: {
      opacity: 0.5,
    },
    tooltipOverflow: {
      display: 'block',
      whiteSpace: 'nowrap',
      overflow: 'hidden',
      textOverflow: 'ellipsis',
      width: 180,
    },
  })
);

export default useStyles;
