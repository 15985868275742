import { Theme } from '@mui/material';

import makeStyles from '@mui/styles/makeStyles';
import createStyles from '@mui/styles/createStyles';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    indicator: {
      borderRadius: 50,
      height: 8,
      width: 8,
      backgroundColor: '#cad4e0',
      margin: '0 auto',
    },
    statusGreen: {
      backgroundColor: theme.colorAccent,
    },
  })
);

export default useStyles;
