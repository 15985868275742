/* eslint-disable @typescript-eslint/naming-convention */
import { BreadcrumbModel } from './index';
import { actionCreator } from '../../type-utils';

export const ActionType = {
  AddBreadcrumb: 'breadcrumb/add',
  RemoveBreadcrumb: 'breadcrumb/remove',
  ClearBreadcrumbs: 'breadcrumb/clear',
  UpdateBreadcrumb: 'breadcrumb/update',
  SetCurrentBreadcrumb: 'breadcrumb/setCurrent',
};

export declare namespace ActionInterface {
  export interface AddBreadcrumb {
    type: string;
    data: Omit<BreadcrumbModel, 'id' | 'current'>;
  }

  export interface RemoveBreadcrumb {
    type: string;
    position: number;
  }

  export interface ClearBreadcrumbs {
    type: string;
  }

  export interface UpdateBreadcrumb {
    type: string;
    data: BreadcrumbModel;
  }

  export interface SetCurrentBreadcrumb {
    type: string;
    id: string;
  }
}

export const ActionCreator = {
  AddBreadcrumb: actionCreator<ActionInterface.AddBreadcrumb>(
    ActionType.AddBreadcrumb
  ),
  RemoveBreadcrumb: actionCreator<ActionInterface.RemoveBreadcrumb>(
    ActionType.RemoveBreadcrumb
  ),
  ClearBreadcrumbs: actionCreator<ActionInterface.ClearBreadcrumbs>(
    ActionType.ClearBreadcrumbs
  ),
  UpdateBreadcrumb: actionCreator<ActionInterface.UpdateBreadcrumb>(
    ActionType.UpdateBreadcrumb
  ),
  SetCurrentBreadcrumb: actionCreator<ActionInterface.SetCurrentBreadcrumb>(
    ActionType.SetCurrentBreadcrumb
  ),
};
