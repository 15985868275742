import React from 'react';
import CheckIcon from '@mui/icons-material/Check';
import Exclaim from '../../../../../../assets/image/exclaim.svg';
import useStyles from './useStyles';
import { useTheme } from '@mui/styles';
import { Skeleton } from '@mui/lab';

export interface IFlowColumnCircleProps {
  circleState: 'happy' | 'sad' | 'loading';
  happyText: string;
  sadText: string;
  linePosition?: 'top' | 'bottom';
  testId: string;
}

export const FlowColumnCircle: React.FC<IFlowColumnCircleProps> = ({
  circleState,
  happyText,
  sadText,
  linePosition,
  testId,
}) => {
  const theme = useTheme();
  const classes = useStyles(theme);

  const circleClass =
    circleState === 'happy'
      ? classes.circleHappy
      : circleState === 'sad'
      ? classes.circleSad
      : classes.circleNeutral;

  const circleText = () => {
    switch (circleState) {
      case 'happy':
        return happyText;
      case 'sad':
        return sadText;
      default:
        return null;
    }
  };

  const circleWithIcon = () => {
    switch (circleState) {
      case 'happy':
        return (
          <div
            className={`${classes.circle} ${circleClass}`}
            data-testid={testId + '-happy'}
          >
            <CheckIcon
              style={{
                fontSize: 20,
                color: theme.colorAccent,
              }}
            />
          </div>
        );
      case 'sad':
        return (
          <div
            className={`${classes.circle} ${circleClass}`}
            data-testid={testId + '-sad'}
          >
            <img
              style={{
                height: 16,
              }}
              src={Exclaim}
              alt='exclaim'
            />
          </div>
        );
      default:
        return (
          <Skeleton
            variant='circular'
            className={classes.loadingCircle}
            data-testid={testId + '-loading'}
          />
        );
    }
  };

  return (
    <div className={classes.circleComponentWrapper}>
      {linePosition === 'top' && (
        <div className={`${classes.verticalLine} ${circleClass}`} />
      )}
      {circleWithIcon()}
      <div className={classes.circleText}>{circleText()}</div>
      {linePosition === 'bottom' && (
        <div className={`${classes.verticalLine} ${circleClass}`} />
      )}
    </div>
  );
};
