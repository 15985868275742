import ApiService from './api';
import {
  IPlugAndChargeEvent,
  IPlugAndChargeInfo,
  IPlugAndChargeRawEvent,
  PlugAndChargeEventStatus,
  PlugAndChargeEventType,
  PlugAndChargeRawEventStatus,
  PlugAndChargeRawEventType,
} from '../types/plugAndCharge';
import { assertNever } from '../utils/assertNever';

const getPncCapabilityByVIN = async (pcid: string): Promise<boolean> => {
  const apiService = new ApiService(
    `/plugandcharge/v1/provisioningcertificates/${pcid}`
  );
  const response = await apiService.query();
  return response.status === 200;
};

const enablePlugAndCharge = async (
  subscriberId: string,
  pcid?: string
): Promise<IPlugAndChargeInfo | undefined> => {
  if (!pcid) {
    throw new Error('Cannot enable plug and charge without valid PCID');
  }
  const apiService = new ApiService(
    `/mgmt/customer/v1/subscribers/${subscriberId}/enableplugandcharge`,
    {
      method: 'POST',
      data: { pcid: pcid },
    }
  );
  const response = await apiService.query();
  return response.status === 200 ? response.data : undefined;
};

const disablePlugAndCharge = async (subscriberId: string): Promise<void> => {
  const apiService = new ApiService(
    `/mgmt/customer/v1/subscribers/${subscriberId}/disableplugandcharge`,
    {
      method: 'POST',
    }
  );
  await apiService.query();
  return Promise.resolve(undefined);
};

function mapEvents(rawEvents: IPlugAndChargeRawEvent[]): IPlugAndChargeEvent[] {
  function mapType(rawType: PlugAndChargeRawEventType): PlugAndChargeEventType {
    switch (rawType) {
      case 'create':
        return 'ENABLE';
      case 'revoke':
        return 'DISABLE';
      case 'mo.prov.cert.updated':
        return 'FACTORY_RESET';
      case 'mo.prov.cert.deleted':
        return 'FACTORY_RESET';
      case 'mo.prov.cert.updated.factory.reset':
        return 'FACTORY_RESET';
      default:
        return assertNever(rawType);
    }
  }

  function mapStatus(
    rawStatus: PlugAndChargeRawEventStatus | undefined
  ): PlugAndChargeEventStatus | undefined {
    switch (rawStatus) {
      case undefined:
        return undefined;
      case 'QUEUED':
        return 'PENDING';
      case 'PENDING':
        return 'PENDING';
      case 'FAILURE':
        return 'FAILURE';
      case 'TECHNICAL_ERROR':
        return 'TRANSIENT_ERROR_RETRYING';
      case 'SUCCESS':
        return 'SUCCESS';
      case 'NOT_FOUND':
        return 'NOT_FOUND_EXTERNALLY';
      default:
        return assertNever(rawStatus);
    }
  }

  return rawEvents.map((e) => {
    return {
      ...e,
      type: mapType(e.type),
      state: mapStatus(e.state),
    };
  });
}

const getEventHistory = async (
  emaid: string
): Promise<IPlugAndChargeEvent[]> => {
  const apiService = new ApiService(
    `/mgmt/plugandcharge/v1/events/emaids/${emaid}`
  );
  const response = await apiService.query();
  return mapEvents(response.data.events);
};

export const PlugAndChargeService = {
  getPncCapabilityByVIN,
  disablePlugAndCharge,
  enablePlugAndCharge,
  getEventHistory,
};
