import React from 'react';
import { Button } from '@mui/material';
import connector, { IPropsFromState } from '../../Connector/Connector';

import useStyles from './useStyles';
import InformationItems from '../../shared/InformationItems';
import { t } from 'i18next';
import { terms } from '../../../utils/translation/i18n_terms';
import { ChargingSessionStatusComponent } from '../../ChargingSessionStatus/ChargingSessionStatusComponent';
import { formatIsoDates } from '../../../utils/dates';
import {
  addBreadcrumbHandler,
  IBreadcrumbHandlerProps,
} from '../../../utils/addBreadcrumbHandler';
import { usePublicChargingSessionsQuery } from '../../../state/queries/usePublicChargingSessions';
import { ChargingSessionDuration } from '../../ChargingSessionDuration/ChargingSessionDuration';
import { Typography } from '../../shared/Typography/Typography';

export type ActivePublicChargingSessionProps = IPropsFromState & {
  userId: string;
};

export const ActivePublicChargingSession: React.FC<ActivePublicChargingSessionProps> = ({
  addBreadcrumb,
  userId,
}) => {
  const classes = useStyles();

  const { data: sessions, isSuccess } = usePublicChargingSessionsQuery(userId);

  if (!isSuccess) {
    return <>Failed to fetch user sessions!</>;
  }

  // If there's multiple sessions, we only show the latest one
  const latestSession = sessions.reduce((maybeLatestSession, nextSession) =>
    new Date(maybeLatestSession.start_datetime) >
    new Date(nextSession.start_datetime)
      ? maybeLatestSession
      : nextSession
  );

  if (!latestSession) {
    // This should never happen as this component is only rendered if the user has a session, but for typesafety we have to check
    return <>User is not charging publicly right now.</>;
  }

  const breadcrumbHandler = (props: IBreadcrumbHandlerProps): void =>
    addBreadcrumbHandler({
      ...props,
      addBreadcrumb,
      level: 1,
    });

  const getItems = () => [
    {
      id: 'start-time',
      headline: t(terms.action_cards.ca_sessions.session.start_time),
      content: (
        <Typography variant='body2' data-testid='data-start-time'>
          {latestSession.start_datetime
            ? formatIsoDates(latestSession.start_datetime)
            : 'undefined'}
        </Typography>
      ),
    },
    {
      id: 'last-updated-by-cpo',
      headline: t(terms.action_cards.ca_sessions.session.last_updated),
      content: (
        <Typography variant='body2' data-testid='data-last-updated-by-cpo'>
          {formatIsoDates(latestSession.last_updated_cpo)}
        </Typography>
      ),
    },
    {
      id: 'duration',
      headline: t(terms.action_cards.ca_sessions.session.duration),
      content: (
        <ChargingSessionDuration
          session_status={latestSession.status}
          start_datetime={latestSession.start_datetime}
          end_datetime={latestSession.end_datetime}
          last_updated_cpo={latestSession.last_updated_cpo}
          data-testid='data-session-duration'
        ></ChargingSessionDuration>
      ),
    },
    {
      id: 'evse-id',
      headline: t(terms.action_cards.ca_sessions.session.evse_id),
      content: (
        <Typography variant='body2' data-testid='data-evse-id'>
          {latestSession.location_info.evse_id}
        </Typography>
      ),
    },
    {
      id: 'status',
      headline: t(terms.action_cards.ca_sessions.session.status),
      content: (
        <ChargingSessionStatusComponent
          status={latestSession.status}
          statusTypeForTestId='data-public-charging-session'
        ></ChargingSessionStatusComponent>
      ),
      hasMarginRight: false,
    },
  ];

  return (
    <div
      className={classes.activeSessionWrapper}
      data-testid='active-public-charging-session-action-component'
    >
      <InformationItems items={getItems()}></InformationItems>
      <div className={`${classes.cardOverlay}`}>
        <Button
          data-testid='details-button'
          onClick={() =>
            breadcrumbHandler({
              component: 'ChargingSessionsCollection',
              friendlyText: 'Charging Sessions',
              large: true,
            })
          }
          classes={{ root: classes.detailButton }}
        >
          {t(terms.action_cards.ca_sessions.details)}
        </Button>
      </div>
    </div>
  );
};

export default connector(ActivePublicChargingSession);
