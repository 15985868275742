import React from 'react';
import { Controller, FieldValues } from 'react-hook-form';
import { TextField } from '@mui/material';
import { DesktopDatePicker } from '@mui/x-date-pickers';
import AdapterDateFns from '@mui/lab/AdapterDateFns';
import LocalizationProvider from '@mui/lab/LocalizationProvider';
import { TDatePickerProps } from './DatePicker.types';

const DatePicker = <FormValues extends FieldValues>({
  name,
  control,
  label,
  className,
  isRequired,
  ...rest
}: TDatePickerProps<FormValues>): React.ReactElement => {
  const inputLabel = isRequired ? [label, '*'].join(' ') : label;

  return (
    <Controller<FormValues>
      name={name}
      control={control}
      render={({ field }) => (
        <LocalizationProvider locale={'en'} dateAdapter={AdapterDateFns}>
          <DesktopDatePicker
            {...rest}
            {...field}
            views={['day']}
            label={inputLabel}
            disableCloseOnSelect={false}
            mask={''}
            inputFormat={''}
            renderInput={(params) => (
              <TextField
                {...params}
                fullWidth
                className={className}
                error={!!rest.error}
                helperText={rest.error?.message}
              />
            )}
          />
        </LocalizationProvider>
      )}
    />
  );
};

export default DatePicker;
