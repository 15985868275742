import React from 'react';
import {
  BasicStatus,
  IMappedStatus,
} from '../../../shared/StatusIndicator/BasicStatus';
import { useTheme } from '@mui/material/styles';

export const CouponStatus: React.FC<{ status: string }> = ({ status }) => {
  const theme = useTheme();

  function valueMapper(value: string): IMappedStatus {
    const values: { [status: string]: IMappedStatus } = {
      active: { color: theme.colorAccent, label: 'Active' },
      disabled: { color: theme.surfaceTertiary, label: 'Disabled' },
      expired: { color: theme.colorAlertSecondary, label: 'Expired' },
    };

    return Object.keys(values).includes(value)
      ? values[value]
      : { color: theme.surfaceTertiary, label: '[Unknown status]' };
  }

  return <BasicStatus status={status} valueMapper={valueMapper} />;
};
