/* eslint-disable @typescript-eslint/naming-convention */
import { ErrorObject } from 'serialize-error';
import { IInvoiceListItem } from '../../../types/invoice';

export type InvoiceStateModel = {
  loading: boolean;
  data: IInvoiceListItem[] | null;
  error: ErrorObject | null;
  request: {
    subscriberId?: string;
    invoiceId?: string;
  } | null;
};

export const initialState: InvoiceStateModel = {
  loading: false,
  data: [],
  error: null,
  request: null,
};

const state: InvoiceStateModel = {
  ...initialState,
};

export type State = typeof state;
export default state;
