import React from 'react';
import ItemSelect from './ItemSelect/ItemSelect';
import Navigation from './Navigation/Navigation';
import useStyles from './useStyles';

interface IOwnProps {
  itemsPerPage: number;
  setItemsPerPage: React.Dispatch<React.SetStateAction<number>>;
  currentPage: number;
  setCurrentPage: React.Dispatch<React.SetStateAction<number>>;
  pagesLength: number;
  itemSelectText: string;
  showSelect?: boolean;
  small?: boolean;
}

export const Pagination: React.FC<IOwnProps> = ({
  itemsPerPage,
  setItemsPerPage,
  currentPage,
  setCurrentPage,
  pagesLength,
  itemSelectText,
  showSelect = true,
  small = false,
}) => {
  const classes = useStyles();

  if (pagesLength <= 1 && itemsPerPage === 10) {
    return null;
  }

  return (
    <div
      className={classes.paginationContainer}
      data-testid='pagination-container'
    >
      {showSelect ? (
        <ItemSelect
          itemsPerPage={itemsPerPage}
          setItemsPerPage={setItemsPerPage}
          itemSelectText={itemSelectText}
          setCurrentPage={setCurrentPage}
        />
      ) : (
        <span></span>
      )}
      <Navigation
        small={small}
        currentPage={currentPage}
        setCurrentPage={setCurrentPage}
        pagesLength={pagesLength}
      />
    </div>
  );
};

export default Pagination;
