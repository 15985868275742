export const CC_MANAGER = 'cc.manager';
export const CC_SUPERVISOR = 'cc.supervisor';
export const CC_OPS_INTERNAL = [
  'ops.viewer-internal',
  'ops.secondlevel-internal',
  'ops.thirdlevel-internal',
];
export const GDPR_MANAGER = 'cc.gdprmanager';

const hasRole = (role: string, agentRoles: string[]): boolean => {
  return agentRoles.includes(role);
};

export const isCCSupervisor = (ccRoles: string[]): boolean =>
  hasRole(CC_SUPERVISOR, ccRoles);

export const isCCManager = (ccRoles: string[]): boolean =>
  hasRole(CC_MANAGER, ccRoles);

export const isOpsInternal = (ccRoles: string[]): boolean =>
  CC_OPS_INTERNAL.some((role) => ccRoles.includes(role));

export const isGDPRManager = (ccRoles: string[]): boolean =>
  hasRole(GDPR_MANAGER, ccRoles);
