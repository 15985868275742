import React from 'react';
import useStyles from './useStyles';
import { Typography } from '../Typography/Typography';

interface IProps {
  title: string;
}

export const CardSubheadline: React.FC<IProps> = ({ title }) => {
  const classes = useStyles();
  return (
    <div className={classes.cardDetail}>
      <Typography variant='h4'>{title}</Typography>
    </div>
  );
};
