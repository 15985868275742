import React from 'react';
import connector, { IPropsFromState } from '../../Connector/Connector';
import { AvailableBrands } from '../VinEligibility';
import useStyles from './useStyles';

type IOwnProps = IPropsFromState & {
  selectedBrand: AvailableBrands;
  setSelectedBrand: (x: AvailableBrands) => void;
};

const BrandSelection: React.FC<IOwnProps> = ({
  selectedBrand,
  setSelectedBrand,
}) => {
  const classes = useStyles();

  return (
    <div className={classes.brandBox}>
      <div
        className={`${classes.brandButton} ${selectedBrand ===
          AvailableBrands.SEAT && classes.buttonSelected}`}
        onClick={() => setSelectedBrand(AvailableBrands.SEAT)}
      >
        SEAT
      </div>
      <div
        className={`${classes.brandButton} ${selectedBrand ===
          AvailableBrands.SKODA && classes.buttonSelected}`}
        onClick={() => setSelectedBrand(AvailableBrands.SKODA)}
      >
        SKODA
      </div>
    </div>
  );
};

export default connector(BrandSelection);
