import { AppDispatch } from '../../../../store';
import { ActionCreator } from '../../../../store/state/actions';
import { ActionInterface } from '../../../../store/state/cardorders/actions';
import { Dispatcher } from '../index';

interface IDispatchers {
  sortCardOrders: Dispatcher<ActionInterface.SortCardOrders>;
  getRFIDCardOrderRequest: Dispatcher<ActionInterface.GetCardOrderRequest>;
}

export const createDispatchers = (dispatch: AppDispatch): IDispatchers => ({
  sortCardOrders: (payload) =>
    dispatch(ActionCreator.cardorders.SortCardOrders(payload)),

  getRFIDCardOrderRequest: (payload) =>
    dispatch(ActionCreator.cardorders.GetCardOrderRequest(payload)),
});
