import { ChargingSessionsStateModel, State } from '..';
import update from 'immutability-helper';

export default (state: State): State => {
  const updatedState: ChargingSessionsStateModel = update(state, {
    $unset: [
      'data',
      'error',
      'loading',
      'organization',
      'private',
      'public',
      'request',
    ],
  });

  return {
    ...updatedState,
  };
};
