import ApiService from './api';

export interface ICCUserRolesResponse {
  permissions: string[];
  roles: string[];
}

export const getCCUserRoles = async (): Promise<ICCUserRolesResponse> => {
  const apiService = new ApiService('/identity/v1/authorization-info');
  const response = await apiService.query();
  return response.data;
};

export const CCRolesService = {
  getCCUserRoles,
};
