import { ActionInterface } from '../actions';
import { State } from '..';
import update from 'immutability-helper';

export default (
  state: State,
  action: ActionInterface.GetChargingSessionsSuccess
): State => {
  const { data } = action;
  const currentSubscription = state;
  const updatedSubscription = update(currentSubscription, {
    data: {
      $set: data,
    },
    loading: {
      $set: false,
    },
    private: {
      $set: action.private,
    },
    public: {
      $set: action.public,
    },
    organization: {
      $set: action.organization,
    },
  });

  return {
    ...state,
    ...updatedSubscription,
  };
};
