import makeStyles from '@mui/styles/makeStyles';
import createStyles from '@mui/styles/createStyles';

const useStyles = makeStyles(() =>
  createStyles({
    detailWrapper: {
      marginBottom: '16px',
      padding: '8px 16px',
    },
    divider: {
      width: '100%',
      backgroundColor: '#f5f6f8 !important',
    },
    infoIcon: {
      marginLeft: '2px !important',
      cursor: 'pointer',
      fontSize: '12px !important',
    },
  })
);

export default useStyles;
