import './App.css';

import { CssBaseline } from '@mui/material';
import React, { ReactElement } from 'react';
import { Auth0Provider } from '@auth0/auth0-react';

import * as CustomTheme from '../theme';
import CRMPanel from './CRMPanel/CRMPanel';
import { DismissableToast } from './shared/Toast/DismissableToast';
import { config } from '../config';
import { ErrorBoundary } from './error-boundary/ErrorBoundary';

function App(): ReactElement {
  return (
    <Auth0Provider
      domain={config.auth0Domain}
      clientId={config.auth0ClientId}
      redirectUri={config.auth0RedirectUri}
      scope={config.auth0Scopes}
      audience={config.auth0Audience}
    >
      <CustomTheme.Provider>
        <CssBaseline />
        <ErrorBoundary>
          <CRMPanel />
        </ErrorBoundary>
        <DismissableToast />
      </CustomTheme.Provider>
    </Auth0Provider>
  );
}

export default App;
