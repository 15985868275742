import React, { useEffect, useState } from 'react';
import SimpleTable, {
  TTableHead,
  TTableRows,
} from '../../shared/Table/SimpleTable';
import connector, { IPropsFromState } from '../../Connector/Connector';

import { ICardOrderModel } from '../../../types/subscriber';
import formatAddress from '../../../utils/formatAddress';
import { formatIsoDates } from '../../../utils/dates';

type TOwnProps = IPropsFromState & {
  cardOrder: ICardOrderModel | null;
};

export const OrderDetails: React.FC<TOwnProps> = ({ cardOrder }) => {
  const [tableHeadings, setTableHeadings] = useState<TTableHead[]>([]);
  const [tableRows, setTableRows] = useState<TTableRows[]>([]);

  useEffect(() => {
    const columns = [
      {
        name: 'Ordering Date',
      },
      {
        name: 'Shipping Address',
      },
    ];

    setTableHeadings(columns);

    const rows = [
      {
        orderDate: cardOrder ? formatIsoDates(cardOrder.created_at) : '-',
        address: cardOrder ? formatAddress(cardOrder.shipping_address) : '-',
      },
    ];

    setTableRows(rows);
  }, []);

  return (
    <SimpleTable
      data-testid='simple-table'
      tableHeadings={tableHeadings}
      tableRows={tableRows}
    />
  );
};

export default connector(OrderDetails);
