/* eslint-disable @typescript-eslint/naming-convention */
import { ErrorObject } from 'serialize-error';
import { ISubscriberModel } from '../../../types/subscriber';

export interface GetSubscriberRequest {
  userId: string;
}

export interface SubscriberStateModel {
  loading: boolean;
  data: ISubscriberModel | null;
  searchData: ISubscriberModel | null;
  error: ErrorObject | null;
  request: { userId: string; action: string } | null;
}

export const initialState: SubscriberStateModel = {
  loading: false,
  data: null,
  searchData: null,
  error: null,
  request: null,
};

const state: SubscriberStateModel = {
  ...initialState,
};

export type State = typeof state;
export default state;
