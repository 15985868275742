import { Dialog } from '@mui/material';
import React, { useState } from 'react';
import { useTheme } from '@mui/material/styles';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import { useTypedTranslation } from '../../../../custom-hooks/useTypedTranslation';
import { DatePicker } from '../../../shared/StaticDatePicker/DatePicker';
import useStyles from './useStyles';
import { Button } from '@elli-eco/component-library';
import { Typography } from '../../../shared/Typography/Typography';

export type IOwnProps = {
  open: boolean;
  onClose: () => void;
  isLoading: boolean;
  currentEndDate: Date;
  onDateChange: (date: Date | null) => void;
  doSetTerminationDate: () => void;
};

export const TerminateAtFutureDateDialog: React.FC<IOwnProps> = ({
  open,
  onClose,
  isLoading,
  currentEndDate,
  onDateChange,
  doSetTerminationDate,
  //tenant,
}) => {
  const theme = useTheme();
  const classes = useStyles(theme);
  const { t, terms } = useTypedTranslation();

  const [dateSelected, setDateSelected] = useState<boolean>(false);

  const onAnyClose = () => {
    setDateSelected(false);
    onClose();
  };

  return (
    <Dialog
      open={open}
      onClose={onAnyClose}
      PaperProps={{
        classes: {
          root: classes.dialog,
        },
      }}
    >
      <IconButton
        aria-label='close'
        className={classes.closeButton}
        onClick={onAnyClose}
        size='large'
      >
        <CloseIcon />
      </IconButton>
      <Typography paragraph variant='h1' color='inherit' align='center'>
        {t(terms.subscription_termination.terminate_at_future_date)}
      </Typography>
      <br />
      <Typography paragraph variant='subtitle1' align='center'>
        {t(terms.subscription_termination.select_future_date)}
      </Typography>
      <br />
      <div>
        <DatePicker
          label='Termination Date'
          onChange={(date: Date | null) => {
            setDateSelected(!!date);
            if (date) {
              // Always return the date with time set to the last second of the day in UTC
              // This is deterministic and more predicatable than just using the current time of day
              date = new Date(
                date.getUTCFullYear(),
                date.getUTCMonth(),
                date.getUTCDate(),
                23,
                59,
                59
              );
            }
            onDateChange(date);
          }}
          disablePast={true}
          minDate={new Date()}
          maxDate={currentEndDate}
        />
      </div>
      <div className={classes.terminationConfirmationButton}>
        <Button
          variant='outlined'
          className={classes.terminateAtFutureDateButton}
          onClick={doSetTerminationDate}
          disabled={isLoading || !dateSelected}
          isLoading={isLoading}
        >
          {t(terms.subscription_termination.terminate_at_future_date)}
        </Button>
      </div>
    </Dialog>
  );
};
