import { Theme } from '@mui/material';

import makeStyles from '@mui/styles/makeStyles';
import createStyles from '@mui/styles/createStyles';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    cardHeaderWrapper: {
      position: 'relative',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'space-between',
      marginBottom: '16px',
    },
    titleWrapper: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'flex-start',
    },
    titleIcon: {
      color: theme.colorPrimary,
      fontSize: '24px',
      marginRight: '16px',
    },
    title: {
      fontSize: '20px !important',
    },
    actionButton: {
      color: 'rgba(0, 0, 0, 0.54)',
      padding: '10px !important',
      right: '-10px',
    },
  })
);

export default useStyles;
