import React from 'react';
import { Controller, FieldValues } from 'react-hook-form';
import MenuItem from '@mui/material/MenuItem';
import InputLabel from '@mui/material/InputLabel';
import FormControl from '@mui/material/FormControl';
import FormHelperText from '@mui/material/FormHelperText';
import MuiSelect from '@mui/material/Select';
import { TSelectProps } from './Select.types';

const Select = <FormValues extends FieldValues>({
  name,
  options,
  control,
  error,
  label,
  labelId,
  disabled,
  ref,
  isRequired,
  ...rest
}: TSelectProps<FormValues>): React.ReactElement => {
  const inputLabel = isRequired ? [label, '*'].join(' ') : label;

  return (
    <Controller
      name={name}
      control={control}
      render={({ field }) => (
        <FormControl
          error={!!error}
          variant='outlined'
          disabled={disabled}
          sx={{ width: '100%' }}
        >
          {label && (
            <InputLabel required={rest.required} id={`${name}-select-label`}>
              {inputLabel}
            </InputLabel>
          )}
          <MuiSelect
            labelId={labelId || `${name}-select-label`}
            {...rest}
            {...field}
            fullWidth
            inputRef={field.ref || ref}
            data-testid={`${name}-select-field`}
            value={rest.value ?? field.value}
          >
            {options.map(({ key, value: option, content, isDisabled }) => (
              <MenuItem key={key} value={option} disabled={isDisabled}>
                {content}
              </MenuItem>
            ))}
          </MuiSelect>
          {error?.message && <FormHelperText>{error.message}</FormHelperText>}
        </FormControl>
      )}
    />
  );
};

export default Select;
