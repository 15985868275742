import makeStyles from '@mui/styles/makeStyles';
import createStyles from '@mui/styles/createStyles';

const useStyles = makeStyles((theme) =>
  createStyles({
    columnContainer: {
      background: theme.surfaceSecondary,
      borderRadius: '4px',
      width: '100%',
      paddingTop: 20,
      paddingBottom: 20,
    },
    title: {
      fontWeight: 600,
      fontSize: 18,
    },
    titleIcon: {
      width: 24,
      height: 24,
      stroke: theme.textPrimary,
      strokeWidth: 1.5,
    },
  })
);

export default useStyles;
