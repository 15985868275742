import { ActionInterface } from '../actions';
import { State } from '..';
import update from 'immutability-helper';

export default (
  state: State,
  action: ActionInterface.GetPaymentDetailsSuccess
): State => {
  const { data } = action;
  const currentPaymentDetailsState = state;
  const updatedPaymentDetailsState = update(currentPaymentDetailsState, {
    data: {
      $set: data,
    },
    loading: {
      $set: false,
    },
  });

  return {
    ...state,
    ...updatedPaymentDetailsState,
  };
};
