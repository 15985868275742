import React from 'react';
import { Skeleton } from '@mui/material';
import useStyles from './useStyles';
import { Typography } from '../Typography/Typography';

interface IProps {
  title: string;
  content: string;
  id?: string;
  isLoading?: boolean;
}
export const BasicInformationItem: React.FC<IProps> = ({
  title,
  content,
  id = title.toLowerCase().replace(' ', '-'),
  isLoading = false,
}) => {
  const classes = useStyles();
  return (
    <div className={classes.cardDetail}>
      <Typography variant='h6'>{title}</Typography>
      <div className={classes.cardDetailData}>
        {isLoading ? (
          <Skeleton
            width={'100%'}
            height={'100%'}
            data-testid={id + '-is-loading'}
          />
        ) : (
          <span className={classes.itemValue} data-testid={id}>
            <Typography variant='body2'>{content}</Typography>
          </span>
        )}
      </div>
    </div>
  );
};
