import update from 'immutability-helper';
import { State } from '../';

export default (state: State): State => {
  const currentUser = state;

  const updatedState = update(currentUser, {
    searchData: {
      $set: null,
    },
  });

  return {
    ...state,
    ...updatedState,
  };
};
