import React from 'react';

import ActionDropdownContainer, {
  IActionItem,
} from '../../shared/ActionDropdownContainer';
import useStyles from './useStyles';
import { Typography } from '../../shared/Typography/Typography';

export interface IOwnProps {
  icon?: JSX.Element;
  title: string;
  actionItems?: IActionItem[];
}

export const Header: React.FC<IOwnProps> = ({ icon, title, actionItems }) => {
  const classes = useStyles();

  const headerLogo = icon && <div className={classes.icon}>{icon}</div>;
  const headerTitle = (
    <Typography className={classes.title} variant='h2'>
      {title}
    </Typography>
  );
  const headerActionItemContainer = actionItems && (
    <ActionDropdownContainer actionItems={actionItems} />
  );

  return (
    <div className={classes.wrapper}>
      {headerLogo}
      {headerTitle}
      {headerActionItemContainer}
    </div>
  );
};
