import React from 'react';
import omit from 'lodash/omit';
import { SubmitHandler } from 'react-hook-form';
import Grid from '@mui/material/Grid';
import { useTypedTranslation } from '../../../../../../custom-hooks/useTypedTranslation';
import useStyles from './useStyles';
import { Form } from '../../../../../shared/form-elements';
import CardElement from '../../../../../shared/CardElement/CardElement';
import {
  EInvoiceRefundType,
  EInvoiceReimbursementAmount,
  IInvoiceRefund,
  IInvoiceRefundLineItem,
  IInvoice,
  IInvoiceLineItem,
  ItemType,
} from '../../../../../../types/invoice';
import { chargeRecordRefundFormSchema } from './ChargeRecordRefundForm.schema';
import AddChargeRecordRefundEntry from './add-entry/AddChargeRecordRefundEntry';
import ChargeRecordRefundEntries from './entries/ChargeRecordRefundEntries';
import ChargeRecordRefundDistribution from './distribution/ChargeRecordRefundDistribution';
import MultilineButton from '../../../../../shared/Buttons/MultilineButton';

const DEFAULT_VALUES: Partial<IInvoiceRefundLineItem> = {
  type: EInvoiceRefundType.CHARGING_SESSION,
  invoice_line_item_id: '',
  process_id: '',
  reimbursement_amount: EInvoiceReimbursementAmount.PARTIAL,
  gross_amount_to_refund: 0,
};

export type TChargeRecordRefundFormProps = {
  invoice: IInvoice;
  invoiceRefund: IInvoiceRefund;
  selectedChargeRecord?: IInvoiceLineItem;
  addRefundEntry: (entry: IInvoiceRefundLineItem) => void;
};
export const ChargeRecordRefundForm: React.FC<TChargeRecordRefundFormProps> = ({
  invoice,
  selectedChargeRecord,
  addRefundEntry,
  invoiceRefund,
}) => {
  const { t, terms } = useTypedTranslation();
  const classes = useStyles();

  const onSubmit: SubmitHandler<IInvoiceRefundLineItem> = (
    data: IInvoiceRefundLineItem
  ) => {
    addRefundEntry(omit(data, 'reimbursement_amount'));
  };

  const invoiceChargeRecords = invoice?.line_items.filter(
    (lineItem) => lineItem.item_type === ItemType.ChargeRecord
  );

  return (
    <Form<IInvoiceRefundLineItem>
      onSubmit={onSubmit}
      defaultValues={DEFAULT_VALUES}
      resetOnSubmit={DEFAULT_VALUES}
      className={classes.refundSectionContainer}
      schema={chargeRecordRefundFormSchema}
    >
      <Grid container spacing={5}>
        <Grid item xs={6} paddingRight='16px'>
          <CardElement
            title={t(terms.refunds.form.headline)}
            isCollapsable={false}
          >
            <div className={classes.container}>
              <AddChargeRecordRefundEntry chargeRecord={selectedChargeRecord} />
              <MultilineButton
                mainText={t(terms.refunds.form.add_item)}
                className={classes.button}
              />
            </div>
          </CardElement>
        </Grid>
        <Grid
          item
          xs={6}
          container
          spacing={8}
          alignItems='center'
          flexDirection='column'
        >
          <ChargeRecordRefundEntries
            currency={invoice.currency}
            refundEntries={invoiceRefund.line_items}
          />
          <ChargeRecordRefundDistribution
            currency={invoice.currency}
            invoiceChargeRecords={invoiceChargeRecords}
            refundEntries={invoiceRefund.line_items}
          />
        </Grid>
      </Grid>
    </Form>
  );
};

export default ChargeRecordRefundForm;
