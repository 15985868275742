import { AppDispatch } from '../../../../store';
import { ActionCreator } from '../../../../store/state/actions';
import { ActionInterface } from '../../../../store/state/invoices/actions';
import { Dispatcher } from '../index';

interface IDispatchers {
  getInvoices: Dispatcher<ActionInterface.GetInvoicesRequest>;
  sortInvoices: Dispatcher<ActionInterface.SortInvoices>;
}

export const createDispatchers = (dispatch: AppDispatch): IDispatchers => ({
  getInvoices: (payload) =>
    dispatch(ActionCreator.invoices.GetInvoicesRequest(payload)),

  sortInvoices: (payload) =>
    dispatch(ActionCreator.invoices.SortInvoices(payload)),
});
