import makeStyles from '@mui/styles/makeStyles';
import createStyles from '@mui/styles/createStyles';

const useStyles = makeStyles(() =>
  createStyles({
    chargingSessionsHeader: {
      fontSize: 16,
      fontWeight: 600,
      lineHeight: 1.38,
      marginBottom: 11,
    },
    showMoreLink: {
      marginLeft: 12,
      top: -42,
      position: 'relative',
      fontSize: 14,
      maxWidth: 200,
    },
    showMoreButton: {
      fontWeight: 'normal',
    },
    stationRefreshing: {
      opacity: 0.5,
    },
    profileElement: {
      marginBottom: 30,
    },
  })
);

export default useStyles;
