import { Middleware, applyMiddleware, compose, createStore } from 'redux';

import { State } from './state';
import { combineReducers } from 'redux';
import { composeWithDevTools } from 'redux-devtools-extension';
import { config } from '../config';
import createSagaMiddleware from 'redux-saga';
import rootReducer from './state/reducers';
import rootSaga from './state/rootSaga';

const reducers = combineReducers<State>({
  app: rootReducer,
});

const sagaMiddleware = createSagaMiddleware();
const middlewares: Array<Middleware> = [];

middlewares.push(sagaMiddleware);

const store = createStore(
  reducers,
  compose(
    config.useDevtools
      ? composeWithDevTools(applyMiddleware(...middlewares))
      : applyMiddleware(...middlewares)
  )
);

sagaMiddleware.run(rootSaga);
export type AppDispatch = typeof store.dispatch;
export default store;
