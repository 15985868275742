import React from 'react';
import {
  AssetTable,
  IColumn,
  IRow,
  ISortState,
} from '../../shared/AssetTable/AssetTable/AssetTable';
import {
  getCardNumber,
  getSharedChargingSessionsData,
  getStationDisplayName,
} from './helpers';

import ChargingSessionsFilter from './ChargingSessionsFilter/ChargingSessionsFilter';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import { IBreadcrumbHandlerProps } from '../../../utils/addBreadcrumbHandler';
import { IChargingRecordViewModel } from '../../../types/chargingRecords';
import { IFilterOption } from '../../shared/Filters/TableFilter';
import { IHomeStation } from '../../../types/user';
import { IRFIDCardModel } from '../../../types/subscriber';
import { IconButton } from '@mui/material';
import { chargingRecordFieldNames } from './TableConfig';
import { getTypeIcon } from '../../../utils/getTypeIcon';
import { formatIsoDates } from '../../../utils/dates';
import { mapFieldsForTable } from '../../../utils/mapFieldsForTable';
import useSharedStyles from '../../shared/useSharedStyles';
import { StationDisplayName } from './StationDisplayName';

interface IOwnProps {
  chargingSessions: IChargingRecordViewModel[];
  chargingCards: IRFIDCardModel[];
  sortState: ISortState;
  defaultFilters?: string[];
  selectedFilters: IFilterOption[];
  privateStationsIds: string[];
  homeStations: IHomeStation[];
  sortRequestHandler: (columnKey: string) => void;
  addBreadcrumbHandler: (props: IBreadcrumbHandlerProps) => void;
  setSelectedFilters: React.Dispatch<React.SetStateAction<IFilterOption[]>>;
}

export const ChargingRecords: React.FC<IOwnProps> = ({
  chargingSessions,
  chargingCards,
  sortState,
  defaultFilters,
  selectedFilters,
  privateStationsIds,
  homeStations,
  sortRequestHandler,
  addBreadcrumbHandler,
  setSelectedFilters,
}) => {
  const sharedClasses = useSharedStyles();
  const getColumns = (): IColumn[] => {
    const sortableColumns = [
      'start_date_time',
      'total_time',
      'total_energy',
      'total_price',
      'location_evse_id',
      'card_used',
    ];

    return mapFieldsForTable(chargingRecordFieldNames, sortableColumns);
  };

  const getPaymentType = (price?: number, couponReduction?: number) => {
    if (!price) {
      return '-';
    }
    if (!couponReduction) {
      return 'Default Payment Option';
    }
    if (couponReduction === price) {
      return 'Voucher';
    }
    return 'Default Payment Option and Voucher';
  };

  const getRows = (): IRow[] => {
    if (!chargingSessions || !chargingSessions.length) {
      return [];
    }

    return Object.values(chargingSessions).map((cs) => {
      return {
        data: {
          public: {
            content: getTypeIcon({
              type: cs.type,
              color: '#878C96',
              fontSize: 14,
              marginBottom: 2,
            }),
          },
          ...getSharedChargingSessionsData(cs, {
            textOverflow: sharedClasses.textOverflow,
          }),
          start_date_time: {
            content: (
              <div data-testid='start-date-time'>
                {formatIsoDates(cs.start_date_time)}
              </div>
            ),
          },
          location_evse_id: {
            content: (
              <StationDisplayName
                evse_id={cs.location_evse_id}
                location_id={cs.station_id}
                station_name={getStationDisplayName(cs)}
                addBreadcrumbHandler={addBreadcrumbHandler}
              ></StationDisplayName>
            ),
          },
          payment_type: {
            content: getPaymentType(cs.price, cs.coupon_reduction),
          },
          card_used: {
            content: getCardNumber(
              cs.card_id,
              cs.rfid_card_serial_number,
              addBreadcrumbHandler,
              chargingCards,
              {
                textOverflow: sharedClasses.textOverflow,
              }
            ),
          },
          actions: {
            content: (
              <IconButton
                data-testid='charging-session-link'
                onClick={() =>
                  addBreadcrumbHandler({
                    component: 'ChargeRecordDetails',
                    friendlyText: 'Charging Record',
                    id: cs.id,
                  })
                }
                color='primary'
                size='large'
              >
                <ChevronRightIcon fontSize='large' />
              </IconButton>
            ),
          },
        },
        id: cs.id,
      };
    });
  };

  return (
    <>
      <ChargingSessionsFilter
        privateStationsIds={privateStationsIds}
        homeStations={homeStations}
        defaultFilters={defaultFilters}
        chargingCards={chargingCards}
        selectedFilters={selectedFilters}
        setSelectedFilters={setSelectedFilters}
      />
      <div data-testid='charging-records-table'>
        <AssetTable
          bulkActions={[]}
          columns={getColumns()}
          rows={getRows()}
          sortingState={sortState}
          sortRequestHandler={sortRequestHandler}
          noDataTitle='No charging sessions available'
          noDataSubTitle='User account has no charging sessions'
        />
      </div>
    </>
  );
};

export default ChargingRecords;
