import React from 'react';
import IconButton from '@mui/material/IconButton';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';

import { IColumn, IRow } from '../../shared/AssetTable/AssetTable/AssetTable';
import { ICreditNote } from '../../../types/creditNote';
import {
  addBreadcrumbHandler,
  TAddBreadCrumbFunc,
} from '../../../utils/addBreadcrumbHandler';
import { BreadcrumbStateModel } from '../../../store/state/breadcrumbs';
import { useTypedTranslation } from '../../../custom-hooks/useTypedTranslation';
import { PaymentStatusIndicator } from '../Invoices/PaymentStatusIndicator';
import { TransactionType } from '../../../types/transaction';
import { formatDateNoTime } from '../../../utils/dates';

interface IFieldName {
  name: string;
  key: keyof ICreditNote | 'action' | 'payment_status';
  isSortableBy: boolean;
}

export const useCreditNoteColumns = (): IColumn[] => {
  const { t, terms } = useTypedTranslation();

  const fieldNames: IFieldName[] = [
    {
      name: t(terms.refunds.overview.fields.credit_note_number),
      key: 'credit_memo_number',
      isSortableBy: true,
    },
    {
      name: t(terms.refunds.overview.fields.document_date),
      key: 'credit_memo_date',
      isSortableBy: true,
    },
    {
      name: t(terms.refunds.overview.fields.amount),
      key: 'gross_amount',
      isSortableBy: true,
    },
    {
      name: t(terms.refunds.overview.fields.current_state),
      key: 'state',
      isSortableBy: true,
    },
    {
      name: t(terms.refunds.overview.fields.payment_status),
      key: 'payment_status',
      isSortableBy: false,
    },
    {
      name: '',
      key: 'action',
      isSortableBy: false,
    },
  ];

  return fieldNames.map(({ name, key, isSortableBy }) => ({
    header: name,
    isSortable: isSortableBy,
    key,
  }));
};

export const useCreditNoteRows = (
  creditNotes: ICreditNote[],
  addBreadcrumb: TAddBreadCrumbFunc,
  taskBreadcrumbState: BreadcrumbStateModel,
  crumbId?: string
): IRow[] =>
  creditNotes.map((creditNote) => ({
    id: creditNote.id,
    data: {
      credit_memo_number: {
        content: creditNote.credit_memo_number || '-',
      },
      credit_memo_date: {
        content: formatDateNoTime(creditNote.credit_memo_date),
      },
      gross_amount: {
        content: `${creditNote.gross_amount} ${creditNote.currency}`,
      },
      state: {
        content: creditNote.state,
      },
      payment_status: {
        content: (
          <PaymentStatusIndicator
            transactionType={TransactionType.CreditNote}
            transactionNumber={creditNote.credit_memo_number}
            transactionState={creditNote.state}
          />
        ),
      },
      action: {
        content: (
          <IconButton
            data-testid='invoice-link'
            onClick={() =>
              addBreadcrumbHandler({
                component: 'CreditNote',
                friendlyText: 'Credit Note',
                id: creditNote.id,
                taskBreadcrumbState,
                addBreadcrumb,
                crumbId,
              })
            }
            color='primary'
            size='large'
          >
            <ChevronRightIcon fontSize='large' />
          </IconButton>
        ),
      },
    },
  }));
