import React from 'react';
import { useTypedTranslation } from '../../../custom-hooks/useTypedTranslation';
import useStyles from './useStyles';
import { TextField } from '@mui/material';

type TOwnProps = {
  searchInput: string;
  setSearchInput: (x: string) => void;
  handleSearch: () => void;
  helperText?: string;
  hasError?: boolean;
  label?: string;
  placeholderOverride?: string;
};

export const SearchBar: React.FC<TOwnProps> = ({
  searchInput,
  setSearchInput,
  handleSearch,
  helperText,
  hasError = false,
  label,
  placeholderOverride,
}) => {
  const classes = useStyles();
  const { t, terms } = useTypedTranslation();

  return (
    <TextField
      label={label}
      className={classes.bar}
      placeholder={
        placeholderOverride ?? t(terms.vin_eligibility.vin_search.search_label)
      }
      onKeyPress={(event) => event.key === 'Enter' && handleSearch()}
      value={searchInput}
      onChange={(e) => setSearchInput(e.target.value)}
      fullWidth
      variant='outlined'
      error={hasError}
      helperText={helperText}
    />
  );
};
