import React, { useState } from 'react';

import { useWallboxConfigDataQuery } from '../../../state/queries/wallboxMetaData';
import MultilineButton from '../../shared/Buttons/MultilineButton';
import { SearchBar } from '../../shared/SearchBar/SearchBar';
import useStyles from './useStyles';
import { StationService } from '../../../services/stations';

export type TOwnProps = {
  setSerialNumber: (x: string) => void;
};

export const WallboxSearch: React.FC<TOwnProps> = ({ setSerialNumber }) => {
  const classes = useStyles();
  const [submittedSearchInput, setSubmittedSearchInput] = useState('');
  const [searchInput, setSearchInput] = useState('');
  const [inputError, setInputError] = useState(false);

  useWallboxConfigDataQuery(submittedSearchInput);

  const handleInputChange = (input: string) => {
    setSearchInput(input.toUpperCase());
    setInputError(!StationService.isValidWallboxSerialNumber(input));
  };

  const handleSearch = async () => {
    setSerialNumber(searchInput);
    setSubmittedSearchInput(searchInput);
  };

  return (
    <>
      <SearchBar
        label='Serial number'
        searchInput={searchInput}
        setSearchInput={handleInputChange}
        handleSearch={handleSearch}
        hasError={inputError}
        helperText={
          inputError
            ? 'This doesn’t look right. A serial number is alphanumeric and sometimes includes dashes. Please check the serial number and try it again.'
            : ''
        }
        placeholderOverride='Enter serial number'
      />
      <div className={classes.buttonBox}>
        <MultilineButton
          mainText='Search'
          handleClick={handleSearch}
          isDisabled={searchInput === '' || inputError}
        />
      </div>
    </>
  );
};
