import {
  getAuthorisationMode,
  getStationModel,
} from '../../../../utils/stationUtils';

import { ActionInterface } from '../actions';
import { IHomeStation } from '../../../../types/user';
import { ISortState } from '../../../../components/shared/AssetTable/AssetTable/AssetTable';
import { State } from '..';
import { lexicographical } from '../../../../utils/comparisonFunctions';
import update from 'immutability-helper';

interface ISortFunctionProps {
  a: IHomeStation;
  b: IHomeStation;
}

interface ISortFunctions {
  [string: string]: (props: ISortFunctionProps) => number;
}

export const sortFunctions: ISortFunctions = {
  connection_state: ({ a, b }) => {
    return lexicographical(a.connection_state, b.connection_state);
  },
  name: ({ a, b }) => {
    return lexicographical(a.name || '', b.name || '');
  },
  model: ({ a, b }) => {
    const first = getStationModel(a.model, a.model_variant);
    const second = getStationModel(b.model, b.model_variant);
    return lexicographical(first, second);
  },
  authorization_mode: ({ a, b }) => {
    const first = getAuthorisationMode(a.authorization_mode);
    const second = getAuthorisationMode(b.authorization_mode);
    return lexicographical(first, second);
  },
  last_contact: ({ a, b }) => {
    const lastContactA = a.last_contact
      ? new Date(a.last_contact)
      : new Date('1970-01-01 00:00:00');
    const lastContactB = b.last_contact
      ? new Date(b.last_contact)
      : new Date('1970-01-01 00:00:00');
    return lexicographical(lastContactA, lastContactB);
  },
};

export const generateSortFunction = (sortState: ISortState) => (
  a: IHomeStation,
  b: IHomeStation
): number => {
  const sortKey = sortState.sortByColumnKey as AdminOrderSortKey;
  if (sortKey) {
    const sortFunction = sortFunctions[sortKey];
    const ascendingSortResult = sortFunction({ a, b });

    return sortState.sortDesc ? ascendingSortResult * -1 : ascendingSortResult;
  } else {
    return 0;
  }
};

type AdminOrderSortKey = keyof typeof sortFunctions;

export default (
  state: State,
  action: ActionInterface.SortHomeStations
): State => {
  const { sortState } = action;
  const currentHomeStationsState = state;

  if (currentHomeStationsState && currentHomeStationsState.stations) {
    const sortedHomeStations: IHomeStation[] =
      currentHomeStationsState.stations;
    sortedHomeStations.sort(generateSortFunction(sortState));

    const updatedHomeStations = update(currentHomeStationsState, {
      stations: {
        $set: sortedHomeStations,
      },
      loading: {
        $set: false,
      },
    });

    return {
      ...state,
      ...updatedHomeStations,
    };
  }

  return {
    ...state,
  };
};
