import { Theme } from '@mui/material';

import makeStyles from '@mui/styles/makeStyles';
import createStyles from '@mui/styles/createStyles';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    subscriptionStatusInfo: {
      color: theme.palette.grey['500'],
      fontSize: '1.2em',
      marginLeft: '.5em',
      cursor: 'pointer',
    },
  })
);

export default useStyles;
