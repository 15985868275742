import createStyles from '@mui/styles/createStyles';
import makeStyles from '@mui/styles/makeStyles';

const useStyles = makeStyles(() =>
  createStyles({
    indicator: {
      borderRadius: '5px',
      height: 8,
      width: 8,
      marginRight: 4,
    },
  })
);

export default useStyles;
