import { Theme } from '@mui/material';

import makeStyles from '@mui/styles/makeStyles';
import createStyles from '@mui/styles/createStyles';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    accountsCardHeader: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'space-between',
      marginBottom: 16,
    },
    titleWrapper: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'flex-start',
    },
    titleIcon: {
      color: theme.colorPrimary,
      fontSize: 24,
      marginRight: 16,
    },
    panelTitle: {
      fontSize: 20,
      fontWeight: 600,
    },
  })
);

export default useStyles;
