import React from 'react';
import { useTheme } from '@mui/material/styles';
import {
  BasicStatus,
  IMappedStatus,
} from '../shared/StatusIndicator/BasicStatus';
import { PublicChargingSessionStatus } from '../../types/publicCharging';

interface IChargingSessionStatusComponentProps {
  status: PublicChargingSessionStatus;
  statusTypeForTestId?: string;
  CustomLabelComponent?: React.FC<{ label: string }>;
}

export const ChargingSessionStatusComponent: React.FC<IChargingSessionStatusComponentProps> = ({
  status,
  statusTypeForTestId,
  CustomLabelComponent,
}) => {
  const theme = useTheme();

  const sessionStatusToUiStatusMap: Record<
    PublicChargingSessionStatus,
    IMappedStatus
  > = {
    ACTIVE: { color: theme.colorAccent, label: 'Active' },
    COMPLETED: { color: theme.colorPrimary, label: 'Completed' },
    INVALID: { color: theme.colorAlertPrimary, label: 'Invalid' },
    PENDING: { color: theme.colorPrimary, label: 'Pending' },
    COMPLETED_BY_CDR: { color: theme.colorPrimary, label: 'Completed by CDR' },
    COMPLETED_BY_REMOTE_STOP: {
      color: theme.colorPrimary,
      label: 'Completed by Remote Stop',
    },
    COMPLETED_BY_AGENT_KILL: {
      color: theme.colorAlertSecondary,
      label: 'Completed by Agent Kill',
    },
    COMPLETED_BY_USER_KILL: {
      color: theme.colorAlertSecondary,
      label: 'Completed by User Kill',
    },
  };

  const valueMapper = (value: PublicChargingSessionStatus): IMappedStatus =>
    sessionStatusToUiStatusMap[value];

  return (
    <BasicStatus
      status={status}
      valueMapper={valueMapper}
      statusTypeForTestId={statusTypeForTestId}
      CustomLabelComponent={CustomLabelComponent}
    />
  );
};
