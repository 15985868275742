import { AppDispatch } from '../../../../store';
import { ActionInterface } from '../../../../store/state/subscription/actions';
import { Dispatcher } from '../index';
import {
  getSubscriptions,
  sortSubscriptions,
  terminateSubscriptions,
} from './dispatchers';

interface IDispatchers {
  sortSubscriptions: Dispatcher<ActionInterface.SortSubscriptions>;
  getSubscriptions: Dispatcher<ActionInterface.GetSubscriptionRequest>;
  terminateSubscription: Dispatcher<
    ActionInterface.TerminateSubscriptionRequest
  >;
}

export const createDispatchers = (dispatch: AppDispatch): IDispatchers => ({
  sortSubscriptions: sortSubscriptions(dispatch),
  getSubscriptions: getSubscriptions(dispatch),
  terminateSubscription: terminateSubscriptions(dispatch),
});
