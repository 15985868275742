import React, { useState } from 'react';

import OrderSuccessIcon from '../../../../assets/image/cardsuccess.svg';
import { useTypedTranslation } from '../../../../custom-hooks/useTypedTranslation';
import connector, { IPropsFromState } from '../../../Connector/Connector';
import DialogHeadline from '../DialogHeadline';
import SuccessMessage from '../SuccessMessage';
import OrderRfidCardForm from './OrderRfidCardForm/OrderRfidCardForm';

type OwnProps = IPropsFromState;

const OrderRfidCard: React.FC<OwnProps> = ({ subscriberState, userState }) => {
  const subscriber = subscriberState?.data;
  const user = userState.data;
  const { t, terms } = useTypedTranslation();
  const [isSuccess, setIsSuccess] = useState<boolean>(false);

  const handleSuccess = () => {
    setIsSuccess(true);
  };

  if (isSuccess) {
    return (
      <SuccessMessage
        headline={t(terms.order_card_dialog.success.headline)}
        iconAltText={t(terms.order_card_dialog.success.headline)}
        icon={OrderSuccessIcon}
      />
    );
  }

  if (!subscriber) {
    return (
      <DialogHeadline
        headline={t(terms.order_card_dialog.no_subscriber.headline)}
        subHeadline={t(terms.order_card_dialog.no_subscriber.sub_headline)}
      />
    );
  }

  return (
    <OrderRfidCardForm
      subscriber={subscriber}
      user={user}
      onSuccess={handleSuccess}
    />
  );
};

export default connector(OrderRfidCard);
