import React from 'react';
import useSharedStyles from './useSharedStyles';

interface ICardProps {
  title: string;
  length: number;
  onClick: () => void;
}

export const CardButton: React.FC<ICardProps> = ({
  onClick,
  length,
  title,
}) => {
  const sharedClasses = useSharedStyles();

  return length > 0 ? (
    <div
      data-testid='card-button'
      onClick={() => onClick()}
      className={sharedClasses.cardButtonWrapper}
    >
      <span className={sharedClasses.cardButtonTitle}>{title}</span>
      <div className={sharedClasses.roundCircle}>
        <span className={sharedClasses.cardButtonCount}>{length}</span>
      </div>
    </div>
  ) : null;
};
