import makeStyles from '@mui/styles/makeStyles';
import createStyles from '@mui/styles/createStyles';

const useStyles = makeStyles(() =>
  createStyles({
    subscriptionInfo: {
      display: 'flex',
      flexDirection: 'column',
      paddingBottom: '16px',
    },
    grid: {
      maxWidth: '900px',
    },
    itemValue: {
      display: 'inline-block',
      fontSize: 14,
      alignItems: 'center',
      maxWidth: '110%',
      textOverflow: 'ellipsis',
      overflow: 'hidden',
      whiteSpace: 'nowrap',
    },
  })
);

export default useStyles;
