import { Dialog, DialogContent, DialogTitle, Grid } from '@mui/material';
import React, { useState } from 'react';
import { useTheme } from '@mui/material/styles';
import Button from '@mui/material/Button';
import { WeChargeDataPage } from '../WeChargeDataPage/WeChargeDataPage';
import { useTypedTranslation } from '../../../../custom-hooks/useTypedTranslation';
import useStyles from './useStyles';
import { TerminateActiveSubscriptionDialog } from '../TerminateActiveSubscriptionDialog';
import { Trans } from 'react-i18next';
import { Typography } from '../../../shared/Typography/Typography';

type summaryTitleProps = {
  header: string;
  subHeader: JSX.Element;
};

const TerminateSubscriptionSummaryDialogTitle: React.FC<summaryTitleProps> = ({
  header,
  subHeader,
}) => {
  return (
    <DialogTitle>
      <Typography
        display='block'
        align='center'
        color='textPrimary'
        variant='h2'
        paragraph
      >
        {header}
      </Typography>
      <Typography
        display='block'
        align='center'
        color='textPrimary'
        variant='h4'
        paragraph
      >
        {subHeader}
      </Typography>
    </DialogTitle>
  );
};

type containerProps = {
  open: boolean;
  onClose: () => void;
  title: JSX.Element | null;
  curStep: number;
  numSteps: number;
  setStep: (step: number) => void;
};

const TerminateSubscriptionSummaryContainer: React.FC<containerProps> = (
  props
) => {
  const theme = useTheme();
  const classes = useStyles(theme);
  const { t, terms } = useTypedTranslation();
  return (
    <Dialog
      disableEscapeKeyDown={true}
      open={props.open}
      onClose={props.onClose}
      PaperProps={{
        classes: {
          root: classes.dialog,
        },
      }}
    >
      {props.title}
      {props.children}
      {props.curStep < props.numSteps ? (
        <Grid
          container
          direction='row'
          alignItems='center'
          justifyContent='center'
        >
          <Grid item xs={6}>
            <Button
              variant='outlined'
              className={classes.stepperButton}
              onClick={() => props.setStep(props.curStep + 1)}
            >
              {t(terms.subscription_termination.result_dialog_button_next)}
            </Button>
          </Grid>
        </Grid>
      ) : (
        <Grid
          container
          direction='row'
          alignItems='baseline'
          justifyContent='center'
        >
          {props.curStep > 1 ? (
            <Grid item xs={6}>
              <Button
                className={classes.stepperButton}
                onClick={() => props.setStep(props.curStep - 1)}
              >
                {t(terms.subscription_termination.result_dialog_button_back)}
              </Button>
            </Grid>
          ) : null}
          <Grid item xs={6}>
            <Button
              variant='outlined'
              className={classes.stepperButton}
              onClick={() => {
                props.setStep(props.numSteps);
                props.onClose();
              }}
            >
              {t(terms.subscription_termination.result_dialog_button_done)}
            </Button>
          </Grid>
        </Grid>
      )}
    </Dialog>
  );
};

export type OwnProps = {
  open: boolean;
  onClose: () => void;
  hasError: boolean;
  tenant: string;
  terminationDate: Date | null;
};

export const TerminateSubscriptionSummaryDialog: React.FC<OwnProps> = ({
  open,
  onClose,
  hasError,
  tenant,
  terminationDate,
}) => {
  const { t, terms } = useTypedTranslation();
  const theme = useTheme();
  const classes = useStyles(theme);

  const isWeChargeTenant = (tn: string | null): boolean => {
    const weChargeTenantNames = ['WeCharge', 'Volkswagen'];
    return tn !== null && weChargeTenantNames.includes(tn);
  };

  const [step, setStep] = useState(1);
  const numSteps = isWeChargeTenant(tenant) ? 2 : 1;

  const title = (
    <TerminateSubscriptionSummaryDialogTitle
      header={t(
        terms.subscription_termination.result_dialog_heading_almost_there
      )}
      subHeader={
        <Trans
          t={t}
          i18nKey={terms.subscription_termination.result_dialog_subheading}
        />
      }
    />
  );

  const getPageByStep = (
    curStep: number,
    numberOfSteps: number,
    hasTerminationError: boolean
  ) => {
    switch (numberOfSteps - curStep) {
      case 1: {
        return <WeChargeDataPage />;
      }
      case 0: {
        return (
          <TerminateActiveSubscriptionDialog
            hasError={hasTerminationError}
            terminationDate={terminationDate}
          />
        );
      }
      default: {
        return <div />;
      }
    }
  };

  return (
    <TerminateSubscriptionSummaryContainer
      open={open}
      onClose={onClose}
      title={step !== numSteps ? title : null}
      curStep={step}
      numSteps={numSteps}
      setStep={setStep}
      data-testid='subscription-termination-summary-dialog'
    >
      <DialogContent
        classes={{
          root: classes.dialogContent,
        }}
      >
        {getPageByStep(step, numSteps, hasError)}
      </DialogContent>
    </TerminateSubscriptionSummaryContainer>
  );
};
