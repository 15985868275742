import { ActionInterface } from '../actions';
import { State } from '..';
import update from 'immutability-helper';

export default (
  state: State,
  action: ActionInterface.GetHomeStationsSuccess
): State => {
  const { data, stations } = action;
  const currentHomeStations = state;
  const updatedHomeStations = update(currentHomeStations, {
    data: {
      $set: data,
    },
    stations: {
      $set: stations,
    },
    loading: {
      $set: false,
    },
  });

  return {
    ...state,
    ...updatedHomeStations,
  };
};
