import React, { useEffect, useState } from 'react';
import { Circle, GoogleMap, useLoadScript } from '@react-google-maps/api';

import { ActionInterface } from '../../../../store/state/chargingStations/actions';
import { CustomMarker } from '../CustomMarker';
import { CustomZoom } from '../CustomZoom/CustomZoom';
import { IBreadcrumbHandlerProps } from '../../../../utils/addBreadcrumbHandler';
import { IChargingStationModel } from '../../../../store/state/chargingStations';
import { ILocationModel } from '../../../../types/chargingSession';
import { IStationViewModel } from '../../../../view-models/StationModel';
import { config as appConfig } from '../../../../config';
import { getRadiusInMeters } from '../../../../utils/geometry';
import refreshButton from '../../../../assets/image/map/refreshButton.svg';
import useStyles from './useStyles';
import { Typography } from '../../../shared/Typography/Typography';

const mapConfig = {
  containerStyle: {
    width: '100%',
    height: '311px',
  },
  circleOptions: {
    strokeColor: '#00ff99',
    strokeWeight: 2,
    fillOpacity: 0,
  },
  mapType: 'satellite',
  startLat: 52.520008,
  startLng: 13.404954,
};

interface IGeolocation {
  lat: number | undefined;
  long: number | undefined;
}

export interface IMapProps {
  getNearbyLocationsRequest: ({
    location,
    evse_id,
  }: Pick<
    ActionInterface.GetNearbyLocationsRequest,
    'evse_id' | 'location'
  >) => void;
  addBreadcrumbHandler: (props: IBreadcrumbHandlerProps) => void;
  evseId: string | undefined;
  resourceId?: string;
  currentStation: IStationViewModel;

  chargingStationState: IChargingStationModel;
}

export const ChargingStationMap: React.FC<IMapProps> = ({
  evseId,
  resourceId,
  currentStation,
  chargingStationState,
  addBreadcrumbHandler,
  getNearbyLocationsRequest,
}) => {
  const classes = useStyles();
  const taskStations = chargingStationState;
  const taskNearbyLocationsState = taskStations && taskStations.nearbyLocations;

  const { isLoaded, loadError } = useLoadScript({
    googleMapsApiKey: appConfig.googleMapApiKey,
  });

  const [mapInstance, setMapInstance] = useState<google.maps.Map | undefined>();

  const [nearbyLocations, setNearbyLocations] = useState<
    ILocationModel[] | null
  >(null);

  const [
    chargingStationGeolocation,
    setChargingStationGeolocation,
  ] = useState<IGeolocation | null>(null);

  const [latitudeCenter, setLatitudeCenter] = useState<number>(
    mapConfig.startLat
  );
  const [longitudeCenter, setLongitudeCenter] = useState<number>(
    mapConfig.startLng
  );
  const [radius, setRadius] = useState<number>(2200);
  const [zoom, setZoom] = useState<number>(19);
  const [isRefreshButtonActive, setIsRefreshButtonActive] = useState<boolean>(
    false
  );

  const onZoomChanged = () => {
    if (mapInstance) {
      const newZoom = mapInstance.getZoom();

      setZoom(newZoom);
      setIsRefreshButtonActive(true);
    }
  };

  const chooseMarker = (item: ILocationModel) => {
    const isEvseId = item.charging_spots && item.charging_spots[0].evse_id;
    if (item.geo_location && isEvseId !== evseId) {
      return (
        <CustomMarker
          key={item.location_id}
          id={item.location_id}
          type='nearby'
          evse_id={isEvseId}
          lat={item.geo_location.lat}
          lng={item.geo_location.long}
          onClick={() =>
            addBreadcrumbHandler({
              component: 'ChargingStationDetails',
              friendlyText: 'Charging Station',
              id: isEvseId,
              config: {
                location_evse_id: isEvseId,
              },
            })
          }
        />
      );
    }
    return null;
  };

  const setLatLngCenter = (lat: number, lng: number): void => {
    setLatitudeCenter(lat);
    setLongitudeCenter(lng);
  };

  const refreshNearbyLocations = (
    lat: number,
    lng: number,
    newZoom: number
  ): void => {
    const newRadius = getRadiusInMeters(lat, newZoom);

    const location = {
      lat: lat,
      long: lng,
      radius: Math.floor(newRadius),
    };

    setIsRefreshButtonActive(false);
    if (resourceId) {
      getNearbyLocationsRequest({ evse_id: resourceId, location });
    }

    setRadius(newRadius);
    setLatLngCenter(lat, lng);
  };

  const refreshLocations = () => {
    if (mapInstance) {
      const lat = mapInstance.getCenter().lat();
      const lng = mapInstance.getCenter().lng();
      const newZoom = mapInstance.getZoom();

      refreshNearbyLocations(lat, lng, newZoom);
    }
  };

  useEffect(() => {
    if (chargingStationGeolocation) {
      const newRadius = getRadiusInMeters(
        chargingStationGeolocation.lat as number,
        zoom
      );

      setRadius(newRadius);
      refreshNearbyLocations(
        chargingStationGeolocation.lat as number,
        chargingStationGeolocation.long as number,
        zoom
      );
    }
  }, [chargingStationGeolocation]);

  useEffect(() => {
    if (currentStation && currentStation.geo_location) {
      setChargingStationGeolocation(currentStation.geo_location);
    }
  }, [currentStation]);

  useEffect(() => {
    if (taskNearbyLocationsState && resourceId) {
      setNearbyLocations(taskNearbyLocationsState[resourceId]);
    } else {
      setNearbyLocations(null);
    }
  }, [taskNearbyLocationsState]);

  if (loadError) {
    return <div data-testid='map-error'>Error loading maps</div>;
  }

  if (!isLoaded) {
    return <div data-testid='map-loading'>Loading maps</div>;
  }

  return (
    <div className={`${!mapInstance && classes.mapLoading}`}>
      <GoogleMap
        data-testid='google-map'
        mapContainerStyle={mapConfig.containerStyle}
        center={{ lat: latitudeCenter, lng: longitudeCenter }}
        zoom={zoom}
        onZoomChanged={onZoomChanged}
        options={{
          disableDefaultUI: true,
          zoomControl: false,
          mapTypeControl: false,
          mapTypeId: mapConfig.mapType,
        }}
        onLoad={setMapInstance}
      >
        {chargingStationGeolocation ? (
          <>
            <CustomMarker
              id={evseId as string}
              type='main'
              lat={chargingStationGeolocation.lat as number}
              lng={chargingStationGeolocation.long as number}
            />

            {nearbyLocations &&
              nearbyLocations.length > 0 &&
              nearbyLocations.map((item) => {
                return chooseMarker(item);
              })}

            <Circle
              center={{ lat: latitudeCenter, lng: longitudeCenter }}
              radius={radius}
              options={mapConfig.circleOptions}
            />
          </>
        ) : (
          <div data-testid='no-google-map' className={classes.noMapDataOverlay}>
            <div className={classes.noMapDataTextBox}>
              <Typography className={classes.noMapDataText} variant='h4'>
                Location can`t be displayed
              </Typography>
            </div>
          </div>
        )}
        <CustomZoom setZoom={setZoom} zoom={zoom} />
        {isRefreshButtonActive && (
          <div
            data-testid='refresh-button'
            className={classes.refreshButton}
            onClick={refreshLocations}
          >
            <img src={refreshButton} alt='refresh button' />
          </div>
        )}
      </GoogleMap>
    </div>
  );
};
