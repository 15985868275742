import React, { useRef } from 'react';
import { IconButton } from '@mui/material';
import useStyles from '../CardElement/useStyles';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import ActionDropdown from '../CardElement/ActionDropdown/ActionDropdown';
import { useToggle } from '../../../custom-hooks/useToggle';

export interface IActionItem {
  icon: JSX.Element;
  friendlyText: string;
  component: JSX.Element;
  disabled?: boolean;
  isFullWidth?: boolean;
}

export interface IOwnProps {
  actionItems: IActionItem[];
}

const ActionDropdownContainer: React.FC<IOwnProps> = ({ actionItems }) => {
  const classes = useStyles();
  const dropdownRef = useRef<HTMLButtonElement>(null);
  const [isOpen, toggleIsOpen] = useToggle(false);

  return (
    <div className={classes.actionWrapper}>
      <IconButton
        color='primary'
        className={classes.actionButton}
        onClick={toggleIsOpen}
        ref={dropdownRef}
        size='large'
      >
        <MoreVertIcon fontSize='small' />
      </IconButton>
      <ActionDropdown
        open={isOpen}
        setOpen={toggleIsOpen}
        dropdownRef={dropdownRef}
        actionItems={actionItems}
      />
    </div>
  );
};

export default ActionDropdownContainer;
