import { ActionInterface } from '../actions';
import { State } from '..';
import serializeError from 'serialize-error';
import update from 'immutability-helper';

export default (
  state: State,
  action: ActionInterface.GetActiveSessionsError
): State => {
  const { error } = action;

  const currentState = state || {
    loading: false,
    data: [],
    error: null,
    request: null,
  };

  const updatedState = update(currentState, {
    error: {
      $set: serializeError(error),
    },
    loading: {
      $set: false,
    },
    data: {
      $set: [],
    },
  });

  return {
    ...state,
    ...updatedState,
  };
};
