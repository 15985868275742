import React, { useContext, useState } from 'react';

import { Button } from '@mui/material';

import connector, { IPropsFromState } from '../../../Connector/Connector';
import DialogHeadline from '../DialogHeadline';
import useStyles from './useStyles';
import MultilineButton from '../../Buttons/MultilineButton';
import { useTypedTranslation } from '../../../../custom-hooks/useTypedTranslation';
import { DialogContextState } from '../context/ActionDialogContext';

export type OwnProps = IPropsFromState & {
  confirmAction: () => Promise<void>;
  headline: string;
  subHeadline?: string;
  children?: React.ReactNode;
};

const ConfirmationDialog: React.FC<OwnProps> = ({
  confirmAction,
  headline,
  subHeadline,
  children,
}) => {
  const classes = useStyles();
  const { t, terms } = useTypedTranslation();
  const { handleCloseDialog } = useContext(DialogContextState);
  const [inProgress, setInProgress] = useState<boolean>(false);

  const handleConfirmation = async () => {
    try {
      setInProgress(true);
      await confirmAction();
      handleCloseDialog && handleCloseDialog();
    } catch (err) {
      setInProgress(false);
    }
  };

  return (
    <div className={classes.outerWrapper}>
      <div>
        <DialogHeadline headline={headline} subHeadline={subHeadline} />
      </div>
      {children}
      <div className={classes.actions}>
        <Button
          color='primary'
          variant='text'
          onClick={handleCloseDialog}
          className={classes.button}
        >
          {t(terms.general.cancel)}
        </Button>
        <MultilineButton
          handleClick={handleConfirmation}
          inProgress={inProgress}
          mainText={t(terms.general.confirm)}
          className={classes.button}
        />
      </div>
    </div>
  );
};

export default connector(ConfirmationDialog);
