import React, { useEffect, useState } from 'react';
import {
  addBreadcrumbHandler,
  IBreadcrumbHandlerProps,
} from '../../../utils/addBreadcrumbHandler';
import {
  getConnectorOverview,
  IConnectorOverview,
} from '../../../utils/getConnectorOverview';
import {
  ICsmsChargingStation,
  IOrganizationModel,
  IUserModel,
} from '../../../types/user';
import connector, { IPropsFromState } from '../../Connector/Connector';
import { AccessModeDialog } from '../../OverviewCards/HomeCharging/AccessModeDialog/AccessModeDialog';
import CircularProgress from '@mui/material/CircularProgress';
import { ElliTooltip } from '../../shared/ElliTooltip/ElliTooltip';
import { StationService } from '../../../services/stations';
import { IActiveSession } from '../../../types/activeSessions';
import IconButton from '@mui/material/IconButton';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import OfflineBoltOutlinedIcon from '@mui/icons-material/OfflineBoltOutlined';
import { OrganizationService } from '../../../services/organizations';
import PowerConnector from '../ChargeRecord/PowerConnector/PowerConnector';
import RefreshIcon from '@mui/icons-material/Refresh';
import { SessionState } from '../../shared/Dialogs/SessionState/SessionState';
import { StatusIndicator } from './StatusIndicator/StatusIndicator';
import differenceInSeconds from 'date-fns/differenceInSeconds';
import { formatIsoDates } from '../../../utils/dates';
import { getAuthenticationMethod } from '../../../utils/getAuthenticationMethod';
import { getAuthorisationMode } from '../../../utils/stationUtils';
import { getSessionState } from '../../../utils/getSessionState';
import { getTypeIcon } from '../../../utils/getTypeIcon';
import { parseConsumption } from '../../CollectionCards/ChargingSessions/helpers';
import { parseChargingAddress } from '../../../utils/parseAddress';
import { parseSecondsDigital } from '../../../utils/parseSeconds';
import useStyles from './useStyles';

type TOwnProps = IPropsFromState & {
  resourceId?: string;
  crumbId?: string;
};

interface IStationNameProps {
  session: IActiveSession | null;
  station: ICsmsChargingStation | null;
}

export const getStationName = ({
  session,
  station,
}: IStationNameProps): string => {
  if (session && session.station_name) {
    return session.station_name;
  } else if (station) {
    if (station.name) {
      return station.name;
    } else if (station.station_serial_number) {
      return station.station_serial_number;
    } else if (station.id) {
      return station.id;
    } else {
      return '-';
    }
  } else {
    return '-';
  }
};

export const ActiveSessionDetails: React.FC<TOwnProps> = ({
  userState,
  activeSessionsState,
  resourceId,
  breadcrumbState,
  crumbId,
  getActiveSessionsRequest,
  addBreadcrumb,
}) => {
  const classes = useStyles();

  const taskBreadcrumbState = breadcrumbState.breadcrumbs;
  const taskSessionState = activeSessionsState;
  const taskUserState = userState;

  const [dialogOpen, setDialogOpen] = useState<boolean>(false);
  const [accessModeDialogOpen, setAccessModeDialogOpen] = useState<boolean>(
    false
  );
  const [currentSession, setCurrentSession] = useState<IActiveSession | null>(
    null
  );
  const [userId, setUserId] = useState<string>('');
  const [currentUser, setCurrentUser] = useState<IUserModel | null>(null);
  const [timer, setTimer] = useState<string>('');
  const [loading, setLoading] = useState<boolean>(false);
  const [
    currentStation,
    setCurrentStation,
  ] = useState<ICsmsChargingStation | null>(null);
  const [stationName, setStationName] = useState<string>('');
  const [stationAddress, setStationAddress] = useState<string>('');
  const [stationOperator, setStationOperator] = useState<string>('');
  const [
    currentOrganization,
    setCurrentOrganization,
  ] = useState<IOrganizationModel | null>(null);
  const [sessionType, setSessionType] = useState<string>('');
  const [
    connectorOverview,
    setConnectorOverview,
  ] = useState<IConnectorOverview | null>(null);

  const [timerInterval, setTimerInterval] = useState<ReturnType<
    typeof setInterval
  > | null>(null);

  let loadingClass = `${classes.itemWrapper}`;

  if (loading) {
    loadingClass += ` ${classes.loading}`;
  }

  const refresh = () => {
    getActiveSessionsRequest({
      userId,
    });
  };

  useEffect(() => {
    if (taskUserState.data) {
      setCurrentUser(taskUserState.data);
      setUserId(taskUserState.data.id);
    }
  }, [taskUserState]);

  useEffect(() => {
    setStationName(
      getStationName({ session: currentSession, station: currentStation })
    );
  }, [currentSession, currentStation]);

  useEffect(() => {
    const overview = getConnectorOverview({
      currentStation,
      currentSession,
    });

    if (overview) {
      setConnectorOverview(overview);
    }
  }, [currentSession, currentStation]);

  useEffect(() => {
    if (currentSession) {
      if (timerInterval) {
        clearInterval(timerInterval);
      }
      let difInSec = differenceInSeconds(
        new Date(),
        new Date(currentSession.start_date_time)
      );
      setTimerInterval(
        setInterval(() => {
          setTimer(parseSecondsDigital(difInSec));
          difInSec++;
        }, 1000)
      );
    }
  }, [currentSession]);

  useEffect(() => {
    if (taskSessionState && resourceId) {
      setLoading(taskSessionState.loading);
      const cs = taskSessionState.data?.find(
        (session) => session.id === resourceId
      );

      if (cs) {
        setCurrentSession(cs);
      }
    }
  }, [taskSessionState, resourceId]);

  useEffect(() => {
    const getCurrentStation = async () => {
      try {
        const station = await StationService.getCsmsStationById(
          (currentSession as IActiveSession).station_id
        );

        setCurrentStation(station);
      } catch (error) {
        setCurrentStation(null);
      }
    };

    if (currentSession) {
      getCurrentStation();
    }
  }, [currentSession]);

  useEffect(() => {
    const getOrganization = async (organizationId: string): Promise<void> => {
      try {
        const orgResponse = await OrganizationService.getOrganization({
          organizationId,
        });

        setCurrentOrganization(orgResponse);
      } catch (error) {
        setCurrentOrganization(null);
      }
    };

    if (currentStation && currentStation.owner) {
      if (currentStation.owner.type === 'organization') {
        getOrganization(currentStation.owner.iam_id);
      }
    }
  }, [currentStation]);

  useEffect(() => {
    if (currentStation && currentStation.owner) {
      const ownerIconMap = {
        organization: 'business',
        user: 'private',
        public: 'public',
      };
      const type = currentStation.owner.type;
      const iconType = ownerIconMap[type as 'organization' | 'user' | 'public'];

      setSessionType(iconType || '');
    }
  }, [currentStation]);

  useEffect(() => {
    if (currentStation) {
      const owner = currentStation.owner;
      if (owner && owner.iam_id === userId && currentUser) {
        const operator = currentUser.first_name
          ? `${currentUser.first_name} ${currentUser.last_name} (user)`
          : `${currentUser.email} (user)`;

        setStationOperator(operator);
      } else if (owner && owner.type !== 'organization') {
        setStationOperator('-');
      }
    }
  }, [currentStation, currentUser]);

  useEffect(() => {
    if (currentStation && currentStation.location) {
      setStationAddress(parseChargingAddress(currentStation.location.address));
    } else {
      setStationAddress('-');
    }
  }, [currentStation]);

  useEffect(() => {
    if (currentOrganization) {
      setStationOperator(currentOrganization.name || '-');
    }
  }, [currentOrganization]);

  const breadcrumbHandler = (props: IBreadcrumbHandlerProps): void =>
    addBreadcrumbHandler({
      ...props,
      addBreadcrumb,
      crumbId,
      taskBreadcrumbState,
    });

  return (
    currentSession && (
      <div
        className={classes.card}
        data-testid='active-session-detail-component'
      >
        <div className={classes.cardHeaderWrapper}>
          <OfflineBoltOutlinedIcon className={classes.headerLogo} />
          <h1 className={classes.headerTitle}>
            Charging Session at {stationName}
          </h1>
        </div>

        <div className={classes.detailsWrapper}>
          <h2 className={classes.sectionTitle}>
            <span>Details</span>
            {loading ? (
              <CircularProgress
                size={16}
                classes={{
                  root: classes.circleProgress,
                }}
              />
            ) : (
              <RefreshIcon
                data-testid='button-refresh'
                onClick={() => refresh()}
                className={classes.refreshIcon}
              />
            )}
          </h2>

          <div className={classes.outerItemWrapper}>
            <div className={classes.itemWrapper}>
              <span className={classes.itemTitle}>Session Start</span>
              <span className={classes.itemValue}>
                {formatIsoDates(currentSession.start_date_time)}
              </span>
            </div>

            <div className={loadingClass} data-testid='loading'>
              <span className={classes.itemTitleWithIcon}>
                <span>Session State</span>
                <IconButton
                  data-testid='more-info-button'
                  onClick={() => setDialogOpen((prevState) => !prevState)}
                  className={classes.infoIcon}
                  disableRipple
                  disableFocusRipple
                  size='large'
                >
                  <InfoOutlinedIcon />
                </IconButton>
              </span>
              <span className={classes.itemValue}>
                <span
                  style={{
                    marginRight: 8,
                  }}
                >
                  <StatusIndicator status={currentSession.charging_state} />
                </span>
                <span data-testid='data-session-state'>
                  {getSessionState(currentSession.charging_state)}
                </span>
              </span>
            </div>

            <div className={loadingClass} data-testid='loading'>
              <span className={classes.itemTitle}>Session Duration</span>
              <span className={classes.valueWithIcon}>
                {timer}
                <CircularProgress
                  size={16}
                  classes={{
                    root: classes.circleProgress,
                  }}
                />
              </span>
            </div>

            <div className={loadingClass} data-testid='loading'>
              <span className={classes.itemTitle}>Energy Consumption</span>
              <span className={classes.itemValue}>
                {currentSession.energy_consumption_wh
                  ? parseConsumption(
                      currentSession.energy_consumption_wh / 1000
                    )
                  : '-'}
              </span>
            </div>

            <div className={classes.itemWrapper}>
              <span className={classes.itemTitleWithIcon}>
                <span>Access Mode</span>
                <IconButton
                  data-testid='access-mode-more-info-button'
                  onClick={() =>
                    setAccessModeDialogOpen((prevState) => !prevState)
                  }
                  className={classes.infoIcon}
                  disableRipple
                  disableFocusRipple
                  size='large'
                >
                  <InfoOutlinedIcon />
                </IconButton>
              </span>
              <span className={classes.itemValue}>
                {getAuthorisationMode(currentSession.authorization_mode)}
              </span>
            </div>

            <div className={classes.itemWrapper}>
              <span className={classes.itemTitle}>Connector Info</span>
              <span className={classes.itemValue}>
                <PowerConnector connectorOverview={connectorOverview} />
              </span>
            </div>
          </div>
        </div>

        <div className={classes.locationWrapper}>
          <h2 className={classes.sectionTitle}>Charging Location</h2>
          <div className={classes.outerItemWrapper}>
            <div className={classes.itemWrapper}>
              <span className={classes.itemTitle}>Station Operator</span>
              <span className={classes.itemValue}>
                {stationOperator ? (
                  <ElliTooltip title={stationOperator}>
                    <span
                      data-testid='data-station-operator'
                      className={classes.tooltipOverflow}
                    >
                      {stationOperator}
                    </span>
                  </ElliTooltip>
                ) : (
                  <span
                    className={classes.tooltipOverflow}
                    data-testid='data-station-operator'
                  >
                    {stationOperator}
                  </span>
                )}
              </span>
            </div>
            <div className={classes.itemWrapperAddress}>
              <span className={classes.itemTitle}>Address</span>
              <span className={classes.itemValue}>{stationAddress}</span>
            </div>
            <div className={classes.itemWrapper}>
              <span className={classes.itemTitle}>Authentication Type</span>
              <span className={classes.itemValue}>
                {getAuthenticationMethod(currentSession.authentication_method)}
              </span>
            </div>
            <div className={classes.itemWrapper}>
              <span className={classes.itemTitle}>Charging Card Id</span>
              <span className={classes.itemValue}>
                {currentSession.rfid_card_label ||
                  currentSession.rfid_card_serial_number ||
                  '-'}
              </span>
            </div>
            <div className={classes.itemWrapper}>
              <span className={classes.itemTitle}>Station Id</span>
              <span
                className={classes.itemValueLink}
                onClick={() =>
                  breadcrumbHandler({
                    component: 'ChargingStationDetails',
                    friendlyText: 'Charging Station',
                    id: currentStation ? currentStation.id : '',
                    config: {
                      station_id: currentStation ? currentStation.id : 0,
                    },
                  })
                }
              >
                {stationName !== '-' ? (
                  <>
                    {getTypeIcon({ type: sessionType })}
                    <ElliTooltip title={stationName}>
                      <span
                        className={classes.tooltipOverflow}
                        style={{
                          width: 180,
                        }}
                        data-testid='data-station-name'
                      >
                        {stationName}
                      </span>
                    </ElliTooltip>
                  </>
                ) : (
                  '-'
                )}
              </span>
            </div>
          </div>
        </div>
        <SessionState open={dialogOpen} onClose={() => setDialogOpen(false)} />
        <AccessModeDialog
          open={accessModeDialogOpen}
          onClose={() => setAccessModeDialogOpen(false)}
        />
      </div>
    )
  );
};

export default connector(ActiveSessionDetails);
