import makeStyles from '@mui/styles/makeStyles';
import createStyles from '@mui/styles/createStyles';

const useStyles = makeStyles(() =>
  createStyles({
    addressElement: {
      marginTop: '10px',
    },
    billingStatus: {
      textTransform: 'capitalize', // !important',
    },
    title: {
      fontSize: 16,
      fontWeight: 600,
      marginBottom: 16,
    },
  })
);

export default useStyles;
