export const getSessionState = (sessionState: string): string => {
  if (sessionState === 'ev_detected') {
    return 'EV detected';
  }

  if (sessionState === 'charging') {
    return 'EV charging';
  }

  if (sessionState === 'suspended_ev') {
    return 'Suspended by EV';
  }

  if (sessionState === 'suspended_evse') {
    return 'Suspended by station';
  }

  if (sessionState === 'finished') {
    return 'Finished';
  }

  return '';
};
