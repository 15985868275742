/* eslint-disable @typescript-eslint/naming-convention */
import { actionCreator } from '../../type-utils';
import { IRFIDCardModel, ISubscriptionModel } from '../../../types/subscriber';
import { ITariffModel } from '../../../types/tariff';

import { ISortState } from '../../../components/shared/AssetTable/AssetTable/AssetTable';
import { ITerminateSubscriptionResponse } from '../../../services/subscriptions';
import { TerminateSubscriptionType } from '../../../components/DetailCards/Subscription/TerminateSubscriptionButton/TerminateSubscriptionButton';

export const ActionType = {
  GetSubscriptionRequest: 'subscription/get-subscription-request',
  GetSubscriptionSuccess: 'subscription/get-subscription-success',
  GetSubscriptionError: 'subscription/get-subscription-error',
  ClearSubscription: 'subscription/clear-subscription',
  SortSubscriptions: 'subscription/sort-subscriptions',
  TerminateSubscriptionRequest: 'subscription/terminate-subscription-request',
  TerminateSubscriptionSuccess: 'subscription/terminate-subscription-success',
  TerminateSubscriptionError: 'subscription/terminate-subscription-error',
};

export declare namespace ActionInterface {
  export interface GetSubscriptionRequest {
    type: string;
    subscriberId: string;
  }

  export interface GetSubscriptionSuccess {
    type: string;
    data: ISubscriptionModel[];
  }

  export interface GetSubscriptionError {
    type: string;
    error: Error;
  }

  export interface ClearSubscription {
    type: string;
  }
  export interface SortSubscriptions {
    type: string;
    sortState: ISortState;
    tariffs: ITariffModel[];
    chargingCards: IRFIDCardModel[];
  }

  export interface TerminateSubscriptionRequest {
    type: string;
    subscriptionId: string;
    subscriberId: string;
    status: TerminateSubscriptionType;
    terminationDate?: Date;
    revocationReason?: string;
  }

  export interface TerminateSubscriptionSuccess {
    type: string;
    data: ITerminateSubscriptionResponse;
    status: TerminateSubscriptionType;
  }

  export interface TerminateSubscriptionError {
    type: string;
    error: Error;
  }
}

export const ActionCreator = {
  GetSubscriptionRequest: actionCreator<ActionInterface.GetSubscriptionRequest>(
    ActionType.GetSubscriptionRequest
  ),
  GetSubscriptionSuccess: actionCreator<ActionInterface.GetSubscriptionSuccess>(
    ActionType.GetSubscriptionSuccess
  ),
  GetSubscriptionError: actionCreator<ActionInterface.GetSubscriptionError>(
    ActionType.GetSubscriptionError
  ),
  ClearSubscription: actionCreator<ActionInterface.ClearSubscription>(
    ActionType.ClearSubscription
  ),
  SortSubscriptions: actionCreator<ActionInterface.SortSubscriptions>(
    ActionType.SortSubscriptions
  ),
  TerminateSubscriptionRequest: actionCreator<
    ActionInterface.TerminateSubscriptionRequest
  >(ActionType.TerminateSubscriptionRequest),
  TerminateSubscriptionSuccess: actionCreator<
    ActionInterface.TerminateSubscriptionSuccess
  >(ActionType.TerminateSubscriptionSuccess),
  TerminateSubscriptionError: actionCreator<
    ActionInterface.TerminateSubscriptionError
  >(ActionType.TerminateSubscriptionError),
};
