import { countryInformationMapper } from '../../../../../utils/currencies';
import { ISubscriberModel } from '../../../../../types/subscriber';
import { IUserModel } from '../../../../../types/user';
import { TInputProps } from '../../../form-elements/input/Input.types';
import { TSelectProps } from '../../../form-elements/select/Select.types';
import { useTypedTranslation } from '../../../../../custom-hooks/useTypedTranslation';

type DefaultValues = Omit<IRfidCardOrderFields, 'zip' | 'other_reason'>;

export interface IRfidCardOrderFields {
  zip: string;
  city: string;
  country: string;
  reason: string;
  address_line_1?: string;
  address_line_2?: string;
  name?: string;
  state?: string;
  other_reason?: string;
}

interface ITextFields {
  [key: string]: TInputProps<IRfidCardOrderFields>;
}
interface ISelectFields {
  [key: string]: TSelectProps<IRfidCardOrderFields>;
}

export const DEFAULT_VALUES: DefaultValues = {
  city: '',
  country: '',
  reason: '',
  address_line_1: '',
  address_line_2: '',
  name: '',
  state: '',
};

const countryCodeOptions = Object.keys(countryInformationMapper).map((key) => ({
  key,
  value: key,
  content: key,
}));

export const useTextFields = (): ITextFields => {
  const { t, terms } = useTypedTranslation();
  const fieldTerms = terms.order_card_dialog.fields;

  return {
    name: {
      id: 'name',
      name: 'name',
      label: t(fieldTerms.name),
    },
    addressLine1: {
      id: 'address_line_1',
      name: 'address_line_1',
      label: t(fieldTerms.address_line_1),
    },
    addressLine2: {
      id: 'address_line_2',
      name: 'address_line_2',
      label: t(fieldTerms.address_line_2),
    },
    zip: {
      id: 'zip',
      name: 'zip',
      label: t(fieldTerms.zip),
      isRequired: true,
    },
    city: {
      id: 'city',
      name: 'city',
      label: t(fieldTerms.city),
      isRequired: true,
    },
    state: {
      id: 'state',
      name: 'state',
      label: t(fieldTerms.state),
    },
    otherReason: {
      id: 'other_reason',
      name: 'other_reason',
      label: t(fieldTerms.other_reason),
      isRequired: true,
    },
  };
};

export const useSelectFields = (): ISelectFields => {
  const { t, terms } = useTypedTranslation();
  const fieldTerms = terms.order_card_dialog.fields;

  const orderReasonOptions: TSelectProps<IRfidCardOrderFields>['options'] = [
    {
      key: 'lost',
      content: t(fieldTerms.reason_options.lost),
      value: 'lost',
    },
    {
      key: 'defective',
      content: t(fieldTerms.reason_options.defective),
      value: 'defective',
    },
    {
      key: 'not-arrived',
      content: t(fieldTerms.reason_options.not_arrived),
      value: 'not-arrived',
    },
    {
      key: 'other',
      content: t(fieldTerms.reason_options.other),
      value: 'other',
    },
  ];

  return {
    country: {
      label: t(fieldTerms.country),
      name: 'country',
      labelId: 'country',
      options: countryCodeOptions,
      isRequired: true,
    },
    reason: {
      label: t(fieldTerms.reason),
      name: 'reason',
      labelId: 'reason',
      options: orderReasonOptions,
      isRequired: true,
    },
  };
};

export const getDefaultValues = (
  subscriber: ISubscriberModel,
  user: IUserModel | null
): Partial<IRfidCardOrderFields> => {
  const { address } = subscriber;
  const { first_name, last_name } = user || {};
  const name =
    first_name || last_name
      ? `${first_name} ${last_name}`
      : DEFAULT_VALUES.name;

  if (!address) {
    return { ...DEFAULT_VALUES, name };
  }

  return {
    zip: address.zip,
    city: address.city || DEFAULT_VALUES.city,
    country: address.country_code || DEFAULT_VALUES.country,
    reason: DEFAULT_VALUES.reason,
    address_line_1: address.address_line_1 || DEFAULT_VALUES.address_line_1,
    address_line_2: address.address_line_2 || DEFAULT_VALUES.address_line_2,
    name,
    state: address.state || DEFAULT_VALUES.state,
  };
};
