import React from 'react';
import { useTheme } from '@mui/material/styles';
import { PlugAndChargeEventType } from '../../../../types/plugAndCharge';
import {
  BasicStatus,
  IMappedStatus,
} from '../../../shared/StatusIndicator/BasicStatus';
import { assertNever } from '../../../../utils/assertNever';

export const PlugAndChargeEventTypeComponent: React.FC<{
  status: PlugAndChargeEventType;
}> = ({ status }) => {
  const theme = useTheme();

  function valueMapper(value: PlugAndChargeEventType): IMappedStatus {
    switch (value) {
      case 'ENABLE': {
        return {
          color: theme.colorAccent, //green - electric lime
          label: 'Enable',
        };
      }
      case 'DISABLE': {
        return {
          color: theme.colorAlertSecondary, //orange
          label: 'Disable',
        };
      }
      case 'FACTORY_RESET': {
        return {
          color: theme.colorAlertPrimary, //popular pink
          label: 'Factory Reset',
        };
      }
      default: {
        return assertNever(value);
      }
    }
  }

  return <BasicStatus status={status} valueMapper={valueMapper} />;
};
