import { ITerminateSubscriptionResponse } from '../../../services/subscriptions';
import * as ls from 'local-storage';

export type TerminationResultStore = {
  result: ITerminateSubscriptionResponse;
  timestamp: number;
}[];

export const MAX_STORED_TERMINATION_RESULTS = 200;

export const terminationResultStorageKey = 'terminateSubscriptionResults';

const dropOldestIfNeeded = (resultStore: TerminationResultStore) => {
  if (resultStore.length < MAX_STORED_TERMINATION_RESULTS) {
    return resultStore;
  }
  return [...resultStore].sort((x, y) => x.timestamp - y.timestamp).slice(1);
};

export const storeSubscriptionTerminationResult = (
  terminationResult: ITerminateSubscriptionResponse
): void => {
  const stored: TerminationResultStore =
    ls.get(terminationResultStorageKey) ?? [];

  ls.set(terminationResultStorageKey, [
    ...dropOldestIfNeeded(stored),
    {
      result: terminationResult,
      timestamp: new Date().getTime(),
    },
  ]);
};

export const getSubscriptionTerminationResult = (
  subscriptionId: string
): ITerminateSubscriptionResponse | null => {
  const stored: TerminationResultStore =
    ls.get(terminationResultStorageKey) ?? [];
  return (
    stored.find((res) => res.result.subscription_id === subscriptionId)
      ?.result ?? null
  );
};
