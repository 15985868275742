import makeStyles from '@mui/styles/makeStyles';

export const useStyles = makeStyles({
  componentWrapper: {
    position: 'relative',
    '&:hover': {
      '& $cardOverlay': {
        transform: 'scale(1)',
      },
    },
  },
  cardOverlay: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    position: 'absolute',
    top: '0',
    left: '0',
    height: '100%',
    width: '100%',
    background: 'rgb(255 255 255 / 77%)',
    paddingBottom: 20,
    transform: 'scale(0)',
  },
  detailButton: {
    border: '2px solid #6633cc',
    background: 'white',
    fontSize: 16,
    fontWeight: 600,
    color: '#6633cc',
    lineHeight: 1.5,
    height: 40,
    width: 100,
    cursor: 'pointer',
    outline: 'none',
  },
});
