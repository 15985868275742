export const TransactionType = {
  Invoice: 'Invoice',
  CreditNote: 'CreditNote',
} as const;
export type TransactionType = typeof TransactionType[keyof typeof TransactionType];

export const TransactionState = {
  Created: 'CREATED',
  TransactionReceived: 'TRANSACTION_RECEIVED',
  ChargeRecordOverviewAttached: 'CHARGE_RECORD_OVERVIEW_ATTACHED',
  MailSent: 'MAIL_SENT',
  Completed: 'COMPLETED',
} as const;
export type TransactionState = typeof TransactionState[keyof typeof TransactionState];
