import React from 'react';
import { Dialog, Grid } from '@mui/material';
import useStyles from './useStyles';
import { Typography } from '../../../shared/Typography/Typography';

export interface IOwnProps {
  open: boolean;
  onClose: (value: string) => void;
  cpoPartId: string;
}

export const CpoGroupDialog: React.FC<IOwnProps> = ({
  open,
  onClose,
  cpoPartId,
}) => {
  const classes = useStyles();
  return (
    <Dialog
      onClose={onClose}
      open={open}
      data-testid='cpo-group-dialog'
      PaperProps={{
        classes: {
          root: classes.dialog,
        },
      }}
    >
      <Grid container flexDirection={'column'} alignItems={'center'}>
        <Grid item>
          <Typography variant='h4'>Information</Typography>
        </Grid>
        <Grid item>
          <Typography variant='body2'>
            CPO Group used was {cpoPartId}.
          </Typography>
        </Grid>
      </Grid>
    </Dialog>
  );
};

export default CpoGroupDialog;
