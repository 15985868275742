import React from 'react';

import useSharedStyles from '../../../shared/useSharedStyles';
import { formatIsoDates } from '../../../../utils/dates';
import Tooltip from '../../../ui/Tooltip';
import CopyTextFieldList from '../../../ui/CopyTextField/CopyTextFieldList';
import { TOOLTIP_NAME } from '../Status/SubscriptionStatus';
import useStyles from './useStyles';
import { Typography } from '../../../shared/Typography/Typography';

export interface IOwnProps {
  startDate: string;
  subscriptionId?: string;
}

export const generateTooltipData = (subscriptionId?: string) => [
  {
    text: subscriptionId || '-',
    title: 'Subscription ID',
  },
];

export const SIGNUP_DATE_TEST_ID = 'signup-date-id';

const SignupDate: React.FC<IOwnProps> = ({ startDate, subscriptionId }) => {
  const sharedClasses = useSharedStyles();
  const classes = useStyles();
  return (
    <div className={sharedClasses.cardDetail}>
      <Typography variant='h6' className={classes.headingWithIcon}>
        Start Date
        {
          <Tooltip tooltipName={TOOLTIP_NAME}>
            <CopyTextFieldList textList={generateTooltipData(subscriptionId)} />
          </Tooltip>
        }
      </Typography>
      <div
        className={sharedClasses.cardDetailData}
        data-testid={SIGNUP_DATE_TEST_ID}
      >
        <Typography variant='body2'>{formatIsoDates(startDate)}</Typography>
      </div>
    </div>
  );
};

export default SignupDate;
