import ApiService from './api';

export interface ICCUserInfoResponse {
  country: string;
  email: string;
  iamId: string;
  tenantId: string;
}

export const getCCUserInfo = async (): Promise<ICCUserInfoResponse> => {
  const apiService = new ApiService('/identity/v1/userinfo');
  const response = await apiService.query();
  const { iamId, country, email, tenantId } = response.data;
  return {
    iamId,
    country,
    email,
    tenantId,
  };
};

export const CCUserInfoService = {
  getCCUserInfo,
};
