import React from 'react';
import useStyles from './useStyles';

interface IOwnProps {
  status?: string;
}

export const Status: React.FC<IOwnProps> = ({ status }) => {
  const classes = useStyles();

  const getClassList = (): string => {
    const classList = [classes.indicator];

    if (status && status.toLowerCase() === 'connected') {
      classList.push(classes.statusGreen);
    }

    return classList.join(' ');
  };

  return <div data-testid='status-icon' className={getClassList()}></div>;
};
