import React, { useState } from 'react';
import ContactDetailsIcon from '../../../../assets/image/contactDetails.svg';
import ChangeUserProfileForm from './ChangeUserProfileForm';
import { useTypedTranslation } from '../../../../custom-hooks/useTypedTranslation';
import { IUserModel } from '../../../../types/user';
import SuccessMessage from '../../../shared/Dialogs/SuccessMessage';
import connector, { IPropsFromState } from '../../../Connector/Connector';

const ChangeUserProfileModal: React.FC<IPropsFromState> = ({
  userState,
  updateUserProfile,
}) => {
  const { t, terms } = useTypedTranslation();
  const { data, error } = userState;
  const { first_name, last_name, display_name, id } = data as IUserModel;
  const [isSuccess, setIsSuccess] = useState<boolean>(false);
  const handleSuccess = () => setIsSuccess(true);

  if (isSuccess) {
    return (
      <SuccessMessage
        headline={t(terms.update_user_profile.success.headline)}
        subHeadline={t(terms.update_user_profile.success.sub_headline)}
        icon={ContactDetailsIcon}
        iconAltText={t(terms.update_user_profile.success.headline)}
      />
    );
  }

  return (
    <ChangeUserProfileForm
      userFirstName={first_name}
      userLastName={last_name}
      userDisplayName={display_name}
      userId={id}
      onSuccess={handleSuccess}
      submitError={!!error}
      updateUserProfile={updateUserProfile}
    />
  );
};

export default connector(ChangeUserProfileModal);
