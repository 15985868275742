import React, { ButtonHTMLAttributes } from 'react';
import { Button, CircularProgress } from '@mui/material';

import useStyles from './useStyles';
import { useFormContext } from 'react-hook-form';
import { Typography } from '../../Typography/Typography';

interface IOwnProps {
  mainText: string | React.ReactElement;
  handleClick?: () => void;
  isDisabled?: boolean;
  smallText?: string;
  buttonType?: ButtonHTMLAttributes<HTMLButtonElement>['type'];
  inProgress?: boolean;
  className?: string;
  actionType?: 'primary' | 'secondary';
}

const MultilineButton: React.FC<IOwnProps> = ({
  handleClick,
  mainText,
  isDisabled,
  smallText,
  buttonType = 'submit',
  inProgress,
  className,
  actionType = 'primary',
}) => {
  const classes = useStyles();
  const formContext = useFormContext();

  let isFormDisabled = false;
  if (buttonType === 'submit' && formContext) {
    const {
      formState: { isValid, isSubmitting },
    } = formContext;

    isFormDisabled = !isValid || isSubmitting;
  }

  const mainTextStyles = smallText
    ? [classes.withSmallButtonText, classes.mainButtonText].join(' ')
    : classes.mainButtonText;

  const isPrimaryType = actionType === 'primary';

  return (
    <Button
      color={isPrimaryType ? 'secondary' : 'primary'}
      variant={isPrimaryType ? 'contained' : 'outlined'}
      classes={{
        root: classes.buttonStyling,
      }}
      onClick={handleClick}
      disabled={isDisabled || isFormDisabled}
      className={className}
      type={buttonType}
    >
      {inProgress ? (
        <CircularProgress
          aria-label='loadingSpinner'
          className={classes.circularProgress}
          size='16px'
          color='inherit'
        />
      ) : null}

      <div className={inProgress ? classes.textContainerHidden : ''}>
        <div className={mainTextStyles}>
          <Typography>{mainText}</Typography>
          {smallText && (
            <Typography className={classes.smallButtonText}>
              {smallText}
            </Typography>
          )}
        </div>
      </div>
    </Button>
  );
};

export default MultilineButton;
