import makeStyles from '@mui/styles/makeStyles';
import createStyles from '@mui/styles/createStyles';

const useStyles = makeStyles(() =>
  createStyles({
    addressElement: {
      marginTop: '10px',
    },
    clickableText: {
      display: 'block',
      whiteSpace: 'nowrap',
      overflow: 'hidden',
      textOverflow: 'ellipsis',
      textDecoration: 'underline',
      fontSize: '14px',
      width: '200px',

      '&:hover': {
        cursor: 'pointer',
      },
    },
    chargingLocationTitle: {
      marginTop: '8px !important',
    },
    itemValue: {
      width: '200px',
      display: 'block',
      whiteSpace: 'nowrap',
      overflow: 'hidden',
      textOverflow: 'ellipsis',
      fontSize: '14px',
    },
    itemText: {
      display: 'block',
      whiteSpace: 'nowrap',
      overflow: 'hidden',
      textOverflow: 'ellipsis',
    },
  })
);

export default useStyles;
