import React, { useEffect, useState } from 'react';

import { CardOrderStateModel } from '../../../store/state/cardorders';
import { ChargingSessionsStateModel } from '../../../store/state/chargingSessions';
import { IBreadcrumbHandlerProps } from '../../../utils/addBreadcrumbHandler';
import { IChargingCardModel } from '../../../store/state/chargingCards';
import { InvoiceStateModel } from '../../../store/state/invoices';
import { SubscriptionStateModel } from '../../../store/state/subscription';
import {
  createActionButtons,
  IActionButtonConfig,
} from '../../shared/ActionBar/cardButtonFactory';

interface IOwnProps {
  subscription: SubscriptionStateModel;
  chargingCards: IChargingCardModel;
  chargingSessions: ChargingSessionsStateModel;
  orders: CardOrderStateModel;
  invoices: InvoiceStateModel;
  breadcrumbHandler: (props: IBreadcrumbHandlerProps) => void;
}

const ActionButtons: React.FC<IOwnProps> = ({
  subscription,
  chargingCards,
  chargingSessions,
  orders,
  invoices,
  breadcrumbHandler,
}) => {
  const [subscriptionsnumber, setSubscriptionsnumber] = useState<number>(0);
  const [cardOrdersnumber, setCardOrdersnumber] = useState<number>(0);
  const [invoicesnumber, setInvoicesnumber] = useState<number>(0);
  const [chargingCardsnumber, setChargingCardsnumber] = useState<number>(0);
  const [chargingSessionsnumber, setChargingSessionsnumber] = useState<number>(
    0
  );

  useEffect(() => {
    if (chargingSessions && Array.isArray(chargingSessions.data)) {
      const publicSessions = chargingSessions.data.filter(
        (session) => session.type === 'public'
      );
      setChargingSessionsnumber(publicSessions.length);
    }

    if (chargingCards && Array.isArray(chargingCards.data)) {
      setChargingCardsnumber(chargingCards.data.length);
    }

    if (orders && Array.isArray(orders.data)) {
      setCardOrdersnumber(orders.data.length);
    }

    if (subscription && Array.isArray(subscription.data)) {
      setSubscriptionsnumber(subscription.data.length);
    }

    if (invoices && Array.isArray(invoices.data)) {
      setInvoicesnumber(invoices.data.length);
    }
  }, [chargingSessions, chargingCards, orders, subscription, invoices]);

  const actionButtonsConfig: IActionButtonConfig[] = [
    {
      type: 'Subscriptions',
      number: subscriptionsnumber,
      breadcrumbHandler,
    },
    {
      type: 'ChargingCards',
      number: chargingCardsnumber,
      breadcrumbHandler,
    },
    {
      type: 'PublicChargingSessions',
      number: chargingSessionsnumber,
      breadcrumbHandler,
    },
    {
      type: 'Orders',
      number: cardOrdersnumber,
      breadcrumbHandler,
    },
    {
      type: 'Invoices',
      number: invoicesnumber,
      breadcrumbHandler,
    },
  ];

  return (
    <React.Fragment>{createActionButtons(actionButtonsConfig)}</React.Fragment>
  );
};

export default ActionButtons;
