import React from 'react';
import CloseIcon from '@mui/icons-material/Close';
import Dialog from '@mui/material/Dialog';
import IconButton from '@mui/material/IconButton';

import {
  DialogContextState,
  IDialogContextState,
} from '../context/ActionDialogContext';
import useStyles from './useStyles';

export interface IOwnProps {
  isOpen: boolean;
  onClose: (input?: unknown) => unknown;
  isFullWidth?: boolean;
}

/**
 * Presentational component for popup dialog. Logic is provided by wrapping component container
 */
const DialogContainer: React.FC<IOwnProps> = ({
  isOpen,
  onClose,
  children,
  isFullWidth,
}) => {
  const classes = useStyles();
  const context: IDialogContextState = {
    handleCloseDialog: onClose,
  };

  return (
    <Dialog
      onClose={onClose}
      open={isOpen}
      scroll='paper'
      PaperProps={{
        classes: {
          root: isFullWidth ? classes.wideDialog : classes.narrowDialog,
        },
      }}
    >
      <>
        <IconButton
          classes={{
            root: classes.closeButton,
          }}
          data-testid='dialog-container-close'
          onClick={onClose}
          size='large'
        >
          <CloseIcon />
        </IconButton>
        <DialogContextState.Provider value={context}>
          {children}
        </DialogContextState.Provider>
      </>
    </Dialog>
  );
};

export default DialogContainer;
