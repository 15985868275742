import update from 'immutability-helper';
import { State } from '../';
import { ActionInterface } from '../actions';

export default (
  state: State,
  action: ActionInterface.UpdateUserSuccess
): State => {
  const { firstName, lastName } = action;
  const currentUser = state;
  const updatedUser = update(currentUser, {
    data: {
      first_name: {
        $set: firstName,
      },
      last_name: {
        $set: lastName,
      },
    },
    loading: {
      $set: false,
    },
  });

  return {
    ...state,
    ...updatedUser,
  };
};
