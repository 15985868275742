import React from 'react';
import useStyles from './useStyles';
import { useTheme } from '@mui/styles';
import { CheckCircle } from '@mui/icons-material';
import Exclaim from '../../../../../../assets/image/exclaim.svg';
import { Skeleton } from '@mui/material';

interface IFlowColumnDestinationCircleProps {
  icon: JSX.Element;
  isHappyState: boolean;
  isLoading: boolean;
  testId: string;
}

export const FlowColumnDestinationCircle: React.FC<IFlowColumnDestinationCircleProps> = ({
  icon,
  isHappyState,
  isLoading,
  testId,
}) => {
  const theme = useTheme();
  const classes = useStyles(theme);

  return isLoading ? (
    <Skeleton
      variant='circular'
      height={96}
      width={96}
      data-testid={testId + '-dest-loading'}
    />
  ) : (
    <div
      className={`${classes.destinationCircle} ${
        isHappyState ? classes.circleHappy : classes.circleSad
      }`}
    >
      <div
        className={`${classes.destinationCircleIcon} ${
          isHappyState
            ? classes.destinationCircleIconHappy
            : classes.destinationCircleIconSad
        }`}
      >
        {icon}
      </div>
      {isHappyState ? (
        <CheckCircle
          className={`${classes.destinationCircleRingIcon} ${classes.destinationCircleRingIconHappy}`}
          data-testid={testId + '-dest-happy'}
        />
      ) : (
        <img
          src={Exclaim}
          alt='exclaim'
          className={`${classes.destinationCircleRingIcon} ${classes.destinationCircleRingIconSad}`}
          data-testid={testId + '-dest-sad'}
        />
      )}
    </div>
  );
};
