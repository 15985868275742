import { Theme } from '@mui/material';

import makeStyles from '@mui/styles/makeStyles';
import createStyles from '@mui/styles/createStyles';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    closeButton: {
      position: 'absolute',
      right: theme.spacing(1),
      top: theme.spacing(1),
      color: theme.textPrimary,
    },
    dataBoxGrey: { backgroundColor: theme.palette.grey['200'], padding: '1em' },
    dialog: {
      padding: 48,
      minWidth: 350,
    },
  })
);

export default useStyles;
