import { ActionType, ActionInterface } from '../actions';
import initialState, { State } from '..';
import openVerificationFlow from './openVerificationFlow';
import setVerificationStep from './setVerificationStep';

const reducer = (state: State = initialState, action: any) => {
  switch (action.type) {
    case ActionType.SetVerificationStep:
      return setVerificationStep(
        state,
        action as ActionInterface.SetVerificationStep
      );
    case ActionType.OpenVerificationFlow:
      return openVerificationFlow(
        state,
        action as ActionInterface.OpenVerificationFlow
      );
    default:
      return state;
  }
};

export default reducer;
