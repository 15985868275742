import { ActionInterface, ActionType } from '../actions';
import initialState, { State } from '..';

import getChargingStationError from './getChargingStationError';
import getChargingStationRequest from './getChargingStationRequest';
import getChargingStationSuccess from './getChargingStationSuccess';
import getNearbyLocationsRequest from './getNearbyLocationsRequest';
import getNearbyLocationsSuccess from './getNearbyLocationsSuccess';

const reducer = (state: State = initialState, action: any) => {
  switch (action.type) {
    case ActionType.GetChargingStationRequest:
      return getChargingStationRequest(
        state,
        action as ActionInterface.GetChargingStationRequest
      );
    case ActionType.GetChargingStationSuccess:
      return getChargingStationSuccess(
        state,
        action as ActionInterface.GetChargingStationSuccess
      );
    case ActionType.GetChargingStationError:
      return getChargingStationError(
        state,
        action as ActionInterface.GetChargingStationError
      );
    case ActionType.GetNearbyLocationsRequest:
      return getNearbyLocationsRequest(
        state,
        action as ActionInterface.GetNearbyLocationsRequest
      );
    case ActionType.GetNearbyLocationsSuccess:
      return getNearbyLocationsSuccess(
        state,
        action as ActionInterface.GetNearbyLocationsSuccess
      );
    default:
      return state;
  }
};

export default reducer;
