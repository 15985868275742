import makeStyles from '@mui/styles/makeStyles';

const useStyles = makeStyles({
  outerWrapper: {
    display: 'flex',
    flexDirection: 'column',
    margin: -16,

    '&> *': {
      margin: 16,
    },
  },

  form: {
    width: 440,
    display: 'flex',
    flexDirection: 'column',
  },

  submitWrapper: {
    display: 'flex',
    justifyContent: 'center',
    position: 'relative',
  },

  errorMessage: {
    position: 'absolute',
    left: '50%',
    bottom: 0,
    transform: 'translate(-50%, 100%)',
  },
});

export default useStyles;
