import React from 'react';
import CheckIcon from '@mui/icons-material/Check';
import { CircularProgress } from '@mui/material';
import PanoramaFishEyeIcon from '@mui/icons-material/PanoramaFishEye';
import { StoreDataModel } from '../LoadingScreen';
import useStyles from './useStyles';

type IProps = {
  data: StoreDataModel;
};

const LoadingState: React.FC<IProps> = ({ data }) => {
  const classes = useStyles();

  if (data && data.data && Object.keys(data.data).length >= 1) {
    return (
      <CheckIcon
        data-testid='check-icon'
        style={{ fill: '#6633cc', fontSize: 24 }}
      />
    );
  }

  if (
    (data && data.error) ||
    (data && data.data && Object.keys(data.data).length === 0) ||
    data === null
  ) {
    return (
      <PanoramaFishEyeIcon
        data-testid='fisheye-icon'
        style={{ fill: 'rgba(0, 0, 0, 0.54)', fontSize: 24 }}
      />
    );
  }

  return (
    <div
      style={{
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        width: 24,
        height: 24,
      }}
    >
      <CircularProgress classes={{ root: classes.loadingCircle }} size={16} />
    </div>
  );
};

export default LoadingState;
