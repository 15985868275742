import React, { useEffect, useState } from 'react';
import { CircularProgress, List } from '@mui/material';
import connector, { IPropsFromState } from '../Connector/Connector';
import { ISubscriberModel } from '../../types/subscriber';
import { SubscriberStateModel } from '../../store/state/subscriber';
import { IUserModel } from '../../types/user';
import { VerificationQuestionsItem } from './VerficationQuestionItem/VerificationQuestionsItem';
import useStyles from './useStyles';

const setSubscriberQuestions = (
  subscriberData: ISubscriberModel,
  arr: IQuestion[]
): void => {
  const addressLineOne = subscriberData?.address?.address_line_1 || '';
  const addressLineTwo = subscriberData?.address?.address_line_2 || '';
  if (subscriberData.address) {
    arr.push({
      id: 2,
      q: 'Billing address in active subscription',
      a: `${addressLineOne} ${addressLineTwo}, ${subscriberData.address.city}, ${subscriberData.address.zip}, ${subscriberData.address.country_code}`,
      isSelected: false,
    });
  }
};

const createDataArray = (
  user: IUserModel | null,
  subscriber: SubscriberStateModel
): IQuestion[] => {
  const arr: IQuestion[] = [];

  if (user) {
    if (user.first_name && user.last_name) {
      arr.push({
        id: 0,
        q: 'Full Name',
        a: `${user.first_name} ${user.last_name}`,
        isSelected: false,
      });
    }

    arr.push({
      id: 1,
      q: 'Email Address',
      a: `${user.email}`,
      isSelected: true,
    });
  }

  if (subscriber && subscriber.searchData) {
    setSubscriberQuestions(subscriber.searchData, arr);
  }

  return arr;
};

type IListProps = IPropsFromState & {
  setProgress: (d: number) => void;
};

interface IQuestion {
  id: number;
  q: string;
  a: string;
  isSelected: boolean;
}

export const VerificationQuestionsList: React.FC<IListProps> = ({
  setProgress,
  removeBreadcrumb,
  breadcrumbState,
  userSearchState,
  subscriberState,
}) => {
  const classes = useStyles();

  const userSearch = userSearchState;
  const user = userSearch.selectedUser;
  const subscriber = subscriberState;

  const questions = createDataArray(user, subscriber);
  const [data, setData] = useState(questions);

  useEffect(() => {
    const questionsArr = createDataArray(user, subscriber);
    setData(questionsArr);
  }, [user, subscriber]);

  useEffect(() => {
    if (data.every((item: IQuestion) => item.isSelected)) {
      setProgress(100);
      if (breadcrumbState && breadcrumbState.breadcrumbs) {
        removeBreadcrumb({ position: 0 });
      }
    }
  }, [data]);

  const selectField = (index: number): void => {
    setData(
      data.map((item: IQuestion) =>
        item.id === index ? { ...item, isSelected: !item.isSelected } : item
      )
    );
  };

  return subscriber && subscriber.loading ? (
    <CircularProgress />
  ) : (
    <List className={classes.rootContainer} data-testid='question-list'>
      {data.map((item: IQuestion) => (
        <VerificationQuestionsItem
          selectField={selectField}
          data-testid='verification-list'
          key={item.a}
          data={item}
        />
      ))}
    </List>
  );
};

export default connector(VerificationQuestionsList);
