import React from 'react';
import { useTheme } from '@mui/material/styles';
import { FirmwareDecision } from '../../../../../types/user';
import {
  BasicStatus,
  IMappedStatus,
} from '../../../../shared/StatusIndicator/BasicStatus';
import { assertNever } from '../../../../../utils/assertNever';

interface IOwnProps {
  status: FirmwareDecision;
}

export const ConsentStatus: React.FC<IOwnProps> = ({ status }) => {
  const theme = useTheme();

  function valueMapper(value: FirmwareDecision): IMappedStatus {
    switch (value) {
      case 'accepted':
        return { color: theme.colorAccent, label: 'Accepted' };
      case 'postponed':
        return { color: theme.textSecondary, label: 'Postponed' };
      case 'declined':
        return { color: theme.colorAlertPrimary, label: 'Declined' };
      default:
        return assertNever(value);
    }
  }

  return <BasicStatus status={status} valueMapper={valueMapper} />;
};
