import axios, { AxiosError, AxiosRequestConfig, AxiosResponse } from 'axios';

import ErrorService from './error';
import { config as appConfig } from '../config';

import { auth0 } from './auth0';

interface IQueryOptions {
  method?: 'GET' | 'PUT' | 'PATCH' | 'POST' | 'DELETE';
  data?: any;
  headers?: any;
  timeout?: number;
  responseType?:
    | 'arraybuffer'
    | 'blob'
    | 'document'
    | 'json'
    | 'text'
    | 'stream';
}

export default class ApiService {
  url: string;
  options: IQueryOptions;

  private buildUrl(baseUrl: string, path: string): string {
    // Remove trailing slash(es)
    const sanitizedBaseUrl = baseUrl.replace(/\/+$/, '');
    // Remove leading slash(es)
    const sanitizedPath = path.replace(/^\/+/, '');

    return `${sanitizedBaseUrl}/${sanitizedPath}`;
  }

  constructor(path: string, options: IQueryOptions = {}) {
    this.url = this.buildUrl(appConfig.elliBaseUrl, path);
    this.options = options;
  }

  /**
   * Handles REST-API queries
   * @throws axios error
   */
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  async query<T = any>(
    { refresh } = { refresh: false }
  ): Promise<AxiosResponse<T>> {
    try {
      const { method = 'GET', data = null } = this.options;
      let queryString = '';

      if (method === 'GET' && data) {
        queryString = `?${this.getQueryString(data)}`;
      }

      const bearerToken = await auth0.getAccessTokens();

      // if no bearer then get one
      const headers = this.options.headers || {};

      Object.assign(headers, {
        ...(bearerToken ? { Authorization: `Bearer ${bearerToken}` } : null),
      });

      const config: AxiosRequestConfig = {
        url: this.url + queryString,
        method,
        headers,
        data,
        timeout: this.options.timeout,
      };

      if (this.options.responseType) {
        config.responseType = this.options.responseType;
      }

      return await axios.request<T>(config);
    } catch (error) {
      const errorResponse = (error as AxiosError)?.response;
      if (!refresh && errorResponse && errorResponse.data.errors) {
        const errorService = new ErrorService(errorResponse.data.errors);
        if (errorService.shouldTokenRefresh()) {
          return this.query({ refresh: true });
        }
      }

      throw error;
    }
  }

  getQueryString(data: Record<string, any>): string {
    const params = new URLSearchParams(data);
    return params.toString();
  }
}
