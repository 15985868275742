import React from 'react';
import MenuItem from '@mui/material/MenuItem';
import Select, { SelectChangeEvent } from '@mui/material/Select';
import useStyles from './useStyles';

interface IOwnProps {
  setItemsPerPage: React.Dispatch<React.SetStateAction<number>>;
  itemsPerPage: number;
  itemSelectText: string;
  setCurrentPage: React.Dispatch<React.SetStateAction<number>>;
}

export const ItemSelect: React.FC<IOwnProps> = ({
  setItemsPerPage,
  itemsPerPage,
  itemSelectText,
  setCurrentPage,
}) => {
  const classes = useStyles();

  const handleChange = (event: SelectChangeEvent<number>) => {
    setItemsPerPage(event.target.value as number);
    setCurrentPage(1);
  };

  return (
    <div
      className={classes.itemSelectContainer}
      data-testid='select-number-container'
    >
      <span className={classes.itemSelectText}>{itemSelectText}:</span>
      <Select
        id='items-per-page-select'
        value={itemsPerPage}
        onChange={handleChange}
        classes={{
          icon: classes.selectIcon,
        }}
      >
        <MenuItem value={10}>10</MenuItem>
        <MenuItem data-testid='menu-item-20' value={20}>
          20
        </MenuItem>
        <MenuItem value={30}>30</MenuItem>
        <MenuItem value={50}>50</MenuItem>
      </Select>
    </div>
  );
};

export default ItemSelect;
