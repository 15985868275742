import update from 'immutability-helper';
import { State } from '..';
import { ActionInterface } from '../actions';
import serializeError from 'serialize-error';

export default (
  state: State,
  action: ActionInterface.GetCardOrderError
): State => {
  const { error } = action;

  const currentCardOrder = state || {
    loading: true,
    data: null,
    error: null,
    request: null,
  };

  const updatedCardOrder = update(currentCardOrder, {
    error: {
      $set: serializeError(error),
    },
    loading: {
      $set: false,
    },
    data: {
      $set: null,
    },
  });

  return {
    ...state,
    ...updatedCardOrder,
  };
};
