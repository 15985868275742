import React from 'react';
import { Grid, Button } from '@mui/material';
import Search from '../../Search/Search';
import useStyles from './useStyles';
import { useTypedTranslation } from '../../../custom-hooks/useTypedTranslation';
import { Typography } from '../../shared/Typography/Typography';

interface IOwnProps {
  isUser: boolean;
  setStep: (x: number) => void;
  emailFromURL?: string;
  setProgress: (x: number) => void;
}

export const UserSearch: React.FC<IOwnProps> = ({
  isUser,
  setStep,
  emailFromURL,
  setProgress,
}) => {
  const classes = useStyles();
  const { t, terms } = useTypedTranslation();

  React.useEffect(() => {
    setProgress(1);
  }, []);

  return (
    <>
      <Grid item={true}>
        <div className={classes.dialogTitle}>
          <Typography variant='h1'>
            {t(terms.customer_verification.user_search.title)}
          </Typography>
        </div>
        <div className={classes.subTitle}>
          <Typography variant='h3'>
            {t(terms.customer_verification.user_search.subtitle)}
          </Typography>
        </div>
      </Grid>
      <Grid item={true}>
        <Search emailFromURL={emailFromURL} />
      </Grid>
      <Grid item={true}>
        <div className={classes.buttonContainer}>
          <Button
            data-testid='verification-flow-button-next'
            variant='outlined'
            color='primary'
            disabled={!isUser}
            onClick={() => {
              setStep(2);
            }}
            classes={{
              root: classes.buttonStyling,
            }}
          >
            {t(terms.customer_verification.user_search.continue_button)}
          </Button>
        </div>
      </Grid>
    </>
  );
};
