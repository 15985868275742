import makeStyles from '@mui/styles/makeStyles';
import createStyles from '@mui/styles/createStyles';

const useStyles = makeStyles(() =>
  createStyles({
    customZoomWrapper: {
      position: 'absolute',
      bottom: '16px',
      right: '16px',
      backgroundColor: '#ffffff',
      display: 'flex',
      flexDirection: 'column',
    },

    zoomIconWrapper: {
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      width: '40px',
      height: '40px',
      cursor: 'pointer',
    },
    zoomIcon: {
      fontSize: '24px !important',
      color: '#6633cb !important',
    },
  })
);

export default useStyles;
