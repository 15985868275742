import React, { useEffect, useState } from 'react';
import connector, { IPropsFromState } from '../../../../Connector/Connector';
import { ICircleState } from '../index';
import { FlowColumnDiagram } from '../FlowColumnDiagram/FlowColumnDiagram';
import { useTariffsQuery } from '../../../../../state/queries/tariffs';
import {
  defaultPncEnabledCircleState,
  defaultSignedUpCircleState,
  defaultTariffEligibleForPncCircleState,
} from './defaultCircleStates';

export const PlugAndChargeReadinessDiagram: React.FC<IPropsFromState> = ({
  subscriberState,
  subscriptionState,
}) => {
  const tariffs = useTariffsQuery();
  const [isLoading, setIsLoading] = useState(true);

  const [signedUpState, setSignedUpState] = useState<ICircleState>(
    defaultSignedUpCircleState
  );
  const [
    tariffEligibilityForPncState,
    setTariffEligibilityForPncState,
  ] = useState<ICircleState>(defaultTariffEligibleForPncCircleState);
  const [pncEnabledState, setPncEnabledState] = useState<ICircleState>(
    defaultPncEnabledCircleState
  );

  useEffect(() => {
    setIsLoading(
      subscriptionState.loading || subscriberState.loading || tariffs.isLoading
    );
  }, [subscriptionState, subscriberState, tariffs]);

  useEffect(() => {
    if (subscriptionState.data) {
      const activeSubscription = subscriptionState.data.find(
        (sub) => sub.status === 'active'
      );
      if (activeSubscription) {
        setSignedUpState({ ...signedUpState, circleState: 'happy' });
        const currentTariff = (tariffs.data || []).find(
          ({ id }) => id === activeSubscription.tariff_id
        );
        setTariffEligibilityForPncState(
          currentTariff?.plug_and_charge_capable
            ? { ...tariffEligibilityForPncState, circleState: 'happy' }
            : { ...tariffEligibilityForPncState, circleState: 'sad' }
        );
      } else {
        setSignedUpState({ ...signedUpState, circleState: 'sad' });
        setTariffEligibilityForPncState({
          ...tariffEligibilityForPncState,
          circleState: 'sad',
        });
      }
    }
  }, [subscriptionState]);

  useEffect(() => {
    if (subscriberState.data?.plug_and_charge.state === 'ENABLED') {
      setPncEnabledState({ ...pncEnabledState, circleState: 'happy' });
    } else {
      setPncEnabledState({ ...pncEnabledState, circleState: 'sad' });
    }
  }, [subscriberState.data]);

  const [circles, setCircles] = useState<ICircleState[]>([
    signedUpState,
    tariffEligibilityForPncState,
    pncEnabledState,
  ]);
  useEffect(() => {
    if (isLoading) {
      setCircles([
        { ...signedUpState, circleState: 'loading' },
        { ...tariffEligibilityForPncState, circleState: 'loading' },
        { ...pncEnabledState, circleState: 'loading' },
      ]);
    } else {
      setCircles([
        signedUpState,
        tariffEligibilityForPncState,
        pncEnabledState,
      ]);
    }
  }, [signedUpState, tariffEligibilityForPncState, pncEnabledState, isLoading]);

  const title = 'Plug & Charge';
  const pncIcon: JSX.Element = (
    <svg viewBox='0 0 24 24' fill='none' xmlns='http://www.w3.org/2000/svg'>
      <path d='M19.919 8.85701C19.9077 8.77812 19.8403 8.71987 19.7606 8.71987L12.0001 8.71973C11.293 8.71973 10.7197 9.29295 10.7197 10.0001C10.7197 10.7072 11.293 11.2804 12.0001 11.2804H19.7606C19.8402 11.2804 19.9078 11.222 19.919 11.1432C19.9724 10.7699 20.0001 10.3881 20.0001 10.0001C20.0001 9.61196 19.9724 9.23033 19.919 8.85701V8.85701Z' />
      <path d='M12 15.4268C9.00285 15.4268 6.57317 12.9972 6.57317 10C6.57317 7.00284 9.00285 4.57317 12 4.57317C13.6471 4.57317 15.1228 5.30693 16.118 6.46539C16.1744 6.53102 16.2728 6.53998 16.34 6.48553L18.0917 5.06705C18.1251 5.03997 18.1463 5.00056 18.1503 4.95773C18.1543 4.91489 18.1409 4.87225 18.1132 4.83938C16.6457 3.10279 14.4516 2 12 2C7.58172 2 4 5.58172 4 10V21.84C4 21.9284 4.07164 22 4.16 22H6.41317C6.50153 22 6.57317 21.9284 6.57317 21.84V16.0573C6.57317 15.9415 6.61939 15.9207 6.70613 15.9974C8.1167 17.2435 9.96988 18 12 18C14.4516 18 16.6457 16.8972 18.1132 15.1606C18.1409 15.1278 18.1543 15.0851 18.1503 15.0423C18.1462 14.9995 18.1251 14.96 18.0917 14.933L16.34 13.5145C16.2727 13.46 16.1744 13.4689 16.118 13.5346C15.1228 14.6931 13.6471 15.4268 12 15.4268Z' />
    </svg>
  );

  return (
    <FlowColumnDiagram
      title={title}
      titleIcon={pncIcon}
      circles={circles}
      testId='pnc-charging-readiness-diagram'
    />
  );
};

export default connector(PlugAndChargeReadinessDiagram);
