import React from 'react';
import Dialog from '@mui/material/Dialog';
import useStyles from './useStyles';
import { Typography } from '../../../../shared/Typography/Typography';

export interface IOwnProps {
  open: boolean;
  onClose: (value: string) => void;
}

export const LifecycleStateDialog: React.FC<IOwnProps> = ({
  open,
  onClose,
}) => {
  const classes = useStyles();

  return (
    <Dialog
      onClose={onClose}
      open={open}
      data-testid='lifecycle-state-dialog'
      PaperProps={{
        classes: {
          root: classes.dialog,
        },
      }}
    >
      <div className={classes.textWrapper}>
        <div className={classes.section}>
          <span className={classes.title}>Lifecycle state: NEW</span>
          <Typography variant='body2'>
            The station has been manufactured and is registered in Elli’s IT
            systems. The station is ready for installation and onboarding.
          </Typography>
        </div>
        <div className={classes.section}>
          <span className={classes.title}>Lifecycle state: PENDING</span>
          <Typography variant='body2'>
            The station has established a connection to the internet at least
            once. It is now in a state waiting to be linked to a user’s account
            (“claiming”).
          </Typography>
        </div>
        <div className={classes.section}>
          <span className={classes.title}>Lifecycle state: ACTIVATED</span>
          <Typography variant='body2'>
            The station is installed, went through onboarding and is now linked
            to a user’s account (“claimed”). Unlinking it will move the station
            to lifecycle state PENDING.
          </Typography>
        </div>
        <div className={classes.section}>
          <span className={classes.title}>Lifecycle state: RETIRED</span>
          <Typography variant='body2'>
            The station has been taken out of service. Any attempt of the
            station to connect to the internet will be blocked.
          </Typography>
        </div>
      </div>
    </Dialog>
  );
};
