import React from 'react';
import { useTheme } from '@mui/material/styles';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import useSharedStyles from '../../../shared/useSharedStyles';
import {
  ISubscriptionModel,
  getSubscriptionStatus,
} from '../../../../types/subscriber';
import useStyles from './useStyles';
import { SubscriptionStatusComponent } from '../../../SubscriptionStatus/SubscriptionStatusComponent';
import { SubscriptionStatusLabel } from '../../../shared/Subscription/SubscriptionStatusLabel';

export interface IOwnProps {
  subscription?: ISubscriptionModel;
  onInfoButtonClick?: () => void;
  showInfoButton?: boolean;
}

export const TOOLTIP_NAME = 'subscription-info';

const SubscriptionStatus: React.FC<IOwnProps> = ({
  subscription,
  onInfoButtonClick,
  showInfoButton,
}) => {
  const theme = useTheme();
  const classes = useStyles(theme);
  const sharedClasses = useSharedStyles();
  return (
    <div className={sharedClasses.cardDetail}>
      <SubscriptionStatusLabel subscription={subscription} />
      <div className={sharedClasses.cardDetailData}>
        {subscription?.status ? (
          <>
            <SubscriptionStatusComponent
              status={getSubscriptionStatus(subscription)}
              statusTypeForTestId='subscription'
            />
            {showInfoButton ? (
              <InfoOutlinedIcon
                data-testid='subscription-status-info'
                className={classes.subscriptionStatusInfo}
                onClick={onInfoButtonClick}
              />
            ) : null}
          </>
        ) : (
          '-'
        )}
      </div>
    </div>
  );
};

export default SubscriptionStatus;
