import {
  IChargingRecord,
  IChargingRecordViewModel,
} from '../../../types/chargingRecords';

import { IChargingSessionModel } from '../../../types/chargingSession';
import { IRFIDCardModel } from '../../../types/subscriber';
import { ISortState } from '../../../components/shared/AssetTable/AssetTable/AssetTable';
/* eslint-disable @typescript-eslint/naming-convention */
import { actionCreator } from '../../type-utils';

export const ActionType = {
  GetChargingSessionsRequest: 'chargingsessions/get-chargingsessions-request',
  GetChargingSessionsSuccess: 'chargingsessions/get-chargingsessions-success',
  GetChargingSessionsError: 'chargingsessions/get-chargingsessions-error',
  ClearChargingSessions: 'chargingsessions/clear-chargingsessions',
  SortChargingSessions: 'chargingsessions/sort-chargingsessions',
};

export declare namespace ActionInterface {
  export interface GetChargingSessionsRequest {
    type: string;
    subscriber_id: string;
    organization_id: string;
    user_id: string;
  }

  export interface GetChargingSessionsSuccess {
    type: string;
    data: IChargingRecordViewModel[];
    private: IChargingRecord[];
    public: IChargingSessionModel[];
    organization: IChargingRecord[];
  }

  export interface GetChargingSessionsError {
    type: string;
    error: Error;
  }

  export interface ClearChargingSessions {
    type: string;
  }

  export interface SortChargingSessions {
    type: string;
    sortState: ISortState;
    chargingCards: IRFIDCardModel[];
  }
}

export const ActionCreator = {
  GetChargingSessionsRequest: actionCreator<
    ActionInterface.GetChargingSessionsRequest
  >(ActionType.GetChargingSessionsRequest),
  GetChargingSessionsSuccess: actionCreator<
    ActionInterface.GetChargingSessionsSuccess
  >(ActionType.GetChargingSessionsSuccess),
  GetChargingSessionsError: actionCreator<
    ActionInterface.GetChargingSessionsError
  >(ActionType.GetChargingSessionsError),
  ClearChargingSessions: actionCreator<ActionInterface.ClearChargingSessions>(
    ActionType.ClearChargingSessions
  ),
  SortChargingSessions: actionCreator<ActionInterface.SortChargingSessions>(
    ActionType.SortChargingSessions
  ),
};
