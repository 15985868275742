import makeStyles from '@mui/styles/makeStyles';
import createStyles from '@mui/styles/createStyles';

const useStyles = makeStyles(() =>
  createStyles({
    buttonBox: {
      padding: '30px 0 30px 15px',
    },
  })
);

export default useStyles;
