import React from 'react';
import Grid from '@mui/material/Grid';
import { Headline } from '@elli-eco/component-library';
import connector, { IPropsFromState } from '../../../../Connector/Connector';
import { useTypedTranslation } from '../../../../../custom-hooks/useTypedTranslation';
import CardElement from '../../../../shared/CardElement/CardElement';
import SubscriptionRefundForm from './form/SubscriptionRefundForm';
import { ItemType } from '../../../../../types/invoice';
import { useInvoiceQuery } from '../../../../../state/queries/useInvoiceQuery';

export type TSubscriptionRefundProps = IPropsFromState & {
  invoiceId?: string;
};

const SubscriptionRefund: React.FC<TSubscriptionRefundProps> = ({
  subscriberState,
  invoiceId,
}) => {
  const { t, terms } = useTypedTranslation();
  const { data: invoice } = useInvoiceQuery(
    subscriberState.data!.id,
    invoiceId
  );
  const baseFee = invoice?.line_items.find(
    (lineItem) => lineItem.item_type === ItemType.BaseFee
  );

  if (!invoice || !baseFee) {
    return null;
  }

  return (
    <Grid container item xs={8}>
      <CardElement title={t(terms.refunds.form.headline)} isCollapsable={false}>
        <Grid container padding={2} paddingTop={0} spacing={2}>
          <Grid item>
            <Headline>{`Invoice ${invoice.id}`}</Headline>
          </Grid>
          <Grid item>
            <SubscriptionRefundForm invoiceId={invoice.id} baseFee={baseFee} />
          </Grid>
        </Grid>
      </CardElement>
    </Grid>
  );
};

export default connector(SubscriptionRefund);
