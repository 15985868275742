import { AvailableBrands } from '../../../components/VinEligibility/VinEligibility';
import { IVehicleModel } from '../../../types/vehicle';
/* eslint-disable @typescript-eslint/naming-convention */
import { actionCreator } from '../../type-utils';

export const ActionType = {
  ClearVehicle: 'vehicle/clear-vehicle',
  GetVehicleRequest: 'vehicle/get-vehicle-request',
  GetVehicleSuccess: 'vehicle/get-vehicle-success',
  GetVehicleError: 'vehicle/get-vehicle-error',
};

export declare namespace ActionInterface {
  export interface GetVehicleRequest {
    type: string;
    vin: string;
    brand: AvailableBrands;
  }

  export interface GetVehicleSuccess {
    type: string;
    data: IVehicleModel;
  }

  export interface GetVehicleError {
    type: string;
    error: Error;
  }
  export interface ClearVehicle {
    type: string;
  }
}

export const ActionCreator = {
  GetVehicleRequest: actionCreator<ActionInterface.GetVehicleRequest>(
    ActionType.GetVehicleRequest
  ),
  GetVehicleSuccess: actionCreator<ActionInterface.GetVehicleSuccess>(
    ActionType.GetVehicleSuccess
  ),
  GetVehicleError: actionCreator<ActionInterface.GetVehicleError>(
    ActionType.GetVehicleError
  ),
  ClearVehicle: actionCreator<ActionInterface.ClearVehicle>(
    ActionType.ClearVehicle
  ),
};
