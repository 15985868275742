import React from 'react';
import { useStyles } from './useStyles';

export const ActionBar: React.FC = ({ children }) => {
  const classes = useStyles();
  return (
    <div className={classes.outerWrapper}>
      <div className={classes.rightFade} />
      <div className={classes.informationRow}>{children}</div>
    </div>
  );
};
