import { ActionInterface, ActionType } from '../actions';
import initialState, { State } from '..';

import clearChargingSessions from './clearChargingSessions';
import getChargingSessionsError from './getChargingSessionsError';
import getChargingSessionsRequest from './getChargingSessionsRequest';
import getChargingSessionsSuccess from './getChargingSessionsSuccess';
import sortChargingSessions from './sortChargingSessions';

const reducer = (state: State = initialState, action: any) => {
  switch (action.type) {
    case ActionType.GetChargingSessionsRequest:
      return getChargingSessionsRequest(
        state,
        action as ActionInterface.GetChargingSessionsRequest
      );
    case ActionType.GetChargingSessionsSuccess:
      return getChargingSessionsSuccess(
        state,
        action as ActionInterface.GetChargingSessionsSuccess
      );
    case ActionType.GetChargingSessionsError:
      return getChargingSessionsError(
        state,
        action as ActionInterface.GetChargingSessionsError
      );
    case ActionType.ClearChargingSessions:
      return clearChargingSessions(state);
    case ActionType.SortChargingSessions:
      return sortChargingSessions(
        state,
        action as ActionInterface.SortChargingSessions
      );
    default:
      return state;
  }
};

export default reducer;
