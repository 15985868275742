import React, { useState } from 'react';
import isEmpty from 'lodash/isEmpty';
import useStyles from './useStyles';
import { InvoiceDetailsTitle } from '../../InvoiceTitle';
import Details from '../../Details';
import connector, { IPropsFromState } from '../../../../Connector/Connector';
import { InvoiceChargeRecordTable } from '../../charge-record-table/InvoiceChargeRecordTable';
import {
  IInvoiceLineItem,
  IInvoiceRefund,
  IInvoiceRefundLineItem,
  ItemType,
} from '../../../../../types/invoice';
import ChargeRecordRefundForm from './form/ChargeRecordRefundForm';
import { useInvoiceRefund } from '../../../../../state/mutations/useInvoiceRefund';
import { useTypedTranslation } from '../../../../../custom-hooks/useTypedTranslation';
import MultilineButton from '../../../../shared/Buttons/MultilineButton';
import InvoiceRefundResponse from '../shared/response/InvoiceRefundResponse';
import { useInvoiceQuery } from '../../../../../state/queries/useInvoiceQuery';

export type TChargeRecordRefundProps = IPropsFromState & {
  invoiceId?: string;
};
const ChargeRecordRefund: React.FC<TChargeRecordRefundProps> = ({
  invoiceId,
  getInvoices,
  removeBreadcrumb,
  subscriberState,
}) => {
  const classes = useStyles();
  const { data: invoice } = useInvoiceQuery(
    subscriberState.data!.id,
    invoiceId
  );
  const { t, terms } = useTypedTranslation();
  const {
    mutate,
    error,
    isError,
    isSuccess,
    isLoading: isSubmitting,
  } = useInvoiceRefund();

  const [chargeRecordRefund, setChargeRecordRefund] = useState<IInvoiceRefund>({
    line_items: [],
  });
  const [selectedChargeRecord, setSelectedChargeRecord] = useState<
    IInvoiceLineItem
  >();

  const addRefundEntry = (entry: IInvoiceRefundLineItem) => {
    setChargeRecordRefund((prevChargeRecordRefund) => ({
      line_items: [...prevChargeRecordRefund.line_items, entry],
    }));
    setSelectedChargeRecord(undefined);
  };

  const invoiceChargeRecords = invoice?.line_items.filter(
    (lineItem) => lineItem.item_type === ItemType.ChargeRecord
  );

  const selectChargeRecordForRefund = (invoiceCdrLineItemId: string) => {
    const chargeRecord = invoiceChargeRecords?.find(
      (cdr) => cdr.process_id === invoiceCdrLineItemId
    );
    if (chargeRecord) {
      setSelectedChargeRecord(chargeRecord);
    }
  };

  const submitChargeRecordRefund = () => {
    if (invoice) {
      mutate(
        {
          subscriberId: subscriberState.data!.id,
          invoiceId: invoice.id,
          data: chargeRecordRefund,
        },
        {
          onSuccess: () => {
            if (subscriberState.data) {
              getInvoices({
                subscriberId: subscriberState.data.id,
              });
            }
          },
        }
      );
    }
  };

  if (!invoice) {
    return null;
  }

  return (
    <div
      className={classes.cardElement}
      data-testid='charge-record-refund-component'
    >
      <InvoiceDetailsTitle invoiceNumber={invoice.invoice_number} />
      {invoice && (
        <>
          <Details
            currentInvoice={invoice}
            hideCreditNotes
            hideRefunds
            hidePDF
          />
          <InvoiceChargeRecordTable
            selectedCdrId={selectedChargeRecord?.process_id}
            refundEntries={chargeRecordRefund.line_items}
            invoiceLineItems={invoiceChargeRecords}
            onRefund={selectChargeRecordForRefund}
          />
          <ChargeRecordRefundForm
            addRefundEntry={addRefundEntry}
            invoice={invoice}
            invoiceRefund={chargeRecordRefund}
            selectedChargeRecord={selectedChargeRecord}
          />
          <MultilineButton
            inProgress={isSubmitting}
            className={classes.button}
            handleClick={submitChargeRecordRefund}
            mainText={t(terms.refunds.form.submit)}
            isDisabled={isEmpty(chargeRecordRefund.line_items)}
          />
        </>
      )}
      <InvoiceRefundResponse
        error={error}
        isError={isError}
        type='charge_record'
        isSuccess={isSuccess}
        removeBreadcrumb={removeBreadcrumb}
      />
    </div>
  );
};

export default connector(ChargeRecordRefund);
