import {
  AssetTable,
  IColumn,
  IRow,
  ISortState,
} from '../shared/AssetTable/AssetTable/AssetTable';
import { CircularProgress } from '@mui/material';
import React, { useEffect, useState } from 'react';
import connector, { IPropsFromState } from '../Connector/Connector';

import { ActionCreator } from '../../store/state/actions';
import { Actions } from '../shared/AssetTable/Actions/Actions';
import { CardOrderStateModel } from '../../store/state/cardorders';
import CreditCard from '@mui/icons-material/CreditCard';
import { SubscriberLabel } from './SubscriberLabel/SubscriberLabel';
import useSharedStyles from '../shared/useSharedStyles';
import { sortRequestHandler as sortStateHandler } from '../../utils/sortRequestHandler';
import { SubscriptionStatusComponent } from '../SubscriptionStatus/SubscriptionStatusComponent';
import { Typography } from '../shared/Typography/Typography';

const getColumns = (): IColumn[] => {
  const sortableColumns = ['status', 'card_number', 'subscriber', 'order_date'];

  return ['status', 'card_number', 'subscriber', 'order_date', 'actions'].map(
    (field) =>
      ({
        header: field,
        isSortable: sortableColumns.includes(field),
        key: field,
      } as IColumn)
  );
};

const getRows = (orderState: CardOrderStateModel): IRow[] => {
  if (!orderState || !orderState.data || orderState.data === null) return [];
  const orders = orderState.data;
  return Object.values(orders).map((order) => {
    const subscriber =
      order.first_name && order.last_name
        ? `${order.first_name} ${order.last_name}`
        : undefined;
    const orderDate = new Date(order.created_at);
    return {
      data: {
        actions: {
          content: <Actions order={order} />,
        },
        card_number: {
          content: <>{order.rfid_card.number}</>,
        },
        order_date: {
          content: <>{orderDate.toLocaleString()}</>,
        },
        status: {
          content: <SubscriptionStatusComponent status={order.status} />,
        },
        subscriber: {
          content: <SubscriberLabel name={subscriber} />,
        },
      },
      id: order.id,
    };
  });
};

type TOwnProps = IPropsFromState & {
  crumbId?: string;
};

const CardsUI: React.FC<TOwnProps> = ({ cardOrdersState, sortCardOrders }) => {
  const sharedClasses = useSharedStyles();
  const cardOrders = cardOrdersState;

  const [sortState, setSortState] = useState<ISortState>({
    sortByColumnKey: 'order_date',
    sortDesc: true,
  });

  const sortRequestHandler = (columnKey: string | null): void => {
    sortStateHandler(columnKey, sortState, setSortState);
  };

  useEffect(() => {
    sortCardOrders(ActionCreator.cardorders.SortCardOrders({ sortState }));
  }, [sortState, sortCardOrders]);

  const cardOrderUIState = (): JSX.Element | null => {
    if (!cardOrders) return null;

    if (cardOrders.loading) {
      return <CircularProgress color='primary' />;
    }

    if (cardOrders.data && cardOrders.data.length) {
      return (
        <AssetTable
          bulkActions={[]}
          columns={getColumns()}
          rows={getRows(cardOrders)}
          filterState={{}}
          sortingState={sortState}
          sortRequestHandler={sortRequestHandler}
        />
      );
    }

    return <Typography variant='body1'>No data available</Typography>;
  };

  return (
    <div className={sharedClasses.cardElement} data-testid='cards-ui-component'>
      <div className={sharedClasses.sectionWrap}>
        <CreditCard
          color='primary'
          style={{ fontSize: '20px', marginRight: '5px' }}
        />
        <Typography variant='h4' color='primary'>
          Cards
        </Typography>
      </div>
      <section>{cardOrderUIState()}</section>
    </div>
  );
};

export default connector(CardsUI);
