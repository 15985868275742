import React from 'react';
import { useTheme } from '@mui/material/styles';

import connector, { IPropsFromState } from '../../Connector/Connector';
import Details from './Details';
import useStyles from './useStyles';
import {
  addBreadcrumbHandler,
  IBreadcrumbHandlerProps,
} from '../../../utils/addBreadcrumbHandler';
import { InvoiceChargeRecordTable } from './charge-record-table/InvoiceChargeRecordTable';
import { InvoiceDetailsTitle } from './InvoiceTitle';
import { useInvoiceQuery } from '../../../state/queries/useInvoiceQuery';
import { ItemType } from '../../../types/invoice';

type TOwnProps = IPropsFromState & {
  crumbId?: string;
  invoiceId?: string;
};

export const InvoiceDetails: React.FC<TOwnProps> = ({
  addBreadcrumb,
  breadcrumbState,
  subscriberState,
  invoiceId,
  crumbId,
}) => {
  const theme = useTheme();
  const classes = useStyles(theme);
  const { data: invoice } = useInvoiceQuery(
    subscriberState.data!.id,
    invoiceId
  );

  const taskBreadcrumbState = breadcrumbState.breadcrumbs;

  const breadcrumbHandler = (props: IBreadcrumbHandlerProps): void =>
    addBreadcrumbHandler({
      ...props,
      addBreadcrumb,
      crumbId,
      taskBreadcrumbState,
      id: invoice?.id,
    });

  return (
    <div data-testid='invoice-details-component'>
      {invoice && (
        <div className={classes.cardElement}>
          <>
            <InvoiceDetailsTitle invoiceNumber={invoice.invoice_number} />
            <Details
              currentInvoice={invoice}
              addBreadcrumbHandler={breadcrumbHandler}
              isSubscriptionRefundAvailable={invoice.line_items.some(
                ({ item_type }) => item_type === ItemType.BaseFee
              )}
            />

            <InvoiceChargeRecordTable
              invoiceLineItems={invoice.line_items.filter(
                ({ item_type }) => item_type === ItemType.ChargeRecord
              )}
            />
          </>
        </div>
      )}
    </div>
  );
};

export default connector(InvoiceDetails);
