import { AxiosError } from 'axios';
import { all, call, put, takeEvery } from 'redux-saga/effects';
import { ActionCreator, ActionInterface, ActionType } from './actions';
import { ISubscriptionModel } from '../../../types/subscriber';
import { SubscribersService } from '../../../services/subscribers';
import {
  ITerminateSubscriptionResponse,
  TerminateSubscriptionService,
} from '../../../services/subscriptions';

export function* getSubscription(
  action: ActionInterface.GetSubscriptionRequest
) {
  try {
    const subscription: ISubscriptionModel[] = yield call(
      SubscribersService.getSubscriptions,
      {
        subscriberId: action.subscriberId,
      }
    );

    yield put<ActionInterface.GetSubscriptionSuccess>(
      ActionCreator.GetSubscriptionSuccess({
        data: subscription,
      })
    );
  } catch (error) {
    yield put<ActionInterface.GetSubscriptionError>(
      ActionCreator.GetSubscriptionError({
        error: error as AxiosError,
      })
    );
  }
}

export function* terminateSubscription(
  action: ActionInterface.TerminateSubscriptionRequest
) {
  try {
    const terminationResponse: ITerminateSubscriptionResponse = yield call(
      TerminateSubscriptionService.terminateSubscription,
      action.subscriptionId,
      action.terminationDate,
      action.revocationReason
    );
    yield put<ActionInterface.TerminateSubscriptionSuccess>(
      ActionCreator.TerminateSubscriptionSuccess({
        data: terminationResponse,
        status: action.status,
      })
    );
  } catch (error) {
    yield put<ActionInterface.TerminateSubscriptionError>(
      ActionCreator.TerminateSubscriptionError({
        error: error as AxiosError,
      })
    );
  }
}

export function* subscriptionSaga() {
  yield all([
    takeEvery(ActionType.GetSubscriptionRequest, getSubscription),
    takeEvery(ActionType.TerminateSubscriptionRequest, terminateSubscription),
  ]);
}

export default subscriptionSaga;
