import ApiService from './api';
import { IAccounts } from '../types/organization';
import { IOrganizationModel } from '../types/user';

interface IRequestParams {
  organizationId?: string;
}

const getOrganization = async ({
  organizationId,
}: IRequestParams): Promise<IOrganizationModel> => {
  const apiService = new ApiService(`/mgmt/enterprise/v1/master-data/`, {
    data: { iam_id: organizationId },
  });
  const response = await apiService.query();
  return response.data.results.length > 0 ? response.data.results[0] : {};
};

const getAccounts = async ({
  organizationId,
}: IRequestParams): Promise<IAccounts> => {
  const apiService = new ApiService(
    `/mgmt/iam/v1/organisations/${organizationId}/members`
  );
  const response = await apiService.query();
  return response.data;
};

export const OrganizationService = { getOrganization, getAccounts };
