import React, { useState } from 'react';
import ErrorOutlineIcon from '@mui/icons-material/ErrorOutline';
import { FaultedSessionDialog } from './FaultedSessionDialog';
import useStyles from './useStyles';

export const FaultedSession: React.FC = () => {
  const classes = useStyles();
  const [dialogOpen, setDialogOpen] = useState<boolean>(false);

  return (
    <>
      <div
        data-testid='faulted-session-component'
        className={classes.tagBox}
        onClick={() => setDialogOpen((prevState) => !prevState)}
      >
        <ErrorOutlineIcon className={classes.icon} />
        <span>Faulted Session</span>
      </div>
      <FaultedSessionDialog
        open={dialogOpen}
        onClose={() => setDialogOpen(false)}
      />
    </>
  );
};

export default FaultedSession;
