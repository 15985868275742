import { IColumn } from '../components/shared/AssetTable/AssetTable/AssetTable';
import { TableCellProps } from '@mui/material';

export interface IFieldNames {
  [name: string]: {
    name: string | JSX.Element;
    width?: string;
    noPadding?: boolean;
    isStatus?: boolean;
    align?: TableCellProps['align'];
  };
}

export const mapFieldsForTable = (
  fieldNames: IFieldNames,
  sortableColumns: string[]
): IColumn[] => {
  return Object.keys(fieldNames).map(
    (field) =>
      ({
        header: fieldNames[field].name,
        isSortable: sortableColumns.includes(field),
        key: field,
        width: fieldNames[field].width,
        noPadding: fieldNames[field].noPadding,
        align: fieldNames[field].align,
      } as IColumn)
  );
};
