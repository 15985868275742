import { ElliTooltip } from '../../shared/ElliTooltip/ElliTooltip';
import { IHomeStation } from '../../../types/user';
import React from 'react';
import { getStationModel } from '../../../utils/stationUtils';

interface IChargeStationStyles {
  textOverflow: string;
}

export const getStationTitle = (
  station: IHomeStation,
  styles: IChargeStationStyles
): JSX.Element => {
  const model = getStationModel(station.model, station.model_variant);

  if (station.name) {
    return (
      <ElliTooltip
        title={station.name}
        heading={model !== '-' ? model : undefined}
      >
        <span
          className={styles.textOverflow}
          data-testid='charging-station-name'
        >
          {station.name}
        </span>
      </ElliTooltip>
    );
  }

  if (station.station_serial_number) {
    return (
      <ElliTooltip
        title={station.station_serial_number}
        heading={model !== '-' ? model : undefined}
      >
        <span
          className={styles.textOverflow}
          data-testid='charging-station-serial'
        >
          {station.station_serial_number}
        </span>
      </ElliTooltip>
    );
  }

  return (
    <ElliTooltip title={station.id} heading={model !== '-' ? model : undefined}>
      <span className={styles.textOverflow} data-testid='charging-station-id'>
        {station.id}
      </span>
    </ElliTooltip>
  );
};
