import React from 'react';
import { FieldValues, useFormContext } from 'react-hook-form';
import { recursiveChildMap } from '../form/Form';
import { TFormProps } from '../form/Form.types';

const ConnectForm = <FormValues extends FieldValues>({
  children,
  disabled = false,
}: Pick<
  TFormProps<FormValues>,
  'children' | 'disabled'
>): React.ReactElement => {
  const methods = useFormContext<FormValues>();
  const {
    formState: { errors },
  } = methods;

  return (
    <>
      {recursiveChildMap<FormValues>(
        children,
        methods.control,
        errors,
        disabled
      )}
    </>
  );
};

export default ConnectForm;
