import React from 'react';
import { yupResolver } from '@hookform/resolvers/yup';
import {
  Control,
  FieldErrors,
  FieldValues,
  FormProvider,
  SubmitHandler,
  useForm,
} from 'react-hook-form';
import { TFormProps } from './Form.types';
import getPropByString from '../../../../utils/get-prop-by-string/getPropByString';

export const recursiveChildMap = <FormValues extends FieldValues>(
  formChildren: TFormProps<FormValues>['children'],
  control: Control<FormValues>,
  errors: FieldErrors,
  disabled: boolean
): JSX.Element[] =>
  React.Children.map(formChildren as JSX.Element[], (child) => {
    if (!child?.props?.children && child?.props?.name) {
      return React.createElement(child.type, {
        ...{
          ...child.props,
          error: getPropByString(errors, child.props.name),
          control: control,
          disabled: disabled || child.props.disabled,
          key: child.props.name,
        },
      });
    }
    if (child?.props?.children) {
      return React.cloneElement(child, {
        control: control,
        children: recursiveChildMap<FormValues>(
          child?.props.children,
          control,
          errors,
          disabled
        ),
      });
    }

    return child;
  });

const Form = <FormValues extends FieldValues>({
  schema,
  children,
  className,
  resetOnSubmit,
  defaultValues,
  disabled = false,
  mode = 'onChange',
  onSubmit = (data: FormValues) => data,
}: TFormProps<FormValues>): React.ReactElement => {
  const methods = useForm<FormValues>({
    mode,
    defaultValues,
    resolver: schema ? yupResolver(schema) : undefined,
  });

  const {
    reset,
    handleSubmit,
    formState: { errors },
  } = methods;

  const onFormSubmit: SubmitHandler<FormValues> = (data: FormValues) => {
    onSubmit(data);

    if (resetOnSubmit) {
      reset(resetOnSubmit);
    }
  };

  return (
    <FormProvider {...methods}>
      <form
        role='form'
        className={className}
        onSubmit={handleSubmit(onFormSubmit)}
      >
        {recursiveChildMap<FormValues>(
          children,
          methods.control,
          errors,
          disabled
        )}
      </form>
    </FormProvider>
  );
};

export default Form;
