import React from 'react';
import startCase from 'lodash/startCase';
import kebabCase from 'lodash/kebabCase';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import TableBody from '@mui/material/TableBody';
import { Table as MuiTable } from '@mui/material';
import { IInvoiceRefundLineItem } from '../../../../../../../types/invoice';
import { TChargeRecordRefundDistribution } from '../distribution/ChargeRecordRefundDistribution.types';

const RefundDistributionColumns = ['Payment Type', 'Amount'] as const;
const RefundEntriesColumns = ['CDR ID', 'Amount'] as const;

interface ITable {
  columns: typeof RefundDistributionColumns | typeof RefundEntriesColumns;
  currency: string;
  refundEntries?: IInvoiceRefundLineItem[];
  refundDistribution?: TChargeRecordRefundDistribution;
}

export const ChargeRecordRefundTable: React.FC<ITable> = ({
  columns,
  currency,
  refundEntries,
  refundDistribution,
}) => {
  const alignTableCell = (
    columns: typeof RefundDistributionColumns | typeof RefundEntriesColumns,
    index: number
  ) => {
    return index === columns.length - 1 ? 'right' : 'left';
  };

  return (
    <TableContainer>
      <MuiTable aria-label='refund table'>
        <TableHead>
          <TableRow>
            {[...columns].map((columnName, index) => (
              <TableCell
                align={alignTableCell(columns, index)}
                key={`${columnName}-${index}`}
              >
                {columnName}
              </TableCell>
            ))}
          </TableRow>
        </TableHead>
        <TableBody>
          {refundEntries &&
            refundEntries.map((entry, index) => (
              <TableRow key={index}>
                <TableCell component='th' scope='row'>
                  {entry.process_id}
                </TableCell>
                <TableCell align='right'>{`${entry.gross_amount_to_refund.toFixed(
                  2
                )} ${currency}`}</TableCell>
              </TableRow>
            ))}
          {refundDistribution &&
            refundDistribution.map((distribution, index) => (
              <TableRow key={index}>
                <TableCell component='th' scope='row'>
                  {startCase(distribution.type)}
                </TableCell>
                <TableCell
                  data-testid={`${kebabCase(
                    distribution.type
                  )}-distribution-amount`}
                  align='right'
                >{`${distribution.amount} ${currency}`}</TableCell>
              </TableRow>
            ))}
        </TableBody>
      </MuiTable>
    </TableContainer>
  );
};
