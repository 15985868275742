import React from 'react';

import { ICardOrderModel } from '../../../../types/subscriber';
import { ActionButton } from '../ActionButton/ActionButton';
import useStyles from './useStyles';

interface IOwnProps {
  order: ICardOrderModel;
}

export const Actions: React.FC<IOwnProps> = ({ order }) => {
  const classes = useStyles();
  const approveAction = () => console.log('approved');
  const rejectAction = () => console.log('rejected');

  const approveIsVisible =
    order && (order.status === 'pending' || order.status === 'rejected');
  const rejectIsVisible = order && order.status === 'pending';

  return (
    <div className={classes.actionContainer}>
      <ActionButton
        title='reject'
        action='reject'
        style={rejectIsVisible ? {} : { display: 'none' }}
        onClick={rejectAction}
      />
      <ActionButton
        title='approve'
        action='approve'
        style={approveIsVisible ? {} : { display: 'none' }}
        onClick={approveAction}
      />
      <ActionButton title='open' action='open' />
    </div>
  );
};
