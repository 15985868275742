import { useTheme } from '@mui/material/styles';
import CheckIcon from '@mui/icons-material/Check';
import CloseIcon from '@mui/icons-material/Close';
import React from 'react';
import { IVehicleEligibleTariffModel } from '../../../../types/vehicle';
import useStyles from './useStyles';
import PublicTariffIcon from '../../../../assets/image/publicTariff.svg';
import VehicleBasedTariffIcon from '../../../../assets/image/vehicleBasedTariff.svg';
import { ElliTooltip } from '../../../shared/ElliTooltip/ElliTooltip';
import { RestrictionEligibleTariffs } from '../../../../services/vehicles';
import { Typography } from '../../../shared/Typography/Typography';

type TOwnProps = {
  eligibleTariff: IVehicleEligibleTariffModel;
};

const RestrictionIconWithHoverTooltip = (restriction: string) => {
  const classes = useStyles();
  return (
    <ElliTooltip title={restriction} alwaysShowOnHover={true}>
      <img
        data-testid={
          restriction === RestrictionEligibleTariffs.vehicle_based
            ? 'VehicleBasedTariffIcon'
            : 'PublicTariffIcon'
        }
        src={
          restriction === RestrictionEligibleTariffs.vehicle_based
            ? VehicleBasedTariffIcon
            : PublicTariffIcon
        }
        alt={
          restriction === RestrictionEligibleTariffs.vehicle_based
            ? 'Vehicle-based Tariff'
            : 'Public Tariff'
        }
        className={classes.tariffImage}
      />
    </ElliTooltip>
  );
};

export const EligibleTariff: React.FC<TOwnProps> = ({ eligibleTariff }) => {
  const classes = useStyles();
  const theme = useTheme();

  return (
    <div className={classes.tariff}>
      {eligibleTariff.eligible ? (
        <CheckIcon
          className={classes.tariffImage}
          style={{
            fontSize: 32,
            color: theme.colorPrimary,
          }}
        />
      ) : (
        <CloseIcon
          className={classes.tariffImage}
          style={{
            fontSize: 32,
            color: '#f00066',
          }}
        />
      )}
      <Typography variant='body1' className={classes.tariffString}>
        {eligibleTariff.tariff_name}
      </Typography>
      <ElliTooltip title={eligibleTariff.tariff_code} alwaysShowOnHover={true}>
        <Typography variant='body1' className={classes.tariffString}>
          {eligibleTariff.tariff_code}
        </Typography>
      </ElliTooltip>
      {RestrictionIconWithHoverTooltip(eligibleTariff.restriction)}
    </div>
  );
};

export default EligibleTariff;
