import { Collapse, Table, TableBody, TableCell, TableRow } from '@mui/material';
import ExpandLessIcon from '@mui/icons-material/ExpandLess';
import React, { useState } from 'react';

import useStyles from './useStyles';
import { ICell, IColumn, IRow } from './AssetTable';
import { AssetTableRow } from './AssetTableRow';

interface ICollapsibleRowItem {
  selected: string[];
  row: IRow;
  columns: IColumn[];
  onRowClick: ((row: IRow) => void) | undefined;
  toggleSelection: (id: string) => void;
  getTableCellClasses: (column: IColumn) => string;
  condensed?: boolean;
}

export const CollapsibleRowItem: React.FC<ICollapsibleRowItem> = ({
  selected,
  row,
  columns,
  onRowClick,
  toggleSelection,
  getTableCellClasses,
  condensed,
}) => {
  const classes = useStyles();
  const [expand, setExpand] = useState<boolean>(false);

  const addBulkEditingInfo = (cell: ICell): ICell['content'] => {
    if (cell.isToggle) {
      return (
        <ExpandLessIcon
          data-testid='expand-icon'
          color='primary'
          className={`
                ${classes.expandIcon}
                ${expand ? classes.expandIconOpen : classes.expandIconClosed}`}
          onClick={() => setExpand(!expand)}
        />
      );
    } else {
      return cell.content;
    }
  };

  const rowSelected = selected.includes(row.id);
  const checkboxCallback = () => {
    toggleSelection(row.id);

    // Unfocus the checkbox so that the row does not remain grey
    const focusedElement = document.activeElement as HTMLElement;
    focusedElement.blur();
  };

  const onClickRowEvent = () => {
    if (onRowClick) {
      onRowClick(row);
    }
    return selected.length > 0 ? checkboxCallback : undefined;
  };

  return (
    <React.Fragment>
      <AssetTableRow
        selected={selected}
        rowSelected={rowSelected}
        onRowClick={onRowClick}
        onClickRowEvent={onClickRowEvent}
        columns={columns}
        getTableCellClasses={getTableCellClasses}
        row={row}
        addBulkEditingInfo={addBulkEditingInfo}
        condensed={condensed}
      />
      <TableRow>
        <TableCell style={{ padding: 0 }} colSpan={6}>
          <Collapse in={expand} timeout='auto' unmountOnExit>
            <Table className={classes.table}>
              <TableBody>
                {row.history?.map((historyRow) => (
                  <TableRow key={`${Math.random()}-${row.id}`}>
                    {columns.map((column) => (
                      <TableCell
                        className={getTableCellClasses(column)}
                        key={`${Math.random()}-${column.key}`}
                        align={column.align || 'inherit'}
                        style={{ width: `${column.width}` }}
                      >
                        {addBulkEditingInfo(historyRow[column.key])}
                      </TableCell>
                    ))}
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </Collapse>
        </TableCell>
      </TableRow>
    </React.Fragment>
  );
};
