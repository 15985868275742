import Dialog from '@mui/material/Dialog';
import React from 'react';
import useStyles from './useStyles';
import { Typography } from '../../../shared/Typography/Typography';

export interface IOwnProps {
  open: boolean;
  onClose: (value: string) => void;
}

export const FaultedSessionDialog: React.FC<IOwnProps> = ({
  open,
  onClose,
}) => {
  const classes = useStyles();

  return (
    <Dialog
      onClose={onClose}
      open={open}
      data-testid='faulted-session-dialog'
      PaperProps={{
        classes: {
          root: classes.dialog,
        },
      }}
    >
      <div className={classes.textWrapper}>
        <div className={classes.section}>
          <span className={classes.title}>Faulted Session</span>
          <Typography variant='body2'>
            This label on a charging record indicates that the charging session
            was not properly stopped before the next one was started.
          </Typography>
        </div>
      </div>
    </Dialog>
  );
};
