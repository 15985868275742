import React from 'react';
import { IconButton } from '@mui/material';
import useStyles from './useStyles';
import RefreshIcon from '@mui/icons-material/Refresh';
import AccessTimeOutlinedIcon from '@mui/icons-material/AccessTimeOutlined';
import { Typography } from '../../../shared/Typography/Typography';

interface IProps {
  emaid: string;
  refreshEventsHandler: () => void;
}

export const PlugAndChargeEventHistoryHeader: React.FC<IProps> = ({
  emaid,
  refreshEventsHandler,
}) => {
  const classes = useStyles();

  return (
    <div className={classes.cardHeaderWrapper}>
      <div className={classes.titleWrapper}>
        <AccessTimeOutlinedIcon className={classes.titleIcon} />
        <Typography
          variant='h1'
          classes={{
            root: classes.title,
          }}
        >
          Plug & Charge Event History - Contract ID &quot;{emaid}&quot;
        </Typography>
      </div>
      <IconButton
        color='primary'
        className={classes.actionButton}
        onClick={refreshEventsHandler}
        size='large'
      >
        <RefreshIcon fontSize='small' />
      </IconButton>
    </div>
  );
};
