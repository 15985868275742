import { ErrorObject } from 'serialize-error';
import { IRFIDCardModel } from '../../../types/subscriber';

export interface IChargingCardModel {
  loading: boolean;
  data: IRFIDCardModel[] | null;
  error: ErrorObject | null;
  request: { subscriberId: string } | null;
  currentCard: IRFIDCardModel | null;
}

export const initialState: IChargingCardModel = {
  loading: false,
  data: [],
  error: null,
  request: null,
  currentCard: null,
};

const state: IChargingCardModel = {
  ...initialState,
};

export type State = typeof state;
export default state;
