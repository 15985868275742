import React from 'react';
import Card from '../../../ChargingCards/Card/Card';
import { IRFIDCardModel } from '../../../../types/subscriber';
import useStyles from './useStyles';
interface IOwnProps {
  chargingCard: IRFIDCardModel;
}

export const General: React.FC<IOwnProps> = ({ chargingCard }) => {
  const classes = useStyles();

  return (
    <div data-testid='general-tab' className={classes.generalWrapper}>
      <div data-testid='charging-card' className={classes.cardFrontWrapper}>
        <Card
          chargingCard={chargingCard}
          showOverlay={false}
          showExtraDetails={false}
        />
      </div>
      <div data-testid='charging-card' className={classes.cardBackWrapper}>
        <Card
          chargingCard={chargingCard}
          isRearSide={true}
          showOverlay={false}
          showExtraDetails={false}
        />
      </div>
    </div>
  );
};

export default General;
