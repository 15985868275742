import React from 'react';
import { useTheme } from '@mui/material/styles';
import {
  BasicStatus,
  IMappedStatus,
} from '../../../shared/StatusIndicator/BasicStatus';

interface IVinCapabilityStatusProps {
  status: boolean;
  statusTypeForTestId?: string;
}

export const VinCapabilityStatus: React.FC<IVinCapabilityStatusProps> = ({
  status,
  statusTypeForTestId,
}) => {
  const theme = useTheme();

  function valueMapper(value: boolean): IMappedStatus {
    return value
      ? { color: theme.colorAccent, label: 'Capable' }
      : { color: theme.surfaceTertiary, label: 'Not capable' };
  }

  return (
    <BasicStatus
      status={status}
      valueMapper={valueMapper}
      statusTypeForTestId={statusTypeForTestId}
    />
  );
};
