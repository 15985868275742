/* eslint-disable @typescript-eslint/naming-convention */

export enum EUserSearchHitType {
  iamId = 'iam_user.id',
  email = 'iam_user.email',
  cardNumber = 'rfid_card.number',
  customerNumber = 'subscriber.customerNumber',
  emaid = 'subscriber.emaid',
}

export interface IUserSearchModel {
  iam_user: IUserModel;
  rfid_card?: {
    id: string;
    htb_white_list?: string;
    number: string;
    status: string;
    tenant_id: string;
    tenant_name: string;
    plasticard_brand_id: number;
    plasticard_design_template: number;
    created_at: string;
    updated_at: string;
    label?: string;
    pairing_date?: string;
    public_charging: boolean;
    subscriber_id?: string;
    subscription_id?: string;
    fleet_id?: string;
    tag?: string;
  };
  subscriber?: {
    // mandatory
    createdAt: string;
    emaid: string;
    fleetId: string;
    iamId: string;
    id: string;
    status: string;
    tenantId: string;
    updatedAt: string;
    // optional
    activeRfidCardId?: string;
    addressId?: string;
    connectionLayerId?: string;
    connectionLayerIdType?: string;
    customerNumber?: string;
    paymentAccountNo?: string;
    taxNumber?: string;
  };
  hits: EUserSearchHitType[];
}

export interface IUserModel {
  id: string;
  first_name?: string;
  last_name?: string;
  display_name?: string;
  email: string;
  country?: string;
  tenant_id?: string;
  organisation_id?: string;
  created_at?: string;
  updated_at?: string;
  last_login: string;
}

export interface IOrganizationModel {
  created_at?: string;
  email_address?: string;
  iam_id?: string;
  kvps_number?: string;
  name?: string;
  phone_number?: string;
  type?: string;
  updated_at?: string;
}

export interface IHomeChargingStationModel {
  limit?: number;
  offset?: number;
  stations: IHomeStation[];
  total_count?: number;
}

export interface IHomeStation {
  access_status?: {
    enabled: boolean;
  };
  auth_name?: string;
  authorization_mode?: string;
  connection_state?: string;
  created_at?: string;
  device_twin_id?: string;
  elli_product_id?: string;
  evses: IHomeEvse[];
  id: string;
  last_contact?: string;
  lifecycle_state: string;
  location_id?: string;
  model?: string;
  name?: string;
  owner?: {
    iam_id: string;
    type: string;
  };
  paired_rfid_cards?: IPairedRFIDCard[];
  station_authentication_method?: string;
  station_serial_number?: string;
  updated_at?: string;
  model_variant?: ModelVariant;
}

export interface IHomeChargingStationMetaData {
  model: string;
  serialNumber?: string;
  pairingCode?: string;
  wifiHotSpotSSID?: string;
  wifiHotSpotPassword?: string;
  networkHostName?: string;
  configurationIpAdress?: string;
  configurationIpPassword?: string;
  pukCode?: string;
}

export interface MGWBDeviceMetaData {
  device_metadata?: {
    custom?: {
      SrkKeyName?: string;
      TpmAuditTrail?: string;
      PlcModemMacAddress?: string;
      EsimCardIccid?: string;
      ComBoardHostName?: string;
      PukCode?: string;
      WebConfigPassword?: string;
      EkCertificate?: string;
      WifiInstallerApPassword?: string;
      ProductNumber?: string;
      WifiInstallerApSsid?: string;
      Imei?: string;
      EK_sign_public_key?: string;
      PowerboardSerialNumber?: string;
      ComboardSerialNumber?: string;
      HardwareVersion?: string;
      Euiccid?: string;
    };
  };
}

export interface ElviDeviceMetaData {
  device_metadata?: {
    serial_number?: string;
    custom: {
      Manufacturer?: string;
      DeliveryDate?: string;
      'PO RefNumber'?: string;
      Format?: string;
      'Wi-Fi supported'?: string;
      'Connector type'?: string;
      DebtorName?: string;
      SIM_ICCID?: string;
      Type?: string;
      'GSM supported'?: string;
      ItemCode?: string;
      'Power type'?: string;
      SerialNumber?: string;
      'Sales Order Number'?: string;
      Ourreference?: string;
    };
  };
}

export type ChargingStationVendor =
  | 'ALPITRONIC'
  | 'DEFAULT_VENDOR'
  | 'ECOTAP'
  | 'EBEE'
  | 'EVBOX'
  | 'FLEXPOLE'
  | 'INNOGY'
  | 'COMPLEO'
  | 'ESYSTEMS';

export type ContractType =
  | 'csm_facility_audi'
  | 'csm_facility_man'
  | 'csm_facility_vw'
  | 'csm_pilot'
  | 'site_operator'
  | 'csm_site_operator_b2b'
  | 'csm_facility_porsche';

export type ConnectorStandard =
  | 'CHADEMO'
  | 'GB_T'
  | 'CCS1'
  | 'DOMESTIC_A'
  | 'DOMESTIC_B'
  | 'DOMESTIC_C'
  | 'DOMESTIC_D'
  | 'DOMESTIC_E'
  | 'DOMESTIC_F'
  | 'DOMESTIC_G'
  | 'DOMESTIC_H'
  | 'DOMESTIC_I'
  | 'DOMESTIC_J'
  | 'DOMESTIC_K'
  | 'DOMESTIC_L'
  | 'IEC_60309_2_single_16'
  | 'IEC_60309_2_three_16'
  | 'IEC_60309_2_three_32'
  | 'IEC_60309_2_three_64'
  | 'IEC_62196_T1'
  | 'IEC_62196_T1_COMBO'
  | 'IEC_62196_T2'
  | 'IEC_62196_T2_COMBO'
  | 'IEC_62196_T3A'
  | 'IEC_62196_T3C'
  | 'PANTOGRAPH_BOTTOM_UP'
  | 'PANTOGRAPH_BOTTOM_DOWN'
  | 'TESLA_, TESLA_S';

export type ModelVariant = 'PRO' | 'CONNECT';

export interface IHomeConnector {
  format?: string;
  id: number;
  max_amperage?: number;
  max_electric_power_kw?: number;
  max_voltage?: number;
  power_type?: string;
  status: string;
  type?: string;
}

export interface IEnterpriseConnector {
  format: string;
  ocpp_connector_id?: number;
  max_amperage: number;
  max_electric_power_kw: number;
  max_voltage: number;
  power_type: string;
  standard: ConnectorStandard;
}

export interface IHomeEvse {
  connectors: IHomeConnector[];
  id: number;
  status: string;
  public_evse_id?: string;
}

export interface IEnterpriseEvse {
  id: string;
  power_type: string;
  public_evse_id?: string;
  evse_public_key?: string;
  chaeging_spot: number;
  connectors?: IEnterpriseConnector[];
}

export interface IPairedRFIDCard {
  id?: string;
}

// interface corresponding to the CSMS GET stations/{stationId} endpoint
export interface ICsmsChargingStation {
  access_status?: {
    enabled: boolean;
  };
  auth_name?: string;
  authorization_mode?: string;
  connection_state?: string;
  created_at?: string;
  device_twin_id?: string;
  elli_product_id?: string;
  evses: IHomeEvse[];
  id: string;
  last_contact?: string;
  lifecycle_state: string;
  location?: {
    address?: IHomeChargingAddress;
    id: string;
    name?: string;
    description?: string;
    geo_location?: {
      lat?: string;
      long?: string;
    };
  };
  model?: string;
  name?: string;
  owner?: {
    iam_id: string;
    type: string;
  };
  onboarding_date?: string;
  paired_rfid_cards?: IPairedRFIDCard[];
  station_authentication_method?: string;
  station_serial_number?: string;
  updated_at?: string;
  pairing_code?: string;
  model_variant?: ModelVariant;
}

// interface corresponding to the HSS GET stations/{stationId} endpoint
export interface IHomeChargingStation {
  access_status?: {
    enabled: boolean;
  };
  authorization_mode?: string;
  connection_state?: string;
  created_at?: string;
  id: string;
  last_contact?: string;
  last_connect?: string;
  lifecycle_state: string;
  location?: {
    address?: IHomeChargingAddress;
    id: string;
    name?: string;
    description?: string;
    geo_location?: {
      lat?: string;
      long?: string;
    };
  };
  model?: string;
  name?: string;
  owner_iam_id?: string;
  station_authentication_method?: string;
  station_serial_number?: string;
  updated_at?: string;
  model_variant?: ModelVariant;
}

// interface corresponding to the ESS GET stations/{stationId}/ endpoint
export interface IEnterpriseChargingStation {
  access_status?: {
    enabled: boolean;
  };
  auth_name: string;
  authorization_mode: string;
  connection_state?: string;
  contract_type?: ContractType;
  created_at?: string;
  device_twin_id?: string;
  elli_product_id?: string;
  evses: IEnterpriseEvse[];
  id: string;
  last_contact?: string;
  lifecycle_state: string;
  location?: {
    address?: IHomeChargingAddress;
    id: string;
    name?: string;
    description?: string;
    geo_location?: {
      lat?: string;
      long?: string;
    };
  };
  model: string;
  name?: string;
  organization_iam_id?: IOrganizationModel['iam_id'];
  organization_type?: IOrganizationModel['type'];
  organization_branch_id?: string;
  onboarding_date?: string;
  offboarding_Scheduled?: string;
  station_authentication_method?: string;
  serial_number: string;
  tags?: string[];
  updated_at?: string;
  vendor: ChargingStationVendor;
  pairing_code?: string;
}

export interface IStationOwner {
  iam_id: string;
  type: 'user' | 'organization';
}

export interface IHomeChargingAddress {
  city?: string;
  country?: string;
  street?: string;
  zip?: string;
}

export interface IFirmwareInformationModel {
  installed_firmware: {
    id?: string;
    version: string;
    release_notes_link?: string;
    installed_at?: string;
    consent?: IFirmwareConsentModel;
  };
  recommended_firmware?: {
    id: string;
    version: string;
    release_notes_link: string;
    consent?: IFirmwareConsentModel;
  };
  scheduled_firmware?: {
    id: string;
    version: string;
    release_notes_link: string;
    update_attempts: number;
    consent?: IFirmwareConsentModel;
  };
}

export interface IFirmwareConsentModel {
  created_at: string;
  decision: FirmwareDecision;
}

export type FirmwareDecision = 'accepted' | 'postponed' | 'declined';
export type FirmwareConsentCreatedBy =
  | 'customer_manual'
  | 'customer_auto'
  | 'agent_manual'
  | 'agent_auto'
  | 'forced';

export interface FirmwareConsentDetailModel {
  id: string;
  decision?: FirmwareDecision;
  created_by?: FirmwareConsentCreatedBy;
  created_at?: string;
  processed_at?: string;
  user: {
    iam_id: string;
  };
  station: {
    id: string;
    name?: string;
  };
  firmware: {
    id: string;
    version?: string;
  };
}

export interface IFirmwareConsentsHistory {
  firmware_consents: FirmwareConsentDetailModel[];
  limit: number;
  offset: number;
  total_count: number;
}

export interface FirmwareConsentHistorySummary {
  id: string;
  decision?: FirmwareDecision;
  created_by?: FirmwareConsentCreatedBy;
  created_at?: string;
  processed_at?: string;
  user: {
    iam_id: string;
  };
  station: {
    id: string;
    name?: string;
  };
  firmware: {
    id: string;
    version?: string;
  };
  history: FirmwareConsentDetailModel[];
}
