import React, { useRef } from 'react';
import { IconButton, InputAdornment, TextField } from '@mui/material';
import FileCopyIcon from '@mui/icons-material/FileCopy';

import { TextTransform, useStyles } from './useStyles';

export interface IOwnProps {
  title: string;
  text?: string;
  textTransform?: TextTransform;
  action?: 'copy' | 'none';
  dataTestId?: string;
}

const copyText = (ref: React.RefObject<HTMLInputElement>): void => {
  (ref.current as HTMLInputElement).select();
  document.execCommand('copy');
};

const CopyTextField: React.FC<IOwnProps> = ({
  title,
  text,
  textTransform,
  action = 'copy',
  dataTestId,
}) => {
  const classes = useStyles({ textTransform });
  const ref = useRef<HTMLInputElement>(null);
  const handleButtonClick: () => void = () => copyText(ref);
  const elementDataTestId = dataTestId || title.toLowerCase().replace(' ', '-');

  const endAdornment = action === 'copy' && (
    <InputAdornment position='end'>
      <IconButton
        onClick={handleButtonClick}
        className={classes.copyIcon}
        data-testid={`${elementDataTestId}-copy-button`}
        disableFocusRipple
        disableRipple
        size='large'
      >
        <FileCopyIcon />
      </IconButton>
    </InputAdornment>
  );

  return (
    <div className={classes.textWrapper}>
      <span className={classes.title}>{title}</span>
      <TextField
        InputProps={{
          classes: {
            input: classes.textBox,
            root: classes.inputRoot,
            focused: classes.inputFocused,
          },
          endAdornment,
        }}
        inputProps={{
          ref,
          readOnly: true,
          'data-testid': elementDataTestId,
        }}
        value={text || '-'}
        variant='outlined'
        size='small'
      />
    </div>
  );
};

export default CopyTextField;
