import { AxiosError } from 'axios';
import { ActionCreator, ActionInterface, ActionType } from './actions';
import { all, call, put, takeEvery } from 'redux-saga/effects';

import { ActiveSessionsService } from '../../../services/activeSessions';
import { IActiveSessionsResponse } from '../../../types/activeSessions';

export function* activeSessionsSaga() {
  function* getActiveSessions(
    action: ActionInterface.GetActiveSessionsRequest
  ) {
    try {
      const activeSessions: IActiveSessionsResponse = yield call(
        ActiveSessionsService.getActiveSessions,
        {
          user_id: action.userId,
        }
      );

      yield put<ActionInterface.GetActiveSessionsSuccess>(
        ActionCreator.GetActiveSessionsSuccess({
          data: activeSessions.charging_sessions,
        })
      );
    } catch (error) {
      yield put<ActionInterface.GetActiveSessionsError>(
        ActionCreator.GetActiveSessionsError({
          error: error as AxiosError,
        })
      );
    }
  }

  yield all([
    takeEvery(ActionType.GetActiveSessionsRequest, getActiveSessions),
  ]);
}

export default activeSessionsSaga;
