import React from 'react';

import { ICreditNote } from '../../../types/creditNote';
import { useTypedTranslation } from '../../../custom-hooks/useTypedTranslation';
import { IInformationItem } from '../../shared/InformationItems/InformationItem';
import InformationItems from '../../shared/InformationItems';
import { formatDateNoTime } from '../../../utils/dates';

interface IOwnProps {
  creditNote?: ICreditNote;
}

const Details: React.FC<IOwnProps> = ({ creditNote }) => {
  const { t, terms } = useTypedTranslation();

  const getItems = (currentCreditNote: ICreditNote): IInformationItem[] => [
    {
      id: 'credit-note-number',
      headline: t(terms.refunds.overview.fields.credit_note_number),
      content: currentCreditNote.credit_memo_number,
    },
    {
      id: 'document-date',
      headline: t(terms.refunds.overview.fields.document_date),
      content: formatDateNoTime(currentCreditNote.credit_memo_date),
    },
    {
      id: 'amount',
      headline: t(terms.refunds.overview.fields.amount),
      content: `${currentCreditNote.gross_amount} ${currentCreditNote.currency}`,
    },
    {
      id: 'status',
      headline: t(terms.refunds.overview.fields.current_state),
      content: currentCreditNote.state,
    },
  ];

  return creditNote ? (
    <InformationItems items={getItems(creditNote)} title='Details' />
  ) : null;
};

export default Details;
