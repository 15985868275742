declare global {
  // eslint-disable-next-line @typescript-eslint/naming-convention
  interface Window {
    CONFIG: Config;
  }
}

export enum Config {
  LOCAL = 'local',
  DEV = 'dev',
  STAGE = 'stage',
  PROD = 'prod',
  TEST = 'test',
}
