import 'regenerator-runtime/runtime';
import 'smoothscroll-polyfill';
import './index.css';
import './utils/translation/i18n';
import * as React from 'react';

import App from './components/App';
import { Provider } from 'react-redux';
import ReactDOM from 'react-dom';
import { asyncWithLDProvider } from 'launchdarkly-react-client-sdk';
import { config } from './config';
import store from './store';
import { QueryClientProvider } from 'react-query';
import { ReactQueryDevtools } from 'react-query/devtools';
import { queryClient } from './state/queryClient';
import { auth0 } from './services/auth0';

const mountNode = document.getElementById('root') as Element;

async function renderApp(): Promise<void> {
  const LDProvider = await asyncWithLDProvider({
    clientSideID: config.launchDarklyClientId,
  });

  try {
    await auth0.getAccessTokens();
  } catch (error) {
    // Authentication failed, will retry on first api call
  }
  ReactDOM.render(
    <QueryClientProvider client={queryClient}>
      <ReactQueryDevtools position='bottom-right' initialIsOpen={false} />
      <Provider store={store}>
        <LDProvider>
          <App />
        </LDProvider>
      </Provider>
    </QueryClientProvider>,
    mountNode
  );
}

window.onload = (): void => {
  renderApp();
};
