import makeStyles from '@mui/styles/makeStyles';
import createStyles from '@mui/styles/createStyles';

const useStyles = makeStyles((theme) =>
  createStyles({
    circleComponentWrapper: {
      display: 'flex',
      flexFlow: 'column nowrap',
      alignItems: 'center',
      justifyContent: 'flex-start',
      maxWidth: 110,
      width: '100%',
      position: 'relative',
      height: '100%',
    },
    circle: {
      height: 34,
      width: 34,
      border: `2px solid`,
      borderColor: theme.colorAccent,
      borderRadius: '50%',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      marginBottom: 6,
    },
    loadingCircle: {
      height: 34,
      width: 34,
    },
    destinationCircle: {
      height: 96,
      width: 96,
      border: `2px solid #00ce4e`,
      borderRadius: '50%',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      marginBottom: 6,
      fontSize: 24,
    },
    destinationCircleIcon: {
      height: 49,
      width: 49,
      strokeWidth: 1,
      position: 'relative',
      left: '12%',
    },
    destinationCircleIconHappy: {
      stroke: theme.colorPrimary,
    },
    destinationCircleIconSad: {
      stroke: theme.colorAlertPrimary,
    },
    destinationCircleRingIcon: {
      position: 'relative',
      top: '-40%',
      right: '-5%',
      zIndex: 5,
      borderRadius: '50%',
      borderWidth: 0,
    },
    destinationCircleRingIconHappy: {
      color: theme.colorAccent,
      backgroundColor: theme.surfaceSecondary,
    },
    destinationCircleRingIconSad: {
      background: theme.surfaceSecondary,
      border: '1px solid',
      borderColor: theme.colorAlertPrimary,
      height: 20,
      width: 20,
      padding: 2,
    },
    circleText: {
      textAlign: 'center',
      fontWeight: 600,
      fontSize: 12,
    },
    circleHappy: {
      borderColor: theme.colorAccent,
    },
    circleSad: {
      borderColor: theme.colorAlertPrimary,
    },
    circleNeutral: {
      borderColor: '#cad3e0',
    },
    verticalLine: {
      borderWidth: 1,
      borderStyle: 'solid',
      height: '24px',
      marginTop: 5,
      marginBottom: 10,
    },
  })
);

export default useStyles;
