import React from 'react';

import { Body } from '@elli-eco/component-library';
import ConfirmationDialog from '../../../shared/Dialogs/Confirmation';
import { useTypedTranslation } from '../../../../custom-hooks/useTypedTranslation';
import connector, { IPropsFromState } from '../../../Connector/Connector';
import { SubscribersService } from '../../../../services/subscribers';
import toast from 'react-hot-toast';

const BlockPublicChargingAccessDialog: React.FC<IPropsFromState> = ({
  subscriberState,
  getSubscriberOnlyRequest,
}) => {
  const { t, terms } = useTypedTranslation();
  const subscriber = subscriberState.data;

  const onConfirm = async () => {
    if (subscriber && !subscriber.subscriber_blocked) {
      try {
        await SubscribersService.blockPublicChargingAccess(subscriber.id);
        getSubscriberOnlyRequest({
          userId: subscriber.iam_id,
        });
      } catch (err) {
        toast.error(t(terms.general_errors.generic));
        throw new Error(
          'Unexpected error. Could not block public charging access.'
        );
      }
    }
  };

  return (
    <ConfirmationDialog
      headline={t(terms.public_charging_access.actions.block.headline)}
      confirmAction={onConfirm}
    >
      <Body>{t(terms.public_charging_access.actions.block.text)}</Body>
    </ConfirmationDialog>
  );
};

export default connector(BlockPublicChargingAccessDialog);
