import React from 'react';
import { useTypedTranslation } from '../../../../custom-hooks/useTypedTranslation';
import {
  BasicStatus,
  IMappedStatus,
} from '../../../shared/StatusIndicator/BasicStatus';
import { UnreachableCaseError } from '../../../../utils/errors';
import { useTheme } from '@mui/material/styles';
import { PublicChargingAccessStatus } from '../../../../types/subscriber';

export interface IOwnProps {
  status: PublicChargingAccessStatus;
}

export const PublicChargingAccessStatusIndicator: React.FC<IOwnProps> = ({
  status,
}) => {
  const { t, terms } = useTypedTranslation();
  const theme = useTheme();

  const valueMapper = (value: PublicChargingAccessStatus): IMappedStatus => {
    switch (value) {
      case 'blocked':
        return {
          color: theme.colorAlertPrimary,
          label: t(terms.public_charging_access.status.blocked),
        };
      case 'active':
        return {
          color: theme.colorAccent,
          label: t(terms.public_charging_access.status.active),
        };
      default:
        throw new UnreachableCaseError(value);
    }
  };

  return <BasicStatus status={status} valueMapper={valueMapper} />;
};
