import { useQuery, UseQueryResult } from 'react-query';
import {
  CCUserInfoService,
  ICCUserInfoResponse,
} from '../../services/ccUserInfo';

export const useCCUserInfoQuery = (): UseQueryResult<ICCUserInfoResponse> => {
  return useQuery(
    'ccUserInfo',
    async () => {
      const ccUserInfo = await CCUserInfoService.getCCUserInfo();
      return {
        ...ccUserInfo,
      };
    },
    {
      enabled: false,
    }
  );
};
