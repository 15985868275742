import { State } from '..';
import update from 'immutability-helper';

export default (state: State): State => {
  const currentUser = state;
  const updatedUser = update(currentUser, {
    loading: {
      $set: true,
    },
  });

  return {
    ...state,
    ...updatedUser,
  };
};
