import { useTheme } from '@mui/material/styles';

import React from 'react';
import { TChargingState } from '../../../../types/activeSessions';
import useStyles from './useStyles';

interface IOwnProps {
  status: TChargingState;
}

export const StatusIndicator: React.FC<IOwnProps> = ({ status }) => {
  const classes = useStyles();
  const theme = useTheme();
  const backgroundColorStatusMapping: { [K in TChargingState]: string } = {
    ev_detected: theme.colorAlertSecondary,
    charging: theme.colorAlertSecondary,
    suspended_ev: theme.colorAlertSecondary,
    suspended_evse: theme.colorAlertSecondary,
    finished: theme.colorAlertSecondary,
  };
  const backgroundColor = backgroundColorStatusMapping[status];
  return (
    <div
      data-testid='status-indicator'
      className={`${classes.indicator} ${status === 'charging' &&
        classes.statusPulse}`}
      style={{ backgroundColor }}
    />
  );
};
