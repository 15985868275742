import React, { useEffect, useState } from 'react';

import Button from '@mui/material/Button';
import ChargingSessions from '../../../ChargingCard/ChargingSessions';
import DoubleArrow from '../../../../../assets/image/doubleArrow.svg';
import { IBreadcrumbHandlerProps } from '../../../../../utils/addBreadcrumbHandler';
import { IChargingRecordViewModel } from '../../../../../types/chargingRecords';
import Pagination from '../../../../shared/Pagination/Pagination';
import { paginateArray } from '../../../../../utils/paginateArray';
import useStyles from './useStyles';
import { Typography } from '../../../../shared/Typography/Typography';

interface IOwnProps {
  chargingRecords: IChargingRecordViewModel[];
  stationRefreshing: boolean;
  breadcrumbHandler: (props: IBreadcrumbHandlerProps) => void;
}

export const RecentSessions: React.FC<IOwnProps> = ({
  chargingRecords,
  stationRefreshing,
  breadcrumbHandler,
}) => {
  const classes = useStyles();

  const [itemsPerPage, setItemsPerPage] = useState<number>(4);
  const [currentPage, setCurrentPage] = useState<number>(1);
  const [pagesLength, setPagesLength] = useState<number>(0);
  const [paginatedSessions, setPaginatedSessions] = useState<
    IChargingRecordViewModel[][]
  >([[]]);
  const [currentRecords, setCurrentRecords] = useState<
    IChargingRecordViewModel[]
  >([]);

  const paginateSessions = (sessions: IChargingRecordViewModel[]): void => {
    const ps = paginateArray(sessions, itemsPerPage);
    setPaginatedSessions(ps);
    setPagesLength(ps.length);
  };

  useEffect(() => {
    setCurrentRecords(paginatedSessions[currentPage - 1]);
  }, [paginatedSessions, currentPage]);

  useEffect(() => {
    paginateSessions(chargingRecords);
  }, [chargingRecords]);

  return (
    <div
      className={classes.profileElement}
      data-testid='charging-station-recent-sessions-public'
    >
      <Typography
        data-testid='charging-sessions-table-header'
        classes={{
          h2: classes.chargingSessionsHeader,
        }}
        variant='h2'
      >
        User’s Charging Sessions at this Station
      </Typography>
      <div className={`${stationRefreshing ? classes.stationRefreshing : ''}`}>
        <ChargingSessions
          addBreadcrumbHandler={breadcrumbHandler}
          chargingSessions={currentRecords}
          title='User did not charge at this station yet.'
        />
        <Pagination
          itemsPerPage={itemsPerPage}
          setItemsPerPage={setItemsPerPage}
          currentPage={currentPage}
          setCurrentPage={setCurrentPage}
          pagesLength={pagesLength}
          itemSelectText='Records per page'
          showSelect={false}
          small={true}
        />
      </div>
      {chargingRecords.length > 3 && (
        <div className={classes.showMoreLink}>
          <Button
            data-testid='charging-session-button'
            color='primary'
            endIcon={<img src={DoubleArrow} alt='arrow' />}
            className={classes.showMoreButton}
            onClick={() =>
              breadcrumbHandler({
                component: 'ChargingSessionsCollection',
                friendlyText: 'Charging Sessions',
                large: true,
              })
            }
          >
            Show all sessions
          </Button>
        </div>
      )}
    </div>
  );
};

export default RecentSessions;
