import React from 'react';
import { useFormContext } from 'react-hook-form';
import Grid from '@mui/material/Grid';
import { Input, Select, ConnectForm } from '../../../../form-elements';
import { useSelectFields, useTextFields } from '../fields';
import { useTypedTranslation } from '../../../../../../custom-hooks/useTypedTranslation';
import { Typography } from '../../../../Typography/Typography';

const AdditionalInformation: React.FC = () => {
  const { t, terms } = useTypedTranslation();
  const { watch } = useFormContext();
  const textFields = useTextFields();
  const selectFields = useSelectFields();
  const isOtherReason = watch('reason') === 'other';

  return (
    <ConnectForm>
      <Grid item xs={12}>
        <Typography variant={'h4'}>
          {t(terms.order_card_dialog.additional_information)}
        </Typography>
      </Grid>
      <Grid item xs={12}>
        <Select {...selectFields.reason} />
      </Grid>
      {isOtherReason && (
        <Grid item xs={12}>
          <Input {...textFields.otherReason} />
        </Grid>
      )}
    </ConnectForm>
  );
};

export default AdditionalInformation;
