import React from 'react';
import Dialog from '@mui/material/Dialog';
import useStyles from './useStyles';
import { Typography } from '../../Typography/Typography';

export interface IOwnProps {
  open: boolean;
  onClose: (value: string) => void;
}

export const SessionState: React.FC<IOwnProps> = ({ open, onClose }) => {
  const classes = useStyles();

  return (
    <Dialog
      onClose={onClose}
      open={open}
      data-testid='more-info-dialog'
      PaperProps={{
        classes: {
          root: classes.session_dialog,
        },
      }}
    >
      <div className={classes.textWrapper}>
        <div className={classes.section}>
          <span className={classes.title}>Session state: EV detected</span>
          <Typography variant='body2'>
            The electric vehicle (EV) is connected to the station and a session
            has been successfully authorized. Begin of charging is expected to
            happen in a moment.
          </Typography>
        </div>
        <div className={classes.section}>
          <span className={classes.title}>Session state: EV charging</span>
          <Typography variant='body2'>
            The electric vehicle is being charged. The value of the field Energy
            Consumption is updated at least every 15 minutes for all stations
            equipped with a power meter.
          </Typography>
        </div>
        <div className={classes.section}>
          <span className={classes.title}>
            Session state: Suspended by station
          </span>
          <Typography variant='body2'>
            The station has suspended the running charging session. Local load
            management logic might tell the station to reduce or temporarily
            stop any power draw.
          </Typography>
        </div>
        <div className={classes.section}>
          <span className={classes.title}>Session state: Suspended by EV</span>
          <Typography variant='body2'>
            The electric vehicle has suspended the running charging session. The
            vehicle’s battery management system might chose to do that as a
            precaution.
          </Typography>
        </div>
        <div className={classes.section}>
          <span className={classes.title}>Session state: Finished</span>
          <Typography variant='body2'>
            The charging session is completed and can be viewed in the user’s
            charging history as a Charging Record.
          </Typography>
        </div>
      </div>
    </Dialog>
  );
};
