import React from 'react';

import CopyTextFieldList from '../../ui/CopyTextField/CopyTextFieldList';

export interface IOwnProps {
  userId: string;
  subscriberId: string;
  customerNumber?: string;
  subscriptionId?: string;
  tariffCode?: string;
}

const isFilled = (value: string | undefined) => {
  return !(value === undefined || value === '' || value === '-');
};

export const UserIds: React.FC<IOwnProps> = ({
  userId,
  subscriberId,
  customerNumber,
  subscriptionId,
  tariffCode,
}) => {
  const getTextList = () => {
    const textList = [];

    if (isFilled(customerNumber)) {
      textList.push({
        title: 'Customer Number',
        text: customerNumber,
      });
    }

    textList.push({
      title: 'User Id (IAM Id)',
      dataTestId: 'user-id',
      text: userId,
    });

    if (isFilled(subscriberId)) {
      textList.push({
        title: 'Subscriber Id',
        text: subscriberId,
      });
    }

    if (isFilled(subscriptionId)) {
      textList.push({
        title: 'Subscription Id',
        text: subscriptionId,
      });
    }

    if (isFilled(tariffCode)) {
      textList.push({
        title: 'Tariff Code',
        text: tariffCode,
      });
    }

    return textList;
  };

  return <CopyTextFieldList textList={getTextList()} />;
};
