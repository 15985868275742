import { Grid } from '@mui/material';
import { SessionDisplayGrid } from './SessionDisplayGrid';
import React from 'react';
import useSharedStyles from '../../shared/useSharedStyles';
import { parseChargingAddress } from '../../../utils/parseAddress';
import { TypeIndicator } from '../ChargingStation/TypeIndicator/TypeIndicator';
import {
  IConnectorModel,
  ILocationModel,
} from '../../../types/chargingSession';
import { IPublicChargingSession } from '../../../types/publicCharging';
import { Typography } from '../../shared/Typography/Typography';
import { BodySmallLink } from '@elli-eco/component-library';

interface IOwnProps {
  onStationIdClicked: (event: React.MouseEvent<HTMLAnchorElement>) => void;
  session: IPublicChargingSession;
  location?: ILocationModel;
  connector?: IConnectorModel;
  crumbId?: string;
}

export const SessionLocationDetails: React.FC<IOwnProps> = ({
  session,
  location,
  connector,
  onStationIdClicked,
}): JSX.Element => {
  const sharedClasses = useSharedStyles();

  const locationGridItems = [
    {
      title: 'CPO',
      text: location?.operator?.name ?? '-',
    },
    {
      title: 'Address',
      text: parseChargingAddress(
        location?.address ?? session.location_info.address
      ),
      width: 6,
    },
    {
      title: 'Charging Type',
      content:
        connector?.connector_type_id !== undefined ? (
          <TypeIndicator
            plugType={connector.connector_type_id}
            powerType={connector.current_type ?? '??'}
          />
        ) : (
          <></>
        ),
      text: connector?.connector_type === undefined ? '-' : undefined,
    },
    {
      title: 'Max. Charging Speed',
      text: connector?.max_electric_power
        ? `${connector?.max_electric_power / 1000} kW`
        : '-',
    },
    {
      title: 'Station Id',
      content: (
        <BodySmallLink onClick={onStationIdClicked}>
          {session.location_info.evse_id}
        </BodySmallLink>
      ),
    },
  ];

  return (
    <Grid container data-testid='location-details'>
      <Grid item xs={12} className={sharedClasses.cardDetail}>
        <Typography variant='h4'>Charging Location</Typography>
      </Grid>
      {SessionDisplayGrid(locationGridItems)}
    </Grid>
  );
};
