import React, { useEffect, useState } from 'react';
import throttle from 'lodash/throttle';
import { useBeforeunload } from 'react-beforeunload';
import './animation.css';
import {
  parseURLparameters,
  checkVinTool,
  checkWallboxTool,
} from '../../utils/parseURLparameters';
import connector, { IPropsFromState } from '../Connector/Connector';

import ActionPanel from './ActionPanel';
import { BreadcrumbModel } from '../../store/state/breadcrumbs';
import Breadcrumbs from '../Breadcrumbs/Breadcrumbs';
import CloseIcon from '@mui/icons-material/Close';
import DetailPanels from './DetailPanels';
import LoadingScreen from '../LoadingScreen/LoadingScreen';
import StaticPanel from './StaticPanel';
import VerifyCustomer from '../VerifyCustomer/VerifyCustomer';
import WelcomePanel from '../WelcomeScreen';
import useSharedStyles from '../shared/useSharedStyles';
import { useDebounceResizeObserver } from '../../custom-hooks/useDebounceResizeObserver';
import useStyles from './useStyles';
import VinEligibility from '../VinEligibility/VinEligibility';
import WallboxSNCheck from '../WallboxSNCheck/WallboxSNCheck';
import { useCCUserRolesQuery } from '../../state/queries/ccUserRoles';
import { useWrappedAuth0Hook } from '../../custom-hooks/useWrappedAuth0Hook';
import { Banner } from '@elli-eco/component-library';
import { useTypedTranslation } from '../../custom-hooks/useTypedTranslation';

type TProps = IPropsFromState;

export const CRMPanel: React.FC<TProps> = ({
  userState,
  subscriberState,
  urlQuery,
  userSearchState,
  breadcrumbState,
  getUserRequest,
  verifyUser,
  clearAll,
  getUserSearchRequest,
  clearSelectedUser,
  setVerificationStep,
  openVerificationFlow,
  setCurrentBreadcrumb,
}) => {
  const { isAuthenticated } = useWrappedAuth0Hook();
  useBeforeunload((event) => event.preventDefault());
  const sharedClasses = useSharedStyles();
  const { ref, width } = useDebounceResizeObserver(500);
  const { t, terms } = useTypedTranslation();

  const { data: ccUserRoles } = useCCUserRolesQuery();

  const user = userState;
  const userSearched = userSearchState;
  const taskBreadcrumbState = breadcrumbState;
  const subscriberData = subscriberState.data;

  const classes = useStyles({
    showBanner: subscriberData?.prevent_subscription,
  });

  const [userId, setUserId] = useState<string>('');
  const [verifyDisplayState, setVerifyDisplayState] = useState<boolean>(false);
  const [firstRender, setFirstRender] = useState(true);
  const [emailFromURL, setEmailFromURL] = useState('');
  const [isUserDataLoadingFromAPI, setUserDataLoadingFromAPI] = useState(false);
  const [programScroll, setProgramScroll] = useState<boolean>(false);
  const [
    currentBreadcrumb,
    setLocalCurrentBreadcrumb,
  ] = useState<BreadcrumbModel | null>(null);
  const urlParams = parseURLparameters(urlQuery);

  // When twilio flex is replaced, then this check should be replaced with a different route
  // e.g. /vin-eligibility-tool. This might require additional changes in the salesforce integration
  const isVinTool = checkVinTool(urlParams);
  const isWallboxTool = checkWallboxTool(urlParams);

  const userSearchedData =
    userSearched &&
    userSearched.data &&
    userSearched.data[0] &&
    userSearched.data[0].iam_user.id;

  const initUserWithoutManualVerification = () => {
    try {
      setFirstRender(false);
      setUserId(userSearchedData);
      clearSelectedUser();
    } catch (error) {
      setUserId('');
      clearSelectedUser();
    }
  };

  useEffect(() => {
    if (ref.current) {
      ref.current.focus();
    }
  }, [ref]);

  useEffect(() => {
    if (taskBreadcrumbState) {
      const current = taskBreadcrumbState.breadcrumbs.find((bc) => bc.current);

      if (current) {
        setLocalCurrentBreadcrumb(current);
      }
    }
  }, [taskBreadcrumbState]);

  useEffect(() => {
    openVerificationFlow({ open: false });
    setVerificationStep({ step: 1 });
  }, []);

  useEffect(() => {
    const email = urlParams?.email;
    if (email === undefined) {
      return;
    }
    getUserSearchRequest({
      query: email.trim(),
    });
  }, []);

  // This useEffect hook is used in the crm / salesforce context
  useEffect(() => {
    const email = urlParams?.email;

    if (typeof email === 'string' && userSearched?.data.length !== 0) {
      // Check for firstRender to be true to skip manual verification and
      // automatically navigate to the cards view
      if (userSearched?.data.length === 1 && firstRender) {
        initUserWithoutManualVerification();
      } else if (userSearched?.data.length > 1) {
        setFirstRender(false);
        setEmailFromURL(email);
        setVerifyDisplayState(true);
      }
    }
  }, [userSearched?.data]);

  useEffect(() => {
    // this is currently the only solution to make RHL possible as the CRMPanel is re-mounted on each code change
    if (process.env.NODE_ENV === 'production') {
      setUserId('');
      clearAll();
    }
  }, [clearAll]);

  useEffect(() => {
    if (userId) {
      clearAll();
      setUserDataLoadingFromAPI(true);
      getUserRequest({ userId });
      verifyUser({ verified: true });
    }
  }, [userId, getUserRequest, verifyUser]);

  useEffect(() => {
    if (currentBreadcrumb && ref.current) {
      const newPosition = currentBreadcrumb.startPoint;

      if (newPosition !== undefined && programScroll) {
        ref.current.scroll({
          top: 0,
          left: newPosition - 12,
          behavior: 'smooth',
        });

        setProgramScroll(false);
      }
    }
  }, [currentBreadcrumb]);

  const scrollHandler = (): void => {
    let newCurrentBreadcrumb = undefined;

    if (taskBreadcrumbState) {
      newCurrentBreadcrumb = taskBreadcrumbState.breadcrumbs.find((bc) => {
        if (bc.startPoint !== undefined && bc.midPoint !== undefined) {
          return (
            (ref.current as HTMLDivElement).scrollLeft > bc.startPoint - 300 &&
            (ref.current as HTMLDivElement).scrollLeft < bc.midPoint
          );
        }

        return false;
      });
    }

    if (
      newCurrentBreadcrumb &&
      currentBreadcrumb &&
      newCurrentBreadcrumb.id !== currentBreadcrumb.id
    ) {
      setCurrentBreadcrumb({
        id: newCurrentBreadcrumb.id as string,
      });
    }
  };

  const setBreadcrumb = (code: string): void => {
    if (currentBreadcrumb) {
      const currentPos = taskBreadcrumbState.breadcrumbs.findIndex(
        (bc) => bc.id === currentBreadcrumb.id
      );

      const position = code === 'ArrowLeft' ? -1 : 1;

      const newPosition =
        taskBreadcrumbState.breadcrumbs[currentPos + position];
      if (newPosition && newPosition.id) {
        setProgramScroll(true);
        setCurrentBreadcrumb({ id: newPosition.id });
      }
    }
  };

  const keyListener = (event: React.KeyboardEvent<HTMLElement>): void => {
    const elementType = (event.target as HTMLElement).tagName || '';
    const handledKeys = ['ArrowLeft', 'ArrowRight'];
    const ignoredElements = ['input', 'textarea'];

    if (
      handledKeys.includes(event.key) &&
      !ignoredElements.includes(elementType.toLowerCase())
    ) {
      event.preventDefault();
      setBreadcrumb(event.key);
    }
  };

  const hasUserData = Boolean(user && user.data);

  const renderWelcomePanel = () => (
    <div className={classes.welcomeWrapper}>
      <WelcomePanel
        setUserDataLoading={setUserDataLoadingFromAPI}
        verifyDisplayState={verifyDisplayState}
        emailFromURL={emailFromURL}
        width={width}
      />
    </div>
  );

  const isCCAuthorized = Boolean(
    isAuthenticated && (ccUserRoles?.isCCManager || ccUserRoles?.isOpsInternal)
  );

  // TODO: Split the conditional rendering and reduce the complexity by using routes
  return (
    <div
      onWheel={throttle(() => scrollHandler(), 500)}
      onKeyDown={(event) => keyListener(event)}
      className={classes.crmWrapper}
      data-testid='crm-panel'
      ref={ref}
      tabIndex={0}
    >
      {isCCAuthorized ? (
        isUserDataLoadingFromAPI ? (
          <LoadingScreen setDataLoading={setUserDataLoadingFromAPI} />
        ) : hasUserData ? (
          <>
            <div className={`${classes.topBar} ${classes.isFixed}`}>
              {subscriberData?.prevent_subscription && (
                <Banner
                  text={t(terms.general_errors.prevent_subscription)}
                  variant='error'
                  icon={true}
                  fullWidth={true}
                />
              )}
              <div className={classes.navigation}>
                <VerifyCustomer
                  setDataLoading={setUserDataLoadingFromAPI}
                  emailFromURL={emailFromURL}
                  startOpen={false}
                  width={width}
                />
                <Breadcrumbs
                  containerRef={ref}
                  setProgramScroll={setProgramScroll}
                />
                <div className={classes.closeIconWrapper}>
                  <CloseIcon
                    style={{ fill: 'white', fontSize: 14 }}
                    onClick={() => {
                      clearAll();
                      setVerifyDisplayState(false);
                    }}
                  />
                </div>
              </div>
            </div>
            <div className={classes.contentWrapper}>
              <div className={sharedClasses.overview}>
                <StaticPanel />
                <ActionPanel />
              </div>
              <DetailPanels containerRef={ref} />
            </div>
          </>
        ) : isVinTool ? (
          <VinEligibility />
        ) : isWallboxTool ? (
          <WallboxSNCheck />
        ) : (
          renderWelcomePanel()
        )
      ) : (
        renderWelcomePanel()
      )}
    </div>
  );
};

export default connector(CRMPanel);
