import { AppDispatch } from '../../../../store';
import { ActionCreator } from '../../../../store/state/actions';
import { ActionInterface } from '../../../../store/state/activeSessions/actions';
import { Dispatcher } from '../index';

interface IDispatchers {
  getActiveSessionsRequest: Dispatcher<
    ActionInterface.GetActiveSessionsRequest
  >;
}

export const createDispatchers = (dispatch: AppDispatch): IDispatchers => ({
  getActiveSessionsRequest: (payload) =>
    dispatch(ActionCreator.activeSessions.GetActiveSessionsRequest(payload)),
});
