import { Action } from 'redux';

export type ActionPayload<TAction extends Action> = Pick<
  TAction,
  Exclude<keyof TAction, 'type'>
>;

export function actionCreator<TAction extends Action>(type: string) {
  return (payload?: ActionPayload<TAction>) => {
    return { ...(payload as Record<string, unknown>), type } as TAction;
  };
}
