import {
  IPlugAndChargeEvent,
  PlugAndChargeEventStatus,
} from '../../../../types/plugAndCharge';
import { usePagination } from '../../../../custom-hooks/usePagination';
import {
  AssetTable,
  IColumn,
  IRow,
} from '../../../shared/AssetTable/AssetTable/AssetTable';
import React from 'react';
import Pagination from '../../../shared/Pagination/Pagination';
import {
  IFieldNames,
  mapFieldsForTable,
} from '../../../../utils/mapFieldsForTable';
import { formatIsoDates } from '../../../../utils/dates';
import { assertNever } from '../../../../utils/assertNever';
import { PlugAndChargeEventTypeComponent } from './PlugAndChargeEventTypeComponent';

type TOwnProps = {
  sortedEvents: IPlugAndChargeEvent[];
  sortDesc: boolean;
  setSortDesc: React.Dispatch<React.SetStateAction<boolean>>;
};

const getColumns = (): IColumn[] => {
  const fieldNames: IFieldNames = {
    date: {
      name: 'Date',
      width: '100px',
    },
    action: {
      name: 'Action',
      width: '100px',
    },
    status: {
      name: 'Status',
      width: '100px',
    },
    vin: {
      name: 'VIN',
      width: '100px',
    },
  };

  return mapFieldsForTable(fieldNames, ['date']);
};

const plugAndChargeEventStatusTextMapping = (
  status: PlugAndChargeEventStatus
) => {
  switch (status) {
    case 'PENDING':
      return 'Pending';
    case 'FAILURE':
      return 'Failure';
    case 'TRANSIENT_ERROR_RETRYING':
      return 'Error (Automatic retry)';
    case 'SUCCESS':
      return 'Success';
    case 'NOT_FOUND_EXTERNALLY':
      return 'Not found (External system)';
    default:
      return assertNever(status);
  }
};

const getRows = (events: IPlugAndChargeEvent[]): IRow[] => {
  return events.map((e) => {
    return {
      id: e.timestamp,
      data: {
        date: {
          content: formatIsoDates(e.timestamp),
        },
        action: {
          content: <PlugAndChargeEventTypeComponent status={e.type} />,
        },
        status: {
          content: e.state ? plugAndChargeEventStatusTextMapping(e.state) : '-',
        },
        vin: {
          content: e.provisioningCertificateId ?? '-',
        },
      },
    };
  });
};

export const PlugAndChargeEventHistoryTable: React.FC<TOwnProps> = ({
  sortedEvents,
  sortDesc,
  setSortDesc,
}) => {
  const {
    currentItems: currentEvents,
    itemsPerPage: eventsPerPage,
    setItemsPerPage: setEventsPerPage,
    currentPage,
    setCurrentPage,
    pagesLength,
  } = usePagination(sortedEvents);

  const sortRequestHandler = () => {
    setSortDesc(!sortDesc);
    setCurrentPage(1);
  };

  return (
    <>
      <AssetTable
        bulkActions={[]}
        columns={getColumns()}
        rows={getRows(currentEvents)}
        sortRequestHandler={sortRequestHandler}
        // This ensures that the sorting direction indicator in the column header is permanently visible
        sortingState={{ sortByColumnKey: 'date', sortDesc }}
        noDataTitle='No event history available'
        noDataSubTitle='User has not yet requested Plug & Charge'
      />
      <Pagination
        itemsPerPage={eventsPerPage}
        setItemsPerPage={setEventsPerPage}
        currentPage={currentPage}
        setCurrentPage={setCurrentPage}
        pagesLength={pagesLength}
        itemSelectText='Events per page'
      />
    </>
  );
};
