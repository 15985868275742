import { IFieldNames } from '../../../utils/mapFieldsForTable';

export const chargingRecordFieldNames: IFieldNames = {
  public: {
    name: 'Session Type',
    noPadding: true,
    align: 'right',
  },
  start_date_time: {
    name: 'Session Start',
  },
  total_time: {
    name: 'Duration',
  },
  total_energy: {
    name: 'Consumption',
  },
  total_price: {
    name: 'Price (incl. VAT)',
  },
  location_evse_id: {
    name: 'Station',
  },
  payment_type: {
    name: 'Type of Payment',
  },
  card_used: {
    name: 'Card Used',
  },
  actions: {
    name: '',
  },
};
