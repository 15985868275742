import ApiService from './api';
import { ChargingCondition } from '../types/chargingCondition';

const getByChargingConditionId = async (
  chargingConditionId: string
): Promise<ChargingCondition> => {
  const apiService = new ApiService(
    `/mgmt/customer/v1/chargingconditions/${chargingConditionId}`
  );
  const response = await apiService.query();
  return response.data;
};

export const ChargingConditionService = {
  getByChargingConditionId,
};
