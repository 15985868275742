import React, { useEffect, useRef } from 'react';
import { Tooltip } from '@mui/material';
import { TooltipProps } from '@mui/material/Tooltip';
import useStyles from './useStyles';

interface IElliTooltipProps extends TooltipProps {
  alwaysShowOnHover?: boolean;
  title: string | string[];
  heading?: string;
}

export const ElliTooltip: React.FC<IElliTooltipProps> = ({
  title,
  children,
  placement = 'top',
  alwaysShowOnHover = false,
  heading,
  ...rest
}) => {
  const classes = useStyles();
  const [arrowRef, setArrowRef] = React.useState<HTMLSpanElement | null>(null);
  const [overflown, setOverflown] = React.useState<boolean>(false);
  const contentRef = useRef<HTMLElement>(null);

  const isOverflown = () => {
    if (contentRef.current) {
      return contentRef.current.clientWidth < contentRef.current.scrollWidth;
    } else {
      return false;
    }
  };

  useEffect(() => {
    const handleResize = () => {
      setOverflown(isOverflown());
    };
    handleResize();
    window.addEventListener('resize', handleResize);
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  const childrenWithRef = React.cloneElement(children, { ref: contentRef });

  // Tooltip only displayed if the content overflows or we explicitly specified it should be
  return overflown || alwaysShowOnHover ? (
    <Tooltip
      classes={{ tooltip: classes.tooltipBox, popper: classes.popper }}
      PopperProps={{
        popperOptions: {
          modifiers: [
            {
              arrow: {
                element: arrowRef,
                enabled: Boolean(arrowRef),
              },
            },
          ],
        },
      }}
      title={
        <div className={classes.contentDiv}>
          {Array.isArray(title) ? (
            <>
              {heading && (
                <p
                  style={{
                    fontWeight: 'bold',
                  }}
                >
                  {heading}
                </p>
              )}
              {title.map((t: string) => (
                <p key={t}>{t}</p>
              ))}
            </>
          ) : (
            <>
              {heading && (
                <p
                  style={{
                    fontWeight: 'bold',
                  }}
                >
                  {heading}
                </p>
              )}
              {title}
            </>
          )}
          <span className={classes.arrow} ref={setArrowRef} />
        </div>
      }
      placement={placement}
      {...rest}
    >
      {childrenWithRef}
    </Tooltip>
  ) : (
    childrenWithRef
  );
};
