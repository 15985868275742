import React, { useState } from 'react';
import useStyles from './useStyles';
import { WallboxResultCard } from './WallboxResultCard/WallboxResultCard';
import { WallboxSearch } from './WallboxSearch/WallboxSearch';
import { Typography } from '../shared/Typography/Typography';

export const WallboxSNCheck: React.FC = () => {
  const classes = useStyles();
  const [serialNumber, setSerialNumber] = useState('');

  return (
    <>
      <div className={classes.headerBox} data-testid='wallbox-header'>
        <Typography variant='h1' className={classes.headline}>
          Wallbox Serial Number Check
        </Typography>
        <Typography variant='body2' className={classes.text}>
          To begin, please enter a valid serial number of a wallbox.
        </Typography>
        <Typography variant='body2' className={classes.text}>
          Please enter the serial number without any dashes.
        </Typography>
      </div>
      <div className={classes.searchBox} data-testid='wallbox-sn-check'>
        <WallboxSearch setSerialNumber={setSerialNumber} />
      </div>
      <div className={classes.resultBox}>
        <WallboxResultCard serialNumber={serialNumber} />
      </div>
    </>
  );
};

export default WallboxSNCheck;
