import { AxiosError } from 'axios';
import { IVehicleModel } from '../../../types/vehicle';
import { ActionCreator, ActionInterface, ActionType } from './actions';
import { all, call, debounce, put } from 'redux-saga/effects';

import { VehiclesService } from '../../../services/vehicles';

export function* vehicleSaga() {
  function* getVehicle(action: ActionInterface.GetVehicleRequest) {
    try {
      const vehicles: IVehicleModel = yield call(VehiclesService.getById, {
        vin: action.vin,
        brand: action.brand,
      });

      yield put<ActionInterface.GetVehicleSuccess>(
        ActionCreator.GetVehicleSuccess({
          data: vehicles,
        })
      );
    } catch (error) {
      yield put<ActionInterface.GetVehicleError>(
        ActionCreator.GetVehicleError({
          error: error as AxiosError,
        })
      );
    }
  }

  yield all([debounce(1000, ActionType.GetVehicleRequest, getVehicle)]);
}

export default vehicleSaga;
