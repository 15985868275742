import React, { useEffect, useState } from 'react';
import connector, { IPropsFromState } from '../../Connector/Connector';
import useSharedStyles from '../../shared/useSharedStyles';
import FirmwareTableLogo from '../../../assets/image/subscription.svg';
import useStyles from './useStyles';
import { ConsentHistory } from './ConsentHistory/ConsentHistory';
import {
  FirmwareConsentDetailModel,
  FirmwareConsentHistorySummary,
  IFirmwareInformationModel,
} from '../../../types/user';
import { StationService } from '../../../services/stations';
import Pagination from '../../shared/Pagination/Pagination';
import { paginateArray } from '../../../utils/paginateArray';
import CircularProgress from '@mui/material/CircularProgress';
import { Typography } from '../../shared/Typography/Typography';

type TOwnProps = IPropsFromState & {
  crumbId?: string;
  userId?: string;
  stationId?: string;
};

function interactedWithLatestFirmware(
  firmwareInfo: IFirmwareInformationModel | undefined
): boolean {
  return firmwareInfo &&
    firmwareInfo.recommended_firmware &&
    !firmwareInfo.recommended_firmware.consent
    ? false
    : true;
}

const groupBy = <T, K extends string>(list: T[], getKey: (item: T) => K) =>
  list.reduce((previous, currentItem) => {
    const group = getKey(currentItem);
    if (!previous[group]) {
      previous[group] = [];
    }
    previous[group].push(currentItem);
    return previous;
  }, {} as Record<K, T[]>);

function getFirmwareHistorySummary(
  firmwareHistory: FirmwareConsentDetailModel[]
): FirmwareConsentHistorySummary[] {
  const firmwareSummary: FirmwareConsentHistorySummary[] = [];
  const groupedFirmwareConsents = groupBy(
    firmwareHistory,
    (i) => i.firmware.id
  );

  for (const key in groupedFirmwareConsents) {
    let firmwareConsentHistorySummary: FirmwareConsentHistorySummary;

    const firmwareConsentsList = groupedFirmwareConsents[key];
    const firstFirmwareConsent = groupedFirmwareConsents[key].shift();

    if (firstFirmwareConsent) {
      firmwareConsentHistorySummary = {
        id: firstFirmwareConsent.id,
        decision: firstFirmwareConsent.decision,
        created_by: firstFirmwareConsent.created_by,
        created_at: firstFirmwareConsent.created_at,
        processed_at: firstFirmwareConsent.processed_at,
        user: {
          iam_id: firstFirmwareConsent.user.iam_id,
        },
        station: {
          id: firstFirmwareConsent.station.id,
          name: firstFirmwareConsent.station.name,
        },
        firmware: {
          id: firstFirmwareConsent.firmware.id,
          version: firstFirmwareConsent.firmware.version,
        },
        history: firmwareConsentsList,
      };
      firmwareSummary.push(firmwareConsentHistorySummary);
    }
  }

  return firmwareSummary;
}

export const FirmwareDetails: React.FC<TOwnProps> = ({ userId, stationId }) => {
  const classes = useStyles();
  const sharedClasses = useSharedStyles();

  const [loading, setLoading] = useState<boolean>(true);
  const [hasStationLoaded, setHasStationLoaded] = useState<boolean>(false);

  const [itemsPerPage, setItemsPerPage] = useState<number>(10);
  const [currentPage, setCurrentPage] = useState<number>(1);
  const [pagesLength, setPagesLength] = useState<number>(0);
  const [
    paginatedFirmwareConsentsHistoryAll,
    setPaginatedFirmwareConsentsHistoryAll,
  ] = useState<FirmwareConsentHistorySummary[][]>([[]]);

  const [
    paginatedFirmwareConsentsHistory,
    setPaginatedFirmwareConsentsHistory,
  ] = useState<FirmwareConsentHistorySummary[]>([]);

  const paginateConsents = (
    consents: FirmwareConsentHistorySummary[]
  ): void => {
    const ps = paginateArray(consents, itemsPerPage);
    setPaginatedFirmwareConsentsHistoryAll(ps);
    setPagesLength(ps.length);
  };

  useEffect(() => {
    setPaginatedFirmwareConsentsHistory(
      paginatedFirmwareConsentsHistoryAll[currentPage - 1]
    );
  }, [paginatedFirmwareConsentsHistoryAll, currentPage]);

  const [firmwareInformation, setFirmwareInformation] = useState<
    IFirmwareInformationModel | undefined
  >(undefined);

  const [firmwareConsentsHistory, setFirmwareConsentsHistory] = useState<
    FirmwareConsentHistorySummary[] | undefined
  >(undefined);

  useEffect(() => {
    if (hasStationLoaded) {
      setLoading(false);
    }
  }, [hasStationLoaded]);

  useEffect(() => {
    const fch = firmwareConsentsHistory;
    if (fch) {
      setCurrentPage(1);
      paginateConsents(fch);
    }
  }, [firmwareConsentsHistory, itemsPerPage]);

  useEffect(() => {
    const getFirmwareInformation = async (id: string): Promise<void> => {
      try {
        const firmwareResponse = await StationService.getStationFirmwareInformation(
          id
        );
        setFirmwareInformation(firmwareResponse);
      } catch (error) {
        setFirmwareInformation(undefined);
      }
    };

    if (stationId) {
      getFirmwareInformation(stationId);
    }
  }, [stationId]);

  useEffect(() => {
    const getFirmwareConsentsHistory = async (
      userId: string,
      stationId: string
    ): Promise<void> => {
      try {
        const firmwareConsentsHistoryResponse = await StationService.getFirmwareConsentsHistory(
          userId,
          stationId
        );

        const rows = [];
        if (
          !interactedWithLatestFirmware(firmwareInformation) &&
          firmwareInformation
        ) {
          const latestFirmware: FirmwareConsentDetailModel = {
            id: 'missingConsent',
            decision: undefined,
            created_by: undefined,
            created_at: undefined,
            processed_at: undefined,
            user: {
              iam_id: 'iam_id',
            },
            station: {
              id: 'station_id',
            },
            firmware: {
              id: 'latestFirmware',
              version: firmwareInformation.recommended_firmware?.version,
            },
          };
          rows.push(latestFirmware);
        }
        rows.push(...firmwareConsentsHistoryResponse.firmware_consents);

        const firmwareHistorySummary = getFirmwareHistorySummary(rows);
        setFirmwareConsentsHistory(firmwareHistorySummary);
      } catch (error) {
        setFirmwareConsentsHistory(undefined);
      }
      setHasStationLoaded(true);
    };

    if (userId && stationId && firmwareInformation) {
      getFirmwareConsentsHistory(userId, stationId);
    }
  }, [stationId, userId, firmwareInformation]);

  return (
    <div
      className={sharedClasses.cardElement}
      data-testid='firmware-details-wrapper'
    >
      <div className={classes.chargingCardDetailsHeader}>
        <div className={classes.titleWrapper}>
          <div className={classes.logoContainer}>
            <img src={FirmwareTableLogo} alt='firmware table logo' />
          </div>
          <Typography
            variant='h1'
            data-testid='firmware-details-headline'
            classes={{
              root: classes.panelTitle,
            }}
          >
            Firmware Consent History
          </Typography>
        </div>
      </div>
      {loading ? (
        <div
          style={{
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center',
            alignItems: 'center',
            height: 241,
          }}
        >
          <CircularProgress data-testid='loading' />
        </div>
      ) : (
        <div>
          <ConsentHistory
            firmwareInformation={firmwareInformation}
            firmwareConsentsHistory={paginatedFirmwareConsentsHistory}
          />
          <Pagination
            itemsPerPage={itemsPerPage}
            setItemsPerPage={setItemsPerPage}
            currentPage={currentPage}
            setCurrentPage={setCurrentPage}
            pagesLength={pagesLength}
            itemSelectText='Records per page'
          />
        </div>
      )}
    </div>
  );
};

export default connector(FirmwareDetails);
