import { IUserModel } from '../../../types/user';
/* eslint-disable @typescript-eslint/naming-convention */
import { actionCreator } from '../../type-utils';

export const ActionType = {
  GetUserRequest: 'user/get-user-request',
  GetUserSuccess: 'user/get-user-success',
  GetUserError: 'user/get-user-error',
  VerifyUser: 'user/verify-user',
  ClearUser: 'user/clear-user',
  UpdateUser: 'user/update-user',
  UpdateUserSuccess: 'user/update-user-success',
  UpdateUserError: 'user/update-user-error',
};

export declare namespace ActionInterface {
  export interface GetUserRequest {
    type: any;
    userId: string;
  }

  export interface GetUserSuccess {
    type: any;
    data: IUserModel;
  }

  export interface GetUserError {
    type: any;
    error: Error;
  }

  export interface VerifyUser {
    type: any;
    verified: boolean;
  }

  export interface ClearUser {
    type: any;
  }

  export interface UpdateUser {
    type: any;
    userId: string;
    firstName: string;
    lastName: string;
  }

  export interface UpdateUserSuccess {
    type: any;
    firstName: string;
    lastName: string;
  }

  export interface UpdateUserError {
    type: any;
    error: Error;
  }
}

export const ActionCreator = {
  GetUserRequest: actionCreator<ActionInterface.GetUserRequest>(
    ActionType.GetUserRequest
  ),
  GetUserSuccess: actionCreator<ActionInterface.GetUserSuccess>(
    ActionType.GetUserSuccess
  ),
  GetUserError: actionCreator<ActionInterface.GetUserError>(
    ActionType.GetUserError
  ),
  VerifyUser: actionCreator<ActionInterface.VerifyUser>(ActionType.VerifyUser),
  ClearUser: actionCreator<ActionInterface.ClearUser>(ActionType.ClearUser),
  UpdateUserRequest: actionCreator<ActionInterface.UpdateUser>(
    ActionType.UpdateUser
  ),
  UpdateUserSuccess: actionCreator<ActionInterface.UpdateUserSuccess>(
    ActionType.UpdateUserSuccess
  ),
  UpdateUserError: actionCreator<ActionInterface.UpdateUserError>(
    ActionType.UpdateUserError
  ),
};
