import {
  BodySmall,
  Button,
  Dialog,
  DialogBody,
  DialogFooter,
  DialogHeader,
} from '@elli-eco/component-library';
import { Grid, useTheme } from '@mui/material';
import React, { MouseEventHandler, ReactNode } from 'react';

import { IPublicChargingSession } from '../../../types/publicCharging';
import { ConfirmationInfo } from './ConfirmationInfo';
import { useStyles } from './useStyles';

interface ISessionChangeConfirmationDialogProps {
  open: boolean;
  session: IPublicChargingSession;
  dialogHeader: string;
  upperMessage: string;
  lowerMessage: string;
  noCallback: () => void;
  yesCallback: MouseEventHandler<HTMLButtonElement>;
  yesText: string;
  yesButtonClassName: 'dismissButton' | 'stopButton';
  noText: string;
  loading: boolean;
  loadingItem: ReactNode;
}

export const SessionChangeConfirmationDialog = ({
  open,
  session,
  dialogHeader,
  upperMessage,
  lowerMessage,
  noCallback,
  yesCallback,
  yesText,
  yesButtonClassName,
  noText,
  loading,
  loadingItem,
}: ISessionChangeConfirmationDialogProps): JSX.Element => {
  const theme = useTheme();
  const classes = useStyles(theme);
  return (
    <Dialog size='M' open={open}>
      <DialogHeader>{dialogHeader}</DialogHeader>
      <DialogBody>
        <BodySmall>
          {upperMessage} <ConfirmationInfo session={session} />
        </BodySmall>
        <BodySmall>{lowerMessage}</BodySmall>
      </DialogBody>
      <DialogFooter>
        <Grid container className={classes.dialogButtons}>
          {!loading ? (
            <>
              <Grid item>
                <Button variant='outlined' onClick={noCallback}>
                  {noText}
                </Button>
              </Grid>
              <Grid item>
                <Button
                  variant='alert'
                  className={classes[yesButtonClassName]}
                  onClick={yesCallback}
                >
                  {yesText}
                </Button>
              </Grid>
            </>
          ) : (
            <Grid item>{loadingItem}</Grid>
          )}
        </Grid>
      </DialogFooter>
    </Dialog>
  );
};
