/* eslint-disable @typescript-eslint/naming-convention */
import { IInvoiceListItem } from '../../../types/invoice';
import { ISortState } from '../../../components/shared/AssetTable/AssetTable/AssetTable';
import { actionCreator } from '../../type-utils';

export const ActionType = {
  GetInvoicesRequest: 'invoices/get-invoices-request',
  GetInvoicesSuccess: 'invoices/get-invoices-success',
  GetInvoicesError: 'invoices/get-invoices-error',
  SortInvoices: 'invoices/sort-invoices',
};

export declare namespace ActionInterface {
  export interface GetInvoicesRequest {
    type: string;
    subscriberId: string;
  }

  export interface GetInvoicesSuccess {
    type: string;
    data: IInvoiceListItem[];
  }

  export interface GetInvoicesError {
    type: string;
    error: Error;
  }

  export interface SortInvoices {
    type: string;
    sortState: ISortState;
  }
}

export const ActionCreator = {
  GetInvoicesRequest: actionCreator<ActionInterface.GetInvoicesRequest>(
    ActionType.GetInvoicesRequest
  ),
  GetInvoicesSuccess: actionCreator<ActionInterface.GetInvoicesSuccess>(
    ActionType.GetInvoicesSuccess
  ),
  GetInvoicesError: actionCreator<ActionInterface.GetInvoicesError>(
    ActionType.GetInvoicesError
  ),
  SortInvoices: actionCreator<ActionInterface.SortInvoices>(
    ActionType.SortInvoices
  ),
};
