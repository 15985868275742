import { ActionInterface } from '../actions';
import { State } from '..';
import serializeError from 'serialize-error';
import update from 'immutability-helper';

export default (
  state: State,
  action: ActionInterface.GetOrganizationError
): State => {
  const { error } = action;
  const currentOrganization = state || {
    loading: true,
    data: null,
    error: null,
    request: null,
    stations: null,
    accounts: null,
  };

  const updatedOrganization = update(currentOrganization, {
    error: {
      $set: serializeError(error),
    },
    loading: {
      $set: false,
    },
    data: {
      $set: null,
    },
    stations: {
      $set: null,
    },
    accounts: {
      $set: null,
    },
  });

  return {
    ...state,
    ...updatedOrganization,
  };
};
