import { alpha, Theme } from '@mui/material/styles';

import createStyles from '@mui/styles/createStyles';
import makeStyles from '@mui/styles/makeStyles';

export const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      fontSize: 24,
      color: theme.colorPrimary,
    },
    open: {
      color: theme.surfaceTertiary,
    },
    error: {
      fill: alpha(theme.textPrimary, 0.54),
    },
  })
);
