import React from 'react';
import { Grid } from '@mui/material';

import { IChargingRecordViewModel } from '../../../../types/chargingRecords';
import decimalToComma from '../../../../utils/decimalToComma';
import { formatIsoDates, formatShortDateTime } from '../../../../utils/dates';
import parseSeconds from '../../../../utils/parseSeconds';
import useSharedStyles from '../../../shared/useSharedStyles';
import useStyles from './useStyles';
import { Typography } from '../../../shared/Typography/Typography';
import { ChargingCondition } from '../../../../types/chargingCondition';
import { ITariffConditionModel } from '../../../../types/tariff';

interface IOwnProps {
  chargingSession: IChargingRecordViewModel;
  chargingCondition?: ChargingCondition;
  tariff?: ITariffConditionModel;
}

const Overview: React.FC<IOwnProps> = ({
  chargingSession,
  chargingCondition,
  tariff,
}) => {
  const sharedClasses = useSharedStyles();
  const classes = useStyles();

  const blockingFee = chargingCondition
    ? chargingSession.location_connector_power_type === 'DC'
      ? chargingCondition.blocking_fee_dc
      : chargingCondition.blocking_fee_ac
    : null;

  const blockingFeeApplicableDuration = blockingFee
    ? `${blockingFee.application_start_local_time.substring(0, 5)} 
      - ${blockingFee.application_end_local_time.substring(0, 5)}`
    : 'Loading';

  return (
    <>
      <Grid item xs={12}>
        <div className={sharedClasses.cardDetail}>
          <Typography variant='h4'>
            <span data-testid='public-overview-title'>Overview</span>
          </Typography>
        </div>
      </Grid>
      <Grid item xs={4}>
        <div className={sharedClasses.cardDetail}>
          <Typography variant='h6'>Session Start/End</Typography>
          <div className={sharedClasses.cardDetailData}>
            <Typography variant='body2'>
              {`
              ${formatShortDateTime(chargingSession.start_date_time)} - 
                ${
                  chargingSession.stop_date_time
                    ? formatShortDateTime(chargingSession.stop_date_time)
                    : '-'
                }
              `}
            </Typography>
          </div>
        </div>
      </Grid>
      <Grid item xs={4}>
        <div className={sharedClasses.cardDetail}>
          <Typography variant='h6'>Received At</Typography>
          <div className={sharedClasses.cardDetailData}>
            <Typography variant='body2'>
              {formatIsoDates(chargingSession.creation_date)}
            </Typography>
          </div>
        </div>
      </Grid>
      <Grid item xs={4}>
        <div className={sharedClasses.cardDetail}>
          <Typography variant='h6'>Tariff Name</Typography>
          <div className={sharedClasses.cardDetailData}>
            <Typography variant='body2'>{tariff?.name}</Typography>
          </div>
        </div>
      </Grid>
      <Grid item xs={4}>
        <div className={sharedClasses.cardDetail}>
          <Typography variant='h6'>Session Duration</Typography>
          <div className={sharedClasses.cardDetailData}>
            <Typography variant='body2'>
              {parseSeconds(chargingSession.duration)}
            </Typography>
          </div>
        </div>
      </Grid>
      <Grid item xs={4}>
        <div className={sharedClasses.cardDetail}>
          <Typography variant='h6'>Product Type</Typography>
          <div className={sharedClasses.cardDetailData}>
            <Typography variant='body2'>
              {chargingSession.location_connector_power_type}
            </Typography>
          </div>
        </div>
      </Grid>
      <Grid item xs={4}>
        <div className={sharedClasses.cardDetail}>
          <Typography variant='h6'>Blocking fee applicable</Typography>
          <div className={sharedClasses.cardDetailData}>
            <Typography variant='body2'>
              {blockingFeeApplicableDuration}
            </Typography>
          </div>
        </div>
      </Grid>
      <Grid item xs={4}>
        <div className={sharedClasses.cardDetail}>
          <Typography variant='h6'>Billing Status</Typography>
          <div className={classes.addressElement}>
            <Typography className={classes.billingStatus} variant='body2'>
              {chargingSession.billing_status}
            </Typography>
          </div>
        </div>
      </Grid>
      <Grid item xs={8}>
        <div className={sharedClasses.cardDetail}>
          <Typography variant='h6'>Energy Consumption</Typography>
          <div className={sharedClasses.cardDetailData}>
            <Typography variant='body2'>
              {chargingSession.consumption
                ? `${decimalToComma(
                    chargingSession.consumption.toFixed(3)
                  )} kWh`
                : '-'}
            </Typography>
          </div>
        </div>
      </Grid>
    </>
  );
};

export default Overview;
