import React from 'react';
import { IVehicleSubscriptionModel } from '../../../../types/vehicle';
import useStyles from './useStyles';
import { Grid } from '@mui/material';
import InformationItem from '../../../shared/InformationItems/InformationItem';
import { useTypedTranslation } from '../../../../custom-hooks/useTypedTranslation';
import { ElliTooltip } from '../../../shared/ElliTooltip/ElliTooltip';
import { SubscriptionStatus } from '../../../../types/subscriber';
import { SubscriptionStatusComponent } from '../../../SubscriptionStatus/SubscriptionStatusComponent';

type TOwnProps = {
  subscription: IVehicleSubscriptionModel;
};

export const AssociatedSubscription: React.FC<TOwnProps> = ({
  subscription,
}) => {
  const classes = useStyles();
  const { t, terms } = useTypedTranslation();

  const getRowItems = (rowSubscription: IVehicleSubscriptionModel) => {
    return [
      {
        id: 'subscription-tariff',
        headline: t(terms.vin_eligibility.vin_overview.paired_subscription),
        content: (
          <ElliTooltip
            heading={t(terms.vin_eligibility.vin_overview.paired_subscription)}
            title={rowSubscription.tariff_name}
            alwaysShowOnHover={true}
          >
            <span className={classes.itemValue}>
              {rowSubscription.tariff_name}
            </span>
          </ElliTooltip>
        ),
      },
      {
        id: 'status',
        headline: t(terms.vin_eligibility.vin_overview.status),
        content: (
          <SubscriptionStatusComponent
            status={rowSubscription.status as SubscriptionStatus}
          />
        ),
      },
      {
        id: '',
        headline: '',
        content: '',
      },
      {
        id: 'subscription-email',
        headline: t(terms.vin_eligibility.vin_overview.email),
        content: (
          <ElliTooltip
            heading={t(terms.vin_eligibility.vin_overview.email)}
            title={rowSubscription.email}
            alwaysShowOnHover={true}
          >
            <span className={classes.itemValue}>{rowSubscription.email}</span>
          </ElliTooltip>
        ),
      },
      {
        id: 'subscription-iam-id',
        headline: t(terms.vin_eligibility.vin_overview.iam_user_id),
        content: (
          <ElliTooltip
            heading={t(terms.vin_eligibility.vin_overview.iam_user_id)}
            title={rowSubscription.iam_id}
            alwaysShowOnHover={true}
          >
            <span className={classes.itemValue}>{rowSubscription.iam_id}</span>
          </ElliTooltip>
        ),
      },
      {
        id: 'subscription-customer-number',
        headline: t(terms.vin_eligibility.vin_overview.customer_number),
        content: (
          <ElliTooltip
            heading={t(terms.vin_eligibility.vin_overview.customer_number)}
            title={rowSubscription.customer_number ?? '-'}
            alwaysShowOnHover={true}
          >
            <span className={classes.itemValue}>
              {rowSubscription.customer_number ?? '-'}
            </span>
          </ElliTooltip>
        ),
      },
    ];
  };

  return (
    <>
      <div className={classes.subscriptionInfo}>
        <Grid
          container
          justifyContent='flex-start'
          alignItems='flex-start'
          direction='row'
          className={classes.grid}
        >
          {getRowItems(subscription).map((item) => (
            <Grid item xs={4} key={item.id}>
              <InformationItem item={item} />
            </Grid>
          ))}
        </Grid>
      </div>
    </>
  );
};

export default AssociatedSubscription;
