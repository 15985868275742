export enum PaymentMethodStatus {
  Valid = 'Valid',
  Unverified = 'Unverified',
  In_Verification = 'In Verification',
  Not_Available = 'Not Available',
  Expired = 'Expired',
}

export interface IPaymentMethod {
  ownerName: string;
  name: string;
  carrierNumber: string;
  paymentType: string;
  expiryMonth: number;
  expiryYear: number;
  bankName: string;
  statusCode: PaymentMethodStatus;
  isDefault: boolean;
  iconUrl?: string;
}

export interface ICoupon {
  vouchers: IVoucher[];
  campaigns: ICampaign[];
}

export interface IVoucher {
  code: string;
  currency: string;
  original_credit: number;
  remaining_credit: number;
  status: string;
  expires_at?: string;
  reserved_at?: string;
}

export interface ICampaign {
  code: string;
  title: string;
  short_description: string;
  description: string;
  start_date: string;
  end_date: string;
}
