import { State, SubscriberStateModel } from '..';

import { ActionInterface } from '../actions';
import update from 'immutability-helper';

export default (
  state: State,
  action: ActionInterface.UpdateBillingAddressRequest
): State => {
  const currentSubscriberState = state || {
    loading: true,
    data: null,
    error: null,
    request: null,
  };

  const updatedSubscriberState: SubscriberStateModel = update(
    currentSubscriberState,
    {
      error: {
        $set: null,
      },
      loading: {
        $set: true,
      },
      request: {
        $set: {
          userId: action.subscriberId,
          action: action.type,
        },
      },
    }
  );

  return {
    ...state,
    ...updatedSubscriberState,
  };
};
