import React from 'react';
import Grid from '@mui/material/Grid';
import { Input, Select, ConnectForm } from '../../../../form-elements';
import { useSelectFields, useTextFields } from '../fields';
import { useTypedTranslation } from '../../../../../../custom-hooks/useTypedTranslation';
import { Typography } from '../../../../Typography/Typography';

const ShippingAddress: React.FC = () => {
  const { t, terms } = useTypedTranslation();
  const textFields = useTextFields();
  const selectFields = useSelectFields();

  return (
    <ConnectForm>
      <Grid item xs>
        <Typography variant={'h4'}>
          {t(terms.order_card_dialog.shipping_address)}
        </Typography>
      </Grid>

      <Grid item xs={12}>
        <Input {...textFields.name} />
      </Grid>
      <Grid item xs={12}>
        <Input {...textFields.addressLine1} />
      </Grid>
      <Grid item xs={12}>
        <Input {...textFields.addressLine2} />
      </Grid>

      <Grid container item spacing={2}>
        <Grid item xs={4}>
          <Input {...textFields.zip} />
        </Grid>
        <Grid item xs={8}>
          <Input {...textFields.city} />
        </Grid>
      </Grid>

      <Grid container item spacing={2}>
        <Grid item xs={6}>
          <Input {...textFields.state} />
        </Grid>
        <Grid item xs={6}>
          <Select {...selectFields.country} />
        </Grid>
      </Grid>
    </ConnectForm>
  );
};

export default ShippingAddress;
