import React from 'react';

import RemoveIcon from '@mui/icons-material/Remove';
import AddIcon from '@mui/icons-material/Add';
import useStyles from './useStyles';

interface IZoomProps {
  zoom: number;
  setZoom: (zoom: number) => void;
}

export const CustomZoom: React.FC<IZoomProps> = ({ zoom, setZoom }) => {
  const classes = useStyles();
  return (
    <div className={classes.customZoomWrapper}>
      <div
        data-testid='zoom-plus'
        className={classes.zoomIconWrapper}
        onClick={() => setZoom(zoom + 1)}
      >
        <AddIcon className={classes.zoomIcon} />
      </div>

      <div
        data-testid='zoom-minus'
        className={classes.zoomIconWrapper}
        onClick={() => setZoom(zoom - 1)}
      >
        <RemoveIcon className={classes.zoomIcon} />
      </div>
    </div>
  );
};
