/* eslint-disable @typescript-eslint/naming-convention */
import { ErrorObject } from 'serialize-error';
import { IUserModel } from '../../../types/user';

export interface GetUserRequest {
  userId: string;
}

export interface UserStateModel {
  verified: boolean;
  loading: boolean;
  data: IUserModel | null;
  error: ErrorObject | null;
  request: { userId: string } | null;
}

export const initialState: UserStateModel = {
  verified: false,
  loading: false,
  data: null,
  error: null,
  request: null,
};

const state: UserStateModel = {
  ...initialState,
};

export type State = typeof state;
export default state;
