import makeStyles from '@mui/styles/makeStyles';
import createStyles from '@mui/styles/createStyles';

const useStyles = makeStyles(() =>
  createStyles({
    cardImage: {
      width: '100%',
      borderRadius: '8px',
    },
  })
);

export default useStyles;
