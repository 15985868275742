import makeStyles from '@mui/styles/makeStyles';
import createStyles from '@mui/styles/createStyles';

const useStyles = makeStyles(() =>
  createStyles({
    dialog: {
      padding: 32,
      width: 410,
    },
    textWrapper: {
      display: 'flex',
      flexFlow: 'column nowrap',
      marginBottom: 20,
      '&:last-child': {
        marginBottom: 0,
      },
    },
    title: {
      fontWeight: 600,
      fontSize: 12,
      marginBottom: 6,
      display: 'block',
    },
    section: {
      marginBottom: 24,
      '&:last-child': {
        marginBottom: 0,
      },
    },
    detailWrapper: {
      marginBottom: '16px',
      padding: '8px 16px',
    },
    statusWrapper: {
      display: 'flex',
      alignItems: 'center',
    },
    activationStatus: {
      display: 'inline-block',
      marginRight: '4px',
      verticalAlign: 'baseline',
    },
    statusText: {
      textTransform: 'capitalize', // !important',
    },
  })
);

export default useStyles;
