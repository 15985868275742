import { AxiosError } from 'axios';
import { ActionCreator, ActionInterface, ActionType } from './actions';
import {
  ActionCreator as ActiveSessionsActionCreator,
  ActionInterface as ActiveSessionsActionInterface,
} from '../activeSessions/actions';
import {
  ActionCreator as HomeStationsActionCreator,
  ActionInterface as HomeStationsActionInterface,
} from '../homeStations/actions';
import {
  ActionCreator as OrganizationActionCreator,
  ActionInterface as OrganizationActionInterface,
} from '../organization/actions';
import {
  ActionCreator as SubscriberActionCreator,
  ActionInterface as SubscriberActionInterface,
} from '../subscriber/actions';
import {
  ActionCreator as PaymentDetailsActionCreator,
  ActionInterface as PaymentDetailsActionInterface,
} from '../paymentDetails/actions';
import { all, call, put, takeEvery } from 'redux-saga/effects';

import { IUserModel } from '../../../types/user';
import { UsersService } from '../../../services/users';

export function* userSaga() {
  function* getUser(action: ActionInterface.GetUserRequest) {
    try {
      const user: IUserModel = yield call(UsersService.getById, {
        userId: action.userId,
      });

      yield put<ActionInterface.GetUserSuccess>(
        ActionCreator.GetUserSuccess({
          data: user,
        })
      );

      yield put<OrganizationActionInterface.GetOrganizationRequest>(
        OrganizationActionCreator.GetOrganizationRequest({
          organizationId: user.organisation_id || '',
        })
      );

      yield put<HomeStationsActionInterface.GetHomeStationsRequest>(
        HomeStationsActionCreator.GetHomeStationsRequest({
          user_id: user.id,
        })
      );

      yield put<ActiveSessionsActionInterface.GetActiveSessionsRequest>(
        ActiveSessionsActionCreator.GetActiveSessionsRequest({
          userId: user.id,
        })
      );

      yield put<SubscriberActionInterface.GetSubscriberRequest>(
        SubscriberActionCreator.GetSubscriberRequest({
          userId: action.userId,
          organizationId: user.organisation_id || '',
        })
      );

      yield put<PaymentDetailsActionInterface.GetPaymentDetailsRequest>(
        PaymentDetailsActionCreator.GetPaymentDetailsRequest({
          userId: action.userId,
        })
      );
    } catch (error) {
      yield put<ActionInterface.GetUserError>(
        ActionCreator.GetUserError({
          error: error as AxiosError,
        })
      );
    }
  }

  function* updateUser(action: ActionInterface.UpdateUser) {
    try {
      yield call(UsersService.updateUser, {
        user_id: action.userId,
        first_name: action.firstName,
        last_name: action.lastName,
      });

      yield put<ActionInterface.UpdateUserSuccess>(
        ActionCreator.UpdateUserSuccess({
          firstName: action.firstName,
          lastName: action.lastName,
        })
      );
    } catch (error) {
      yield put<ActionInterface.UpdateUserError>(
        ActionCreator.UpdateUserError({
          error: error as AxiosError,
        })
      );
    }
  }

  yield all([takeEvery(ActionType.GetUserRequest, getUser)]);
  yield all([takeEvery(ActionType.UpdateUser, updateUser)]);
}

export default userSaga;
