import { Theme } from '@mui/material';

import makeStyles from '@mui/styles/makeStyles';
import createStyles from '@mui/styles/createStyles';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    showMoreLink: {
      marginLeft: 12,
      top: -42,
      position: 'relative',
      fontSize: 14,
      maxWidth: 200,
      marginBottom: -42,
    },
    tabPanelRoot: {
      padding: '24px 0',
    },
    tabWrapperRoot: {
      minHeight: 28,
    },
    chargingCardDetailsHeader: {
      position: 'relative',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'space-between',
      marginBottom: 16,
    },
    titleWrapper: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'flex-start',
    },
    titleIcon: {
      color: theme.colorPrimary,
      fontSize: 24,
      marginRight: 16,
    },
    actionIcon: {
      color: theme.colorPrimary,
      fontSize: 24,
    },
    panelTitle: {
      fontSize: 20,
    },
    tabText: {
      color: '#000',
      fontWeight: 600,
    },
    selectedText: {
      color: theme.colorPrimary,
    },
    tabButtonRoot: {
      padding: '0 4px 6px',
      fontWeight: 600,
      minWidth: 10,
      margin: '0 20px',
      minHeight: 28,
    },
    divider: {
      width: 'calc(100% + 32px)',
      marginLeft: -16,
      backgroundColor: 'rgba(0, 0, 0, 0.08)',
    },
    chargingSessionsHeader: {
      fontSize: 16,
      fontWeight: 600,
      lineHeight: 1.38,
      marginBottom: 16,
    },
    showMoreButton: {
      fontWeight: 'normal',
    },
    confirmationPopupList: {
      listStyle: 'disc',
      listStylePosition: 'outside',
      maxWidth: 400,
    },
  })
);

export default useStyles;
