import React from 'react';
import { Grid } from '@mui/material';
import useStyles from './useStyles';
import { useTypedTranslation } from '../../../../custom-hooks/useTypedTranslation';
import InformationItem from '../../../shared/InformationItems/InformationItem';
import { PaymentMethodStatus } from '../../../../types/paymentMethods';
import { PaymentMethodStatusComponent } from './PaymentMethodStatusComponent';
import { Typography } from '../../../shared/Typography/Typography';

interface IPaymentEWalletInformation {
  email: string;
  status: PaymentMethodStatus;
}

export const PaymentEWalletInformation: React.FC<IPaymentEWalletInformation> = ({
  email,
  status,
}) => {
  const { t, terms } = useTypedTranslation();
  const classes = useStyles();
  const getItems = () => {
    return [
      {
        id: 'ewallet-email',
        headline: t(terms.payment_methods.email),
        content: (
          <>
            <Typography variant='body2'>{email}</Typography>
          </>
        ),
      },
      {
        id: 'payment-method-status',
        headline: t(terms.payment_methods.status),
        content: <PaymentMethodStatusComponent status={status} />,
      },
    ];
  };

  return (
    <div className={classes.cardInformation}>
      <Grid container>
        {getItems().map((item) => (
          <Grid item xs={6} key={item.id}>
            <InformationItem item={item} />
          </Grid>
        ))}
      </Grid>
    </div>
  );
};
