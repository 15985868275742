import { Theme } from '@mui/material/styles';

import createStyles from '@mui/styles/createStyles';
import makeStyles from '@mui/styles/makeStyles';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    brandBox: {
      height: '48px',
      borderBottom: '1px solid #DADCE0',
      maxWidth: '1028px',
      minWidth: '640px',
      margin: '0 16px',
    },
    searchBox: {
      display: 'flex',
      flexDirection: 'row',
      justifyContent: 'space-between',
      alignItems: 'center',
      maxWidth: '1028px',
      minWidth: '640px',
      height: '100px',
      margin: '0 16px',
    },
    resultBox: {
      boxShadow: '0 2px 8px 0 rgb(0 0 0 / 8%)',
      maxWidth: '1028px',
      minWidth: '640px',
      minHeight: '600px',
      backgroundColor: '#ffffff',
      margin: '0 16px',
    },
    emptyResult: {
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'center',
      maxWidth: '1028px',
      minWidth: '640px',
      padding: '50px',
    },
    headline: {
      fontWeight: 600,
      color: theme.colorPrimary,
      textAlign: 'center',
    },
    text: {
      color: theme.colorPrimary,
      textAlign: 'center',
    },
  })
);

export default useStyles;
