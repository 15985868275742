import { Theme } from '@mui/material';

import makeStyles from '@mui/styles/makeStyles';
import createStyles from '@mui/styles/createStyles';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    bar: {
      height: '56px',
      marginRight: '10px',
      display: 'flex',
      fontSize: '14px',
      fontFamily: 'Inter, sans-serif',
      fontWeight: 600,
      lineHeight: '1.1876em',
      letterSpacing: '0.14px',
      backgroundColor: theme.surfacePrimary,
      border: '1px solid #ccc',
    },
  })
);

export default useStyles;
