/* eslint-disable @typescript-eslint/naming-convention */
import { ErrorObject } from 'serialize-error';
import { ICardOrderModel } from '../../../types/subscriber';

export interface GetCardOrderRequest {
  subscriberId: string;
}

export interface CardOrderStateModel {
  loading: boolean;
  data: ICardOrderModel[] | null;
  error: ErrorObject | null;
  request: { subscriberId: string } | null;
}

export const initialState: CardOrderStateModel = {
  loading: false,
  data: null,
  error: null,
  request: null,
};

const state: CardOrderStateModel = {
  ...initialState,
};

export type State = typeof state;
export default state;
