import React from 'react';
import useStyles from './useStyles';
import { ICell, IColumn, IRow } from './AssetTable';
import { TableCell, TableRow } from '@mui/material';

interface IAssetTableRow {
  selected: string[];
  rowSelected: boolean;
  onRowClick: ((row: IRow) => void) | undefined;
  onClickRowEvent: (() => void) | undefined;
  columns: IColumn[];
  getTableCellClasses: (column: IColumn) => string;
  row: IRow;
  addBulkEditingInfo: (cell: ICell) => ICell['content'];
  condensed?: boolean;
}

export const AssetTableRow: React.FC<IAssetTableRow> = ({
  selected,
  rowSelected,
  onRowClick,
  onClickRowEvent,
  condensed,
  columns,
  getTableCellClasses,
  row,
  addBulkEditingInfo,
}) => {
  const classes = useStyles();
  return (
    <React.Fragment>
      <TableRow
        data-testid={`table-row-${row.id}`}
        key={Math.random()}
        className={`${classes.tableRow} ${
          rowSelected ? classes.tableRowSelected : ''
        } ${selected.length > 0 ? classes.tableRowAnySelected : ''} ${
          onRowClick ? classes.tableRowClickable : ''
        }`}
        onClick={onClickRowEvent}
        style={{ height: condensed ? '48px' : '56px' }}
      >
        {columns.map((column) => (
          <TableCell
            className={getTableCellClasses(column)}
            key={`${row.id}-${column.key}`}
            align={column.align || 'inherit'}
          >
            {addBulkEditingInfo(row.data[column.key])}
          </TableCell>
        ))}
      </TableRow>
    </React.Fragment>
  );
};
