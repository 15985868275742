import React from 'react';

import { useTheme } from '@mui/material/styles';
import { PaymentStatus } from '../../../types/payment';
import {
  BasicStatus,
  IMappedStatus,
} from '../../shared/StatusIndicator/BasicStatus';
import { Skeleton } from '@mui/material';
import { useGetPaymentStatus } from '../../../state/queries/useGetPaymentStatus';
import { TransactionState, TransactionType } from '../../../types/transaction';

export const PaymentStatusIndicator: React.FC<{
  transactionType: TransactionType;
  transactionNumber: string;
  transactionState: TransactionState;
}> = ({ transactionType, transactionNumber, transactionState }) => {
  const theme = useTheme();
  const { data, isLoading } = useGetPaymentStatus(
    transactionType,
    transactionNumber,
    transactionState
  );

  if (isLoading) {
    return <Skeleton />;
  }

  const paymentStatus = data || PaymentStatus.Unknown;

  const valueMapper = (value: string): IMappedStatus => {
    switch (value) {
      case PaymentStatus.Processing:
        return {
          color: theme.colorAlertSecondary,
          label: PaymentStatus.Processing,
        };
      case PaymentStatus.Paid:
        return {
          color: theme.colorAccent,
          label: PaymentStatus.Paid,
        };
      case PaymentStatus.Failed:
        return {
          color: theme.colorAlertPrimary,
          label: PaymentStatus.Failed,
        };
      default:
        return {
          color: theme.surfaceTertiary,
          label: PaymentStatus.Unknown,
        };
    }
  };

  return <BasicStatus status={paymentStatus} valueMapper={valueMapper} />;
};
