import React, { useEffect, useRef } from 'react';
import connector, { IPropsFromState } from '../Connector/Connector';

import { BreadcrumbModel } from '../../store/state/breadcrumbs';
import { getDetailComponent } from '../../utils/getDetailComponent';
import useSharedStyles from '../shared/useSharedStyles';
import useStyles from './useStyles';

interface IProps {
  crumb: BreadcrumbModel;
  containerRef: React.RefObject<HTMLDivElement>;
}

type TOwnProps = IPropsFromState & IProps;

export const DetailPanel: React.FC<TOwnProps> = ({
  crumb,
  containerRef,
  updateBreadcrumb,
}) => {
  const sharedClasses = useSharedStyles();
  const classes = useStyles();
  const ref = useRef<HTMLDivElement>(null);

  useEffect(() => {
    if (ref.current && containerRef.current) {
      if (ref.current.offsetLeft > containerRef.current.scrollLeft) {
        if (!crumb.midPoint) {
          containerRef.current.scroll({
            top: 0,
            left: ref.current.offsetLeft,
            behavior: 'smooth',
          });

          const left = ref.current.offsetLeft;
          const computedStyles = window.getComputedStyle(ref.current);
          const widthString = computedStyles.getPropertyValue('width');
          const paddingString = computedStyles.getPropertyValue(
            'padding-right'
          );
          const width = parseFloat(widthString);
          const padding = parseFloat(paddingString);
          const midPoint = left + (width - padding) / 2;

          updateBreadcrumb({
            data: {
              ...crumb,
              midPoint,
              startPoint: left,
            },
          });
        }
      }
    }
  });

  const getDetailStyles = () => {
    if (crumb.withSidebar) {
      return sharedClasses.overviewWithSidebar;
    } else if (crumb.large) {
      return sharedClasses.largeOverview;
    } else {
      return sharedClasses.overview;
    }
  };

  const getDetailsWrapperStyles = () => {
    switch (true) {
      case crumb.withSidebar:
        return sharedClasses.withSidebarDetailsWrapper;
      case crumb.large:
        return sharedClasses.largeDetailsWrapper;
      default:
        return sharedClasses.detailsWrapper;
    }
  };

  return (
    <div
      data-testid='detail-panel'
      ref={ref}
      className={
        crumb.large || crumb.withSidebar
          ? classes.detailPanelLarge
          : classes.detailPanel
      }
    >
      <div className={getDetailStyles()}>
        <div className={getDetailsWrapperStyles()}>
          {getDetailComponent(crumb)}
        </div>
      </div>
    </div>
  );
};

export default connector(DetailPanel);
