import makeStyles from '@mui/styles/makeStyles';

const useStyles = makeStyles({
  pdfWrapper: {
    width: '100%',
    height: 800,
  },
});

export default useStyles;
