import React from 'react';
import Dialog from '@mui/material/Dialog';
import useStyles from './useStyles';
import UpToDateLogo from '../../../../assets/image/upToDate.svg';
import OutdatedLogo from '../../../../assets/image/outdated.svg';
import InProgressLogo from '../../../../assets/image/inProgress.svg';
import AlertLogo from '../../../../assets/image/alert.svg';
import { Typography } from '../../../shared/Typography/Typography';

export interface IOwnProps {
  open: boolean;
  onClose: (value: string) => void;
}

export const FirmwareDialog: React.FC<IOwnProps> = ({ open, onClose }) => {
  const classes = useStyles();

  return (
    <Dialog
      onClose={onClose}
      open={open}
      data-testid='firmware-dialog'
      PaperProps={{
        classes: {
          root: classes.dialog,
        },
      }}
    >
      <div className={classes.textWrapper}>
        <div className={classes.section}>
          <div className={classes.statusWrapper}>
            <img
              className={classes.indicator}
              src={UpToDateLogo}
              alt='Up to date indicator'
            />
            <span className={classes.title}>Up to Date</span>
          </div>
          <Typography variant='body2'>
            The current version of the firmware is the latest.
          </Typography>
        </div>
        <div className={classes.section}>
          <div className={classes.statusWrapper}>
            <img
              className={classes.indicator}
              src={OutdatedLogo}
              alt='Outdated indicator'
            />
            <span className={classes.title}>Outdated</span>
          </div>
          <Typography variant='body2'>
            This firmware version is out of date and a new recommended version
            is available.
          </Typography>
        </div>
        <div className={classes.section}>
          <div className={classes.statusWrapper}>
            <img
              className={classes.indicator}
              src={InProgressLogo}
              alt='In Progress indicator'
            />
            <span className={classes.title}>In Progress</span>
          </div>
          <Typography variant='body2'>
            Customer has provided consent, and the firmware is scheduled,
            currently downloading, waiting to be installed, or installing.
          </Typography>
        </div>
        <div className={classes.section}>
          <div className={classes.statusWrapper}>
            <img
              className={classes.indicator}
              src={AlertLogo}
              alt='Error indicator'
            />
            <span className={classes.title}>Error</span>
          </div>
          <Typography variant='body2'>
            Customer has provided consent, but the firmware update has failed.
            Update attempts have reached the maximum number without success.
          </Typography>
        </div>
      </div>
    </Dialog>
  );
};
