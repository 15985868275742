import React, { useEffect, useState } from 'react';
import connector, { IPropsFromState } from '../../../../Connector/Connector';
import { ICircleState } from '../index';
import { FlowColumnDiagram } from '../FlowColumnDiagram/FlowColumnDiagram';
import { findByStatus } from '../../../../../utils/findByStatus';
import {
  defaultCardAddedToWalletCircleState,
  defaultCardOrderedCircleState,
  defaultCardPairedCircleState,
  defaultSignedUpCircleState,
} from './defaultCircleStates';

export const CardChargingReadinessDiagram: React.FC<IPropsFromState> = ({
  subscriptionState,
  chargingCardState,
  cardOrdersState,
}) => {
  const [isLoading, setIsLoading] = useState(true);

  const activeSubscription = subscriptionState.data
    ? subscriptionState.data.find((sub) => sub.status === 'active')
    : undefined;

  const [signedUpState, setSignedUpState] = useState<ICircleState>(
    defaultSignedUpCircleState
  );
  const [orderState, setOrderState] = useState<ICircleState>(
    defaultCardOrderedCircleState
  );
  const [addedState, setAddedState] = useState<ICircleState>(
    defaultCardAddedToWalletCircleState
  );
  const [linkedState, setLinkedState] = useState<ICircleState>(
    defaultCardPairedCircleState
  );

  useEffect(() => {
    setIsLoading(
      subscriptionState.loading ||
        chargingCardState.loading ||
        cardOrdersState.loading
    );
  }, [subscriptionState, chargingCardState, cardOrdersState]);

  useEffect(() => {
    if (activeSubscription) {
      setSignedUpState({ ...signedUpState, circleState: 'happy' });
    } else {
      setSignedUpState({ ...signedUpState, circleState: 'sad' });
    }
  }, [activeSubscription]);

  useEffect(() => {
    const hasCardOrders =
      cardOrdersState &&
      Array.isArray(cardOrdersState.data) &&
      cardOrdersState.data.length;
    if (hasCardOrders) {
      setOrderState({ ...orderState, circleState: 'happy' });
    } else {
      setOrderState({ ...orderState, circleState: 'sad' });
    }
  }, [cardOrdersState]);

  useEffect(() => {
    const hasChargingCards =
      chargingCardState &&
      Array.isArray(chargingCardState.data) &&
      chargingCardState.data.length;

    if (hasChargingCards) {
      setAddedState({ ...addedState, circleState: 'happy' });
    } else {
      setAddedState({ ...addedState, circleState: 'sad' });
    }

    const cardIsPairedToActiveSubscription = () => {
      if (!chargingCardState.data) {
        return false;
      }
      const currentCard = findByStatus(chargingCardState.data, ['active']);
      return (
        hasChargingCards &&
        activeSubscription &&
        currentCard &&
        currentCard?.subscription_id === activeSubscription.id
      );
    };

    if (cardIsPairedToActiveSubscription()) {
      setLinkedState({ ...linkedState, circleState: 'happy' });
    } else {
      setLinkedState({ ...linkedState, circleState: 'sad' });
    }
  }, [chargingCardState, activeSubscription]);

  const [circles, setCircles] = useState<ICircleState[]>([
    signedUpState,
    orderState,
    addedState,
    linkedState,
  ]);
  useEffect(() => {
    if (isLoading) {
      setCircles([
        { ...signedUpState, circleState: 'loading' },
        { ...orderState, circleState: 'loading' },
        { ...addedState, circleState: 'loading' },
        { ...linkedState, circleState: 'loading' },
      ]);
    } else {
      setCircles([signedUpState, orderState, addedState, linkedState]);
    }
  }, [signedUpState, orderState, addedState, linkedState, isLoading]);

  const title = 'Card';
  const cardIcon: JSX.Element = (
    <svg viewBox='0 0 25 24' fill='none' xmlns='http://www.w3.org/2000/svg'>
      <path d='M10.2332 18.675C9.78315 18.675 9.48315 18.975 9.48315 19.425C9.48315 19.875 9.78315 20.175 10.2332 20.175C10.6832 20.175 10.9832 19.875 10.9832 19.425C10.9832 18.975 10.6082 18.675 10.2332 18.675Z' />
      <path
        d='M5.95825 15.15C6.48325 14.625 7.15825 14.175 7.83325 13.875C8.58325 13.575 9.33325 13.425 10.1583 13.425C10.9833 13.425 11.8083 13.575 12.4833 13.875C13.2333 14.175 13.8333 14.625 14.3583 15.15'
        strokeMiterlimit='10'
        strokeLinecap='round'
      />
      <path
        d='M8.05786 17.325C8.35786 17.025 8.65786 16.8 9.03286 16.65C9.40786 16.5 9.78286 16.425 10.2329 16.425C10.6829 16.425 11.0579 16.5 11.4329 16.65C11.8079 16.8 12.1079 17.025 12.4079 17.325'
        strokeMiterlimit='10'
        strokeLinecap='round'
      />
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M22.9079 10.95L11.2829 22.575C10.6829 23.175 9.78286 23.175 9.18286 22.575L1.75786 15.15C1.15786 14.55 1.15786 13.65 1.75786 13.05L13.3829 1.42498C13.9829 0.824976 14.8829 0.824976 15.4829 1.42498L22.9079 8.84998C23.5079 9.37498 23.5079 10.35 22.9079 10.95Z'
        strokeMiterlimit='10'
        strokeLinecap='round'
      />
    </svg>
  );

  return (
    <FlowColumnDiagram
      title={title}
      titleIcon={cardIcon}
      circles={circles}
      testId='card-charging-readiness-diagram'
    />
  );
};

export default connector(CardChargingReadinessDiagram);
