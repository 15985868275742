import update from 'immutability-helper';
import { State, OrganizationStateModel } from '..';

export default (state: State): State => {
  const updatedState: OrganizationStateModel = update(state, {
    $unset: ['data', 'accounts', 'error', 'loading', 'request', 'stations'],
  });

  return {
    ...updatedState,
  };
};
