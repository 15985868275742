import makeStyles from '@mui/styles/makeStyles';
import createStyles from '@mui/styles/createStyles';

const useStyles = makeStyles(() => {
  return createStyles({
    actionContainer: {
      float: 'right',
    },
  });
});

export default useStyles;
