import ApiService from './api';
import { IActiveSessionsResponse } from '../types/activeSessions';

interface IActiveSessionsProps {
  user_id: string;
}

const getActiveSessions = async ({
  user_id,
}: IActiveSessionsProps): Promise<IActiveSessionsResponse> => {
  const data: IActiveSessionsProps = {
    user_id,
  };

  const apiService = new ApiService(`/mgmt/csms/v1/chargingsessions`, {
    data,
  });

  const response = await apiService.query();
  return response.data;
};

export const ActiveSessionsService = {
  getActiveSessions,
};
