import createStyles from '@mui/styles/createStyles';
import makeStyles from '@mui/styles/makeStyles';

const useStyles = makeStyles(() =>
  createStyles({
    searchBox: {
      display: 'flex',
      flexDirection: 'row',
      justifyContent: 'space-between',
      alignItems: 'center',
      maxWidth: '1028px',
      minWidth: '640px',
      height: '100px',
      marginLeft: '16px',
    },
    resultBox: {
      maxWidth: '1028px',
      minWidth: '640px',
      minHeight: '530px',
      marginLeft: '16px',
      marginTop: '40px',
    },
    headerBox: {
      margin: '40px 16px 16px',
    },
    headline: {
      fontWeigt: 'bold',
      marginBottom: '33px',
    },
    text: {
      textAlign: 'left',
    },
  })
);

export default useStyles;
