import { Theme } from '@mui/material';

import makeStyles from '@mui/styles/makeStyles';
import createStyles from '@mui/styles/createStyles';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    dialog: {
      padding: 48,
      minWidth: 350,
    },
    stepper: { fontSize: '2em', width: '100%' },
    stepperButton: {
      borderColor: theme.colorPrimary,
      color: theme.colorPrimary,
      width: '100%',
    },
    warningNote: {
      marginTop: '1em',
      color: theme.colorAlertSecondary,
    },
    dialogContent: {
      marginBottom: '1em',
    },
  })
);

export default useStyles;
