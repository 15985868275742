import { AppDispatch } from '../../../../store';
import { ActionCreator } from '../../../../store/state/actions';
import { ActionInterface } from '../../../../store/state/subscription/actions';

export const getSubscriptions = (dispatch: AppDispatch) => (
  payload?: Omit<ActionInterface.GetSubscriptionRequest, 'type'>
): ActionInterface.GetSubscriptionRequest =>
  dispatch(ActionCreator.subscription.GetSubscriptionRequest(payload));

export const sortSubscriptions = (dispatch: AppDispatch) => (
  payload?: Omit<ActionInterface.SortSubscriptions, 'type'>
): ActionInterface.SortSubscriptions =>
  dispatch(ActionCreator.subscription.SortSubscriptions(payload));

export const terminateSubscriptions = (dispatch: AppDispatch) => (
  payload?: Omit<ActionInterface.TerminateSubscriptionRequest, 'type'>
): ActionInterface.TerminateSubscriptionRequest =>
  dispatch(ActionCreator.subscription.TerminateSubscriptionRequest(payload));
