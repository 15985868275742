import { AxiosError } from 'axios';
import { ActionCreator, ActionInterface, ActionType } from './actions';
import {
  ActionCreator as CardOrderActionCreator,
  ActionInterface as CardOrderActionInterface,
} from '../cardorders/actions';
import {
  ActionCreator as ChargingCardActionCreator,
  ActionInterface as ChargingCardActionInterface,
} from '../chargingCards/actions';
import {
  ActionCreator as ChargingSessionsActionCreator,
  ActionInterface as ChargingSessionsActionInterface,
} from '../chargingSessions/actions';
import {
  ActionCreator as InvoicesActionCreator,
  ActionInterface as InvoicesActionInterface,
} from '../invoices/actions';
import {
  ActionCreator as SubscriptionActionCreator,
  ActionInterface as SubscriptionActionInterface,
} from '../subscription/actions';
import { all, call, put, takeEvery } from 'redux-saga/effects';

import { ISubscriberModel } from '../../../types/subscriber';
import { SubscribersService } from '../../../services/subscribers';
import {
  BillingAddressService,
  IUpdateBillingAddress,
} from '../../../services/billingAddress';

export function* getSubscriber(action: ActionInterface.GetSubscriberRequest) {
  try {
    const subscriber: ISubscriberModel = yield call(
      SubscribersService.getById,
      {
        userId: action.userId,
      }
    );

    yield put<ActionInterface.GetSubscriberSuccess>(
      ActionCreator.GetSubscriberSuccess({
        data: subscriber,
      })
    );

    yield put<SubscriptionActionInterface.GetSubscriptionRequest>(
      SubscriptionActionCreator.GetSubscriptionRequest({
        subscriberId: subscriber.id,
      })
    );

    yield put<CardOrderActionInterface.GetCardOrderRequest>(
      CardOrderActionCreator.GetCardOrderRequest({
        subscriberId: subscriber.id,
      })
    );

    yield put<ChargingCardActionInterface.GetChargingCardRequest>(
      ChargingCardActionCreator.GetChargingCardRequest({
        subscriberId: subscriber.id,
      })
    );

    yield put<ChargingSessionsActionInterface.GetChargingSessionsRequest>(
      ChargingSessionsActionCreator.GetChargingSessionsRequest({
        subscriber_id: subscriber.id,
        organization_id: action.organizationId,
        user_id: action.userId,
      })
    );

    yield put<InvoicesActionInterface.GetInvoicesRequest>(
      InvoicesActionCreator.GetInvoicesRequest({
        subscriberId: subscriber.id,
      })
    );
  } catch (error) {
    yield put<ActionInterface.GetSubscriberError>(
      ActionCreator.GetSubscriberError({
        error: error as AxiosError,
      })
    );

    yield put<SubscriptionActionInterface.GetSubscriptionError>(
      SubscriptionActionCreator.GetSubscriptionError({
        error: error as AxiosError,
      })
    );

    yield put<CardOrderActionInterface.GetCardOrderError>(
      CardOrderActionCreator.GetCardOrderError({
        error: error as AxiosError,
      })
    );

    yield put<ChargingCardActionInterface.GetChargingCardError>(
      ChargingCardActionCreator.GetChargingCardError({
        error: error as AxiosError,
      })
    );

    yield put<ChargingSessionsActionInterface.GetChargingSessionsRequest>(
      ChargingSessionsActionCreator.GetChargingSessionsRequest({
        subscriber_id: '',
        organization_id: action.organizationId,
        user_id: action.userId,
      })
    );

    yield put<InvoicesActionInterface.GetInvoicesError>(
      InvoicesActionCreator.GetInvoicesError({
        error: error as AxiosError,
      })
    );
  }
}

export function* getSubscriberTemp(
  action: ActionInterface.GetSubscriberRequest
) {
  try {
    const subscriber: ISubscriberModel = yield call(
      SubscribersService.getById,
      {
        userId: action.userId,
      }
    );

    yield put<ActionInterface.GetSubscriberTempSuccess>(
      ActionCreator.GetSubscriberTempSuccess({
        searchedData: subscriber,
      })
    );
  } catch (error) {
    yield put<ActionInterface.GetSubscriberError>(
      ActionCreator.GetSubscriberError({
        error: error as AxiosError,
      })
    );
  }
}

export function* getSubscriberOnly(
  action: ActionInterface.GetSubscriberOnlyRequest
) {
  try {
    const subscriber: ISubscriberModel = yield call(
      SubscribersService.getById,
      {
        userId: action.userId,
      }
    );

    yield put<ActionInterface.GetSubscriberOnlySuccess>(
      ActionCreator.GetSubscriberOnlySuccess({
        data: subscriber,
      })
    );
  } catch (error) {
    yield put<ActionInterface.GetSubscriberError>(
      ActionCreator.GetSubscriberError({
        error: error as AxiosError,
      })
    );
  }
}

export function* updatedSubscriberBillingAddress(
  action: ActionInterface.UpdateBillingAddressRequest
) {
  try {
    const billingAddressData: IUpdateBillingAddress = yield call(
      BillingAddressService.updateBillingAddress,
      {
        subscriberId: action.subscriberId,
        subscriberAddress: action.subscriberAddress,
      }
    );
    yield put<ActionInterface.UpdateBillingAddressSuccess>(
      ActionCreator.UpdateBillingAddressSuccess({
        subscriberAddress: billingAddressData,
      })
    );
  } catch (error) {
    yield put<ActionInterface.UpdateBillingAddressError>(
      ActionCreator.UpdateBillingAddressError({
        error: error as AxiosError,
      })
    );
  }
}

export function* subscriberSaga() {
  yield all([
    takeEvery(ActionType.GetSubscriberRequest, getSubscriber),
    takeEvery(ActionType.GetSubscriberTempRequest, getSubscriberTemp),
    takeEvery(
      ActionType.UpdateBillingAddressRequest,
      updatedSubscriberBillingAddress
    ),
    takeEvery(ActionType.GetSubscriberOnlyRequest, getSubscriberOnly),
  ]);
}

export default subscriberSaga;
