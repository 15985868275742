import lookup, { CountryCode } from 'country-code-lookup';

export const getCountry = (code: string): string | undefined => {
  const countryObject: CountryCode = lookup.byIso(code);
  if (countryObject) {
    return countryObject.country;
  }

  return code;
};
