import {
  IInvoiceRefundLineItem,
  IInvoiceLineItem,
} from '../../../../../../../types/invoice';

export enum EChargeRecordRefundDistributionType {
  DEFAULT_PAYMENT_OPTION = 'default_payment_option',
  CHARGING_CREDIT = 'charging_credit',
}

export type TChargeRecordRefundDistribution = Array<{
  type:
    | EChargeRecordRefundDistributionType.DEFAULT_PAYMENT_OPTION
    | EChargeRecordRefundDistributionType.CHARGING_CREDIT;
  amount: string;
}>;

export type TChargeRecordRefundDistributionProps = {
  currency: string;
  invoiceChargeRecords: IInvoiceLineItem[];
  refundEntries: IInvoiceRefundLineItem[];
};

export type TDistributionCalculationResult = {
  [key in EChargeRecordRefundDistributionType]: number;
};
