import update from 'immutability-helper';
import { State } from '../';
import { ActionInterface } from '../actions';
import serializeError from 'serialize-error';

export default (state: State, action: ActionInterface.GetUserError): State => {
  const { error } = action;
  const currentUser = state || {
    loading: true,
    data: null,
    error: null,
    request: null,
  };

  const updatedUser = update(currentUser, {
    error: {
      $set: serializeError(error),
    },
    loading: {
      $set: false,
    },
    data: {
      $set: null,
    },
  });

  return {
    ...state,
    ...updatedUser,
  };
};
