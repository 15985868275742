import { IconButton } from '@mui/material';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import React, { useEffect, useState } from 'react';

import OrderRfidCardIcon from '../../../assets/image/addcard.svg';
import chargingCardsImage from '../../../assets/image/chargingCards.svg';
import { useTypedTranslation } from '../../../custom-hooks/useTypedTranslation';
import { ChargingSessionsStateModel } from '../../../store/state/chargingSessions';
import { SubscriptionStateModel } from '../../../store/state/subscription';
import { IChargingRecordViewModel } from '../../../types/chargingRecords';
import { IRFIDCardModel, ISubscriptionModel } from '../../../types/subscriber';
import { ITariffModel } from '../../../types/tariff';
import {
  addBreadcrumbHandler,
  IBreadcrumbHandlerProps,
} from '../../../utils/addBreadcrumbHandler';
import { formatIsoDates } from '../../../utils/dates';
import {
  IFieldNames,
  mapFieldsForTable,
} from '../../../utils/mapFieldsForTable';
import { sortRequestHandler as sortStateHandler } from '../../../utils/sortRequestHandler';
import connector, { IPropsFromState } from '../../Connector/Connector';
import { IActionItem } from '../../shared/ActionDropdownContainer';
import {
  AssetTable,
  IColumn,
  IRow,
  ISortState,
} from '../../shared/AssetTable/AssetTable/AssetTable';
import OrderRfidCard from '../../shared/Dialogs/OrderRfidCard';
import { ElliTooltip } from '../../shared/ElliTooltip/ElliTooltip';
import useSharedStyles from '../../shared/useSharedStyles';
import { Header } from '../Header';
import useStyles from './useStyles';
import { useTariffsQuery } from '../../../state/queries/tariffs';
import { SubscriptionStatusComponent } from '../../SubscriptionStatus/SubscriptionStatusComponent';

type IChargingCardsCollectionRow = IRFIDCardModel & {
  start_date_time: string;
  location_evse_id: string;
  tariff: string;
};

const fieldNames: IFieldNames = {
  number: {
    name: 'Serial Number',
    width: '180px',
  },
  status: {
    name: 'Status',
    width: '100px',
  },
  subscription: {
    name: 'Subscription',
    width: '144px',
  },
  label: {
    name: 'Label',
    width: '124px',
  },
  last_time_used: {
    name: 'Last Time Used',
  },
  last_station_used: {
    name: 'Last Station Used',
  },
  actions: {
    name: '',
    width: '36px',
  },
};

const addClickableLink = (
  data: string,
  style: {
    textOverflow: string;
  }
): string => {
  return data === '-'
    ? `${style.textOverflow}`
    : `clickableCell ${style.textOverflow}`;
};

const notEmptyArray = (
  value: ChargingSessionsStateModel | SubscriptionStateModel
): boolean | null => {
  return value && value.data && value.data.length > 0;
};

const createDataForTable = (
  cards: IRFIDCardModel[],
  sessions: ChargingSessionsStateModel,
  subscriptions: SubscriptionStateModel,
  tariffs: ITariffModel[] | null
): IChargingCardsCollectionRow[] => {
  return cards.map((chargingCard) => {
    const row: IChargingCardsCollectionRow = {
      ...chargingCard,
      tariff: '-',
      start_date_time: '-',
      location_evse_id: '-',
    };

    if (tariffs && notEmptyArray(subscriptions)) {
      const subscription = (subscriptions.data as ISubscriptionModel[]).find(
        (item) => chargingCard.subscription_id === item.id
      );

      if (subscription) {
        const tariff = tariffs.find(
          (item) => item.id === subscription.tariff_id
        );

        if (tariff) {
          row.tariff = tariff.code;
        }
      }
    }

    if (notEmptyArray(sessions)) {
      const cardSessions = (sessions.data as IChargingRecordViewModel[]).filter(
        (item) => chargingCard.id === item.card_id
      );

      if (cardSessions && cardSessions.length > 0) {
        const { start_date_time, station_id } = [
          ...cardSessions,
        ].sort((a, b): number =>
          new Date(b.start_date_time) > new Date(a.start_date_time) ? 1 : -1
        )[0];

        row.start_date_time = start_date_time;
        row.location_evse_id = station_id;
      }
    }

    return row;
  });
};

const getColumns = (): IColumn[] => {
  const sortableColumns: string[] = [
    'number',
    'status',
    'subscription',
    'label',
    'last_time_used',
    'last_station_used',
  ];

  return mapFieldsForTable(fieldNames, sortableColumns);
};

type TOwnProps = IPropsFromState & {
  crumbId?: string;
};

const ChargingCard = <img src={chargingCardsImage} alt='charging cards' />;

export const ChargingCardsCollection: React.FC<TOwnProps> = ({
  chargingCardState,
  chargingSessionsState,
  subscriptionState,
  sortChargingCards,
  breadcrumbState,
  addBreadcrumb,
  crumbId,
}) => {
  const classes = useStyles();
  const sharedClasses = useSharedStyles();

  const tariffs = useTariffsQuery();
  const getTariffsIfLoaded = (): ITariffModel[] =>
    !tariffs.isLoading && !tariffs.isError && tariffs.data ? tariffs.data : [];

  const chargingCards = chargingCardState;
  const chargingSessions = chargingSessionsState;
  const subscriptions = subscriptionState;
  const { t, terms } = useTypedTranslation();

  const taskBreadcrumbState = breadcrumbState.breadcrumbs;

  const [sortState, setSortState] = useState<ISortState>({
    sortByColumnKey: 'number',
    sortDesc: false,
  });

  const breadcrumbHandler = (props: IBreadcrumbHandlerProps): void =>
    addBreadcrumbHandler({
      ...props,
      addBreadcrumb,
      crumbId,
      taskBreadcrumbState,
    });

  const actionItems: IActionItem[] = [
    {
      icon: (
        <img
          src={OrderRfidCardIcon}
          alt='order rfid card'
          width={36}
          height={36}
          className={classes.chargingCardIcon}
        />
      ),
      friendlyText: t(terms.order_card_dialog.headline),
      component: <OrderRfidCard />,
    },
  ];

  const setNumberLink = (
    number: string | undefined,
    id: string
  ): string | JSX.Element => {
    if (number) {
      return (
        <ElliTooltip title={number}>
          <span
            className={addClickableLink(id, {
              textOverflow: sharedClasses.textOverflow,
            })}
            data-testid='charging-card-link'
            onClick={() =>
              breadcrumbHandler({
                component: 'ChargingCardDetails',
                friendlyText: 'Charging Card',
                id,
              })
            }
          >
            {number}
          </span>
        </ElliTooltip>
      );
    }
    return <span data-testid='charging-card-link'>-</span>;
  };

  const setSubscriptionLink = (
    tariff: string | undefined,
    subscriptionId: string
  ): string | JSX.Element => {
    if (tariff && tariff !== '-') {
      return (
        <ElliTooltip title={tariff}>
          <span
            data-testid='subscription-detail-link'
            className={addClickableLink(subscriptionId, {
              textOverflow: sharedClasses.textOverflow,
            })}
            onClick={() =>
              breadcrumbHandler({
                component: 'SubscriptionDetails',
                friendlyText: 'Subscription',
                id: subscriptionId,
              })
            }
          >
            {tariff}
          </span>
        </ElliTooltip>
      );
    }
    return '-';
  };

  const setLastStationUsedLink = (
    stationId: string | undefined
  ): string | JSX.Element => {
    if (stationId && stationId !== '-') {
      return (
        <ElliTooltip title={stationId}>
          <span
            className={addClickableLink(stationId, {
              textOverflow: sharedClasses.textOverflow,
            })}
            data-testid='charging-station-link'
            onClick={() =>
              breadcrumbHandler({
                component: 'ChargingStationDetails',
                friendlyText: 'Charging Station',
                id: stationId,
                config: {
                  location_evse_id: stationId,
                },
              })
            }
          >
            {stationId}
          </span>
        </ElliTooltip>
      );
    }
    return '-';
  };

  const sortRequestHandler = (columnKey: string | null): void => {
    sortStateHandler(columnKey, sortState, setSortState);
  };

  useEffect(() => {
    sortChargingCards({
      sortState,
      sessions: chargingSessions.data as IChargingRecordViewModel[],
      subscriptions: subscriptions.data as ISubscriptionModel[],
      tariffs: getTariffsIfLoaded(),
    });
  }, [sortState, sortChargingCards, tariffs.data]);

  const getRows = (): IRow[] => {
    if (
      !(chargingCards && chargingCards.data && chargingCards.data.length > 0)
    ) {
      return [];
    }
    const dataForTable: IChargingCardsCollectionRow[] = createDataForTable(
      chargingCards.data,
      chargingSessions,
      subscriptions,
      getTariffsIfLoaded()
    );

    return dataForTable.map((chargingCard) => {
      return {
        data: {
          number: {
            content: setNumberLink(chargingCard.number, chargingCard.id),
          },
          status: {
            content: (
              <SubscriptionStatusComponent status={chargingCard.status} />
            ),
          },
          subscription: {
            content: setSubscriptionLink(
              chargingCard.tariff,
              chargingCard.subscription_id
            ),
          },
          label: {
            content: chargingCard.label ? (
              <ElliTooltip title={chargingCard.label}>
                <span className={sharedClasses.textOverflow}>
                  {chargingCard.label}
                </span>
              </ElliTooltip>
            ) : (
              '-'
            ),
          },
          last_time_used: {
            content: (
              <ElliTooltip title={chargingCard.start_date_time}>
                <span className={sharedClasses.textOverflow}>
                  {formatIsoDates(chargingCard.start_date_time).toString()}
                </span>
              </ElliTooltip>
            ),
          },
          last_station_used: {
            content: setLastStationUsedLink(chargingCard.location_evse_id),
          },
          actions: {
            content: (
              <IconButton
                data-testid='charging-card-end-link'
                onClick={() =>
                  breadcrumbHandler({
                    component: 'ChargingCardDetails',
                    friendlyText: 'Charging Card',
                    id: chargingCard.id,
                  })
                }
                color='primary'
                size='large'
              >
                <ChevronRightIcon fontSize='large' />
              </IconButton>
            ),
          },
        },
        id: chargingCard.id,
      };
    });
  };

  return (
    <div
      className={sharedClasses.cardElement}
      data-testid='charging-cards-collection-ui-component'
    >
      <Header
        title='Charging Cards Collection'
        icon={ChargingCard}
        actionItems={actionItems}
      />
      <section>
        <AssetTable
          bulkActions={[]}
          columns={getColumns()}
          rows={getRows()}
          sortingState={sortState}
          sortRequestHandler={sortRequestHandler}
          noDataTitle='No charging cards available'
          noDataSubTitle='User account has no access to any charging cards.'
        />
      </section>
    </div>
  );
};

export default connector(ChargingCardsCollection);
