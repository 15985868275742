import { Theme } from '@mui/material';

import createStyles from '@mui/styles/createStyles';
import makeStyles from '@mui/styles/makeStyles';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    chargingCardDetailsHeader: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'space-between',
      marginBottom: '16px',
      position: 'relative',
    },
    typeWrapper: {
      display: 'flex',
      alignItems: 'center',
    },
    elementWithoutMap: {
      padding: '16px 16px 16px',
    },
    titleWrapper: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'flex-start',
    },
    logoContainer: {
      marginRight: '15px',
    },
    panelTitle: {
      fontSize: '20px !important',
    },
    infoButton: {
      position: 'absolute',
      right: -6,
      padding: 9,
      color: theme.colorPrimary,
    },
    profileElement: {
      marginBottom: '30px',
    },
    expandIcon: {
      fontSize: '24px',
      cursor: 'pointer',
      transition: 'transform .3s cubic-bezier(0.87, 0, 0.13, 1) !important',
    },
    expandIconOpen: {
      transform: 'rotate(0)',
    },
    expandIconClosed: {
      transform: 'rotate(180deg)',
    },
  })
);

export default useStyles;
