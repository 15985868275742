import update from 'immutability-helper';
import { State, UserStateModel } from '../';

export default (state: State): State => {
  const updatedState: UserStateModel = update(state, {
    $unset: ['data', 'error', 'loading', 'request', 'verified'],
  });

  return {
    ...updatedState,
  };
};
