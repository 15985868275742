import React, { useEffect, useRef, useState } from 'react';
import { FormHelperText, Grid } from '@mui/material';

import { useStyles } from './useStyles';
import DialogHeadline from '../../../shared/Dialogs/DialogHeadline';
import MultilineButton from '../../../shared/Buttons/MultilineButton';
import { useTypedTranslation } from '../../../../custom-hooks/useTypedTranslation';
import { ISubscriberModel } from '../../../../types/subscriber';
import { changeBillingFormSchema } from './ChangeBillingForm.schema';
import {
  getDefaultValues,
  IChangeBillingFields,
  useTextFields,
  useSelectFields,
} from './fields';
import { Dispatcher } from '../../../Connector/dispatchers';
import { ActionInterface } from '../../../../store/state/subscriber/actions';
import {
  ConnectForm,
  Form,
  Input,
  Select,
} from '../../../shared/form-elements';

type SubmitState = 'submitted' | 'failed' | 'open';

export interface IOwnProps {
  subscriberAddress: ISubscriberModel['address'];
  subscriberTaxNumber: ISubscriberModel['tax_number'];
  subscriberId: ISubscriberModel['id'];
  submitError: boolean;
  updateBillingAddress: Dispatcher<ActionInterface.UpdateBillingAddressRequest>;
  onSuccess: () => void;
}

const ChangeBillingForm: React.FC<IOwnProps> = ({
  subscriberAddress,
  subscriberTaxNumber,
  subscriberId,
  submitError,
  onSuccess,
  updateBillingAddress,
}) => {
  const classes = useStyles();
  const { t, terms } = useTypedTranslation();
  const [submitState, setSubmitState] = useState<SubmitState>('open');
  const subscriberAddressRef = useRef(subscriberAddress);
  const textFields = useTextFields();
  const selectFields = useSelectFields();

  useEffect(() => {
    submitError && setSubmitState('failed');
  }, [submitError]);

  useEffect(() => {
    if (subscriberAddress !== subscriberAddressRef.current) {
      onSuccess();
    }
  }, [subscriberAddress]);

  const onSubmit = (data: IChangeBillingFields) => {
    updateBillingAddress({
      subscriberAddress: data,
      subscriberId,
    });
    setSubmitState('submitted');
  };

  const getShippingAddressFields = () => (
    <ConnectForm>
      <Grid item xs={12}>
        <Input {...textFields.addressLine1} />
      </Grid>
      <Grid item xs={12}>
        <Input {...textFields.addressLine2} />
      </Grid>
      <Grid container item spacing={2}>
        <Grid item xs>
          <Input {...textFields.zip} />
        </Grid>
        <Grid item xs={8}>
          <Input {...textFields.city} />
        </Grid>
      </Grid>

      <Grid container item spacing={2}>
        <Grid item xs={6}>
          <Input {...textFields.state} />
        </Grid>
        <Grid item xs={6}>
          <Select {...selectFields.country} />
        </Grid>
      </Grid>
      <Grid item xs={12}>
        <Input {...textFields.taxNumber} />
      </Grid>
    </ConnectForm>
  );

  return (
    <div className={classes.outerWrapper}>
      <div>
        <DialogHeadline
          headline={t(terms.update_billing_address.headline)}
          subHeadline={t(terms.update_billing_address.sub_headline)}
        />
      </div>

      <Form
        onSubmit={onSubmit}
        className={classes.form}
        schema={changeBillingFormSchema}
        defaultValues={getDefaultValues(subscriberAddress, subscriberTaxNumber)}
      >
        <Grid container spacing={2}>
          {getShippingAddressFields()}

          <Grid item xs={12} className={classes.submitWrapper}>
            <MultilineButton
              mainText={t(terms.update_billing_address.submit)}
            />
            {submitState === 'failed' && (
              <FormHelperText className={classes.errorMessage}>
                {t(terms.update_billing_address.failure)}
              </FormHelperText>
            )}
          </Grid>
        </Grid>
      </Form>
    </div>
  );
};

export default ChangeBillingForm;
