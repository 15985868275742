import * as yup from 'yup';

const addressLine1And2Error =
  'Length of address Line 1 and 2 combined can not exceed 100 characters';

export const changeBillingFormSchema = yup.object().shape({
  zip: yup
    .string()
    .required()
    .min(2),
  city: yup.string().required(),
  country_code: yup.string().required(),
  address_line_1: yup
    .string()
    .max(60)
    .test('AddressLine1And2Max100', addressLine1And2Error, (value, context) => {
      const len =
        (value ? value.length : 0) +
        (context.parent.address_line_2
          ? context.parent.address_line_2.length
          : 0);
      return len < 100;
    }),
  address_line_2: yup
    .string()
    .max(60)
    .test('AddressLine1And2Max100', addressLine1And2Error, (value, context) => {
      const len =
        (context.parent.address_line_1
          ? context.parent.address_line_1.length
          : 0) + (value ? value.length : 0);
      return len < 100;
    }),
  state: yup.string(),
  tax_number: yup.string(),
});
