import { AppDispatch } from '../../../../store';
import { ActionCreator } from '../../../../store/state/actions';
import { ActionInterface } from '../../../../store/state/chargingStations/actions';
import { Dispatcher } from '../index';

interface IDispatchers {
  getChargingStationRequest: Dispatcher<
    ActionInterface.GetChargingStationRequest
  >;
  getNearbyLocationsRequest: Dispatcher<
    ActionInterface.GetNearbyLocationsRequest
  >;
}

export const createDispatchers = (dispatch: AppDispatch): IDispatchers => ({
  getChargingStationRequest: (payload) =>
    dispatch(ActionCreator.chargingStations.GetChargingStationRequest(payload)),

  getNearbyLocationsRequest: (payload) =>
    dispatch(ActionCreator.chargingStations.GetNearbyLocationsRequest(payload)),
});
