import React from 'react';

import connector, { IPropsFromState } from '../../Connector/Connector';
import Details from './Details';
import useStyles from './useStyles';
import PdfViewer from '../../shared/PdfViewer';
import { useInvoicePdfQuery } from '../../../state/queries/useInvoicePdf';
import { InvoiceDetailsTitle } from './InvoiceTitle';
import { useInvoiceQuery } from '../../../state/queries/useInvoiceQuery';

export type TOwnProps = IPropsFromState & {
  invoiceId?: string;
  crumbId?: string;
};

const InvoicePdf: React.FC<TOwnProps> = ({ subscriberState, invoiceId }) => {
  const classes = useStyles();
  const { data: invoicePdf } = useInvoicePdfQuery(
    subscriberState.data!.id,
    invoiceId
  );
  const { data: invoice } = useInvoiceQuery(
    subscriberState.data!.id,
    invoiceId
  );

  return (
    <div
      className={classes.cardElement}
      data-testid='invoice-details-component'
    >
      {invoice && (
        <>
          <InvoiceDetailsTitle invoiceNumber={invoice.invoice_number} />
          <Details
            currentInvoice={invoice}
            hideRefunds={true}
            hidePDF={true}
            hideCreditNotes={true}
          />

          {invoicePdf && <PdfViewer file={invoicePdf.data} />}
        </>
      )}
    </div>
  );
};

export default connector(InvoicePdf);
