import React, { useEffect, useState } from 'react';
import parseSeconds from '../../utils/parseSeconds';
import { differenceInSeconds } from 'date-fns';

type IOwnProps = {
  start_time: Date;
};

const LiveDuration: React.FC<IOwnProps> = ({ start_time }) => {
  const [duration, setDuration] = useState(() =>
    parseSeconds(differenceInSeconds(Date.now(), start_time))
  );

  useEffect(() => {
    const intervalId = setInterval(
      () => {
        setDuration(parseSeconds(differenceInSeconds(Date.now(), start_time)));
      },
      // The update must happen more frequently than once every second, otherwise one second is skipped every now and then
      100
    );

    return () => clearInterval(intervalId);
  }, [start_time]);

  return <span data-testid='live-duration'>{duration}</span>;
};

export default LiveDuration;
