import React, { useEffect, useRef, useState } from 'react';
import CircularProgress from '@mui/material/CircularProgress';
import IconButton from '@mui/material/IconButton';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import OpenInNewIcon from '@mui/icons-material/OpenInNew';
import {
  addBreadcrumbHandler,
  IBreadcrumbHandlerProps,
} from '../../../utils/addBreadcrumbHandler';
import {
  IStationViewModel,
  StationViewModel,
} from '../../../view-models/StationModel';
import connector, { IPropsFromState } from '../../Connector/Connector';

import { ActionDropdown } from './ActionDropdown/ActionDropdown';
import ChargingStationLogo from '../../../assets/image/chargingStation.svg';
import { Connectors } from './Connectors/index';
import { General } from './General/General';
import { StationService } from '../../../services/stations';
import { IActiveSession } from '../../../types/activeSessions';
import { IChargingRecordViewModel } from '../../../types/chargingRecords';
import { IRFIDCardModel } from '../../../types/subscriber';
import { Location } from './Location';
import { LocationService } from '../../../services/locations';
import { ChargingStationMap } from './Map/Map';
import { OrganizationService } from '../../../services/organizations';
import { RecentSessions } from './RecentSessions';
import {
  ICsmsChargingStation,
  IEnterpriseChargingStation,
  IFirmwareInformationModel,
  IHomeChargingStation,
  IStationOwner,
  IUserModel,
} from '../../../types/user';
import { UsersService } from '../../../services/users';
import { config } from '../../../config';
import { parseStationOwner } from '../../../utils/stationUtils';
import useSharedStyles from '../../shared/useSharedStyles';
import useStyles from './useStyles';
import { Firmware } from './Firmware/Firmware';
import { Typography } from '../../shared/Typography/Typography';
import { getElliProductData } from '../../../utils/elliProductIds';

const getConsoleUrl = (id: string): string => {
  if (config.env === 'prod') {
    return `https://console.elli.eco/technical-operations/stations/details/${id}/overview`;
  }

  return `https://${config.env}.console.elli.eco/technical-operations/stations/details/${id}/overview`;
};

type TOwnProps = IPropsFromState & {
  resourceId?: string;
  crumbId?: string;
  stationId?: string;
  evseId?: string;
};

export const ChargingStationDetails: React.FC<TOwnProps> = ({
  chargingStationState,
  chargingSessionsState,
  breadcrumbState,
  activeSessionsState,
  resourceId,
  crumbId,
  stationId,
  evseId,
  userState,
  subscriberState,
  organizationState,
  chargingCardState,
  addBreadcrumb,
  getNearbyLocationsRequest,
  getChargingSessionsRequest,
  getActiveSessionsRequest,
}) => {
  const classes = useStyles();
  const sharedClasses = useSharedStyles();
  const taskBreadcrumbState = breadcrumbState.breadcrumbs;
  const taskUserState = userState;
  const taskSubscriberState = subscriberState;
  const taskOrganizationState = organizationState;
  const taskChargingSessions = chargingSessionsState;

  const taskActiveSessionState = activeSessionsState;

  const [loading, setLoading] = useState<boolean>(true);
  const [hasStationLoaded, setHasStationLoaded] = useState<boolean>(false);

  const [
    currentStation,
    setCurrentStation,
  ] = useState<IStationViewModel | null>(null);

  const [currentActiveSessions, setCurrentActiveSessions] = useState<
    IActiveSession[]
  >([]);

  const [chargingRecords, setChargingRecords] = useState<
    IChargingRecordViewModel[]
  >([]);

  const [open, setOpen] = useState(false);
  const dropdownRef = useRef<HTMLButtonElement>(null);

  const [stationRefreshing, setStationRefreshing] = useState<boolean>(false);

  const [chargingCards, setChargingCards] = useState<IRFIDCardModel[]>([]);

  const [stationFirmwareInformation, setStationFirmwareInformation] = useState<
    IFirmwareInformationModel | undefined
  >(undefined);

  const isEssStation = (
    station: IHomeChargingStation | IEnterpriseChargingStation
  ): station is IEnterpriseChargingStation => {
    return (
      (station as IEnterpriseChargingStation).organization_iam_id !== undefined
    );
  };

  const getStationOwner = (
    station: IHomeChargingStation | IEnterpriseChargingStation | undefined
  ): IStationOwner | undefined => {
    if (!station) {
      return undefined;
    }
    let owner: IStationOwner | undefined;
    if (isEssStation(station) && station.organization_iam_id) {
      owner = {
        iam_id: station.organization_iam_id,
        type: 'organization',
      };
      return owner;
    } else if (!isEssStation(station) && station.owner_iam_id) {
      owner = {
        iam_id: station.owner_iam_id,
        type: 'user',
      };
      return owner;
    }
    return undefined;
  };

  const getStationOwnerDependentOnProductId = async (
    csmsStation: ICsmsChargingStation
  ): Promise<IStationOwner | undefined> => {
    const assignedProduct = csmsStation.elli_product_id;

    let productResponse;
    if (assignedProduct) {
      productResponse =
        getElliProductData('CHARGE_AT_HOME').id === assignedProduct
          ? await StationService.getAdditionalHssDataForStation(csmsStation.id)
          : await StationService.getAdditionalEssDataForStation(csmsStation.id);
    }

    return getStationOwner(productResponse);
  };

  const getStation = async (id: string): Promise<void> => {
    try {
      const csmsChargingStation = await StationService.getCsmsStationById(id);

      const stationOwner = await getStationOwnerDependentOnProductId(
        csmsChargingStation
      );

      const station = new StationViewModel({
        location: null,
        station: { ...csmsChargingStation, owner: stationOwner },
      });

      station.setAutoUpdate(await StationService.getAutoUpdateInformation(id));

      setCurrentStation(station);
    } catch (error) {
      setCurrentStation(null);
    }
  };

  const getLocation = async (id: string): Promise<void> => {
    try {
      const response = await LocationService.getById(id);
      const station = new StationViewModel({
        location: response[0],
        station: null,
      });
      setCurrentStation(station);
    } catch (error) {
      setCurrentStation(null);
    }
  };

  const getStationFirmware = async (id: string): Promise<void> => {
    try {
      const firmwareResponse = await StationService.getStationFirmwareInformation(
        id
      );
      setStationFirmwareInformation(firmwareResponse);
    } catch (error) {
      setStationFirmwareInformation(undefined);
    }
  };

  useEffect(() => {
    if (chargingCardState) {
      setChargingCards(chargingCardState.data || []);
    }
  }, [chargingCardState]);

  const breadcrumbHandler = (props: IBreadcrumbHandlerProps): void =>
    addBreadcrumbHandler({
      ...props,
      addBreadcrumb,
      crumbId,
      taskBreadcrumbState,
    });

  const getStationHeading = (station: IStationViewModel | null): string => {
    if (!station) {
      return '';
    }

    if (station.name !== '-') {
      return station.name;
    } else if (station.station_serial_number !== '-') {
      return station.station_serial_number;
    } else if (resourceId) {
      return resourceId;
    }

    return '-';
  };

  useEffect(() => {
    if (hasStationLoaded) {
      setTimeout(() => {
        setLoading(false);
      }, 1000);
    }
  }, [hasStationLoaded]);

  useEffect(() => {
    if (!taskChargingSessions.loading && !taskActiveSessionState.loading) {
      setStationRefreshing(false);
    }
  }, [taskChargingSessions, taskActiveSessionState]);

  useEffect(() => {
    if (evseId) {
      getLocation(evseId);
    } else if (stationId) {
      getStation(stationId);
      getStationFirmware(stationId);
    }
  }, [stationId, evseId]);

  useEffect(() => {
    if (currentStation && taskActiveSessionState.data) {
      const activeSessionsAtStation = taskActiveSessionState.data.filter(
        (session: IActiveSession) =>
          session.station_id === stationId || session.station_id === evseId
      );

      setCurrentActiveSessions(activeSessionsAtStation);
    }
  }, [currentStation, taskActiveSessionState]);

  useEffect(() => {
    if (currentStation && taskChargingSessions.data) {
      const stationChargingRecords = taskChargingSessions.data.filter(
        (session: IChargingRecordViewModel) =>
          session.station_id === stationId || session.station_id === evseId
      );
      setChargingRecords(stationChargingRecords);
    }
  }, [currentStation]);

  useEffect(() => {
    const getUserById = async (id: string): Promise<void> => {
      try {
        const response = await UsersService.getById({ userId: id });
        const owner = parseStationOwner({
          firstName: response.first_name,
          lastName: response.last_name,
          email: response.email,
          type: 'user',
        });

        (currentStation as IStationViewModel).setStationOwner(owner);
        setHasStationLoaded(true);
        if (!taskChargingSessions.loading && !taskActiveSessionState.loading) {
          setStationRefreshing(false);
        }
      } catch (error) {
        (currentStation as IStationViewModel).setStationOwner('-');
        setHasStationLoaded(true);
        if (!taskChargingSessions.loading && !taskActiveSessionState.loading) {
          setStationRefreshing(false);
        }
      }
    };

    const getOrgById = async (id: string): Promise<void> => {
      try {
        const response = await OrganizationService.getOrganization({
          organizationId: id,
        });
        const owner = parseStationOwner({
          orgName: response.name,
          type: 'organization',
        });

        (currentStation as IStationViewModel).setStationOwner(owner);
        setHasStationLoaded(true);
        if (!taskChargingSessions.loading && !taskActiveSessionState.loading) {
          setStationRefreshing(false);
        }
      } catch (error) {
        (currentStation as IStationViewModel).setStationOwner('-');
        setHasStationLoaded(true);
        if (!taskChargingSessions.loading && !taskActiveSessionState.loading) {
          setStationRefreshing(false);
        }
      }
    };

    if (currentStation) {
      if (currentStation.station_owner_type === 'user') {
        getUserById(currentStation.iam_id);
      } else if (currentStation.station_owner_type === 'organization') {
        getOrgById(currentStation.iam_id);
      } else {
        setHasStationLoaded(true);
        if (!taskChargingSessions.loading && !taskActiveSessionState.loading) {
          setStationRefreshing(false);
        }
      }
    }
  }, [currentStation]);

  const refreshSessions = (): void => {
    getChargingSessionsRequest({
      user_id: (taskUserState.data as IUserModel).id,
      subscriber_id:
        taskSubscriberState && taskSubscriberState.data
          ? taskSubscriberState.data.id
          : '',
      organization_id:
        taskOrganizationState &&
        taskOrganizationState.data &&
        taskOrganizationState.data.iam_id
          ? taskOrganizationState.data.iam_id
          : '',
    });
    getActiveSessionsRequest({
      userId: (taskUserState.data as IUserModel).id,
    });
  };

  const refreshStation = () => {
    setStationRefreshing(true);
    if (evseId) {
      refreshSessions();
      getLocation(evseId);
    } else if (stationId) {
      refreshSessions();
      getStation(stationId);
      getStationFirmware(stationId);
    } else {
      refreshSessions();
      setStationRefreshing(false);
    }
  };

  return (
    <div
      style={{ padding: 0, marginBottom: 0 }}
      className={sharedClasses.cardElement}
      data-testid='charging-station-details-wrapper'
    >
      <div className={classes.elementWithoutMap}>
        <div className={classes.chargingCardDetailsHeader}>
          <div className={classes.titleWrapper}>
            <div className={classes.logoContainer}>
              <img src={ChargingStationLogo} alt='charging station logo' />
            </div>
            <Typography
              variant='h1'
              classes={{
                root: classes.panelTitle,
              }}
            >
              Charging Station -{' '}
              <span data-testid='heading-label'>
                {getStationHeading(currentStation)}
              </span>
            </Typography>
          </div>
          {currentStation && currentStation.type !== 'public' && (
            <IconButton
              data-testid='more-info-button'
              onClick={() => setOpen((prevOpen) => !prevOpen)}
              classes={{
                root: classes.infoButton,
              }}
              ref={dropdownRef}
              size='large'
            >
              <MoreVertIcon />
            </IconButton>
          )}
          <ActionDropdown
            open={open}
            setOpen={setOpen}
            dropdownRef={dropdownRef}
            actionItems={[
              {
                icon: <OpenInNewIcon style={{ fontSize: 24 }} />,
                friendlyText: 'View in Console',
                url: currentStation
                  ? getConsoleUrl(currentStation.station_id)
                  : '',
              },
            ]}
          />
        </div>
        {loading ? (
          <div
            style={{
              display: 'flex',
              flexDirection: 'column',
              justifyContent: 'center',
              alignItems: 'center',
              height: 241,
            }}
          >
            <CircularProgress data-testid='loading' />
          </div>
        ) : (
          currentStation && (
            <>
              <General
                chargingStation={currentStation}
                stationRefreshing={stationRefreshing}
              />
              {stationFirmwareInformation &&
                currentStation.type !== 'public' && (
                  <Firmware
                    userId={currentStation.iam_id}
                    stationId={currentStation.station_id}
                    firmwareInformation={stationFirmwareInformation}
                    breadcrumbHandler={breadcrumbHandler}
                    stationRefreshing={stationRefreshing}
                  />
                )}
              <Connectors
                chargingStation={currentStation}
                refreshStation={refreshStation}
                stationRefreshing={stationRefreshing}
              />
              <RecentSessions
                chargingStation={currentStation}
                chargingRecords={chargingRecords}
                activeSessions={currentActiveSessions}
                breadcrumbHandler={breadcrumbHandler}
                stationRefreshing={stationRefreshing}
                chargingCards={chargingCards}
              />
              <Location chargingStation={currentStation} />
            </>
          )
        )}
      </div>
      {currentStation && !loading && (
        <ChargingStationMap
          getNearbyLocationsRequest={getNearbyLocationsRequest}
          addBreadcrumbHandler={breadcrumbHandler}
          evseId={resourceId}
          resourceId={resourceId}
          currentStation={currentStation}
          chargingStationState={chargingStationState}
        />
      )}
    </div>
  );
};

export default connector(ChargingStationDetails);
