import React from 'react';
import useStyles from './useStyles';
import {
  AssetTable,
  IColumn,
  IRow,
} from '../../../shared/AssetTable/AssetTable/AssetTable';
import {
  IFieldNames,
  mapFieldsForTable,
} from '../../../../utils/mapFieldsForTable';
import {
  FirmwareConsentCreatedBy,
  FirmwareConsentHistorySummary,
  FirmwareDecision,
  IFirmwareInformationModel,
} from '../../../../types/user';
import { formatIsoDatesNoSeconds } from '../../../../utils/dates';
import { ConsentStatus } from './ConsentStatus/ConsentStatus';

interface IOwnProps {
  firmwareInformation?: IFirmwareInformationModel;
  firmwareConsentsHistory?: FirmwareConsentHistorySummary[];
}

export const ConsentHistory: React.FC<IOwnProps> = ({
  firmwareInformation,
  firmwareConsentsHistory,
}) => {
  const classes = useStyles();

  const mapFirmwareConsentCreatedByToString = (
    decision?: FirmwareDecision,
    createdBy?: FirmwareConsentCreatedBy
  ): string => {
    if (decision === 'accepted') {
      if (createdBy === 'customer_manual' || createdBy === 'agent_manual') {
        return 'Manual';
      }
      if (createdBy === 'customer_auto' || createdBy === 'agent_auto') {
        return 'Auto';
      }
      if (createdBy === 'forced') {
        return 'System';
      }
    }
    return '';
  };

  const getColumns = (): IColumn[] => {
    const fieldNames: IFieldNames = {
      firmware_version: {
        name: 'Firmware Version',
        width: '140px',
      },
      consent_status: {
        name: 'Consent Status',
        width: '110px',
      },
      response_date: {
        name: 'Response Date',
        width: '145px',
      },
      update_initiated: {
        name: 'Update Initiated',
        width: '145px',
      },
      consent_source: {
        name: 'Consent Source',
        width: '',
      },
      actions: {
        name: '',
        width: '36px',
      },
    };
    return mapFieldsForTable(fieldNames, []);
  };

  const getRows = (
    firmwareInfo?: IFirmwareInformationModel,
    firmwareHistory?: FirmwareConsentHistorySummary[]
  ): IRow[] => {
    if (!firmwareInfo || !firmwareHistory) {
      return [];
    }

    return firmwareHistory.map((consent) => {
      return {
        data: {
          firmware_version: {
            content: consent.firmware.version ?? '-',
          },
          consent_status: {
            content: consent.decision ? (
              <ConsentStatus status={consent.decision} />
            ) : (
              '-'
            ),
          },
          response_date: {
            content: consent.created_at ? (
              <div data-testid='created-at-date-data'>
                {formatIsoDatesNoSeconds(consent.created_at)}
              </div>
            ) : (
              '-'
            ),
          },
          update_initiated: {
            content: consent.processed_at ? (
              <div data-testid='processed-at-date-data'>
                {formatIsoDatesNoSeconds(consent.processed_at)}
              </div>
            ) : (
              '-'
            ),
          },
          consent_source: {
            content: (
              <div data-testid='created-by-data'>
                {mapFirmwareConsentCreatedByToString(
                  consent.decision,
                  consent.created_by
                )}
              </div>
            ),
          },
          actions: {
            content: '',
            isToggle: consent.history.length > 0,
          },
        },
        history: consent.history.map((element) => {
          return {
            firmware_version: {
              content: '',
            },
            consent_status: {
              content: element.decision ? (
                <ConsentStatus status={element.decision} />
              ) : (
                '-'
              ),
            },
            response_date: {
              content: element.created_at ? (
                <div data-testid='created-at-date-data'>
                  {formatIsoDatesNoSeconds(element.created_at)}
                </div>
              ) : (
                '-'
              ),
            },
            update_initiated: {
              content: element.processed_at ? (
                <div data-testid='processed-at-date-data'>
                  {formatIsoDatesNoSeconds(element.processed_at)}
                </div>
              ) : (
                ''
              ),
            },
            consent_source: {
              content: (
                <div data-testid='created-by-data'>
                  {mapFirmwareConsentCreatedByToString(
                    element.decision,
                    element.created_by
                  )}
                </div>
              ),
            },
            actions: {
              content: '',
            },
          };
        }),
        id: consent.id,
      };
    });
  };

  return (
    <div
      className={classes.profileElement}
      data-testid='collapsible-asset-table'
    >
      <AssetTable
        bulkActions={[]}
        columns={getColumns()}
        rows={getRows(firmwareInformation, firmwareConsentsHistory)}
        sortRequestHandler={() => true}
        noDataTitle='No firmware consent history available'
        noDataSubTitle='The user has not yet interacted with any firmware update of this station.'
        isCollapsible={true}
      />
    </div>
  );
};
