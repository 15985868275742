import React from 'react';
import { IBreadcrumbHandlerProps } from '../../../../utils/addBreadcrumbHandler';
import { CardButton } from '../../CardButton';

interface IOwnProps {
  breadcrumbHandler: (props: IBreadcrumbHandlerProps) => void;
  invoicesNumber: number;
}

export const InvoicesActionButton: React.FC<IOwnProps> = ({
  invoicesNumber,
  breadcrumbHandler,
}) => (
  <CardButton
    onClick={() =>
      breadcrumbHandler({
        component: 'InvoiceCollection',
        friendlyText: 'Invoices',
        large: true,
      })
    }
    length={invoicesNumber}
    title='Invoices'
  />
);
