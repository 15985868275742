import React from 'react';

import { formatDateNoTime } from '../../../../utils/dates';
import { parseAmount } from '../../../CollectionCards/Invoices/InvoiceTable';
import useStyles from './useStyles';
import { useTypedTranslation } from '../../../../custom-hooks/useTypedTranslation';
import { IBreadcrumbHandlerProps } from '../../../../utils/addBreadcrumbHandler';
import InformationItems from '../../../shared/InformationItems';
import { IInformationItem } from '../../../shared/InformationItems/InformationItem';
import { useCCUserRolesQuery } from '../../../../state/queries/ccUserRoles';
import toast from 'react-hot-toast';
import { IInvoice } from '../../../../types/invoice';

export interface IOwnProps {
  currentInvoice: IInvoice;
  addBreadcrumbHandler?: (props: IBreadcrumbHandlerProps) => void;
  hideRefunds?: boolean;
  hideCreditNotes?: boolean;
  hidePDF?: boolean;
  isSubscriptionRefundAvailable?: boolean;
}

/**
 * Generates information for invoice to be displayed
 * @param currentInvoice handled invoice
 * @param addBreadcrumbHandler callback for click handler
 * @param hideRefunds should refund link be displayed?
 * @param hideCreditNotes should credit note link be displayed?
 * @param hidePDF should invoice pdf link be displayed?
 */
const Details: React.FC<IOwnProps> = ({
  currentInvoice,
  addBreadcrumbHandler,
  hideRefunds = false,
  hideCreditNotes = false,
  hidePDF = false,
  isSubscriptionRefundAvailable = false,
}) => {
  const ccUserRolesQuery = useCCUserRolesQuery();
  const classes = useStyles();
  const { t, terms } = useTypedTranslation();

  const getCreditNotesHeadline = (
    addBreadcrumbHandler: (props: IBreadcrumbHandlerProps) => void
  ) => ({
    id: 'show-credit-notes',
    headline: t(terms.refunds.show_headline),
    content: (
      <span
        className={classes.itemLink}
        onClick={() =>
          addBreadcrumbHandler({
            component: 'CreditNotesCollection',
            friendlyText: t(terms.refunds.show_headline),
            large: true,
          })
        }
      >
        {t(terms.refunds.show_link)}
      </span>
    ),
  });

  const getChargeRecordRefundHeadline = (
    addBreadcrumbHandler: (props: IBreadcrumbHandlerProps) => void
  ) => ({
    id: 'add-cdr-refund',
    headline: t(terms.refunds.add_headline),
    content:
      currentInvoice.state === 'COMPLETED' ? (
        <span
          className={classes.itemLink}
          data-testid='show-cdr-refund'
          onClick={() =>
            addBreadcrumbHandler({
              component: 'ChargeRecordRefund',
              friendlyText: t(terms.refunds.add_cdr_link),
              withSidebar: true,
            })
          }
        >
          {t(terms.refunds.add_cdr_link)}
        </span>
      ) : (
        <span
          className={classes.disableRefund}
          data-testid='show-cdr-refund'
          title='Invoice not completed. Refunding is not possible.'
          onClick={() =>
            toast.error(
              'Invoice is not completed. Refunding is not possible yet.'
            )
          }
        >
          {t(terms.refunds.add_cdr_link)}
        </span>
      ),
  });

  const getSubscriptionRefundHeadline = (
    addBreadcrumbHandler: (props: IBreadcrumbHandlerProps) => void
  ) => ({
    id: 'add-subscription-refund',
    headline: t(terms.refunds.add_headline),
    content:
      currentInvoice.state === 'COMPLETED' ? (
        <span
          className={classes.itemLink}
          data-testid='show-subscription-refund'
          onClick={() =>
            addBreadcrumbHandler({
              component: 'SubscriptionRefund',
              friendlyText: t(terms.refunds.add_subscription_link),
            })
          }
        >
          {t(terms.refunds.add_subscription_link)}
        </span>
      ) : (
        <span
          className={classes.disableRefund}
          data-testid='show-subscription-refund'
          title='Invoice not completed. Refunding is not possible.'
          onClick={() =>
            toast.error(
              'Invoice is not completed. Refunding is not possible yet.'
            )
          }
        >
          {t(terms.refunds.add_subscription_link)}
        </span>
      ),
  });

  const getPDFHeadline = (
    addBreadcrumbHandler: (props: IBreadcrumbHandlerProps) => void
  ) => ({
    id: 'show-invoice-pdf',
    headline: 'PDF',
    content: (
      <span
        className={classes.itemLink}
        data-testid='show-invoice-pdf'
        onClick={() =>
          addBreadcrumbHandler({
            component: 'InvoicePDF',
            friendlyText: 'Invoice PDF',
            withSidebar: true,
          })
        }
      >
        Show PDF
      </span>
    ),
  });

  const getInformationItems = () => {
    const detailItems: IInformationItem[] = [
      {
        id: 'document-date',
        headline: t(terms.invoice.table.columns.document_date),
        content: formatDateNoTime(currentInvoice.invoice_date),
      },
      {
        id: 'total',
        headline: 'Total',
        content: parseAmount(
          currentInvoice.gross_amount,
          currentInvoice.currency
        ),
      },
    ];

    return detailItems;
  };

  const getActionItems = () => {
    const actionItems: {
      id: string;
      headline: string;
      content: JSX.Element;
    }[] = [];

    if (!addBreadcrumbHandler) {
      return actionItems;
    }

    if (!hidePDF) {
      actionItems.push(getPDFHeadline(addBreadcrumbHandler));
    }
    if (!hideCreditNotes) {
      actionItems.push(getCreditNotesHeadline(addBreadcrumbHandler));
    }
    if (!hideRefunds && ccUserRolesQuery.data?.isCCSupervisor) {
      if (isSubscriptionRefundAvailable) {
        actionItems.push(getSubscriptionRefundHeadline(addBreadcrumbHandler));
      }
      actionItems.push(getChargeRecordRefundHeadline(addBreadcrumbHandler));
    }

    return actionItems;
  };

  return (
    <div
      style={{
        display: 'flex',
        flexDirection: 'column',
        rowGap: 10,
      }}
    >
      <InformationItems items={getInformationItems()} title='Details' />
      <InformationItems items={getActionItems()} />
    </div>
  );
};

export default Details;
