import { AppDispatch } from '../../../../store';
import { ActionCreator } from '../../../../store/state/actions';
import { ActionInterface } from '../../../../store/state/subscriber/actions';
import { Dispatcher } from '../index';

interface IDispatchers {
  setSubscriberTemp: Dispatcher<ActionInterface.SetSubscriberTemp>;
  getSubscriberTempRequest: Dispatcher<ActionInterface.GetSubscriberRequest>;
  clearSubscriberTemp: Dispatcher<ActionInterface.ClearSubscriberTemp>;
  updateBillingAddress: Dispatcher<ActionInterface.UpdateBillingAddressRequest>;
  getSubscriberOnlyRequest: Dispatcher<
    ActionInterface.GetSubscriberOnlyRequest
  >;
}

export const createDispatchers = (dispatch: AppDispatch): IDispatchers => ({
  setSubscriberTemp: (payload) =>
    dispatch(ActionCreator.subscriber.SetSubscriberTemp(payload)),

  getSubscriberTempRequest: (payload) =>
    dispatch(ActionCreator.subscriber.GetSubscriberTempRequest(payload)),

  clearSubscriberTemp: (payload) =>
    dispatch(ActionCreator.subscriber.ClearSubscriberTemp(payload)),

  updateBillingAddress: (payload) =>
    dispatch(ActionCreator.subscriber.UpdateBillingAddressRequest(payload)),

  getSubscriberOnlyRequest: (payload) =>
    dispatch(ActionCreator.subscriber.GetSubscriberOnlyRequest(payload)),
});
