import { IUpdateBillingAddress } from '../../../services/billingAddress';
import { ISubscriberModel } from '../../../types/subscriber';
/* eslint-disable @typescript-eslint/naming-convention */
import { actionCreator } from '../../type-utils';

export const ActionType = {
  GetSubscriberRequest: 'subscriber/get-subscriber-request',
  GetSubscriberSuccess: 'subscriber/get-subscriber-success',
  GetSubscriberError: 'subscriber/get-subscriber-error',
  ClearSubscriber: 'subscriber/clear-subscriber',
  GetSubscriberTempRequest: 'subscriber/get-subscriber-temp-request',
  GetSubscriberTempSuccess: 'subscriber/get-subscriber-temp-success',
  ClearSubscriberTemp: 'subscriber/clear-subscriber-temp',
  SetSubscriberTemp: 'subscriber/set-subscriber-temp',
  GetSubscriberOnlyRequest: 'subscriber/get-subscriber-only-request',
  GetSubscriberOnlySuccess: 'subscriber/get-subscriber-only-success',
  UpdateBillingAddressRequest: 'subscriber/update-billing-address',
  UpdateBillingAddressSuccess: 'subscriber/update-billing-address-success',
  UpdateBillingAddressError: 'subscriber/update-billing-address-error',
};

export declare namespace ActionInterface {
  export interface GetSubscriberRequest {
    type: string;
    userId: string;
    organizationId: string;
  }

  export interface GetSubscriberSuccess {
    type: string;
    data: ISubscriberModel;
  }

  export interface GetSubscriberError {
    type: string;
    error: Error;
  }

  export interface ClearSubscriber {
    type: any;
  }

  export interface GetSubscriberTempSuccess {
    type: string;
    searchedData: ISubscriberModel;
  }

  export interface ClearSubscriberTemp {
    type: string;
  }

  export interface SetSubscriberTemp {
    type: string;
    subscriber: ISubscriberModel;
  }

  export interface GetSubscriberOnlyRequest {
    type: string;
    userId: string;
  }

  export interface GetSubscriberOnlySuccess {
    type: string;
    data: ISubscriberModel;
  }

  export interface UpdateBillingAddressRequest {
    type: string;
    subscriberId: string;
    subscriberAddress: IUpdateBillingAddress;
  }

  export interface UpdateBillingAddressSuccess {
    type: string;
    subscriberAddress: IUpdateBillingAddress;
  }

  export interface UpdateBillingAddressError {
    type: string;
    error: Error;
  }
}

export const ActionCreator = {
  GetSubscriberRequest: actionCreator<ActionInterface.GetSubscriberRequest>(
    ActionType.GetSubscriberRequest
  ),
  GetSubscriberSuccess: actionCreator<ActionInterface.GetSubscriberSuccess>(
    ActionType.GetSubscriberSuccess
  ),
  GetSubscriberError: actionCreator<ActionInterface.GetSubscriberError>(
    ActionType.GetSubscriberError
  ),
  ClearSubscriber: actionCreator<ActionInterface.ClearSubscriber>(
    ActionType.ClearSubscriber
  ),
  GetSubscriberTempRequest: actionCreator<ActionInterface.GetSubscriberRequest>(
    ActionType.GetSubscriberTempRequest
  ),
  GetSubscriberTempSuccess: actionCreator<
    ActionInterface.GetSubscriberTempSuccess
  >(ActionType.GetSubscriberTempSuccess),
  ClearSubscriberTemp: actionCreator<ActionInterface.ClearSubscriberTemp>(
    ActionType.ClearSubscriberTemp
  ),
  SetSubscriberTemp: actionCreator<ActionInterface.SetSubscriberTemp>(
    ActionType.SetSubscriberTemp
  ),
  GetSubscriberOnlyRequest: actionCreator<
    ActionInterface.GetSubscriberOnlyRequest
  >(ActionType.GetSubscriberOnlyRequest),
  GetSubscriberOnlySuccess: actionCreator<
    ActionInterface.GetSubscriberOnlySuccess
  >(ActionType.GetSubscriberOnlySuccess),
  UpdateBillingAddressRequest: actionCreator<
    ActionInterface.UpdateBillingAddressRequest
  >(ActionType.UpdateBillingAddressRequest),
  UpdateBillingAddressSuccess: actionCreator<
    ActionInterface.UpdateBillingAddressSuccess
  >(ActionType.UpdateBillingAddressSuccess),
  UpdateBillingAddressError: actionCreator<
    ActionInterface.UpdateBillingAddressError
  >(ActionType.UpdateBillingAddressError),
};
