import React, { useEffect, useState } from 'react';
import { FlowColumnDiagram } from '../FlowColumnDiagram/FlowColumnDiagram';
import { ICircleState } from '../index';
import connector, { IPropsFromState } from '../../../../Connector/Connector';
import { defaultSignedUpCircleState } from './defaultCircleStates';

export const AppChargingReadinessDiagram: React.FC<IPropsFromState> = ({
  subscriptionState,
}) => {
  const [isLoading, setIsLoading] = useState(true);
  useEffect(() => {
    setIsLoading(subscriptionState.loading);
  }, [subscriptionState]);

  const activeSubscription = subscriptionState.data
    ? subscriptionState.data.find((sub) => sub.status === 'active')
    : undefined;

  const [signedUpState, setSignedUpState] = useState<ICircleState>(
    defaultSignedUpCircleState
  );
  useEffect(() => {
    if (activeSubscription) {
      setSignedUpState({ ...signedUpState, circleState: 'happy' });
    } else {
      setSignedUpState({ ...signedUpState, circleState: 'sad' });
    }
  }, [activeSubscription]);

  const [circles, setCircles] = useState<ICircleState[]>([signedUpState]);
  useEffect(() => {
    if (isLoading) {
      setCircles([{ ...signedUpState, circleState: 'loading' }]);
    } else {
      setCircles([signedUpState]);
    }
  }, [signedUpState, isLoading]);

  const title = 'App';
  const appIcon: JSX.Element = (
    <svg viewBox='0 0 24 24' fill='none' xmlns='http://www.w3.org/2000/svg'>
      <path
        d='M20.0254 7.80005C20.5504 8.32505 21.0004 9.00005 21.3004 9.67505C21.6004 10.425 21.7504 11.175 21.7504 12C21.7504 12.825 21.6004 13.65 21.3004 14.325C21.0004 15.075 20.5504 15.675 20.0254 16.2'
        strokeMiterlimit='10'
        strokeLinecap='round'
      />
      <path
        d='M17.8496 9.8999C18.1496 10.1999 18.3746 10.4999 18.5246 10.8749C18.6746 11.2499 18.7496 11.6249 18.7496 12.0749C18.7496 12.5249 18.6746 12.8999 18.5246 13.2749C18.3746 13.6499 18.1496 13.9499 17.8496 14.2499'
        strokeMiterlimit='10'
        strokeLinecap='round'
      />
      <path d='M6.75 18.75H11.25' strokeLinecap='round' />
      <path d='M13.5 21.75H4.5C3.675 21.75 3 21.075 3 20.25V3.75C3 2.925 3.675 2.25 4.5 2.25H13.5C14.325 2.25 15 2.925 15 3.75V20.25C15 21.075 14.325 21.75 13.5 21.75Z' />
    </svg>
  );

  return (
    <FlowColumnDiagram
      title={title}
      titleIcon={appIcon}
      circles={circles}
      testId='app-charging-readiness-diagram'
    />
  );
};

export default connector(AppChargingReadinessDiagram);
