import makeStyles from '@mui/styles/makeStyles';
import createStyles from '@mui/styles/createStyles';

const useStyles = makeStyles(() =>
  createStyles({
    capability: {
      paddingBottom: '16px',
      display: 'flex',
      flexDirection: 'row',
      alignItems: 'center',
    },
    capabilityLabel: {
      paddingLeft: '8px',
    },
  })
);

export default useStyles;
