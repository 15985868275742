import React from 'react';

import useStyles from './useStyles';
import { Skeleton } from '@mui/material';

export interface IInformationItem {
  headline: React.ReactNode | string;
  content: React.ReactNode | string | number;
  id: string;
}

export interface IOwnProps {
  item: IInformationItem;
  hasMarginRight?: boolean;
  isLoading?: boolean;
}

/**
 * Simple container for information used e.g on detail cards
 * @param item information headline content
 * @param hasMarginRight option to show right margin
 * @param isLoading option to display loading skeleton
 */
const InformationItem: React.FC<IOwnProps> = ({
  item,
  hasMarginRight = true,
  isLoading = false,
}) => {
  const classes = useStyles();
  const { headline, content, id } = item;

  return (
    <div
      className={`${classes.itemWrapper} ${
        hasMarginRight ? classes.marginRight : ''
      }`}
    >
      <span className={classes.itemTitle}>{headline}</span>
      {isLoading ? (
        <Skeleton
          width={'100%'}
          height={'100%'}
          data-testid={id + '-is-loading'}
        />
      ) : (
        <span className={classes.itemValue} data-testid={id}>
          {content}
        </span>
      )}
    </div>
  );
};

export default InformationItem;
