import { useCallback, useState } from 'react';

type IUseToggle = ReturnType<() => [boolean, () => void]>;

/**
 * Toggles a boolean state
 * @param initialValue initial state
 */
export const useToggle = (initialValue: boolean): IUseToggle => {
  const [state, setState] = useState(initialValue);
  const toggleState = useCallback(() => setState((prev) => !prev), []);

  return [state, toggleState];
};
