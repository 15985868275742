import {
  IPublicChargingSession,
  IRemoteStopResponse,
} from '../types/publicCharging';
import ApiService from './api';

const getChargingSessions = async (
  userId: string
): Promise<IPublicChargingSession[]> => {
  const apiService = new ApiService(
    `/mgmt/publiccharging/v1/chargingsessions?iam_id=${userId}`,
    {
      method: 'GET',
    }
  );
  const response = await apiService.query();
  return response.data;
};

const STOP_SESSION_TIMEOUT_S = 20;
const stopSession = async (
  userId: string,
  evseId: string
): Promise<IRemoteStopResponse> => {
  const apiService = new ApiService(
    `/mgmt/publiccharging/v1/chargingsessions/stop`,
    {
      method: 'POST',
      data: {
        iam_id: userId,
        evse_id: evseId,
      },
      timeout: STOP_SESSION_TIMEOUT_S * 1000,
    }
  );
  const response = await apiService.query();
  return response.data;
};

const terminateSession = async (
  userId: string,
  evseId: string
): Promise<undefined> => {
  const apiService = new ApiService(
    `/mgmt/publiccharging/v1/chargingsessions/terminate`,
    {
      method: 'POST',
      data: {
        iam_id: userId,
        evse_id: evseId,
      },
    }
  );
  const response = await apiService.query();
  return response.data;
};

export const PublicChargingService = {
  getChargingSessions,
  stopSession,
  terminateSession,
  STOP_SESSION_TIMEOUT_S,
};
