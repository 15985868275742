import React from 'react';
import { IVehiclePairedUserModel } from '../../../../types/vehicle';
import useStyles from './useStyles';
import { Grid } from '@mui/material';
import InformationItem from '../../../shared/InformationItems/InformationItem';
import { useTypedTranslation } from '../../../../custom-hooks/useTypedTranslation';
import { ElliTooltip } from '../../../shared/ElliTooltip/ElliTooltip';

type TOwnProps = {
  user: IVehiclePairedUserModel;
};

export const AssociatedUser: React.FC<TOwnProps> = ({ user }) => {
  const classes = useStyles();
  const { t, terms } = useTypedTranslation();

  const getItems = (userData: IVehiclePairedUserModel) => {
    return [
      {
        id: 'user-email',
        headline: t(terms.vin_eligibility.vin_overview.email),
        content: (
          <ElliTooltip
            heading={t(terms.vin_eligibility.vin_overview.email)}
            title={userData.email}
            alwaysShowOnHover={true}
          >
            <span className={classes.itemValue}>{userData.email}</span>
          </ElliTooltip>
        ),
      },
      {
        id: 'user-iam-id',
        headline: t(terms.vin_eligibility.vin_overview.iam_user_id),
        content: (
          <ElliTooltip
            heading={t(terms.vin_eligibility.vin_overview.iam_user_id)}
            title={userData.iam_id}
            alwaysShowOnHover={true}
          >
            <span className={classes.itemValue}>{userData.iam_id}</span>
          </ElliTooltip>
        ),
      },
      {
        id: 'customer-number',
        headline: t(terms.vin_eligibility.vin_overview.customer_number),
        content: (
          <ElliTooltip
            heading={t(terms.vin_eligibility.vin_overview.customer_number)}
            title={userData.customer_number ?? '-'}
            alwaysShowOnHover={true}
          >
            <span className={classes.itemValue}>
              {userData.customer_number ?? '-'}
            </span>
          </ElliTooltip>
        ),
      },
    ];
  };

  return (
    <>
      <h2 className={classes.userName}>{user.name}</h2>
      <Grid
        container
        justifyContent='space-between'
        alignItems='flex-start'
        direction='row'
        className={classes.userGrid}
      >
        {getItems(user).map((item) => (
          <Grid item xs={4} key={item.id}>
            <InformationItem item={item} />
          </Grid>
        ))}
      </Grid>
    </>
  );
};

export default AssociatedUser;
