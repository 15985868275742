import { AppDispatch } from '../../../../store';
import { ActionCreator } from '../../../../store/state/actions';
import { ActionInterface } from '../../../../store/state/organization/actions';
import { Dispatcher } from '../index';

interface IDispatchers {
  getOrganizationRequest: Dispatcher<ActionInterface.GetOrganizationRequest>;
  sortOrganizationStations: Dispatcher<
    ActionInterface.SortOrganizationStations
  >;
  sortAccounts: Dispatcher<ActionInterface.SortAccounts>;
}

export const createDispatchers = (dispatch: AppDispatch): IDispatchers => ({
  getOrganizationRequest: (payload) =>
    dispatch(ActionCreator.organization.GetOrganizationRequest(payload)),

  sortOrganizationStations: (payload) =>
    dispatch(ActionCreator.organization.SortOrganizationStations(payload)),

  sortAccounts: (payload) =>
    dispatch(ActionCreator.organization.SortAccounts(payload)),
});
