import { ActionInterface } from '../actions';
import initialState, { State } from '..';
import update from 'immutability-helper';

export default (
  state: State,
  action: ActionInterface.UpdateBreadcrumb
): State => {
  if (!state) {
    return {
      ...initialState,
    };
  }

  const breadcrumbState = state;

  const currentCrumb = breadcrumbState.breadcrumbs.findIndex(
    (bc) => bc.id === action.data.id
  );

  const updatedBreadcrumbs = update(breadcrumbState, {
    breadcrumbs: {
      [currentCrumb]: {
        $merge: {
          ...action.data,
        },
      },
    },
  });

  return {
    ...state,
    ...updatedBreadcrumbs,
  };
};
