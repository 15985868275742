import { ElliTooltip } from '../../shared/ElliTooltip/ElliTooltip';
import { IBreadcrumbHandlerProps } from '../../../utils/addBreadcrumbHandler';
import { ICell } from '../../shared/AssetTable/AssetTable/AssetTable';
import { IChargingRecordViewModel } from '../../../types/chargingRecords';
import { IRFIDCardModel } from '../../../types/subscriber';
import React from 'react';
import decimalToComma from '../../../utils/decimalToComma';
import { formatIsoDates } from '../../../utils/dates';
import parseSeconds from '../../../utils/parseSeconds';

interface ISharedStyle {
  textOverflow: string;
}

export const parseConsumption = (consumption: number): string => {
  if (consumption) {
    return `${decimalToComma(consumption.toFixed(3))} kWh`;
  }

  return '-';
};

export const getSharedChargingSessionsData = (
  cs: IChargingRecordViewModel,
  style: ISharedStyle
): { [key: string]: ICell } => {
  return {
    created_at: {
      content: (
        <div data-testid='created-at-data'>
          {formatIsoDates(cs.creation_date)}
        </div>
      ),
    },
    total_time: {
      content: (
        <span data-testid='duration-data'>
          {cs.invalid_duration ? '-' : parseSeconds(cs.duration)}
        </span>
      ),
    },
    total_energy: {
      content: (
        <ElliTooltip title={parseConsumption(cs.consumption)}>
          <span className={`${style.textOverflow}`} data-testid='consumption'>
            {parseConsumption(cs.consumption)}
          </span>
        </ElliTooltip>
      ),
    },
    total_price: {
      content:
        cs.price !== undefined ? (
          <span data-testid='price-data'>{`${decimalToComma(
            Number(cs.price).toFixed(2)
          )} ${cs.currency}`}</span>
        ) : (
          <span data-testid='price-data'>-</span>
        ),
    },
  };
};

export const getStationDisplayName = (
  chargingRecord: IChargingRecordViewModel
): string => {
  if (chargingRecord.type === 'public' && chargingRecord.station_id) {
    return chargingRecord.station_id;
  } else if (chargingRecord.station_name) {
    return chargingRecord.station_name;
  } else if (chargingRecord.station_serial_number) {
    return chargingRecord.station_serial_number;
  } else if (chargingRecord.station_id) {
    return chargingRecord.station_id;
  } else {
    return '-';
  }
};

export const addClickableLink = (data: string, style: ISharedStyle): string => {
  return data === '-'
    ? `${style.textOverflow}`
    : `clickableCell ${style.textOverflow}`;
};

export const getCardNumber = (
  id: string | undefined,
  serialNumber: string | undefined,
  addBreadcrumbHandler: (props: IBreadcrumbHandlerProps) => void,
  chargingCards: IRFIDCardModel[],
  style: ISharedStyle
): string | JSX.Element => {
  if (chargingCards && id) {
    const card = chargingCards.find((cc) => cc.id === id);
    if (card) {
      return (
        <ElliTooltip title={card.number}>
          <span
            className={addClickableLink(card.number, {
              textOverflow: style.textOverflow,
            })}
            data-testid='card-link'
            onClick={() =>
              addBreadcrumbHandler({
                component: 'ChargingCardDetails',
                friendlyText: 'Charging Card',
                id: card.id,
              })
            }
          >
            {card.number}
          </span>
        </ElliTooltip>
      );
    }
  }

  if (serialNumber) {
    return (
      <ElliTooltip
        heading='This card is not owned by this user'
        title={serialNumber}
        alwaysShowOnHover={true}
      >
        <span className={style.textOverflow} data-testid='card-link'>
          {serialNumber}
        </span>
      </ElliTooltip>
    );
  }

  return '-';
};
