import React from 'react';
import { AxiosError } from 'axios';

import { PAIRING_ACTIONS } from './pairingReducer';
import { pairRFIDCardAndSubscription } from '../../../../services/rfidCards';

/**
 * Handles pairing of rfid card and subscription as well as resulting state changes
 */
export const preparePairing = async (
  dispatch: React.Dispatch<any>,
  subscriptionId?: string,
  inputRfidCardId?: string
): Promise<void> => {
  if (!subscriptionId || !inputRfidCardId) {
    return;
  }

  dispatch({ type: PAIRING_ACTIONS.PENDING });

  try {
    await pairRFIDCardAndSubscription(subscriptionId, inputRfidCardId);
    dispatch({ type: PAIRING_ACTIONS.SUCCESS });
  } catch (error) {
    let message = 'Pairing failed';
    const { errors } = (error as AxiosError)?.response?.data;

    if (errors?.length && errors[0].message) {
      message = errors[0].message;
    }

    dispatch({
      type: PAIRING_ACTIONS.WARNING,
      payload: message,
    });
  }
};
