import { ActionInterface, ActionType } from '../actions';
import initialState, { State } from '..';

import getActiveSessionsError from './getActiveSessionsError';
import getActiveSessionsRequest from './getActiveSessionsRequest';
import getActiveSessionsSuccess from './getActiveSessionsSuccess';

const reducer = (state: State = initialState, action: any) => {
  switch (action.type) {
    case ActionType.GetActiveSessionsRequest:
      return getActiveSessionsRequest(
        state,
        action as ActionInterface.GetActiveSessionsRequest
      );
    case ActionType.GetActiveSessionsSuccess:
      return getActiveSessionsSuccess(
        state,
        action as ActionInterface.GetActiveSessionsSuccess
      );
    case ActionType.GetActiveSessionsError:
      return getActiveSessionsError(
        state,
        action as ActionInterface.GetActiveSessionsError
      );
    default:
      return state;
  }
};

export default reducer;
