import React from 'react';
import { IBreadcrumbHandlerProps } from '../../../../utils/addBreadcrumbHandler';
import { CardButton } from '../../CardButton';

interface IOwnProps {
  breadcrumbHandler: (props: IBreadcrumbHandlerProps) => void;
  organizationStationsNumber: number;
}

export const StationsActionButton: React.FC<IOwnProps> = ({
  organizationStationsNumber,
  breadcrumbHandler,
}) => (
  <CardButton
    onClick={() =>
      breadcrumbHandler({
        component: 'ChargingStationsCollection',
        friendlyText: 'Stations',
        large: true,
      })
    }
    length={organizationStationsNumber}
    title='Stations'
  />
);
