import React from 'react';
import Check from '@mui/icons-material/Check';
import ErrorOutline from '@mui/icons-material/ErrorOutline';
import connector, { IPropsFromState } from '../../Connector/Connector';
import useStyles from './useStyles';

type TProps = IPropsFromState;

export const UserVerified: React.FC<TProps> = ({
  subscriberState,
  setSelectedUser,
  userState,
  setSubscriberTemp,
  openVerificationFlow,
  setVerificationStep,
}) => {
  const classes = useStyles();
  const user = userState;
  const subscriber = subscriberState;
  const classList = user.verified
    ? `${classes.verifiedBox} ${classes.verified}`
    : `${classes.verifiedBox} ${classes.unverified}`;

  const continueVerification = () => {
    if (user.verified) {
      return;
    }
    if (subscriber && subscriber.data) {
      setSubscriberTemp({ subscriber: subscriber.data });
    }
    if (user && user.data) {
      setSelectedUser({ user: user.data });
    }
    setVerificationStep({ step: 3 });
    openVerificationFlow({ open: true });
  };

  return (
    <div
      style={{
        cursor: user.verified ? 'auto' : 'pointer',
        marginRight: '30px',
      }}
      onClick={continueVerification}
      className={classList}
      data-testid='user-verified-component'
    >
      {user.verified ? (
        <>
          <Check className={classes.verifiedImage} style={{ color: '#fff' }} />
          <span data-testid='user-verified'>Verified</span>
        </>
      ) : (
        <>
          <ErrorOutline
            className={classes.verifiedImage}
            style={{ color: '#fff' }}
          />
          <span>Unverified</span>
        </>
      )}
    </div>
  );
};

export default connector(UserVerified);
