import createStyles from '@mui/styles/createStyles';
import makeStyles from '@mui/styles/makeStyles';

const useStyles = makeStyles(() =>
  createStyles({
    wideDialog: {
      maxWidth: 690,
      paddingLeft: 24,
      paddingRight: 24,
      paddingTop: 64,
      paddingBottom: 24,
    },
    narrowDialog: {
      maxWidth: 690,
      padding: 64,
    },
    closeButton: {
      position: 'absolute',
      right: 6,
      top: 6,
      padding: 10,
    },
  })
);

export default useStyles;
