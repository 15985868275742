export const paginateArray = <T>(
  arrayItems: T[],
  itemsPerPage: number
): T[][] => {
  return arrayItems.reduce((resultArray: T[][], item, index) => {
    const chunkIndex = Math.floor(index / itemsPerPage);

    if (!resultArray[chunkIndex]) {
      resultArray[chunkIndex] = [];
    }

    resultArray[chunkIndex].push(item);

    return resultArray;
  }, []);
};
