import { AxiosResponse } from 'axios';

import ApiService from './api';
import { ICreditNote } from '../types/creditNote';
import { PaymentStatus } from '../types/payment';
import { IInvoiceRefundRequest } from '../types/invoice';

const postRefund = async (
  subscriberId: string,
  invoiceId: string,
  refundData: IInvoiceRefundRequest
): Promise<ICreditNote> => {
  const apiService = new ApiService(
    `/mgmt/msp-billing/v2/subscriber/${subscriberId}/invoices/${invoiceId}/refund`,
    {
      method: 'POST',
      data: refundData,
    }
  );

  const response = await apiService.query();

  return response.data;
};

const getPdfForCreditNote = async (
  subscriberId: string,
  invoiceId: string,
  creditNoteId: string
): Promise<AxiosResponse<Blob>> => {
  const apiService = new ApiService(
    `/mgmt/msp-billing/v2/subscriber/${subscriberId}/invoices/${invoiceId}/creditmemos/${creditNoteId}/pdf`,
    {
      responseType: 'blob',
    }
  );
  return apiService.query();
};

const getCreditNotePaymentStatus = async (
  creditNoteNumber: string
): Promise<PaymentStatus> => {
  const apiService = new ApiService(
    `/mgmt/msp-payment/v1/payment/creditmemo/${creditNoteNumber}/status`
  );
  const response = await apiService.query();
  return response.data.status;
};

export const RefundService = {
  getPdfForCreditNote,
  postRefund,
  getCreditNotePaymentStatus,
};
