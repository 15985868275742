import update from 'immutability-helper';
import { State } from '../';
import { ActionInterface } from '../actions';
import serializeError from 'serialize-error';

export default (
  state: State,
  action: ActionInterface.UpdateUserError
): State => {
  const { error } = action;
  const currentUser = state;
  const updatedUser = update(currentUser, {
    error: {
      $set: serializeError(error),
    },
    loading: {
      $set: false,
    },
  });

  return {
    ...state,
    ...updatedUser,
  };
};
