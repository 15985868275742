import React, { useState } from 'react';
import { Divider, FormHelperText, Grid } from '@mui/material';

import connector, { IPropsFromState } from '../../../../Connector/Connector';
import useStyles from '../useStyles';
import DialogHeadline from '../../DialogHeadline';
import MultilineButton from '../../../Buttons/MultilineButton';
import { useTypedTranslation } from '../../../../../custom-hooks/useTypedTranslation';
import { orderRfidCardFormSchema } from './OrderRfidCardForm.schema';
import { getDefaultValues, IRfidCardOrderFields } from './fields';
import { RfidCardOrdersService } from '../../../../../services/rfidCardOrders';
import { ISubscriberModel } from '../../../../../types/subscriber';
import { IUserModel } from '../../../../../types/user';
import { Form } from '../../../form-elements';
import AdditionalInformation from './AdditionalInformation/AdditionalInformation';
import ShippingAddress from './ShippingAddress/ShippingAddress';

type SubmitState = 'submitted' | 'failed' | 'open';

export interface IOwnProps extends IPropsFromState {
  subscriber: ISubscriberModel;
  user: IUserModel | null;
  onSuccess(): void;
}

/**
 * Creates a form to order a new RFID card for user
 */
const OrderRfidCardForm: React.FC<IOwnProps> = ({
  subscriber,
  user,
  onSuccess,
  subscriptionState,
  getRFIDCardOrderRequest,
}) => {
  const classes = useStyles();
  const { t, terms } = useTypedTranslation();
  const [submitState, setSubmitState] = useState<SubmitState>('open');

  const onSubmit = async (data: IRfidCardOrderFields) => {
    const subscriptions = subscriptionState.data || [];
    const activeSubscription = subscriptions.find(
      ({ status }) => status === 'active'
    );
    try {
      await RfidCardOrdersService.orderRfidCard(
        subscriber.id,
        data,
        activeSubscription?.id
      );
      setSubmitState('submitted');
      getRFIDCardOrderRequest({ subscriberId: subscriber.id });
      onSuccess();
    } catch (e) {
      setSubmitState('failed');
    }
  };

  return (
    <div className={classes.outerWrapper}>
      <div>
        <DialogHeadline
          headline={t(terms.order_card_dialog.headline)}
          subHeadline={t(terms.order_card_dialog.sub_headline)}
        />
      </div>

      <Form<IRfidCardOrderFields>
        defaultValues={getDefaultValues(subscriber, user)}
        schema={orderRfidCardFormSchema}
        className={classes.form}
        onSubmit={onSubmit}
      >
        <Grid container spacing={2}>
          <ShippingAddress />
          <Grid item xs={12}>
            <Divider />
          </Grid>
          <AdditionalInformation />
          <Grid item xs={12} className={classes.submitWrapper}>
            <MultilineButton mainText={t(terms.order_card_dialog.submit)} />
            {submitState === 'failed' && (
              <FormHelperText className={classes.errorMessage}>
                {t(terms.order_card_dialog.request_failed)}
              </FormHelperText>
            )}
          </Grid>
        </Grid>
      </Form>
    </div>
  );
};

export default connector(OrderRfidCardForm);
