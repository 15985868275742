import React from 'react';
import { IBreadcrumbHandlerProps } from '../../../../utils/addBreadcrumbHandler';
import { CardButton } from '../../CardButton';

interface IOwnProps {
  breadcrumbHandler: (props: IBreadcrumbHandlerProps) => void;
  chargingCardsNumber: number;
}

export const ChargingCardsActionButton: React.FC<IOwnProps> = ({
  chargingCardsNumber,
  breadcrumbHandler,
}) => (
  <CardButton
    onClick={() =>
      breadcrumbHandler({
        component: 'ChargingCardsCollection',
        friendlyText: 'Charging Cards',
        large: true,
      })
    }
    length={chargingCardsNumber}
    title='Cards'
  />
);
