import { ISubscriberModel } from '../../../../types/subscriber';
import { IUpdateBillingAddress } from '../../../../services/billingAddress';
import { TInputProps } from '../../../shared/form-elements/input/Input.types';
import { TSelectProps } from '../../../shared/form-elements/select/Select.types';
import { countryInformationMapper } from '../../../../utils/currencies';
import { useTypedTranslation } from '../../../../custom-hooks/useTypedTranslation';

type DefaultValues = Omit<IChangeBillingFields, 'zip'>;

export interface IChangeBillingFields {
  zip: string;
  city: string;
  country_code: string;
  tax_number?: string;
  address_line_1?: string;
  address_line_2?: string;
  state?: string;
}

interface ITextFields {
  [key: string]: TInputProps<IChangeBillingFields>;
}
interface ISelectFields {
  [key: string]: TSelectProps<IChangeBillingFields>;
}

const DEFAULT_VALUES: DefaultValues = {
  city: '',
  country_code: '',
  address_line_1: '',
  address_line_2: '',
  state: '',
  tax_number: '',
};

const countryCodeOptions = Object.keys(countryInformationMapper).map((key) => ({
  key,
  value: key,
  content: key,
}));

export const useTextFields = (): ITextFields => {
  const { t, terms } = useTypedTranslation();
  const labels = terms.update_billing_address.fields;

  return {
    addressLine1: {
      id: 'address_line_1',
      name: 'address_line_1',
      label: t(labels.address_line_1),
    },
    addressLine2: {
      id: 'address_line_2',
      name: 'address_line_2',
      label: t(labels.address_line_2),
    },
    zip: {
      id: 'zip',
      name: 'zip',
      label: t(labels.zip),
      isRequired: true,
    },
    city: {
      id: 'city',
      name: 'city',
      label: t(labels.city),
      isRequired: true,
    },
    state: {
      id: 'state',
      name: 'state',
      label: t(labels.state),
    },
    taxNumber: {
      id: 'tax_number',
      name: 'tax_number',
      label: t(labels.tax_number),
    },
  };
};

export const useSelectFields = (): ISelectFields => {
  const { t, terms } = useTypedTranslation();
  const labels = terms.update_billing_address.fields;

  return {
    country: {
      label: t(labels.country_code),
      name: 'country_code',
      labelId: 'country',
      options: countryCodeOptions,
      isRequired: true,
    },
  };
};

export const getDefaultValues = (
  subscriberAddress: ISubscriberModel['address'],
  subscriberTaxNumber: ISubscriberModel['tax_number']
): Partial<IUpdateBillingAddress> => {
  if (!subscriberAddress) {
    return { ...DEFAULT_VALUES };
  }

  return {
    zip: subscriberAddress.zip,
    city: subscriberAddress.city || DEFAULT_VALUES.city,
    country_code: subscriberAddress.country_code || DEFAULT_VALUES.country_code,
    address_line_1:
      subscriberAddress.address_line_1 || DEFAULT_VALUES.address_line_1,
    address_line_2:
      subscriberAddress.address_line_2 || DEFAULT_VALUES.address_line_2,
    state: subscriberAddress.state || DEFAULT_VALUES.state,
    tax_number: subscriberTaxNumber || DEFAULT_VALUES.tax_number,
  };
};
