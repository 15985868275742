import React from 'react';
import { useTheme } from '@mui/material/styles';
import { SubscriptionStatus } from '../../types/subscriber';
import {
  BasicStatus,
  IMappedStatus,
} from '../shared/StatusIndicator/BasicStatus';

interface ISubscriptionStatusComponentProps {
  status: SubscriptionStatus;
  statusTypeForTestId?: string;
  CustomLabelComponent?: React.FC<{ label: string }>;
}

export const SubscriptionStatusComponent: React.FC<ISubscriptionStatusComponentProps> = ({
  status,
  statusTypeForTestId,
  CustomLabelComponent,
}) => {
  const theme = useTheme();

  function valueMapper(value: SubscriptionStatus): IMappedStatus {
    switch (value) {
      case 'active':
        return { color: theme.colorAccent, label: 'Active' };
      case 'approved':
        return { color: theme.colorPrimary, label: 'Approved' };
      case 'archived':
        return { color: theme.surfaceTertiary, label: 'Archived' };
      case 'cancelled':
        return { color: theme.colorAlertSecondary, label: 'Cancelled' };
      case 'completed':
        return { color: theme.colorAccent, label: 'Completed' };
      case 'upcoming':
        return { color: theme.colorPrimaryDark, label: 'Upcoming' };
      case 'inactive':
        return { color: theme.surfaceTertiary, label: 'Inactive' };
      case 'ordered':
        return { color: theme.colorAccent, label: 'Ordered' };
      case 'pending':
        return { color: theme.materialFrameColor, label: 'Pending' };
      case 'printed':
        return { color: theme.colorAlertSecondary, label: 'Printed' };
      case 'readytopair':
        return { color: theme.colorAccent, label: 'Ready to pair' };
      case 'rejected':
        return { color: theme.colorAlertPrimary, label: 'Rejected' };
      case 'shipped':
        return { color: theme.colorAccent, label: 'Shipped' };
      default:
        // TODO: Remove type assertions to RfidCardOrderStatus in
        //       src/components/OverviewCards/HomeCharging/index.tsx
        //       and return assertNever(status) instead
        //return assertNever(status);
        return { color: theme.surfaceTertiary, label: '[Unknown status]' };
    }
  }

  return (
    <BasicStatus
      status={status}
      valueMapper={valueMapper}
      statusTypeForTestId={statusTypeForTestId}
      CustomLabelComponent={CustomLabelComponent}
    />
  );
};
