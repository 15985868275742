import createStyles from '@mui/styles/createStyles';
import makeStyles from '@mui/styles/makeStyles';

const useStyles = makeStyles(() =>
  createStyles({
    container: {
      paddingTop: 16,
    },
    titleWrapper: {
      paddingBottom: 16,
    },
    panelTitle: { fontSize: 20 },
  })
);

export default useStyles;
