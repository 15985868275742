import { IFirmwareInformationModel } from '../../../../types/user';
import FirmwareTable from './FirmwareTable';
import React from 'react';
import useStyles from './useStyles';
import { IBreadcrumbHandlerProps } from '../../../../utils/addBreadcrumbHandler';
import { Typography } from '../../../shared/Typography/Typography';

interface IOwnProps {
  userId: string;
  stationId: string;
  firmwareInformation: IFirmwareInformationModel;
  breadcrumbHandler: (props: IBreadcrumbHandlerProps) => void;
  stationRefreshing: boolean;
}

export const Firmware: React.FC<IOwnProps> = ({
  userId,
  stationId,
  firmwareInformation,
  breadcrumbHandler,
  stationRefreshing,
}) => {
  const classes = useStyles();

  return (
    <div data-testid='firmware-info' className={classes.profileElement}>
      <Typography
        data-testid='firmware-table-header'
        classes={{
          h2: classes.firmwareHeader,
        }}
        variant='h2'
      >
        Firmware
      </Typography>
      <div className={`${stationRefreshing ? classes.stationRefreshing : ''}`}>
        <FirmwareTable
          userId={userId}
          stationId={stationId}
          firmwareInformation={firmwareInformation}
          addBreadcrumbHandler={breadcrumbHandler}
        />
      </div>
    </div>
  );
};
