import { ActionInterface, ActionType } from '../actions';
import initialState, { IPaymentDetailsModel, State } from '..';

import getPaymentDetailsError from './getPaymentDetailsError';
import getPaymentDetailsRequest from './getPaymentDetailsRequest';
import getPaymentDetailsSuccess from './getPaymentDetailsSuccess';
import { Action } from 'redux';

function isGetPaymentDetailsRequest(
  action: Action
): action is ActionInterface.GetPaymentDetailsRequest {
  return action.type === ActionType.GetPaymentDetailsRequest;
}

function isGetPaymentDetailsSuccess(
  action: Action
): action is ActionInterface.GetPaymentDetailsSuccess {
  return action.type === ActionType.GetPaymentDetailsSuccess;
}

function isGetPaymentDetailsError(
  action: Action
): action is ActionInterface.GetPaymentDetailsError {
  return action.type === ActionType.GetPaymentDetailsError;
}

const reducer = (
  state: State = initialState,
  action: Action
): IPaymentDetailsModel => {
  if (isGetPaymentDetailsRequest(action)) {
    return getPaymentDetailsRequest(state, action);
  }
  if (isGetPaymentDetailsSuccess(action)) {
    return getPaymentDetailsSuccess(state, action);
  }
  if (isGetPaymentDetailsError(action)) {
    return getPaymentDetailsError(state, action);
  }
  return state;
};

export default reducer;
