import update from 'immutability-helper';
import { State } from '..';

export default (state: State): State => {
  const currentUser = state;

  const updatedUser = update(currentUser, {
    selectedUser: {
      $set: null,
    },
    data: {
      $set: [],
    },
    loading: {
      $set: false,
    },
    error: {
      $set: null,
    },
    request: {
      $set: null,
    },
  });

  return {
    ...state,
    ...updatedUser,
  };
};
