import {
  AssetTable,
  IColumn,
  IRow,
  ISortState,
} from '../../shared/AssetTable/AssetTable/AssetTable';

import { ElliTooltip } from '../../shared/ElliTooltip/ElliTooltip';
import { IMember } from '../../../types/organization';
import React from 'react';
import useSharedStyles from '../../shared/useSharedStyles';
import {
  IFieldNames,
  mapFieldsForTable,
} from '../../../utils/mapFieldsForTable';

interface IOwnProps {
  accounts: IMember[];
  sortState: ISortState;
  sortRequestHandler: (columnKey: string) => void;
}

export const AccountsTable: React.FC<IOwnProps> = ({
  accounts,
  sortState,
  sortRequestHandler,
}) => {
  const sharedClasses = useSharedStyles();
  const fieldNames: IFieldNames = {
    email: {
      name: 'Email',
    },
    first_name: {
      name: 'First name',
    },
    last_name: {
      name: 'Last name',
    },
    roles: {
      name: 'Permissions',
    },
  };

  const getColumns = (): IColumn[] => {
    const sortableColumns = ['email', 'first_name', 'last_name', 'roles'];
    return mapFieldsForTable(fieldNames, sortableColumns);
  };

  const getRows = (): IRow[] => {
    if (!accounts || !accounts.length) {
      return [];
    }

    return accounts.map((account) => {
      return {
        data: {
          email: {
            content: account.email ? (
              <ElliTooltip title={account.email}>
                <span
                  className={sharedClasses.textOverflow}
                  data-testid='data-account-email'
                >
                  {account.email}
                </span>
              </ElliTooltip>
            ) : (
              <span data-testid='data-account-email'>-</span>
            ),
          },
          first_name: {
            content: account.first_name ? (
              <ElliTooltip title={account.first_name}>
                <span
                  className={sharedClasses.textOverflow}
                  data-testid='data-account-first_name'
                >
                  {account.first_name}
                </span>
              </ElliTooltip>
            ) : (
              <span data-testid='data-account-first_name'>-</span>
            ),
          },
          last_name: {
            content: account.last_name ? (
              <ElliTooltip title={account.last_name}>
                <span
                  className={sharedClasses.textOverflow}
                  data-testid='data-account-last_name'
                >
                  {account.last_name}
                </span>
              </ElliTooltip>
            ) : (
              <span data-testid='data-account-last_name'>-</span>
            ),
          },
          roles: {
            content: account.roles ? (
              <ElliTooltip title={account.roles} heading='Permissions'>
                <span
                  className={sharedClasses.textOverflow}
                  data-testid='data-account-roles'
                >
                  {account.roles.join(', ')}
                </span>
              </ElliTooltip>
            ) : (
              <span data-testid='data-account-roles'>-</span>
            ),
          },
        },
        id: account.id,
      };
    });
  };

  return (
    <div data-testid='accounts-table'>
      <AssetTable
        bulkActions={[]}
        columns={getColumns()}
        rows={getRows()}
        sortingState={sortState}
        sortRequestHandler={sortRequestHandler}
        noDataTitle='No accounts available'
        noDataSubTitle='There are no accounts in this Organization'
      />
    </div>
  );
};

export default AccountsTable;
