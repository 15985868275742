import makeStyles from '@mui/styles/makeStyles';
import createStyles from '@mui/styles/createStyles';

const useStyles = makeStyles(() =>
  createStyles({
    box: {
      padding: '24px',
    },
    headline: {
      paddingBottom: '24px',
    },
    section: {
      borderBottom: '1px solid #DADCE0',
      marginBottom: '24px',
    },
    sectionHeadline: {
      paddingBottom: '24px',
    },
    sectionDescription: {
      paddingBottom: '24px',
    },
    list: {
      listStyleType: 'none',
      margin: 0,
      padding: 0,
    },
  })
);

export default useStyles;
