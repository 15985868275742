import { QueryClient } from 'react-query';
import { toast } from 'react-hot-toast';
import { AxiosError } from '../test-utils/AxiosError';

function handleGlobalError(error: unknown) {
  const httpError = error as AxiosError;
  console.error(httpError.message);
  if (httpError.response && httpError.response.status === 401) {
    toast.error(`Session expired, please reload the page and login again`, {
      duration: Infinity,
    });
  }
  return Promise.resolve(error);
}

export const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      onError: (error) => handleGlobalError(error),
      staleTime: 5e3,
      refetchOnWindowFocus: false,
    },
    mutations: {
      onError: (error) => handleGlobalError(error),
    },
  },
});
