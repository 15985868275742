import { Theme } from '@mui/material';

import makeStyles from '@mui/styles/makeStyles';
import createStyles from '@mui/styles/createStyles';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    box: {
      background: theme.surfacePrimary,
      borderRadius: '4px',
      boxShadow: '0 6px 18px 0 rgba(0, 0, 0, 0.06)',
      display: 'flex',
      flexDirection: 'column',
      padding: '1em',
      width: 'fit-content',
      marginTop: '20px',
    },
    resultField: {
      marginBottom: '10px',
    },
    userIdInput: {
      marginBottom: '20px',
      marginTop: '25px',
      width: '473px',
    },
    inputRoot: {
      '& label.Mui-focused': {
        backgroundColor: 'white',
        paddingRight: '4px',
      },
    },
  })
);

export default useStyles;
