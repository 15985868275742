import styled from '@emotion/styled';
import { Typography } from '../../../../../../shared/Typography/Typography';

const TotalAmountLabelStyled = styled(Typography)`
  display: block;
  margin-top: 16px;
  text-align: end;
  width: 100%;
`;

export { TotalAmountLabelStyled };
