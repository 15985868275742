import { Grid } from '@mui/material';
import React from 'react';
import useSharedStyles from '../../shared/useSharedStyles';
import { Typography } from '../../shared/Typography/Typography';

export const SessionDisplayGrid = (
  itemsList: {
    title: string;
    text?: string;
    content?: JSX.Element;
    width?: number;
  }[]
): JSX.Element[] => {
  const sharedClasses = useSharedStyles();
  return itemsList.map(({ title, text, content, width }, index) => (
    <Grid
      item
      xs={width ? width : 4}
      className={sharedClasses.cardDetail}
      key={index}
    >
      <Typography variant='h6'>{title}</Typography>
      {text ? (
        <Typography
          variant='body2'
          data-testid='grid-item'
          className={sharedClasses.cardDetailData}
        >
          {text}
        </Typography>
      ) : (
        <div style={{ marginTop: 10 }} data-testid='grid-item'>
          {content}
        </div>
      )}
    </Grid>
  ));
};
