/* eslint-disable @typescript-eslint/naming-convention */
import { actionCreator } from '../../type-utils';
export const ActionType = {
  ClearAll: 'other/clear-all',
};

export declare namespace ActionInterface {
  export interface ClearAll {
    type: string;
  }
}

export const ActionCreator = {
  ClearAll: actionCreator<ActionInterface.ClearAll>(ActionType.ClearAll),
};
