import { ActionInterface, ActionType } from '../actions';
import initialState, { State } from '..';

import clearHomeStations from './clearHomeStations';
import getHomeStationsError from './getHomeStationsError';
import getHomeStationsRequest from './getHomeStationsRequest';
import getHomeStationsSuccess from './getHomeStationsSuccess';
import sortHomeStations from './sortHomeStations';

const reducer = (state: State = initialState, action: any) => {
  switch (action.type) {
    case ActionType.GetHomeStationsRequest:
      return getHomeStationsRequest(
        state,
        action as ActionInterface.GetHomeStationsRequest
      );
    case ActionType.GetHomeStationsSuccess:
      return getHomeStationsSuccess(
        state,
        action as ActionInterface.GetHomeStationsSuccess
      );
    case ActionType.GetHomeStationsError:
      return getHomeStationsError(
        state,
        action as ActionInterface.GetHomeStationsError
      );
    case ActionType.ClearHomeStations:
      return clearHomeStations(state);
    case ActionType.SortHomeStations:
      return sortHomeStations(
        state,
        action as ActionInterface.SortHomeStations
      );
    default:
      return state;
  }
};

export default reducer;
