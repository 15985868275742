/* eslint-disable @typescript-eslint/naming-convention */
import { ErrorObject } from 'serialize-error';
import { ISubscriptionModel } from '../../../types/subscriber';
import { ITerminateSubscriptionResponse } from '../../../services/subscriptions';

export interface GetSubscriptionRequest {
  subscriberId: string;
}

export interface SubscriptionStateModel {
  loading: boolean;
  data: ISubscriptionModel[] | null;
  terminationResponse: ITerminateSubscriptionResponse | null;
  error: ErrorObject | null;
  request: { subscriberId: string } | null;
}

export const initialState: SubscriptionStateModel = {
  loading: false,
  data: null,
  error: null,
  request: null,
  terminationResponse: null,
};

const state: SubscriptionStateModel = {
  ...initialState,
};

export type State = typeof state;
export default state;
