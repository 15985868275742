import makeStyles from '@mui/styles/makeStyles';
import createStyles from '@mui/styles/createStyles';

const useStyles = makeStyles(() =>
  createStyles({
    chargingCardDetailsHeader: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'space-between',
      marginBottom: '16px',
    },

    titleWrapper: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'flex-start',
    },

    titleIcon: {
      color: '#6633CB !important',
      fontSize: '24px !important',
      marginRight: '16px !important',
    },

    panelTitle: {
      fontSize: '20px !important',
    },

    chargingSessionsTableWrapper: {},

    filterWrapper: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'flex-start',
      marginLeft: '16px',
      marginBottom: '16px',
      minHeight: '32px',
    },

    filterButton: {
      fontWeight: 'bold', // !important',
    },

    filterClear: {
      marginLeft: '8px',
    },

    filterClearButton: {
      fontSize: '14px', // !important',
      fontWeight: 'normal', // !important',
    },

    filterTags: {
      marginLeft: '27px',
      display: 'flex',
    },
  })
);

export default useStyles;
