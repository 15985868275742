import { ActionInterface, ActionType } from '../actions';
import initialState, { BreadcrumbState, State } from '..';

import addBreadcrumb from './addBreadcrumb';
import clearBreadcrumbs from './clearBreadcrumbs';
import removeBreadcrumb from './removeBreadcrumb';
import setCurrentBreadcrumb from './setCurrentBreadcrumb';
import updateBreadcrumb from './updateBreadcrumb';

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const reducer = (state: State = initialState, action: any): BreadcrumbState => {
  switch (action.type) {
    case ActionType.AddBreadcrumb:
      return addBreadcrumb(state, action as ActionInterface.AddBreadcrumb);
    case ActionType.RemoveBreadcrumb:
      return removeBreadcrumb(
        state,
        action as ActionInterface.RemoveBreadcrumb
      );
    case ActionType.ClearBreadcrumbs:
      return clearBreadcrumbs(state);
    case ActionType.UpdateBreadcrumb:
      return updateBreadcrumb(
        state,
        action as ActionInterface.UpdateBreadcrumb
      );
    case ActionType.SetCurrentBreadcrumb:
      return setCurrentBreadcrumb(
        state,
        action as ActionInterface.SetCurrentBreadcrumb
      );
    default:
      return state;
  }
};

export default reducer;
