import React from 'react';
import useStyles from './useStyles';

interface ITextItemProps {
  title: string;
  childTestId?: string;
  refreshingState: boolean;
}

export const TextItem: React.FC<ITextItemProps> = ({
  title,
  childTestId,
  refreshingState,
  children,
}) => {
  const classes = useStyles();

  return (
    <div
      className={`${classes.itemWrapper} ${
        refreshingState ? classes.stationRefreshing : ''
      }`}
    >
      <span className={classes.itemTitle}>{title}</span>
      <span className={classes.itemValue} data-testid={childTestId}>
        {children}
      </span>
    </div>
  );
};
