import React, { useEffect, useRef, useState } from 'react';
import { Grid, IconButton } from '@mui/material';

import ActionDropdown from './ActionDropdown/ActionDropdown';
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import ExpandLessIcon from '@mui/icons-material/ExpandLess';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import { Transition } from 'react-transition-group';

import { IActionItem } from '../ActionDropdownContainer';
import useStyles from './useStyles';
import { Typography } from '../Typography/Typography';
import useSharedStyles from '../useSharedStyles';

type ITransitionStyles = 'entering' | 'entered' | 'exiting' | 'exited';

type TProps = {
  title: string;
  withArrow?: boolean;
  withActions?: boolean;
  actionItems?: IActionItem[];
  startCollapsed?: boolean;
  isCollapsable?: boolean;
};

export const CardElement: React.FC<TProps> = ({
  title,
  children,
  withArrow = false,
  withActions = false,
  actionItems,
  startCollapsed = false,
  isCollapsable = true,
}) => {
  const classes = useStyles();
  const sharedClasses = useSharedStyles();
  const [expand, setExpand] = useState<boolean>(!startCollapsed);
  const cardElementRef = useRef<HTMLDivElement>(null);
  const [panelHeight, setPanelHeight] = useState<number>(0);
  const [open, setOpen] = useState(false);
  const dropdownRef = useRef<HTMLButtonElement>(null);

  useEffect(() => {
    if (!isCollapsable) {
      return;
    }

    if (cardElementRef.current) {
      const scrollHeight = cardElementRef.current.scrollHeight;

      if (scrollHeight > 400) {
        setPanelHeight(cardElementRef.current.scrollHeight);
      } else {
        setPanelHeight(600);
      }
    }
  }, []);

  const handleToggle = (): void => {
    setOpen((prevOpen) => !prevOpen);
  };

  const defaultStyle = {
    transition: `max-height .3s ease-in-out`,
    height: 'auto',
    maxHeight: panelHeight ? `${panelHeight}px` : '1000px',
    overflow: 'hidden',
  };

  const getTransitionStyles = (state: string) => {
    if (!isCollapsable) {
      return {};
    }

    switch (state) {
      case 'entering':
        return { maxHeight: panelHeight ? `${panelHeight}px` : '1000px' };
      case 'entered':
        return { maxHeight: panelHeight ? `${panelHeight}px` : '1000px' };
      default:
        return { maxHeight: '0' };
    }
  };

  return (
    <div ref={cardElementRef} className={classes.cardElement}>
      <Grid className={classes.headerStyle} container>
        <Grid
          container
          alignItems='center'
          justifyContent='space-between'
          className={classes.titleWrapper}
        >
          <div className={classes.titleWithExpand}>
            {isCollapsable && (
              <ExpandLessIcon
                data-testid='expand-icon'
                className={`
                ${classes.expandIcon} 
                ${expand ? classes.expandIconOpen : classes.expandIconClosed}`}
                onClick={() => setExpand(!expand)}
              />
            )}

            <Typography
              className={sharedClasses.cardTitle}
              variant='h4'
              data-testid='page-heading-subscriptions'
            >
              {title}
            </Typography>
          </div>
          <div className={classes.actionWrapper}>
            {withActions && (
              <>
                <IconButton
                  color='primary'
                  className={classes.actionButton}
                  onClick={handleToggle}
                  ref={dropdownRef}
                  data-testid='action-dropdown-button'
                  size='large'
                >
                  <MoreVertIcon fontSize='small' />
                </IconButton>
                <ActionDropdown
                  open={open}
                  setOpen={setOpen}
                  dropdownRef={dropdownRef}
                  actionItems={actionItems || []}
                />
              </>
            )}
            {withArrow && (
              <IconButton
                color='primary'
                className={classes.actionButton}
                size='large'
              >
                <ArrowForwardIcon className={classes.nextIcon} />
              </IconButton>
            )}
          </div>
        </Grid>
      </Grid>
      <Transition in={expand} timeout={500}>
        {(state: ITransitionStyles) => (
          <Grid
            data-testid='child-wrapper'
            item
            xs={12}
            style={{
              ...defaultStyle,
              ...getTransitionStyles(state),
            }}
          >
            <div>{children}</div>
          </Grid>
        )}
      </Transition>
    </div>
  );
};

export default CardElement;
