import { useTheme } from '@mui/material';
import React from 'react';
import { IPublicChargingSession } from '../../../types/publicCharging';
import { formatIsoDates } from '../../../utils/dates';
import { useStyles } from './useStyles';

type TConfirmationInfoProps = {
  session: IPublicChargingSession;
};

export const ConfirmationInfo: React.FC<TConfirmationInfoProps> = ({
  session,
}) => {
  const theme = useTheme();
  const classes = useStyles(theme);
  return (
    <p>
      Station:{' '}
      <span className={classes.highlight}>{session.location_info.evse_id}</span>
      <br />
      Started at:{' '}
      <span className={classes.highlight}>
        {formatIsoDates(session.start_datetime)}
      </span>
    </p>
  );
};
