export interface ICountryInformation {
  currency: string;
}
// NOTE: Currencies based on https://www.iban.com/currency-codes [22.01.2020]
export const countryInformationMapper: Record<string, ICountryInformation> = {
  AT: { currency: 'EUR' },
  BE: { currency: 'EUR' },
  BG: { currency: 'BGN' },
  CH: { currency: 'CHF' },
  CY: { currency: 'EUR' },
  CZ: { currency: 'CZK' },
  DE: { currency: 'EUR' },
  DK: { currency: 'DKK' },
  EE: { currency: 'EUR' },
  ES: { currency: 'EUR' },
  FI: { currency: 'EUR' },
  FR: { currency: 'EUR' },
  GB: { currency: 'GBP' },
  GR: { currency: 'EUR' },
  HR: { currency: 'HRK' },
  HU: { currency: 'HUF' },
  IE: { currency: 'EUR' },
  IT: { currency: 'EUR' },
  LT: { currency: 'EUR' },
  LU: { currency: 'EUR' },
  LV: { currency: 'EUR' },
  MT: { currency: 'EUR' },
  NL: { currency: 'EUR' },
  NO: { currency: 'NOK' },
  PL: { currency: 'PLN' },
  PT: { currency: 'EUR' },
  RO: { currency: 'RON' },
  SE: { currency: 'SEK' },
  SI: { currency: 'EUR' },
  SK: { currency: 'EUR' },
};
