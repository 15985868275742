import HomeIcon from '@mui/icons-material/HomeOutlined';
import PublicIcon from '@mui/icons-material/Public';
import React from 'react';
import BusinessIcon from '@mui/icons-material/Business';

interface IGetTypeIconProps {
  type: string;
  fontSize?: number;
  color?: string;
  marginBottom?: number;
}

export const getTypeIcon = ({
  type,
  fontSize = 20,
  color = '#000',
  marginBottom = 0,
}: IGetTypeIconProps): JSX.Element => {
  const iconStyles = {
    marginRight: 4,
    fontSize,
    color,
    marginBottom,
    verticalAlign: 'middle',
  };

  switch (type) {
    case 'private':
      return <HomeIcon data-testid='HomeIcon' style={iconStyles} />;
    case 'business':
      return <BusinessIcon data-testid='BusinessIcon' style={iconStyles} />;
    default:
      return <PublicIcon data-testid='PublicIcon' style={iconStyles} />;
  }
};
