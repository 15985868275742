import { AppDispatch } from '../../../../store';
import { ActionCreator } from '../../../../store/state/actions';
import { ActionInterface } from '../../../../store/state/chargingSessions/actions';
import { Dispatcher } from '../index';

export interface IDispatchers {
  getChargingSessionsRequest: Dispatcher<
    ActionInterface.GetChargingSessionsRequest
  >;
  sortChargingSessions: Dispatcher<ActionInterface.SortChargingSessions>;
}

export const createDispatchers = (dispatch: AppDispatch): IDispatchers => ({
  getChargingSessionsRequest: (payload) =>
    dispatch(
      ActionCreator.chargingSessions.GetChargingSessionsRequest(payload)
    ),

  sortChargingSessions: (payload) =>
    dispatch(ActionCreator.chargingSessions.SortChargingSessions(payload)),
});
