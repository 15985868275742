import React, { useEffect, useState } from 'react';
import isEmpty from 'lodash/isEmpty';
import find from 'lodash/find';
import Grid from '@mui/material/Grid';
import { Headline } from '@elli-eco/component-library';
import { useTypedTranslation } from '../../../../../../../custom-hooks/useTypedTranslation';
import { IInvoiceRefundLineItem } from '../../../../../../../types/invoice';
import { ChargeRecordRefundTable } from '../table/ChargeRecordRefundTable';
import {
  EChargeRecordRefundDistributionType,
  TChargeRecordRefundDistribution,
  TChargeRecordRefundDistributionProps,
  TDistributionCalculationResult,
} from './ChargeRecordRefundDistribution.types';

const ChargeRecordRefundDistribution: React.FC<TChargeRecordRefundDistributionProps> = ({
  invoiceChargeRecords,
  refundEntries = [],
  currency,
}) => {
  const { t, terms } = useTypedTranslation();
  const [
    defaultPaymentOptionRefundAmount,
    setDefaultPaymentOptionRefundAmount,
  ] = useState<number>(0);
  const [chargingCreditRefundAmount, setChargingCreditRefundAmount] = useState<
    number
  >(0);

  const refundDistribution: TChargeRecordRefundDistribution = [
    {
      type: EChargeRecordRefundDistributionType.DEFAULT_PAYMENT_OPTION,
      amount: defaultPaymentOptionRefundAmount.toFixed(2),
    },
    {
      type: EChargeRecordRefundDistributionType.CHARGING_CREDIT,
      amount: chargingCreditRefundAmount.toFixed(2),
    },
  ];

  const calculateRefundDistribution = (
    refundEntries: IInvoiceRefundLineItem[]
  ) => {
    const distributionResult: TDistributionCalculationResult = {
      [EChargeRecordRefundDistributionType.CHARGING_CREDIT]: 0,
      [EChargeRecordRefundDistributionType.DEFAULT_PAYMENT_OPTION]: 0,
    };

    refundEntries.forEach((refundEntry) => {
      const chargeRecord = find(invoiceChargeRecords, {
        id: refundEntry.invoice_line_item_id,
      });

      if (!chargeRecord) {
        return;
      }

      if (
        refundEntry.gross_amount_to_refund <= Number(chargeRecord.gross_amount)
      ) {
        distributionResult.default_payment_option +=
          refundEntry.gross_amount_to_refund;
      } else {
        if (chargeRecord.gross_amount) {
          distributionResult.default_payment_option += Number(
            chargeRecord.gross_amount
          );
        }

        const chargingCreditRemainder =
          refundEntry.gross_amount_to_refund -
          Number(chargeRecord.gross_amount);

        distributionResult.charging_credit += chargingCreditRemainder;
      }
    });

    return distributionResult;
  };

  useEffect(() => {
    const result = calculateRefundDistribution(refundEntries);

    setChargingCreditRefundAmount(result.charging_credit);
    setDefaultPaymentOptionRefundAmount(result.default_payment_option);
  }, [refundEntries.length]);

  if (isEmpty(refundEntries)) {
    return null;
  }

  return (
    <Grid item container>
      <Grid item xs={12}>
        <Headline>{t(terms.refunds.form.distribution.headline)}</Headline>
        <ChargeRecordRefundTable
          currency={currency}
          columns={['Payment Type', 'Amount']}
          refundDistribution={refundDistribution}
        />
      </Grid>
    </Grid>
  );
};

export default ChargeRecordRefundDistribution;
