import { Theme } from '@mui/material';

import makeStyles from '@mui/styles/makeStyles';
import createStyles from '@mui/styles/createStyles';

const lightBorder = '1px solid #f5f6f8';

const useStyles = makeStyles((theme: Theme) => {
  const onColor = theme.colorAccent;
  const offColor = theme.textSecondary;
  return createStyles({
    indicator: {
      '&.activefirst': {
        transform: 'rotate(90deg)',
      },
      '&.activefirst, &.inactivefirst': {
        background: `linear-gradient(to right, ${onColor} 50%, ${offColor} 50%)`,
        borderColor: theme.textSecondary,
      },
      '&.inactivefirst': {
        transform: 'rotate(-90deg)',
      },
      '&:hover': {
        borderColor: theme.textSecondary,
      },
      background: theme.surfacePrimary,
      borderColor: theme.surfaceTertiary,
      borderRadius: '5px',
      borderStyle: 'solid',
      borderWidth: '1px',
      cursor: 'pointer',
      display: 'inline-block',
      height: '10px',
      transition: 'transform 0.2s cubic-bezier(0.4, 0, 0.2, 1) ',
      verticalAlign: 'middle',
      width: '10px',
    },
    statusCell: {
      width: '28px',
    },
    headerCell: {
      fontSize: '12px !important',
      color: '#878c96 !important',
      lineHeight: '1.2 !important',
    },
    tableCellRoot: {
      fontSize: 12,
      color: '#878c96',
      paddingLeft: 12,
      paddingRight: 0,
    },
    'MuiTableCell-root': {
      paddingLeft: 14,
      paddingRight: 14,
      borderBottom: lightBorder,
    },
    noBorder: {
      borderBottom: '0 !important',
    },
  });
});

export default useStyles;
