import { AxiosError } from 'axios';
import { all, call, put, takeEvery } from 'redux-saga/effects';
import { ActionCreator, ActionInterface, ActionType } from './actions';
import {
  ICardOrderModel,
  ICardOrderRequestModel,
} from '../../../types/subscriber';
import { SubscribersService } from '../../../services/subscribers';
import { RfidCardOrdersService } from '../../../services/rfidCardOrders';

export function* cardOrderSaga() {
  function* getCardOrder(action: ActionInterface.GetCardOrderRequest) {
    try {
      const cardOrders: ICardOrderRequestModel[] = yield call(
        SubscribersService.getRFIDCardOrders,
        {
          subscriberId: action.subscriberId,
        }
      );

      const cardOrdersWithOrderers: ICardOrderModel[] = yield call(
        RfidCardOrdersService.enrichOrdersWithOrderers,
        cardOrders
      );

      yield put<ActionInterface.GetCardOrderSuccess>(
        ActionCreator.GetCardOrderSuccess({
          data: cardOrdersWithOrderers,
        })
      );

      yield put<ActionInterface.SortCardOrders>(
        ActionCreator.SortCardOrders({
          sortState: {
            sortByColumnKey: 'order_date',
            sortDesc: true,
          },
        })
      );
    } catch (error) {
      yield put<ActionInterface.GetCardOrderError>(
        ActionCreator.GetCardOrderError({
          error: error as AxiosError,
        })
      );
    }
  }

  yield all([takeEvery(ActionType.GetCardOrderRequest, getCardOrder)]);
}

export default cardOrderSaga;
