import React, { useState } from 'react';
import SuccessMessage from '../../shared/Dialogs/SuccessMessage';
import ContactDetailsIcon from '../../../assets/image/contactDetails.svg';
import { IPublicChargingSession } from '../../../types/publicCharging';
import { StopSessionButton } from './StopSessionButton';
import { Grid, useTheme } from '@mui/material';
import { useStyles } from './useStyles';
import { DismissSessionButton } from './DismissSessionButton';
import DialogContainer from '../../shared/Dialogs/DialogContainer/DialogContainer';

export const SESSION_COMPLETION_TIMEOUT_S = 30;

type TOwnProps = {
  session: IPublicChargingSession;
  userId: string;
};

export type StopCommandStatus =
  | 'idle'
  | 'sending'
  | 'waiting'
  | 'error'
  | 'success';

export type DismissCommandStatus = StopCommandStatus | 'clicked';

export const isCommandInProgress = (status: DismissCommandStatus): boolean =>
  status === 'sending' || status === 'waiting';

export const StopAndDismissSessionButtons: React.FC<TOwnProps> = ({
  session,
  userId,
}) => {
  const theme = useTheme();
  const classes = useStyles(theme);
  const evseId = session.location_info.evse_id;

  const [stopCommandStatus, setStopCommandStatus] = useState<StopCommandStatus>(
    'idle'
  );
  const [dismissCommandStatus, setDismissCommandStatus] = useState<
    DismissCommandStatus
  >('idle');

  const stopSuccessDialog = () => (
    <DialogContainer
      isOpen={stopCommandStatus === 'success'}
      onClose={() => setStopCommandStatus('idle')}
    >
      <SuccessMessage
        headline='Success'
        subHeadline={'Session has been successfully stopped.'}
        icon={ContactDetailsIcon}
        iconAltText={'Session has been successfully stopped.'}
      />
    </DialogContainer>
  );

  const dismissSuccessDialog = () => (
    <DialogContainer
      isOpen={dismissCommandStatus === 'success'}
      onClose={() => setDismissCommandStatus('idle')}
    >
      <SuccessMessage
        headline='Success'
        subHeadline={'Session has been successfully dismissed.'}
        icon={ContactDetailsIcon}
        iconAltText={'Session has been successfully dismissed.'}
      />
    </DialogContainer>
  );

  const stopAndDismissButtons = () => (
    <Grid container className={classes.buttonGrid}>
      <Grid item>
        <StopSessionButton
          setStopCommandStatus={setStopCommandStatus}
          startDismissConfirmDialog={() => setDismissCommandStatus('clicked')}
          stopCommandStatus={stopCommandStatus}
          dismissCommandStatus={dismissCommandStatus}
          userId={userId}
          evseId={evseId}
          session={session}
        />
      </Grid>
      <Grid item>
        <DismissSessionButton
          setDismissCommandStatus={setDismissCommandStatus}
          stopCommandStatus={stopCommandStatus}
          dismissCommandStatus={dismissCommandStatus}
          userId={userId}
          evseId={evseId}
          session={session}
        />
      </Grid>
    </Grid>
  );

  return (
    <>
      {stopSuccessDialog()}
      {dismissSuccessDialog()}
      {stopAndDismissButtons()}
    </>
  );
};
