import makeStyles from '@mui/styles/makeStyles';
import createStyles from '@mui/styles/createStyles';

const useStyles = makeStyles(() =>
  createStyles({
    tariff: {
      paddingBottom: '16px',
      display: 'flex',
      flexDirection: 'row',
      alignItems: 'center',
      columnGap: '20px',
    },
    tariffString: {
      height: '30px',
      flex: 1,
      textOverflow: 'ellipsis',
      overflow: 'hidden',
    },
    tariffImage: {
      height: '30px',
      flex: 0.25,
    },
    headerString: {
      flex: 1,
      fontSize: 12,
      color: '#878c96',
      marginBottom: 10,
      lineHeight: 1.33,
      position: 'relative',
    },
    headerImage: {
      flex: 0.25,
      fontSize: 12,
      color: '#878c96',
      marginBottom: 10,
      lineHeight: 1.33,
      position: 'relative',
    },
  })
);

export default useStyles;
