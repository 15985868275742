import { IRevocationReason } from '../types/subscription';
import ApiService from './api';

export interface ITerminateSubscriptionResponse {
  iam_id: string;
  subscription_id: string;
  tariff_code: string;
  vehicle_identification_number?: string;
}

const getRevocationReasons = async (): Promise<IRevocationReason[]> => {
  const apiService = new ApiService(
    '/mgmt/customer/v1/subscriptions/revocationreasons'
  );
  const response = await apiService.query();
  return response.data;
};

const terminateSubscription = async (
  subscriptionId: string,
  terminationDate?: Date,
  revocationReason?: string
): Promise<ITerminateSubscriptionResponse> => {
  const apiService = new ApiService(
    `/mgmt/customer/v1/subscriptions/${subscriptionId}/terminate`,
    {
      method: 'POST',
      data: {
        termination_date: terminationDate,
        revocation_reason: revocationReason,
      },
    }
  );
  const response = await apiService.query();
  return response.data;
};

export const TerminateSubscriptionService = {
  getRevocationReasons,
  terminateSubscription,
};
