import { IFilter, IFilterOption } from '../../../shared/Filters/TableFilter';

import { IChargingRecordViewModel } from '../../../../types/chargingRecords';
import { IChargingSessionModel } from '../../../../types/chargingSession';
import { IHomeStation } from '../../../../types/user';
import { IRFIDCardModel } from '../../../../types/subscriber';

const getPrivateStationDisplay = (
  id: string,
  homeStations?: IHomeStation[]
): string => {
  if (!homeStations) {
    return id;
  }

  const currentStation = homeStations.find((station) => station.id === id);
  if (currentStation) {
    if (currentStation.name) {
      return currentStation.name;
    } else if (currentStation.station_serial_number) {
      return currentStation.station_serial_number;
    }
  }

  return id;
};

interface IOwnProps {
  defaultFilters?: string[];
  chargingCards?: IRFIDCardModel[];
  privateStationsIds?: string[];
  homeStations?: IHomeStation[];
}

export const getFilters = ({
  defaultFilters,
  chargingCards,
  privateStationsIds,
  homeStations,
}: IOwnProps): IFilter[] => {
  const filters: IFilter[] = [];

  filters.push({
    filterName: 'Session Type',
    options: [
      {
        name: 'Public',
        checked:
          Array.isArray(defaultFilters) && defaultFilters.includes('Public'),
        filterName: 'Session Type',
        filterFunc: (value: IChargingSessionModel): boolean =>
          value.type === 'public',
      },
      {
        name: 'Private',
        checked:
          Array.isArray(defaultFilters) && defaultFilters.includes('Private'),
        filterName: 'Session Type',
        filterFunc: (value: IChargingSessionModel): boolean =>
          value.type === 'private',
      },
      {
        name: 'Business',
        checked:
          Array.isArray(defaultFilters) && defaultFilters.includes('Business'),
        filterName: 'Session Type',
        filterFunc: (value: IChargingSessionModel): boolean =>
          value.type === 'business',
      },
    ],
  });

  if (chargingCards) {
    filters.push({
      filterName: 'Card Used',
      options: chargingCards.reduce((accum: IFilterOption[], current) => {
        const option = {
          name: current.number,
          checked:
            Array.isArray(defaultFilters) &&
            defaultFilters.includes(current.number),
          filterName: 'Card Used',
          filterFunc: (value: IChargingRecordViewModel): boolean => {
            if (value.card_id) {
              return current.id === value.card_id;
            }

            return false;
          },
        };
        accum.push(option);
        return accum;
      }, []),
    });
  }

  if (privateStationsIds && privateStationsIds.length) {
    filters.push({
      filterName: 'Home Chargers',
      options: privateStationsIds.reduce((accum: IFilterOption[], current) => {
        const option = {
          name: getPrivateStationDisplay(current, homeStations),
          checked:
            Array.isArray(defaultFilters) && defaultFilters.includes(current),
          filterName: 'Home Chargers',
          filterFunc: (value: IChargingRecordViewModel): boolean => {
            if (value.station_id) {
              return current === value.station_id;
            }

            return false;
          },
        };
        accum.push(option);
        return accum;
      }, []),
    });
  }

  return filters;
};
