/* eslint-disable @typescript-eslint/naming-convention */
import { ErrorObject } from 'serialize-error';
import { IUserModel, IUserSearchModel } from '../../../types/user';

export interface GetSelectedUserRequest {
  userId: string;
}
export interface GetUserSearchRequest {
  email: string;
}

export interface UserSearchStateModel {
  loading: boolean;
  data: IUserSearchModel[];
  error: ErrorObject | null;
  request: { query: string } | null;
  selectedUser: IUserModel | null;
}

export const initialState: UserSearchStateModel = {
  loading: false,
  data: [],
  error: null,
  request: null,
  selectedUser: null,
};

const state: UserSearchStateModel = {
  ...initialState,
};

export type State = typeof state;
export default state;
