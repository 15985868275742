import { Theme } from '@mui/material/styles';

import createStyles from '@mui/styles/createStyles';
import makeStyles from '@mui/styles/makeStyles';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    inputFieldWrapper: {
      position: 'relative',
      width: '100%',
    },
    inputField: {
      marginTop: 20,
      marginBottom: 20,
    },
    statusIcon: {
      position: 'absolute',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      lineHeight: 0,
      top: '50%',
      width: 24,
      height: 24,
      right: 16,
      transform: 'translateY(-50%)',
    },
    label: {
      background: '#ffffff',
      paddingRight: 5,
    },
    error: {
      position: 'absolute',
      bottom: -3,
      left: 0,
      right: 0,
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      color: theme.colorAlertPrimary,
      fontSize: 12,
      fontWeight: 600,
    },
    errorIcon: {
      fontSize: 16,
      marginRight: 6,
      color: theme.colorAlertPrimary,
    },
    inputRoot: {
      display: 'flex',
      justifyContent: 'space-between',
      '&:hover fieldset': {
        borderColor: 'rgba(0, 0, 0, 0.23) !important',
      },
    },
  })
);

export default useStyles;
