import makeStyles from '@mui/styles/makeStyles';
import createStyles from '@mui/styles/createStyles';

const useStyles = makeStyles(() =>
  createStyles({
    generalWrapper: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
    },
    cardFrontWrapper: {
      maxWidth: 247,
      margin: 12,
    },
    cardBackWrapper: {
      maxWidth: 247,
      margin: 12,
    },
  })
);

export default useStyles;
