import React from 'react';

import LiveDuration from '../LiveDuration';
import { PublicChargingSessionStatus } from '../../types/publicCharging';
import parseSeconds from '../../utils/parseSeconds';
import { Typography } from '../shared/Typography/Typography';

type TProps = {
  session_status: PublicChargingSessionStatus;
  start_datetime: string;
  end_datetime?: string;
  last_updated_cpo: string;
};

const formatDuration = (startTime: string, endTime: string) =>
  parseSeconds((Date.parse(endTime) - Date.parse(startTime)) / 1000);

export const ChargingSessionDuration: React.FC<TProps> = ({
  session_status,
  start_datetime,
  end_datetime,
  last_updated_cpo,
}) => {
  const showLiveDuration =
    session_status === 'ACTIVE' || session_status === 'PENDING';
  const showFinalDuration = end_datetime !== undefined;

  return (
    <Typography variant='body2' data-testid='data-session-duration'>
      {showLiveDuration ? (
        <LiveDuration start_time={new Date(start_datetime)}></LiveDuration>
      ) : showFinalDuration ? (
        formatDuration(start_datetime, end_datetime)
      ) : (
        'approx. ' + formatDuration(start_datetime, last_updated_cpo)
      )}
    </Typography>
  );
};
