import { ICsmsChargingStation, IHomeStation } from '../../../types/user';

import { ISortState } from '../../../components/shared/AssetTable/AssetTable/AssetTable';
/* eslint-disable @typescript-eslint/naming-convention */
import { actionCreator } from '../../type-utils';

export const ActionType = {
  GetHomeStationsRequest: 'home-stations/get-home-stations-request',
  GetHomeStationsSuccess: 'home-stations/get-home-stations-success',
  GetHomeStationsError: 'home-stations/get-home-stations-error',
  ClearHomeStations: 'home-stations/clear-home-stations',
  SortHomeStations: 'home-stations/sort-home-stations',
};

export declare namespace ActionInterface {
  export interface GetHomeStationsRequest {
    type: string;
    user_id: string;
  }

  export interface GetHomeStationsSuccess {
    type: string;
    data: ICsmsChargingStation | null;
    stations: IHomeStation[];
  }

  export interface GetHomeStationsError {
    type: string;
    error: Error;
  }

  export interface ClearHomeStations {
    type: string;
  }

  export interface SortHomeStations {
    type: string;
    sortState: ISortState;
  }
}

export const ActionCreator = {
  GetHomeStationsRequest: actionCreator<ActionInterface.GetHomeStationsRequest>(
    ActionType.GetHomeStationsRequest
  ),
  GetHomeStationsSuccess: actionCreator<ActionInterface.GetHomeStationsSuccess>(
    ActionType.GetHomeStationsSuccess
  ),
  GetHomeStationsError: actionCreator<ActionInterface.GetHomeStationsError>(
    ActionType.GetHomeStationsError
  ),
  ClearHomeStations: actionCreator<ActionInterface.ClearHomeStations>(
    ActionType.ClearHomeStations
  ),
  SortHomeStations: actionCreator<ActionInterface.SortHomeStations>(
    ActionType.SortHomeStations
  ),
};
