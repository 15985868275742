import createStyles from '@mui/styles/createStyles';
import makeStyles from '@mui/styles/makeStyles';

const useStyles = makeStyles(() =>
  createStyles({
    tableContainer: {
      maxHeight: '500px',
    },
  })
);

export default useStyles;
