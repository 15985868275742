import createStyles from '@mui/styles/createStyles';
import makeStyles from '@mui/styles/makeStyles';

const useStyles = makeStyles(() =>
  createStyles({
    successMessageWrapper: {
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',

      '& > img': {
        margin: 32,
        marginTop: 16,
      },
    },
  })
);

export default useStyles;
