import { Auth0Client } from '@auth0/auth0-spa-js';
import { config } from '../config';

const auth0Client = new Auth0Client({
  domain: config.auth0Domain,
  client_id: config.auth0ClientId,
  scope: config.auth0Scopes,
  audience: config.auth0Audience,
});

export const auth0 = {
  async getAccessTokens(): Promise<string> {
    return await auth0Client.getTokenSilently();
  },
  async getIdToken(): Promise<string | undefined> {
    const idTokenClaims = await auth0Client.getIdTokenClaims();

    return idTokenClaims?.__raw;
  },
};
