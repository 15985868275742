import React, { ReactNode } from 'react';
import {
  BodyHighlight,
  BodySmall,
  Button,
  Dialog,
  DialogBody,
  DialogFooter,
  DialogHeader,
} from '@elli-eco/component-library';
import { Grid } from '@mui/material';
import { useStyles } from './useStyles';

interface ISessionChangeErrorDialogProps {
  open: boolean;
  dialogHeader: string;
  errorMessage: string;
  additionalMessages: string[];
  closeCallback: () => void;
  yesButton?: ReactNode;
}

export const SessionChangeErrorDialog = ({
  open,
  dialogHeader,
  errorMessage,
  additionalMessages,
  closeCallback,
  yesButton,
}: ISessionChangeErrorDialogProps): JSX.Element => {
  const classes = useStyles();
  const messageElements = additionalMessages.map((message, idx) => (
    <BodySmall key={idx}>{message}</BodySmall>
  ));
  return (
    <Dialog size='M' open={open} onClose={closeCallback}>
      <DialogHeader>{dialogHeader}</DialogHeader>
      <DialogBody>
        <BodyHighlight>{errorMessage}</BodyHighlight>
        {messageElements}
      </DialogBody>
      <DialogFooter>
        <Grid container className={classes.dialogButtons}>
          <Grid item>
            <Button variant='outlined' onClick={closeCallback}>
              Back
            </Button>
          </Grid>
          {yesButton ? <Grid item>{yesButton}</Grid> : <></>}
        </Grid>
      </DialogFooter>
    </Dialog>
  );
};
