import React from 'react';

import useStyles from './useStyles';
import InformationItem, {
  IInformationItem,
  IOwnProps as IInformationItemProps,
} from './InformationItem';

type InformationItemWithProps = IInformationItem &
  Omit<IInformationItemProps, 'item'>;

interface IOwnProps {
  items: InformationItemWithProps[];
  title?: string;
}

const InformationItems: React.FC<IOwnProps> = ({ items, title }) => {
  const classes = useStyles();
  return (
    <>
      {title && <h2 className={classes.headline}>{title}</h2>}
      <div className={classes.outerItemWrapper} role='list'>
        {items.map((item) => (
          <InformationItem
            item={item}
            key={item.id}
            hasMarginRight={item.hasMarginRight}
            isLoading={item.isLoading}
          />
        ))}
      </div>
    </>
  );
};

export default InformationItems;
