import React, { useContext } from 'react';
import { useTypedTranslation } from '../../../../custom-hooks/useTypedTranslation';

import MultilineButton from '../../Buttons/MultilineButton';
import { DialogContextState } from '../context/ActionDialogContext';
import DialogHeadline from '../DialogHeadline';
import useStyles from './useStyles';

export interface IProps {
  headline: string;
  subHeadline?: string;
  icon: string;
  iconAltText: string;
}

const SuccessMessage: React.FC<IProps> = ({
  headline,
  subHeadline,
  icon,
  iconAltText,
}) => {
  const { handleCloseDialog } = useContext(DialogContextState);
  const { t, terms } = useTypedTranslation();
  const classes = useStyles();

  return (
    <div className={classes.successMessageWrapper}>
      <DialogHeadline headline={headline} subHeadline={subHeadline} />

      <img src={icon} alt={iconAltText} width='80' height='80' />

      <MultilineButton
        mainText={t(terms.general.close)}
        handleClick={handleCloseDialog}
      />
    </div>
  );
};

export default SuccessMessage;
