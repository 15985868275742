import { IconButton } from '@mui/material';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import React, { useState } from 'react';

import {
  IFirmwareConsentModel,
  IFirmwareInformationModel,
} from '../../../../types/user';
import {
  IFieldNames,
  mapFieldsForTable,
} from '../../../../utils/mapFieldsForTable';
import {
  AssetTable,
  IColumn,
  IRow,
} from '../../../shared/AssetTable/AssetTable/AssetTable';
import { FirmwareDialog } from './FirmwareDialog';
import { FirmwareStatus } from './FirmwareStatus';
import useStyles from './useStyles';
import { IBreadcrumbHandlerProps } from '../../../../utils/addBreadcrumbHandler';

import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import { ConsentStatus } from '../../FirmwareDetailCard/ConsentHistory/ConsentStatus/ConsentStatus';

interface IOwnProps {
  userId: string;
  stationId: string;
  firmwareInformation: IFirmwareInformationModel;
  addBreadcrumbHandler: (props: IBreadcrumbHandlerProps) => void;
}

interface IFirmwareStatusTableHeaderProps {
  setFirmwareDialogOpen: (value: boolean) => void;
}

const FirmwareStatusTableHeader: React.FC<IFirmwareStatusTableHeaderProps> = ({
  setFirmwareDialogOpen,
}) => {
  const classes = useStyles();
  return (
    <>
      <span>Firmware Status</span>
      <IconButton
        data-testid='firmware-info-button'
        onClick={() => setFirmwareDialogOpen(true)}
        className={classes.infoIcon}
        disableRipple
        disableFocusRipple
        size='large'
      >
        <InfoOutlinedIcon />
      </IconButton>
    </>
  );
};

export const FirmwareTable: React.FC<IOwnProps> = ({
  userId,
  stationId,
  firmwareInformation,
  addBreadcrumbHandler,
}) => {
  const [firmwareDialogOpen, setFirmwareDialogOpen] = useState<boolean>(false);

  const getAvailableVersion = (
    firmwareInfo?: IFirmwareInformationModel
  ): string => {
    if (firmwareInfo?.scheduled_firmware) {
      return firmwareInfo.scheduled_firmware.version;
    } else if (firmwareInfo?.recommended_firmware) {
      return firmwareInfo.recommended_firmware.version;
    }
    return '-';
  };

  const getConsent = (
    firmwareInfo?: IFirmwareInformationModel
  ): IFirmwareConsentModel | undefined => {
    if (firmwareInfo?.scheduled_firmware) {
      return firmwareInfo.scheduled_firmware.consent;
    } else if (firmwareInfo?.recommended_firmware) {
      return firmwareInfo.recommended_firmware.consent;
    } else if (firmwareInfo?.installed_firmware) {
      return firmwareInfo.installed_firmware.consent;
    }
    return undefined;
  };

  const getColumns = (): IColumn[] => {
    const fieldNames: IFieldNames = {
      current_version: {
        name: 'Current Version',
      },
      status: {
        name: (
          <FirmwareStatusTableHeader
            setFirmwareDialogOpen={setFirmwareDialogOpen}
          />
        ),
      },
      available_version: {
        name: 'Available Version',
      },
      consent_status: {
        name: 'Consent Status',
      },
      actions: {
        name: '',
        width: '36px',
      },
    };

    return mapFieldsForTable(fieldNames, []);
  };

  const getRows = (firmwareInfo?: IFirmwareInformationModel): IRow[] => {
    if (!firmwareInfo) {
      return [];
    }

    const availableVersion = getAvailableVersion(firmwareInfo);
    const consent = getConsent(firmwareInfo);

    return [
      {
        data: {
          current_version: {
            content: firmwareInfo.installed_firmware.version,
          },
          status: {
            content: <FirmwareStatus firmwareInformation={firmwareInfo} />,
          },
          available_version: { content: availableVersion },
          consent_status: {
            content: (
              <div data-testid='ConsentStatus'>
                {consent?.decision ? (
                  <ConsentStatus status={consent.decision} />
                ) : (
                  '-'
                )}
              </div>
            ),
          },
          actions: {
            content: (
              <IconButton
                color='primary'
                data-testid='firmware-details-icon'
                onClick={() =>
                  addBreadcrumbHandler({
                    component: 'FirmwareDetails',
                    friendlyText: 'Firmware Consent History',
                    config: {
                      user_id: userId,
                      station_id: stationId,
                    },
                  })
                }
                size='large'
              >
                <ChevronRightIcon fontSize='large' />
              </IconButton>
            ),
          },
        },
        id: '0',
      },
    ];
  };

  return (
    <div data-testid='firmware-table'>
      <AssetTable
        bulkActions={[]}
        columns={getColumns()}
        rows={getRows(firmwareInformation)}
        sortRequestHandler={() => true}
      />
      <FirmwareDialog
        open={firmwareDialogOpen}
        onClose={() => setFirmwareDialogOpen(false)}
      />
    </div>
  );
};

export default FirmwareTable;
