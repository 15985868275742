import makeStyles from '@mui/styles/makeStyles';
import createStyles from '@mui/styles/createStyles';

const useStyles = makeStyles(() =>
  createStyles({
    typeWrapper: {
      display: 'flex',
      alignItems: 'center',
    },
    connectorLogo: {
      minWidth: 24,
    },
    connectorTitle: {
      marginLeft: 5,
    },
  })
);

export default useStyles;
