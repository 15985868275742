import React from 'react';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import IconButton from '@mui/material/IconButton';
import {
  AssetTable,
  IColumn,
  IRow,
  ISortState,
} from '../../../shared/AssetTable/AssetTable/AssetTable';
import {
  getAuthorisationMode,
  getStationModel,
} from '../../../../utils/stationUtils';

import { ElliTooltip } from '../../../shared/ElliTooltip/ElliTooltip';
import { IBreadcrumbHandlerProps } from '../../../../utils/addBreadcrumbHandler';
import { IHomeStation } from '../../../../types/user';
import { Status } from '../Status';
import { formatIsoDates } from '../../../../utils/dates';
import { getStationTitle } from '../helpers';
import useSharedStyles from '../../../shared/useSharedStyles';
import {
  IFieldNames,
  mapFieldsForTable,
} from '../../../../utils/mapFieldsForTable';

interface IOwnProps {
  chargingStations: IHomeStation[];
  sortState: ISortState;
  sortRequestHandler: (columnKey: string) => void;
  addBreadcrumbHandler: (props: IBreadcrumbHandlerProps) => void;
}

export const ChargingStationsTable: React.FC<IOwnProps> = ({
  chargingStations,
  sortState,
  sortRequestHandler,
  addBreadcrumbHandler,
}) => {
  const fieldNames: IFieldNames = {
    connection_state: {
      name: 'Charging Status',
      isStatus: true,
      align: 'right',
    },
    name: {
      name: 'Station',
    },
    model: {
      name: 'Station model',
    },
    authorization_mode: {
      name: 'Access Mode',
    },
    last_contact: {
      name: 'Last contact',
    },
    actions: {
      name: '',
      width: '36px',
    },
  };

  const getColumns = (): IColumn[] => {
    const sortableColumns = [
      'connection_state',
      'name',
      'model',
      'authorization_mode',
      'last_contact',
    ];

    return mapFieldsForTable(fieldNames, sortableColumns);
  };

  const getRows = (): IRow[] => {
    const sharedClasses = useSharedStyles();
    if (!chargingStations || !chargingStations.length) {
      return [];
    }

    return Object.values(chargingStations).map((cs) => {
      return {
        data: {
          connection_state: {
            content: <Status status={cs.connection_state} />,
          },
          name: {
            content: getStationTitle(cs, {
              textOverflow: sharedClasses.textOverflow,
            }),
          },
          model: {
            content: cs.model ? (
              <ElliTooltip title={getStationModel(cs.model, cs.model_variant)}>
                <span
                  className={sharedClasses.textOverflow}
                  data-testid='charging-station-model'
                >
                  {getStationModel(cs.model, cs.model_variant)}
                </span>
              </ElliTooltip>
            ) : (
              '-'
            ),
          },
          authorization_mode: {
            content: cs.authorization_mode ? (
              <ElliTooltip title={getAuthorisationMode(cs.authorization_mode)}>
                <span
                  className={sharedClasses.textOverflow}
                  data-testid='charging-station-auth-mode'
                >
                  {getAuthorisationMode(cs.authorization_mode)}
                </span>
              </ElliTooltip>
            ) : (
              '-'
            ),
          },
          last_contact: {
            content: cs.last_contact ? (
              <ElliTooltip title={formatIsoDates(cs.last_contact)}>
                <span
                  className={sharedClasses.textOverflow}
                  data-testid='charging-station-last-contact'
                >
                  {formatIsoDates(cs.last_contact)}
                </span>
              </ElliTooltip>
            ) : (
              '-'
            ),
          },
          actions: {
            content: (
              <IconButton
                data-testid='charging-station-link'
                onClick={() =>
                  addBreadcrumbHandler({
                    component: 'ChargingStationDetails',
                    friendlyText: 'Charging Station',
                    id: cs.id,
                    config: {
                      station_id: cs.id,
                    },
                  })
                }
                color='primary'
                size='large'
              >
                <ChevronRightIcon fontSize='large' />
              </IconButton>
            ),
          },
        },
        id: cs.id,
      };
    });
  };

  return (
    <div data-testid='charging-stations-table'>
      <AssetTable
        bulkActions={[]}
        columns={getColumns()}
        rows={getRows()}
        sortingState={sortState}
        sortRequestHandler={sortRequestHandler}
        noDataTitle='No stations available'
        noDataSubTitle='Account has no access to any charging station.'
      />
    </div>
  );
};
