import React from 'react';
import {
  AssetTable,
  IColumn,
  IRow,
  ISortState,
} from '../../shared/AssetTable/AssetTable/AssetTable';

import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import { ElliTooltip } from '../../shared/ElliTooltip/ElliTooltip';
import { IBreadcrumbHandlerProps } from '../../../utils/addBreadcrumbHandler';
import { IInvoiceListItem } from '../../../types/invoice';
import IconButton from '@mui/material/IconButton';
import { formatDateNoTime } from '../../../utils/dates';
import useSharedStyles from '../../shared/useSharedStyles';
import {
  IFieldNames,
  mapFieldsForTable,
} from '../../../utils/mapFieldsForTable';
import { useTypedTranslation } from '../../../custom-hooks/useTypedTranslation';
import { PaymentStatusIndicator } from './PaymentStatusIndicator';
import { TransactionType } from '../../../types/transaction';

export const parseAmount = (amount: string, currency?: string): string => {
  if (currency) {
    return `${amount} ${currency}`;
  }
  return amount;
};

interface IOwnProps {
  invoices: IInvoiceListItem[];
  sortState: ISortState;
  sortRequestHandler: (columnKey: string) => void;
  addBreadcrumbHandler: (props: IBreadcrumbHandlerProps) => void;
}

export const InvoiceTable: React.FC<IOwnProps> = ({
  invoices,
  sortState,
  sortRequestHandler,
  addBreadcrumbHandler,
}) => {
  const { t, terms } = useTypedTranslation();

  const fieldNames: IFieldNames = {
    invoice_number: {
      name: t(terms.invoice.table.columns.invoice_id),
    },
    document_date: {
      name: t(terms.invoice.table.columns.document_date),
    },
    amount: {
      name: t(terms.invoice.table.columns.amount),
    },
    credit_notes_amount: {
      name: t(terms.invoice.table.columns.credit_notes),
    },
    payment_status: {
      name: t(terms.invoice.table.columns.payment_status),
    },
    actions: {
      name: '',
      width: '36px',
    },
  };

  const getColumns = (): IColumn[] => {
    const sortableColumns = [
      'invoice_number',
      'document_date',
      'amount',
      'credit_notes_amount',
    ];

    return mapFieldsForTable(fieldNames, sortableColumns);
  };

  const getRows = (): IRow[] => {
    const sharedClasses = useSharedStyles();
    if (!invoices || !invoices.length) {
      return [];
    }

    return Object.values(invoices).map((invoice) => {
      return {
        data: {
          invoice_number: {
            content: invoice.invoice_number ? (
              <ElliTooltip title={invoice.invoice_number}>
                <span
                  className={sharedClasses.textOverflow}
                  data-testid='data-invoice-number'
                >
                  {invoice.invoice_number}
                </span>
              </ElliTooltip>
            ) : (
              <span data-testid='data-invoice-number'>-</span>
            ),
          },
          document_date: {
            content: invoice.invoice_date ? (
              <ElliTooltip title={formatDateNoTime(invoice.invoice_date)}>
                <span
                  className={sharedClasses.textOverflow}
                  data-testid='data-document-date'
                >
                  {formatDateNoTime(invoice.invoice_date)}
                </span>
              </ElliTooltip>
            ) : (
              <span data-testid='data-document-date'>-</span>
            ),
          },
          amount: {
            content: invoice.gross_amount ? (
              <ElliTooltip
                title={parseAmount(invoice.gross_amount, invoice.currency)}
              >
                <span
                  className={sharedClasses.textOverflow}
                  data-testid='data-invoice-amount'
                >
                  {parseAmount(invoice.gross_amount, invoice.currency)}
                </span>
              </ElliTooltip>
            ) : (
              <span data-testid='data-invoice-amount'>-</span>
            ),
          },
          credit_notes_amount: {
            content: (
              <span data-testid='data-invoice-credit-notes-amount'>
                {invoice.credit_memos?.length || ''}
              </span>
            ),
          },
          payment_status: {
            content: (
              <PaymentStatusIndicator
                transactionType={TransactionType.Invoice}
                transactionNumber={invoice.invoice_number}
                transactionState={invoice.state}
              />
            ),
          },
          actions: {
            content: (
              <IconButton
                data-testid='invoice-link'
                onClick={() =>
                  addBreadcrumbHandler({
                    component: 'InvoiceDetails',
                    friendlyText: 'Invoice',
                    id: invoice.id,
                  })
                }
                color='primary'
                size='large'
              >
                <ChevronRightIcon fontSize='large' />
              </IconButton>
            ),
          },
        },
        id: invoice.id,
      };
    });
  };

  return (
    <div data-testid='invoice-table'>
      <AssetTable
        bulkActions={[]}
        columns={getColumns()}
        rows={getRows()}
        sortingState={sortState}
        sortRequestHandler={sortRequestHandler}
        noDataTitle='No invoices available'
        noDataSubTitle='Account does not yet have any invoices.'
      />
    </div>
  );
};

export default InvoiceTable;
