import { Theme } from '@mui/material';

import makeStyles from '@mui/styles/makeStyles';
import createStyles from '@mui/styles/createStyles';

const useStyles = makeStyles((theme: Theme) => {
  return createStyles({
    actionButton: {
      fill: theme.colorPrimary,
      marginLeft: '24px',
      'tr:hover &': {
        visibility: 'visible',
      },
      visibility: 'hidden',
    },
    actionButtonLabel: {
      height: '24px',
      width: '24px',
    },
  });
});

export default useStyles;
