import makeStyles from '@mui/styles/makeStyles';
import createStyles from '@mui/styles/createStyles';

const useStyles = makeStyles(() =>
  createStyles({
    logoContainer: {
      marginRight: '16px',
    },
  })
);

export default useStyles;
