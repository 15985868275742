import update from 'immutability-helper';
import { State, BreadcrumbState } from '..';

export default (state: State): State => {
  const updatedState: BreadcrumbState = update(state, {
    $unset: ['breadcrumbs'],
  });

  return {
    ...updatedState,
  };
};
