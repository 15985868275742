import { ActionInterface } from '../store/state/breadcrumbs/actions';
import { BreadcrumbStateModel } from '../store/state/breadcrumbs';

export interface IBreadcrumbHandlerProps {
  component: string;
  friendlyText: string;
  large?: boolean;
  withSidebar?: boolean;
  id?: string;
  defaultFilters?: string[];
  level?: number;
  config?: { [key: string]: string | number | boolean };
}

export type TAddBreadCrumbFunc = ({
  data,
}: Pick<ActionInterface.AddBreadcrumb, 'data'>) => void;

export interface IAddBreadcrumbHandlerProps {
  component: string;
  friendlyText: string;
  taskBreadcrumbState?: BreadcrumbStateModel;
  large?: boolean;
  withSidebar?: boolean;
  id?: string;
  defaultFilters?: string[];
  crumbId?: string;
  level?: number;
  addBreadcrumb: TAddBreadCrumbFunc;
  config?: { [key: string]: string | number | boolean };
}

export interface IBreadcrumbData {
  large?: boolean;
  withSidebar?: boolean;
  component: string;
  friendlyText: string;
  level: number;
  componentId?: string;
  defaultFilters?: string[];
}

export const addBreadcrumbHandler = ({
  component,
  friendlyText,
  large = false,
  withSidebar = false,
  id,
  defaultFilters,
  taskBreadcrumbState,
  crumbId,
  level,
  addBreadcrumb,
  config,
}: IAddBreadcrumbHandlerProps): void => {
  let breadcrumbLevel: number;

  if (level) {
    breadcrumbLevel = level;
  } else if (crumbId) {
    const currentIndex = (taskBreadcrumbState as BreadcrumbStateModel).findIndex(
      (bc) => bc.id === crumbId
    );

    breadcrumbLevel = currentIndex + 1;
  } else {
    breadcrumbLevel = 1;
  }

  const data: IBreadcrumbData = {
    large,
    withSidebar,
    component,
    friendlyText,
    level: breadcrumbLevel,
    componentId: id,
    ...config,
  };

  if (defaultFilters) {
    data.defaultFilters = defaultFilters;
  }

  addBreadcrumb({ data });
};
