import { useQuery, UseQueryResult } from 'react-query';
import { SubscribersService } from '../../services/subscribers';
import { ITariffModel } from '../../types/tariff';

export const useTariffsQuery = (): UseQueryResult<ITariffModel[]> => {
  return useQuery('tariffs', SubscribersService.getTariffs, {
    staleTime: Infinity,
    cacheTime: Infinity,
  });
};
