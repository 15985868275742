import update from 'immutability-helper';
import { State } from '..';
import { ActionInterface } from '../actions';
import { storeSubscriptionTerminationResult } from '../../../../components/DetailCards/Subscription/subscriptionTerminationResultsStorage';

export default (
  state: State,
  action: ActionInterface.TerminateSubscriptionSuccess
): State => {
  const currentSubscriptionState = state;

  if (action.status === 'upcoming') {
    const index = currentSubscriptionState.data?.findIndex(
      (sub) => sub.status === 'upcoming'
    );

    // this deletes the upcoming subscription
    const updatedState = update(currentSubscriptionState, {
      data: {
        $splice: [[index!, 1]],
      },
      loading: {
        $set: false,
      },
    });

    return {
      ...state,
      ...updatedState,
    };
  } else {
    storeSubscriptionTerminationResult(action.data);
    const updatedState = update(currentSubscriptionState, {
      data: {
        $set: currentSubscriptionState.data,
      },
      loading: {
        $set: false,
      },
      terminationResponse: {
        $set: action.data,
      },
    });

    return {
      ...state,
      ...updatedState,
    };
  }
};
