import React from 'react';
import useStyles from './useStyles';
import { Typography } from '../../../shared/Typography/Typography';

interface IPaymentCard {
  cardNumber: string;
  expiryMonth: number;
  expiryYear: number;
  name: string;
  iconUrl?: string;
}

export const PaymentCard: React.FC<IPaymentCard> = ({
  cardNumber,
  expiryMonth,
  expiryYear,
  name,
  iconUrl,
}) => {
  const cardNumberParts =
    cardNumber
      ?.replace(/\s/g, '')
      .replace(/\*/g, 'X')
      .match(/.{1,4}/g) || [];
  const classes = useStyles({
    iconUrl,
    cardNumberLength: cardNumberParts.length,
  });

  return (
    <div className={classes.cardContainer}>
      <div className={classes.cardLayout} data-testid='payment-card-background'>
        {iconUrl ? (
          <div
            className={classes.cardIconOverlay}
            data-testid='payment-card-icon'
          ></div>
        ) : null}
        <div className={classes.cardOverlay}>
          <div className={classes.cardOverlayTextContainer}>
            {cardNumberParts.map((partialNumber, index) => (
              <Typography
                data-testid='payment-card-number-fragment'
                variant='body2'
                className={classes.numberOnCard}
                key={index}
              >
                {partialNumber}
              </Typography>
            ))}
          </div>
          <div className={classes.cardOverlayTextContainer}>
            <Typography variant='body2' className={classes.textOnCard}>
              {name}
            </Typography>
            <Typography variant='body2' className={classes.textOnCard}>
              {expiryMonth}/{expiryYear}
            </Typography>
          </div>
        </div>
      </div>
    </div>
  );
};
