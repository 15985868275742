import { Theme } from '@mui/material';

import makeStyles from '@mui/styles/makeStyles';
import createStyles from '@mui/styles/createStyles';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    terminateSubscriptionButton: {
      color: theme.colorAlertPrimary,
      marginTop: '12px',
      display: 'flex',
      justifyContent: 'center',
    },
  })
);

export default useStyles;
