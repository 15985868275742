import update from 'immutability-helper';
import serializeError from 'serialize-error';
import { State } from '..';
import { ActionInterface } from '../actions';

export default (
  state: State,
  action: ActionInterface.TerminateSubscriptionError
): State => {
  const currentSubscription = state || {
    loading: true,
    data: null,
    error: null,
    request: null,
    terminationResponse: null,
  };

  const terminatedSubscription = update(currentSubscription, {
    error: {
      $set: serializeError(action.error),
    },
    loading: {
      $set: false,
    },
    data: {
      $set: currentSubscription.data,
    },
    terminationResponse: {
      $set: null,
    },
  });

  return {
    ...state,
    ...terminatedSubscription,
  };
};
