import { IConfig } from '../config';

export const getTestConfig = (): IConfig => ({
  useDevtools: true,
  elliBaseUrl: 'https://api.elli.eco/sandbox',
  aadRedirectUrl: 'http://localhost:8080/',
  aadClientAuthority:
    'https://login.microsoftonline.com/fbb6e5ca-b9eb-4bc5-b01e-50e49ed6ea42',
  aadClientId: '901b5182-e975-44af-b230-9ef4445d8a16',
  XApiKey: '',
  googleMapApiKey: '',
  env: '',
  launchDarklyClientId: '',
  auth0Audience: '',
  auth0ClientId: '',
  auth0Domain: '',
  auth0RedirectUri: '',
  auth0Scopes: '',
});
