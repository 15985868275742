import React from 'react';
import useStyles from './useStyles';

export const EligibleTariffHeader: React.FC = () => {
  const classes = useStyles();
  return (
    <div className={classes.tariff}>
      <div className={classes.headerImage}>Eligibility</div>
      <div className={classes.headerString}>Display name</div>
      <div className={classes.headerString}>Internal name</div>
      <div className={classes.headerImage}>Restriction</div>
    </div>
  );
};
