import makeStyles from '@mui/styles/makeStyles';
import createStyles from '@mui/styles/createStyles';
import { Theme } from '@mui/material';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    table: {
      background: theme.surfacePrimary,
      '& td': {
        borderBottom: 'none',
      },
      '& tbody>tr:last-child': {
        borderTop: '1px solid rgba(224, 224, 224, 1)',
      },
    },
    blockingFeeCells: {
      borderBottom: 0,
      padding: '1px 16px',
    },
    dialog: {
      padding: 32,
      minWidth: 346,
    },
  })
);

export default useStyles;
