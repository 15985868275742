import { AuthMethods } from '../types/chargingRecords';

export const getAuthenticationMethod = (authMethod: AuthMethods): string => {
  if (authMethod === 'none') {
    return 'None';
  }

  if (
    [
      'public_card',
      'private_card_paired',
      'private_card_owned',
      'free_card',
    ].includes(authMethod)
  ) {
    return 'RFID';
  }

  if (['public_remote', 'private_remote'].includes(authMethod)) {
    return 'Remote';
  }

  return '-';
};
