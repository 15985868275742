import React from 'react';
import { useTypedTranslation } from '../../../custom-hooks/useTypedTranslation';
import connect, { IPropsFromState } from '../../Connector/Connector';
import AssociatedSubscription from './AssociatedSubscription/AssociatedSubscription';
import AssociatedUser from './AssociatedUser/AssociatedUser';
import EligibleTariff from './EligibleTariff/EligibleTariff';
import useStyles from './useStyles';
import VinResultElement from './VinResultElement';
import { PlugAndChargeCapability } from './PlugAndChargeCapability/PlugAndChargeCapability';
import { EligibleTariffHeader } from './EligibleTariff/EligibleTariffHeader';
import { Typography } from '../../shared/Typography/Typography';

type TOwnProps = Pick<IPropsFromState, 'vehicleState'>;

export const VinResultCard: React.FC<TOwnProps> = ({ vehicleState }) => {
  const classes = useStyles();
  const { t, terms } = useTypedTranslation();

  const userListElements:
    | JSX.Element[]
    | undefined = vehicleState.data?.associated_users.map((user) => (
    <li key={user.iam_id}>
      <AssociatedUser user={user} />
    </li>
  ));

  const subscriptionListElements:
    | JSX.Element[]
    | undefined = vehicleState.data?.associated_subscriptions.map(
    (subscription) => (
      <li key={subscription.iam_id}>
        <AssociatedSubscription subscription={subscription} />
      </li>
    )
  );

  const tariffListElements:
    | JSX.Element[]
    | undefined = vehicleState.data?.eligible_tariffs.map((tariff) => (
    <li key={tariff.tariff_name}>
      <EligibleTariff eligibleTariff={tariff} />
    </li>
  ));

  tariffListElements?.unshift(
    <li>
      <EligibleTariffHeader />
    </li>
  );

  const plugAndChargeElements = [
    <li key='plugAndChargeCapability'>
      <PlugAndChargeCapability vin={vehicleState.data?.vin} />
    </li>,
  ];

  return (
    <div className={classes.box}>
      <Typography variant='h1' className={classes.headline}>
        VIN: {vehicleState.data && vehicleState.data.vin}
      </Typography>
      <VinResultElement
        title={t(terms.vin_eligibility.vin_overview.associated_users)}
        listElements={userListElements}
      />
      <VinResultElement
        title={t(terms.vin_eligibility.vin_overview.associated_subscriptions)}
        bodyText={t(terms.vin_eligibility.vin_overview.subscription_notice)}
        listElements={subscriptionListElements}
      />
      <VinResultElement
        title={t(terms.vin_eligibility.vin_overview.eligible_tariffs)}
        listElements={tariffListElements}
      />
      <VinResultElement
        title='Plug & Charge'
        listElements={plugAndChargeElements}
        isLastItem
      />
    </div>
  );
};

export default connect(VinResultCard);
