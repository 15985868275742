import React, { useEffect, useState } from 'react';

import { CircularProgress } from '@mui/material';
import { State as SubscriberState } from '../../store/state/subscriber';
import { State as UserSearchState } from '../../store/state/userSearch';

export interface ILogicProps {
  userSearch: UserSearchState;
  subscriberData: SubscriberState;
  setStep: (x: number) => void;
  onVerify: () => void;
}

export const LogicLayer: React.FC<ILogicProps> = ({
  userSearch,
  subscriberData,
  setStep,
  onVerify,
}) => {
  const [userInitials, setUserInitials] = useState<{
    first_name: string;
    last_name: string;
  } | null>(null);

  const [noInitials, setNoInitials] = useState<boolean>(false);

  const user = userSearch;
  const subscriber = subscriberData;
  const error = subscriber && subscriber.error;

  useEffect(() => {
    if (userInitials) {
      setStep(3);
    }

    if (noInitials) {
      onVerify();
    }
  }, [noInitials, userInitials]);

  useEffect(() => {
    if (subscriber && !subscriber.loading) {
      setUserInitials({
        first_name: '',
        last_name: '',
      });
    }
  }, [subscriber]);

  useEffect(() => {
    const selectedUser = user && user.selectedUser;
    if (selectedUser && selectedUser.first_name && selectedUser.last_name) {
      setUserInitials({
        first_name: selectedUser.first_name,
        last_name: selectedUser.last_name,
      });
    }
  }, [user]);

  useEffect(() => {
    if (error) {
      setNoInitials(true);
    }
  }, [error]);

  return (
    <div data-testid='logic-layer'>
      <CircularProgress data-testid='loading' />
    </div>
  );
};
