import { Theme } from '@mui/material';

import makeStyles from '@mui/styles/makeStyles';
import createStyles from '@mui/styles/createStyles';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    paginationRoot: {
      alignSelf: 'flex-end',
    },
    itemRoot: {
      borderRadius: 0,
      fontSize: 16,
      fontWeight: 600,
      height: 24,
      minWidth: 24,
      color: theme.colorPrimary,
    },
    itemPage: {
      '&$itemSelected': {
        backgroundColor: theme.colorPrimary,
        '&:hover': {
          backgroundColor: '#572ab1',
        },
      },
    },
    itemSelected: {
      color: '#fff',
    },
    itemEllipsis: {
      color: '#878c96',
    },
    itemIcon: {
      fontSize: 26,
    },
  })
);

export default useStyles;
