/* eslint-disable @typescript-eslint/naming-convention */
import { actionCreator } from '../../type-utils';

export const ActionType = {
  OpenVerificationFlow: 'ui/open-verification-flow',
  SetVerificationStep: 'ui/set-verification-step',
};

export declare namespace ActionInterface {
  export interface OpenVerificationFlow {
    type: string;
    open: boolean;
  }

  export interface SetVerificationStep {
    type: string;
    step: number;
  }
}

export const ActionCreator = {
  OpenVerificationFlow: actionCreator<ActionInterface.OpenVerificationFlow>(
    ActionType.OpenVerificationFlow
  ),
  SetVerificationStep: actionCreator<ActionInterface.SetVerificationStep>(
    ActionType.SetVerificationStep
  ),
};
