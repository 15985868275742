import React from 'react';
import CloseIcon from '@mui/icons-material/Close';
import PublicIcon from '@mui/icons-material/Public';
import { IFilterOption } from '../Filters/TableFilter';
import useStyles from './useStyles';

interface IOwnProps {
  tag: IFilterOption;
  icon?: React.Component;
  clearFilter: (option: IFilterOption) => void;
}

const FilterTag: React.FC<IOwnProps> = ({ tag, icon, clearFilter }) => {
  const classes = useStyles();
  return (
    <div className={classes.filterTag} data-testid='filter-tag'>
      {icon && <PublicIcon className={classes.leftIcon} />}
      <span className={classes.text}>{tag.name}</span>
      <CloseIcon
        data-testid='filter-close-icon'
        color='primary'
        className={classes.rightIcon}
        onClick={() => clearFilter(tag)}
      />
    </div>
  );
};

export default FilterTag;
