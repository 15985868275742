import React from 'react';
import connector, { IPropsFromState } from '../../Connector/Connector';
import { PaymentCardSection } from './CardSection/PaymentCardSection';
import { CouponSection } from './CouponSection/CouponSection';
import useStyles from './useStyles';
import { Typography } from '../../shared/Typography/Typography';
import useSharedStyles from '../../shared/useSharedStyles';

type TOwnProps = IPropsFromState;

const PaymentDetails: React.FC<TOwnProps> = ({
  paymentDetailsState,
  subscriberState,
}) => {
  const paymentDetails = paymentDetailsState.data;
  const classes = useStyles();
  const sharedClasses = useSharedStyles();

  return (
    <div className={sharedClasses.cardElement}>
      <div className={classes.titleWrapper}>
        <Typography
          variant='h1'
          classes={{
            root: classes.panelTitle,
          }}
          data-testid='payment-method-details-title'
        >
          Payment Methods
        </Typography>
      </div>
      <div className={classes.container}>
        {paymentDetails && paymentDetails.length > 0 ? (
          <PaymentCardSection paymentMethod={paymentDetails[0]} />
        ) : null}
        <CouponSection userId={subscriberState.data?.iam_id} />
      </div>
    </div>
  );
};

export default connector(PaymentDetails);
