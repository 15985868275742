import { useTheme } from '@mui/styles';
import useStyles from './useStyles';
import React from 'react';
import { Grid } from '@mui/material';
import {
  FlowColumnCircle,
  IFlowColumnCircleProps,
} from './Circle/FlowColumnCircle';
import { FlowColumnDestinationCircle } from './Circle/FlowColumnDestinationCircle';
import { Typography } from '../../../../shared/Typography/Typography';

type TOwnProps = {
  title: string;
  titleIcon: JSX.Element;
  circles: IFlowColumnCircleProps[];
  testId: string;
};

export const FlowColumnDiagram: React.FC<TOwnProps> = ({
  title,
  titleIcon,
  circles,
  testId,
}) => {
  const theme = useTheme();
  const classes = useStyles(theme);

  const circleItems = circles.map((circle) => (
    <Grid item xs={7} key={circle.testId}>
      <FlowColumnCircle
        circleState={circle.circleState}
        happyText={circle.happyText}
        sadText={circle.sadText}
        linePosition='bottom'
        testId={circle.testId}
      />
    </Grid>
  ));

  const destIsLoading = circles.some(
    (circle) => circle.circleState === 'loading'
  );

  const destIsHappy = circles.every((circle) => circle.circleState === 'happy');

  return (
    <div className={classes.columnContainer} data-testid={testId}>
      <Grid container justifyContent='center'>
        <Grid item className={classes.titleIcon}>
          {titleIcon}
        </Grid>
        <Grid item xs={12} justifyContent='center' marginBottom={3}>
          <Typography className={classes.title} textAlign='center'>
            Charging with
          </Typography>
          <Typography className={classes.title} gutterBottom textAlign='center'>
            {title}
          </Typography>
        </Grid>
        <Grid container item xs={12} justifyContent='center'>
          {circleItems}
        </Grid>
        <Grid item justifyContent='center'>
          <FlowColumnDestinationCircle
            icon={titleIcon}
            isHappyState={destIsHappy}
            isLoading={destIsLoading}
            testId={testId}
          />
        </Grid>
      </Grid>
    </div>
  );
};
