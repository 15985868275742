import React, { useEffect, useState } from 'react';

import { ChargingSessionsStateModel } from '../../../store/state/chargingSessions';
import { IBreadcrumbHandlerProps } from '../../../utils/addBreadcrumbHandler';
import { OrganizationStateModel } from '../../../store/state/organization';
import { IMember } from '../../../types/organization';
import {
  createActionButtons,
  IActionButtonConfig,
} from '../../shared/ActionBar/cardButtonFactory';

interface IOwnProps {
  chargingSessions: ChargingSessionsStateModel;
  organization: OrganizationStateModel;
  accountCollectionFlag: boolean;
  breadcrumbHandler: (props: IBreadcrumbHandlerProps) => void;
  onSetOrganisationMembers: (accountMembers: IMember[]) => void;
}

const ActionButtons: React.FC<IOwnProps> = ({
  organization,
  chargingSessions,
  accountCollectionFlag,
  breadcrumbHandler,
  onSetOrganisationMembers,
}) => {
  const [organizationStationsnumber, setOrganizationStationsnumber] = useState<
    number
  >(0);
  const [chargingSessionsnumber, setChargingSessionsnumber] = useState<number>(
    0
  );
  const [accountsnumber, setAccountsnumber] = useState<number>(0);

  useEffect(() => {
    if (organization) {
      if (organization.stations) {
        setOrganizationStationsnumber(organization.stations.total_count || 0);
      }
      if (organization.accounts && organization.accounts.members) {
        setAccountsnumber(organization.accounts.members.length);
        onSetOrganisationMembers(organization.accounts.members);
      }
    }

    if (chargingSessions && Array.isArray(chargingSessions.data)) {
      const orgSessions = chargingSessions.data.filter(
        (session) => session.type === 'business'
      );
      setChargingSessionsnumber(orgSessions.length);
    }
  }, [organization, chargingSessions]);

  const actionButtonsConfig: (IActionButtonConfig | undefined)[] = [
    {
      type: 'Stations',
      number: organizationStationsnumber,
      breadcrumbHandler,
    },
    {
      type: 'BusinessChargingSessions',
      number: chargingSessionsnumber,
      breadcrumbHandler,
    },
    accountCollectionFlag
      ? {
          type: 'Account',
          number: accountsnumber,
          breadcrumbHandler,
        }
      : undefined,
  ];

  return (
    <React.Fragment>{createActionButtons(actionButtonsConfig)}</React.Fragment>
  );
};

export default ActionButtons;
