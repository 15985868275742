import React from 'react';
import { IFirmwareInformationModel } from '../../../../types/user';
import useStyles from './useStyles';
import UpToDateLogo from '../../../../assets/image/upToDate.svg';
import OutdatedLogo from '../../../../assets/image/outdated.svg';
import InProgressLogo from '../../../../assets/image/inProgress.svg';
import AlertLogo from '../../../../assets/image/alert.svg';

const MAX_UPDATE_ATTEMPTS = 4;
interface IOwnProps {
  firmwareInformation: IFirmwareInformationModel;
}

export const FirmwareStatus: React.FC<IOwnProps> = ({
  firmwareInformation,
}) => {
  const classes = useStyles();

  const setInput = (
    statusText: string,
    statusIconPath: string
  ): { statusText: string; statusIconPath: string } => {
    return {
      statusText,
      statusIconPath,
    };
  };

  const mapFirmwareInformationToStatus = (): {
    statusText: string;
    statusIconPath: string;
  } => {
    if (firmwareInformation.scheduled_firmware) {
      if (
        firmwareInformation.scheduled_firmware.update_attempts >=
        MAX_UPDATE_ATTEMPTS
      ) {
        return setInput('Error', AlertLogo);
      } else {
        return setInput('In Progress', InProgressLogo);
      }
    } else if (firmwareInformation.recommended_firmware) {
      return setInput('Outdated', OutdatedLogo);
    } else {
      return setInput('Up to Date', UpToDateLogo);
    }
  };

  const status = mapFirmwareInformationToStatus();

  return (
    <div className={classes.statusWrapper}>
      <img
        className={classes.indicator}
        src={status.statusIconPath}
        alt='Status for firmware update process'
      />
      <span>{status.statusText}</span>
    </div>
  );
};
