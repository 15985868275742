import { Theme } from '@mui/material/styles';

import createStyles from '@mui/styles/createStyles';
import makeStyles from '@mui/styles/makeStyles';

export const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    message: {
      fontSize: 12,
      fontWeight: 600,
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
    },
    icon: {
      fontSize: 16,
      marginRight: 6,
    },
    error: {
      '&$message, & $icon': {
        color: theme.colorAlertPrimary,
      },
    },
    success: {
      '&$message, & $icon': {
        color: theme.colorPrimary,
      },
    },
  })
);
