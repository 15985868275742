import { Grid } from '@mui/material';
import Magnifier from '../../../../assets/image/magnifyingGlass.svg';
import useTheme from '@mui/styles/useTheme';
import React from 'react';
import { Typography } from '../../../shared/Typography/Typography';

interface IOwnProps {
  reason: string;
}

const PriceBreakdownUnavailable: React.FC<IOwnProps> = ({ reason }) => {
  const theme = useTheme();

  return (
    <Grid container alignItems={'center'} direction={'column'}>
      <img src={Magnifier} />
      <Typography variant='h1' sx={{ color: theme.textSecondary }}>
        Price Breakdown not available
      </Typography>
      <Typography
        variant='subtitle1'
        sx={{ color: theme.textSecondary }}
        maxWidth={400}
        textAlign={'center'}
      >
        {reason}
      </Typography>
    </Grid>
  );
};

export default PriceBreakdownUnavailable;
