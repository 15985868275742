import React, { useState } from 'react';
import { Grid, IconButton } from '@mui/material';
import HelpOutlineIcon from '@mui/icons-material/HelpOutline';

import useStyles from './useStyles';
import useSharedStyles from '../../../shared/useSharedStyles';
import decimalToComma from '../../../../utils/decimalToComma';
import { IChargingRecordViewModel } from '../../../../types/chargingRecords';
import { PriceDialog } from './PriceDialog/PriceDialog';
import { Typography } from '../../../shared/Typography/Typography';

interface IOwnProps {
  chargingSession: IChargingRecordViewModel;
}

const PaymentDetails: React.FC<IOwnProps> = ({ chargingSession }) => {
  const { price, coupon_reduction, currency } = chargingSession;
  const classes = useStyles();
  const sharedClasses = useSharedStyles();
  const [isPriceDialogOpen, setIsPriceDialogOpen] = useState(false);

  const sessionTotalPrice = price
    ? decimalToComma(Number(price).toFixed(2))
    : 0;

  const sessionAppliedCoupon = coupon_reduction
    ? decimalToComma(Number(coupon_reduction).toFixed(2))
    : 0;

  const calculatePricePaid = (): number | string => {
    if (!coupon_reduction) {
      return sessionTotalPrice;
    }
    if (!price || coupon_reduction > price) {
      return 0;
    }
    const priceReduction = price - coupon_reduction;

    return decimalToComma(Number(priceReduction).toFixed(2));
  };

  return (
    <>
      <Grid item xs={12}>
        <div className={sharedClasses.cardDetail}>
          <Typography variant='h4'>
            <span data-testid='payment-details-title'>Price and Payment</span>
          </Typography>
        </div>
      </Grid>
      <Grid item xs={4}>
        <div className={sharedClasses.cardDetail}>
          <Typography variant='h6'>
            Price (incl. VAT {chargingSession.vat_percentage * 100}%)
          </Typography>
          <div className={classes.addressElement}>
            <Typography variant='body2' className={classes.headingWithIcon}>
              {[sessionTotalPrice, currency].join(' ')}
              <IconButton
                data-testid='price-info-button'
                onClick={() => setIsPriceDialogOpen((prevState) => !prevState)}
                className={classes.infoIcon}
                disableRipple
                disableFocusRipple
                size='large'
              >
                <HelpOutlineIcon />
              </IconButton>
            </Typography>
          </div>
          <PriceDialog
            open={isPriceDialogOpen}
            onClose={() => setIsPriceDialogOpen(false)}
            chargingSession={chargingSession}
          />
        </div>
      </Grid>
      <Grid item xs={4}>
        <div className={sharedClasses.cardDetail}>
          <Typography variant='h6'>Paid via default payment method</Typography>
          <div className={sharedClasses.cardDetailData}>
            <Typography variant='body2'>
              {[calculatePricePaid(), currency].join(' ')}
            </Typography>
          </div>
        </div>
      </Grid>
      <Grid item xs={4}>
        <div className={sharedClasses.cardDetail}>
          <Typography variant='h6'>Paid via multi-purpose voucher</Typography>
          <div className={sharedClasses.cardDetailData}>
            <Typography variant='body2'>
              {[sessionAppliedCoupon, currency].join(' ')}
            </Typography>
          </div>
        </div>
      </Grid>
    </>
  );
};

export default PaymentDetails;
