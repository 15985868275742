import { config as appConfig } from '../config';
import ApiService from './api';

const getVinByIamId = async (
  userIamId: string
): Promise<string | undefined> => {
  const apiService = new ApiService(
    `/mgmt/car-details/v1/cars/?iam_id=${userIamId}`,
    {
      headers: {
        'X-apikey': appConfig.XApiKey,
      },
    }
  );
  const response = await apiService.query();
  // We return only the first car because it is unexpected that a normal user will have >1 car
  return response.status === 200 || response.status === 304
    ? response.data.vehicle_identification_number
    : undefined;
};

export const CarService = {
  getVinByIamId,
};
