import React from 'react';
import ceePlug from '../../../../assets/image/connectorTypes/ceePlug.svg';
import chademo from '../../../../assets/image/connectorTypes/chademo.svg';
import combo from '../../../../assets/image/connectorTypes/combo.svg';
import schuko from '../../../../assets/image/connectorTypes/schuko.svg';

import type1 from '../../../../assets/image/connectorTypes/type1.svg';
import type2 from '../../../../assets/image/connectorTypes/type2.svg';
import useStyles from './useStyles';
import { Typography } from '../../../shared/Typography/Typography';

interface IOwnProps {
  plugType: number | string;
  powerType: string;
}

interface IConnectorType {
  image: string;
  title: string;
}

export const LocationConnectorTypeMap: { [key: number]: IConnectorType } = {
  1000: { image: type1, title: 'Type 1' },
  1100: { image: type2, title: 'Type 2' },
  1200: { image: schuko, title: 'Schuko' },
  1400: { image: ceePlug, title: 'CEE Plug' },
  2000: { image: combo, title: 'Combo' },
  2100: { image: chademo, title: 'ChAdeMo' },
  2200: { image: combo, title: 'Combo' },
};

const LocationConnectorTypeByName: { [key: string]: IConnectorType } = {
  IEC_62196_T2: { image: type2, title: 'Type 2' },
  IEC_62196_T2_COMBO: { image: combo, title: 'Combo' },
  CHADEMO: { image: chademo, title: 'ChAdeMo' },
};

export const TypeIndicator: React.FC<IOwnProps> = ({ plugType, powerType }) => {
  const classes = useStyles();
  let typeIcon: IConnectorType = {
    image: '',
    title: '',
  };

  if (typeof plugType === 'string') {
    typeIcon = LocationConnectorTypeByName[plugType];
  } else if (typeof plugType === 'number') {
    typeIcon = LocationConnectorTypeMap[plugType];
  }

  return (
    <div className={classes.typeWrapper}>
      <img
        className={classes.connectorLogo}
        src={typeIcon.image}
        alt='charging station logo'
      />
      <Typography variant='body2' style={{ marginLeft: 5 }}>
        {typeIcon.title} ({powerType})
      </Typography>
    </div>
  );
};
