import React, { useEffect } from 'react';
import { useFormContext, useWatch } from 'react-hook-form';

import useFormStyles from '../../../../../../shared/form-elements/useStyles';
import { useTypedTranslation } from '../../../../../../../custom-hooks/useTypedTranslation';
import {
  EInvoiceReimbursementAmount,
  IInvoiceRefundLineItem,
  IInvoiceLineItem,
} from '../../../../../../../types/invoice';
import {
  ConnectForm,
  Input,
  Select,
} from '../../../../../../shared/form-elements';
import {
  REFUND_TYPE_SELECT_OPTIONS,
  REIMBURSEMENT_AMOUNT_SELECT_OPTIONS,
} from '../../../shared/options/InvoiceRefundOptions';

export type TAddChargeRecordRefundEntryProps = {
  chargeRecord?: IInvoiceLineItem;
};
const AddChargeRecordRefundEntry: React.FC<TAddChargeRecordRefundEntryProps> = ({
  chargeRecord,
}) => {
  const formClasses = useFormStyles();
  const { t, terms } = useTypedTranslation();
  const { setValue, control } = useFormContext<IInvoiceRefundLineItem>();
  const reimbursementAmountOption = useWatch({
    name: `reimbursement_amount`,
    control,
  });
  const isFullRefund =
    reimbursementAmountOption === EInvoiceReimbursementAmount.FULL;

  let amountLabel = t(terms.refunds.form.fields.amount);

  if (chargeRecord) {
    amountLabel += ` in ${chargeRecord.currency}`;
  }

  const prefillRefundEntry = (invoiceLineItem: IInvoiceLineItem) => {
    setValue('invoice_line_item_id', invoiceLineItem.id);
    setValue('process_id', invoiceLineItem.process_id);
  };

  const setFullRefundAmount = () => {
    if (chargeRecord) {
      setValue('gross_amount_to_refund', Number(chargeRecord.gross_amount), {
        shouldValidate: true,
      });
    }
  };

  useEffect(() => {
    if (chargeRecord) prefillRefundEntry(chargeRecord);
  }, [chargeRecord]);

  useEffect(() => {
    if (isFullRefund) {
      setFullRefundAmount();
    }
  }, [reimbursementAmountOption]);

  return (
    <ConnectForm disabled={!chargeRecord}>
      <Select
        name='type'
        className={formClasses.formField}
        options={REFUND_TYPE_SELECT_OPTIONS}
        disabled
      />
      <Input
        name='process_id'
        className={formClasses.formField}
        label='Process ID'
        disabled
      />
      <Select
        name='reimbursement_amount'
        className={formClasses.formField}
        label={t(terms.refunds.form.fields.reimbursement_amount)}
        options={REIMBURSEMENT_AMOUNT_SELECT_OPTIONS}
        isRequired
        autoFocus
      />
      <Input
        name='gross_amount_to_refund'
        type='number'
        label={amountLabel}
        disabled={!reimbursementAmountOption || isFullRefund}
        className={formClasses.formField}
        isRequired
      />
    </ConnectForm>
  );
};

export default AddChargeRecordRefundEntry;
