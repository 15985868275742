import React, { useEffect, useMemo, useState } from 'react';

import connector, { IPropsFromState } from '../../Connector/Connector';
import CardElement from '../../shared/CardElement/CardElement';
import { useTypedTranslation } from '../../../custom-hooks/useTypedTranslation';
import Details from './Details';
import useStyles from './useStyles';
import PdfViewer from '../../shared/PdfViewer';
import { RefundService } from '../../../services/refunds';

export type TOwnProps = IPropsFromState & {
  creditNoteId?: string;
};

const CreditNote: React.FC<TOwnProps> = ({
  creditNoteId,
  subscriberState,
  invoicesState,
}) => {
  const classes = useStyles();
  const { t, terms } = useTypedTranslation();
  const [creditNotePdf, setCreditNotePdf] = useState<Blob | null>(null);

  useEffect(() => {
    if (!creditNoteId) {
      setCreditNotePdf(null);
      return;
    }

    const getPdf = async (): Promise<void> => {
      try {
        const invoiceId = invoicesState.data?.find(({ credit_memos }) =>
          credit_memos?.find(({ id }) => id === creditNoteId)
        )?.id;
        const { data } = await RefundService.getPdfForCreditNote(
          subscriberState.data!.id,
          invoiceId!,
          creditNoteId
        );
        setCreditNotePdf(data);
      } catch (e) {
        setCreditNotePdf(null);
      }
    };

    getPdf();
  }, [creditNoteId]);

  const creditNote = useMemo(() => {
    const { data } = invoicesState;
    if (!data) {
      return undefined;
    }

    return data
      .flatMap(({ credit_memos }) => credit_memos || [])
      .find(({ id }) => id === creditNoteId);
  }, [creditNoteId, invoicesState]);

  return (
    <CardElement
      title={t(terms.refunds.credit_note.headline)}
      isCollapsable={false}
    >
      <div className={classes.contentWrapper}>
        <Details creditNote={creditNote} />
        {creditNotePdf && <PdfViewer file={creditNotePdf} />}
      </div>
    </CardElement>
  );
};

export default connector(CreditNote);
