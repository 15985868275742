import update from 'immutability-helper';
import { State } from '..';
import { ActionInterface } from '../actions';
import serializeError from 'serialize-error';

export default (
  state: State,
  action: ActionInterface.GetVehicleError
): State => {
  const { error } = action;
  const currentVehicle = state;

  const updatedVehicle = update(currentVehicle, {
    error: {
      $set: serializeError(error),
    },
    loading: {
      $set: false,
    },
  });

  return {
    ...state,
    ...updatedVehicle,
  };
};
