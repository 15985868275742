import React, { useEffect, useState } from 'react';
import useSharedStyles from '../../shared/useSharedStyles';
import { usePublicChargingSessionsQuery } from '../../../state/queries/usePublicChargingSessions';
import { formatIsoDates } from '../../../utils/dates';
import breadcrumbConnector, {
  IPropsFromState,
} from '../../Connector/Connector';
import { useChargingLocation } from '../../../state/queries/useChargingLocation';
import { IPublicChargingSession } from '../../../types/publicCharging';
import { IConnectorModel } from '../../../types/chargingSession';
import ChargingSessionIcon from '../../../assets/image/chargingSession.svg';
import { StopAndDismissSessionButtons } from './StopAndDismissSessionButtons';
import { SessionChargingDetails } from './SessionChargingDetails';
import { SessionLocationDetails } from './SessionLocationDetails';
import {
  addBreadcrumbHandler,
  IBreadcrumbHandlerProps,
} from '../../../utils/addBreadcrumbHandler';
import { Typography } from '../../shared/Typography/Typography';

export interface IOwnProps {
  sessionId?: string;
  userId?: string;
  crumbId?: string;
}

export const PublicChargingSessionDetails: React.FC<IPropsFromState &
  IOwnProps> = ({
  sessionId,
  userId,
  crumbId,
  addBreadcrumb,
  breadcrumbState,
}) => {
  const sharedClasses = useSharedStyles();
  const [session, setSession] = useState<IPublicChargingSession | undefined>();
  const [connector, setConnector] = useState<IConnectorModel | undefined>();
  const { data: chargingSessions } = usePublicChargingSessionsQuery(userId);
  const { data: location } = useChargingLocation({
    evseId: session?.location_info.evse_id,
  });

  useEffect(() => {
    setSession(chargingSessions?.find((s) => s.id === sessionId));
  }, [sessionId, chargingSessions]);

  useEffect(() => {
    if (location === undefined) {
      return;
    }
    const connectorById = location.charging_spots
      .flatMap((cp) => cp.connectors ?? [])
      .find((con) => con.connector_id === session?.location_info.connector_id);

    if (connectorById !== undefined) {
      setConnector(connectorById);
      return;
    }

    // Fall back to first connector matching the evseId if connector_id was undefined. As of 2023-05-19, this is the case for ~2% of all sessions.
    const fallbackConnectorCandidatesByEvse = location.charging_spots
      .filter((cp) => cp.evse_id === session?.location_info.evse_id)
      .flatMap((cp) => cp.connectors);

    if (fallbackConnectorCandidatesByEvse.length === 1) {
      setConnector(fallbackConnectorCandidatesByEvse[0]);
    } else {
      // If the charge point has more than one connector, we can't know for sure which one the user has connected to
      setConnector(undefined);
    }
  }, [location, session]);

  if (
    sessionId === undefined ||
    userId === undefined ||
    session === undefined
  ) {
    return <></>;
  }

  const breadcrumbHandler = (props: IBreadcrumbHandlerProps): void =>
    addBreadcrumbHandler({
      ...props,
      addBreadcrumb,
      crumbId,
      taskBreadcrumbState: breadcrumbState.breadcrumbs,
    });

  return (
    <div
      className={sharedClasses.cardElement}
      data-testid='charging-session-details-component'
    >
      <div className={sharedClasses.headingWithIcon}>
        <img
          src={ChargingSessionIcon}
          alt='Charging Session'
          className={sharedClasses.titleIcon}
        />
        <Typography
          variant='h1'
          classes={{
            root: sharedClasses.sectionTitle,
          }}
        >
          Charging Session - {formatIsoDates(session.start_datetime)}
        </Typography>
      </div>
      <SessionChargingDetails session={session}></SessionChargingDetails>
      <SessionLocationDetails
        session={session}
        location={location}
        connector={connector}
        onStationIdClicked={() =>
          breadcrumbHandler({
            component: 'ChargingStationDetails',
            friendlyText: 'Charging Station',
            id: session.location_info.evse_id,
            config: {
              station_id:
                location?.location_id || session.location_info.evse_id,
              location_evse_id: session.location_info.evse_id,
            },
          })
        }
      ></SessionLocationDetails>
      <StopAndDismissSessionButtons
        session={session}
        userId={userId}
      ></StopAndDismissSessionButtons>
    </div>
  );
};

export default breadcrumbConnector(PublicChargingSessionDetails);
