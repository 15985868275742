import React, { useState } from 'react';

import { AccessModeDialog } from '../../../../OverviewCards/HomeCharging/AccessModeDialog/AccessModeDialog';
import BusinessIcon from '@mui/icons-material/Business';
import { ConnectionStatus } from '../ConnectionStatus/ConnectionStatus';
import { ElliTooltip } from '../../../../shared/ElliTooltip/ElliTooltip';
import { IStationViewModel } from '../../../../../view-models/StationModel';
import IconButton from '@mui/material/IconButton';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import { LifecycleStateDialog } from '../Dialogs/LifecycleState';
import PermIdentityIcon from '@mui/icons-material/PermIdentity';
import { SerialNumberDialog } from '../Dialogs/SerialNumber';
import Tooltip from '../../../../ui/Tooltip';
import CopyTextFieldList from '../../../../ui/CopyTextField/CopyTextFieldList';
import { TextItem } from '../TextItem/TextItem';
import useStyles from './useStyles';
import { AutoUpdateDialog } from '../Dialogs/AutoUpdate';

interface IOwnProps {
  chargingStation: IStationViewModel;
  stationRefreshing: boolean;
}

export const General: React.FC<IOwnProps> = ({
  chargingStation,
  stationRefreshing,
}) => {
  const classes = useStyles();

  const [accessModeDialogOpen, setAccessModeDialogOpen] = useState<boolean>(
    false
  );
  const [lifecycleStateDialogOpen, setLifecycleStateDialogOpen] = useState<
    boolean
  >(false);
  const [autoUpdateDialogOpen, setAutoUpdateDialogOpen] = useState<boolean>(
    false
  );

  function getIconByUserType(): JSX.Element {
    if (chargingStation.station_owner_type === 'organization') {
      return (
        <BusinessIcon
          data-testid='BusinessIcon'
          style={{
            fontSize: 20,
            marginRight: 4,
            verticalAlign: 'middle',
          }}
        />
      );
    } else {
      return (
        <PermIdentityIcon
          data-testid='PermIdentityIcon'
          style={{
            fontSize: 20,
            marginRight: 4,
            verticalAlign: 'middle',
          }}
        />
      );
    }
  }

  const stationOwnerTooltipData = [
    {
      title: 'IAM Id',
      text: chargingStation.iam_id,
    },
  ];

  return (
    <div className={classes.outerItemWrapper}>
      <div
        className={`${classes.itemWrapper} ${
          stationRefreshing ? classes.stationRefreshing : ''
        }`}
      >
        <span className={classes.itemTitleWithIcon}>
          Station Owner
          <Tooltip tooltipName='station-owner'>
            <CopyTextFieldList textList={stationOwnerTooltipData} />
          </Tooltip>
        </span>
        <span className={classes.itemValue}>
          <span className={classes.ownerIconItem}>{getIconByUserType()}</span>
          <ElliTooltip title={chargingStation.station_owner}>
            <span
              className={classes.tooltipOverflow}
              data-testid='station-owner'
            >
              {chargingStation.station_owner}
            </span>
          </ElliTooltip>
        </span>
      </div>
      <TextItem
        title={'Station Name'}
        refreshingState={stationRefreshing}
        childTestId={'station-name'}
      >
        {chargingStation.name}
      </TextItem>
      <div className={classes.itemWrapper}>
        <span className={classes.itemTitleWithIcon}>
          Station Serial Number
          <Tooltip tooltipName='serial-number'>
            <SerialNumberDialog chargingStation={chargingStation} />
          </Tooltip>
        </span>
        <span className={classes.itemValue} data-testid='station-serial-number'>
          {chargingStation.station_serial_number}
        </span>
      </div>
      <TextItem
        title={'Station Model'}
        refreshingState={false}
        childTestId={'station-model'}
      >
        {chargingStation.model}
      </TextItem>
      <div
        className={`${classes.itemWrapper} ${
          stationRefreshing ? classes.stationRefreshing : ''
        }`}
      >
        <span className={classes.itemTitleWithIcon}>
          <span>Access Mode</span>
          <IconButton
            data-testid='access-mode-more-info-button'
            onClick={() => setAccessModeDialogOpen((prevState) => !prevState)}
            className={classes.infoIcon}
            disableRipple
            disableFocusRipple
            size='large'
          >
            <InfoOutlinedIcon />
          </IconButton>
          <AccessModeDialog
            open={accessModeDialogOpen}
            onClose={() => setAccessModeDialogOpen(false)}
          />
        </span>
        <span className={classes.itemValue} data-testid='station-access-mode'>
          {chargingStation.authorization_mode}
        </span>
      </div>
      <div
        className={`${classes.itemWrapper} ${
          stationRefreshing ? classes.stationRefreshing : ''
        }`}
      >
        <span className={classes.itemTitleWithIcon}>
          <span>Lifecycle Status</span>
          <IconButton
            data-testid='lifecycle-more-info-button'
            onClick={() =>
              setLifecycleStateDialogOpen((prevState) => !prevState)
            }
            className={classes.infoIcon}
            disableRipple
            disableFocusRipple
            size='large'
          >
            <InfoOutlinedIcon />
          </IconButton>
          <LifecycleStateDialog
            open={lifecycleStateDialogOpen}
            onClose={() => setLifecycleStateDialogOpen(false)}
          />
        </span>
        <span className={classes.itemValue}>
          <span
            className={classes.capitalize}
            data-testid='station-lifecycle-state'
          >
            {chargingStation.lifecycle_state}
          </span>
        </span>
      </div>
      <TextItem
        title={'Last Contact'}
        refreshingState={stationRefreshing}
        childTestId={'station-last-contact'}
      >
        {chargingStation.last_contact}
      </TextItem>
      <TextItem
        title={'Internet Connection'}
        refreshingState={stationRefreshing}
      >
        <ConnectionStatus status={chargingStation.connection_state} />
        <span
          className={classes.capitalize}
          data-testid='station-connection-state'
        >
          {chargingStation.connection_state}
        </span>
      </TextItem>
      {chargingStation.onboarding_date !== '-' && (
        <TextItem
          title={'Onboarding Date'}
          childTestId={'station-onboarding-date'}
          refreshingState={stationRefreshing}
        >
          {chargingStation.onboarding_date}
        </TextItem>
      )}
      <div className={classes.itemWrapper}>
        <span className={classes.itemTitleWithIcon}>
          Auto Update
          <IconButton
            data-testid='auto-update-more-info-button'
            onClick={() => setAutoUpdateDialogOpen((prevState) => !prevState)}
            className={classes.infoIcon}
            disableRipple
            disableFocusRipple
            size='large'
          >
            <InfoOutlinedIcon />
          </IconButton>
          <AutoUpdateDialog
            open={autoUpdateDialogOpen}
            onClose={() => setAutoUpdateDialogOpen(false)}
          />
        </span>
        <span className={classes.itemValue} data-testid='station-auto-update'>
          {chargingStation.auto_update}
        </span>
      </div>
    </div>
  );
};

export default General;
