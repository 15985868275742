import React from 'react';
import connector, { IPropsFromState } from '../../Connector/Connector';
import defaultRFIDCard from '../../../assets/image/defaultRFIDCard.jpg';
import useStyles from './useStyles';
import elliD0Back from '../../../assets/image/cardImages/elli-d0-back-400.png';
import elliD0Front from '../../../assets/image/cardImages/elli-d0-front-400.png';
import elliD2Back from '../../../assets/image/cardImages/elli-d2-back-400.png';
import elliD2Front from '../../../assets/image/cardImages/elli-d2-front-400.png';

import seatD2Back from '../../../assets/image/cardImages/seat-d2-back-400.png';
import seatD2Front from '../../../assets/image/cardImages/seat-d2-front-400.png';
import seatD3Back from '../../../assets/image/cardImages/seat-d3-back.png';
import seatD3Front from '../../../assets/image/cardImages/seat-d3-front.png';

import skodaD3Back from '../../../assets/image/cardImages/skoda-d3-back.png';
import skodaD3Front from '../../../assets/image/cardImages/skoda-d3-front.png';

import wechargeD1Back from '../../../assets/image/cardImages/wecharge-d1-back-400.png';
import wechargeD1Front from '../../../assets/image/cardImages/wecharge-d1-front-400.png';
import wechargeD101Back from '../../../assets/image/cardImages/skoda-d2-back-400.png';
import wechargeD101Front from '../../../assets/image/cardImages/wecharge-d101-front-400.png';
import wechargeD102Back from '../../../assets/image/cardImages/wecharge-d102-back-400.png';
import wechargeD102Front from '../../../assets/image/cardImages/wecharge-d102-front-400.png';

import audiD1Back from '../../../assets/image/cardImages/audi-d1-back.png';
import audiD1Front from '../../../assets/image/cardImages/audi-d1-front.png';

// When configuring card design templates check link below for the up-to-date designs.
// https://docs.google.com/spreadsheets/d/1h6KETIOv6vW_e3QfxnhAyMcPmWUyfgXFN1e6lVEcbEM/edit?usp=sharing
// Please note that some designs are particular to a type of user i.e. only home charging users
const cardImagesConfig = [
  {
    brandName: 'Elli Legacy',
    brandId: 0,
    designTemplates: [
      { templateId: 0, front: elliD0Front, back: elliD0Back },
      { templateId: 2, front: elliD2Front, back: elliD2Back },
    ],
  },
  {
    brandName: 'Elli',
    brandId: 1,
    designTemplates: [
      { templateId: 1, front: elliD0Front, back: elliD0Back },
      { templateId: 2, front: elliD2Front, back: elliD2Back },
    ],
  },
  {
    brandName: 'Skoda',
    brandId: 2,
    designTemplates: [
      { templateId: 1, front: skodaD3Front, back: skodaD3Back }, // deprecated
      { templateId: 2, front: skodaD3Front, back: skodaD3Back }, // deprecated
      { templateId: 3, front: skodaD3Front, back: skodaD3Back },
    ],
  },
  {
    brandName: 'Seat',
    brandId: 3,
    designTemplates: [
      { templateId: 1, front: seatD2Front, back: seatD2Back },
      { templateId: 2, front: seatD2Front, back: seatD2Back },
      { templateId: 3, front: seatD3Front, back: seatD3Back },
    ],
  },
  {
    brandName: 'WeCharge',
    brandId: 4,
    designTemplates: [
      { templateId: 1, front: wechargeD1Front, back: wechargeD1Back },
      { templateId: 101, front: wechargeD101Front, back: wechargeD101Back },
      { templateId: 102, front: wechargeD102Front, back: wechargeD102Back },
    ],
  },
  {
    brandName: 'Audi',
    brandId: 5,
    designTemplates: [{ templateId: 1, front: audiD1Front, back: audiD1Back }],
  },
];

export type TProps = IPropsFromState & {
  brandId: number;
  templateId: number;
  back?: boolean;
};

export const CardImage: React.FC<TProps> = ({ brandId, templateId, back }) => {
  const classes = useStyles();

  const findDesignTemplate = (
    brandId: number,
    templateId: number
  ): { src: string; testId: string } => {
    const brandCard = cardImagesConfig.find(
      (brandConfig) => brandConfig.brandId === brandId
    );
    if (brandCard) {
      const brandTemplate = brandCard.designTemplates.find(
        (dt) => dt.templateId === templateId
      );
      if (brandTemplate) {
        return back
          ? {
              src: brandTemplate.back,
              testId: `card-image-bid${brandId}-tid${templateId}-back`,
            }
          : {
              src: brandTemplate.front,
              testId: `card-image-bid${brandId}-tid${templateId}-front`,
            };
      }
    }
    return {
      src: defaultRFIDCard,
      testId: `card-image-default`,
    };
  };

  const cardDesign = findDesignTemplate(brandId, templateId);

  return (
    <img
      data-testid={cardDesign.testId}
      className={classes.cardImage}
      src={cardDesign.src}
      alt='rfid card'
    />
  );
};

export default connector(CardImage);
