import React, { useState } from 'react';

export function usePagination<T>(
  itemCollection: T[]
): {
  currentItems: T[];
  itemsPerPage: number;
  setItemsPerPage: React.Dispatch<React.SetStateAction<number>>;
  currentPage: number;
  setCurrentPage: React.Dispatch<React.SetStateAction<number>>;
  pagesLength: number;
} {
  const [itemsPerPage, setItemsPerPage] = useState<number>(10);
  const [currentPage, setCurrentPage] = useState<number>(1);

  const getCurrentItems = () => {
    const start = (currentPage - 1) * itemsPerPage;
    const end = start + itemsPerPage;
    return itemCollection.slice(start, end);
  };
  const currentItems = getCurrentItems();

  const pagesLength =
    itemCollection.length === 0
      ? 1
      : Math.ceil(itemCollection.length / itemsPerPage);

  return {
    currentItems,
    itemsPerPage,
    setItemsPerPage,
    currentPage,
    setCurrentPage,
    pagesLength,
  };
}
