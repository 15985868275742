import { HomeStationsStateModel, State } from '..';
import update from 'immutability-helper';

export default (state: State): State => {
  const updatedState: HomeStationsStateModel = update(state, {
    $unset: ['stations', 'data', 'error', 'loading', 'request'],
  });

  return {
    ...updatedState,
  };
};
