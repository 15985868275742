/* eslint-disable @typescript-eslint/naming-convention */
import { ErrorObject } from 'serialize-error';
import { IActiveSession } from '../../../types/activeSessions';

export interface ActiveSessionsStateModel {
  loading: boolean;
  data: IActiveSession[] | null; // TODO: see if null works here
  error: ErrorObject | null;
  request: { userId: string } | null;
}

export const initialState: ActiveSessionsStateModel = {
  loading: false,
  data: [],
  error: null,
  request: null,
};

const state: ActiveSessionsStateModel = {
  ...initialState,
};

export type State = typeof state;
export default state;
