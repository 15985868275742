import activeSessions from './activeSessions/reducers';
import breadcrumbs from './breadcrumbs/reducers';
import cardorders from './cardorders/reducers';
import chargingCards from './chargingCards/reducers';
import chargingSessions from './chargingSessions/reducers';
import chargingStations from './chargingStations/reducers';
import { combineReducers } from 'redux';
import homeStations from './homeStations/reducers';
import invoices from './invoices/reducers';
import organization from './organization/reducers';
import subscriber from './subscriber/reducers';
import subscription from './subscription/reducers';
import ui from './ui/reducers';
import user from './user/reducers';
import userSearch from './userSearch/reducers';
import vehicle from './vehicle/reducers';
import paymentDetails from './paymentDetails/reducers';

export default combineReducers({
  breadcrumbs,
  user,
  subscriber,
  subscription,
  cardorders,
  userSearch,
  ui,
  chargingCards,
  chargingSessions,
  chargingStations,
  organization,
  homeStations,
  activeSessions,
  invoices,
  vehicle,
  paymentDetails,
});
