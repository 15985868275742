/* eslint-disable @typescript-eslint/naming-convention */
import { IPaymentMethod } from '../../../types/paymentMethods';

import { actionCreator } from '../../type-utils';

export const ActionType = {
  GetPaymentDetailsRequest: 'charging-card/get-payment-details-request',
  GetPaymentDetailsSuccess: 'charging-card/get-payment-details-success',
  GetPaymentDetailsError: 'charging-card/get-payment-details-error',
};

export declare namespace ActionInterface {
  export interface GetPaymentDetailsRequest {
    type: string;
    userId: string;
  }

  export interface GetPaymentDetailsSuccess {
    type: string;
    data: IPaymentMethod[];
  }

  export interface GetPaymentDetailsError {
    type: string;
    error: Error;
  }
}

export const ActionCreator = {
  GetPaymentDetailsRequest: actionCreator<
    ActionInterface.GetPaymentDetailsRequest
  >(ActionType.GetPaymentDetailsRequest),
  GetPaymentDetailsSuccess: actionCreator<
    ActionInterface.GetPaymentDetailsSuccess
  >(ActionType.GetPaymentDetailsSuccess),
  GetPaymentDetailsError: actionCreator<ActionInterface.GetPaymentDetailsError>(
    ActionType.GetPaymentDetailsError
  ),
};
