import { ActionInterface } from '../actions';
import { ISortState } from '../../../../components/shared/AssetTable/AssetTable/AssetTable';
import { State } from '..';
import { lexicographical } from '../../../../utils/comparisonFunctions';
import update from 'immutability-helper';
import { IInvoiceListItem } from '../../../../types/invoice';

interface ISortFunctionProps {
  a: IInvoiceListItem;
  b: IInvoiceListItem;
}

interface ISortFunctions {
  [string: string]: (props: ISortFunctionProps) => number;
}

export const sortFunctions: ISortFunctions = {
  invoice_number: ({ a, b }) => {
    return lexicographical(a.invoice_number, b.invoice_number);
  },
  document_date: ({ a, b }) => {
    const invoiceDateA = new Date(a.invoice_date).getTime();
    const invoiceDateB = new Date(b.invoice_date).getTime();
    return lexicographical(invoiceDateA, invoiceDateB);
  },
  amount: ({ a, b }) => {
    return lexicographical(a.gross_amount, b.gross_amount);
  },
  credit_notes_amount: ({ a, b }) => {
    return lexicographical(a.credit_memos, b.credit_memos);
  },
};

export const generateSortFunction = (sortState: ISortState) => (
  a: IInvoiceListItem,
  b: IInvoiceListItem
) => {
  const sortKey = sortState.sortByColumnKey as AdminOrderSortKey;
  if (sortKey) {
    const sortFunction = sortFunctions[sortKey];
    const ascendingSortResult = sortFunction({ a, b });

    return sortState.sortDesc ? ascendingSortResult * -1 : ascendingSortResult;
  } else {
    return 0;
  }
};

type AdminOrderSortKey = keyof typeof sortFunctions;

export default (state: State, action: ActionInterface.SortInvoices): State => {
  const { sortState } = action;
  const currentInvoicesState = state;

  if (currentInvoicesState && currentInvoicesState.data) {
    const newInvoicesData: IInvoiceListItem[] = currentInvoicesState.data.slice();
    newInvoicesData.sort(generateSortFunction(sortState));

    const updatedInvoicesState = update(currentInvoicesState, {
      data: {
        $set: newInvoicesData,
      },
      loading: {
        $set: false,
      },
    });

    return {
      ...state,
      ...updatedInvoicesState,
    };
  }

  return {
    ...state,
  };
};
