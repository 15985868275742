import React from 'react';
import ReceiptIcon from '@mui/icons-material/Receipt';
import useStyles from './useStyles';

interface IInvoiceTitle {
  invoiceNumber: string;
}
export const InvoiceDetailsTitle: React.FC<IInvoiceTitle> = ({
  invoiceNumber,
}) => {
  const classes = useStyles();
  return (
    <div className={classes.cardHeaderWrapper}>
      <ReceiptIcon className={classes.headerLogo} />
      <h1 className={classes.headerTitle}>Invoice {invoiceNumber}</h1>
    </div>
  );
};
