import { Theme } from '@mui/material';

import makeStyles from '@mui/styles/makeStyles';
import createStyles from '@mui/styles/createStyles';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    flex: {
      display: 'flex',
      flexDirection: 'column',
    },
    checkCircle: {
      color: theme.colorPrimary,
      width: '27px',
      height: '27px',
    },
    checkCircleOutline: {
      color: '#cad3e0',
      width: '27px !important',
      height: '27px !important',
    },

    root: {
      width: '473px',
      maxWidth: 473,
      display: 'flex !important',
      justifyContent: ' space-between !important',
      alignItems: 'center !important',
      padding: '20px 25px !important',
      borderRadius: '4px !important',
      marginTop: '15px',
      '@media (max-width: 1440px) and (min-width: 1280px)': {
        marginTop: '10px',
      },
      border: '1px solid #cad3e0',
      '&:hover': {
        border: '1px solid #6633cc',
        cursor: 'pointer',
        '& .checkCircleOutline': {
          color: '#6633cc',
        },
      },
    },

    selected: {
      display: 'flex !important',
      justifyContent: ' space-between !important',
      alignItems: 'center !important',
      padding: '20px 25px !important',
      borderRadius: '4px !important',
      marginTop: '15px',
      '@media (max-width: 1440px) and (min-width: 1280px)': {
        marginTop: '10px',
      },
      border: '1px solid #6633cc',
      backgroundColor: '#f6f2ff',
      boxShadow: '0 2px 8px 0 rgba(0, 0, 0, 0.08)',
      '&:hover': {
        cursor: 'pointer',
      },
    },
  })
);

export default useStyles;
