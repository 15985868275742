import sortCreditNotes from './sortCreditNotes';
import { ISortState } from '../../shared/AssetTable/AssetTable/AssetTable';
import { ICreditNote } from '../../../types/creditNote';

export interface ISortedCreditNotesData {
  sortState: ISortState;
  sortedCreditNotes: ICreditNote[];
}

export enum ACTION_TYPES {
  SET_CREDIT_NOTES = 'SET_CREDIT_NOTES',
  SET_SORTING = 'SET_SORTING',
}

export interface IAction {
  type: ACTION_TYPES;
  payload: string | ICreditNote[];
}

const reducer = (
  state: ISortedCreditNotesData,
  action: IAction
): ISortedCreditNotesData => {
  const { type, payload } = action;

  if (type === ACTION_TYPES.SET_CREDIT_NOTES) {
    return {
      ...state,
      sortedCreditNotes: sortCreditNotes(
        payload as ICreditNote[],
        state.sortState
      ),
    };
  } else if (type === ACTION_TYPES.SET_SORTING) {
    const newSortState: ISortState = {
      ...state.sortState,
      sortByColumnKey: payload as string,
      sortDesc:
        (payload as string) === state.sortState.sortByColumnKey
          ? !state.sortState.sortDesc
          : true,
    };

    return {
      ...state,
      sortState: newSortState,
      sortedCreditNotes: sortCreditNotes(
        [...state.sortedCreditNotes],
        newSortState
      ),
    };
  } else {
    return state;
  }
};

export default reducer;
