import { AppDispatch } from '../../../store';
import { createDispatchers as createActiveSessionsDispatchers } from './activeSessions';
import { createDispatchers as createBreadcrumbDispatchers } from './breadcrumbs';
import { createDispatchers as createCardOrdersDispatchers } from './cardOrders';
import { createDispatchers as createChargingCardsDispatchers } from './chargingCards';
import { createDispatchers as createChargingSessionsDispatchers } from './chargingSessions';
import { createDispatchers as createChargingStationsDispatchers } from './chargingStations';
import { createDispatchers as createHomeStationsDispatchers } from './homeStations';
import { createDispatchers as createInvoicesDispatchers } from './invoices';
import { createDispatchers as createOrganizationDispatchers } from './organization';
import { createDispatchers as createOthersDispatchers } from './other';
import { createDispatchers as createSubscriberDispatchers } from './subscriber';
import { createDispatchers as createSubscriptionDispatchers } from './subscription';
import { createDispatchers as createUiDispatchers } from './ui';
import { createDispatchers as createUserDispatchers } from './user';
import { createDispatchers as createUserSearchDispatchers } from './userSearch';
import { createDispatchers as createVehicleDispatchers } from './vehicle';

export const getDispatchers = (dispatch: AppDispatch): StoreDispatchers => ({
  ...createActiveSessionsDispatchers(dispatch),
  ...createBreadcrumbDispatchers(dispatch),
  ...createCardOrdersDispatchers(dispatch),
  ...createChargingCardsDispatchers(dispatch),
  ...createChargingSessionsDispatchers(dispatch),
  ...createChargingStationsDispatchers(dispatch),
  ...createHomeStationsDispatchers(dispatch),
  ...createInvoicesDispatchers(dispatch),
  ...createOrganizationDispatchers(dispatch),
  ...createOthersDispatchers(dispatch),
  ...createSubscriberDispatchers(dispatch),
  ...createSubscriptionDispatchers(dispatch),
  ...createUiDispatchers(dispatch),
  ...createUserDispatchers(dispatch),
  ...createUserSearchDispatchers(dispatch),
  ...createVehicleDispatchers(dispatch),
});

export type Dispatcher<T> = (payload?: Omit<T, 'type'>) => T;

type StoreDispatchers = ReturnType<typeof createActiveSessionsDispatchers> &
  ReturnType<typeof createBreadcrumbDispatchers> &
  ReturnType<typeof createCardOrdersDispatchers> &
  ReturnType<typeof createChargingCardsDispatchers> &
  ReturnType<typeof createChargingSessionsDispatchers> &
  ReturnType<typeof createChargingStationsDispatchers> &
  ReturnType<typeof createHomeStationsDispatchers> &
  ReturnType<typeof createInvoicesDispatchers> &
  ReturnType<typeof createOrganizationDispatchers> &
  ReturnType<typeof createOthersDispatchers> &
  ReturnType<typeof createSubscriberDispatchers> &
  ReturnType<typeof createSubscriptionDispatchers> &
  ReturnType<typeof createUiDispatchers> &
  ReturnType<typeof createUserDispatchers> &
  ReturnType<typeof createUserSearchDispatchers> &
  ReturnType<typeof createVehicleDispatchers>;
