import { AppDispatch } from '../../../../store';
import { ActionCreator } from '../../../../store/state/actions';
import { ActionInterface } from '../../../../store/state/userSearch/actions';
import { Dispatcher } from '../index';

interface IDispatchers {
  clearSelectedUser: Dispatcher<ActionInterface.ClearSelectedUser>;
  getUserSearchRequest: Dispatcher<ActionInterface.GetUserSearchRequest>;
  setSelectedUser: Dispatcher<ActionInterface.SetSelectedUser>;
}

export const createDispatchers = (dispatch: AppDispatch): IDispatchers => ({
  clearSelectedUser: (payload) =>
    dispatch(ActionCreator.userSearch.ClearSelectedUser(payload)),

  getUserSearchRequest: (payload) =>
    dispatch(ActionCreator.userSearch.GetUserSearchRequest(payload)),

  setSelectedUser: (payload) =>
    dispatch(ActionCreator.userSearch.SetSelectedUser(payload)),
});
