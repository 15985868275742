/* eslint-disable @typescript-eslint/naming-convention */
export interface UIStateModel {
  isVerificationOpen: boolean;
  verificationStep: number;
}

export const initialState: UIStateModel = {
  isVerificationOpen: false,
  verificationStep: 1,
};

const state: UIStateModel = {
  ...initialState,
};

export type State = typeof state;
export default state;
