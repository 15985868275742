/* eslint-disable @typescript-eslint/naming-convention */
import { ILocationModel } from '../../../types/chargingSession';
import { actionCreator } from '../../type-utils';
import { ILocationParams } from '../../../services/locations';

export const ActionType = {
  GetChargingStationRequest: 'charging-station/get-charging-station-request',
  GetChargingStationSuccess: 'charging-station/get-charging-station-success',
  GetChargingStationError: 'charging-station/get-charging-station-error',
  GetNearbyLocationsRequest: 'charging-station/get-nearby-locations-request',
  GetNearbyLocationsSuccess: 'charging-station/get-nearby-locations-success',
};

export declare namespace ActionInterface {
  export interface GetChargingStationRequest {
    type: string;
    evse_id: string;
  }

  export interface GetChargingStationSuccess {
    type: string;
    data: ILocationModel;
    evse_id: string;
  }

  export interface GetChargingStationError {
    type: string;
    error: Error;
  }

  export interface GetNearbyLocationsRequest {
    type: string;
    evse_id: string;
    location: ILocationParams;
  }

  export interface GetNearbyLocationsSuccess {
    type: string;
    nearbyLocations: ILocationModel[];

    evse_id: string;
  }
}

export const ActionCreator = {
  GetChargingStationRequest: actionCreator<
    ActionInterface.GetChargingStationRequest
  >(ActionType.GetChargingStationRequest),
  GetChargingStationSuccess: actionCreator<
    ActionInterface.GetChargingStationSuccess
  >(ActionType.GetChargingStationSuccess),
  GetChargingStationError: actionCreator<
    ActionInterface.GetChargingStationError
  >(ActionType.GetChargingStationError),
  GetNearbyLocationsRequest: actionCreator<
    ActionInterface.GetNearbyLocationsRequest
  >(ActionType.GetNearbyLocationsRequest),
  GetNearbyLocationsSuccess: actionCreator<
    ActionInterface.GetNearbyLocationsSuccess
  >(ActionType.GetNearbyLocationsSuccess),
};
