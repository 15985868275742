import React from 'react';
import { useTypedTranslation } from '../../../custom-hooks/useTypedTranslation';
import ContactDetailsIcon from '../../../assets/image/contactDetails.svg';
import ExclaimIcon from '../../../assets/image/exclaim.svg';
import useStyles from '../../shared/Dialogs/SuccessMessage/useStyles';
import DialogHeadline from '../../shared/Dialogs/DialogHeadline';
import { Typography } from '../../shared/Typography/Typography';

export interface IProps {
  hasError: boolean;
  terminationDate: Date | null;
}

export const TerminateActiveSubscriptionDialog: React.FC<IProps> = ({
  hasError,
  terminationDate,
}) => {
  const { t, terms } = useTypedTranslation();

  const classes = useStyles();

  const subheadline = terminationDate
    ? t(
        terms.subscription_information
          .terminate_active_subscription_future_date_success,
        {
          terminationDate,
          weekday: 'long',
          year: 'numeric',
          month: 'numeric',
          day: 'numeric',
        }
      )
    : t(
        terms.subscription_information.terminate_active_subscription_success
          .sub_headline
      );

  return (
    <div className={classes.successMessageWrapper}>
      {hasError ? (
        <>
          <Typography
            variant='h1'
            align='center'
            gutterBottom
            data-testid='terminate-active-subscription-dialog-error'
          >
            {t(
              terms.subscription_information.terminate_active_subscription_error
                .headline
            )}
          </Typography>
          <img
            src={ExclaimIcon}
            alt={t(
              terms.subscription_information.terminate_active_subscription_error
                .headline
            )}
          />
          <Typography variant='subtitle1' align='center' gutterBottom>
            {t(
              terms.subscription_information.terminate_active_subscription_error
                .sub_headline
            )}
          </Typography>
        </>
      ) : (
        <>
          <DialogHeadline
            headline={t(
              terms.subscription_information
                .terminate_active_subscription_success.headline
            )}
            subHeadline={subheadline}
            data-testid='terminate-active-subscription-dialog-success'
          />
          <img
            src={ContactDetailsIcon}
            alt={t(
              terms.subscription_information
                .terminate_active_subscription_success.headline
            )}
          />
        </>
      )}
    </div>
  );
};
