import React from 'react';
import { Grid } from '@mui/material';

import useSharedStyles from '../../../shared/useSharedStyles';
import { Typography } from '../../../shared/Typography/Typography';
import BreakdownTable from './BreakdownTable';
import { ICDRMgmtResponse } from '../../../../types/chargingSession';
import PriceBreakdownUnavailable from './PriceBreakdownUnavailable';
import { ChargingCondition } from '../../../../types/chargingCondition';

interface IOwnProps {
  chargeRecord?: ICDRMgmtResponse;
  chargingCondition: ChargingCondition;
}

const getPriceBreakDownUnavailabilityReason = (
  chargeRecord?: ICDRMgmtResponse
) => {
  if (!chargeRecord || (chargeRecord && !chargeRecord.price_breakdown)) {
    return `The price is defined by the CPO and does not follow our pricing structure.
    Price breakdown must be not available for fixed price CDR older than March 2024.
    `;
  }
  if (chargeRecord && chargeRecord.pricing_model === 'markup_pricing') {
    return 'This tariff is based on markup/variable pricing.';
  }
  return null;
};

const PriceBreakdown: React.FC<IOwnProps> = ({
  chargeRecord,
  chargingCondition,
}) => {
  const sharedClasses = useSharedStyles();

  const priceBreakDownUnavailablityReason = getPriceBreakDownUnavailabilityReason(
    chargeRecord
  );

  return (
    <>
      <Grid item xs={12} sx={{ marginTop: '8px' }}>
        <div className={sharedClasses.cardDetail}>
          <Typography variant='h4'>Price Breakdown</Typography>
        </div>
      </Grid>
      {priceBreakDownUnavailablityReason && (
        <PriceBreakdownUnavailable reason={priceBreakDownUnavailablityReason} />
      )}
      {chargeRecord && !priceBreakDownUnavailablityReason && (
        <BreakdownTable
          chargeRecord={chargeRecord}
          chargingCondition={chargingCondition}
        />
      )}
    </>
  );
};

export default PriceBreakdown;
