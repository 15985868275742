import { Theme } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';

export const useStyles = makeStyles((theme: Theme) => ({
  buttonGrid: {
    gap: 120,
    justifyContent: 'center',
    padding: 24,
  },
  dialogButtons: {
    gap: 120,
    justifyContent: 'center',
    paddingTop: 32,
  },
  dismissButton: {
    backgroundColor: theme.colorAlertPrimary,
    color: '#FFFFFF',
    '&:hover': {
      backgroundColor: theme.colorAlertPrimaryDark,
    },
    '&:focus': {
      backgroundColor: theme.colorAlertPrimaryDark,
    },
    width: 170,
  },
  stopButton: {
    width: 170,
  },
  circularProgress: {
    paddingTop: 12,
  },
  highlight: {
    fontWeight: theme.typography.bodyHighlight.fontWeight,
  },
}));
