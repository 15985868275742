import { ICreditNote } from './creditNote';
import { TransactionState } from './transaction';

export interface IInvoiceListItem {
  id: string;
  invoice_number: string;
  gross_amount: string;
  currency: string;
  invoice_date: string;
  state: TransactionState;
  country: string;
  credit_memos: ICreditNote[];
}

export interface IInvoice extends IInvoiceListItem {
  line_items: IInvoiceLineItem[];
}

export const ItemType = {
  BaseFee: 'BASE_FEE',
  ChargeRecord: 'CHARGE_RECORD',
  Voucher: 'VOUCHER',
  SignUpFee: 'SIGN_UP_FEE',
} as const;
export type ItemType = typeof ItemType[keyof typeof ItemType];

export interface IInvoiceLineItem {
  id: string;
  item_type: ItemType;
  start_date: string;
  end_date: string;
  process_id: string;
  gross_amount: string;
  currency: string;
  quantity: string;
}

export interface IInvoiceRefund {
  line_items: IInvoiceRefundLineItem[];
}

export interface IInvoiceRefundLineItem {
  invoice_line_item_id: string;
  process_id: string;
  type: EInvoiceRefundType;
  gross_amount_to_refund: number;
  reimbursement_amount?: EInvoiceReimbursementAmount | null;
}

export interface IInvoiceRefundRequest {
  line_items: IInvoiceRefundLineItemRequest[];
}

export interface IInvoiceRefundLineItemRequest {
  invoice_line_item_id: string;
  gross_amount_to_refund: string;
}

export enum EInvoiceRefundType {
  CHARGING_SESSION = 'CHARGING_SESSION',
  SUBSCRIPTION_FEE = 'MONTHLY_FEE',
}

export enum EInvoiceReimbursementAmount {
  FULL = 'FULL',
  PARTIAL = 'PARTIAL',
}
