import makeStyles from '@mui/styles/makeStyles';

const useStyles = makeStyles({
  wrapper: {
    display: 'flex',
    width: '100%',
    position: 'relative',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'flex-start',
    paddingBottom: 10,
  },
  title: {
    fontSize: 20,
    fontWeight: 600,
    lineHeight: '1.2em',
  },
  icon: {
    marginRight: 16,
  },
});

export default useStyles;
