import React from 'react';
import { Label } from '@elli-eco/component-library';

import { useTypedTranslation } from '../../../custom-hooks/useTypedTranslation';
import useSharedStyles from '../useSharedStyles';
import { ISubscriptionModel } from '../../../types/subscriber';
import { SubscriptionStatusInfo } from './SubscriptionStatusInfo';
import Tooltip from '../../ui/Tooltip';

interface IProps {
  subscription?: ISubscriptionModel;
}

export const SubscriptionStatusLabel: React.FC<IProps> = ({ subscription }) => {
  const { t, terms } = useTypedTranslation();
  const classes = useSharedStyles();

  return (
    <div className={classes.labelWrapper}>
      <Label>{t(terms.subscription.labels.status)}</Label>
      {subscription?.inactivation_reason && (
        <Tooltip tooltipName='status-info'>
          <SubscriptionStatusInfo
            inactivationReason={subscription.inactivation_reason}
            revocationReason={subscription.revocation_reason}
          />
        </Tooltip>
      )}
    </div>
  );
};
