const getPropByString = (obj: Record<string, any>, string?: string): any => {
  if (!string) {
    return obj;
  }

  const prop = string.split('.').reduce((value, key) => value?.[key], obj);

  return prop;
};

export default getPropByString;
