import React from 'react';

import CopyTextFieldList from '../../ui/CopyTextField/CopyTextFieldList';
import { IOwnProps as ICopyTextField } from '../../ui/CopyTextField/index';
import { useTypedTranslation } from '../../../custom-hooks/useTypedTranslation';
import { useGetRevocationReasons } from '../../../state/queries/useGetRevocationReasons';

interface IProps {
  inactivationReason: string;
  revocationReason?: string;
}

export const SubscriptionStatusInfo: React.FC<IProps> = ({
  inactivationReason,
  revocationReason,
}) => {
  const { t, terms } = useTypedTranslation();
  const { data: revocationReasons } = useGetRevocationReasons();

  const getRevocationReasonTerm = (slug: string) => {
    const revocationReasonItem = revocationReasons?.find(
      (r) => r.revocation_reason === slug
    );
    return revocationReasonItem?.description;
  };

  return (
    <CopyTextFieldList
      textList={[
        {
          title: t(terms.subscription.labels.inactivation_reason),
          text: inactivationReason,
          textTransform: 'capitalize',
          action: 'none',
        },
        ...(revocationReason
          ? ([
              {
                title: t(terms.subscription.labels.revocation_reason),
                text: getRevocationReasonTerm(revocationReason),
                action: 'none',
              },
            ] as ICopyTextField[])
          : []),
      ]}
    />
  );
};
