import React, { useEffect, useRef, useState } from 'react';
import { FormHelperText, Grid } from '@mui/material';
import useStyles from '../useStyles';
import { useTypedTranslation } from '../../../../custom-hooks/useTypedTranslation';
import { Dispatcher } from '../../../Connector/dispatchers';
import ConnectForm from '../../../shared/form-elements/connect-form/ConnectForm';
import { Form, Input } from '../../../shared/form-elements';
import { ActionInterface } from '../../../../store/state/user/actions';
import { IUserModel } from '../../../../types/user';
import { getDefaultValues, IChangeUserProfile, useTextFields } from './fields';
import MultilineButton from '../../../shared/Buttons/MultilineButton';
import DialogHeadline from '../../../shared/Dialogs/DialogHeadline';
import { changeUserProfileSchema } from './ChangeUserProfile.schema';

type SubmitState = 'submitted' | 'failed' | 'open';

export interface IOwnProps {
  userFirstName: IUserModel['first_name'];
  userLastName: IUserModel['last_name'];
  userDisplayName: IUserModel['display_name'];
  userId: IUserModel['id'];
  submitError: boolean;
  updateUserProfile: Dispatcher<ActionInterface.UpdateUser>;
  onSuccess: () => void;
}

const ChangeUserProfileForm: React.FC<IOwnProps> = ({
  userFirstName,
  userLastName,
  userDisplayName,
  userId,
  submitError,
  onSuccess,
  updateUserProfile,
}) => {
  const classes = useStyles();
  const { t, terms } = useTypedTranslation();
  const [submitState, setSubmitState] = useState<SubmitState>('open');

  const userFirstNameRef = useRef(userFirstName);
  const userLastNameRef = useRef(userLastName);
  const userDisplayNameRef = useRef(userDisplayName);

  const textFields = useTextFields();

  useEffect(() => {
    if (submitError) {
      submitError && setSubmitState('failed');
    }
  }, [submitError]);

  useEffect(() => {
    if (
      userFirstName !== userFirstNameRef.current ||
      userLastName !== userLastNameRef.current ||
      userDisplayName !== userDisplayNameRef.current
    ) {
      onSuccess();
    }
  }, [userFirstName, userLastName, userDisplayName]);

  const onSubmit = (data: IChangeUserProfile) => {
    updateUserProfile({
      firstName: data.first_name,
      lastName: data.last_name,
      userId,
    });
    setSubmitState('submitted');
  };
  const getUserProfileFields = () => (
    <ConnectForm>
      <Grid item xs={12}>
        <Input {...textFields.firstName} />
      </Grid>
      <Grid item xs={12}>
        <Input {...textFields.lastName} />
      </Grid>
    </ConnectForm>
  );

  return (
    <div className={classes.outerWrapper}>
      <div>
        <DialogHeadline
          headline={t(terms.update_user_profile.headline)}
          subHeadline={t(terms.update_user_profile.sub_headline)}
        />
      </div>

      <Form
        onSubmit={onSubmit}
        className={classes.form}
        schema={changeUserProfileSchema}
        defaultValues={getDefaultValues(userFirstName, userLastName)}
      >
        <Grid container spacing={2}>
          {getUserProfileFields()}

          <Grid item xs={12} className={classes.submitWrapper}>
            <MultilineButton mainText={t(terms.update_user_profile.submit)} />
            {submitState === 'failed' && (
              <FormHelperText className={classes.errorMessage}>
                {t(terms.update_user_profile.failure)}
              </FormHelperText>
            )}
          </Grid>
        </Grid>
      </Form>
    </div>
  );
};

export default ChangeUserProfileForm;
