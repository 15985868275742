interface IErrorModel {
  code: string;
  message: string;
}

const REFRESH_STATUS = {
  USERS_UNAUTHORIZED: 'USERS_UNAUTHORIZED',
  AUTHZ_SIGNIN_WITH_TOKEN: 'AUTHZ_SIGNIN_WITH_TOKEN',
  UNAUTHORIZED: 'UNAUTHORIZED',
};

export default class ErrorService {
  errors: IErrorModel[];
  errorCodes: string[];

  constructor(errors: IErrorModel[]) {
    this.errors = errors;
    this.errorCodes = this.getErrorResponseCodes();
  }

  getErrorResponseCodes(): string[] {
    return this.errors.map((error) => error.code);
  }

  shouldTokenRefresh(): boolean {
    let shouldRefresh = false;

    this.errorCodes.forEach((code: string) => {
      if (Object.keys(REFRESH_STATUS).includes(code)) {
        shouldRefresh = true;
      }
    });

    return shouldRefresh;
  }
}
