import React from 'react';

import { IStationViewModel } from '../../../../../view-models/StationModel';
import CopyTextFieldList from '../../../../ui/CopyTextField/CopyTextFieldList';

export interface IOwnProps {
  chargingStation: IStationViewModel;
}

export const SerialNumberDialog: React.FC<IOwnProps> = ({
  chargingStation,
}) => {
  const textList = [
    {
      title: 'Station Id',
      text: chargingStation.station_id,
    },
    {
      title: 'Serial Number',
      text: chargingStation.station_serial_number,
    },
    {
      title: 'Authentication Name',
      text: chargingStation.auth_name,
    },
    {
      title: 'Device Twin Id',
      text: chargingStation.device_twin_id,
    },
    {
      title: 'Pairing Code',
      text: chargingStation.pairing_code,
    },
  ];

  return <CopyTextFieldList textList={textList} />;
};
