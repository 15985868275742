import React from 'react';
import { IBreadcrumbHandlerProps } from '../../../../utils/addBreadcrumbHandler';
import { CardButton } from '../../CardButton';

interface IOwnProps {
  breadcrumbHandler: (props: IBreadcrumbHandlerProps) => void;
  accountsNumber: number;
}

export const AccountsActionButton: React.FC<IOwnProps> = ({
  accountsNumber,
  breadcrumbHandler,
}) => (
  <CardButton
    onClick={() =>
      breadcrumbHandler({
        component: 'AccountsCollection',
        friendlyText: 'Accounts',
        large: true,
      })
    }
    length={accountsNumber}
    title='Accounts'
  />
);
