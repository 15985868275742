import React from 'react';
import { IBreadcrumbHandlerProps } from '../../../../utils/addBreadcrumbHandler';
import { CardButton } from '../../CardButton';

interface IOwnProps {
  breadcrumbHandler: (props: IBreadcrumbHandlerProps) => void;
  cardOrdersNumber: number;
}

export const OrdersActionButton: React.FC<IOwnProps> = ({
  cardOrdersNumber,
  breadcrumbHandler,
}) => (
  <CardButton
    onClick={() =>
      breadcrumbHandler({
        component: 'OrdersCollection',
        friendlyText: 'Orders',
        large: true,
      })
    }
    length={cardOrdersNumber}
    title='Orders'
  />
);
