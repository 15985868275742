import { Theme } from '@mui/material';

import makeStyles from '@mui/styles/makeStyles';
import createStyles from '@mui/styles/createStyles';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    table: {
      width: 'auto',
      tableLayout: 'fixed',
    },
    root: {
      padding: 0,
    },
    cellPaddingNone: {
      padding: '5px 24px 5px 0',
      minWidth: 200,
    },
    tableHead: {
      fontSize: 12,
      color: '#878c96',
    },
    tableCellRoot: {
      borderBottom: 'none',
      fontWeight: 'normal',
      '& .clickableCell': {
        textDecoration: 'underline',
        '&:hover': {
          cursor: 'pointer',
          color: theme.colorPrimary,
        },
      },
    },
  })
);

export default useStyles;
