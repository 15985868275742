import { CardOrderStateModel } from '../../store/state/cardorders';
import { IChargingCardModel as ChargingCardStateModel } from '../../store/state/chargingCards';
import { ChargingSessionsStateModel } from '../../store/state/chargingSessions';
import { HomeStationsStateModel } from '../../store/state/homeStations';
import { InvoiceStateModel } from '../../store/state/invoices';

import { OrganizationStateModel } from '../../store/state/organization';
import React, { useEffect, useState } from 'react';
import { SubscriberStateModel } from '../../store/state/subscriber';
import { SubscriptionStateModel } from '../../store/state/subscription';
import { UserStateModel } from '../../store/state/user';
import connector, { IPropsFromState } from '../Connector/Connector';

import CloseIcon from '@mui/icons-material/Close';
import { DealershipLogo } from '../DealershipLogo/DealershipLogo';
import IconButton from '@mui/material/IconButton';
import LoadingState from './LoadingState/LoadingState';
import { parseURLparameters } from '../../utils/parseURLparameters';
import useStyles from './useStyles';
import { Typography } from '../shared/Typography/Typography';

export type StoreDataModel =
  | UserStateModel
  | CardOrderStateModel
  | SubscriberStateModel
  | SubscriptionStateModel
  | ChargingCardStateModel
  | ChargingSessionsStateModel
  | OrganizationStateModel
  | HomeStationsStateModel
  | InvoiceStateModel
  | null;

type TProps = IPropsFromState & {
  setDataLoading: (x: boolean) => void;
};

export const LoadingScreen: React.FC<TProps> = ({
  userState,
  subscriberState,
  subscriptionState,
  cardOrdersState,
  chargingCardState,
  chargingSessionsState,
  organizationState,
  homeStationsState,
  userSearchState,
  invoicesState,
  setDataLoading,
  clearSelectedUser,
  clearAll,
}) => {
  const classes = useStyles();

  const [showClose, setShowClose] = useState<boolean>(false);

  const isDataLoaded = (data: StoreDataModel): boolean => {
    return data && (data.data || data.error) ? true : false;
  };

  const user = userState ? userState : null;
  const userLoaded = isDataLoaded(user);

  const subscriber = subscriberState ? subscriberState : null;
  const subscriberLoaded = isDataLoaded(subscriber);

  const subscription = subscriptionState ? subscriptionState : null;
  const subscriptionLoaded = isDataLoaded(subscription);

  const cardOrders = cardOrdersState ? cardOrdersState : null;
  const cardOrdersLoaded = isDataLoaded(cardOrders);

  const chargingCards = chargingCardState ? chargingCardState : null;
  const chargingCardsLoaded = isDataLoaded(chargingCards);

  const chargingSessions = chargingSessionsState ? chargingSessionsState : null;
  const chargingSessionsLoaded = isDataLoaded(chargingSessions);

  const organization = organizationState ? organizationState : null;
  const organizationLoaded = isDataLoaded(organization);

  const homeStations = homeStationsState ? homeStationsState : null;
  const homeStationsLoaded = isDataLoaded(homeStations);

  const invoices = invoicesState ? invoicesState : null;
  const invoicesLoaded = isDataLoaded(invoices);

  const selectedUser = userSearchState?.selectedUser;

  const showScreenInfo = () => {
    if (selectedUser) {
      return (
        (selectedUser.first_name &&
          selectedUser.last_name &&
          `${selectedUser.first_name} ${selectedUser.last_name}`) ||
        selectedUser.email
      );
    }

    const params = parseURLparameters(window.location.search);
    return params.email;
  };

  useEffect(() => {
    setTimeout(() => {
      setShowClose(true);
    }, 10000);
  }, []);

  useEffect(() => {
    if (
      userLoaded &&
      subscriberLoaded &&
      subscriptionLoaded &&
      cardOrdersLoaded &&
      chargingCardsLoaded &&
      chargingSessionsLoaded &&
      organizationLoaded &&
      homeStationsLoaded &&
      invoicesLoaded
    ) {
      setTimeout(() => {
        if (selectedUser) {
          clearSelectedUser();
        }
        setDataLoading(false);
      }, 1500);
    }
  }, [
    userLoaded,
    subscriberLoaded,
    subscriptionLoaded,
    cardOrdersLoaded,
    chargingCardsLoaded,
    chargingSessionsLoaded,
    organizationLoaded,
    homeStationsLoaded,
    invoicesLoaded,
  ]);

  const resetState = () => {
    clearAll();
    clearSelectedUser();
    setDataLoading(false);
  };

  return (
    <div className={classes.loadingScreenWrapper}>
      <div className={classes.loadingScreenBox}>
        <div className={classes.closeWrapper}>
          {showClose && (
            <IconButton
              data-testid='stop-loading-button'
              aria-label='close'
              onClick={() => resetState()}
              size='large'
            >
              <CloseIcon />
            </IconButton>
          )}
        </div>
        <div style={{ display: 'flex' }}>
          <Typography style={{ marginRight: 8 }} variant='h1'>
            {showScreenInfo()}
          </Typography>
          {selectedUser && (
            <DealershipLogo
              id={selectedUser.tenant_id}
              width='32px'
              height='32px'
            />
          )}
        </div>
        <h4 className={classes.loadingTitle}>
          Loading customer information...
        </h4>
        <div
          data-testid='loading-screen'
          className={classes.loadingInformationList}
        >
          <div className={classes.loadingInformationListItem}>
            <LoadingState data={user} />
            <Typography style={{ marginLeft: 5 }} variant='body2'>
              User Profile
            </Typography>
          </div>
          <div className={classes.loadingInformationListItem}>
            <LoadingState data={chargingSessions} />
            <Typography style={{ marginLeft: 5 }} variant='body2'>
              Charging sessions
            </Typography>
          </div>
          <div className={classes.loadingInformationListItem}>
            <LoadingState data={organization} />
            <Typography style={{ marginLeft: 5 }} variant='body2'>
              Organization Profile
            </Typography>
          </div>
          <div className={classes.loadingInformationListItem}>
            <LoadingState data={invoices} />
            <Typography style={{ marginLeft: 5 }} variant='body2'>
              Invoices
            </Typography>
          </div>
          <div className={classes.loadingInformationListItem}>
            <LoadingState data={subscription} />
            <Typography style={{ marginLeft: 5 }} variant='body2'>
              Subscriptions
            </Typography>
          </div>
          <div className={classes.loadingInformationListItem}>
            <LoadingState data={homeStations} />
            <Typography style={{ marginLeft: 5 }} variant='body2'>
              Charging stations
            </Typography>
          </div>
          <div className={classes.loadingInformationListItem}>
            <LoadingState data={chargingCards} />
            <Typography style={{ marginLeft: 5 }} variant='body2'>
              Charging cards
            </Typography>
          </div>
          <div className={classes.loadingInformationListItem}>
            <LoadingState data={null} />
            <Typography style={{ marginLeft: 5 }} variant='body2'>
              Support tickets
            </Typography>
          </div>
          <div className={classes.loadingInformationListItem}>
            <LoadingState data={cardOrders} />
            <Typography style={{ marginLeft: 5 }} variant='body2'>
              Orders
            </Typography>
          </div>
        </div>
      </div>
    </div>
  );
};

export default connector(LoadingScreen);
