/* eslint-disable @typescript-eslint/naming-convention */

export interface BreadcrumbModel {
  defaultFilters?: string[];
  component: string;
  large?: boolean;
  withSidebar?: boolean;
  friendlyText: string;
  level: number;
  componentId?: string;
  id?: string;
  midPoint?: number;
  startPoint?: number;
  current: boolean;
  org?: boolean;
  location_evse_id?: string;
  station_id?: string;
  user_id?: string;
}

export type BreadcrumbStateModel = BreadcrumbModel[];

export interface BreadcrumbState {
  breadcrumbs: BreadcrumbStateModel;
}

export const initialState: BreadcrumbModel = {
  component: 'Overview',
  friendlyText: 'Overview',
  level: 0,
  midPoint: 200,
  startPoint: 0,
  current: true,
};

const state: BreadcrumbState = {
  breadcrumbs: [
    {
      ...initialState,
    },
  ],
};

export type State = typeof state;
export default state;
