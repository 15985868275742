import makeStyles from '@mui/styles/makeStyles';

const useStyles = makeStyles({
  button: {
    display: 'block',
    margin: '0 auto',
  },
  container: {
    padding: 16,
  },
  refundSectionContainer: {
    marginTop: '5rem',
  },
});

export default useStyles;
