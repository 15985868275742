import { useState, useMemo, RefObject } from 'react';
import useResizeObserver from 'use-resize-observer';
import { debounce } from 'lodash';

interface IReturnType {
  ref: RefObject<HTMLDivElement>;
  width?: number;
  height?: number;
}

interface ISizeType {
  width?: number;
  height?: number;
}

export const useDebounceResizeObserver = (wait: number): IReturnType => {
  const [size, setSize] = useState<ISizeType>({});
  const onResize = useMemo(() => debounce(setSize, wait, { leading: true }), [
    wait,
  ]);
  const { ref } = useResizeObserver<HTMLDivElement>({ onResize });

  return { ref, ...size };
};
