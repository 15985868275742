import * as yup from 'yup';
import { IRfidCardOrderFields } from './fields';

export const orderRfidCardFormSchema: yup.SchemaOf<IRfidCardOrderFields> = yup
  .object()
  .shape({
    name: yup.string(),
    state: yup.string(),
    zip: yup
      .string()
      .required()
      .min(2),
    address_line_1: yup.string(),
    address_line_2: yup.string(),
    city: yup.string().required(),
    country: yup.string().required(),
    reason: yup.string().required(),
    other_reason: yup.string().when('reason', {
      is: (reason: string) => reason === 'other',
      then: yup
        .string()
        .min(3)
        .required(),
    }),
  });
