import createStyles from '@mui/styles/createStyles';
import makeStyles from '@mui/styles/makeStyles';

const useStyles = makeStyles(() =>
  createStyles({
    stationRefreshing: {
      opacity: 0.5,
    },
    generalWrapper: {
      display: 'flex',
      flexDirection: 'row',
      alignItems: 'top',
    },
    profileElement: {
      marginBottom: 30,
    },
    dataTypography: {
      marginTop: 10,
    },
  })
);

export default useStyles;
