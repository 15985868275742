import makeStyles from '@mui/styles/makeStyles';
import createStyles from '@mui/styles/createStyles';

const useStyles = makeStyles(() =>
  createStyles({
    userName: {
      fontFamily: 'Inter, sans-serif',
      fontStyle: 'normal',
      fontWeight: 600,
      fontSize: '14px',
      paddingBottom: '12px',
    },
    userGrid: {
      maxWidth: '900px',
    },
    itemValue: {
      display: 'inline-block',
      fontSize: 14,
      alignItems: 'center',
      maxWidth: '110%',
      textOverflow: 'ellipsis',
      overflow: 'hidden',
      whiteSpace: 'nowrap',
    },
  })
);

export default useStyles;
