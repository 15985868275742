import { Theme } from '@mui/material/styles';
import createStyles from '@mui/styles/createStyles';
import makeStyles from '@mui/styles/makeStyles';

export const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    outerWrapper: {
      display: 'flex',
      flexDirection: 'column',
      margin: -16,
      '&> *': {
        margin: 16,
      },
    },
    form: {
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'center',
    },
    buttonWrapper: {
      display: 'flex',
      justifyContent: 'center',
      position: 'relative',
      marginTop: '3em',
    },
    errorMessage: {
      position: 'absolute',
      left: '50%',
      bottom: 0,
      transform: 'translate(-50%, 100%)',
    },
    vinInputRoot: {
      paddingTop: '0.5em',
    },
    vinInput: {
      marginBottom: '1em',
      '& .MuiFormLabel-root': {
        fontWeight: 400,
      },
    },
    buttonErrorAlert: {
      marginTop: '0.5em',
    },
    alertOutlined: {
      borderWidth: '2px',
      backgroundColor: 'none',
      borderColor: theme.colorAlertPrimary,
      color: theme.colorAlertPrimary,
      '&:hover': {
        backgroundColor: 'rgba(180, 0, 89, 0.08)',
        borderColor: theme.colorAlertPrimary,
      },
      '&:active': {
        backgroundColor: 'rgba(180, 0, 89, 0.16)',
        borderColor: theme.colorAlertPrimary,
      },
    },
    alertContained: {
      color: theme.surfacePrimary,
      backgroundColor: theme.colorAlertPrimaryDark,
      '&:hover': {
        backgroundColor: 'rgba(180, 0, 89, 0.9)',
      },
      '&:active': {
        backgroundColor: 'rgba(180, 0, 89, 1)',
      },
    },
    alertText: {
      color: theme.colorAlertPrimary,
      '&:hover': {
        backgroundColor: 'rgba(180, 0, 89, 0.08)',
      },
      '&:active': {
        backgroundColor: 'rgba(180, 0, 89, 0.16)',
      },
    },
    refreshIcon: {
      padding: '5px',
      color: 'rgba(0, 0, 0, 0.54)',
    },
    pncStatus: {
      marginRight: '0em',
    },
  })
);

export default useStyles;
