import makeStyles from '@mui/styles/makeStyles';
import createStyles from '@mui/styles/createStyles';

const useStyles = makeStyles(() =>
  createStyles({
    headingWithIcon: {
      display: 'flex',
      alignItems: 'center',
      lineHeight: 1, // !important,
    },
  })
);

export default useStyles;
