import ApiService from './api';
import { ICoupon, IPaymentMethod } from '../types/paymentMethods';

const getPaymentDetails = async (iamId: string): Promise<IPaymentMethod[]> => {
  const apiService = new ApiService(
    `/mgmt/msp-payment/v1/payment-method/${iamId}/details`
  );
  const response = await apiService.query();
  return [response.data];
};

const getCoupons = async (iamId: string): Promise<{ coupons: ICoupon[] }> => {
  const apiService = new ApiService(
    `/mgmt/promotion/v1/users/${iamId}/coupons`
  );
  const response = await apiService.query();
  return response.data;
};

export const PaymentDetailsService = {
  getPaymentDetails,
  getCoupons,
};
