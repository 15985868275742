import React from 'react';
import Dialog from '@mui/material/Dialog';
import useStyles from './useStyles';
import { Typography } from '../../../../shared/Typography/Typography';

export interface IOwnProps {
  open: boolean;
  onClose: (value: string) => void;
}

export const AutoUpdateDialog: React.FC<IOwnProps> = ({ open, onClose }) => {
  const classes = useStyles();

  return (
    <Dialog
      onClose={onClose}
      open={open}
      data-testid='auto-update-dialog'
      PaperProps={{
        classes: {
          root: classes.dialog,
        },
      }}
    >
      <div className={classes.textWrapper}>
        <div className={classes.section}>
          <span className={classes.title}>On</span>
          <Typography variant='body2'>
            The customer gave consent that their charging station will be
            automatically updated to the newest firmware. New updates will be
            displayed in their wallbox settings but they do not need to give
            permission (consent) each time.
          </Typography>
        </div>
        <div className={classes.section}>
          <span className={classes.title}>Off</span>
          <Typography variant='body2'>
            The customer will be notified of new compatible firmware updates,
            and needs to manually give permission (consent) each time to update
            their charging station.
          </Typography>
        </div>
      </div>
    </Dialog>
  );
};
