import React from 'react';
import { useTypedTranslation } from '../../../custom-hooks/useTypedTranslation';
import ContactDetailsIcon from '../../../assets/image/contactDetails.svg';
import SuccessMessage from '../../shared/Dialogs/SuccessMessage';
import DialogContainer from '../../shared/Dialogs/DialogContainer/DialogContainer';

export interface IProps {
  hasError: boolean;
  isOpen: boolean;
  onClose: () => void;
}

export const TerminateUpcomingSubscriptionDialog: React.FC<IProps> = ({
  hasError,
  isOpen,
  onClose,
}) => {
  const { t, terms } = useTypedTranslation();

  return (
    <DialogContainer isOpen={isOpen} onClose={onClose}>
      {hasError ? (
        <SuccessMessage
          headline={t(
            terms.subscription_information.terminate_upcoming_subscription_error
              .headline
          )}
          subHeadline={t(
            terms.subscription_information.terminate_upcoming_subscription_error
              .sub_headline
          )}
          icon={ContactDetailsIcon}
          iconAltText={t(
            terms.subscription_information.terminate_upcoming_subscription_error
              .headline
          )}
          data-testid='test'
        />
      ) : (
        <SuccessMessage
          headline={t(
            terms.subscription_information
              .terminate_upcoming_subscription_success.headline
          )}
          subHeadline={t(
            terms.subscription_information
              .terminate_upcoming_subscription_success.sub_headline
          )}
          icon={ContactDetailsIcon}
          iconAltText={t(
            terms.subscription_information
              .terminate_upcoming_subscription_success.headline
          )}
        />
      )}
    </DialogContainer>
  );
};
