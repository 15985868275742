import { Dialog } from '@mui/material';
import React, { useRef } from 'react';
import { Trans } from 'react-i18next';
import { useTheme } from '@mui/material/styles';
import { Button } from '@elli-eco/component-library';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import { useTypedTranslation } from '../../../../custom-hooks/useTypedTranslation';
import useStyles from './useStyles';
import Message, { IMessage, MessageTypes } from '../../../shared/Message';
import { Typography } from '../../../shared/Typography/Typography';
import { TerminateSubscriptionForm } from './TerminateSubscriptionForm/TerminateSubscriptionForm';
import { IOnTerminateSubscriptionParams } from '../SubscriptionDetails/SubscriptionDetails';

const blockTerminationWithUpcomingSubMessage: IMessage = {
  type: MessageTypes.ERROR,
  text:
    'This subscription can not be terminated as it has an upcoming subscription attached to it. Please terminate the upcoming subscription first to revoke this active subscription.',
};

export type OwnProps = {
  open: boolean;
  onClose: () => void;
  isLoading: boolean;
  doTerminate: (params: IOnTerminateSubscriptionParams) => void;
  doSetTerminationDate: () => void;
  tenant: string;
  hasUpcomingSubscription: boolean;
};

export const TerminateSubscriptionWarningDialog: React.FC<OwnProps> = ({
  open,
  onClose,
  isLoading,
  doTerminate,
  doSetTerminationDate,
  tenant,
  hasUpcomingSubscription,
}) => {
  const theme = useTheme();
  const classes = useStyles(theme);
  const { t, terms } = useTypedTranslation();

  const isWeChargeTenant = (tn: string | null): boolean => {
    const weChargeTenantNames = ['WeCharge', 'Volkswagen'];
    return tn !== null && weChargeTenantNames.includes(tn);
  };

  const terminateButton = useRef<HTMLButtonElement>(null);

  return (
    <Dialog
      open={open}
      onClose={onClose}
      PaperProps={{
        classes: {
          root: classes.dialog,
        },
      }}
    >
      {onClose ? (
        <IconButton
          aria-label='close'
          className={classes.closeButton}
          onClick={onClose}
          size='large'
        >
          <CloseIcon />
        </IconButton>
      ) : null}
      <Typography paragraph variant='h1' color='inherit' align='center'>
        {t(terms.subscription_termination.terminate_subscription)}
      </Typography>
      <Typography paragraph variant='subtitle1' align='center'>
        <Trans i18nKey={terms.subscription_termination.confirmation} />
      </Typography>
      {isWeChargeTenant(tenant) ? (
        <Typography paragraph variant='caption' align='center'>
          {t(terms.subscription_termination.vin_tenant_warning)}
        </Typography>
      ) : (
        ''
      )}
      <br />
      <TerminateSubscriptionForm
        callback={doTerminate}
        disabled={isLoading || hasUpcomingSubscription}
        isLoading={isLoading}
      />
      <div className={classes.terminationConfirmationButton}>
        {!hasUpcomingSubscription ? (
          <Button
            ref={terminateButton}
            variant='outlined'
            onClick={doSetTerminationDate}
            disabled={isLoading || hasUpcomingSubscription}
          >
            {t(terms.subscription_termination.terminate_at_future_date)}
          </Button>
        ) : (
          <Message message={blockTerminationWithUpcomingSubMessage} />
        )}
      </div>
    </Dialog>
  );
};
