/* eslint-disable @typescript-eslint/naming-convention */
import { ErrorObject } from 'serialize-error';
import { IVehicleModel } from '../../../types/vehicle';

export interface GetVehicleRequest {
  vin: string;
  brand: string;
}

export interface VehicleStateModel {
  loading: boolean;
  data: IVehicleModel | null;
  error: ErrorObject | null;
  request: { vin: string; brand: string } | null;
}

export const initialState: VehicleStateModel = {
  loading: false,
  data: null,
  error: null,
  request: null,
};

const state: VehicleStateModel = {
  ...initialState,
};

export type State = typeof state;
export default state;
