import * as React from 'react';
import { toast, ToastBar, Toaster } from 'react-hot-toast';
import { Close, Warning } from '@mui/icons-material';
import { IconButton } from '@mui/material';
import { useTheme } from '@mui/styles';

export function DismissableToast() {
  const theme = useTheme();
  return (
    <div>
      <Toaster
        reverseOrder={false}
        position='top-center'
        toastOptions={{
          style: {
            borderRadius: '8px',
            background: theme.surfacePrimary,
            color: theme.textPrimary,
          },
        }}
      >
        {(t) => (
          <ToastBar toast={t}>
            {({ icon, message }) => (
              <>
                {t.type === 'error' ? (
                  <Warning style={{ color: theme.colorAlertSecondaryDark }} />
                ) : (
                  icon
                )}
                {message}
                {t.type !== 'loading' && (
                  <IconButton size='small'>
                    <Close
                      onClick={() => toast.dismiss(t.id)}
                      style={{ color: theme.textSecondary }}
                    />
                  </IconButton>
                )}
              </>
            )}
          </ToastBar>
        )}
      </Toaster>
    </div>
  );
}
