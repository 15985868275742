import { Theme } from '@mui/material';

import makeStyles from '@mui/styles/makeStyles';
import createStyles from '@mui/styles/createStyles';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    brandBox: {
      display: 'flex',
    },
    brandButton: {
      fontFamily: 'Inter, sans-serif',
      fontStyle: 'normal',
      fontWeight: 600,
      fontSize: '16px',
      padding: '13px',
      cursor: 'pointer',
    },
    buttonSelected: {
      borderBottom: `3px solid ${theme.colorPrimary}`,
      color: theme.colorPrimary,
    },
  })
);

export default useStyles;
