import {
  EInvoiceRefundType,
  EInvoiceReimbursementAmount,
} from '../../../../../../types/invoice';
import { ISelectOption } from '../../../../../shared/form-elements/select/Select.types';

const REFUND_TYPE_SELECT_OPTIONS: ISelectOption[] = [
  {
    key: EInvoiceRefundType.CHARGING_SESSION,
    value: EInvoiceRefundType.CHARGING_SESSION,
    content: 'Charging Session',
  },
  {
    key: EInvoiceRefundType.SUBSCRIPTION_FEE,
    value: EInvoiceRefundType.SUBSCRIPTION_FEE,
    content: 'Subscription Fee',
  },
];

const REIMBURSEMENT_AMOUNT_SELECT_OPTIONS: ISelectOption[] = [
  {
    key: EInvoiceReimbursementAmount.PARTIAL,
    value: EInvoiceReimbursementAmount.PARTIAL,
    content: 'Partial Amount',
  },
  {
    key: EInvoiceReimbursementAmount.FULL,
    value: EInvoiceReimbursementAmount.FULL,
    content: 'Full Amount',
  },
];

export { REFUND_TYPE_SELECT_OPTIONS, REIMBURSEMENT_AMOUNT_SELECT_OPTIONS };
