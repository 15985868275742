import React from 'react';
import { IBreadcrumbHandlerProps } from '../../../utils/addBreadcrumbHandler';
import { ElliTooltip } from '../../shared/ElliTooltip/ElliTooltip';
import { addClickableLink } from './helpers';
import useSharedStyles from '../../shared/useSharedStyles';

interface IOwnProps {
  location_id: string;
  evse_id: string;
  station_name: string;
  addBreadcrumbHandler: (props: IBreadcrumbHandlerProps) => void;
}

export const StationDisplayName: React.FC<IOwnProps> = ({
  location_id,
  evse_id,
  station_name,
  addBreadcrumbHandler,
}) => {
  const sharedClasses = useSharedStyles();
  const breadcrumbHandler = () =>
    addBreadcrumbHandler({
      component: 'ChargingStationDetails',
      friendlyText: 'Charging Station',
      id: location_id,
      config: {
        station_id: location_id,
        location_evse_id: evse_id,
      },
    });

  return (
    <ElliTooltip title={station_name}>
      <span
        className={addClickableLink(location_id, {
          textOverflow: sharedClasses.textOverflow,
        })}
        data-testid='charging-station-link'
        onClick={breadcrumbHandler}
      >
        {station_name}
      </span>
    </ElliTooltip>
  );
};
