import { Theme } from '@mui/material';

import makeStyles from '@mui/styles/makeStyles';
import createStyles from '@mui/styles/createStyles';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    'MuiButton-label': {
      paddingTop: 2,
    },
    dropdownButton: {
      padding: '0 4px',
      minWidth: 'auto',
      margin: '0 12px 0 4px',
      borderBottom: '2px solid transparent',
    },
    dropdownButtonUnderline: {
      padding: '0 4px',
      minWidth: 'auto',
      margin: '0 12px 0 4px',
      borderBottom: `2px solid ${theme.colorPrimary}`,
    },
    dropdownPaper: {
      width: 190,
    },
    menuItem: {
      fontWeight: 'normal',
      fontSize: 16,
      color: theme.colorPrimary,
      borderBottom: '1px solid #f5f6f8',
    },
    menuItemText: {
      display: 'block',
      whiteSpace: 'nowrap',
      overflow: 'hidden',
      textOverflow: 'ellipsis',
    },
    menuList: {
      padding: 0,
    },
    breadcrumbWrapper: {
      fontSize: '16px',
      display: 'flex',
      alignItems: 'center',
    },

    breadcrumb: {
      color: '#6633cc',
      paddingBottom: '3px',
      paddingTop: '3px',
      borderBottom: '2px solid transparent',
      marginRight: '5px',
      display: 'flex',
      alignItems: 'center',
      whiteSpace: 'nowrap',
    },

    actionItem: {
      fontWeight: 'normal',
    },

    arrow: {
      marginRight: '5px',
      fontSize: 16,
    },

    breadcrumLink: {
      cursor: 'pointer',
      '&:hover': {
        textDecoration: 'underline',
      },
    },

    arrowSpan: {
      display: 'flex',
      alignItems: 'center',
    },

    lastBreadcrumb: {
      fontWeight: 'bold',
    },

    currentBreadcrumb: {
      borderBottom: '2px solid #6633cc',
    },
    name: {
      fontWeight: 'bold',
      marginRight: '5px',
      whiteSpace: 'nowrap',
      height: '100%',
      paddingBottom: '3px',
      paddingTop: '3px',
      borderBottom: '2px solid transparent',
    },
    buttonLabel: {
      paddingTop: '2px',
    },
  })
);

export default useStyles;
