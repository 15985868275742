import { ActionInterface } from '../actions';
import { State } from '..';
import update from 'immutability-helper';

export default (
  state: State,
  action: ActionInterface.SetVerificationStep
): State => {
  const { step } = action;
  const currentUI = state || {
    isVerificationOpen: false,
    verificationStep: 1,
  };

  const updatedUI = update(currentUI, {
    verificationStep: {
      $set: step,
    },
  });

  return {
    ...state,
    ...updatedUI,
  };
};
