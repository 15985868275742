import React from 'react';
import MuiTypography, { TypographyProps } from '@mui/material/Typography';
import { Variant } from '@mui/material/styles/createTypography';

interface IProps extends Omit<TypographyProps, 'variant'> {
  variant?: Variant | 'inherit';
}

/**
 * The Blitz Component Library explicitly excludes certain MUI Typography variants from being used.
 * This wrapper component again enables the use of these variants since they are widely used throughout the Contact Center.
 * Please use Blitz Component Library typography components over MUI Typography in the future.
 * See {@link https://component-library.elli.eco/?path=/docs/components-typography Component Library}.
 * @example
 * import { Body } from '@elli-eco/component-library';
 *
 * // Instead of <Typography variant='body2'></Typography>
 * <Body></Body>
 */
export const Typography: React.FC<IProps> = (props) => {
  return (
    <MuiTypography
      {...props}
      variant={props.variant as TypographyProps['variant']}
    >
      {props.children}
    </MuiTypography>
  );
};
