import React from 'react';
import { Grid } from '@mui/material';
import useStyles from './useStyles';
import { useTypedTranslation } from '../../../../custom-hooks/useTypedTranslation';
import InformationItem from '../../../shared/InformationItems/InformationItem';
import { PaymentMethodStatus } from '../../../../types/paymentMethods';
import { PaymentMethodStatusComponent } from './PaymentMethodStatusComponent';
import { Typography } from '../../../shared/Typography/Typography';

interface IPaymentCardInformation {
  cardIssuer: string;
  bankName: string;
  status: PaymentMethodStatus;
  expiryMonth: number;
  expiryYear: number;
}

export const PaymentCardInformation: React.FC<IPaymentCardInformation> = ({
  cardIssuer,
  bankName,
  status,
  expiryMonth,
  expiryYear,
}) => {
  const { t, terms } = useTypedTranslation();
  const classes = useStyles();
  const endDate = `${expiryMonth}/${expiryYear}`;
  const getItems = () => {
    return [
      {
        id: 'card-issuer',
        headline: t(terms.payment_methods.card_issuer),
        content: (
          <>
            <Typography variant='body2'>{cardIssuer}</Typography>
          </>
        ),
      },
      {
        id: 'payment-method-status',
        headline: t(terms.payment_methods.status),
        content: <PaymentMethodStatusComponent status={status} />,
      },
      {
        id: 'bank-name',
        headline: t(terms.payment_methods.bank_name),
        content: (
          <>
            <Typography variant='body2'>{bankName} </Typography>
          </>
        ),
      },
      {
        id: 'expiry',
        headline: t(terms.payment_methods.expiry_date),
        content: (
          <>
            <Typography variant='body2'>{endDate}</Typography>
          </>
        ),
      },
    ];
  };

  return (
    <div className={classes.cardInformation}>
      <Grid container>
        {getItems().map((item) => (
          <Grid item xs={6} key={item.id}>
            <InformationItem item={item} />
          </Grid>
        ))}
      </Grid>
    </div>
  );
};
