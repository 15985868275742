import createStyles from '@mui/styles/createStyles';
import makeStyles from '@mui/styles/makeStyles';

const useStyles = makeStyles(() =>
  createStyles({
    indicator: {
      marginRight: 8,
    },
    capitalize: {
      textTransform: 'capitalize',
    },
    stationRefreshing: {
      opacity: 0.5,
    },
    profileElement: {
      marginBottom: 30,
    },
    firmwareHeader: {
      fontSize: 16,
      fontWeight: 600,
      lineHeight: 1.38,
      marginBottom: 7,
      display: 'flex',
      alignItems: 'center',
    },
    statusWrapper: {
      display: 'flex',
      alignItems: 'center',
      marginBottom: 8,
    },
    infoIcon: {
      marginLeft: 2,
      cursor: 'pointer',
      fontSize: 12,
    },
    dialog: {
      padding: 32,
      width: 410,
    },
    textWrapper: {
      display: 'flex',
      flexFlow: 'column nowrap',
      marginBottom: 20,
      '&:last-child': {
        marginBottom: 0,
      },
    },
    title: {
      fontWeight: 600,
      fontSize: 12,
    },
    section: {
      marginBottom: 24,
      '&:last-child': {
        marginBottom: 0,
      },
    },
    statusText: {
      textTransform: 'capitalize', // !important',
    },
  })
);

export default useStyles;
