import React from 'react';
import sum from 'lodash/sum';
import isEmpty from 'lodash/isEmpty';
import Grid from '@mui/material/Grid';

import { Headline } from '@elli-eco/component-library';
import { ChargeRecordRefundTable } from '../table/ChargeRecordRefundTable';
import { useTypedTranslation } from '../../../../../../../custom-hooks/useTypedTranslation';
import { IInvoiceRefundLineItem } from '../../../../../../../types/invoice';
import { TotalAmountLabelStyled } from './ChargeRecordRefundEntries.style';
import { Typography } from '../../../../../../shared/Typography/Typography';

export type TChargeRecordRefundEntriesProps = {
  currency: string;
  refundEntries?: IInvoiceRefundLineItem[];
};
const ChargeRecordRefundEntries: React.FC<TChargeRecordRefundEntriesProps> = ({
  currency,
  refundEntries = [],
}) => {
  const { t, terms } = useTypedTranslation();

  const totalRefundAmount = sum(
    refundEntries.map((entry) => entry.gross_amount_to_refund)
  ).toFixed(2);

  return (
    <Grid item container>
      <Grid item xs={12}>
        <Headline>{t(terms.refunds.form.entries.headline)}</Headline>
        {isEmpty(refundEntries) ? (
          <Typography variant='body2'>
            {t(terms.refunds.form.entries.noEntries)}
          </Typography>
        ) : (
          <>
            <ChargeRecordRefundTable
              columns={['CDR ID', 'Amount']}
              refundEntries={refundEntries}
              currency={currency}
            />

            <TotalAmountLabelStyled variant='caption'>
              {t(terms.refunds.form.entries.totalAmount, {
                amount: totalRefundAmount,
                currency,
              })}
            </TotalAmountLabelStyled>
          </>
        )}
      </Grid>
    </Grid>
  );
};

export default ChargeRecordRefundEntries;
