import ApiService from './api';
import { ITariffModel, ITariffConditionModel } from '../types/tariff';
import { AxiosResponse } from 'axios';

const getById = async (tariffId: string): Promise<ITariffModel> => {
  const apiService = new ApiService(`/mgmt/customer/v1/tariffs/${tariffId}`);
  const response = await apiService.query();
  return response.data;
};

const getByConditionId = async (
  tariffId: string,
  baseConditionId: string
): Promise<ITariffConditionModel> => {
  const apiService = new ApiService(
    `/mgmt/customer/v1/tariffs/${tariffId}/baseconditions/${baseConditionId}`
  );
  const response = await apiService.query();
  return response.data;
};

const getTariffPdf = async (
  baseConditionId: string
): Promise<AxiosResponse<Blob>> => {
  const apiService = new ApiService(
    `/mgmt/customer/v1/tariffs/${baseConditionId}/pdf`,
    {
      responseType: 'blob',
    }
  );
  return await apiService.query();
};

export const TariffService = {
  getById,
  getByConditionId,
  getTariffPdf,
};
