import { ActionInterface, ActionType } from '../actions';
import initialState, { State } from '..';

import getInvoicesError from './getInvoicesError';
import getInvoicesRequest from './getInvoicesRequest';
import getInvoicesSuccess from './getInvoicesSuccess';
import sortInvoices from './sortInvoices';

const reducer = (state: State = initialState, action: any) => {
  switch (action.type) {
    case ActionType.GetInvoicesRequest:
      return getInvoicesRequest(
        state,
        action as ActionInterface.GetInvoicesRequest
      );
    case ActionType.GetInvoicesSuccess:
      return getInvoicesSuccess(
        state,
        action as ActionInterface.GetInvoicesSuccess
      );
    case ActionType.GetInvoicesError:
      return getInvoicesError(
        state,
        action as ActionInterface.GetInvoicesError
      );
    case ActionType.SortInvoices:
      return sortInvoices(state, action as ActionInterface.SortInvoices);
    default:
      return state;
  }
};

export default reducer;
