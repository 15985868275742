import { useQuery, UseQueryResult } from 'react-query';
import { CCRolesService } from '../../services/ccUserRoles';
import {
  isCCManager,
  isCCSupervisor,
  isGDPRManager,
  isOpsInternal,
} from '../../utils/isRole';
import { queryClient } from '../queryClient';

export interface ICCRoles {
  isCCSupervisor: boolean | null;
  isCCManager: boolean | null;
  isOpsInternal: boolean | null;
  isGdprManager: boolean | null;
}

export const useCCUserRolesQuery = (): UseQueryResult<ICCRoles> => {
  return useQuery(
    'ccUserRoles',
    async () => {
      const ccRoles = await CCRolesService.getCCUserRoles();
      return {
        isCCManager: isCCManager(ccRoles.roles),
        isCCSupervisor: isCCSupervisor(ccRoles.roles),
        isOpsInternal: isOpsInternal(ccRoles.roles),
        isGdprManager: isGDPRManager(ccRoles.roles),
      };
    },
    {
      enabled: false,
    }
  );
};

export const resetCCUserRolesQuery = (): (() => Promise<void>) => {
  return async () => await queryClient.resetQueries('ccUserRoles');
};
