import update from 'immutability-helper';
import { State } from '..';
import { ActionInterface } from '../actions';

export default (
  state: State,
  action: ActionInterface.GetUserSearchSuccess
): State => {
  const { data } = action;
  const currentUser = state;
  const updatedUser = update(currentUser, {
    data: {
      $set: data,
    },
    loading: {
      $set: false,
    },
  });

  return {
    ...state,
    ...updatedUser,
  };
};
