import React from 'react';
import useStyles from './useStyles';
import { Typography } from '../Typography/Typography';

export interface IProps {
  linkTo: () => void;
  linkText: string;
  testId?: string;
}

export const TextLink: React.FC<IProps> = ({ linkTo, linkText, testId }) => {
  const classes = useStyles();
  return (
    <Typography variant='body2'>
      <span
        data-testid={testId || null}
        className={classes.chargingCardLink}
        onClick={() => linkTo()}
      >
        {linkText}
      </span>
    </Typography>
  );
};
