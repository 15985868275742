import React from 'react';
import { useTheme } from '@mui/material/styles';
import ReceiptIcon from '@mui/icons-material/Receipt';
import useStyles from '../useStyles';
import { getColumns, getRows } from './InvoiceChargeRecordTable.config';
import { AssetTable } from '../../../shared/AssetTable/AssetTable/AssetTable';
import { IInvoiceChargeRecord } from '../../../../types/invoiceLineItems';
import {
  IInvoiceRefundLineItem,
  IInvoiceLineItem,
} from '../../../../types/invoice';

export type IInvoiceCdrLineItem = Omit<
  IInvoiceChargeRecord,
  'charging_credit' | 'coupon_reduction'
>;

interface IInvoiceCdrs {
  refundEntries?: IInvoiceRefundLineItem[];
  invoiceLineItems?: IInvoiceLineItem[];
  selectedCdrId?: string;
  onRefund?: (cdrLineItemId: string) => void;
}

export const InvoiceChargeRecordTable: React.FC<IInvoiceCdrs> = ({
  invoiceLineItems = [],
  refundEntries = [],
  selectedCdrId,
  onRefund,
}) => {
  const theme = useTheme();
  const classes = useStyles(theme);

  return (
    <>
      <div className={classes.sectionWrapper} data-testid='invoice-cdrs'>
        <ReceiptIcon className={classes.headerLogo} />
        <h1 className={classes.headerTitle}>CDRs</h1>
      </div>
      <AssetTable
        bulkActions={[]}
        columns={getColumns(!!onRefund)}
        rows={getRows(invoiceLineItems, refundEntries, onRefund, selectedCdrId)}
        sortRequestHandler={() => true}
        noDataTitle='No charge records available'
        noDataSubTitle='The selected invoice does not have any cdrs.'
      />
    </>
  );
};
