import { getDevConfig } from './config/dev';
import { getLocalConfig } from './config/local';
import { getProdConfig } from './config/prod';
import { getStageConfig } from './config/stage';
import { getTestConfig } from './config/test';
import { Config } from './utils/environment';
import { UnreachableCaseError } from './utils/errors';

export interface IConfig {
  useDevtools: boolean;
  elliBaseUrl: string;
  elliAuthUrl?: string;
  aadRedirectUrl: string;
  aadClientAuthority: string;
  aadClientId: string;
  XApiKey: string;
  googleMapApiKey: string;
  env: string;
  launchDarklyClientId: string;
  auth0Audience: string;
  auth0ClientId: string;
  auth0Domain: string;
  auth0RedirectUri: string;
  auth0Scopes: string;
}

const getConfig = (): IConfig => {
  switch (window.CONFIG) {
    case Config.DEV:
      return getDevConfig();
    case Config.LOCAL:
      return getLocalConfig();
    case Config.STAGE:
      return getStageConfig();
    case Config.PROD:
      return getProdConfig();
    case Config.TEST:
      return getTestConfig();
    default:
      throw new UnreachableCaseError(window.CONFIG);
  }
};

export const config = getConfig();
