import { IConfig } from '../config';

export const getProdConfig = (): IConfig => ({
  useDevtools: false,
  elliBaseUrl: 'https://api.elli.eco',
  aadRedirectUrl: 'https://contact-center.elli.eco/',
  aadClientAuthority:
    'https://login.microsoftonline.com/fbb6e5ca-b9eb-4bc5-b01e-50e49ed6ea42',
  aadClientId: '6422ba02-c3d9-4ad6-a63a-1630702af13c',
  XApiKey: 'amMu3Isd3UKzGQbd2cbA5Tt7VDaZC6pp',
  // TODO: #121436 Revoke and remove API keys from code
  googleMapApiKey: 'AIzaSyC0syM2bvnt0PImiVc7DXj-quYnngC80gQ',
  env: 'prod',
  launchDarklyClientId: '5f6af87ee2134b0af4d21d15',
  auth0Audience: 'https://api.elli.eco/mgmt',
  auth0ClientId: 'kMn4dZ585Zj6kfhTCupJ3Mz8V10kQNvk',
  auth0Domain: 'https://login.elli.eco',
  auth0RedirectUri: 'https://contact-center.elli.eco',
  auth0Scopes: 'openid', //should be enough if not use 'openid profile'
});
