import React from 'react';
import InformationItem, { IInformationItem } from './InformationItem';
import { QueryObserverResult } from 'react-query';

export interface IOwnProps {
  item: IInformationItem;
  queryState: QueryObserverResult;
  hasMarginRight?: boolean;
}

/**
 * Container for information used e.g on detail cards
 * in which the content depends on a state query
 * @param item information item content
 * @param query stateful query which item rendering depends on
 * @param hasMarginRight option to show right margin
 */
export const StatefulInformationItem: React.FC<IOwnProps> = ({
  item,
  queryState,
  hasMarginRight = true,
}) => {
  return (
    <InformationItem
      item={item}
      isLoading={queryState.isLoading || queryState.isError}
      hasMarginRight={hasMarginRight}
    />
  );
};
