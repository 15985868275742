import update from 'immutability-helper';
import { State } from '../';
import { ActionInterface } from '../actions';

export default (
  state: State,
  action: ActionInterface.GetSubscriberTempSuccess
): State => {
  const { searchedData } = action;
  const currentSubscriber = state;

  const updatedSubscriber = update(currentSubscriber, {
    searchData: {
      $set: searchedData,
    },
    loading: {
      $set: false,
    },
  });

  return {
    ...state,
    ...updatedSubscriber,
  };
};
