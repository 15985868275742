import React, { useState } from 'react';
import VinEligibilityImage from '../../assets/image/vinEligibility.svg';
import { useTypedTranslation } from '../../custom-hooks/useTypedTranslation';
import connector, { IPropsFromState } from '../Connector/Connector';
import useCRMStyles from '../CRMPanel/useStyles';
import BrandSelection from './BrandSelection/BrandSelection';
import useStyles from './useStyles';
import VinResultCard from './VinResultCard/VinResultCard';
import VinSearch from './VinSearch/VinSearch';
import { Typography } from '../shared/Typography/Typography';

export enum AvailableBrands {
  SEAT = 'SEAT',
  SKODA = 'SKODA',
}

const VinEligibilityImageComponent = (): JSX.Element => {
  const sharedCrmClasses = useCRMStyles({});
  return (
    <div className={sharedCrmClasses.welcomeImageContainer}>
      <img src={VinEligibilityImage} alt='Welcome' />
    </div>
  );
};

type TOwnProps = Pick<IPropsFromState, 'vehicleState'>;

export const VinEligibility: React.FC<TOwnProps> = ({ vehicleState }) => {
  const vehicle = vehicleState;
  const classes = useStyles();
  const { t, terms } = useTypedTranslation();

  const [selectedBrand, setSelectedBrand] = useState(AvailableBrands.SEAT);

  return (
    <>
      <div className={classes.brandBox} data-testid='brand-selection'>
        <BrandSelection
          selectedBrand={selectedBrand}
          setSelectedBrand={setSelectedBrand}
        />
      </div>
      <div className={classes.searchBox} data-testid='vin-search'>
        <VinSearch selectedBrand={selectedBrand} />
      </div>
      {vehicle && vehicle.data ? (
        <div className={classes.resultBox} data-testid='vehicle-result-card'>
          <VinResultCard />
        </div>
      ) : (
        <div className={classes.emptyResult} data-testid='empty-result-page'>
          <VinEligibilityImageComponent />
          <Typography variant='h3' className={classes.headline}>
            {t(terms.vin_eligibility.vin_search.headline)}
          </Typography>
          <Typography variant='body2' className={classes.text}>
            {t(terms.vin_eligibility.vin_search.instructions)}
          </Typography>
        </div>
      )}
    </>
  );
};

export default connector(VinEligibility);
