import makeStyles from '@mui/styles/makeStyles';

const useStyles = makeStyles({
  cardElement: {
    backgroundColor: '#ffffff',
    boxShadow: '0 2px 8px 0 rgba(0, 0, 0, 0.08)',
    borderRadius: 4,
    padding: 16,
    marginBottom: 12,
    position: 'relative',
  },
  button: {
    display: 'block',
    margin: '16px 16px 16px auto',
  },
});

export default useStyles;
