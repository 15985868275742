import update from 'immutability-helper';
import { State } from '../';
import { IAddressModel, ISubscriberModel } from '../../../../types/subscriber';
import { ActionInterface } from '../actions';

export const updateBillingAddressSuccess = (
  state: State,
  action: ActionInterface.UpdateBillingAddressSuccess
): State => {
  const { subscriberAddress } = action;
  const currentSubscriber = state;

  const createUpdatedAddress = (
    currentAddress: ISubscriberModel['address']
  ) => {
    return {
      ...(currentAddress as IAddressModel),
      ...subscriberAddress,
    };
  };

  const updatedSubscriber = update(currentSubscriber, {
    data: {
      tax_number: {
        $set: subscriberAddress.tax_number,
      },
      address: {
        $apply: createUpdatedAddress,
      },
    },
  });

  return {
    ...state,
    ...updatedSubscriber,
  };
};
