import React from 'react';
import { useTheme } from '@mui/material/styles';
import { PaymentMethodStatus } from '../../../../types/paymentMethods';
import {
  BasicStatus,
  IMappedStatus,
} from '../../../shared/StatusIndicator/BasicStatus';
import { assertNever } from '../../../../utils/assertNever';

export const PaymentMethodStatusComponent: React.FC<{
  status: PaymentMethodStatus;
  CustomLabelComponent?: React.FC<{ label: string }>;
}> = ({ status, CustomLabelComponent }) => {
  const theme = useTheme();

  function valueMapper(value: PaymentMethodStatus): IMappedStatus {
    switch (value) {
      case PaymentMethodStatus.Valid:
        return {
          color: theme.colorAccent, //green - electric lime
          label: 'Valid',
        };
      case PaymentMethodStatus.Unverified:
        return {
          color: theme.colorAlertSecondary, //orange
          label: 'Unverified',
        };
      case PaymentMethodStatus.In_Verification:
        return {
          color: theme.colorAlertSecondary, //orange
          label: 'In verification',
        };
      case PaymentMethodStatus.Expired:
        return {
          color: theme.colorAlertPrimary, //popular pink
          label: 'Expired',
        };
      case PaymentMethodStatus.Not_Available:
        return {
          color: theme.materialFrameColor, //grey
          label: 'Not available',
        };
      default:
        return assertNever(value);
    }
  }

  return (
    <BasicStatus
      status={status}
      valueMapper={valueMapper}
      CustomLabelComponent={CustomLabelComponent}
    />
  );
};
