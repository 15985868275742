import { Divider, Grid } from '@mui/material';
import {
  IBreadcrumbHandlerProps,
  addBreadcrumbHandler,
} from '../../../utils/addBreadcrumbHandler';
import { IOrganizationModel, IUserModel } from '../../../types/user';
import React, { useEffect, useState } from 'react';
import connector, { IPropsFromState } from '../../Connector/Connector';

import CardElement from '../../shared/CardElement/CardElement';
import { ElliTooltip } from '../../shared/ElliTooltip/ElliTooltip';
import { IMember } from '../../../types/organization';
import { OrgDialog } from './Dialog';
import { formatDateNoTime } from '../../../utils/dates';
import useSharedStyles from '../../shared/useSharedStyles';
import { useFlags } from 'launchdarkly-react-client-sdk';
import Tooltip from '../../ui/Tooltip';
import { ActionBar } from '../../shared/ActionBar/ActionBar';
import ActionButtons from './ActionButtons';
import useStyles from './useStyles';
import { Typography } from '../../shared/Typography/Typography';

type TProps = IPropsFromState & {
  crumbId?: string;
  startCollapsed?: boolean;
};

const nullOrganization: IOrganizationModel = {
  created_at: '-',
  email_address: '-',
  iam_id: '-',
  kvps_number: '',
  name: '-',
  phone_number: '-',
  type: '-',
  updated_at: '-',
};

export const OrganizationUI: React.FC<TProps> = ({
  organizationState,
  chargingSessionsState,
  userState,
  startCollapsed = false,
  addBreadcrumb,
}) => {
  const { accountsCollection } = useFlags();
  const sharedClasses = useSharedStyles();
  const classes = useStyles();
  const organization = organizationState;
  const chargingSessions = chargingSessionsState;
  const taskUser = userState;

  const [organizationData, setOrganizationData] = useState<IOrganizationModel>(
    nullOrganization
  );

  const [organizationMembers, setOrganizationMembers] = useState<IMember[]>([]);
  const [currentUser, setCurrentUser] = useState<IUserModel | null>(null);
  const [userRoles, setUserRoles] = useState<string[]>([]);

  useEffect(() => {
    if (taskUser && taskUser.data) {
      setCurrentUser(taskUser.data);
    }
  }, [taskUser]);

  useEffect(() => {
    if (organization && organization.data) {
      setOrganizationData({ ...nullOrganization, ...organization.data });
    } else {
      setOrganizationData(nullOrganization);
    }
  }, [organizationState]);

  useEffect(() => {
    if (organizationMembers.length && currentUser) {
      const currentOrgMember = organizationMembers.find(
        (orgMember) => orgMember.email === currentUser.email
      );
      if (currentOrgMember) {
        setUserRoles(currentOrgMember.roles);
      }
    }
  }, [organizationMembers, currentUser]);

  const breadcrumbHandler = (props: IBreadcrumbHandlerProps): void =>
    addBreadcrumbHandler({
      ...props,
      addBreadcrumb,
      level: 1,
      config: {
        org: true,
      },
    });

  return organizationData.iam_id !== '-' ? (
    <CardElement title='Organization Profile' startCollapsed={startCollapsed}>
      <Grid
        data-testid='organization-ui-component'
        classes={{
          root: classes.detailWrapper,
        }}
        container
        justifyContent='space-between'
        alignItems='center'
        direction='row'
      >
        <Grid item xs={12}>
          <div style={{ marginBottom: 16 }}>
            <Typography data-testid='name-title' variant='h4'>
              Name: {organizationData.name}
            </Typography>
          </div>
        </Grid>
        <Grid item xs={6}>
          <div className={sharedClasses.cardDetail}>
            <Typography
              className={sharedClasses.headingWithIcon}
              data-testid='tariff-name-title'
              variant='h6'
            >
              Business
              {organizationData.iam_id && (
                <Tooltip tooltipName='more-info'>
                  <OrgDialog orgId={organizationData.iam_id} />
                </Tooltip>
              )}
            </Typography>
            <div className={sharedClasses.cardDetailData}>
              <Typography data-testid='tariff-name' variant='body2'>
                {organizationData.kvps_number
                  ? `Car Dealership (KVPS: ${organizationData.kvps_number})`
                  : '-'}
              </Typography>
            </div>
          </div>
        </Grid>
        <Grid item xs={6}>
          <div className={sharedClasses.cardDetail}>
            <Typography data-testid='subscription-status-title' variant='h6'>
              Contact Email
            </Typography>
            <div className={sharedClasses.cardDetailData}>
              <ElliTooltip
                title={organizationData.email_address as string}
                alwaysShowOnHover={false}
              >
                <Typography
                  classes={{
                    root: classes.emailTrim,
                  }}
                  data-testid='customer-email'
                  variant='body2'
                >
                  {organizationData.email_address}
                </Typography>
              </ElliTooltip>
            </div>
          </div>
        </Grid>
        <Grid item xs={6}>
          <div className={sharedClasses.cardDetail}>
            <Typography data-testid='signup-date-title' variant='h6'>
              Creation Date
            </Typography>
            <div className={sharedClasses.cardDetailData}>
              <Typography data-testid='signup-date' variant='body2'>
                {formatDateNoTime(organizationData.created_at || '')}
              </Typography>
            </div>
          </div>
        </Grid>
        <Grid item xs={6}>
          <div className={sharedClasses.cardDetail}>
            <Typography data-testid='preliminary-end-title' variant='h6'>
              Contact Phone
            </Typography>
            <div className={sharedClasses.cardDetailData}>
              <ElliTooltip
                title={organizationData.phone_number as string}
                alwaysShowOnHover={false}
              >
                <Typography
                  classes={{
                    root: classes.emailTrim,
                  }}
                  data-testid='preliminary-end'
                  variant='body2'
                >
                  {organizationData.phone_number}
                </Typography>
              </ElliTooltip>
            </div>
          </div>
        </Grid>
        <Grid item xs={6}>
          <Typography data-testid='preliminary-end-title' variant='h6'>
            User&apos;s Role in Organization
          </Typography>
          <div className={sharedClasses.cardDetailData}>
            <Typography data-testid='preliminary-end' variant='body2'>
              {userRoles.length ? userRoles.join(', ') : 'User'}
            </Typography>
          </div>
        </Grid>
      </Grid>
      <Divider className={classes.divider} />
      <ActionBar>
        <ActionButtons
          accountCollectionFlag={accountsCollection}
          breadcrumbHandler={breadcrumbHandler}
          chargingSessions={chargingSessions}
          organization={organization}
          onSetOrganisationMembers={setOrganizationMembers}
        />
      </ActionBar>
    </CardElement>
  ) : null;
};

export default connector(OrganizationUI);
