import React from 'react';
import { Grid } from '@mui/material';
import connector, { IPropsFromState } from '../../../Connector/Connector';

import useStyles from './useStyles';
import { useTheme } from '@mui/styles';
import AppChargingReadinessDiagram from './ChargingDiagrams/AppChargingReadinessDiagram';
import CardChargingReadinessDiagram from './ChargingDiagrams/CardChargingReadinessDiagram';
import PlugAndChargeReadinessDiagram from './ChargingDiagrams/PlugAndChargeReadinessDiagram';
import { Typography } from '../../../shared/Typography/Typography';

export interface ICircleState {
  circleState: 'happy' | 'sad' | 'loading';
  testId: string;
  happyText: string;
  sadText: string;
}

type TOwnProps = IPropsFromState;

export const ChargingReadiness: React.FC<TOwnProps> = () => {
  const theme = useTheme();
  const classes = useStyles(theme);

  return (
    <div className={classes.chargingReadinessWrapper}>
      <div className={classes.closeIcon} />
      <div className={classes.headerWrapper}>
        <Typography
          variant='h1'
          classes={{
            root: classes.dialogTitle,
          }}
        >
          Readiness for Public Charging
        </Typography>
        <Typography
          variant='subtitle1'
          classes={{
            root: classes.dialogSubtitle,
          }}
        >
          Preconditions that need to be met before charging in public
        </Typography>
      </div>
      <Grid container columnSpacing={2} justifyItems='space-between'>
        <Grid container item xs={4}>
          <AppChargingReadinessDiagram />
        </Grid>
        <Grid container item xs={4}>
          <CardChargingReadinessDiagram />
        </Grid>
        <Grid container item xs={4}>
          <PlugAndChargeReadinessDiagram />
        </Grid>
      </Grid>
    </div>
  );
};

export default connector(ChargingReadiness);
