import React from 'react';
import { useTheme } from '@mui/material/styles';
import { PlugAndChargeState } from '../../../../types/plugAndCharge';
import {
  BasicStatus,
  IMappedStatus,
} from '../../../shared/StatusIndicator/BasicStatus';
import { assertNever } from '../../../../utils/assertNever';

export const PlugAndChargeStatus: React.FC<{
  status: PlugAndChargeState;
}> = ({ status }) => {
  const theme = useTheme();

  function valueMapper(value: PlugAndChargeState): IMappedStatus {
    switch (value) {
      case PlugAndChargeState.ENABLED:
        return { color: theme.colorAccent, label: 'Enabled' };
      case PlugAndChargeState.DISABLED:
        return { color: theme.surfaceTertiary, label: 'Disabled' };
      case PlugAndChargeState.NO_REQUESTS_FOUND:
        return { color: theme.surfaceTertiary, label: 'No requests found' };
      case PlugAndChargeState.PENDING_DISABLE:
        return { color: theme.colorAlertSecondary, label: 'Pending disable' };
      case PlugAndChargeState.PENDING_ENABLE:
        return { color: theme.colorAlertSecondary, label: 'Pending enable' };
      case PlugAndChargeState.ERROR:
        return { color: theme.colorAlertPrimary, label: 'Error' };
      default:
        return assertNever(value);
    }
  }

  return <BasicStatus status={status} valueMapper={valueMapper} />;
};
