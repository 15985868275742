import { Button, ProgressBar } from '@elli-eco/component-library';
import { CircularProgress, useTheme } from '@mui/material';
import React, { useEffect, useState } from 'react';
import {
  StopCommandStatus,
  SESSION_COMPLETION_TIMEOUT_S,
  isCommandInProgress,
  DismissCommandStatus,
} from './StopAndDismissSessionButtons';
import { useStopChargingSessionQuery } from '../../../state/queries/usePublicChargingSessions';
import { IPublicChargingSession } from '../../../types/publicCharging';
import { useStyles } from './useStyles';
import { PublicChargingService } from '../../../services/publicCharging';
import { SessionChangeConfirmationDialog } from './SessionChangeConfirmDialog';
import { SessionChangeErrorDialog } from './SessionChangeErrorDialog';

type TStopSessionButtonProps = {
  setStopCommandStatus: (x: StopCommandStatus) => void;
  startDismissConfirmDialog: () => void;
  stopCommandStatus: StopCommandStatus;
  dismissCommandStatus: DismissCommandStatus;
  userId: string;
  evseId: string;
  session: IPublicChargingSession;
};

const startProgressBarInterval: (
  setValue: React.Dispatch<React.SetStateAction<number>>,
  steps: number
) => () => void = (setValue, steps) => {
  setValue(0);
  const updateInterval = 500;
  const interval = setInterval(
    () =>
      setValue((currentValue) =>
        Math.min(100, currentValue + 100 / ((steps * 1000) / updateInterval))
      ),
    updateInterval
  );
  return () => clearInterval(interval);
};

export const StopSessionButton: React.FC<TStopSessionButtonProps> = ({
  setStopCommandStatus,
  startDismissConfirmDialog,
  stopCommandStatus,
  dismissCommandStatus,
  userId,
  evseId,
  session,
}) => {
  const theme = useTheme();
  const classes = useStyles(theme);

  const [errorMessage, setErrorMessage] = useState<string>('');
  const [confirmDialogOpen, setConfirmDialogOpen] = useState<boolean>(false);
  const [errorDialogOpen, setErrorDialogOpen] = useState<boolean>(false);
  const [currentStep, setCurrentStep] = useState<0 | 1 | 2>(0);
  const [waitProgress, setWaitProgress] = useState<number>(0);

  const {
    mutate: sendStopSessionCommand,
    status: stopMutationStatus,
    error: stopMutationError,
  } = useStopChargingSessionQuery(userId, evseId);

  const switchToDismissSessionFlow = () => {
    setErrorMessage('');
    startDismissConfirmDialog();
  };

  useEffect(() => {
    switch (stopMutationStatus) {
      case 'idle':
        setStopCommandStatus('idle');
        return;
      case 'success':
        return;
      case 'loading':
        setCurrentStep(0);
        setWaitProgress(0);
        setStopCommandStatus('sending');
        return;
      case 'error':
        setStopCommandStatus('error');
        setErrorMessage(
          `Error sending stop request to station: ${
            (stopMutationError as { message: string }).message
          }`
        );
        return;
      default:
        setStopCommandStatus('waiting');
    }
  }, [stopMutationStatus]);

  useEffect(() => {
    switch (stopCommandStatus) {
      case 'sending':
        setCurrentStep(1);
        return startProgressBarInterval(
          setWaitProgress,
          PublicChargingService.STOP_SESSION_TIMEOUT_S
        );
      case 'waiting':
        setCurrentStep(2);
        return startProgressBarInterval(
          setWaitProgress,
          SESSION_COMPLETION_TIMEOUT_S
        );
      default:
        return () => undefined;
    }
  }, [stopCommandStatus]);

  useEffect(() => {
    if (errorMessage === '') {
      setErrorDialogOpen(false);
    } else {
      setConfirmDialogOpen(false);
      setErrorDialogOpen(true);
    }
  }, [errorMessage]);

  useEffect(() => {
    if (
      (session.status === 'COMPLETED' ||
        session.status === 'COMPLETED_BY_REMOTE_STOP') &&
      (confirmDialogOpen || errorDialogOpen)
    ) {
      setConfirmDialogOpen(false);
      setErrorDialogOpen(false);
      setStopCommandStatus('success');
    }
  }, [session]);

  if (!['ACTIVE', 'PENDING', 'INVALID'].includes(session.status)) {
    return <></>;
  }

  const stepNames = ['', 'Send stop request', 'Wait for session to complete'];

  const stopSessionButton = () => (
    <Button
      variant='outlined'
      className={'stopButton'}
      isLoading={isCommandInProgress(stopCommandStatus)}
      disabled={isCommandInProgress(dismissCommandStatus)}
      onClick={() => setConfirmDialogOpen(true)}
    >
      Stop session
    </Button>
  );

  const confirmationDialog = () => (
    <SessionChangeConfirmationDialog
      open={confirmDialogOpen}
      session={session}
      dialogHeader={'Stop session'}
      upperMessage={'Are you sure you want to stop the following session?'}
      lowerMessage={
        'We will send a request to the CPO to stop the session remotely.'
      }
      noCallback={() => setConfirmDialogOpen(false)}
      yesCallback={sendStopSessionCommand}
      yesText={'Stop session'}
      yesButtonClassName={'stopButton'}
      noText={'Back'}
      loading={isCommandInProgress(stopCommandStatus)}
      loadingItem={
        <>
          <CircularProgress
            value={waitProgress}
            variant='determinate'
          ></CircularProgress>
          <ProgressBar
            className={classes.circularProgress}
            numberOfSteps={2}
            currentStep={currentStep}
          >
            <p>
              Step {currentStep} of 2:{' '}
              <span className={classes.highlight}>
                {stepNames[currentStep]}
              </span>
            </p>
          </ProgressBar>
        </>
      }
    ></SessionChangeConfirmationDialog>
  );

  const errorDialog = () => (
    <SessionChangeErrorDialog
      open={errorDialogOpen}
      dialogHeader={'Session could not be stopped'}
      errorMessage={errorMessage}
      additionalMessages={[
        "Maybe the user's car is still connected? In that case, ask the user to unplug the car and then try again.",
        'Otherwise, if there is no other option, you may want to dismiss the session instead.',
      ]}
      closeCallback={() => setErrorMessage('')}
      yesButton={
        <Button
          variant='filled'
          className={classes.dismissButton}
          onClick={switchToDismissSessionFlow}
        >
          Dismiss session
        </Button>
      }
    ></SessionChangeErrorDialog>
  );

  return (
    <>
      {stopSessionButton()}
      {confirmationDialog()}
      {errorDialog()}
    </>
  );
};
