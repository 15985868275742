import React from 'react';
import { TableContainer } from '@mui/material';
import { CardSubheadline } from '../../../shared/InformationItems/CardSubheadline';
import {
  AssetTable,
  IColumn,
  IRow,
} from '../../../shared/AssetTable/AssetTable/AssetTable';
import { mapFieldsForTable } from '../../../../utils/mapFieldsForTable';
import { fieldNames } from './TableConfig';
import useSharedStyles from '../../../shared/useSharedStyles';
import { formatIsoDates } from '../../../../utils/dates';
import decimalToComma from '../../../../utils/decimalToComma';
import { CouponStatus } from './CouponStatus';
import useStyles from './useStyles';
import { useGetCoupons } from '../../../../state/queries/useGetCoupons';

const setAmountWithCurrency = (amount: number, currency: string) => {
  return `${decimalToComma(amount.toFixed(2))} ${currency}`;
};

const getColumns = (): IColumn[] => {
  const sortableColumns = [
    'status',
    'voucher_code',
    'initial_value',
    'remaining_value',
    'reservation_date',
    'expiry_date',
  ];

  return mapFieldsForTable(fieldNames, sortableColumns);
};

interface ICouponSection {
  userId?: string;
}

export const CouponSection: React.FC<ICouponSection> = ({ userId }) => {
  const classes = useStyles();
  const sharedClasses = useSharedStyles();
  const { data, isError, isLoading } = useGetCoupons(userId);
  const vouchers = data?.vouchers;

  const getRows = (): IRow[] => {
    if (!(userId && vouchers) || isLoading || isError) {
      return [];
    }

    return vouchers.map((voucher) => ({
      id: voucher.code,
      data: {
        status: {
          content: <CouponStatus status={voucher.status} />,
        },
        voucher_code: {
          content: (
            <span
              className={sharedClasses.textOverflow}
              data-testid='voucher_code'
            >
              {voucher.code}
            </span>
          ),
        },
        initial_value: {
          content: (
            <span
              className={sharedClasses.textOverflow}
              data-testid='coupon-initial_value'
            >
              {setAmountWithCurrency(voucher.original_credit, voucher.currency)}
            </span>
          ),
        },
        remaining_value: {
          content: (
            <span
              className={sharedClasses.textOverflow}
              data-testid='coupon-remaining_value'
            >
              {setAmountWithCurrency(
                voucher.remaining_credit,
                voucher.currency
              )}
            </span>
          ),
        },
        reservation_date: {
          content: (
            <span
              className={sharedClasses.textOverflow}
              data-testid='coupon-reservation_date'
            >
              {voucher.reserved_at ? formatIsoDates(voucher.reserved_at) : '-'}
            </span>
          ),
        },
        expiry_date: {
          content: (
            <span
              className={sharedClasses.textOverflow}
              data-testid='coupon-expiry_date'
            >
              {voucher.expires_at ? formatIsoDates(voucher.expires_at) : '-'}
            </span>
          ),
        },
      },
    }));
  };

  return (
    <div data-testid='coupon-section'>
      <CardSubheadline title='Multi Purpose Voucher' />
      <TableContainer className={classes.tableContainer}>
        <AssetTable
          bulkActions={[]}
          columns={getColumns()}
          rows={getRows()}
          sortRequestHandler={(_x) => {
            return;
          }}
        />
      </TableContainer>
    </div>
  );
};
