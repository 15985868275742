import React from 'react';

import { IOwnProps as ICopyTextField } from '../ui/CopyTextField';
import CopyTextFieldList from '../ui/CopyTextField/CopyTextFieldList';

export const generateTooltipData = (
  rfidUid: string,
  cardNumber?: string,
  tag?: string
): ICopyTextField[] => {
  const tooltipData: ICopyTextField[] = [];

  tooltipData.push({
    title: 'RFID uid',
    text: rfidUid,
  });

  if (cardNumber) {
    tooltipData.push({
      title: 'Card number',
      text: cardNumber,
    });
  }

  if (tag) {
    tooltipData.push({
      title: 'RFID tag',
      text: tag,
    });
  }

  return tooltipData;
};

export interface IOwnProps {
  rfidUid: string;
  cardNumber?: string;
  tag?: string;
}

export const DialogChargingCard: React.FC<IOwnProps> = ({
  rfidUid,
  cardNumber,
  tag,
}) => {
  const tooltipData = generateTooltipData(rfidUid, cardNumber, tag);

  return <CopyTextFieldList textList={tooltipData} />;
};
