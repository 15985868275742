interface ITenantData {
  id: string;
  name: string;
  environment: 'Prod' | 'Sandbox';
}

const tenantData: ITenantData[] = [
  {
    id: 'Ee4qUdsuePsfg3wj1NuL',
    name: 'Volkswagen',
    environment: 'Sandbox',
  },
  {
    id: 'ursWWtYQQ0QQahA0DFNv',
    name: 'Volkswagen',
    environment: 'Prod',
  },
  {
    id: 'FUt6cGL7Muvu9aVRZDLL',
    name: 'Elli',
    environment: 'Sandbox',
  },
  {
    id: 'BMJ7MQCdw1ChOg5tFmEJ',
    name: 'Elli',
    environment: 'Prod',
  },
  {
    id: 'LgWQZjLJPb7lNaIwnPKQ',
    name: 'Seat',
    environment: 'Sandbox',
  },
  {
    id: 'JMJn0x1539PRL3Biefz3',
    name: 'Seat',
    environment: 'Prod',
  },
  {
    id: 'eSMMKx84NriRMEpCapIu',
    name: 'WeCharge',
    environment: 'Sandbox',
  },
  {
    id: 'pkEp7dW3rzepQWc40Tg0',
    name: 'WeCharge',
    environment: 'Prod',
  },
  {
    id: '6vAHOeO0oWltnhNKD1c2',
    name: 'Skoda',
    environment: 'Sandbox',
  },
  {
    id: 'waZpfLWcTLPVMDg3WlwP',
    name: 'Skoda',
    environment: 'Prod',
  },
  {
    id: 'RTZvHc8TVqwlQNRv88PK',
    name: 'Audi',
    environment: 'Sandbox',
  },
  {
    id: 'ZU3md13yy6iD13N2gjVs',
    name: 'Audi',
    environment: 'Prod',
  },
  {
    id: 'WGNfWx72kgVRpBXou1th',
    name: 'Sixt',
    environment: 'Sandbox',
  },
  {
    id: '27GjIbsVQgUxaEWFSNpv',
    name: 'Sixt',
    environment: 'Prod',
  },
];

export const getDealershipName = (tenantId?: string): string | undefined => {
  return tenantData.find((tenant) => tenant.id === tenantId)?.name;
};

export const getTenantIdForBrand = (
  brand: string,
  isProduction: boolean
): string | undefined => {
  return tenantData.find(
    (tenant) =>
      tenant.name.toUpperCase() === brand.toUpperCase() &&
      tenant.environment === (isProduction ? 'Prod' : 'Sandbox')
  )?.id;
};
