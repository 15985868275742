import update from 'immutability-helper';
import { omit } from 'lodash';
import { initialState, State, VehicleStateModel } from '../';
import { ActionInterface } from '../actions';

export default (
  state: State,
  action: ActionInterface.GetVehicleRequest
): State => {
  const vehicle = state || {
    ...initialState,
  };

  const updatedVehicle: VehicleStateModel = update(vehicle, {
    error: {
      $set: null,
    },
    loading: {
      $set: true,
    },
    request: {
      $set: omit(action, ['type']),
    },
  });

  return {
    ...state,
    ...updatedVehicle,
  };
};
