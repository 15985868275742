import ApiService from './api';
import { AxiosResponse } from 'axios';
import { IInvoice, IInvoiceListItem } from '../types/invoice';
import { PaymentStatus } from '../types/payment';

const getSubscriberInvoices = async (
  subscriberId: string
): Promise<IInvoiceListItem[]> => {
  const apiService = new ApiService(
    `/mgmt/msp-billing/v2/subscriber/${subscriberId}/invoices`
  );
  const response = await apiService.query();
  return response.data;
};

const getInvoiceById = async (
  subscriberId: string,
  invoiceId: string
): Promise<IInvoice> => {
  const apiService = new ApiService(
    `/mgmt/msp-billing/v2/subscriber/${subscriberId}/invoices/${invoiceId}`
  );
  const response = await apiService.query();
  return response.data;
};

const getInvoicePdf = async (
  subscriberId: string,
  invoiceId: string
): Promise<AxiosResponse<Blob>> => {
  const apiService = new ApiService(
    `/mgmt/msp-billing/v2/subscriber/${subscriberId}/invoices/${invoiceId}/pdf`,
    {
      responseType: 'blob',
    }
  );
  return await apiService.query();
};

const getInvoicePaymentStatus = async (
  invoiceNumber: string
): Promise<PaymentStatus> => {
  const apiService = new ApiService(
    `/mgmt/msp-payment/v1/payment/invoice/${invoiceNumber}/status`
  );
  const response = await apiService.query();
  return response.data.status;
};

export const InvoiceService = {
  getInvoiceById,
  getInvoicePdf,
  getSubscriberInvoices,
  getInvoicePaymentStatus,
};
