import React from 'react';
import { IConnectorOverview } from '../../../../utils/getConnectorOverview';
import chademo from '../../../../assets/image/connectorTypes/chademo.svg';
import combo from '../../../../assets/image/connectorTypes/combo.svg';
import type2 from '../../../../assets/image/connectorTypes/type2.svg';
import unknown from '../../../../assets/image/connectorTypes/Connector_Unknown.svg';
import useStyles from './useStyles';

export type TConnectorType =
  | 'IEC_62196_T2'
  | 'IEC_62196_T2_COMBO'
  | 'CHADEMO'
  | 'UNKNOWN';

export const connectorTypeMap = {
  IEC_62196_T2: { image: type2, name: 'Type 2' },
  IEC_62196_T2_COMBO: { image: combo, name: 'Combo' },
  CHADEMO: { image: chademo, name: 'ChAdeMo' },
  UNKNOWN: { image: unknown, name: 'Unknown' },
};

export type TPowerType = 'AC_1_PHASE' | 'AC_3_PHASE' | 'DC';

export const powerTypeMap = {
  AC_1_PHASE: 'AC 1 Phase',
  AC_3_PHASE: 'AC 3 Phase',
  DC: 'DC',
};

export const getPowerType = (type: TPowerType | undefined): string => {
  if (type) {
    return powerTypeMap[type];
  }

  return '';
};

interface IStyles {
  icon: {
    [key: string]: string | number;
  };
  text: {
    [key: string]: string | number;
  };
}

interface IOwnProps {
  connectorOverview: IConnectorOverview | null;
  styles?: IStyles;
}

export const PowerConnector: React.FC<IOwnProps> = ({
  connectorOverview,
  styles,
}) => {
  if (!connectorOverview || !connectorOverview.type) {
    return null;
  }

  const classes = useStyles();

  const type = connectorOverview.type || 'UNKNOWN';

  const typeIcon = connectorTypeMap[type];
  const connectorText = `max. ${connectorOverview.power} kW (${getPowerType(
    connectorOverview.powerType as TPowerType | undefined
  )})`;

  const iconStyles = styles ? styles.icon : {};
  const textStyles = styles ? styles.text : {};

  return (
    <div className={classes.typeWrapper}>
      <img
        className={classes.connectorLogo}
        src={typeIcon.image}
        alt='connector type'
        style={{
          ...iconStyles,
        }}
      />
      <div className={classes.connectorTitle} style={{ ...textStyles }}>
        {connectorText}
      </div>
    </div>
  );
};

export default PowerConnector;
