import HomeCharger, {
  CHARGER_MAP,
  TValidChargers,
} from '../ActionCards/HomeCharger';
import React, { useEffect, useState } from 'react';
import connector, { IPropsFromState } from '../Connector/Connector';

import ActionCardElement from '../shared/ActionCardElement/ActionCardElement';
import ActiveSession from '../ActionCards/ActiveSession';
import ActivePublicChargingSession from '../ActionCards/ActivePublicChargingSession';
import ChargingCards from '../ChargingCards/ChargingCards';
import ChargingCardsImage from '../../assets/image/chargingCards.svg';
import ChargingStationIcon from '../../assets/image/charging_station.svg';
import { IHomeStation } from '../../types/user';
import { IInvoiceListItem } from '../../types/invoice';
import Invoice from '../ActionCards/Invoice';
import PublicOutlinedIcon from '@mui/icons-material/PublicOutlined';
import HomeOutlinedIcon from '@mui/icons-material/HomeOutlined';
import ReceiptIcon from '@mui/icons-material/Receipt';
import { lexicographical } from '../../utils/comparisonFunctions';
import useSharedStyles from '../shared/useSharedStyles';
import { useTypedTranslation } from '../../custom-hooks/useTypedTranslation';
import { usePublicChargingSessionsQuery } from '../../state/queries/usePublicChargingSessions';

export type TOwnProps = IPropsFromState;

const sortByCreation = (a: IInvoiceListItem, b: IInvoiceListItem): number =>
  lexicographical(
    new Date(a.invoice_date).getTime(),
    new Date(b.invoice_date).getTime()
  );

export const ActionPanel: React.FC<TOwnProps> = ({
  activeSessionsState,
  userState,
  homeStationsState,
  invoicesState,
}) => {
  const sharedClasses = useSharedStyles();
  const activeSessions = activeSessionsState;
  const taskHomeStations = homeStationsState;
  const user = userState;
  const taskInvoices = invoicesState;

  const [userId, setUserId] = useState<string>('');
  const [homeStations, setHomeStations] = useState<IHomeStation[]>([]);
  const [homeStationTitle, setHomeStationTitle] = useState<string>('');
  const [latestInvoice, setLatestInvoice] = useState<IInvoiceListItem | null>(
    null
  );
  const { t, terms } = useTypedTranslation();
  const {
    data: publicChargingSessions,
    isSuccess: publicChargingSessionsQuerySuccess,
  } = usePublicChargingSessionsQuery(userId);

  const hasPublicChargingSessions = Boolean(
    publicChargingSessionsQuerySuccess &&
      publicChargingSessions[0] !== undefined
  );

  const hasSessions = Boolean(
    activeSessions &&
      Array.isArray(activeSessions.data) &&
      activeSessions.data.length
  );

  const hasHomeStations = Boolean(homeStations && homeStations.length);
  const hasInvoices = Boolean(latestInvoice);

  useEffect(() => {
    if (!taskInvoices?.data) {
      return;
    }

    const invoices = [...taskInvoices.data];
    invoices.sort(sortByCreation);
    setLatestInvoice(invoices[invoices.length - 1]);
  }, [taskInvoices]);

  useEffect(() => {
    if (user.data) {
      setUserId(user.data.id);
    }
  }, [user]);

  useEffect(() => {
    if (taskHomeStations && taskHomeStations.stations) {
      const filtered = taskHomeStations.stations.filter(
        (hs) => hs.model && Object.keys(CHARGER_MAP).includes(hs.model)
      );

      setHomeStations(filtered);

      if (filtered.length) {
        setHomeStationTitle(CHARGER_MAP[filtered[0].model as TValidChargers]);
      }
    }
  }, [taskHomeStations]);

  return (
    <div className={sharedClasses.actionWrapper}>
      <ActionCardElement
        title={t(terms.action_cards.rfid_cards.headline)}
        Icon={<img src={ChargingCardsImage} alt='charging cards' />}
      >
        <ChargingCards />
      </ActionCardElement>

      {hasHomeStations && (
        <ActionCardElement
          title={homeStationTitle}
          Icon={<img src={ChargingStationIcon} alt='Charging Station' />}
        >
          <HomeCharger
            homeStations={homeStations}
            setHomeStationTitle={setHomeStationTitle}
          />
        </ActionCardElement>
      )}

      {hasInvoices && (
        <ActionCardElement
          title={t(terms.action_cards.invoices.headline)}
          Icon={
            <ReceiptIcon
              style={{
                color: 'rgb(102, 51, 204)',
              }}
            />
          }
        >
          <Invoice invoice={latestInvoice} />
        </ActionCardElement>
      )}

      {hasPublicChargingSessions && (
        <ActionCardElement
          title={t(terms.action_cards.ca_sessions.headline)}
          Icon={
            <PublicOutlinedIcon
              style={{
                color: 'rgb(102, 51, 204)',
              }}
            />
          }
        >
          <ActivePublicChargingSession
            userId={userId}
          ></ActivePublicChargingSession>
        </ActionCardElement>
      )}

      {hasSessions &&
        activeSessions.data?.map((session, index) => (
          <ActionCardElement
            key={session.id}
            startCollapse={index > 0}
            title={t(terms.action_cards.sessions.headline)}
            Icon={
              <HomeOutlinedIcon
                style={{
                  color: 'rgb(102, 51, 204)',
                }}
              />
            }
          >
            <ActiveSession
              session={session}
              loading={activeSessions.loading}
              userId={userId}
            />
          </ActionCardElement>
        ))}
    </div>
  );
};

export default connector(ActionPanel);
