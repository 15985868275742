import {
  IChargingRecord,
  IChargingRecordViewModel,
} from '../../../types/chargingRecords';

/* eslint-disable @typescript-eslint/naming-convention */
import { ErrorObject } from 'serialize-error';
import { IChargingSessionModel } from '../../../types/chargingSession';

export interface GetChargingSessionsRequest {
  subscriber_id: string;
}

export interface ChargingSessionsStateModel {
  loading: boolean;
  data: IChargingRecordViewModel[] | null;
  error: ErrorObject | null;
  request: { subscriber_id: string } | null;
  public: IChargingSessionModel[] | null;
  private: IChargingRecord[] | null;
  organization: IChargingRecord[] | null;
}

export const initialState: ChargingSessionsStateModel = {
  loading: false,
  data: [],
  error: null,
  request: null,
  public: null,
  private: null,
  organization: null,
};

const state: ChargingSessionsStateModel = {
  ...initialState,
};

export type State = typeof state;
export default state;
