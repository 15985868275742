import { Theme } from '@mui/material/styles';
import createStyles from '@mui/styles/createStyles';
import makeStyles from '@mui/styles/makeStyles';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    errorContainer: {
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      textAlign: 'center',
      minHeight: 'inherit',
      justifyContent: 'center',
    },
    errorTextBox: { marginTop: '16px' },
    errorTextElement: { maxWidth: '500px' },
    errorTextHeadline: { marginBottom: '16px', maxWidth: '500px' },
    loadingContainer: {
      display: 'flex',
      justifyContent: 'center',
      aligntItems: 'center',
      minHeight: 'inherit',
    },
    loadingItem: {
      alignSelf: 'center',
      color: theme.colorAccent,
    },
    tableDescription: {
      color: theme.textSecondary,
      width: '300px',
    },
    tableAlignLeft: {
      textAlign: 'left',
    },
    tableAlignRight: {
      textAlign: 'right',
    },
    contentContainer: {
      minHeight: 'inherit',
    },
  })
);

export default useStyles;
