import React, { useEffect, useState } from 'react';

import {
  AssetTable,
  BulkAction,
  IColumn,
  IRow,
  ISortState,
} from '../../AssetTable/AssetTable/AssetTable';
import Pagination from '../../Pagination/Pagination';
import { paginateArray } from '../../../../utils/paginateArray';
import useSharedStyles from '../../../shared/useSharedStyles';
import useStyles from './useStyles';

const bulkActions: BulkAction[] = [];

export interface IOwnProps {
  headline: React.ReactNode;
  columns: IColumn[];
  rows: IRow[];
  sortHandler: (columnKey: string) => void;
  sortState: ISortState;
  rowTypePlural: string;
}

export const PaginatedCollection: React.FC<IOwnProps> = ({
  headline,
  columns,
  rows,
  sortHandler,
  sortState,
  rowTypePlural,
}) => {
  const classes = useStyles();
  const sharedClasses = useSharedStyles();
  const [currentRows, setCurrentRows] = useState<IRow[]>([]);
  const [itemsPerPage, setItemsPerPage] = useState<number>(10);
  const [currentPage, setCurrentPage] = useState<number>(1);
  const [pagesLength, setPagesLength] = useState<number>(0);
  const [paginatedRows, setPaginatedRows] = useState<IRow[][]>([[]]);

  const sortRequestHandler = (columnKey: string | null): void => {
    if (columnKey) {
      sortHandler(columnKey);
    }
  };

  useEffect(() => {
    setCurrentRows(paginatedRows[currentPage - 1] || []);
  }, [paginatedRows, currentPage]);

  useEffect(() => {
    const pagination = paginateArray(rows, itemsPerPage);
    setPaginatedRows(pagination);
    setPagesLength(pagination.length);
  }, [rows, itemsPerPage]);

  return (
    <div className={sharedClasses.cardElement}>
      <div className={classes.headline}>{headline}</div>
      <AssetTable
        bulkActions={bulkActions}
        columns={columns}
        rows={currentRows}
        sortingState={sortState}
        sortRequestHandler={sortRequestHandler}
        noDataTitle={`No ${rowTypePlural} available`}
        noDataSubTitle={`Account does not yet have any ${rowTypePlural}.`}
      />
      <Pagination
        itemsPerPage={itemsPerPage}
        setItemsPerPage={setItemsPerPage}
        currentPage={currentPage}
        setCurrentPage={setCurrentPage}
        pagesLength={pagesLength}
        itemSelectText={`${rowTypePlural} per page`}
      />
    </div>
  );
};

export default PaginatedCollection;
