import { ActionInterface, ActionType } from '../actions';
import initialState, { State } from '..';

import getChargingCardError from './getChargingCardError';
import getChargingCardRequest from './getChargingCardRequest';
import getChargingCardSuccess from './getChargingCardSuccess';
import setCurrentCard from './setCurrentCard';
import sortChargingCards from './sortChargingCards';

const reducer = (state: State = initialState, action: any) => {
  switch (action.type) {
    case ActionType.GetChargingCardRequest:
      return getChargingCardRequest(
        state,
        action as ActionInterface.GetChargingCardRequest
      );
    case ActionType.GetChargingCardSuccess:
      return getChargingCardSuccess(
        state,
        action as ActionInterface.GetChargingCardSuccess
      );
    case ActionType.GetChargingCardError:
      return getChargingCardError(
        state,
        action as ActionInterface.GetChargingCardError
      );
    case ActionType.SetCurrentCard:
      return setCurrentCard(state, action as ActionInterface.SetCurrentCard);
    case ActionType.SortChargingCards:
      return sortChargingCards(
        state,
        action as ActionInterface.SortChargingCards
      );
    default:
      return state;
  }
};

export default reducer;
