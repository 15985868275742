import { Grid } from '@mui/material';
import React, { useState } from 'react';

import { AccessModeDialog } from '../../../OverviewCards/HomeCharging/AccessModeDialog/AccessModeDialog';
import { ElliTooltip } from '../../../shared/ElliTooltip/ElliTooltip';
import { IChargingRecordViewModel } from '../../../../types/chargingRecords';
import { IConnectorOverview } from '../../../../utils/getConnectorOverview';
import IconButton from '@mui/material/IconButton';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import PowerConnector from '../PowerConnector/PowerConnector';
import WarningIcon from '@mui/icons-material/Warning';
import decimalToComma from '../../../../utils/decimalToComma';
import { formatIsoDates } from '../../../../utils/dates';
import parseSeconds from '../../../../utils/parseSeconds';
import useSharedStyles from '../../../shared/useSharedStyles';
import useStyles from './useStyles';
import { Typography } from '../../../shared/Typography/Typography';

interface IOwnProps {
  chargingSession: IChargingRecordViewModel;
  connectorOverview: IConnectorOverview | null;
}

const Overview: React.FC<IOwnProps> = ({
  chargingSession,
  connectorOverview,
}) => {
  const sharedClasses = useSharedStyles();
  const classes = useStyles();
  const [dialogOpen, setDialogOpen] = useState<boolean>(false);

  return (
    <>
      <Grid item xs={12}>
        <div className={sharedClasses.cardDetail}>
          <Typography variant='h4'>
            <span data-testid='private-overview-title'>Overview</span>
          </Typography>
        </div>
      </Grid>
      <Grid item xs={4}>
        <div className={sharedClasses.cardDetail}>
          <Typography variant='h6'>Session Start</Typography>
          <div className={sharedClasses.cardDetailData}>
            <Typography variant='body2'>
              {formatIsoDates(chargingSession.start_date_time)}
            </Typography>
          </div>
        </div>
      </Grid>
      <Grid item xs={4}>
        <div className={sharedClasses.cardDetail}>
          <Typography variant='h6'>Session End</Typography>
          <div className={sharedClasses.cardDetailData}>
            {chargingSession.invalid_duration && (
              <ElliTooltip
                alwaysShowOnHover={true}
                title='Impossible session end timestamp'
              >
                <WarningIcon className={classes.warningIcon} />
              </ElliTooltip>
            )}
            <Typography variant='body2'>
              {chargingSession.stop_date_time
                ? formatIsoDates(chargingSession.stop_date_time)
                : '-'}
            </Typography>
          </div>
        </div>
      </Grid>
      <Grid item xs={4}>
        <div className={sharedClasses.cardDetail}>
          <Typography variant='h6'>Session Duration</Typography>
          <div className={sharedClasses.cardDetailData}>
            <Typography variant='body2'>
              {chargingSession.invalid_duration ||
              !chargingSession.stop_date_time
                ? '-'
                : parseSeconds(chargingSession.duration)}
            </Typography>
          </div>
        </div>
      </Grid>
      <Grid item xs={4}>
        <div className={sharedClasses.cardDetail}>
          <Typography variant='h6'>Energy Consumption</Typography>
          <div className={classes.addressElement}>
            <Typography variant='body2'>
              {chargingSession.consumption
                ? `${decimalToComma(
                    chargingSession.consumption.toFixed(3)
                  )} kWh`
                : '-'}
            </Typography>
          </div>
        </div>
      </Grid>
      <Grid item xs={4}>
        <div className={sharedClasses.cardDetail}>
          <Typography variant='h6'>
            Access Mode
            <IconButton
              data-testid='more-info-button'
              onClick={() => setDialogOpen((prevState) => !prevState)}
              className={classes.infoIcon}
              disableRipple
              disableFocusRipple
              size='large'
            >
              <InfoOutlinedIcon />
            </IconButton>
          </Typography>
          <AccessModeDialog
            open={dialogOpen}
            onClose={() => setDialogOpen(false)}
          />
          <div className={sharedClasses.cardDetailData}>
            <Typography variant='body2'>
              {chargingSession.authorization_mode || '-'}
            </Typography>
          </div>
        </div>
      </Grid>
      <Grid item xs={4}>
        <div className={sharedClasses.cardDetail}>
          <Typography variant='h6'>Connector Info</Typography>
          <div className={classes.addressElement}>
            <Typography variant='body2'>
              <PowerConnector connectorOverview={connectorOverview} />
            </Typography>
          </div>
        </div>
      </Grid>
    </>
  );
};

export default Overview;
