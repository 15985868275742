import React from 'react';
import { Table, TableBody, TableCell, TableRow } from '@mui/material';
import { formatDates, formatIsoDates } from '../../../utils/dates';

import { ISubscriptionModel } from '../../../types/subscriber';
import useSharedStyles from '../../shared/useSharedStyles';
import useStyles from './useStyles';
import { Typography } from '../../shared/Typography/Typography';

export interface ISubscriptionTableProps {
  subscriptionData: ISubscriptionModel[];
}

export const SubscriptionTable: React.FC<ISubscriptionTableProps> = (props) => {
  const classes = useStyles();
  const sharedClasses = useSharedStyles();
  const { subscriptionData } = props;

  return (
    <div className={sharedClasses.scrollingDiv}>
      {subscriptionData
        ? subscriptionData.map((sub) => (
            <div key={sub.id}>
              <Table className='MuiTable-root' size='small'>
                <TableBody>
                  <TableRow>
                    <TableCell className={classes.rowTitleCell}>
                      <Typography variant='h6'>Subscription ID</Typography>
                    </TableCell>
                    <TableCell align='left'>
                      <Typography variant='body1'>{sub.id}</Typography>
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell className={classes.rowTitleCell}>
                      <Typography variant='h6'>Status</Typography>
                    </TableCell>
                    <TableCell align='left'>
                      <Typography variant='body1'>{sub.status}</Typography>
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell className={classes.rowTitleCell}>
                      <Typography variant='h6'>Start date</Typography>
                    </TableCell>
                    <TableCell align='left'>
                      <Typography variant='body1'>
                        {sub.start_date && formatIsoDates(sub.start_date)}
                      </Typography>
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell className={classes.rowTitleCell}>
                      <Typography variant='h6'>Preliminary end</Typography>
                    </TableCell>
                    <TableCell align='left'>
                      <Typography variant='body1'>
                        {sub.end_date && formatIsoDates(sub.end_date)}
                      </Typography>
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell className={classes.rowTitleCell}>
                      <Typography variant='h6'>Automatic renewal</Typography>
                    </TableCell>
                    <TableCell align='left'>
                      <Typography variant='body1'>
                        {sub.automatic_renewal ? 'yes' : 'no'}
                      </Typography>
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell className={classes.rowTitleCell}>
                      <Typography variant='h6'>Cancellation period</Typography>
                    </TableCell>
                    <TableCell align='left'>
                      <Typography variant='body1'>
                        {sub.expiration_reminder_sent_at &&
                          sub.notice_period_end_date &&
                          `${formatDates(
                            sub.expiration_reminder_sent_at
                          )} / ${formatDates(sub.notice_period_end_date)}`}
                      </Typography>
                    </TableCell>
                  </TableRow>
                </TableBody>
              </Table>
            </div>
          ))
        : null}
    </div>
  );
};
