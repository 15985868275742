import { ActiveSessionsStateModel, State } from '..';

import { ActionInterface } from '../actions';
import { omit } from 'lodash';
import update from 'immutability-helper';

export default (
  state: State,
  action: ActionInterface.GetActiveSessionsRequest
): State => {
  const activeSessionsState = state || {
    loading: true,
    data: [],
    error: null,
    request: null,
  };

  const updatedState: ActiveSessionsStateModel = update(activeSessionsState, {
    error: {
      $set: null,
    },
    loading: {
      $set: true,
    },
    request: {
      $set: omit(action, ['type']),
    },
  });

  return {
    ...state,
    ...updatedState,
  };
};
