import React, { useState } from 'react';
import { useTypedTranslation } from '../../../custom-hooks/useTypedTranslation';
import { ISubscriberModel } from '../../../types/subscriber';
import ContactDetailsIcon from '../../../assets/image/contactDetails.svg';
import connector, { IPropsFromState } from '../../Connector/Connector';
import SuccessMessage from '../../shared/Dialogs/SuccessMessage';
import ChangeBillingForm from './ChangeBillingForm/ChangeBillingForm';

const ChangeBillingModal: React.FC<IPropsFromState> = ({
  subscriberState,
  updateBillingAddress,
}) => {
  const { t, terms } = useTypedTranslation();
  const { data, error } = subscriberState;
  const { address, id, tax_number } = data as ISubscriberModel;
  const [isSuccess, setIsSuccess] = useState<boolean>(false);
  const handleSuccess = () => setIsSuccess(true);

  if (isSuccess) {
    return (
      <SuccessMessage
        headline={t(terms.update_billing_address.success.headline)}
        subHeadline={t(terms.update_billing_address.success.sub_headline)}
        icon={ContactDetailsIcon}
        iconAltText={t(terms.update_billing_address.success.headline)}
      />
    );
  }

  return (
    <ChangeBillingForm
      subscriberAddress={address}
      subscriberTaxNumber={tax_number}
      subscriberId={id}
      onSuccess={handleSuccess}
      submitError={!!error}
      updateBillingAddress={updateBillingAddress}
    />
  );
};

export default connector(ChangeBillingModal);
