import { useQuery, UseQueryResult } from 'react-query';
import {
  isValidWallboxSerialNumber,
  StationService,
} from '../../services/stations';
import {
  ICsmsChargingStation,
  IHomeChargingStationMetaData,
  MGWBDeviceMetaData,
} from '../../types/user';
import { getStationModel } from '../../utils/stationUtils';

const mapQueryResultsToDomain = (
  metaData: MGWBDeviceMetaData,
  station: ICsmsChargingStation
): IHomeChargingStationMetaData => ({
  model: getStationModel(station.model, station.model_variant),
  serialNumber: station.station_serial_number,
  pairingCode: station.pairing_code,
  wifiHotSpotSSID: metaData.device_metadata?.custom?.WifiInstallerApSsid,
  wifiHotSpotPassword:
    metaData.device_metadata?.custom?.WifiInstallerApPassword,
  networkHostName: metaData.device_metadata?.custom?.ComBoardHostName,
  configurationIpAdress:
    station.model === 'EVBOX_MGWB' ? '192.168.123.4' : undefined,
  configurationIpPassword: metaData.device_metadata?.custom?.WebConfigPassword,
  pukCode: metaData.device_metadata?.custom?.PukCode,
});

export const useWallboxConfigDataQuery = (
  serialNumber: string
): UseQueryResult<IHomeChargingStationMetaData | undefined> => {
  return useQuery(
    ['wallboxConfigData', serialNumber],
    async () => {
      const data = await StationService.getStationsBySerialNumber(
        serialNumber,
        {
          limit: 1,
        }
      );

      if (
        data.stations.length === 0 ||
        serialNumber !== data.stations[0].station_serial_number?.toUpperCase()
      ) {
        return undefined;
      }

      const station = await StationService.getCsmsStationById(
        data.stations[0].id
      );
      const metaData = await StationService.getStationMetaData(station.id);

      return mapQueryResultsToDomain(metaData, station);
    },
    {
      enabled: serialNumber !== '' && isValidWallboxSerialNumber(serialNumber),
    }
  );
};
