import {
  parse,
  isBefore,
  isAfter,
  addSeconds,
  addDays,
  subSeconds,
} from 'date-fns';

/*
 * Currently, we only get applicable blocking period from the backend.
 * Since, in the UI we want to show not-applicable blocking period as well,
 * we have to calculate them manually.
 */
export const getBlockingIntervals = (
  sessionStart: Date,
  sessionEnd: Date,
  applicationStartTime: string,
  applicationEndTime: string
) => {
  const isBillableAllDay = applicationStartTime === applicationEndTime;

  if (isBillableAllDay) {
    return [{ label: 'applicable', start: sessionStart, end: sessionEnd }];
  }
  let billableStartTime = parse(applicationStartTime, 'HH:mm:ss', sessionStart);
  let billableEndTime = parse(applicationEndTime, 'HH:mm:ss', sessionStart);

  const intervals: {
    label: 'applicable' | 'not applicable';
    start: Date;
    end: Date;
  }[] = [];

  // Start of charging session
  let currentStart = sessionStart;

  // Loop until the end of the charging session
  while (isBefore(currentStart, sessionEnd)) {
    // Check if within billable interval
    if (
      isAfter(currentStart, billableStartTime) &&
      isBefore(currentStart, billableEndTime)
    ) {
      intervals.push({
        start: intervals.length ? subSeconds(currentStart, 1) : currentStart,
        end: isBefore(sessionEnd, billableEndTime)
          ? sessionEnd
          : billableEndTime,
        label: 'applicable',
      });
      currentStart = addSeconds(billableEndTime, 1);
    } else {
      let nextBillableStart;

      if (isBefore(currentStart, billableStartTime)) {
        nextBillableStart = billableStartTime;
      } else if (isAfter(currentStart, billableEndTime)) {
        billableStartTime = addDays(billableStartTime, 1);
        billableEndTime = addDays(billableEndTime, 1);
        nextBillableStart = billableStartTime;
      } else {
        nextBillableStart = billableEndTime;
      }

      intervals.push({
        start: intervals.length ? subSeconds(currentStart, 1) : currentStart,
        end: isBefore(sessionEnd, nextBillableStart)
          ? sessionEnd
          : nextBillableStart,
        label: 'not applicable',
      });
      currentStart = addSeconds(nextBillableStart, 1);
    }
  }

  return intervals;
};
