import React, { useEffect, useState } from 'react';
import { useTypedTranslation } from '../../../custom-hooks/useTypedTranslation';
import connector, { IPropsFromState } from '../../Connector/Connector';
import MultilineButton from '../../shared/Buttons/MultilineButton';
import { SearchBar } from '../../shared/SearchBar/SearchBar';
import { AvailableBrands } from '../VinEligibility';
import useStyles from './useStyles';

type TOwnProps = IPropsFromState & {
  selectedBrand: AvailableBrands;
};

export const VinSearch: React.FC<TOwnProps> = ({
  selectedBrand,
  vehicleState,
  getVehicleRequest,
  clearVehicle,
}) => {
  const vehicle = vehicleState;
  const isLoading = vehicle && vehicle.loading;
  const [searchInput, setSearchInput] = useState('');
  const [inputError, setInputError] = useState(false);
  const classes = useStyles();
  const [helperText, setHelperText] = useState('');
  const { t, terms } = useTypedTranslation();

  const searchInputBrandIndication = () => {
    if (searchInput.startsWith('VSS')) {
      return AvailableBrands.SEAT;
    }
    if (searchInput.startsWith('TMB')) {
      return AvailableBrands.SKODA;
    }
    return null;
  };

  useEffect(() => {
    switch (searchInputBrandIndication()) {
      case AvailableBrands.SEAT:
        setHelperText(
          selectedBrand === AvailableBrands.SEAT
            ? ''
            : t(terms.vin_eligibility.vin_search.warning_text_seat)
        );
        break;
      case AvailableBrands.SKODA:
        setHelperText(
          selectedBrand === AvailableBrands.SKODA
            ? ''
            : t(terms.vin_eligibility.vin_search.warning_text_skoda)
        );
        break;
      default:
        setHelperText('');
        break;
    }
  });

  useEffect(() => {
    if (vehicle && vehicle.error) {
      setHelperText(t(terms.vin_eligibility.vin_search.error));
    }
    if (inputError) {
      setHelperText(t(terms.vin_eligibility.vin_search.not_vin));
    }
  });

  const handleSearch = () => {
    if (
      searchInput &&
      (searchInput !== vehicle.data?.vin ||
        selectedBrand !== vehicle.data?.brand)
    ) {
      clearVehicle();
      getVehicleRequest({
        vin: searchInput.trim(),
        brand: selectedBrand,
      });
    }
  };

  const handleInputChange = (input: string) => {
    setInputError(!/[A-HJ-NPR-Z0-9]{11,17}/.test(input));
    setSearchInput(input.toUpperCase());
  };

  return (
    <>
      <SearchBar
        searchInput={searchInput}
        setSearchInput={handleInputChange}
        handleSearch={handleSearch}
        helperText={helperText}
        hasError={inputError || (vehicle && !!vehicle.error)}
      />
      <div className={classes.buttonBox}>
        <MultilineButton
          handleClick={handleSearch}
          mainText='Search'
          inProgress={isLoading}
          isDisabled={inputError}
        />
      </div>
    </>
  );
};

export default connector(VinSearch);
