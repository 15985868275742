import React from 'react';
import { useTheme } from '@mui/material';
import { BodySmallLink, Title1 } from '@elli-eco/component-library';
import useStyles from './useStyles';
import ErrorIcon from '../../assets/image/error.png';

export const ErrorFallback = () => {
  const theme = useTheme();
  const classes = useStyles(theme);

  const refreshPage = () => {
    window.location.reload();
  };

  return (
    <div className={classes.errorBoundary} data-testid='error-fallback'>
      <div className={classes.divContainer}>
        <img src={ErrorIcon} alt={'Error Icon'} width='80' height='80' />

        <Title1 className={classes.errorHeadline}>Something went wrong…</Title1>
        <div className={classes.subHeading}>
          An unexpected error occurred.
          <br />
          If you have not already, please refresh your browser first or contact
          Team Radiatori for further support.
        </div>

        <BodySmallLink
          className={classes.link}
          component='button'
          onClick={() => refreshPage()}
        >
          Refresh Page
        </BodySmallLink>
      </div>
    </div>
  );
};
