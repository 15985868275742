import React from 'react';
import { Button, Grid } from '@mui/material';
import connector, { IPropsFromState } from '../../Connector/Connector';
import {
  addBreadcrumbHandler,
  IBreadcrumbHandlerProps,
} from '../../../utils/addBreadcrumbHandler';
import { IInvoiceListItem } from '../../../types/invoice';
import InformationItem from '../../shared/InformationItems/InformationItem';
import { formatDateNoTime } from '../../../utils/dates';
import { parseAmount } from '../../CollectionCards/Invoices/InvoiceTable';
import { useTypedTranslation } from '../../../custom-hooks/useTypedTranslation';
import { useStyles } from './useStyles';
import { Typography } from '../../shared/Typography/Typography';

type TOwnProps = IPropsFromState & {
  invoice: IInvoiceListItem | null;
};

export const Invoice: React.FC<TOwnProps> = ({
  invoice,
  breadcrumbState,
  addBreadcrumb,
}) => {
  const classes = useStyles();
  const { t, terms } = useTypedTranslation();
  const taskBreadcrumbState = breadcrumbState.breadcrumbs;

  const breadcrumbHandler = (props: IBreadcrumbHandlerProps): void =>
    addBreadcrumbHandler({
      ...props,
      addBreadcrumb,
      taskBreadcrumbState,
    });

  if (!invoice) {
    return null;
  }

  const getItems = () => [
    {
      id: 'invoice-id',
      headline: t(terms.action_cards.invoices.invoice_id),
      content: (
        <Typography variant='body2' data-testid='data-invoice-number'>
          {invoice.invoice_number}
        </Typography>
      ),
    },
    {
      id: 'invoice-document-date',
      headline: t(terms.action_cards.invoices.document_date),
      content: (
        <Typography variant='body2' data-testid='data-created-at'>
          {formatDateNoTime(invoice.invoice_date)}
        </Typography>
      ),
    },
    {
      id: 'invoice-total-amount',
      headline: t(terms.action_cards.invoices.total_amount),
      content: (
        <Typography variant='body2' data-testid='data-invoice-amount'>
          {invoice.gross_amount
            ? parseAmount(invoice.gross_amount, invoice.currency)
            : '-'}
        </Typography>
      ),
    },
  ];

  return (
    <div
      className={`${classes.componentWrapper}`}
      data-testid='invoice-action-component'
    >
      <Grid container>
        {getItems().map((item) => (
          <Grid item xs={item.id === 'invoice-id' ? 12 : 6} key={item.id}>
            <InformationItem key={item.id} item={item} hasMarginRight={false} />
          </Grid>
        ))}
      </Grid>
      <div className={`${classes.cardOverlay}`}>
        <Button
          data-testid='details-button'
          onClick={() =>
            breadcrumbHandler({
              component: 'InvoiceDetails',
              friendlyText: 'Invoice',
              id: invoice.id,
            })
          }
          classes={{ root: classes.detailButton }}
        >
          {t(terms.action_cards.invoices.details)}
        </Button>
      </div>
    </div>
  );
};

export default connector(Invoice);
