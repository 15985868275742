import { AppDispatch } from '../../../../store';
import { ActionCreator } from '../../../../store/state/actions';
import { ActionInterface } from '../../../../store/state/homeStations/actions';
import { Dispatcher } from '../index';

interface IDispatchers {
  getHomeStationsRequest: Dispatcher<ActionInterface.GetHomeStationsRequest>;
  sortHomeStations: Dispatcher<ActionInterface.SortHomeStations>;
}

export const createDispatchers = (dispatch: AppDispatch): IDispatchers => ({
  getHomeStationsRequest: (payload) =>
    dispatch(ActionCreator.homeStations.GetHomeStationsRequest(payload)),

  sortHomeStations: (payload) =>
    dispatch(ActionCreator.homeStations.SortHomeStations(payload)),
});
