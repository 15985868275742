export enum PlugAndChargeState {
  PENDING_ENABLE = 'PENDING_ENABLE',
  ENABLED = 'ENABLED',
  PENDING_DISABLE = 'PENDING_DISABLE',
  DISABLED = 'DISABLED',
  ERROR = 'ERROR',
  NO_REQUESTS_FOUND = 'NO_REQUESTS_FOUND',
}

export interface IPlugAndChargeInfo {
  state: PlugAndChargeState;
  emaid: string;
  pcid?: string;
  message: string;
}

export type PlugAndChargeRawEventType =
  | 'create'
  | 'revoke'
  | 'mo.prov.cert.updated'
  | 'mo.prov.cert.deleted'
  | 'mo.prov.cert.updated.factory.reset';

export type PlugAndChargeRawEventStatus =
  | 'QUEUED'
  | 'PENDING'
  | 'FAILURE'
  | 'TECHNICAL_ERROR'
  | 'SUCCESS'
  | 'NOT_FOUND';

export interface IPlugAndChargeRawEvent {
  timestamp: string;
  type: PlugAndChargeRawEventType;
  state?: PlugAndChargeRawEventStatus;
  provisioningCertificateId?: string;
}

export type PlugAndChargeEventType = 'ENABLE' | 'DISABLE' | 'FACTORY_RESET';

export type PlugAndChargeEventStatus =
  | 'PENDING'
  | 'FAILURE'
  | 'TRANSIENT_ERROR_RETRYING'
  | 'SUCCESS'
  | 'NOT_FOUND_EXTERNALLY';

export interface IPlugAndChargeEvent {
  timestamp: string;
  type: PlugAndChargeEventType;
  state?: PlugAndChargeEventStatus;
  provisioningCertificateId?: string;
}
