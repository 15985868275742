import React, { useEffect, useState } from 'react';

import { CardOrderStateModel } from '../../../store/state/cardorders';
import { ChargingSessionsStateModel } from '../../../store/state/chargingSessions';
import { IBreadcrumbHandlerProps } from '../../../utils/addBreadcrumbHandler';
import { IChargingCardModel } from '../../../store/state/chargingCards';
import { HomeStationsStateModel } from '../../../store/state/homeStations';
import {
  createActionButtons,
  IActionButtonConfig,
} from '../../shared/ActionBar/cardButtonFactory';

interface IOwnProps {
  chargingCards: IChargingCardModel;
  chargingSessions: ChargingSessionsStateModel;
  orders: CardOrderStateModel;
  homeStations: HomeStationsStateModel;
  breadcrumbHandler: (props: IBreadcrumbHandlerProps) => void;
}

const ActionButtons: React.FC<IOwnProps> = ({
  chargingCards,
  chargingSessions,
  orders,
  homeStations,
  breadcrumbHandler,
}) => {
  const [homeStationsnumber, setHomeStationsnumber] = useState<number>(0);
  const [cardOrdersnumber, setCardOrdersnumber] = useState<number>(0);
  const [chargingCardsnumber, setChargingCardsnumber] = useState<number>(0);
  const [chargingSessionsnumber, setChargingSessionsnumber] = useState<number>(
    0
  );

  useEffect(() => {
    if (chargingSessions && Array.isArray(chargingSessions.data)) {
      const publicSessions = chargingSessions.data.filter(
        (session) => session.type === 'private'
      );
      setChargingSessionsnumber(publicSessions.length);
    }

    if (chargingCards && Array.isArray(chargingCards.data)) {
      setChargingCardsnumber(chargingCards.data.length);
    }

    if (orders && Array.isArray(orders.data)) {
      setCardOrdersnumber(orders.data.length);
    }

    if (homeStations && Array.isArray(homeStations.stations)) {
      setHomeStationsnumber(homeStations.stations.length);
    }
  }, [chargingSessions, chargingCards, orders, homeStations]);

  const actionButtonsConfig: IActionButtonConfig[] = [
    {
      type: 'Stations',
      number: homeStationsnumber,
      breadcrumbHandler,
    },
    {
      type: 'PrivateChargingSessions',
      number: chargingSessionsnumber,
      breadcrumbHandler,
    },
    {
      type: 'Orders',
      number: cardOrdersnumber,
      breadcrumbHandler,
    },
    {
      type: 'ChargingCards',
      number: chargingCardsnumber,
      breadcrumbHandler,
    },
  ];

  return (
    <React.Fragment>{createActionButtons(actionButtonsConfig)}</React.Fragment>
  );
};

export default ActionButtons;
