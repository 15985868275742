import { ActionType } from './actions';
import {
  ActionCreator as BreadcrumbsActionCreator,
  ActionInterface as BreadcrumbsActionInterface,
} from '../breadcrumbs/actions';
import {
  ActionCreator as CardOrdersActionCreator,
  ActionInterface as CardOrdersActionInterface,
} from '../cardorders/actions';
import {
  ActionCreator as ChargingSessionsActionCreator,
  ActionInterface as ChargingSessionsActionInterface,
} from '../chargingSessions/actions';
import {
  ActionCreator as SubscriberActionCreator,
  ActionInterface as SubscriberActionInterface,
} from '../subscriber/actions';
import {
  ActionCreator as SubscriptionActionCreator,
  ActionInterface as SubscriptionActionInterface,
} from '../subscription/actions';
import {
  ActionCreator as UserActionCreator,
  ActionInterface as UserActionInterface,
} from '../user/actions';
import {
  ActionCreator as OrganizationActionCreator,
  ActionInterface as OrganizationActionInterface,
} from '../organization/actions';
import {
  ActionCreator as HomeStationsActionCreator,
  ActionInterface as HomeStationsActionInterface,
} from '../homeStations/actions';
import { all, put, takeEvery } from 'redux-saga/effects';

export function* otherSaga() {
  function* clearAll() {
    try {
      yield put<UserActionInterface.ClearUser>(UserActionCreator.ClearUser());

      yield put<SubscriptionActionInterface.ClearSubscription>(
        SubscriptionActionCreator.ClearSubscription()
      );

      yield put<SubscriberActionInterface.ClearSubscriber>(
        SubscriberActionCreator.ClearSubscriber()
      );

      yield put<CardOrdersActionInterface.ClearCardOrders>(
        CardOrdersActionCreator.ClearCardOrders()
      );

      yield put<BreadcrumbsActionInterface.RemoveBreadcrumb>(
        BreadcrumbsActionCreator.RemoveBreadcrumb({ position: 0 })
      );

      yield put<ChargingSessionsActionInterface.ClearChargingSessions>(
        ChargingSessionsActionCreator.ClearChargingSessions()
      );

      yield put<OrganizationActionInterface.ClearOrganization>(
        OrganizationActionCreator.ClearOrganization()
      );

      yield put<HomeStationsActionInterface.ClearHomeStations>(
        HomeStationsActionCreator.ClearHomeStations()
      );
    } catch (error) {
      throw new Error(JSON.stringify(error));
    }
  }

  yield all([takeEvery(ActionType.ClearAll, clearAll)]);
}

export default otherSaga;
