import { Button } from '@elli-eco/component-library';
import { CircularProgress, useTheme } from '@mui/material';
import React, { useEffect, useState } from 'react';
import {
  StopCommandStatus,
  isCommandInProgress,
  DismissCommandStatus,
} from './StopAndDismissSessionButtons';
import { useDismissChargingSessionQuery } from '../../../state/queries/usePublicChargingSessions';
import { IPublicChargingSession } from '../../../types/publicCharging';
import { useStyles } from './useStyles';
import { SessionChangeConfirmationDialog } from './SessionChangeConfirmDialog';
import { SessionChangeErrorDialog } from './SessionChangeErrorDialog';

type TDismissSessionButtonProps = {
  setDismissCommandStatus: (x: DismissCommandStatus) => void;
  dismissCommandStatus: DismissCommandStatus;
  stopCommandStatus: StopCommandStatus;
  userId: string;
  evseId: string;
  session: IPublicChargingSession;
};

export const DismissSessionButton: React.FC<TDismissSessionButtonProps> = ({
  setDismissCommandStatus,
  dismissCommandStatus,
  stopCommandStatus,
  userId,
  evseId,
  session,
}) => {
  const theme = useTheme();
  const classes = useStyles(theme);

  const [errorMessage, setErrorMessage] = useState<string>('');
  const [confirmDialogOpen, setConfirmDialogOpen] = useState<boolean>(false);
  const [errorDialogOpen, setErrorDialogOpen] = useState<boolean>(false);

  const {
    mutate: sendDismissSessionCommand,
    status: dismissMutationStatus,
    error: dismissMutationError,
  } = useDismissChargingSessionQuery(userId, evseId);

  useEffect(() => {
    if (dismissCommandStatus === 'clicked') {
      setDismissCommandStatus('idle');
      setConfirmDialogOpen(true);
    }
  }, [dismissCommandStatus]);

  useEffect(() => {
    switch (dismissMutationStatus) {
      case 'idle':
        setDismissCommandStatus('idle');
        return;
      case 'success':
        return;
      case 'loading':
        setDismissCommandStatus('sending');
        return;
      case 'error':
        setDismissCommandStatus('error');
        setErrorMessage(
          `Error sending dismiss command: ${
            (dismissMutationError as { message: string }).message
          }`
        );
        return;
      default:
        setDismissCommandStatus('waiting');
    }
  }, [dismissMutationStatus]);

  useEffect(() => {
    if (errorMessage === '') {
      setErrorDialogOpen(false);
    } else {
      setConfirmDialogOpen(false);
      setErrorDialogOpen(true);
    }
  }, [errorMessage]);

  useEffect(() => {
    if (
      (session.status === 'COMPLETED' ||
        session.status === 'COMPLETED_BY_REMOTE_STOP' ||
        session.status === 'COMPLETED_BY_AGENT_KILL') &&
      (confirmDialogOpen || errorDialogOpen)
    ) {
      setConfirmDialogOpen(false);
      setErrorDialogOpen(false);
      setDismissCommandStatus('success');
    }
  }, [session]);

  if (!['ACTIVE', 'PENDING', 'INVALID'].includes(session.status)) {
    return <></>;
  }

  const dismissButton = () => (
    <Button
      variant='filled'
      className={classes.dismissButton}
      isLoading={isCommandInProgress(dismissCommandStatus)}
      disabled={isCommandInProgress(stopCommandStatus)}
      onClick={() => setConfirmDialogOpen(true)}
    >
      Dismiss session
    </Button>
  );

  const confirmDialog = () => (
    <SessionChangeConfirmationDialog
      open={confirmDialogOpen}
      session={session}
      dialogHeader={'Dismiss session'}
      upperMessage={'Are you sure you want to dismiss this session?'}
      lowerMessage={
        'We will override any updates from the CPO and consider the session completed immediately.'
      }
      noCallback={() => setConfirmDialogOpen(false)}
      yesCallback={sendDismissSessionCommand}
      yesText={'Dismiss session'}
      yesButtonClassName={'dismissButton'}
      noText={'Back'}
      loading={isCommandInProgress(dismissCommandStatus)}
      loadingItem={<CircularProgress></CircularProgress>}
    ></SessionChangeConfirmationDialog>
  );

  const errorDialog = () => (
    <SessionChangeErrorDialog
      open={errorDialogOpen}
      dialogHeader={'Session could not be dismissed'}
      errorMessage={errorMessage}
      additionalMessages={[
        'This should never happen! Please copy the following info and report a bug with the backend team!',
        `Session id: ${session.id}`,
        `EVSE id: ${session.location_info.evse_id}`,
      ]}
      closeCallback={() => setErrorMessage('')}
    ></SessionChangeErrorDialog>
  );

  return (
    <>
      {dismissButton()}
      {confirmDialog()}
      {errorDialog()}
    </>
  );
};
