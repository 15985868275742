import { ErrorObject } from 'serialize-error';
import { ILocationModel } from '../../../types/chargingSession';
import { ILocationParams } from './../../../services/locations';

interface ILocation {
  [id: string]: ILocationModel;
}

export interface ILocations {
  [id: string]: ILocationModel[];
}

export interface IChargingStationModel {
  loading: boolean;
  nearbyLocationsLoading: boolean;
  data: ILocation | null;
  nearbyLocations: ILocations | null;
  error: ErrorObject | null;
  request: { evse_id: string } | { location: ILocationParams } | null;
}

export const initialState: IChargingStationModel = {
  loading: false,
  nearbyLocationsLoading: false,
  data: null,
  nearbyLocations: null,
  error: null,
  request: null,
};

const state: IChargingStationModel = {
  ...initialState,
};

export type State = typeof state;
export default state;
