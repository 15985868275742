import { useQuery, UseQueryResult } from 'react-query';
import { InvoiceService } from '../../services/invoices';
import { IInvoice } from '../../types/invoice';

export const useInvoiceQuery = (
  subscriberId: string | undefined,
  invoiceId: string | undefined
): UseQueryResult<IInvoice> => {
  return useQuery(
    ['invoice', invoiceId],
    async () => {
      return await InvoiceService.getInvoiceById(subscriberId!, invoiceId!);
    },
    {
      enabled: !!invoiceId && !!subscriberId,
      cacheTime: Infinity,
      staleTime: Infinity,
    }
  );
};
