import createStyles from '@mui/styles/createStyles';
import makeStyles from '@mui/styles/makeStyles';

const useStyles = makeStyles(() =>
  createStyles({
    buttonContainer: {
      marginTop: 26,
    },
    centeredContainer: {
      display: 'flex',
      justifyContent: 'center',
    },
  })
);

export default useStyles;
