import { AppDispatch } from '../../../../store';
import { ActionCreator } from '../../../../store/state/actions';
import { ActionInterface } from '../../../../store/state/user/actions';
import { Dispatcher } from '../index';

interface IDispatchers {
  getUserRequest: Dispatcher<ActionInterface.GetUserRequest>;
  verifyUser: Dispatcher<ActionInterface.VerifyUser>;
  updateUserProfile: Dispatcher<ActionInterface.UpdateUser>;
}

export const createDispatchers = (dispatch: AppDispatch): IDispatchers => ({
  getUserRequest: (payload) =>
    dispatch(ActionCreator.user.GetUserRequest(payload)),
  verifyUser: (payload) => dispatch(ActionCreator.user.VerifyUser(payload)),
  updateUserProfile: (payload) =>
    dispatch(ActionCreator.user.UpdateUserRequest(payload)),
});
